import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProtokolAltBilesenF2_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProtokolAltBilesenF2_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  NormmAll: any[];
  NormmAll_dummy: any[];
  ProtokolAltBilesenById: any[];
  ProtokolAltBilesenById_dummy: any[];
  ProtokolBilesenById: any[];
  ProtokolBilesenById_dummy: any[];
  AltBilesenTestleri: any[];
  AltBilesenTestleri_dummy: any[];
  AltBilesenByBilesenId: any[];
  AltBilesenByBilesenId_dummy: any[];
  AlternatifTestAdaylari: any[];
  AlternatifTestAdaylari_dummy: any[];
  ProtokolAlternatifTestler: any[];
  ProtokolAlternatifTestler_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  SetValueOf: any;
  SetValueOf_dummy: any;
  DeleteRecord: number;
  DeleteRecord_dummy: number;
  DenormalizeProtokol: number;
  DenormalizeProtokol_dummy: number;
}

export class ProtokolAltBilesenF2_ScreenBase extends React.PureComponent<IProtokolAltBilesenF2_ScreenProps, any> {
  protokolaltbilesenf2_765890_value_kuikaSelectBoxRef: React.RefObject<any>;
  protokolaltbilesenf2_778774_value_kuikaSelectBoxRef: React.RefObject<any>;
  protokolaltbilesenf2_819532_value_kuikaSelectBoxRef: React.RefObject<any>;
  protokolaltbilesenf2_789865_value_kuikaSelectBoxRef: React.RefObject<any>;
  protokolaltbilesenf2_678845_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "bc53d55d-b04e-448f-aecc-3e9d9523e171",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Protokol Alt Bileşen Tanımı" },
        { Id: 909295, PropertyName: "value", Value: "Varsayılan Test" },
        { Id: 765890, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 436804, PropertyName: "value", Value: "Norm" },
        { Id: 778774, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 933433, PropertyName: "value", Value: "Alternatif Testler" },
        { Id: 819532, PropertyName: "placeholder", Value: "Alternatif Test" },
        { Id: 789865, PropertyName: "placeholder", Value: "Norm" },
        { Id: 678845, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 67831, PropertyName: "title", Value: "Title" },
        { Id: 884242, PropertyName: "value", Value: "[datafield:test]" },
        { Id: 785224, PropertyName: "title", Value: "Title" },
        { Id: 458743, PropertyName: "value", Value: "[datafield:norm]" },
        { Id: 878478, PropertyName: "title", Value: "Title" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.protokolaltbilesenf2_765890_value_kuikaSelectBoxRef = React.createRef();
    this.protokolaltbilesenf2_778774_value_kuikaSelectBoxRef = React.createRef();
    this.protokolaltbilesenf2_819532_value_kuikaSelectBoxRef = React.createRef();
    this.protokolaltbilesenf2_789865_value_kuikaSelectBoxRef = React.createRef();
    this.protokolaltbilesenf2_678845_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      NormmAll: [],
      ProtokolAltBilesenById: [],
      ProtokolBilesenById: [],
      AltBilesenTestleri: [],
      AltBilesenByBilesenId: [],
      AlternatifTestAdaylari: [],
      ProtokolAlternatifTestler: [],
      SaveRecord: false,
      SetValueOf: "",
      DeleteRecord: false,
      DenormalizeProtokol: 0
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("protokolaltbilesenf2", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProtokolAltBilesenF2PageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("protokolaltbilesenf2", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("protokolaltbilesenf2", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProtokolAltBilesenF2PageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      protokolaltbilesenf2_765890_value: this.state.ProtokolAltBilesenById?.at?.(0)?.varsayilanTestId ?? undefined,
      protokolaltbilesenf2_778774_value: this.state.ProtokolAltBilesenById?.at?.(0)?.varsayilanTestNormId ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ProtokolAltBilesenF2PageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolAltBilesenId ?? this.props.screenInputs.protokolaltbilesenid,
        "Guid"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid,
        "Guid"
      ),
      protokolAltBilesenId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolAltBilesenId ?? this.props.screenInputs.protokolaltbilesenid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProtokolAltBilesenF2/ProtokolAltBilesenF2PageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.NormmAll = result?.data.normmAll;

    formVars.protokolaltbilesenf2_778774_value =
      stateVars.ProtokolAltBilesenById?.length > 0
        ? stateVars.ProtokolAltBilesenById[0].varsayilanTestNormId
        : this.state.ProtokolAltBilesenById?.length > 0
        ? this.state.ProtokolAltBilesenById[0].varsayilanTestNormId
        : null;
    formVars.protokolaltbilesenf2_778774_options = stateVars.NormmAll;

    formVars.protokolaltbilesenf2_789865_options = stateVars.NormmAll;
    stateVars.ProtokolAltBilesenById = result?.data.protokolAltBilesenById;
    formVars.protokolaltbilesenf2_765890_value =
      stateVars.ProtokolAltBilesenById?.length > 0
        ? stateVars.ProtokolAltBilesenById[0].varsayilanTestId
        : this.state.ProtokolAltBilesenById?.length > 0
        ? this.state.ProtokolAltBilesenById[0].varsayilanTestId
        : null;
    formVars.protokolaltbilesenf2_765890_options = stateVars.AltBilesenTestleri;
    formVars.protokolaltbilesenf2_778774_value =
      stateVars.ProtokolAltBilesenById?.length > 0
        ? stateVars.ProtokolAltBilesenById[0].varsayilanTestNormId
        : this.state.ProtokolAltBilesenById?.length > 0
        ? this.state.ProtokolAltBilesenById[0].varsayilanTestNormId
        : null;
    formVars.protokolaltbilesenf2_778774_options = stateVars.NormmAll;
    stateVars.ProtokolBilesenById = result?.data.protokolBilesenById;
    stateVars.AltBilesenTestleri = result?.data.altBilesenTestleri;

    formVars.protokolaltbilesenf2_765890_value =
      stateVars.ProtokolAltBilesenById?.length > 0
        ? stateVars.ProtokolAltBilesenById[0].varsayilanTestId
        : this.state.ProtokolAltBilesenById?.length > 0
        ? this.state.ProtokolAltBilesenById[0].varsayilanTestId
        : null;
    formVars.protokolaltbilesenf2_765890_options = stateVars.AltBilesenTestleri;
    stateVars.AltBilesenByBilesenId = result?.data.altBilesenByBilesenId;
    stateVars.AlternatifTestAdaylari = result?.data.alternatifTestAdaylari;

    formVars.protokolaltbilesenf2_819532_options = stateVars.AlternatifTestAdaylari;
    stateVars.ProtokolAlternatifTestler = result?.data.protokolAlternatifTestler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProtokolAltBilesenF2PageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProtokolAltBilesenF2PageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.protokolaltbilesenf2_765890_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolAltBilesenById?.length > 0
        ? stateVars.ProtokolAltBilesenById[0].varsayilanTestId
        : this.state.ProtokolAltBilesenById?.length > 0
        ? this.state.ProtokolAltBilesenById[0].varsayilanTestId
        : null
    );

    stateVars.dataSource_765890 = this.state.AltBilesenTestleri;
    stateVars.dataSource_765890 = this.state.AltBilesenTestleri;
    formVars.protokolaltbilesenf2_778774_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolAltBilesenById?.length > 0
        ? stateVars.ProtokolAltBilesenById[0].varsayilanTestNormId
        : this.state.ProtokolAltBilesenById?.length > 0
        ? this.state.ProtokolAltBilesenById[0].varsayilanTestNormId
        : null
    );

    stateVars.dataSource_778774 = this.state.NormmAll;
    stateVars.dataSource_778774 = this.state.NormmAll;

    stateVars.dataSource_819532 = this.state.AlternatifTestAdaylari;
    stateVars.dataSource_819532 = this.state.AlternatifTestAdaylari;

    stateVars.dataSource_789865 = this.state.NormmAll;
    stateVars.dataSource_789865 = this.state.NormmAll;

    stateVars.dataSource_678845 = this.state.ProtokolAlternatifTestler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProtokolAltBilesenF2Component_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProtokolAltBilesenF2Component_765890_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protokolaltbilesenf2_765890_value", "value", "AltBilesenTestleri", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "protokolaltbilesenf2_765890_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolAltBilesenById?.length > 0
            ? stateVars.ProtokolAltBilesenById[0].altBilesenId
            : this.state.ProtokolAltBilesenById?.length > 0
            ? this.state.ProtokolAltBilesenById[0].altBilesenId
            : null
        ),
        "Guid"
      ),
      varsayilanTestId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "protokolaltbilesenf2_765890_value",
            "value",
            "AltBilesenTestleri",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProtokolAltBilesenF2/ProtokolAltBilesenF2Component_765890_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AlternatifTestAdaylari = result?.data.alternatifTestAdaylari;

    formVars.protokolaltbilesenf2_819532_options = stateVars.AlternatifTestAdaylari;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProtokolAltBilesenF2Component_765890_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProtokolAltBilesenF2Component_765890_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_819532 = this.state.AlternatifTestAdaylari;
    stateVars.dataSource_819532 = this.state.AlternatifTestAdaylari;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProtokolAltBilesenF2Component_506590_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protokolaltbilesenf2_789865_value", "value", "NormmAll", "id", "")
        ),
        null
      ),
      message: "0",
      formName: "protokolaltbilesenf2_789865_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "protokolaltbilesenf2_819532_value",
            "value",
            "AlternatifTestAdaylari",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "protokolaltbilesenf2_819532_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      NormId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protokolaltbilesenf2_789865_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      ProtokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolAltBilesenId ?? this.props.screenInputs.protokolaltbilesenid,
        "Guid"
      ),
      TestId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "protokolaltbilesenf2_819532_value",
            "value",
            "AlternatifTestAdaylari",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      protokolAltBilesenId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolAltBilesenId ?? this.props.screenInputs.protokolaltbilesenid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProtokolAltBilesenF2/ProtokolAltBilesenF2Component_506590_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.ProtokolAlternatifTestler = result?.data.protokolAlternatifTestler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProtokolAltBilesenF2Component_506590_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProtokolAltBilesenF2Component_506590_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "protokolaltbilesenf2_819532_value",
      null,
      null,
      "Id"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "protokolaltbilesenf2_789865_value",
      null,
      null,
      "Id"
    );

    stateVars.dataSource_678845 = this.state.ProtokolAlternatifTestler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProtokolAltBilesenF2Component_624309_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "protokolaltbilesenf2_678845_value", "id"),
        "Guid"
      ),
      protokolAltBilesenId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolAltBilesenId ?? this.props.screenInputs.protokolaltbilesenid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProtokolAltBilesenF2/ProtokolAltBilesenF2Component_624309_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteRecord = result?.data.deleteRecord_1;
    stateVars.ProtokolAlternatifTestler = result?.data.protokolAlternatifTestler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProtokolAltBilesenF2Component_624309_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProtokolAltBilesenF2Component_624309_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_678845 = this.state.ProtokolAlternatifTestler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProtokolAltBilesenF2Component_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protokolaltbilesenf2_765890_value", "value", "AltBilesenTestleri", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "protokolaltbilesenf2_765890_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protokolaltbilesenf2_778774_value", "value", "NormmAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "protokolaltbilesenf2_778774_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolAltBilesenId ?? this.props.screenInputs.protokolaltbilesenid,
        "Guid"
      ),
      VarsayilanTestId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "protokolaltbilesenf2_765890_value",
            "value",
            "AltBilesenTestleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      VarsayilanTestNormId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protokolaltbilesenf2_778774_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProtokolAltBilesenF2/ProtokolAltBilesenF2Component_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.DenormalizeProtokol = result?.data.denormalizeProtokol;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProtokolAltBilesenF2Component_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProtokolAltBilesenF2Component_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
