import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Header,
  Icon,
  KCol,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label
} from "../../components/web-components";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { DashboardBilesenDetay_ScreenBase } from "./dashboardbilesendetay-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class DashboardBilesenDetay_Screen extends DashboardBilesenDetay_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{}}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="dashboardbilesendetay_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 5,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              >
                <KRow
                  id="668268"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="386896"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <KRow
                      id="593495"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="913099"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="994532"
                          value={ReactSystemFunctions.translate(this.ml, 994532, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: "18px",
                              color: "rgba(10, 34, 64, 1)",
                              textTransform: "uppercase"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="90644"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></KCol>

                      <KCol
                        id="45059"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></KCol>
                    </KRow>
                  </KCol>

                  <KCol
                    id="464992"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>

                  <KCol
                    id="604487"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Icon
                      id="297690"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.DashboardBilesenDetayComponent_297690_onClick();
                      }}
                      showCursorPointer
                      iconName="clear"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="dashboardbilesendetay_body"
                style={
                  {
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="977589"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="821689"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <KRow
                      id="656707"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="338583"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KTable
                          id="613837"
                          kuikaRef={this.dashboardbilesendetay_613837_value_kuikaTableRef}
                          form={this.props.form}
                          dataSource={this.state.DashboardFitskorDetay}
                          size="middle"
                          bordered={true}
                          loading={false}
                          transformedOnMobileResolution={false}
                          showHeader={true}
                          fixedHeader={false}
                          editable={false}
                          tableWidthMode="fit"
                          globalSearch={false}
                          searchable={false}
                          sorter={true}
                          filtering={false}
                          pagination={false}
                          pageSize={10}
                          showSizeChanger={false}
                          columnChooser={false}
                          resizableColumns={false}
                          striped={false}
                          stripedColor="#F5F7FA"
                          insertRowActive={false}
                          insertRowPosition="top"
                          showNoDataFound={true}
                          nodatafoundmessage={ReactSystemFunctions.translate(
                            this.ml,
                            613837,
                            "nodatafoundmessage",
                            this.defaultML
                          )}
                          multiSelect={false}
                          export={false}
                          editableAlwaysActive={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KTableHeader
                            id="594718"
                            columnTitles={this.state.DashboardFitskorDetayHeader?.at?.(0)?.header ?? undefined}
                            hideOnMobileResolution={false}
                            textDirection="Default"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "12px",
                                color: "rgba(10, 34, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></KTableHeader>

                          <KTableRow
                            id="473661"
                            hoverFontColor="red"
                            hoverBgColor="#F5F5F5"
                            style={
                              {
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KTableColumn
                              id="260145"
                              title={ReactSystemFunctions.translate(this.ml, 260145, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="62715"
                                value="[datafield:okulNumarasi]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="666862"
                              title={ReactSystemFunctions.translate(this.ml, 666862, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="791120"
                                value="[datafield:adSoyad]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="17011"
                              visibility={this.state.isComp17011Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "0"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 17011, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="525837"
                                value="[datafield:bilesen1Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="598327"
                              visibility={this.state.isComp598327Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "1"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 598327, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="27851"
                                value="[datafield:bilesen2Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="299648"
                              visibility={this.state.isComp299648Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "2"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 299648, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="343877"
                                value="[datafield:bilesen3Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="700706"
                              visibility={this.state.isComp700706Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "3"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 700706, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="75662"
                                value="[datafield:bilesen4Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="185910"
                              visibility={this.state.isComp185910Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "4"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 185910, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="143793"
                                value="[datafield:bilesen5Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="153903"
                              visibility={this.state.isComp153903Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "5"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 153903, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="702474"
                                value="[datafield:bilesen6Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="130239"
                              visibility={this.state.isComp130239Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "6"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 130239, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="237569"
                                value="[datafield:bilesen7Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="606708"
                              visibility={this.state.isComp606708Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "7"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 606708, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="481735"
                                value="[datafield:bilesen8Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="390657"
                              visibility={this.state.isComp390657Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "8"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 390657, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="205778"
                                value="[datafield:bilesen9Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="228704"
                              visibility={this.state.isComp228704Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "9"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 228704, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="222213"
                                value="[datafield:bilesen10Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="774594"
                              visibility={this.state.isComp774594Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "10"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 774594, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="21285"
                                value="[datafield:bilesen11Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="67117"
                              visibility={this.state.isComp67117Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "11"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 67117, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="26666"
                                value="[datafield:bilesen12Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="388437"
                              visibility={this.state.isComp388437Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isGreaterThan(
                                  this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                  "12"
                                )
                              }
                              title={ReactSystemFunctions.translate(this.ml, 388437, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="954461"
                                value="[datafield:bilesen13Puan]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="849708"
                              title={ReactSystemFunctions.translate(this.ml, 849708, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="936935"
                                value="[datafield:fitSkor]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>
                          </KTableRow>
                        </KTable>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(DashboardBilesenDetay_Screen))))
  )
);
export { tmp as DashboardBilesenDetay_Screen };
//export default tmp;
//export { tmp as DashboardBilesenDetay_Screen };
