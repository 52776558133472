import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDatePicker,
  KPanel,
  KRow,
  Label,
  MultiSelect,
  NumberInput,
  Rating,
  Switch
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { BasvuruEgitmenDetay_ScreenBase } from "./basvuruegitmendetay-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class BasvuruEgitmenDetay_Screen extends BasvuruEgitmenDetay_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              basvuruegitmendetay_7597451_value: this.state.SelectBasvuruEgitmen?.at?.(0)?.foto ?? undefined,
              basvuruegitmendetay_709260_value: this.state.SelectBasvuruEgitmen?.at?.(0)?.memapYer ?? undefined,
              basvuruegitmendetay_874048_value: this.state.SelectBasvuruEgitmen?.at?.(0)?.memapFayda ?? undefined,
              basvuruegitmendetay_804623_value: undefined,
              basvuruegitmendetay_912613_value: undefined,
              basvuruegitmendetay_262520_value: 3,
              basvuruegitmendetay_545654_value: "",
              basvuruegitmendetay_343725_value: undefined,
              basvuruegitmendetay_412386_value: undefined,
              basvuruegitmendetay_368150_value: false
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="basvuruegitmendetay_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="472882"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="622344"
                    xs={21}
                    sm={21}
                    md={21}
                    lg={21}
                    xl={21}
                    xxl={21}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="796540"
                      value={ReactSystemFunctions.translate(this.ml, 796540, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "18px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="835430"
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    xxl={3}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Icon
                      id="968274"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.BasvuruEgitmenDetayComponent_968274_onClick();
                      }}
                      showCursorPointer
                      iconName="close"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="basvuruegitmendetay_body"
                style={
                  {
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="176430"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="302640"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 5,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <KPanel id="2747666" scrolling="hidden" scrollRadius={false} style={{} as any}>
                      <KRow
                        id="4176973"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="4668252"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          xxl={24}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                              borderBottomLeftRadius: 8,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="7924058"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="3059503"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="basvuruegitmendetay_7597451_value">
                                <Image
                                  id="7597451"
                                  zoomOnClick={true}
                                  imageFit="fit"
                                  style={{ height: "108px", display: "block" } as any}
                                ></Image>
                              </Form.Item>
                            </KCol>
                          </KRow>

                          <KRow
                            id="69406"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="132679"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderTopLeftRadius: 8,
                                  borderTopRightRadius: 8,
                                  borderBottomRightRadius: 8,
                                  borderBottomLeftRadius: 8,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(0, 190, 214, 0.08)",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KRow
                                id="7479746"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="5908354"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="1333779"
                                    value={ReactSystemFunctions.translate(this.ml, 1333779, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <HorizontalStack
                                    id="237791"
                                    direction="horizontal"
                                    size={6}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="7965712"
                                      value={this.state.SelectBasvuruEgitmen?.at?.(0)?.ad ?? undefined}
                                      style={{ display: "block" } as any}
                                    ></Label>

                                    <Label
                                      id="4810327"
                                      value={this.state.SelectBasvuruEgitmen?.at?.(0)?.soyad ?? undefined}
                                      style={{ display: "block" } as any}
                                    ></Label>
                                  </HorizontalStack>
                                </KCol>

                                <KCol
                                  id="91059"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="8406194"
                                    value={ReactSystemFunctions.translate(this.ml, 8406194, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="4430370"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.cinsiyet ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="296575"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></KCol>
                              </KRow>

                              <KRow
                                id="6396295"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="6951619"
                                  xs={24}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="2304294"
                                    value={ReactSystemFunctions.translate(this.ml, 2304294, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="1001417"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.email ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="736209"
                                  xs={24}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="9330945"
                                    value={ReactSystemFunctions.translate(this.ml, 9330945, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="4391480"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.cepTelefonu ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="835709"
                                  xs={24}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="1116683"
                                    value={ReactSystemFunctions.translate(this.ml, 1116683, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="1862274"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.dogumTarihi ?? undefined}
                                    formatter="date"
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>

                          <KRow
                            id="311933"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="614399"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderTopLeftRadius: 8,
                                  borderTopRightRadius: 8,
                                  borderBottomRightRadius: 8,
                                  borderBottomLeftRadius: 8,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(236, 125, 49, 0.08)",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KRow
                                id="1043784"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="1331729"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="7391551"
                                    value={ReactSystemFunctions.translate(this.ml, 7391551, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="7868884"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.egitimDuzey ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>
                              </KRow>

                              <KRow
                                id="8953007"
                                visibility={this.state.isComp8953007Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.SelectBasvuruEgitmen[0]?.egitimDuzey?.toString(),
                                    "Lisans"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="453556"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="3048911"
                                    value={ReactSystemFunctions.translate(this.ml, 3048911, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="6694261"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.lisansMezun ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="9503561"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="8768062"
                                    value={ReactSystemFunctions.translate(this.ml, 8768062, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="7497860"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.lisansBolum ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="344186"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></KCol>
                              </KRow>

                              <KRow
                                id="3173525"
                                visibility={this.state.isComp3173525Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.SelectBasvuruEgitmen[0]?.egitimDuzey?.toString(),
                                    "Lisans üstü"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="4940496"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="3982047"
                                    value={ReactSystemFunctions.translate(this.ml, 3982047, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="4517527"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.lisansustuDerece ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="603882"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="6878697"
                                    value={ReactSystemFunctions.translate(this.ml, 6878697, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="1319389"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.lisansustuKonu ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="215412"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></KCol>
                              </KRow>

                              <KRow
                                id="8237260"
                                visibility={this.state.isComp8237260Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.SelectBasvuruEgitmen[0]?.egitimDuzey?.toString(),
                                    "Lise"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="2406697"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="3056754"
                                    value={ReactSystemFunctions.translate(this.ml, 3056754, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="7924933"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.liseDerece ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="38903"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="5169745"
                                    value={ReactSystemFunctions.translate(this.ml, 5169745, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="7563921"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.liseSertifika ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="173817"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></KCol>
                              </KRow>

                              <KRow
                                id="6165486"
                                visibility={this.state.isComp6165486Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.SelectBasvuruEgitmen[0]?.egitimDuzey?.toString(),
                                    "Spor bilimleri fakültesinde öğrenciyim"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="4254015"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="1372981"
                                    value={ReactSystemFunctions.translate(this.ml, 1372981, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="9424610"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.ogrenciUni ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="163593"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="4489629"
                                    value={ReactSystemFunctions.translate(this.ml, 4489629, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="5457052"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.ogrenciBolum ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="672173"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></KCol>
                              </KRow>
                            </KCol>
                          </KRow>

                          <KRow
                            id="609297"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="588297"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderTopLeftRadius: 8,
                                  borderTopRightRadius: 8,
                                  borderBottomRightRadius: 8,
                                  borderBottomLeftRadius: 8,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(10, 33, 64, 0.08)",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KRow
                                id="2997494"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="9589100"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="4642527"
                                    value={ReactSystemFunctions.translate(this.ml, 4642527, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="4034303"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.meslek ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="639872"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="8998200"
                                    value={ReactSystemFunctions.translate(this.ml, 8998200, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="3957459"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.tecrubeSure ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="656970"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></KCol>
                              </KRow>

                              <KRow
                                id="8694517"
                                visibility={this.state.isComp8694517Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.SelectBasvuruEgitmen[0]?.meslek?.toString(),
                                    "Antrenör"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="7398539"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="9975869"
                                    value={ReactSystemFunctions.translate(this.ml, 9975869, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="1479436"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.antrenorKlup ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="661414"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="8786072"
                                    value={ReactSystemFunctions.translate(this.ml, 8786072, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="7983305"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.antrenorBrans ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="460766"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></KCol>
                              </KRow>

                              <KRow
                                id="4940011"
                                visibility={this.state.isComp4940011Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.SelectBasvuruEgitmen[0]?.meslek?.toString(),
                                    "Beden eğitimi öğretmeni"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="3799104"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="2007466"
                                    value={ReactSystemFunctions.translate(this.ml, 2007466, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="8325109"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.bedenOkul ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="893809"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="2355371"
                                    value={ReactSystemFunctions.translate(this.ml, 2355371, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="9637686"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.bedenSinif ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="290402"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></KCol>
                              </KRow>

                              <KRow
                                id="5087320"
                                visibility={this.state.isComp5087320Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.SelectBasvuruEgitmen[0]?.meslek?.toString(),
                                    "Fitness eğitmeni"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="353042"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="5532961"
                                    value={ReactSystemFunctions.translate(this.ml, 5532961, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="6668765"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.fitnessMerkez ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="1228850"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="1709881"
                                    value={ReactSystemFunctions.translate(this.ml, 1709881, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="7596153"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.fitnessDers ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="92506"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="865657"
                                    value={ReactSystemFunctions.translate(this.ml, 865657, "value", this.defaultML)}
                                    style={
                                      {
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                        color: "rgba(10, 33, 64, 1)",
                                        lineHeight: "20px"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="6531374"
                                    value={this.state.SelectBasvuruEgitmen?.at?.(0)?.ozelPopulasyon ?? undefined}
                                    style={{ display: "block" } as any}
                                  ></Label>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>

                          <KRow
                            id="7241419"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="7381095"
                              xs={24}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="963476"
                                direction="horizontal"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="basvuruegitmendetay_709260_value">
                                  <Switch
                                    id="709260"
                                    editability={this.state.isComp709260Enabled}
                                    disabled={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Switch>
                                </Form.Item>

                                <Label
                                  id="3647503"
                                  value={ReactSystemFunctions.translate(this.ml, 3647503, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(10, 33, 64, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </HorizontalStack>
                            </KCol>

                            <KCol
                              id="766398"
                              xs={24}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="787761"
                                direction="horizontal"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="basvuruegitmendetay_874048_value">
                                  <Switch
                                    id="874048"
                                    editability={this.state.isComp874048Enabled}
                                    disabled={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Switch>
                                </Form.Item>

                                <Label
                                  id="491633"
                                  value={ReactSystemFunctions.translate(this.ml, 491633, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(10, 33, 64, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></Label>
                              </HorizontalStack>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>
                    </KPanel>
                  </KCol>
                </KRow>

                <KRow
                  id="719663"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="681156"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="26578"
                      value={ReactSystemFunctions.translate(this.ml, 26578, "value", this.defaultML)}
                      style={
                        {
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "rgba(10, 33, 64, 1)",
                          lineHeight: "20px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="basvuruegitmendetay_804623_value">
                      <MultiSelect
                        id="804623"
                        kuikaRef={this.basvuruegitmendetay_804623_value_kuikaMultiSelectRef}
                        options={this.state.ServiceAll}
                        placeholder={ReactSystemFunctions.translate(this.ml, 804623, "placeholder", this.defaultML)}
                        allowClear={false}
                        autoClearSearchValue={true}
                        mode="tags"
                        showSearch={true}
                        autoRefresh={true}
                        datavaluefield="id"
                        datatextfield="title"
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></MultiSelect>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="883175"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="303448"
                      value={ReactSystemFunctions.translate(this.ml, 303448, "value", this.defaultML)}
                      style={
                        {
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "rgba(10, 33, 64, 1)",
                          lineHeight: "20px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="basvuruegitmendetay_912613_value">
                      <KSelectBox
                        id="912613"
                        kuikaRef={this.basvuruegitmendetay_912613_value_kuikaSelectBoxRef}
                        options={this.state.MerkezAll}
                        placeholder={ReactSystemFunctions.translate(this.ml, 912613, "placeholder", this.defaultML)}
                        allowClear={false}
                        autoClearSearchValue={true}
                        showSearch={false}
                        widthType="fill"
                        containsNullItem={false}
                        sortBy="none"
                        datavaluefield="id"
                        datatextfield="unvani"
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></KSelectBox>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="683561"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="48637"
                      value={ReactSystemFunctions.translate(this.ml, 48637, "value", this.defaultML)}
                      style={
                        {
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "rgba(10, 33, 64, 1)",
                          lineHeight: "20px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="basvuruegitmendetay_262520_value">
                      <Rating
                        id="262520"
                        allowHalf={false}
                        count={5}
                        onBlur={{}}
                        onChange={{}}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 4,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0
                          } as any
                        }
                      ></Rating>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="927273"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>
                </KRow>

                <KRow
                  id="61741"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="407082"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="353692"
                      value={ReactSystemFunctions.translate(this.ml, 353692, "value", this.defaultML)}
                      style={
                        {
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "rgba(10, 33, 64, 1)",
                          lineHeight: "20px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="basvuruegitmendetay_545654_value">
                      <KDatePicker
                        id="545654"
                        kuikaRef={this.basvuruegitmendetay_545654_value_kuikaDateRef}
                        placeholder={ReactSystemFunctions.translate(this.ml, 545654, "placeholder", this.defaultML)}
                        allowClear={true}
                        format="DD/MM/YYYY"
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></KDatePicker>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="846178"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="362689"
                      value={ReactSystemFunctions.translate(this.ml, 362689, "value", this.defaultML)}
                      style={
                        {
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "rgba(10, 33, 64, 1)",
                          lineHeight: "20px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="basvuruegitmendetay_343725_value">
                      <KSelectBox
                        id="343725"
                        kuikaRef={this.basvuruegitmendetay_343725_value_kuikaSelectBoxRef}
                        options={this.state.MerkezAll}
                        placeholder={ReactSystemFunctions.translate(this.ml, 343725, "placeholder", this.defaultML)}
                        allowClear={false}
                        autoClearSearchValue={true}
                        showSearch={false}
                        widthType="fill"
                        containsNullItem={false}
                        sortBy="none"
                        datavaluefield="id"
                        datatextfield="unvani"
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></KSelectBox>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="506322"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="934743"
                      value={ReactSystemFunctions.translate(this.ml, 934743, "value", this.defaultML)}
                      style={
                        {
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "rgba(10, 33, 64, 1)",
                          lineHeight: "20px"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="basvuruegitmendetay_412386_value">
                      <NumberInput
                        id="412386"
                        decimalSeparator="."
                        decimalScale={2}
                        disabled={false}
                        placeholder={ReactSystemFunctions.translate(this.ml, 412386, "placeholder", this.defaultML)}
                        fixedDecimalScale={false}
                        allowNegative={true}
                        allowEmptyFormatting={false}
                        isThousandSeperatorOn={true}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></NumberInput>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="915454"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-end",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="769751"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 8,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <Form.Item className="kFormItem" name="basvuruegitmendetay_368150_value">
                        <Switch
                          id="368150"
                          disabled={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        ></Switch>
                      </Form.Item>

                      <Label
                        id="5868"
                        value={ReactSystemFunctions.translate(this.ml, 5868, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></Label>
                    </HorizontalStack>
                  </KCol>
                </KRow>

                <KRow
                  id="685813"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="335090"
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    xxl={4}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Button
                      id="233539"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.BasvuruEgitmenDetayComponent_233539_onClick();
                      }}
                      showCursorPointer
                      label={ReactSystemFunctions.translate(this.ml, 233539, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      iconPosition="left"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          alignItems: "center",
                          textAlign: "-webkit-center",
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "rgba(0, 190, 214, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    ></Button>
                  </KCol>

                  <KCol
                    id="894828"
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    xxl={4}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>

                  <KCol
                    id="73337"
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    xxl={4}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>

                  <KCol
                    id="968422"
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    xxl={4}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>

                  <KCol
                    id="275836"
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    xxl={4}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>

                  <KCol
                    id="299268"
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    xxl={4}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-end",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Button
                      id="391319"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.BasvuruEgitmenDetayComponent_391319_onClick();
                      }}
                      showCursorPointer
                      label={ReactSystemFunctions.translate(this.ml, 391319, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      iconPosition="left"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(0, 190, 214, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          alignItems: "center",
                          textAlign: "-webkit-center",
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "rgba(255, 255, 255, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    ></Button>
                  </KCol>
                </KRow>

                <KRow
                  id="924341"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="374866"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>
                </KRow>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(BasvuruEgitmenDetay_Screen))))
  )
);
export { tmp as BasvuruEgitmenDetay_Screen };
//export default tmp;
//export { tmp as BasvuruEgitmenDetay_Screen };
