import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IStatistics_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IStatistics_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  ProtokolAll: any[];
  ProtokolAll_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  StatisticsHeaders: any[];
  StatisticsHeaders_dummy: any[];
  Statistics1: any[];
  Statistics1_dummy: any[];
  Statistics2: any[];
  Statistics2_dummy: any[];
  Statistics3: any[];
  Statistics3_dummy: any[];
  Statistics4: any[];
  Statistics4_dummy: any[];
  Statistics5: any[];
  Statistics5_dummy: any[];
  Statistics6: any[];
  Statistics6_dummy: any[];
  Statistics7: any[];
  Statistics7_dummy: any[];
  Statistics8: any[];
  Statistics8_dummy: any[];
  Statistics9: any[];
  Statistics9_dummy: any[];
  Statistics10: any[];
  Statistics10_dummy: any[];
  Statistics11: any[];
  Statistics11_dummy: any[];
  Statistics12: any[];
  Statistics12_dummy: any[];
  Statistics13: any[];
  Statistics13_dummy: any[];
  StatisticsFitskor: any[];
  StatisticsFitskor_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp858494Visible: "visible" | "hidden";
  isComp873109Visible: "visible" | "hidden";
  isComp421177Visible: "visible" | "hidden";
  isComp648218Visible: "visible" | "hidden";
  isComp109620Visible: "visible" | "hidden";
  isComp386986Visible: "visible" | "hidden";
  isComp410932Visible: "visible" | "hidden";
  isComp165685Visible: "visible" | "hidden";
  isComp260568Visible: "visible" | "hidden";
  isComp303739Visible: "visible" | "hidden";
  isComp995738Visible: "visible" | "hidden";
  isComp740118Visible: "visible" | "hidden";
  isComp117230Visible: "visible" | "hidden";
  isComp329860Visible: "visible" | "hidden";
  isComp580388Visible: "visible" | "hidden";
  isComp902739Visible: "visible" | "hidden";
  isComp637565Visible: "visible" | "hidden";
  isComp775175Visible: "visible" | "hidden";
  isComp679376Visible: "visible" | "hidden";
  isComp458513Visible: "visible" | "hidden";
  isComp608123Visible: "visible" | "hidden";
  isComp748479Visible: "visible" | "hidden";
  isComp527186Visible: "visible" | "hidden";
  isComp773566Visible: "visible" | "hidden";
  isComp136286Visible: "visible" | "hidden";
  isComp261145Visible: "visible" | "hidden";
  isComp947521Visible: "visible" | "hidden";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class Statistics_ScreenBase extends React.PureComponent<IStatistics_ScreenProps, any> {
  statistics_429146_value_kuikaSelectBoxRef: React.RefObject<any>;
  statistics_550018_value_kuikaTableRef: React.RefObject<any>;
  statistics_950610_value_kuikaTableRef: React.RefObject<any>;
  statistics_432546_value_kuikaTableRef: React.RefObject<any>;
  statistics_454449_value_kuikaTableRef: React.RefObject<any>;
  statistics_515629_value_kuikaTableRef: React.RefObject<any>;
  statistics_944779_value_kuikaTableRef: React.RefObject<any>;
  statistics_608660_value_kuikaTableRef: React.RefObject<any>;
  statistics_237621_value_kuikaTableRef: React.RefObject<any>;
  statistics_7761_value_kuikaTableRef: React.RefObject<any>;
  statistics_129696_value_kuikaTableRef: React.RefObject<any>;
  statistics_919835_value_kuikaTableRef: React.RefObject<any>;
  statistics_167450_value_kuikaTableRef: React.RefObject<any>;
  statistics_971471_value_kuikaTableRef: React.RefObject<any>;
  statistics_57209_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "ce1538a9-cf2e-4486-93b9-7663f682168f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 521286, PropertyName: "value", Value: "istatistikler" },
        { Id: 429146, PropertyName: "placeholder", Value: "Protokol" },
        { Id: 232951, PropertyName: "title", Value: "Sınıf" },
        { Id: 371343, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 827278, PropertyName: "title", Value: "< 25" },
        { Id: 312212, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 771236, PropertyName: "title", Value: "25 - 50" },
        { Id: 10380, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 757981, PropertyName: "title", Value: "50 - 75" },
        { Id: 443930, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 744127, PropertyName: "title", Value: "75 - 100" },
        { Id: 517545, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 647089, PropertyName: "title", Value: "Sınıf" },
        { Id: 909231, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 820924, PropertyName: "title", Value: "< 25" },
        { Id: 11472, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 168803, PropertyName: "title", Value: "25 - 50" },
        { Id: 863289, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 188556, PropertyName: "title", Value: "50 - 75" },
        { Id: 317398, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 907394, PropertyName: "title", Value: "75 - 100" },
        { Id: 337822, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 10918, PropertyName: "title", Value: "Sınıf" },
        { Id: 824645, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 186238, PropertyName: "title", Value: "< 25" },
        { Id: 406407, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 337483, PropertyName: "title", Value: "25 - 50" },
        { Id: 604939, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 329670, PropertyName: "title", Value: "50 - 75" },
        { Id: 737148, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 669528, PropertyName: "title", Value: "75 - 100" },
        { Id: 386803, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 308996, PropertyName: "title", Value: "Sınıf" },
        { Id: 590966, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 197621, PropertyName: "title", Value: "< 25" },
        { Id: 284018, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 754811, PropertyName: "title", Value: "25 - 50" },
        { Id: 960709, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 815098, PropertyName: "title", Value: "50 - 75" },
        { Id: 736824, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 569607, PropertyName: "title", Value: "75 - 100" },
        { Id: 108286, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 577619, PropertyName: "title", Value: "Sınıf" },
        { Id: 192207, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 613068, PropertyName: "title", Value: "< 25" },
        { Id: 986368, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 111705, PropertyName: "title", Value: "25 - 50" },
        { Id: 620941, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 29109, PropertyName: "title", Value: "50 - 75" },
        { Id: 679011, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 346633, PropertyName: "title", Value: "75 - 100" },
        { Id: 788063, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 212255, PropertyName: "title", Value: "Sınıf" },
        { Id: 987888, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 474182, PropertyName: "title", Value: "< 25" },
        { Id: 273795, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 985236, PropertyName: "title", Value: "25 - 50" },
        { Id: 327862, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 283013, PropertyName: "title", Value: "50 - 75" },
        { Id: 430336, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 882910, PropertyName: "title", Value: "75 - 100" },
        { Id: 401086, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 239842, PropertyName: "title", Value: "Sınıf" },
        { Id: 306237, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 97256, PropertyName: "title", Value: "< 25" },
        { Id: 240479, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 781532, PropertyName: "title", Value: "25 - 50" },
        { Id: 814541, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 278218, PropertyName: "title", Value: "50 - 75" },
        { Id: 922411, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 374495, PropertyName: "title", Value: "75 - 100" },
        { Id: 40372, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 383927, PropertyName: "title", Value: "Sınıf" },
        { Id: 99151, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 369413, PropertyName: "title", Value: "< 25" },
        { Id: 817629, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 489785, PropertyName: "title", Value: "25 - 50" },
        { Id: 19683, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 821671, PropertyName: "title", Value: "50 - 75" },
        { Id: 804425, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 44371, PropertyName: "title", Value: "75 - 100" },
        { Id: 719781, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 361237, PropertyName: "title", Value: "Sınıf" },
        { Id: 682794, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 652303, PropertyName: "title", Value: "< 25" },
        { Id: 130926, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 717456, PropertyName: "title", Value: "25 - 50" },
        { Id: 886630, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 329434, PropertyName: "title", Value: "50 - 75" },
        { Id: 697912, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 611126, PropertyName: "title", Value: "75 - 100" },
        { Id: 322803, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 105905, PropertyName: "title", Value: "Sınıf" },
        { Id: 369292, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 535398, PropertyName: "title", Value: "< 25" },
        { Id: 239329, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 294344, PropertyName: "title", Value: "25 - 50" },
        { Id: 720798, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 880406, PropertyName: "title", Value: "50 - 75" },
        { Id: 581843, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 523830, PropertyName: "title", Value: "75 - 100" },
        { Id: 8187, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 726668, PropertyName: "title", Value: "Sınıf" },
        { Id: 936110, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 876565, PropertyName: "title", Value: "< 25" },
        { Id: 976138, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 250064, PropertyName: "title", Value: "25 - 50" },
        { Id: 254722, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 692939, PropertyName: "title", Value: "50 - 75" },
        { Id: 889986, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 752341, PropertyName: "title", Value: "75 - 100" },
        { Id: 974044, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 480421, PropertyName: "title", Value: "Sınıf" },
        { Id: 984523, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 348994, PropertyName: "title", Value: "< 25" },
        { Id: 836281, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 444632, PropertyName: "title", Value: "25 - 50" },
        { Id: 916379, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 875338, PropertyName: "title", Value: "50 - 75" },
        { Id: 164954, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 734815, PropertyName: "title", Value: "75 - 100" },
        { Id: 582069, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 675336, PropertyName: "title", Value: "Sınıf" },
        { Id: 759931, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 573529, PropertyName: "title", Value: "< 25" },
        { Id: 265715, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 863982, PropertyName: "title", Value: "25 - 50" },
        { Id: 330022, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 127225, PropertyName: "title", Value: "50 - 75" },
        { Id: 197022, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 127255, PropertyName: "title", Value: "75 - 100" },
        { Id: 974310, PropertyName: "value", Value: "[datafield:u101adet]" },
        { Id: 439842, PropertyName: "tab", Value: "FitSkor" },
        { Id: 213415, PropertyName: "title", Value: "Sınıf" },
        { Id: 908953, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 96717, PropertyName: "title", Value: "< 25" },
        { Id: 127998, PropertyName: "value", Value: "[datafield:u25adet]" },
        { Id: 557049, PropertyName: "title", Value: "25 - 50" },
        { Id: 793606, PropertyName: "value", Value: "[datafield:u51adet]" },
        { Id: 492419, PropertyName: "title", Value: "50 - 75" },
        { Id: 374203, PropertyName: "value", Value: "[datafield:u76adet]" },
        { Id: 118823, PropertyName: "title", Value: "75 - 100" },
        { Id: 67813, PropertyName: "value", Value: "[datafield:u101adet]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.statistics_429146_value_kuikaSelectBoxRef = React.createRef();
    this.statistics_550018_value_kuikaTableRef = React.createRef();
    this.statistics_950610_value_kuikaTableRef = React.createRef();
    this.statistics_432546_value_kuikaTableRef = React.createRef();
    this.statistics_454449_value_kuikaTableRef = React.createRef();
    this.statistics_515629_value_kuikaTableRef = React.createRef();
    this.statistics_944779_value_kuikaTableRef = React.createRef();
    this.statistics_608660_value_kuikaTableRef = React.createRef();
    this.statistics_237621_value_kuikaTableRef = React.createRef();
    this.statistics_7761_value_kuikaTableRef = React.createRef();
    this.statistics_129696_value_kuikaTableRef = React.createRef();
    this.statistics_919835_value_kuikaTableRef = React.createRef();
    this.statistics_167450_value_kuikaTableRef = React.createRef();
    this.statistics_971471_value_kuikaTableRef = React.createRef();
    this.statistics_57209_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      ProtokolAll: [],
      SetValueOf: "",
      StatisticsHeaders: [],
      Statistics1: [],
      Statistics2: [],
      Statistics3: [],
      Statistics4: [],
      Statistics5: [],
      Statistics6: [],
      Statistics7: [],
      Statistics8: [],
      Statistics9: [],
      Statistics10: [],
      Statistics11: [],
      Statistics12: [],
      Statistics13: [],
      StatisticsFitskor: [],
      NAVIGATE: "",
      isComp858494Visible: "hidden",
      isComp873109Visible: "hidden",
      isComp421177Visible: "hidden",
      isComp648218Visible: "hidden",
      isComp109620Visible: "hidden",
      isComp386986Visible: "hidden",
      isComp410932Visible: "hidden",
      isComp165685Visible: "hidden",
      isComp260568Visible: "hidden",
      isComp303739Visible: "hidden",
      isComp995738Visible: "hidden",
      isComp740118Visible: "hidden",
      isComp117230Visible: "hidden",
      isComp329860Visible: "hidden",
      isComp580388Visible: "hidden",
      isComp902739Visible: "hidden",
      isComp637565Visible: "hidden",
      isComp775175Visible: "hidden",
      isComp679376Visible: "hidden",
      isComp458513Visible: "hidden",
      isComp608123Visible: "hidden",
      isComp748479Visible: "hidden",
      isComp527186Visible: "hidden",
      isComp773566Visible: "hidden",
      isComp136286Visible: "hidden",
      isComp261145Visible: "hidden",
      isComp947521Visible: "hidden",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("statistics", "master_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.StatisticsPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("statistics", "master_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("statistics", "master_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.StatisticsPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.StatisticsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      statistics_429146_value: this.state.ProtokolAll?.at?.(0)?.id ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  checkTabErrors = () => {
    document.querySelectorAll(".rc-tabs-tab").forEach((tab) => {
      (tab as HTMLElement).style.color = "";
    });

    const tabPanes = document.querySelectorAll(".rc-tabs-tabpane");
    const tabs = document.querySelectorAll(".rc-tabs-tab");

    tabPanes.forEach((tabPane, index) => {
      const uniquePaneId = `rc-tabs-tabpane-${index}`;
      if (!tabPane.hasAttribute("id")) {
        tabPane.setAttribute("id", uniquePaneId);
      }

      const uniqueTabId = `rc-tabs-tab-${index}`;
      if (tabs[index]) {
        if (!tabs[index].hasAttribute("id")) {
          tabs[index].setAttribute("id", uniqueTabId);
        }
        tabs[index].setAttribute("aria-controls", uniquePaneId);
      }
    });

    const errorElements = document.querySelectorAll(".rc-tabs .ant-form-item-explain-error");

    const tabsWithErrors = new Set<string>();

    errorElements.forEach((errorElement) => {
      const tabPane = errorElement.closest(".rc-tabs-tabpane");
      if (tabPane && tabPane.id) {
        tabsWithErrors.add(tabPane.id);
      }
    });

    tabsWithErrors.forEach((tabPaneId) => {
      const tab = document.querySelector(`.rc-tabs-tab[aria-controls="${tabPaneId}"]`);
      if (tab) {
        (tab as HTMLElement).style.color = "red";
      }
    });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  StatisticsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Statistics/StatisticsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.ProtokolAll = result?.data.protokolAll;

    formVars.statistics_429146_value =
      stateVars.ProtokolAll?.length > 0
        ? stateVars.ProtokolAll[0].id
        : this.state.ProtokolAll?.length > 0
        ? this.state.ProtokolAll[0].id
        : null;
    formVars.statistics_429146_options = stateVars.ProtokolAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.StatisticsPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  StatisticsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "statistics_429146_value",
      ReactSystemFunctions.toString(
        this,
        stateVars.ProtokolAll?.length > 0
          ? stateVars.ProtokolAll[0].id
          : this.state.ProtokolAll?.length > 0
          ? this.state.ProtokolAll[0].id
          : null
      ),
      null,
      "Id"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.StatisticsPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  StatisticsPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_1: ReactSystemFunctions.convertToTypeByName(1, "number"),
      protokolId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_2: ReactSystemFunctions.convertToTypeByName(2, "number"),
      protokolId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_3: ReactSystemFunctions.convertToTypeByName(3, "number"),
      protokolId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_4: ReactSystemFunctions.convertToTypeByName(4, "number"),
      protokolId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_5: ReactSystemFunctions.convertToTypeByName(5, "number"),
      protokolId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_6: ReactSystemFunctions.convertToTypeByName(6, "number"),
      protokolId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_7: ReactSystemFunctions.convertToTypeByName(7, "number"),
      protokolId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_8: ReactSystemFunctions.convertToTypeByName(8, "number"),
      protokolId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_9: ReactSystemFunctions.convertToTypeByName(9, "number"),
      protokolId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_10: ReactSystemFunctions.convertToTypeByName(10, "number"),
      protokolId_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_11: ReactSystemFunctions.convertToTypeByName(11, "number"),
      protokolId_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_12: ReactSystemFunctions.convertToTypeByName(12, "number"),
      protokolId_13: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_13: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_13: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_13: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_13: ReactSystemFunctions.convertToTypeByName(13, "number"),
      protokolId_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Statistics/StatisticsPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.StatisticsHeaders = result?.data.statisticsHeaders;
    stateVars.isComp858494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim1
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim1
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp421177Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim2
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim2
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp109620Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim3
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim3
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410932Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim4
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim4
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim5
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim5
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995738Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim6
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim6
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp117230Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim7
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim7
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp580388Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim8
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim8
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637565Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim9
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim9
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp679376Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim10
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim10
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp608123Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim11
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim11
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp527186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim12
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim12
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136286Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim13
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim13
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.Statistics1 = result?.data.statistics1;

    stateVars.Statistics2 = result?.data.statistics2;

    stateVars.Statistics3 = result?.data.statistics3;

    stateVars.Statistics4 = result?.data.statistics4;

    stateVars.Statistics5 = result?.data.statistics5;

    stateVars.Statistics6 = result?.data.statistics6;

    stateVars.Statistics7 = result?.data.statistics7;

    stateVars.Statistics8 = result?.data.statistics8;

    stateVars.Statistics9 = result?.data.statistics9;

    stateVars.Statistics10 = result?.data.statistics10;

    stateVars.Statistics11 = result?.data.statistics11;

    stateVars.Statistics12 = result?.data.statistics12;

    stateVars.Statistics13 = result?.data.statistics13;

    stateVars.StatisticsFitskor = result?.data.statisticsFitskor;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.StatisticsPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  StatisticsPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.statistics_429146_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolAll?.length > 0
        ? stateVars.ProtokolAll[0].id
        : this.state.ProtokolAll?.length > 0
        ? this.state.ProtokolAll[0].id
        : null
    );

    formVars.statistics_429146_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolAll?.length > 0
        ? stateVars.ProtokolAll[0].id
        : this.state.ProtokolAll?.length > 0
        ? this.state.ProtokolAll[0].id
        : null
    );

    stateVars.dataSource_429146 = this.state.ProtokolAll;
    stateVars.dataSource_429146 = this.state.ProtokolAll;
    formVars.statistics_858494_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim1
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim1
        : null
    );

    stateVars.isComp858494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim1
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim1
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_550018 = this.state.Statistics1;
    stateVars.isComp873109Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";
    formVars.statistics_421177_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim2
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim2
        : null
    );

    stateVars.isComp421177Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim2
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim2
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_950610 = this.state.Statistics2;
    stateVars.isComp648218Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";
    formVars.statistics_109620_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim3
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim3
        : null
    );

    stateVars.isComp109620Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim3
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim3
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_432546 = this.state.Statistics3;
    stateVars.isComp386986Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";
    formVars.statistics_410932_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim4
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim4
        : null
    );

    stateVars.isComp410932Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim4
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim4
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_454449 = this.state.Statistics4;
    stateVars.isComp165685Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";
    formVars.statistics_260568_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim5
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim5
        : null
    );

    stateVars.isComp260568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim5
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim5
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_515629 = this.state.Statistics5;
    stateVars.isComp303739Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";
    formVars.statistics_995738_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim6
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim6
        : null
    );

    stateVars.isComp995738Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim6
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim6
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_944779 = this.state.Statistics6;
    stateVars.isComp740118Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";
    formVars.statistics_117230_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim7
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim7
        : null
    );

    stateVars.isComp117230Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim7
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim7
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_608660 = this.state.Statistics7;
    stateVars.isComp329860Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";
    formVars.statistics_580388_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim8
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim8
        : null
    );

    stateVars.isComp580388Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim8
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim8
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_237621 = this.state.Statistics8;
    stateVars.isComp902739Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";
    formVars.statistics_637565_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim9
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim9
        : null
    );

    stateVars.isComp637565Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim9
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim9
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_7761 = this.state.Statistics9;
    stateVars.isComp775175Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";
    formVars.statistics_679376_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim10
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim10
        : null
    );

    stateVars.isComp679376Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim10
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim10
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_129696 = this.state.Statistics10;
    stateVars.isComp458513Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";
    formVars.statistics_608123_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim11
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim11
        : null
    );

    stateVars.isComp608123Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim11
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim11
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_919835 = this.state.Statistics11;
    stateVars.isComp748479Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";
    formVars.statistics_527186_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim12
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim12
        : null
    );

    stateVars.isComp527186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim12
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim12
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_167450 = this.state.Statistics12;
    stateVars.isComp773566Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";
    formVars.statistics_136286_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim13
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim13
        : null
    );

    stateVars.isComp136286Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim13
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim13
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_971471 = this.state.Statistics13;
    stateVars.isComp261145Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";

    stateVars.dataSource_57209 = this.state.StatisticsFitskor;
    stateVars.isComp947521Visible = ReactSystemFunctions.isNotEmpty(null, "Total") === true ? "visible" : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  StatisticsComponent_429146_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_1: ReactSystemFunctions.convertToTypeByName(1, "number"),
      protokolId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_2: ReactSystemFunctions.convertToTypeByName(2, "number"),
      protokolId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_3: ReactSystemFunctions.convertToTypeByName(3, "number"),
      protokolId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_4: ReactSystemFunctions.convertToTypeByName(4, "number"),
      protokolId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_5: ReactSystemFunctions.convertToTypeByName(5, "number"),
      protokolId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_6: ReactSystemFunctions.convertToTypeByName(6, "number"),
      protokolId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_7: ReactSystemFunctions.convertToTypeByName(7, "number"),
      protokolId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_8: ReactSystemFunctions.convertToTypeByName(8, "number"),
      protokolId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_9: ReactSystemFunctions.convertToTypeByName(9, "number"),
      protokolId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_10: ReactSystemFunctions.convertToTypeByName(10, "number"),
      protokolId_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_11: ReactSystemFunctions.convertToTypeByName(11, "number"),
      protokolId_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_12: ReactSystemFunctions.convertToTypeByName(12, "number"),
      protokolId_13: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_13: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_13: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_13: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      sira_13: ReactSystemFunctions.convertToTypeByName(13, "number"),
      protokolId_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupdonemiId_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      rolename_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      profilID_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Statistics/StatisticsComponent_429146_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.StatisticsHeaders = result?.data.statisticsHeaders;
    stateVars.isComp858494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim1
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim1
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp421177Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim2
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim2
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp109620Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim3
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim3
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410932Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim4
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim4
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim5
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim5
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995738Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim6
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim6
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp117230Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim7
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim7
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp580388Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim8
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim8
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637565Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim9
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim9
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp679376Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim10
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim10
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp608123Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim11
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim11
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp527186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim12
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim12
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136286Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.StatisticsHeaders?.length > 0
            ? stateVars.StatisticsHeaders[0].tanim13
            : this.state.StatisticsHeaders?.length > 0
            ? this.state.StatisticsHeaders[0].tanim13
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.Statistics1 = result?.data.statistics1;

    stateVars.Statistics2 = result?.data.statistics2;

    stateVars.Statistics3 = result?.data.statistics3;

    stateVars.Statistics4 = result?.data.statistics4;

    stateVars.Statistics5 = result?.data.statistics5;

    stateVars.Statistics6 = result?.data.statistics6;

    stateVars.Statistics7 = result?.data.statistics7;

    stateVars.Statistics8 = result?.data.statistics8;

    stateVars.Statistics9 = result?.data.statistics9;

    stateVars.Statistics10 = result?.data.statistics10;

    stateVars.Statistics11 = result?.data.statistics11;

    stateVars.Statistics12 = result?.data.statistics12;

    stateVars.Statistics13 = result?.data.statistics13;

    stateVars.StatisticsFitskor = result?.data.statisticsFitskor;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.StatisticsComponent_429146_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  StatisticsComponent_429146_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.statistics_858494_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim1
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim1
        : null
    );

    stateVars.dataSource_550018 = this.state.Statistics1;
    formVars.statistics_421177_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim2
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim2
        : null
    );

    stateVars.dataSource_950610 = this.state.Statistics2;
    formVars.statistics_109620_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim3
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim3
        : null
    );

    stateVars.dataSource_432546 = this.state.Statistics3;
    formVars.statistics_410932_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim4
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim4
        : null
    );

    stateVars.dataSource_454449 = this.state.Statistics4;
    formVars.statistics_260568_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim5
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim5
        : null
    );

    stateVars.dataSource_515629 = this.state.Statistics5;
    formVars.statistics_995738_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim6
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim6
        : null
    );

    stateVars.dataSource_944779 = this.state.Statistics6;
    formVars.statistics_117230_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim7
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim7
        : null
    );

    stateVars.dataSource_608660 = this.state.Statistics7;
    formVars.statistics_580388_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim8
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim8
        : null
    );

    stateVars.dataSource_237621 = this.state.Statistics8;
    formVars.statistics_637565_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim9
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim9
        : null
    );

    stateVars.dataSource_7761 = this.state.Statistics9;
    formVars.statistics_679376_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim10
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim10
        : null
    );

    stateVars.dataSource_129696 = this.state.Statistics10;
    formVars.statistics_608123_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim11
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim11
        : null
    );

    stateVars.dataSource_919835 = this.state.Statistics11;
    formVars.statistics_527186_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim12
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim12
        : null
    );

    stateVars.dataSource_167450 = this.state.Statistics12;
    formVars.statistics_136286_tab = ReactSystemFunctions.toString(
      this,
      stateVars.StatisticsHeaders?.length > 0
        ? stateVars.StatisticsHeaders[0].tanim13
        : this.state.StatisticsHeaders?.length > 0
        ? this.state.StatisticsHeaders[0].tanim13
        : null
    );

    stateVars.dataSource_971471 = this.state.Statistics13;

    stateVars.dataSource_57209 = this.state.StatisticsFitskor;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_873109_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_550018_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "1");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "344676",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_648218_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_950610_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "2");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "21799",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_386986_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_432546_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "3");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "547608",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_165685_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_454449_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "4");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "248418",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_303739_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_515629_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "5");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "788594",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_740118_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_944779_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "6");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "163174",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_329860_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_608660_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "7");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "512232",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_902739_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_237621_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "8");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "282513",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_775175_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_7761_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "9");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "244057",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_458513_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_129696_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "10");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "415440",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_748479_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_919835_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "11");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "707396",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_773566_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_167450_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "12");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "545799",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_261145_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_971471_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables("StatisticsDetay", "bilesensira", "13");
    KuikaAppManager.addToPageInputVariables(
      "StatisticsDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "159654",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  StatisticsComponent_947521_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "StatisticsFitskorDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "statistics_57209_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables(
      "StatisticsFitskorDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "statistics_429146_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Statistics",
      "StatisticsFitskorDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "493400",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [
      344676, 21799, 547608, 248418, 788594, 163174, 512232, 282513, 244057, 415440, 707396, 545799, 159654, 493400
    ] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.StatisticsPageInit();
    }
  }
}
