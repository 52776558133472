import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRandevuFromMerkez_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRandevuFromMerkez_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  DavetiyeById: any[];
  DavetiyeById_dummy: any[];
  RandevuById: any[];
  RandevuById_dummy: any[];
  MerkezById: any[];
  MerkezById_dummy: any[];
  RandevuHizmetler: any[];
  RandevuHizmetler_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp204364Enabled: "enabled" | "disabled";
}

export class RandevuFromMerkez_ScreenBase extends React.PureComponent<IRandevuFromMerkez_ScreenProps, any> {
  randevufrommerkez_502333_value_kuikaFlexGridRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "b93d9546-0da8-415f-9630-56fdddd07395",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Randevu" },
        { Id: 993642, PropertyName: "value", Value: "Merkez" },
        { Id: 667050, PropertyName: "value", Value: "Randevu almak istediğiniz hizmeti seçiniz..." },
        { Id: 502333, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 345849, PropertyName: "value", Value: "[datafield:title]" },
        { Id: 652630, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 842853, PropertyName: "value", Value: "Süre" },
        { Id: 875569, PropertyName: "value", Value: "[datafield:sure]" },
        { Id: 75989, PropertyName: "value", Value: "dk" },
        { Id: 341303, PropertyName: "label", Value: "İLERİ" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.randevufrommerkez_502333_value_kuikaFlexGridRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      DavetiyeById: [],
      RandevuById: [],
      MerkezById: [],
      RandevuHizmetler: [],
      NAVIGATE: "",
      isComp204364Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("randevufrommerkez", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.RandevuFromMerkezPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("randevufrommerkez", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("randevufrommerkez", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.RandevuFromMerkezPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      randevufrommerkez_295034_value: this.state.MerkezById?.at?.(0)?.logo ?? undefined,
      randevufrommerkez_204364_value: this.state.MerkezById?.at?.(0)?.rate ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  RandevuFromMerkezPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      davetiyeId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid,
        "Guid"
      ),
      randevuId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid,
        "Guid"
      ),
      merkezId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MerkezId ?? this.props.screenInputs.merkezid,
        "Guid"
      ),
      davetId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid,
        "Guid"
      ),
      seciliHizmetId_3: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      seciliMerkezId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MerkezId ?? this.props.screenInputs.merkezid,
        "Guid"
      ),
      seciliEgitmenId_3: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "RandevuFromMerkez/RandevuFromMerkezPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DavetiyeById = result?.data.davetiyeById;
    stateVars.RandevuById = result?.data.randevuById;
    stateVars.MerkezById = result?.data.merkezById;
    formVars.randevufrommerkez_295034_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].logo
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].logo
        : null
    );
    formVars.randevufrommerkez_295034_placeholderimage = "";
    formVars.randevufrommerkez_354991_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].unvani
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].unvani
        : null
    );
    formVars.randevufrommerkez_572231_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].acikAdres
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].acikAdres
        : null
    );
    stateVars.RandevuHizmetler = result?.data.randevuHizmetler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RandevuFromMerkezPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RandevuFromMerkezPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.randevufrommerkez_295034_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].logo
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].logo
        : null
    );

    formVars.randevufrommerkez_354991_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].unvani
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].unvani
        : null
    );

    formVars.randevufrommerkez_572231_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].acikAdres
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].acikAdres
        : null
    );

    formVars.randevufrommerkez_204364_value = ReactSystemFunctions.value(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].rate
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].rate
        : null
    );

    stateVars.dataSource_502333 = this.state.RandevuHizmetler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RandevuFromMerkezComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RandevuFromMerkezComponent_657215_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      davetId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid,
        "Guid"
      ),
      seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "randevufrommerkez_502333_value", "id"),
        "Guid"
      ),
      seciliMerkezId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MerkezId ?? this.props.screenInputs.merkezid,
        "Guid"
      ),
      seciliEgitmenId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RandevuFromMerkez/RandevuFromMerkezComponent_657215_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RandevuHizmetler = result?.data.randevuHizmetler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RandevuFromMerkezComponent_657215_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RandevuFromMerkezComponent_657215_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_502333 = this.state.RandevuHizmetler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RandevuFromMerkezComponent_341303_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "RandevuFromMerkez2",
      "DavetiyeId",
      this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuFromMerkez2",
      "RandevuId",
      this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuFromMerkez2",
      "HizmetId",
      ReactSystemFunctions.value(
        this,
        stateVars.RandevuHizmetler?.length > 0
          ? stateVars.RandevuHizmetler[0].seciliHizmetId
          : this.state.RandevuHizmetler?.length > 0
          ? this.state.RandevuHizmetler[0].seciliHizmetId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuFromMerkez2",
      "MerkezId",
      this.props.screenInputs.MerkezId ?? this.props.screenInputs.merkezid
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuFromMerkez2",
      "DanisanKisiId",
      this.props.screenInputs.DanisanKisiId ?? this.props.screenInputs.danisankisiid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.RandevuFromMerkezComponent_341303_onClick1_,
      "RandevuFromMerkez",
      "RandevuFromMerkez2",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "287620",
      null,
      "right",
      null,
      "1080px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  RandevuFromMerkezComponent_341303_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [287620] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.RandevuFromMerkezPageInit();
    }
    if (diId == 287620) {
      isErrorOccurred = await this.RandevuFromMerkezComponent_341303_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
