import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPersoncopy_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPersoncopy_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  SehirAll: any[];
  SehirAll_dummy: any[];
  IsSinifExisting: any[];
  IsSinifExisting_dummy: any[];
  KisiSelectById: any[];
  KisiSelectById_dummy: any[];
  KUserSelectAll: any[];
  KUserSelectAll_dummy: any[];
  AktifKirilim1AltKirilim2leri: any[];
  AktifKirilim1AltKirilim2leri_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  SetValueOf: any;
  SetValueOf_dummy: any;
  ProfileIdByRoleAndUser: any[];
  ProfileIdByRoleAndUser_dummy: any[];
  KurumIDbyKisiIDRole: any[];
  KurumIDbyKisiIDRole_dummy: any[];
  SaveUser: any;
  SaveUser_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
  TokenOfUser: any[];
  TokenOfUser_dummy: any[];
  BaseUrl: any[];
  BaseUrl_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  Notify: boolean;
  Notify_dummy: boolean;
  ManageIsOrtagi: number;
  ManageIsOrtagi_dummy: number;
  isComp903413Visible: "visible" | "hidden";
  isComp534531Visible: "visible" | "hidden";
  isComp732015Visible: "visible" | "hidden";
}

export class Personcopy_ScreenBase extends React.PureComponent<IPersoncopy_ScreenProps, any> {
  personcopy_15520_value_kuikaSelectBoxRef: React.RefObject<any>;
  personcopy_770374_value_kuikaDateRef: React.RefObject<any>;
  personcopy_460914_value_kuikaSelectBoxRef: React.RefObject<any>;
  personcopy_360533_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "17aeab61-768a-45a5-8f6b-5dcf581c47cc",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Kişi Formu" },
        { Id: 1919321, PropertyName: "value", Value: "Adı" },
        { Id: 2575579, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 9303234, PropertyName: "value", Value: "Soyadı" },
        { Id: 1642114, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 677202, PropertyName: "value", Value: "Biyolojik Cinsiyeti" },
        { Id: 15520, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 821510, PropertyName: "value", Value: "Doğum Tarihi" },
        { Id: 770374, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 3716883, PropertyName: "value", Value: "E-posta Adresi" },
        { Id: 7046934, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 3044861, PropertyName: "value", Value: "Telefonu" },
        { Id: 8847930, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 60672, PropertyName: "value", Value: "Şehir" },
        { Id: 460914, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 366690, PropertyName: "value", Value: "Okul Numarası" },
        { Id: 385822, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 360533, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 315533, PropertyName: "value", Value: "Kullanıcı Oluştur" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";
  PersoncopyRecuringAction_15_Interval: any;

  constructor(props) {
    super(props);
    this.personcopy_15520_value_kuikaSelectBoxRef = React.createRef();
    this.personcopy_770374_value_kuikaDateRef = React.createRef();
    this.personcopy_460914_value_kuikaSelectBoxRef = React.createRef();
    this.personcopy_360533_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      SehirAll: [],
      IsSinifExisting: [],
      KisiSelectById: [],
      KUserSelectAll: [],
      AktifKirilim1AltKirilim2leri: [],
      IfThenElse: false,
      SetValueOf: "",
      ChangeVisibilityOf: "",
      ProfileIdByRoleAndUser: [],
      KurumIDbyKisiIDRole: [],
      SaveRecord: false,
      Notify: false,
      ManageIsOrtagi: 0,
      dataSource_15520: [
        { key: "K", text: "Kadın/Kız" },
        { key: "E", text: "Erkek" }
      ],
      isComp903413Visible: "hidden",
      isComp534531Visible: "hidden",
      isComp732015Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("personcopy", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.PersoncopyPageInit();
    }

    if (false) {
      if (true) {
        await this.PersoncopyRecuringAction_15_();
      }
      this.PersoncopyRecuringAction_15_Interval = setInterval(async () => {
        await this.PersoncopyRecuringAction_15_();
      }, 15000);
    }

    KuikaAppManager.calculateAndSetBodyHeight("personcopy", "");
  }

  componentWillUnmount() {
    if (this.PersoncopyRecuringAction_15_Interval) {
      clearInterval(this.PersoncopyRecuringAction_15_Interval);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("personcopy", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.PersoncopyPageInit();
    }

    if (prevState.isPageInitLoaded !== this.state.isPageInitLoaded && this.state.isPageInitLoaded === true) {
      if (true && true) {
        await this.PersoncopyRecuringAction_15_();
      }
      this.PersoncopyRecuringAction_15_Interval = setInterval(async () => {
        await this.PersoncopyRecuringAction_15_();
      }, 15000);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      personcopy_2575579_value: this.state.KisiSelectById?.at?.(0)?.adi ?? undefined,
      personcopy_1642114_value: this.state.KisiSelectById?.at?.(0)?.soyadi ?? undefined,
      personcopy_15520_value: this.state.KisiSelectById?.at?.(0)?.cinsiyet ?? undefined,
      personcopy_770374_value: this.state.KisiSelectById?.at?.(0)?.dogumTarihi ?? undefined,
      personcopy_7046934_value: this.state.KisiSelectById?.at?.(0)?.eposta ?? undefined,
      personcopy_8847930_value: this.state.KisiSelectById?.at?.(0)?.telefon ?? undefined,
      personcopy_460914_value: this.state.KisiSelectById?.at?.(0)?.sehirId ?? undefined,
      personcopy_385822_value: this.state.KisiSelectById?.at?.(0)?.okulNumarasi ?? undefined,
      personcopy_360533_value: this.state.KisiSelectById?.at?.(0)?.mevcutKirilim2Id ?? undefined,
      personcopy_409657_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  PersoncopyPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      rolename_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kisiId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Personcopy/PersoncopyPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.personcopy_519225_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );
    stateVars.SehirAll = result?.data.sehirAll;

    formVars.personcopy_460914_value =
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].sehirId
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].sehirId
        : null;
    formVars.personcopy_460914_options = stateVars.SehirAll;
    stateVars.IsSinifExisting = result?.data.isSinifExisting;
    stateVars.isComp534531Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.IsSinifExisting?.length > 0
            ? stateVars.IsSinifExisting[0].isSinifExisting
            : this.state.IsSinifExisting?.length > 0
            ? this.state.IsSinifExisting[0].isSinifExisting
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KisiSelectById = result?.data.kisiSelectById;
    formVars.personcopy_2575579_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );
    formVars.personcopy_1642114_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );
    formVars.personcopy_15520_value =
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].cinsiyet
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].cinsiyet
        : null;
    formVars.personcopy_770374_value = ReactSystemFunctions.value(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].dogumTarihi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].dogumTarihi
        : null
    );
    formVars.personcopy_7046934_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].eposta
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].eposta
        : null
    );
    formVars.personcopy_8847930_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].telefon
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].telefon
        : null
    );
    formVars.personcopy_460914_value =
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].sehirId
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].sehirId
        : null;
    formVars.personcopy_460914_options = stateVars.SehirAll;
    formVars.personcopy_385822_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].okulNumarasi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].okulNumarasi
        : null
    );
    formVars.personcopy_360533_value =
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].mevcutKirilim2Id
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].mevcutKirilim2Id
        : null;
    formVars.personcopy_360533_options = stateVars.AktifKirilim1AltKirilim2leri;
    stateVars.isComp732015Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].id
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KUserSelectAll = result?.data.kUserSelectAll;
    stateVars.AktifKirilim1AltKirilim2leri = result?.data.aktifKirilim1AltKirilim2leri;

    formVars.personcopy_360533_value =
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].mevcutKirilim2Id
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].mevcutKirilim2Id
        : null;
    formVars.personcopy_360533_options = stateVars.AktifKirilim1AltKirilim2leri;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersoncopyPageInit_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp903413Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  PersoncopyPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client")) {
      isErrorOccurred = await this.PersoncopyPageInit_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersoncopyPageInit_if2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp534531Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  PersoncopyPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client")) {
      isErrorOccurred = await this.PersoncopyPageInit_if2();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersoncopyPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.personcopy_2575579_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );

    formVars.personcopy_1642114_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );

    formVars.personcopy_15520_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].cinsiyet
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].cinsiyet
        : null
    );

    formVars.personcopy_770374_value = ReactSystemFunctions.value(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].dogumTarihi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].dogumTarihi
        : null
    );

    formVars.personcopy_7046934_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].eposta
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].eposta
        : null
    );

    formVars.personcopy_8847930_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].telefon
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].telefon
        : null
    );

    formVars.personcopy_460914_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].sehirId
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].sehirId
        : null
    );

    stateVars.dataSource_460914 = this.state.SehirAll;
    stateVars.dataSource_460914 = this.state.SehirAll;
    formVars.personcopy_385822_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].okulNumarasi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].okulNumarasi
        : null
    );

    stateVars.isComp534531Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.IsSinifExisting?.length > 0
            ? stateVars.IsSinifExisting[0].isSinifExisting
            : this.state.IsSinifExisting?.length > 0
            ? this.state.IsSinifExisting[0].isSinifExisting
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcopy_519225_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );

    formVars.personcopy_360533_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].mevcutKirilim2Id
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].mevcutKirilim2Id
        : null
    );

    stateVars.dataSource_360533 = this.state.AktifKirilim1AltKirilim2leri;
    stateVars.dataSource_360533 = this.state.AktifKirilim1AltKirilim2leri;
    stateVars.isComp732015Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].id
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  PersoncopyComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersoncopyComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      role_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      KisiId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      role_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Personcopy/PersoncopyComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.personcopy_519225_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );
    stateVars.ProfileIdByRoleAndUser = result?.data.profileIdByRoleAndUser;
    stateVars.KurumIDbyKisiIDRole = result?.data.kurumIDbyKisiIDRole;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersoncopyComponent_9544_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_7046934_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_7046934_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personcopy_409657_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_2575579_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_2575579_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_1642114_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_1642114_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_7046934_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_2575579_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_1642114_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("94593018-6663-47a8-b1c3-9e3cff619781", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      twoFactorAuthenticationType_0: ReactSystemFunctions.convertToTypeByName("", "number"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      templateId_4: ReactSystemFunctions.convertToTypeByName("77e1b837-fbfa-41b4-b5a3-baf6dcff9dfb", "string"),
      receivers_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_7046934_value", "value", "", "", "")
        ),
        "string"
      ),
      subject_4: ReactSystemFunctions.convertToTypeByName("MeMap Platformuna Davet Edildiniz", "string"),
      sender_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_4: ReactSystemFunctions.convertToTypeByName("", "string"),
      memap_4: ReactSystemFunctions.convertToTypeByName("MeMap", "string"),
      language_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lang
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lang
            : null
        ),
        "string"
      ),
      providerEmail_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_7046934_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Personcopy/PersoncopyComponent_9544_onClick_if1", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveUser = result?.data.saveUser;
    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.TokenOfUser = result?.data.tokenOfUser;
    stateVars.BaseUrl = result?.data.baseUrl;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyComponent_9544_onClick_if11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersoncopyComponent_9544_onClick_if11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "00389d4c_8a20_2af3_be85_5dbfec1daf85_notify",
        this.defaultML,
        "kullaniciifelseilksonra"
      ),
      "default",
      "bottom-right",
      0,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyComponent_9544_onClick_if12_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersoncopyComponent_9544_onClick_if12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_2575579_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_2575579_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_7046934_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_7046934_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personcopy_409657_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_1642114_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_1642114_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_15520_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_15520_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_360533_value", "value", "AktifKirilim1AltKirilim2leri", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_360533_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.IsSinifExisting?.length > 0
            ? stateVars.IsSinifExisting[0].isSinifExisting
            : this.state.IsSinifExisting?.length > 0
            ? this.state.IsSinifExisting[0].isSinifExisting
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_460914_value", "value", "SehirAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_460914_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_385822_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_385822_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_2575579_value", "value", "", "", "")
        ),
        "string"
      ),
      Eposta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_7046934_value", "value", "", "", "")
        ),
        "string"
      ),
      Soyadi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_1642114_value", "value", "", "", "")
        ),
        "string"
      ),
      Telefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_8847930_value", "value", "", "", "")
        ),
        "string"
      ),
      Cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_15520_value", "value", "", "key", "")
        ),
        "string"
      ),
      DogumTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personcopy_770374_value", "value", "", "", "")
        ),
        "Date"
      ),
      MevcutKirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcopy_360533_value",
            "value",
            "AktifKirilim1AltKirilim2leri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SehirId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personcopy_460914_value", "value", "SehirAll", "id", "id")
        ),
        "Guid"
      ),
      CreatedByProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      OkulNumarasi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_385822_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.SaveUser?.id),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Personcopy/PersoncopyComponent_9544_onClick_if12_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  PersoncopyComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personcopy_409657_value", "value", "", "", "")
        ),
        true
      )
    ) {
      isErrorOccurred = await this.PersoncopyComponent_9544_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyComponent_9544_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersoncopyComponent_9544_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_2575579_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_2575579_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_7046934_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_7046934_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personcopy_409657_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_1642114_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_1642114_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_15520_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_15520_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_360533_value", "value", "AktifKirilim1AltKirilim2leri", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_360533_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.IsSinifExisting?.length > 0
            ? stateVars.IsSinifExisting[0].isSinifExisting
            : this.state.IsSinifExisting?.length > 0
            ? this.state.IsSinifExisting[0].isSinifExisting
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_460914_value", "value", "SehirAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_460914_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_385822_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "personcopy_385822_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_2575579_value", "value", "", "", "")
        ),
        "string"
      ),
      Eposta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_7046934_value", "value", "", "", "")
        ),
        "string"
      ),
      Soyadi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_1642114_value", "value", "", "", "")
        ),
        "string"
      ),
      Telefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_8847930_value", "value", "", "", "")
        ),
        "string"
      ),
      Cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_15520_value", "value", "", "key", "")
        ),
        "string"
      ),
      DogumTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personcopy_770374_value", "value", "", "", "")
        ),
        "Date"
      ),
      MevcutKirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcopy_360533_value",
            "value",
            "AktifKirilim1AltKirilim2leri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      SehirId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personcopy_460914_value", "value", "SehirAll", "id", "id")
        ),
        "Guid"
      ),
      CreatedByProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      OkulNumarasi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcopy_385822_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Personcopy/PersoncopyComponent_9544_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_3;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyComponent_9544_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersoncopyComponent_9544_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "bd145e34_0137_2096_0d82_115145c91127_notify",
        this.defaultML,
        "saverecordkisisonra"
      ),
      "default",
      "bottom-right",
      0,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyComponent_9544_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersoncopyComponent_9544_onClick_if4 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      KurumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid,
        "Guid"
      ),
      Rolename_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      Kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Personcopy/PersoncopyComponent_9544_onClick_if4", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_4;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyComponent_9544_onClick_if41_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersoncopyComponent_9544_onClick_if41_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "8d3d2409_e4f1_cef6_4d48_1bd06350525e_notify",
        this.defaultML,
        "notifyprofilifelsilk"
      ),
      "default",
      "bottom-right",
      0,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  PersoncopyComponent_9544_onClick_else4 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfileIdByRoleAndUser?.length > 0
            ? stateVars.ProfileIdByRoleAndUser[0].profilId
            : this.state.ProfileIdByRoleAndUser?.length > 0
            ? this.state.ProfileIdByRoleAndUser[0].profilId
            : null
        ),
        "Guid"
      ),
      KurumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid,
        "Guid"
      ),
      Rolename_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      Kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Personcopy/PersoncopyComponent_9544_onClick_else4", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_5;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyComponent_9544_onClick_else41_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersoncopyComponent_9544_onClick_else41_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "09020044_d3c3_b601_70c5_154c9a164589_notify",
        this.defaultML,
        "notifyprofilifelsonra"
      ),
      "default",
      "bottom-right",
      0,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  PersoncopyComponent_9544_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfileIdByRoleAndUser?.length > 0
            ? stateVars.ProfileIdByRoleAndUser[0].profilId
            : this.state.ProfileIdByRoleAndUser?.length > 0
            ? this.state.ProfileIdByRoleAndUser[0].profilId
            : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.PersoncopyComponent_9544_onClick_if4();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.PersoncopyComponent_9544_onClick_else4();
      if (isErrorOccurred) return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "06c5c5af_ceb9_ab33_b433_9146476c6ffe_notify",
        this.defaultML,
        "notifyprofilifelsesonra"
      ),
      "default",
      "bottom-right",
      0,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyComponent_9544_onClick5_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersoncopyComponent_9544_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Personcopy/PersoncopyComponent_9544_onClick5_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ManageIsOrtagi = result?.data.manageIsOrtagi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersoncopyComponent_9544_onClick6_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersoncopyComponent_9544_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);
    formVars.personcopy_519225_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersoncopyRecuringAction_15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id, null)
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "personcopy_360533_value",
        this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id,
        null,
        "Id"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersoncopyRecuringAction_15_1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersoncopyRecuringAction_15_1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  PersoncopyRecuringAction_15_1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id, null)
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp534531Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
