import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IParQFormcopy_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IParQFormcopy_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  KisiSelectById: any[];
  KisiSelectById_dummy: any[];
  FormParQById: any[];
  FormParQById_dummy: any[];
  FormParQSoru3ById: any[];
  FormParQSoru3ById_dummy: any[];
  FormParQSoru6ById: any[];
  FormParQSoru6ById_dummy: any[];
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  GoBack: any;
  GoBack_dummy: any;
  SetValueOf: any;
  SetValueOf_dummy: any;
  ToggleVisibilityOf: string;
  ToggleVisibilityOf_dummy: string;
  Divide: number;
  Divide_dummy: number;
  Sum: number;
  Sum_dummy: number;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  FormParQBelirtiSemptom: any[];
  FormParQBelirtiSemptom_dummy: any[];
  FormParQSonuc: any[];
  FormParQSonuc_dummy: any[];
  FormParq1Validasyon: any[];
  FormParq1Validasyon_dummy: any[];
  FormParQ1x3Validasyon: any[];
  FormParQ1x3Validasyon_dummy: any[];
  FormParQSoru3Validasyon: any[];
  FormParQSoru3Validasyon_dummy: any[];
  FormaParQBabaValidasyon: any[];
  FormaParQBabaValidasyon_dummy: any[];
  FormParQAnneValidasyon: any[];
  FormParQAnneValidasyon_dummy: any[];
  FormParQ5Validasyon: any[];
  FormParQ5Validasyon_dummy: any[];
  FormParQ6Validasyon: any[];
  FormParQ6Validasyon_dummy: any[];
  FormParQ6x1Validasyon: any[];
  FormParQ6x1Validasyon_dummy: any[];
  FormParQ6x2Validasyon: any[];
  FormParQ6x2Validasyon_dummy: any[];
  FormParQ6x3Validasyon: any[];
  FormParQ6x3Validasyon_dummy: any[];
  FormParQ6x4Validasyon: any[];
  FormParQ6x4Validasyon_dummy: any[];
  FormParQ7Validasyon: any[];
  FormParQ7Validasyon_dummy: any[];
  FormParQSave: number;
  FormParQSave_dummy: number;
  FormParQSoru3Save: number;
  FormParQSoru3Save_dummy: number;
  FormParQSoru6Save: number;
  FormParQSoru6Save_dummy: number;
  isComp26011Visible: "visible" | "hidden";
  isComp329331Visible: "visible" | "hidden";
  isComp851647Visible: "visible" | "hidden";
  isComp319278Visible: "visible" | "hidden";
  isComp127283Visible: "visible" | "hidden";
  isComp80854Visible: "visible" | "hidden";
  isComp217409Visible: "visible" | "hidden";
  isComp210725Visible: "visible" | "hidden";
  isComp673097Visible: "visible" | "hidden";
  isComp23734Visible: "visible" | "hidden";
  isComp707761Visible: "visible" | "hidden";
  isComp177991Visible: "visible" | "hidden";
  isComp911981Visible: "visible" | "hidden";
  isComp710369Visible: "visible" | "hidden";
  isComp714589Visible: "visible" | "hidden";
  isComp118539Visible: "visible" | "hidden";
  isComp696700Visible: "visible" | "hidden";
  isComp821128Visible: "visible" | "hidden";
  isComp532130Visible: "visible" | "hidden";
  isComp882479Visible: "visible" | "hidden";
  isComp503462Visible: "visible" | "hidden";
  isComp311406Visible: "visible" | "hidden";
  isComp761377Visible: "visible" | "hidden";
  isComp50002Visible: "visible" | "hidden";
  isComp629359Visible: "visible" | "hidden";
  isComp449366Visible: "visible" | "hidden";
  isComp273435Visible: "visible" | "hidden";
  isComp688878Visible: "visible" | "hidden";
  isComp494450Visible: "visible" | "hidden";
  isComp129797Visible: "visible" | "hidden";
  isComp164785Visible: "visible" | "hidden";
  isComp499458Visible: "visible" | "hidden";
  isComp629321Visible: "visible" | "hidden";
  isComp908768Visible: "visible" | "hidden";
  isComp661928Visible: "visible" | "hidden";
  isComp33607Visible: "visible" | "hidden";
  isComp388124Visible: "visible" | "hidden";
  isComp215453Visible: "visible" | "hidden";
  isComp311202Visible: "visible" | "hidden";
  isComp389701Visible: "visible" | "hidden";
  isComp346751Visible: "visible" | "hidden";
  isComp950581Visible: "visible" | "hidden";
  isComp671904Visible: "visible" | "hidden";
  isComp189397Visible: "visible" | "hidden";
  isComp468286Visible: "visible" | "hidden";
  isComp943000Visible: "visible" | "hidden";
  isComp165687Visible: "visible" | "hidden";
  isComp768789Visible: "visible" | "hidden";
  isComp839743Visible: "visible" | "hidden";
  isComp74150Visible: "visible" | "hidden";
  isComp518528Visible: "visible" | "hidden";
  isComp949743Visible: "visible" | "hidden";
  isComp337464Enabled: "enabled" | "disabled";
}

export class ParQFormcopy_ScreenBase extends React.PureComponent<IParQFormcopy_ScreenProps, any> {
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "f0891c94-d241-4cf7-adec-80d28cae8e09",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 975017, PropertyName: "value", Value: "Sağlık Kontrolü" },
        { Id: 686570, PropertyName: "value", Value: "(" },
        { Id: 366411, PropertyName: "value", Value: ")" },
        {
          Id: 123660,
          PropertyName: "value",
          Value:
            "Egzersiz öncesi katılım taraması; egzersiz öncesi katılım değerlendirmesi; Egzersize Hazır Olma Değerlendirmesi"
        },
        {
          Id: 629566,
          PropertyName: "value",
          Value:
            "Eğer yaşınız 18-65 arasında ise uluslararası  kabul gören Fiziksel Aktiveiteye Hazır Olma Anketi fiziksel aktiviteye başlamadan önce hekim kontrolüne gerek olup olmadığını söylecektir. Eğer yaşınız 65'in üzerinde ise ve daha önce aktif değildiyseniz, mutlaka spor hekiminden onam formu almanız gerekmektedir."
        },
        {
          Id: 437761,
          PropertyName: "value",
          Value: "1. Son 3 aydaki fiziksel aktivite seviyenizi en iyi tarif eden hangisidir?"
        },
        { Id: 762231, PropertyName: "value", Value: "Egzersiz yapmıyorum, hareketli sayılmam." },
        { Id: 577778, PropertyName: "value", Value: "Egzersiz yapmıyorum ama hareketli sayılırım." },
        { Id: 399056, PropertyName: "value", Value: "Egzersiz yapıyorum." },
        { Id: 694750, PropertyName: "value", Value: "2-3 gün/hafta" },
        { Id: 149168, PropertyName: "value", Value: "4-5 gün/hafta" },
        { Id: 654962, PropertyName: "value", Value: "6-7 gün/hafta" },
        {
          Id: 56739,
          PropertyName: "value",
          Value:
            "2. Kardiyovasküler, Metabolik ve/veya Böbrek hastalığı varlığına işaret edebillecek belirti ve semptomlar"
        },
        {
          Id: 190181,
          PropertyName: "value",
          Value: "1. Dinlenme halinde veya fiziksel aktivite sırasında göğüs ağrısı tecrübe ediyor musunuz?"
        },
        { Id: 314974, PropertyName: "value", Value: "Hayır" },
        { Id: 874284, PropertyName: "value", Value: "Evet" },
        {
          Id: 585217,
          PropertyName: "value",
          Value:
            "2. Kısa yürüyüşlerinizde bile özellikle alt bacaklarınızda yanma veya kramp hissini tecrübe ediyor musunuz?"
        },
        { Id: 78235, PropertyName: "value", Value: "Hayır" },
        { Id: 160505, PropertyName: "value", Value: "Evet" },
        {
          Id: 456374,
          PropertyName: "value",
          Value: "3. Dinlenme esnasında veya düşük bir eforda nefes nefese kalır mısınız?"
        },
        { Id: 93599, PropertyName: "value", Value: "Hayır" },
        { Id: 541327, PropertyName: "value", Value: "Evet" },
        {
          Id: 663262,
          PropertyName: "value",
          Value: "4. Sizi rahatsız edecek  şekilde güçlü, hızlı, düzensiz kalp atışları fark ediyor musunuz?"
        },
        { Id: 399875, PropertyName: "value", Value: "Hayır" },
        { Id: 312892, PropertyName: "value", Value: "Evet" },
        {
          Id: 930564,
          PropertyName: "value",
          Value: "5. Genellikle geceleri ortaya çıkan nefes darlığı veya öksürük krizleri yaşar mısınız?"
        },
        { Id: 926467, PropertyName: "value", Value: "Hayır" },
        { Id: 214057, PropertyName: "value", Value: "Evet" },
        {
          Id: 571342,
          PropertyName: "value",
          Value: "6. Doktorunuz size kalbinizde her hangi bir üfürüm olduğunu söylemiş miydi?"
        },
        { Id: 84587, PropertyName: "value", Value: "Hayır" },
        { Id: 400813, PropertyName: "value", Value: "Evet" },
        {
          Id: 130222,
          PropertyName: "value",
          Value:
            "7. Baş dönmesi nedeniyle dengenizi kaybeder misiniz veya bilinç kaybı (bayılma) tecrübe ediyor musunuz?"
        },
        { Id: 39079, PropertyName: "value", Value: "Hayır" },
        { Id: 291452, PropertyName: "value", Value: "Evet" },
        { Id: 162989, PropertyName: "value", Value: "8. Ayak bileklerinizde ödem tecrübe ediyor musunuz?" },
        { Id: 816598, PropertyName: "value", Value: "Hayır" },
        { Id: 238355, PropertyName: "value", Value: "Evet" },
        {
          Id: 79987,
          PropertyName: "value",
          Value:
            "3. Daha önce geçirdiğiniz veya şu anda teşhisli kardiyovasküler, metabolik yada böbrek hastalıklarından herhangi birine sahip misiniz?"
        },
        { Id: 87511, PropertyName: "value", Value: "Hayır" },
        { Id: 345973, PropertyName: "value", Value: "Evet" },
        { Id: 663005, PropertyName: "value", Value: "1. Kalp krizi - Miyokard Enfarktüs" },
        { Id: 814910, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 874384, PropertyName: "value", Value: "Hayır" },
        { Id: 840722, PropertyName: "value", Value: "Evet" },
        { Id: 724982, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 146506, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 574243, PropertyName: "value", Value: "2. Kalp ameliyatı" },
        { Id: 600933, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 338474, PropertyName: "value", Value: "Hayır" },
        { Id: 389869, PropertyName: "value", Value: "Evet" },
        { Id: 562563, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 552938, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 131686, PropertyName: "value", Value: "3. Kalp nakli" },
        { Id: 804448, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 190264, PropertyName: "value", Value: "Hayır" },
        { Id: 392655, PropertyName: "value", Value: "Evet" },
        { Id: 428617, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 441666, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 774792, PropertyName: "value", Value: "4. Kalp pili" },
        { Id: 446609, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 711068, PropertyName: "value", Value: "Hayır" },
        { Id: 173407, PropertyName: "value", Value: "Evet" },
        { Id: 940581, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 366768, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 452576, PropertyName: "value", Value: "5. Hipertansiyon" },
        { Id: 299770, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 515918, PropertyName: "value", Value: "Hayır" },
        { Id: 699533, PropertyName: "value", Value: "Evet" },
        { Id: 757058, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 394297, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 514560, PropertyName: "value", Value: "6. Anjina ve Sessiz Miyokard İskemisi" },
        { Id: 712384, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 974864, PropertyName: "value", Value: "Hayır" },
        { Id: 285189, PropertyName: "value", Value: "Evet" },
        { Id: 249272, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 503137, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 390141, PropertyName: "value", Value: "7. Kronik kalp yetmezliği" },
        { Id: 861592, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 693351, PropertyName: "value", Value: "Hayır" },
        { Id: 759387, PropertyName: "value", Value: "Evet" },
        { Id: 730058, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 875104, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 688292, PropertyName: "value", Value: "8. Tip I Diyabet" },
        { Id: 712349, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 973013, PropertyName: "value", Value: "Hayır" },
        { Id: 190773, PropertyName: "value", Value: "Evet" },
        { Id: 759525, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 202020, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 122468, PropertyName: "value", Value: "9. Tip II Diyabet" },
        { Id: 504945, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 880988, PropertyName: "value", Value: "Hayır" },
        { Id: 78660, PropertyName: "value", Value: "Evet" },
        { Id: 327153, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 410924, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 134744, PropertyName: "value", Value: "10. Obezite" },
        { Id: 218611, PropertyName: "value", Value: "Boy" },
        { Id: 35554, PropertyName: "placeholder", Value: "15" },
        { Id: 259130, PropertyName: "value", Value: "Kilo" },
        { Id: 84142, PropertyName: "placeholder", Value: "15" },
        { Id: 578084, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 582048, PropertyName: "value", Value: "Hayır" },
        { Id: 191046, PropertyName: "value", Value: "Evet" },
        { Id: 96979, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 813285, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 873189, PropertyName: "value", Value: "11. Hiperlipidemi" },
        { Id: 947118, PropertyName: "value", Value: "T.K." },
        { Id: 981775, PropertyName: "placeholder", Value: "15" },
        { Id: 585600, PropertyName: "value", Value: "HDL" },
        { Id: 138481, PropertyName: "placeholder", Value: "15" },
        { Id: 18997, PropertyName: "value", Value: "LDL" },
        { Id: 816505, PropertyName: "placeholder", Value: "15" },
        { Id: 123296, PropertyName: "value", Value: "TK/HDL Hesaplama" },
        { Id: 337464, PropertyName: "placeholder", Value: "15" },
        { Id: 842082, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 493608, PropertyName: "value", Value: "Hayır" },
        { Id: 255051, PropertyName: "value", Value: "Evet" },
        { Id: 326609, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 751235, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 303986, PropertyName: "value", Value: "12. Kronik Böbrek Hastalığı" },
        { Id: 210238, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 521051, PropertyName: "value", Value: "Hayır" },
        { Id: 357951, PropertyName: "value", Value: "Evet" },
        { Id: 191371, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 492488, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 617018, PropertyName: "value", Value: "13. Kronik Karaciğer Hastalığı" },
        { Id: 72534, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 234670, PropertyName: "value", Value: "Hayır" },
        { Id: 458336, PropertyName: "value", Value: "Evet" },
        { Id: 377463, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 56334, PropertyName: "placeholder", Value: "Type here..." },
        {
          Id: 321877,
          PropertyName: "value",
          Value:
            "4. Anneniz 65 yaş öncesinde, babanız ise 55 yaş öncesinde, kalp krizi, bypass ameliyatı ya da ani ölüm hikayesi var mı?"
        },
        { Id: 694573, PropertyName: "value", Value: "1. Anne" },
        { Id: 50042, PropertyName: "value", Value: "1. Ani Ölüm" },
        { Id: 5157, PropertyName: "value", Value: "2. Geçirilmiş bypass ameliyatı" },
        { Id: 29101, PropertyName: "value", Value: "3. Miyokard Enfarktüs" },
        { Id: 982948, PropertyName: "value", Value: "2. Baba" },
        { Id: 866182, PropertyName: "value", Value: "1. Ani Ölüm" },
        { Id: 551692, PropertyName: "value", Value: "2. Geçirilmiş bypass ameliyatı" },
        { Id: 434693, PropertyName: "value", Value: "3. Miyokard Enfarktüs" },
        { Id: 518513, PropertyName: "value", Value: "Eforlu testten hiç geçtiniz mi?" },
        { Id: 752085, PropertyName: "value", Value: "Hayır" },
        { Id: 514435, PropertyName: "value", Value: "Evet" },
        {
          Id: 477169,
          PropertyName: "value",
          Value: "Test sonucunda doktorunuz herhangi bir probleminiz olduğunu söyledi mi?"
        },
        { Id: 888772, PropertyName: "value", Value: "Hayır" },
        { Id: 421553, PropertyName: "value", Value: "Evet" },
        {
          Id: 861037,
          PropertyName: "value",
          Value: "5. Teşhis edilmiş veya egzersizle kötüleşebilecek bir kemik ya da eklem rahatsızlığını var mıdır?"
        },
        { Id: 349331, PropertyName: "value", Value: "Hayır" },
        { Id: 432985, PropertyName: "value", Value: "Evet" },
        { Id: 47450, PropertyName: "value", Value: "1. Akut Sakatlık" },
        { Id: 332615, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 103815, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 810830, PropertyName: "value", Value: "2. Kronik Sakatlık" },
        { Id: 689354, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 654161, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 783708, PropertyName: "value", Value: "3. Osteoporoz" },
        { Id: 877911, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 445930, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 363855, PropertyName: "value", Value: "4. Kronik bel ağrısı" },
        { Id: 243374, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 342330, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 58800, PropertyName: "value", Value: "5. Artrit" },
        { Id: 522812, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 339185, PropertyName: "placeholder", Value: "Type here..." },
        {
          Id: 804090,
          PropertyName: "value",
          Value: "6. Teşhis edilmiş başka herhangi bir kronik rahatsızlığınız var mıdır?"
        },
        { Id: 666375, PropertyName: "value", Value: "Hayır" },
        { Id: 79250, PropertyName: "value", Value: "Evet" },
        { Id: 171841, PropertyName: "value", Value: "1. Akciğer Hastalıkları" },
        { Id: 734857, PropertyName: "value", Value: "1. Kronik Obstrüktif Akciğer Hastalığı" },
        { Id: 735732, PropertyName: "value", Value: "2. Kronik Restriktif Akciğer Hastalığı" },
        { Id: 91016, PropertyName: "value", Value: "3. Kistik Fibrozis" },
        { Id: 130735, PropertyName: "value", Value: "4. Astım" },
        { Id: 75184, PropertyName: "value", Value: "5. Akciğer / Akciğer Kalp Transplantasyonu" },
        { Id: 271513, PropertyName: "value", Value: "2. Imunolojik Rahatsızlıklar" },
        { Id: 29143, PropertyName: "value", Value: "1. Kanser" },
        { Id: 395102, PropertyName: "value", Value: "2. AIDS" },
        { Id: 578435, PropertyName: "value", Value: "3. Kronik Yorgunluk" },
        { Id: 491809, PropertyName: "value", Value: "4. Abdominal Organ Transplantasyonu" },
        { Id: 619032, PropertyName: "value", Value: "5. Kanama ve Pıhtılaşma Bozukluğu" },
        { Id: 541070, PropertyName: "value", Value: "6. Kanama ve Pıhtılaşma Bozukluğu" },
        { Id: 481603, PropertyName: "value", Value: "7. Fibromiyalji" },
        { Id: 543653, PropertyName: "value", Value: "3. Nörolojik Hastalıklar" },
        { Id: 92447, PropertyName: "value", Value: "1. İnme ve Beyin Hasarı" },
        { Id: 154024, PropertyName: "value", Value: "2. Omurilik Felci" },
        { Id: 537637, PropertyName: "value", Value: "3. Kas Distrofisi" },
        { Id: 275155, PropertyName: "value", Value: "4. Epilepsi" },
        { Id: 916059, PropertyName: "value", Value: "5. Multiple Skleroz" },
        { Id: 24314, PropertyName: "value", Value: "6. Polio/Post Polio Sendromu" },
        { Id: 76225, PropertyName: "value", Value: "7. Amyotrofik Lateral Skleroz" },
        { Id: 611441, PropertyName: "value", Value: "8. Beyin Felci" },
        { Id: 998704, PropertyName: "value", Value: "9. Parkinson" },
        { Id: 455997, PropertyName: "value", Value: "4. Bilişsel, Psikolojik ve Duyusal Bozukluklar" },
        { Id: 427024, PropertyName: "value", Value: "1. Entelektüel Yetersizlik" },
        { Id: 592129, PropertyName: "value", Value: "2. Alzheimer Hastalığı" },
        { Id: 624899, PropertyName: "value", Value: "3. Akıl Hastalığı" },
        { Id: 634418, PropertyName: "value", Value: "4. Stres ve Anksiyete Bozukluğu" },
        { Id: 103752, PropertyName: "value", Value: "5. İşitme Engelli/İşitme Kaybı" },
        { Id: 946837, PropertyName: "value", Value: "6. Görme Bozukluğu" },
        { Id: 957270, PropertyName: "value", Value: "5. Diğer" },
        { Id: 910320, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 739962, PropertyName: "placeholder", Value: "Type here..." },
        {
          Id: 295033,
          PropertyName: "value",
          Value: "7. Şu anda yaşadığınız geçici herhangi bir özel durum var mıdır?"
        },
        { Id: 617328, PropertyName: "value", Value: "Hayır" },
        { Id: 681680, PropertyName: "value", Value: "Evet" },
        { Id: 244591, PropertyName: "value", Value: "1. Soğuk Algınlığı" },
        { Id: 330244, PropertyName: "value", Value: "2. Hamilelik" },
        { Id: 422118, PropertyName: "value", Value: "3. Diğer" },
        { Id: 652993, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 357526, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 358320, PropertyName: "value", Value: "8. Sigara içer misiniz?" },
        { Id: 553821, PropertyName: "value", Value: "Hayır" },
        { Id: 524338, PropertyName: "value", Value: "Evet" },
        { Id: 505635, PropertyName: "value", Value: "Günde" },
        { Id: 673600, PropertyName: "placeholder", Value: "15" },
        { Id: 859604, PropertyName: "value", Value: "Haftada" },
        { Id: 161683, PropertyName: "placeholder", Value: "15" },
        { Id: 354030, PropertyName: "value", Value: "Kaç senedir içiyorsunuz?" },
        { Id: 656623, PropertyName: "placeholder", Value: "15" },
        { Id: 74150, PropertyName: "label", Value: "SumEfor" },
        { Id: 518528, PropertyName: "label", Value: "Kaydet" },
        { Id: 92022, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 974898, PropertyName: "placeholder", Value: "Type here..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      KisiSelectById: [],
      FormParQById: [],
      FormParQSoru3ById: [],
      FormParQSoru6ById: [],
      TriggerEvent: "",
      GoBack: "",
      SetValueOf: "",
      ToggleVisibilityOf: "",
      Divide: 0,
      Sum: 0,
      ChangeVisibilityOf: "",
      FormParQBelirtiSemptom: [],
      FormParQSonuc: [],
      FormParq1Validasyon: [],
      FormParQ1x3Validasyon: [],
      FormParQSoru3Validasyon: [],
      FormaParQBabaValidasyon: [],
      FormParQAnneValidasyon: [],
      FormParQ5Validasyon: [],
      FormParQ6Validasyon: [],
      FormParQ6x1Validasyon: [],
      FormParQ6x2Validasyon: [],
      FormParQ6x3Validasyon: [],
      FormParQ6x4Validasyon: [],
      FormParQ7Validasyon: [],
      FormParQSave: 0,
      FormParQSoru3Save: 0,
      FormParQSoru6Save: 0,
      isComp26011Visible: "hidden",
      isComp329331Visible: "hidden",
      isComp851647Visible: "hidden",
      isComp319278Visible: "hidden",
      isComp127283Visible: "hidden",
      isComp80854Visible: "hidden",
      isComp217409Visible: "hidden",
      isComp210725Visible: "hidden",
      isComp673097Visible: "hidden",
      isComp23734Visible: "hidden",
      isComp707761Visible: "hidden",
      isComp177991Visible: "hidden",
      isComp911981Visible: "hidden",
      isComp710369Visible: "hidden",
      isComp714589Visible: "hidden",
      isComp118539Visible: "hidden",
      isComp696700Visible: "hidden",
      isComp821128Visible: "hidden",
      isComp532130Visible: "hidden",
      isComp882479Visible: "hidden",
      isComp503462Visible: "hidden",
      isComp311406Visible: "hidden",
      isComp761377Visible: "hidden",
      isComp50002Visible: "hidden",
      isComp629359Visible: "hidden",
      isComp449366Visible: "hidden",
      isComp273435Visible: "hidden",
      isComp688878Visible: "hidden",
      isComp494450Visible: "hidden",
      isComp129797Visible: "hidden",
      isComp164785Visible: "hidden",
      isComp499458Visible: "hidden",
      isComp629321Visible: "hidden",
      isComp908768Visible: "hidden",
      isComp661928Visible: "hidden",
      isComp33607Visible: "hidden",
      isComp388124Visible: "hidden",
      isComp215453Visible: "hidden",
      isComp311202Visible: "hidden",
      isComp389701Visible: "hidden",
      isComp346751Visible: "hidden",
      isComp950581Visible: "hidden",
      isComp671904Visible: "hidden",
      isComp189397Visible: "hidden",
      isComp468286Visible: "hidden",
      isComp943000Visible: "hidden",
      isComp165687Visible: "hidden",
      isComp768789Visible: "hidden",
      isComp839743Visible: "hidden",
      isComp74150Visible: "hidden",
      isComp518528Visible: "hidden",
      isComp949743Visible: "hidden",
      isComp337464Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("parqformcopy", "");
      return;
    }

    if (true) {
      await this.ParQFormcopyPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("parqformcopy", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("parqformcopy", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ParQFormcopyPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      parqformcopy_68315_value: this.state.FormParQById?.at?.(0)?.s1x1 ?? undefined,
      parqformcopy_183279_value: this.state.FormParQById?.at?.(0)?.s1x2 ?? undefined,
      parqformcopy_790913_value: this.state.FormParQById?.at?.(0)?.s1x3 ?? undefined,
      parqformcopy_43178_value: this.state.FormParQById?.at?.(0)?.s1x3x1 ?? undefined,
      parqformcopy_567403_value: this.state.FormParQById?.at?.(0)?.s1x3x2 ?? undefined,
      parqformcopy_235714_value: this.state.FormParQById?.at?.(-1)?.s1x3x3 ?? undefined,
      parqformcopy_485437_value: this.state.FormParQById?.at?.(0)?.s2x1 ?? undefined,
      parqformcopy_960012_value: this.state.FormParQById?.at?.(0)?.s2x2 ?? undefined,
      parqformcopy_158970_value: this.state.FormParQById?.at?.(0)?.s2x3 ?? undefined,
      parqformcopy_107454_value: this.state.FormParQById?.at?.(0)?.s2x4 ?? undefined,
      parqformcopy_173410_value: this.state.FormParQById?.at?.(0)?.s2x5 ?? undefined,
      parqformcopy_643202_value: this.state.FormParQById?.at?.(0)?.s2x6 ?? undefined,
      parqformcopy_538933_value: this.state.FormParQById?.at?.(0)?.s2x7 ?? undefined,
      parqformcopy_721461_value: this.state.FormParQById?.at?.(0)?.s2x8 ?? undefined,
      parqformcopy_238402_value: this.state.FormParQById?.at?.(0)?.s3 ?? undefined,
      parqformcopy_673903_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x3 ?? undefined,
      parqformcopy_63995_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x1xIlac ?? undefined,
      parqformcopy_146506_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x1x1 ?? undefined,
      parqformcopy_631046_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x3 ?? undefined,
      parqformcopy_355803_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x2xIlac ?? undefined,
      parqformcopy_552938_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x2x1 ?? undefined,
      parqformcopy_815856_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x3 ?? undefined,
      parqformcopy_827266_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x3xIlac ?? undefined,
      parqformcopy_441666_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x3x1 ?? undefined,
      parqformcopy_301053_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x4 ?? undefined,
      parqformcopy_425116_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x4xIlac ?? undefined,
      parqformcopy_366768_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x4x1 ?? undefined,
      parqformcopy_316213_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x5 ?? undefined,
      parqformcopy_360226_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x5xIlac ?? undefined,
      parqformcopy_394297_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x5x1 ?? undefined,
      parqformcopy_395667_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x6 ?? undefined,
      parqformcopy_951810_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x6xIlac ?? undefined,
      parqformcopy_503137_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x6x1 ?? undefined,
      parqformcopy_858125_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x7 ?? undefined,
      parqformcopy_2796_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x7xIlac ?? undefined,
      parqformcopy_875104_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x7x1 ?? undefined,
      parqformcopy_985460_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x8 ?? undefined,
      parqformcopy_875505_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x8xIlac ?? undefined,
      parqformcopy_202020_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x8x1 ?? undefined,
      parqformcopy_113946_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x9 ?? undefined,
      parqformcopy_47848_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x9xIlac ?? undefined,
      parqformcopy_410924_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x9x1 ?? undefined,
      parqformcopy_221532_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10 ?? undefined,
      parqformcopy_35554_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10xBoy ?? undefined,
      parqformcopy_84142_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10xKilo ?? undefined,
      parqformcopy_173392_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10xIlac ?? undefined,
      parqformcopy_813285_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10x1 ?? undefined,
      parqformcopy_245992_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11 ?? undefined,
      parqformcopy_981775_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xTK ?? undefined,
      parqformcopy_138481_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xHDL ?? undefined,
      parqformcopy_816505_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xLDL ?? undefined,
      parqformcopy_337464_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xResult ?? undefined,
      parqformcopy_421543_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xIlac ?? undefined,
      parqformcopy_751235_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11x1 ?? undefined,
      parqformcopy_137075_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x12 ?? undefined,
      parqformcopy_763635_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x12xIlac ?? undefined,
      parqformcopy_492488_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x12x1 ?? undefined,
      parqformcopy_683570_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x13 ?? undefined,
      parqformcopy_17076_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x13xIlac ?? undefined,
      parqformcopy_56334_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x13x1 ?? undefined,
      parqformcopy_485590_value: this.state.FormParQById?.at?.(0)?.s4x1 ?? undefined,
      parqformcopy_527506_value: this.state.FormParQById?.at?.(0)?.s4x1x1 ?? undefined,
      parqformcopy_139967_value: this.state.FormParQById?.at?.(0)?.s4x1x2 ?? undefined,
      parqformcopy_426278_value: this.state.FormParQById?.at?.(0)?.s4x1x3 ?? undefined,
      parqformcopy_925296_value: this.state.FormParQById?.at?.(0)?.s4x2 ?? undefined,
      parqformcopy_726714_value: this.state.FormParQById?.at?.(0)?.s4x2x1 ?? undefined,
      parqformcopy_289204_value: this.state.FormParQById?.at?.(0)?.s4x2x2 ?? undefined,
      parqformcopy_752131_value: this.state.FormParQById?.at?.(0)?.s4x2x3 ?? undefined,
      parqformcopy_966189_value: this.state.FormParQById?.at?.(0)?.s4xEfor ?? undefined,
      parqformcopy_135425_value: this.state.FormParQById?.at?.(0)?.s4xEforxProblem ?? undefined,
      parqformcopy_382369_value: this.state.FormParQById?.at?.(0)?.s5 ?? undefined,
      parqformcopy_477451_value: this.state.FormParQById?.at?.(0)?.s5X1Check ?? undefined,
      parqformcopy_103815_value: this.state.FormParQById?.at?.(0)?.s5X1 ?? undefined,
      parqformcopy_75034_value: this.state.FormParQById?.at?.(0)?.s5X2Check ?? undefined,
      parqformcopy_654161_value: this.state.FormParQById?.at?.(0)?.s5x2 ?? undefined,
      parqformcopy_85355_value: this.state.FormParQById?.at?.(0)?.s5X3Check ?? undefined,
      parqformcopy_445930_value: this.state.FormParQById?.at?.(0)?.s5x3 ?? undefined,
      parqformcopy_38196_value: this.state.FormParQById?.at?.(0)?.s5X4Check ?? undefined,
      parqformcopy_342330_value: this.state.FormParQById?.at?.(0)?.s5x4 ?? undefined,
      parqformcopy_918726_value: this.state.FormParQById?.at?.(0)?.s5X5Check ?? undefined,
      parqformcopy_339185_value: this.state.FormParQById?.at?.(0)?.s5x5 ?? undefined,
      parqformcopy_930924_value: this.state.FormParQById?.at?.(0)?.s6 ?? undefined,
      parqformcopy_666388_value: false,
      parqformcopy_713132_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x1 ?? undefined,
      parqformcopy_154499_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x2 ?? undefined,
      parqformcopy_867208_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x3 ?? undefined,
      parqformcopy_622758_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x4 ?? undefined,
      parqformcopy_590152_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x5 ?? undefined,
      parqformcopy_700493_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2 ?? undefined,
      parqformcopy_528451_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x1 ?? undefined,
      parqformcopy_581396_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x2 ?? undefined,
      parqformcopy_165036_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x3 ?? undefined,
      parqformcopy_996331_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x4 ?? undefined,
      parqformcopy_761897_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x5 ?? undefined,
      parqformcopy_782070_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x6 ?? undefined,
      parqformcopy_590758_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x7 ?? undefined,
      parqformcopy_847131_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3 ?? undefined,
      parqformcopy_996970_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x1 ?? undefined,
      parqformcopy_736647_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x2 ?? undefined,
      parqformcopy_718707_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x3 ?? undefined,
      parqformcopy_285485_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x4 ?? undefined,
      parqformcopy_710319_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x5 ?? undefined,
      parqformcopy_659579_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x6 ?? undefined,
      parqformcopy_257868_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x7 ?? undefined,
      parqformcopy_560619_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x8 ?? undefined,
      parqformcopy_275784_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x9 ?? undefined,
      parqformcopy_736437_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4 ?? undefined,
      parqformcopy_883656_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x1 ?? undefined,
      parqformcopy_627907_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x2 ?? undefined,
      parqformcopy_893733_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x3 ?? undefined,
      parqformcopy_786497_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x4 ?? undefined,
      parqformcopy_365318_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x5 ?? undefined,
      parqformcopy_342249_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x6 ?? undefined,
      parqformcopy_486661_value: this.state.FormParQSoru6ById?.at?.(0)?.sx6x5Check ?? undefined,
      parqformcopy_739962_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x5 ?? undefined,
      parqformcopy_926688_value: this.state.FormParQById?.at?.(0)?.s7 ?? undefined,
      parqformcopy_74533_value: this.state.FormParQById?.at?.(0)?.s7x1 ?? undefined,
      parqformcopy_740586_value: this.state.FormParQById?.at?.(0)?.s7x2 ?? undefined,
      parqformcopy_216720_value: this.state.FormParQById?.at?.(0)?.s7x3xCheck ?? undefined,
      parqformcopy_357526_value: this.state.FormParQById?.at?.(0)?.s7x3 ?? undefined,
      parqformcopy_413414_value: this.state.FormParQById?.at?.(0)?.s8 ?? undefined,
      parqformcopy_673600_value: this.state.FormParQById?.at?.(0)?.s8xGunde ?? undefined,
      parqformcopy_161683_value: this.state.FormParQById?.at?.(0)?.s8xHaftada ?? undefined,
      parqformcopy_656623_value: this.state.FormParQById?.at?.(0)?.s8xKacSene ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ParQFormcopyPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FormId ?? this.props.screenInputs.formid,
        "Guid"
      ),
      FormId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FormId ?? this.props.screenInputs.formid,
        "Guid"
      ),
      FormId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FormId ?? this.props.screenInputs.formid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ParQFormcopy/ParQFormcopyPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.KisiSelectById = result?.data.kisiSelectById;
    formVars.parqformcopy_791824_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );
    formVars.parqformcopy_735964_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );
    formVars.parqformcopy_676715_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].yas
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].yas
        : null
    );
    stateVars.isComp768789Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "E"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp839743Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "K"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.FormParQById = result?.data.formParQById;
    formVars.parqformcopy_68315_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x1
        : null
    );
    formVars.parqformcopy_183279_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x2
        : null
    );
    formVars.parqformcopy_790913_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x3
        : null
    );
    formVars.parqformcopy_43178_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x3x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x3x1
        : null
    );
    formVars.parqformcopy_567403_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x3x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x3x2
        : null
    );
    formVars.parqformcopy_235714_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[stateVars.FormParQById.length - 1].s1x3x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[this.state.FormParQById.length - 1].s1x3x3
        : null
    );
    formVars.parqformcopy_485437_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x1
        : null
    );
    formVars.parqformcopy_960012_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x2
        : null
    );
    formVars.parqformcopy_158970_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x3
        : null
    );
    formVars.parqformcopy_107454_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x4
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x4
        : null
    );
    formVars.parqformcopy_173410_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x5
        : null
    );
    formVars.parqformcopy_643202_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x6
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x6
        : null
    );
    formVars.parqformcopy_538933_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x7
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x7
        : null
    );
    formVars.parqformcopy_721461_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x8
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x8
        : null
    );
    formVars.parqformcopy_238402_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s3
        : null
    );
    formVars.parqformcopy_485590_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1
        : null
    );
    formVars.parqformcopy_527506_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1x1
        : null
    );
    formVars.parqformcopy_139967_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1x2
        : null
    );
    formVars.parqformcopy_426278_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1x3
        : null
    );
    formVars.parqformcopy_925296_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2
        : null
    );
    formVars.parqformcopy_726714_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2x1
        : null
    );
    formVars.parqformcopy_289204_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2x2
        : null
    );
    formVars.parqformcopy_752131_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2x3
        : null
    );
    formVars.parqformcopy_966189_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4xEfor
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4xEfor
        : null
    );
    formVars.parqformcopy_135425_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4xEforxProblem
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4xEforxProblem
        : null
    );
    formVars.parqformcopy_382369_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5
        : null
    );
    formVars.parqformcopy_477451_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X1Check
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X1Check
        : null
    );
    formVars.parqformcopy_103815_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X1
        : null
    );
    formVars.parqformcopy_75034_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X2Check
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X2Check
        : null
    );
    formVars.parqformcopy_654161_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x2
        : null
    );
    formVars.parqformcopy_85355_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X3Check
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X3Check
        : null
    );
    formVars.parqformcopy_445930_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x3
        : null
    );
    formVars.parqformcopy_38196_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X4Check
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X4Check
        : null
    );
    formVars.parqformcopy_342330_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x4
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x4
        : null
    );
    formVars.parqformcopy_918726_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X5Check
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X5Check
        : null
    );
    formVars.parqformcopy_339185_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x5
        : null
    );
    formVars.parqformcopy_930924_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s6
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s6
        : null
    );
    formVars.parqformcopy_926688_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7
        : null
    );
    formVars.parqformcopy_74533_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x1
        : null
    );
    formVars.parqformcopy_740586_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x2
        : null
    );
    formVars.parqformcopy_216720_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x3xCheck
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x3xCheck
        : null
    );
    formVars.parqformcopy_357526_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x3
        : null
    );
    formVars.parqformcopy_413414_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8
        : null
    );
    formVars.parqformcopy_673600_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8xGunde
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8xGunde
        : null
    );
    formVars.parqformcopy_161683_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8xHaftada
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8xHaftada
        : null
    );
    formVars.parqformcopy_656623_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8xKacSene
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8xKacSene
        : null
    );
    stateVars.isComp329331Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQById?.length > 0
            ? stateVars.FormParQById[0].s3
            : this.state.FormParQById?.length > 0
            ? this.state.FormParQById[0].s3
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp499458Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQById?.length > 0
            ? stateVars.FormParQById[0].s5
            : this.state.FormParQById?.length > 0
            ? this.state.FormParQById[0].s5
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215453Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQById?.length > 0
            ? stateVars.FormParQById[0].s6
            : this.state.FormParQById?.length > 0
            ? this.state.FormParQById[0].s6
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp189397Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQById?.length > 0
            ? stateVars.FormParQById[0].s7
            : this.state.FormParQById?.length > 0
            ? this.state.FormParQById[0].s7
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943000Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQById?.length > 0
            ? stateVars.FormParQById[0].s8
            : this.state.FormParQById?.length > 0
            ? this.state.FormParQById[0].s8
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.FormParQSoru3ById = result?.data.formParQSoru3ById;
    formVars.parqformcopy_673903_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3
        : null
    );
    formVars.parqformcopy_63995_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x1xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x1xIlac
        : null
    );
    formVars.parqformcopy_146506_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x1x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x1x1
        : null
    );
    formVars.parqformcopy_631046_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3
        : null
    );
    formVars.parqformcopy_355803_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x2xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x2xIlac
        : null
    );
    formVars.parqformcopy_552938_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x2x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x2x1
        : null
    );
    formVars.parqformcopy_815856_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3
        : null
    );
    formVars.parqformcopy_827266_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3xIlac
        : null
    );
    formVars.parqformcopy_441666_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3x1
        : null
    );
    formVars.parqformcopy_301053_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x4
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x4
        : null
    );
    formVars.parqformcopy_425116_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x4xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x4xIlac
        : null
    );
    formVars.parqformcopy_366768_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x4x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x4x1
        : null
    );
    formVars.parqformcopy_316213_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x5
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x5
        : null
    );
    formVars.parqformcopy_360226_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x5xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x5xIlac
        : null
    );
    formVars.parqformcopy_394297_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x5x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x5x1
        : null
    );
    formVars.parqformcopy_395667_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x6
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x6
        : null
    );
    formVars.parqformcopy_951810_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x6xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x6xIlac
        : null
    );
    formVars.parqformcopy_503137_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x6x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x6x1
        : null
    );
    formVars.parqformcopy_858125_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x7
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x7
        : null
    );
    formVars.parqformcopy_2796_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x7xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x7xIlac
        : null
    );
    formVars.parqformcopy_875104_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x7x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x7x1
        : null
    );
    formVars.parqformcopy_985460_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x8
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x8
        : null
    );
    formVars.parqformcopy_875505_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x8xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x8xIlac
        : null
    );
    formVars.parqformcopy_202020_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x8x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x8x1
        : null
    );
    formVars.parqformcopy_113946_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x9
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x9
        : null
    );
    formVars.parqformcopy_47848_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x9xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x9xIlac
        : null
    );
    formVars.parqformcopy_410924_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x9x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x9x1
        : null
    );
    formVars.parqformcopy_221532_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10
        : null
    );
    formVars.parqformcopy_35554_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10xBoy
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10xBoy
        : null
    );
    formVars.parqformcopy_84142_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10xKilo
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10xKilo
        : null
    );
    formVars.parqformcopy_173392_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10xIlac
        : null
    );
    formVars.parqformcopy_813285_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10x1
        : null
    );
    formVars.parqformcopy_245992_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11
        : null
    );
    formVars.parqformcopy_981775_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xTK
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xTK
        : null
    );
    formVars.parqformcopy_138481_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xHDL
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xHDL
        : null
    );
    formVars.parqformcopy_816505_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xLDL
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xLDL
        : null
    );
    formVars.parqformcopy_337464_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xResult
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xResult
        : null
    );
    formVars.parqformcopy_421543_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xIlac
        : null
    );
    formVars.parqformcopy_751235_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11x1
        : null
    );
    formVars.parqformcopy_137075_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x12
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x12
        : null
    );
    formVars.parqformcopy_763635_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x12xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x12xIlac
        : null
    );
    formVars.parqformcopy_492488_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x12x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x12x1
        : null
    );
    formVars.parqformcopy_683570_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x13
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x13
        : null
    );
    formVars.parqformcopy_17076_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x13xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x13xIlac
        : null
    );
    formVars.parqformcopy_56334_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x13x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x13x1
        : null
    );
    stateVars.FormParQSoru6ById = result?.data.formParQSoru6ById;
    formVars.parqformcopy_713132_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x1
        : null
    );
    formVars.parqformcopy_154499_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x2
        : null
    );
    formVars.parqformcopy_867208_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x3
        : null
    );
    formVars.parqformcopy_622758_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x4
        : null
    );
    formVars.parqformcopy_590152_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x5
        : null
    );
    formVars.parqformcopy_700493_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2
        : null
    );
    formVars.parqformcopy_528451_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x1
        : null
    );
    formVars.parqformcopy_581396_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x2
        : null
    );
    formVars.parqformcopy_165036_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x3
        : null
    );
    formVars.parqformcopy_996331_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x4
        : null
    );
    formVars.parqformcopy_761897_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x5
        : null
    );
    formVars.parqformcopy_782070_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x6
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x6
        : null
    );
    formVars.parqformcopy_590758_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x7
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x7
        : null
    );
    formVars.parqformcopy_847131_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3
        : null
    );
    formVars.parqformcopy_996970_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x1
        : null
    );
    formVars.parqformcopy_736647_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x2
        : null
    );
    formVars.parqformcopy_718707_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x3
        : null
    );
    formVars.parqformcopy_285485_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x4
        : null
    );
    formVars.parqformcopy_710319_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x5
        : null
    );
    formVars.parqformcopy_659579_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x6
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x6
        : null
    );
    formVars.parqformcopy_257868_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x7
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x7
        : null
    );
    formVars.parqformcopy_560619_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x8
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x8
        : null
    );
    formVars.parqformcopy_275784_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x9
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x9
        : null
    );
    formVars.parqformcopy_736437_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4
        : null
    );
    formVars.parqformcopy_883656_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x1
        : null
    );
    formVars.parqformcopy_627907_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x2
        : null
    );
    formVars.parqformcopy_893733_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x3
        : null
    );
    formVars.parqformcopy_786497_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x4
        : null
    );
    formVars.parqformcopy_365318_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x5
        : null
    );
    formVars.parqformcopy_342249_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x6
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x6
        : null
    );
    formVars.parqformcopy_486661_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].sx6x5Check
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].sx6x5Check
        : null
    );
    formVars.parqformcopy_739962_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x5
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParQFormcopyPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    if (await this.ParQFormcopyComponent_74150_onClick()) return true;

    stateVars.isComp768789Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "E"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp839743Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "K"
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_791824_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );

    formVars.parqformcopy_735964_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );

    formVars.parqformcopy_676715_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].yas
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].yas
        : null
    );

    formVars.parqformcopy_68315_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x1
        : null
    );

    formVars.parqformcopy_183279_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x2
        : null
    );

    formVars.parqformcopy_790913_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x3
        : null
    );

    formVars.parqformcopy_43178_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x3x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x3x1
        : null
    );

    formVars.parqformcopy_567403_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x3x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x3x2
        : null
    );

    formVars.parqformcopy_235714_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[stateVars.FormParQById.length - 1].s1x3x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[this.state.FormParQById.length - 1].s1x3x3
        : null
    );

    formVars.parqformcopy_485437_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x1
        : null
    );

    formVars.parqformcopy_960012_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x2
        : null
    );

    formVars.parqformcopy_158970_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x3
        : null
    );

    formVars.parqformcopy_107454_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x4
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x4
        : null
    );

    formVars.parqformcopy_173410_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x5
        : null
    );

    formVars.parqformcopy_643202_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x6
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x6
        : null
    );

    formVars.parqformcopy_538933_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x7
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x7
        : null
    );

    formVars.parqformcopy_721461_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x8
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x8
        : null
    );

    formVars.parqformcopy_238402_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s3
        : null
    );

    stateVars.isComp329331Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQById?.length > 0
            ? stateVars.FormParQById[0].s3
            : this.state.FormParQById?.length > 0
            ? this.state.FormParQById[0].s3
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_673903_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3
        : null
    );

    stateVars.isComp851647Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_673903_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_63995_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x1xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x1xIlac
        : null
    );

    stateVars.isComp319278Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_63995_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_146506_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x1x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x1x1
        : null
    );

    formVars.parqformcopy_631046_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3
        : null
    );

    stateVars.isComp127283Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_631046_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_355803_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x2xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x2xIlac
        : null
    );

    stateVars.isComp80854Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_355803_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_552938_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x2x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x2x1
        : null
    );

    formVars.parqformcopy_815856_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3
        : null
    );

    stateVars.isComp217409Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_815856_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_827266_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3xIlac
        : null
    );

    stateVars.isComp210725Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_827266_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_441666_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3x1
        : null
    );

    formVars.parqformcopy_301053_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x4
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x4
        : null
    );

    stateVars.isComp673097Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_301053_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_425116_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x4xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x4xIlac
        : null
    );

    stateVars.isComp23734Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_425116_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_366768_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x4x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x4x1
        : null
    );

    formVars.parqformcopy_316213_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x5
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x5
        : null
    );

    stateVars.isComp707761Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_316213_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_360226_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x5xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x5xIlac
        : null
    );

    stateVars.isComp177991Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_360226_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_394297_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x5x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x5x1
        : null
    );

    formVars.parqformcopy_395667_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x6
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x6
        : null
    );

    stateVars.isComp911981Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_395667_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_951810_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x6xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x6xIlac
        : null
    );

    stateVars.isComp710369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_951810_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_503137_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x6x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x6x1
        : null
    );

    formVars.parqformcopy_858125_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x7
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x7
        : null
    );

    stateVars.isComp714589Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_858125_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_2796_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x7xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x7xIlac
        : null
    );

    stateVars.isComp118539Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_2796_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_875104_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x7x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x7x1
        : null
    );

    formVars.parqformcopy_985460_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x8
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x8
        : null
    );

    stateVars.isComp696700Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_985460_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_875505_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x8xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x8xIlac
        : null
    );

    stateVars.isComp821128Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_875505_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_202020_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x8x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x8x1
        : null
    );

    formVars.parqformcopy_113946_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x9
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x9
        : null
    );

    stateVars.isComp532130Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_113946_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_47848_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x9xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x9xIlac
        : null
    );

    stateVars.isComp882479Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_47848_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_410924_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x9x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x9x1
        : null
    );

    formVars.parqformcopy_221532_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10
        : null
    );

    stateVars.isComp503462Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_221532_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_35554_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10xBoy
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10xBoy
        : null
    );

    formVars.parqformcopy_84142_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10xKilo
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10xKilo
        : null
    );

    formVars.parqformcopy_173392_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10xIlac
        : null
    );

    stateVars.isComp311406Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_173392_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_813285_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10x1
        : null
    );

    formVars.parqformcopy_245992_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11
        : null
    );

    stateVars.isComp761377Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_245992_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_981775_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xTK
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xTK
        : null
    );

    formVars.parqformcopy_138481_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xHDL
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xHDL
        : null
    );

    formVars.parqformcopy_816505_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xLDL
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xLDL
        : null
    );

    formVars.parqformcopy_337464_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xResult
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xResult
        : null
    );

    formVars.parqformcopy_421543_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xIlac
        : null
    );

    stateVars.isComp50002Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_421543_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_751235_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11x1
        : null
    );

    formVars.parqformcopy_137075_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x12
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x12
        : null
    );

    stateVars.isComp629359Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_137075_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_763635_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x12xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x12xIlac
        : null
    );

    stateVars.isComp449366Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_763635_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_492488_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x12x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x12x1
        : null
    );

    formVars.parqformcopy_683570_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x13
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x13
        : null
    );

    stateVars.isComp273435Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_683570_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_17076_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x13xIlac
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x13xIlac
        : null
    );

    stateVars.isComp688878Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_17076_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_56334_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x13x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x13x1
        : null
    );

    formVars.parqformcopy_485590_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1
        : null
    );

    stateVars.isComp494450Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_485590_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_527506_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1x1
        : null
    );

    formVars.parqformcopy_139967_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1x2
        : null
    );

    formVars.parqformcopy_426278_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1x3
        : null
    );

    formVars.parqformcopy_925296_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2
        : null
    );

    stateVars.isComp129797Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_925296_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_726714_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2x1
        : null
    );

    formVars.parqformcopy_289204_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2x2
        : null
    );

    formVars.parqformcopy_752131_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2x3
        : null
    );

    formVars.parqformcopy_966189_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4xEfor
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4xEfor
        : null
    );

    stateVars.isComp164785Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_966189_value", "value", "", "", "")
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_135425_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4xEforxProblem
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4xEforxProblem
        : null
    );

    formVars.parqformcopy_382369_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5
        : null
    );

    stateVars.isComp499458Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQById?.length > 0
            ? stateVars.FormParQById[0].s5
            : this.state.FormParQById?.length > 0
            ? this.state.FormParQById[0].s5
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_477451_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X1Check
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X1Check
        : null
    );

    stateVars.isComp629321Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_477451_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_103815_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X1
        : null
    );

    formVars.parqformcopy_75034_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X2Check
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X2Check
        : null
    );

    stateVars.isComp908768Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_75034_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_654161_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x2
        : null
    );

    formVars.parqformcopy_85355_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X3Check
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X3Check
        : null
    );

    stateVars.isComp661928Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_85355_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_445930_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x3
        : null
    );

    formVars.parqformcopy_38196_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X4Check
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X4Check
        : null
    );

    stateVars.isComp33607Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_38196_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_342330_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x4
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x4
        : null
    );

    formVars.parqformcopy_918726_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X5Check
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X5Check
        : null
    );

    stateVars.isComp388124Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_918726_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_339185_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x5
        : null
    );

    formVars.parqformcopy_930924_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s6
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s6
        : null
    );

    stateVars.isComp215453Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQById?.length > 0
            ? stateVars.FormParQById[0].s6
            : this.state.FormParQById?.length > 0
            ? this.state.FormParQById[0].s6
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp311202Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_666388_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_713132_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x1
        : null
    );

    formVars.parqformcopy_154499_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x2
        : null
    );

    formVars.parqformcopy_867208_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x3
        : null
    );

    formVars.parqformcopy_622758_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x4
        : null
    );

    formVars.parqformcopy_590152_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x5
        : null
    );

    formVars.parqformcopy_700493_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2
        : null
    );

    stateVars.isComp389701Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_700493_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_528451_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x1
        : null
    );

    formVars.parqformcopy_581396_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x2
        : null
    );

    formVars.parqformcopy_165036_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x3
        : null
    );

    formVars.parqformcopy_996331_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x4
        : null
    );

    formVars.parqformcopy_761897_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x5
        : null
    );

    formVars.parqformcopy_782070_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x6
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x6
        : null
    );

    formVars.parqformcopy_590758_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x7
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x7
        : null
    );

    formVars.parqformcopy_847131_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3
        : null
    );

    stateVars.isComp346751Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_847131_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_996970_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x1
        : null
    );

    formVars.parqformcopy_736647_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x2
        : null
    );

    formVars.parqformcopy_718707_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x3
        : null
    );

    formVars.parqformcopy_285485_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x4
        : null
    );

    formVars.parqformcopy_710319_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x5
        : null
    );

    formVars.parqformcopy_659579_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x6
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x6
        : null
    );

    formVars.parqformcopy_257868_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x7
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x7
        : null
    );

    formVars.parqformcopy_560619_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x8
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x8
        : null
    );

    formVars.parqformcopy_275784_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x9
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x9
        : null
    );

    formVars.parqformcopy_736437_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4
        : null
    );

    stateVars.isComp950581Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_736437_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_883656_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x1
        : null
    );

    formVars.parqformcopy_627907_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x2
        : null
    );

    formVars.parqformcopy_893733_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x3
        : null
    );

    formVars.parqformcopy_786497_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x4
        : null
    );

    formVars.parqformcopy_365318_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x5
        : null
    );

    formVars.parqformcopy_342249_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x6
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x6
        : null
    );

    formVars.parqformcopy_486661_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].sx6x5Check
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].sx6x5Check
        : null
    );

    stateVars.isComp671904Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_486661_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_739962_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x5
        : null
    );

    formVars.parqformcopy_926688_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7
        : null
    );

    stateVars.isComp189397Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQById?.length > 0
            ? stateVars.FormParQById[0].s7
            : this.state.FormParQById?.length > 0
            ? this.state.FormParQById[0].s7
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_74533_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x1
        : null
    );

    formVars.parqformcopy_740586_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x2
        : null
    );

    formVars.parqformcopy_216720_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x3xCheck
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x3xCheck
        : null
    );

    stateVars.isComp468286Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_216720_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_357526_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x3
        : null
    );

    formVars.parqformcopy_413414_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8
        : null
    );

    stateVars.isComp943000Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQById?.length > 0
            ? stateVars.FormParQById[0].s8
            : this.state.FormParQById?.length > 0
            ? this.state.FormParQById[0].s8
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqformcopy_673600_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8xGunde
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8xGunde
        : null
    );

    formVars.parqformcopy_161683_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8xHaftada
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8xHaftada
        : null
    );

    formVars.parqformcopy_656623_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8xKacSene
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8xKacSene
        : null
    );

    stateVars.isComp518528Visible =
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.NewEditView ?? this.props.screenInputs.neweditview,
        "V"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ParQFormcopyComponent_993262_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_68315_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_183279_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_790913_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_43178_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_567403_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_235714_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_183279_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_68315_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_790913_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_43178_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_567403_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_235714_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_790913_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_68315_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_183279_value", false, null);
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp26011Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_43178_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_567403_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_235714_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_567403_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_43178_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_235714_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_235714_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_43178_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqformcopy_567403_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_238402_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp329331Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_673903_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp851647Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_63995_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp319278Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_631046_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp127283Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_355803_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp80854Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_815856_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp217409Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_827266_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp210725Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_301053_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp673097Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_425116_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp23734Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_316213_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp707761Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_360226_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp177991Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_395667_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp911981Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_951810_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp710369Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_858125_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp714589Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_2796_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp118539Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_985460_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp696700Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_875505_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp821128Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_113946_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp532130Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_47848_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp882479Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_221532_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp503462Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_173392_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp311406Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_245992_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp761377Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_981775_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_981775_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_981775_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_245992_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_138481_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_138481_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_245992_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_138481_value", "value", "", "", "")
        ),
        null
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.Divide = await ReactSystemFunctions.divide(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_981775_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_138481_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_981775_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_981775_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParQFormcopyComponent_981775_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_981775_value", "value", "", "", "")
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "parqformcopy_337464_value",
        ReactSystemFunctions.value(this, this.state.Divide),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  ParQFormcopyComponent_138481_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_981775_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_981775_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_245992_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_138481_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_138481_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_245992_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_981775_value", "value", "", "", "")
        ),
        null
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.Divide = await ReactSystemFunctions.divide(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_981775_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_138481_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_138481_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_138481_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParQFormcopyComponent_138481_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_981775_value", "value", "", "", "")
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "parqformcopy_337464_value",
        ReactSystemFunctions.value(this, this.state.Divide),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  ParQFormcopyComponent_421543_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp50002Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_137075_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp629359Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_763635_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp449366Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_683570_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp273435Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_17076_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp688878Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_485590_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp494450Visible");
    if (await this.ParQFormcopyComponent_74150_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_925296_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp129797Visible");
    if (await this.ParQFormcopyComponent_74150_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_966189_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp164785Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_382369_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp499458Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_477451_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp629321Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_75034_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp908768Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_85355_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp661928Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_38196_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp33607Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_918726_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp388124Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_930924_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp215453Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_666388_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp311202Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_700493_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp389701Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_847131_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp346751Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_736437_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp950581Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_486661_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp671904Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_926688_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp189397Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_216720_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp468286Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_413414_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp943000Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormcopyComponent_74150_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Sum = await ReactSystemFunctions.sum(
      ReactSystemFunctions.toDouble(
        this,
        ReactSystemFunctions.value(this, "parqformcopy_485590_value", "value", "", "", "")
      ),
      ReactSystemFunctions.toDouble(
        this,
        ReactSystemFunctions.value(this, "parqformcopy_925296_value", "value", "", "", "")
      )
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParQFormcopyComponent_74150_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ParQFormcopyComponent_74150_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, this.state.Sum), "0")) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp165687Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_74150_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_74150_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParQFormcopyComponent_74150_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.Sum), "0")) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp165687Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  ParQFormcopyComponent_518528_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_790913_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_790913_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParq1Validasyon?.length > 0
            ? stateVars.FormParq1Validasyon[0].result
            : this.state.FormParq1Validasyon?.length > 0
            ? this.state.FormParq1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_68315_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_68315_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParq1Validasyon?.length > 0
            ? stateVars.FormParq1Validasyon[0].result
            : this.state.FormParq1Validasyon?.length > 0
            ? this.state.FormParq1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_183279_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_183279_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParq1Validasyon?.length > 0
            ? stateVars.FormParq1Validasyon[0].result
            : this.state.FormParq1Validasyon?.length > 0
            ? this.state.FormParq1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_485437_value", "value", "", "", "")
        ),
        "string"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_960012_value", "value", "", "", "")
        ),
        "string"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_158970_value", "value", "", "", "")
        ),
        "string"
      ),
      Param4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_107454_value", "value", "", "", "")
        ),
        "string"
      ),
      Param5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_173410_value", "value", "", "", "")
        ),
        "string"
      ),
      Param6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_643202_value", "value", "", "", "")
        ),
        "string"
      ),
      Param7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_538933_value", "value", "", "", "")
        ),
        "string"
      ),
      Param8_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_721461_value", "value", "", "", "")
        ),
        "string"
      ),
      sedanter_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_790913_value", "value", "", "", "")
        ),
        "boolean"
      ),
      kronik_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_930924_value", "value", "", "", "")
        ),
        "boolean"
      ),
      teshis_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_238402_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_68315_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_183279_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_790913_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ParQFormcopy/ParQFormcopyComponent_518528_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FormParQBelirtiSemptom = result?.data.formParQBelirtiSemptom;
    stateVars.FormParQSonuc = result?.data.formParQSonuc;
    stateVars.FormParq1Validasyon = result?.data.formParq1Validasyon;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_43178_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_43178_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ1x3Validasyon?.length > 0
            ? stateVars.FormParQ1x3Validasyon[0].result
            : this.state.FormParQ1x3Validasyon?.length > 0
            ? this.state.FormParQ1x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_567403_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_567403_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ1x3Validasyon?.length > 0
            ? stateVars.FormParQ1x3Validasyon[0].result
            : this.state.FormParQ1x3Validasyon?.length > 0
            ? this.state.FormParQ1x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_235714_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_235714_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ1x3Validasyon?.length > 0
            ? stateVars.FormParQ1x3Validasyon[0].result
            : this.state.FormParQ1x3Validasyon?.length > 0
            ? this.state.FormParQ1x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_43178_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_567403_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_235714_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_790913_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParQFormcopy/ParQFormcopyComponent_518528_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.FormParQ1x3Validasyon = result?.data.formParQ1x3Validasyon;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_673903_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_673903_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_631046_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_631046_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_815856_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_815856_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_301053_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_301053_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_316213_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_316213_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_395667_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_395667_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_858125_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_858125_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_985460_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_985460_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_113946_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_113946_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_221532_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_221532_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_245992_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_245992_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_137075_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_137075_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_683570_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_683570_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_673903_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_631046_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_815856_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_301053_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_316213_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_395667_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_858125_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param8_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_985460_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param9_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_113946_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param10_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_221532_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param11_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_245992_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param12_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_137075_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param13_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_683570_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_238402_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParQFormcopy/ParQFormcopyComponent_518528_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.FormParQSoru3Validasyon = result?.data.formParQSoru3Validasyon;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_726714_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_726714_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormaParQBabaValidasyon?.length > 0
            ? stateVars.FormaParQBabaValidasyon[0].result
            : this.state.FormaParQBabaValidasyon?.length > 0
            ? this.state.FormaParQBabaValidasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_289204_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_289204_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormaParQBabaValidasyon?.length > 0
            ? stateVars.FormaParQBabaValidasyon[0].result
            : this.state.FormaParQBabaValidasyon?.length > 0
            ? this.state.FormaParQBabaValidasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_752131_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_752131_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormaParQBabaValidasyon?.length > 0
            ? stateVars.FormaParQBabaValidasyon[0].result
            : this.state.FormaParQBabaValidasyon?.length > 0
            ? this.state.FormaParQBabaValidasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_726714_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_289204_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_752131_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_925296_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParQFormcopy/ParQFormcopyComponent_518528_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.FormaParQBabaValidasyon = result?.data.formaParQBabaValidasyon;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_527506_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_527506_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQAnneValidasyon?.length > 0
            ? stateVars.FormParQAnneValidasyon[0].result
            : this.state.FormParQAnneValidasyon?.length > 0
            ? this.state.FormParQAnneValidasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_139967_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_139967_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQAnneValidasyon?.length > 0
            ? stateVars.FormParQAnneValidasyon[0].result
            : this.state.FormParQAnneValidasyon?.length > 0
            ? this.state.FormParQAnneValidasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_426278_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_426278_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQAnneValidasyon?.length > 0
            ? stateVars.FormParQAnneValidasyon[0].result
            : this.state.FormParQAnneValidasyon?.length > 0
            ? this.state.FormParQAnneValidasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_527506_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_139967_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_426278_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_485590_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParQFormcopy/ParQFormcopyComponent_518528_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.FormParQAnneValidasyon = result?.data.formParQAnneValidasyon;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_477451_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_477451_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ5Validasyon?.length > 0
            ? stateVars.FormParQ5Validasyon[0].result
            : this.state.FormParQ5Validasyon?.length > 0
            ? this.state.FormParQ5Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_75034_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_75034_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ5Validasyon?.length > 0
            ? stateVars.FormParQ5Validasyon[0].result
            : this.state.FormParQ5Validasyon?.length > 0
            ? this.state.FormParQ5Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_85355_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_85355_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ5Validasyon?.length > 0
            ? stateVars.FormParQ5Validasyon[0].result
            : this.state.FormParQ5Validasyon?.length > 0
            ? this.state.FormParQ5Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_38196_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_38196_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ5Validasyon?.length > 0
            ? stateVars.FormParQ5Validasyon[0].result
            : this.state.FormParQ5Validasyon?.length > 0
            ? this.state.FormParQ5Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_918726_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_918726_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ5Validasyon?.length > 0
            ? stateVars.FormParQ5Validasyon[0].result
            : this.state.FormParQ5Validasyon?.length > 0
            ? this.state.FormParQ5Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_477451_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_75034_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_85355_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_38196_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_918726_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_382369_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParQFormcopy/ParQFormcopyComponent_518528_onClick5_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.FormParQ5Validasyon = result?.data.formParQ5Validasyon;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick6_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_666388_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_666388_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6Validasyon?.length > 0
            ? stateVars.FormParQ6Validasyon[0].result
            : this.state.FormParQ6Validasyon?.length > 0
            ? this.state.FormParQ6Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_700493_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_700493_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6Validasyon?.length > 0
            ? stateVars.FormParQ6Validasyon[0].result
            : this.state.FormParQ6Validasyon?.length > 0
            ? this.state.FormParQ6Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_847131_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_847131_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6Validasyon?.length > 0
            ? stateVars.FormParQ6Validasyon[0].result
            : this.state.FormParQ6Validasyon?.length > 0
            ? this.state.FormParQ6Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_736437_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_736437_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6Validasyon?.length > 0
            ? stateVars.FormParQ6Validasyon[0].result
            : this.state.FormParQ6Validasyon?.length > 0
            ? this.state.FormParQ6Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_486661_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_486661_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6Validasyon?.length > 0
            ? stateVars.FormParQ6Validasyon[0].result
            : this.state.FormParQ6Validasyon?.length > 0
            ? this.state.FormParQ6Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_666388_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_700493_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_847131_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_736437_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_486661_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_930924_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParQFormcopy/ParQFormcopyComponent_518528_onClick6_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.FormParQ6Validasyon = result?.data.formParQ6Validasyon;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick7_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_713132_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_713132_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x1Validasyon?.length > 0
            ? stateVars.FormParQ6x1Validasyon[0].result
            : this.state.FormParQ6x1Validasyon?.length > 0
            ? this.state.FormParQ6x1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_154499_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_154499_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x1Validasyon?.length > 0
            ? stateVars.FormParQ6x1Validasyon[0].result
            : this.state.FormParQ6x1Validasyon?.length > 0
            ? this.state.FormParQ6x1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_867208_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_867208_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x1Validasyon?.length > 0
            ? stateVars.FormParQ6x1Validasyon[0].result
            : this.state.FormParQ6x1Validasyon?.length > 0
            ? this.state.FormParQ6x1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_622758_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_622758_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x1Validasyon?.length > 0
            ? stateVars.FormParQ6x1Validasyon[0].result
            : this.state.FormParQ6x1Validasyon?.length > 0
            ? this.state.FormParQ6x1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_590152_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_590152_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x1Validasyon?.length > 0
            ? stateVars.FormParQ6x1Validasyon[0].result
            : this.state.FormParQ6x1Validasyon?.length > 0
            ? this.state.FormParQ6x1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_713132_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_154499_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_867208_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_622758_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_590152_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_666388_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParQFormcopy/ParQFormcopyComponent_518528_onClick7_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.FormParQ6x1Validasyon = result?.data.formParQ6x1Validasyon;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick8_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_528451_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_528451_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_581396_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_581396_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_165036_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_165036_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_996331_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_996331_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_761897_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_761897_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_782070_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_782070_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_590758_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_590758_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_528451_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_581396_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_165036_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_996331_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_761897_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_782070_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_590758_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_700493_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParQFormcopy/ParQFormcopyComponent_518528_onClick8_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.FormParQ6x2Validasyon = result?.data.formParQ6x2Validasyon;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick9_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_996970_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_996970_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_736647_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_736647_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_718707_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_718707_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_285485_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_285485_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_710319_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_710319_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_659579_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_659579_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_257868_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_257868_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_560619_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_560619_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_275784_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_275784_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_996970_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_736647_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_718707_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_285485_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_710319_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_659579_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_257868_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param8_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_560619_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param9_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_275784_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_847131_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParQFormcopy/ParQFormcopyComponent_518528_onClick9_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.FormParQ6x3Validasyon = result?.data.formParQ6x3Validasyon;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick10_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_883656_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_883656_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x4Validasyon?.length > 0
            ? stateVars.FormParQ6x4Validasyon[0].result
            : this.state.FormParQ6x4Validasyon?.length > 0
            ? this.state.FormParQ6x4Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_627907_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_627907_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x4Validasyon?.length > 0
            ? stateVars.FormParQ6x4Validasyon[0].result
            : this.state.FormParQ6x4Validasyon?.length > 0
            ? this.state.FormParQ6x4Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_893733_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_893733_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x4Validasyon?.length > 0
            ? stateVars.FormParQ6x4Validasyon[0].result
            : this.state.FormParQ6x4Validasyon?.length > 0
            ? this.state.FormParQ6x4Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_786497_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_786497_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x4Validasyon?.length > 0
            ? stateVars.FormParQ6x4Validasyon[0].result
            : this.state.FormParQ6x4Validasyon?.length > 0
            ? this.state.FormParQ6x4Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_365318_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_365318_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x4Validasyon?.length > 0
            ? stateVars.FormParQ6x4Validasyon[0].result
            : this.state.FormParQ6x4Validasyon?.length > 0
            ? this.state.FormParQ6x4Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_342249_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_342249_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x4Validasyon?.length > 0
            ? stateVars.FormParQ6x4Validasyon[0].result
            : this.state.FormParQ6x4Validasyon?.length > 0
            ? this.state.FormParQ6x4Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_883656_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_627907_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_893733_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_786497_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_365318_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_342249_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_736437_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParQFormcopy/ParQFormcopyComponent_518528_onClick10_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.FormParQ6x4Validasyon = result?.data.formParQ6x4Validasyon;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick11_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_74533_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_74533_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ7Validasyon?.length > 0
            ? stateVars.FormParQ7Validasyon[0].result
            : this.state.FormParQ7Validasyon?.length > 0
            ? this.state.FormParQ7Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_740586_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_740586_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ7Validasyon?.length > 0
            ? stateVars.FormParQ7Validasyon[0].result
            : this.state.FormParQ7Validasyon?.length > 0
            ? this.state.FormParQ7Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_216720_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_216720_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ7Validasyon?.length > 0
            ? stateVars.FormParQ7Validasyon[0].result
            : this.state.FormParQ7Validasyon?.length > 0
            ? this.state.FormParQ7Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_74533_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_740586_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_216720_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_926688_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ParQFormcopy/ParQFormcopyComponent_518528_onClick11_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.FormParQ7Validasyon = result?.data.formParQ7Validasyon;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick12_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "parqformcopy_92022_value",
      ReactSystemFunctions.toString(
        this,
        stateVars.FormParQSonuc?.length > 0
          ? stateVars.FormParQSonuc[0].condition_result
          : this.state.FormParQSonuc?.length > 0
          ? this.state.FormParQSonuc[0].condition_result
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "parqformcopy_974898_value",
      ReactSystemFunctions.toString(
        this,
        stateVars.FormParQSonuc?.length > 0
          ? stateVars.FormParQSonuc[0].extra_info
          : this.state.FormParQSonuc?.length > 0
          ? this.state.FormParQSonuc[0].extra_info
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick13_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_68315_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_68315_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParq1Validasyon?.length > 0
            ? stateVars.FormParq1Validasyon[0].result
            : this.state.FormParq1Validasyon?.length > 0
            ? this.state.FormParq1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_183279_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_183279_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParq1Validasyon?.length > 0
            ? stateVars.FormParq1Validasyon[0].result
            : this.state.FormParq1Validasyon?.length > 0
            ? this.state.FormParq1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_790913_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_790913_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParq1Validasyon?.length > 0
            ? stateVars.FormParq1Validasyon[0].result
            : this.state.FormParq1Validasyon?.length > 0
            ? this.state.FormParq1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_43178_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_43178_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ1x3Validasyon?.length > 0
            ? stateVars.FormParQ1x3Validasyon[0].result
            : this.state.FormParQ1x3Validasyon?.length > 0
            ? this.state.FormParQ1x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_567403_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_567403_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ1x3Validasyon?.length > 0
            ? stateVars.FormParQ1x3Validasyon[0].result
            : this.state.FormParQ1x3Validasyon?.length > 0
            ? this.state.FormParQ1x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_235714_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_235714_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ1x3Validasyon?.length > 0
            ? stateVars.FormParQ1x3Validasyon[0].result
            : this.state.FormParQ1x3Validasyon?.length > 0
            ? this.state.FormParQ1x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_527506_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_527506_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQAnneValidasyon?.length > 0
            ? stateVars.FormParQAnneValidasyon[0].result
            : this.state.FormParQAnneValidasyon?.length > 0
            ? this.state.FormParQAnneValidasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_139967_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_139967_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQAnneValidasyon?.length > 0
            ? stateVars.FormParQAnneValidasyon[0].result
            : this.state.FormParQAnneValidasyon?.length > 0
            ? this.state.FormParQAnneValidasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_426278_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_426278_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQAnneValidasyon?.length > 0
            ? stateVars.FormParQAnneValidasyon[0].result
            : this.state.FormParQAnneValidasyon?.length > 0
            ? this.state.FormParQAnneValidasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_726714_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_726714_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormaParQBabaValidasyon?.length > 0
            ? stateVars.FormaParQBabaValidasyon[0].result
            : this.state.FormaParQBabaValidasyon?.length > 0
            ? this.state.FormaParQBabaValidasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_289204_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_289204_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormaParQBabaValidasyon?.length > 0
            ? stateVars.FormaParQBabaValidasyon[0].result
            : this.state.FormaParQBabaValidasyon?.length > 0
            ? this.state.FormaParQBabaValidasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_752131_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_752131_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormaParQBabaValidasyon?.length > 0
            ? stateVars.FormaParQBabaValidasyon[0].result
            : this.state.FormaParQBabaValidasyon?.length > 0
            ? this.state.FormaParQBabaValidasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_103815_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_103815_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_477451_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_477451_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_477451_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ5Validasyon?.length > 0
            ? stateVars.FormParQ5Validasyon[0].result
            : this.state.FormParQ5Validasyon?.length > 0
            ? this.state.FormParQ5Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_654161_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_654161_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_75034_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_75034_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_75034_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ5Validasyon?.length > 0
            ? stateVars.FormParQ5Validasyon[0].result
            : this.state.FormParQ5Validasyon?.length > 0
            ? this.state.FormParQ5Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_445930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_445930_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_85355_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_85355_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_85355_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ5Validasyon?.length > 0
            ? stateVars.FormParQ5Validasyon[0].result
            : this.state.FormParQ5Validasyon?.length > 0
            ? this.state.FormParQ5Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_342330_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_342330_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_38196_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_38196_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_38196_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ5Validasyon?.length > 0
            ? stateVars.FormParQ5Validasyon[0].result
            : this.state.FormParQ5Validasyon?.length > 0
            ? this.state.FormParQ5Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_339185_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_339185_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_918726_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_918726_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_918726_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ5Validasyon?.length > 0
            ? stateVars.FormParQ5Validasyon[0].result
            : this.state.FormParQ5Validasyon?.length > 0
            ? this.state.FormParQ5Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_74533_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_74533_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ7Validasyon?.length > 0
            ? stateVars.FormParQ7Validasyon[0].result
            : this.state.FormParQ7Validasyon?.length > 0
            ? this.state.FormParQ7Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_740586_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_740586_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ7Validasyon?.length > 0
            ? stateVars.FormParQ7Validasyon[0].result
            : this.state.FormParQ7Validasyon?.length > 0
            ? this.state.FormParQ7Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_357526_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_357526_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_216720_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_216720_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_216720_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ7Validasyon?.length > 0
            ? stateVars.FormParQ7Validasyon[0].result
            : this.state.FormParQ7Validasyon?.length > 0
            ? this.state.FormParQ7Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_673600_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_673600_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_413414_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_161683_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_161683_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_413414_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_656623_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_656623_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_413414_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_673903_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_673903_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_221532_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_221532_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_35554_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_35554_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_221532_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_84142_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_84142_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_221532_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_245992_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_245992_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_138481_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_138481_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_245992_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_816505_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_816505_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_245992_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_981775_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_981775_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_245992_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_137075_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_137075_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_683570_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_683570_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_631046_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_631046_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_815856_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_815856_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_301053_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_301053_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_316213_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_316213_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_395667_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_395667_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_858125_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_858125_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_985460_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_985460_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_113946_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_113946_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQSoru3Validasyon?.length > 0
            ? stateVars.FormParQSoru3Validasyon[0].result
            : this.state.FormParQSoru3Validasyon?.length > 0
            ? this.state.FormParQSoru3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_666388_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_666388_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6Validasyon?.length > 0
            ? stateVars.FormParQ6Validasyon[0].result
            : this.state.FormParQ6Validasyon?.length > 0
            ? this.state.FormParQ6Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_713132_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_713132_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x1Validasyon?.length > 0
            ? stateVars.FormParQ6x1Validasyon[0].result
            : this.state.FormParQ6x1Validasyon?.length > 0
            ? this.state.FormParQ6x1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_154499_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_154499_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x1Validasyon?.length > 0
            ? stateVars.FormParQ6x1Validasyon[0].result
            : this.state.FormParQ6x1Validasyon?.length > 0
            ? this.state.FormParQ6x1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_867208_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_867208_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x1Validasyon?.length > 0
            ? stateVars.FormParQ6x1Validasyon[0].result
            : this.state.FormParQ6x1Validasyon?.length > 0
            ? this.state.FormParQ6x1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_622758_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_622758_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x1Validasyon?.length > 0
            ? stateVars.FormParQ6x1Validasyon[0].result
            : this.state.FormParQ6x1Validasyon?.length > 0
            ? this.state.FormParQ6x1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_590152_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_590152_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x1Validasyon?.length > 0
            ? stateVars.FormParQ6x1Validasyon[0].result
            : this.state.FormParQ6x1Validasyon?.length > 0
            ? this.state.FormParQ6x1Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_700493_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_700493_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6Validasyon?.length > 0
            ? stateVars.FormParQ6Validasyon[0].result
            : this.state.FormParQ6Validasyon?.length > 0
            ? this.state.FormParQ6Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_528451_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_528451_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_581396_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_581396_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_165036_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_165036_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_996331_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_996331_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_761897_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_761897_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_782070_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_782070_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_590758_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_590758_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x2Validasyon?.length > 0
            ? stateVars.FormParQ6x2Validasyon[0].result
            : this.state.FormParQ6x2Validasyon?.length > 0
            ? this.state.FormParQ6x2Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_847131_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_847131_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6Validasyon?.length > 0
            ? stateVars.FormParQ6Validasyon[0].result
            : this.state.FormParQ6Validasyon?.length > 0
            ? this.state.FormParQ6Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_996970_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_996970_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_736647_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_736647_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_718707_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_718707_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_285485_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_285485_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_710319_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_710319_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_659579_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_659579_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_257868_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_257868_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_560619_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_560619_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_275784_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_275784_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x3Validasyon?.length > 0
            ? stateVars.FormParQ6x3Validasyon[0].result
            : this.state.FormParQ6x3Validasyon?.length > 0
            ? this.state.FormParQ6x3Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_736437_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_736437_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6Validasyon?.length > 0
            ? stateVars.FormParQ6Validasyon[0].result
            : this.state.FormParQ6Validasyon?.length > 0
            ? this.state.FormParQ6Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_883656_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_883656_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x4Validasyon?.length > 0
            ? stateVars.FormParQ6x4Validasyon[0].result
            : this.state.FormParQ6x4Validasyon?.length > 0
            ? this.state.FormParQ6x4Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_627907_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_627907_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x4Validasyon?.length > 0
            ? stateVars.FormParQ6x4Validasyon[0].result
            : this.state.FormParQ6x4Validasyon?.length > 0
            ? this.state.FormParQ6x4Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_893733_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_893733_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x4Validasyon?.length > 0
            ? stateVars.FormParQ6x4Validasyon[0].result
            : this.state.FormParQ6x4Validasyon?.length > 0
            ? this.state.FormParQ6x4Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_786497_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_786497_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x4Validasyon?.length > 0
            ? stateVars.FormParQ6x4Validasyon[0].result
            : this.state.FormParQ6x4Validasyon?.length > 0
            ? this.state.FormParQ6x4Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_365318_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_365318_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x4Validasyon?.length > 0
            ? stateVars.FormParQ6x4Validasyon[0].result
            : this.state.FormParQ6x4Validasyon?.length > 0
            ? this.state.FormParQ6x4Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_342249_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_342249_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6x4Validasyon?.length > 0
            ? stateVars.FormParQ6x4Validasyon[0].result
            : this.state.FormParQ6x4Validasyon?.length > 0
            ? this.state.FormParQ6x4Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_739962_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_739962_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_486661_value", "value", "", "", "")
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_486661_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "parqformcopy_486661_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.FormParQ6Validasyon?.length > 0
            ? stateVars.FormParQ6Validasyon[0].result
            : this.state.FormParQ6Validasyon?.length > 0
            ? this.state.FormParQ6Validasyon[0].result
            : null
        ),
        "0"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FormSonuc1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.FormParQSonuc?.length > 0
            ? stateVars.FormParQSonuc[0].condition_result
            : this.state.FormParQSonuc?.length > 0
            ? this.state.FormParQSonuc[0].condition_result
            : null
        ),
        "string"
      ),
      FormSonuc2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.FormParQSonuc?.length > 0
            ? stateVars.FormParQSonuc[0].extra_info
            : this.state.FormParQSonuc?.length > 0
            ? this.state.FormParQSonuc[0].extra_info
            : null
        ),
        "string"
      ),
      FormTarih_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      S1x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_68315_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S1x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_183279_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S1x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_790913_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S1x3x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_43178_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S1x3x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_567403_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S1x3x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_235714_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_485437_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_960012_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_158970_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_107454_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_173410_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_643202_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_538933_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x8_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_721461_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_238402_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_485590_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x1x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_527506_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x1x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_139967_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x1x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_426278_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_925296_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x2x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_726714_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x2x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_289204_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x2x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_752131_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4xEfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_966189_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4xEforxProblem_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_135425_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_382369_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S5X1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_103815_value", "value", "", "", "")
        ),
        "string"
      ),
      S5X1Check_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_477451_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S5x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_654161_value", "value", "", "", "")
        ),
        "string"
      ),
      S5X2Check_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_75034_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S5x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_445930_value", "value", "", "", "")
        ),
        "string"
      ),
      S5X3Check_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_85355_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S5x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_342330_value", "value", "", "", "")
        ),
        "string"
      ),
      S5X4Check_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_38196_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S5x5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_339185_value", "value", "", "", "")
        ),
        "string"
      ),
      S5X5Check_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_918726_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_930924_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_926688_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S7x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_74533_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S7x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_740586_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S7x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_357526_value", "value", "", "", "")
        ),
        "string"
      ),
      S7x3xCheck_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_216720_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S8_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_413414_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S8xGunde_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_673600_value", "value", "", "", "")
        ),
        "number"
      ),
      S8xHaftada_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_161683_value", "value", "", "", "")
        ),
        "number"
      ),
      S8xKacSene_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_656623_value", "value", "", "", "")
        ),
        "number"
      ),
      SedanterDegil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_790913_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Semptom_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          stateVars.FormParQBelirtiSemptom?.length > 0
            ? stateVars.FormParQBelirtiSemptom[0].result
            : this.state.FormParQBelirtiSemptom?.length > 0
            ? this.state.FormParQBelirtiSemptom[0].result
            : null
        ),
        "boolean"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FormId ?? this.props.screenInputs.formid,
        "Guid"
      ),
      FormId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FormId ?? this.props.screenInputs.formid,
        "Guid"
      ),
      FormTarih_1: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      S3x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_673903_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x10_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_221532_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x10x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_813285_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x10xBoy_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_35554_value", "value", "", "", "")
        ),
        "number"
      ),
      S3x10xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_173392_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x10xKilo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_84142_value", "value", "", "", "")
        ),
        "number"
      ),
      S3x11_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_245992_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x11x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_751235_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x11xHDL_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_138481_value", "value", "", "", "")
        ),
        "number"
      ),
      S3x11xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_421543_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x11xLDL_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_816505_value", "value", "", "", "")
        ),
        "number"
      ),
      S3x11xResult_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_337464_value", "value", "", "", "")
        ),
        "number"
      ),
      S3x11xTK_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_981775_value", "value", "", "", "")
        ),
        "number"
      ),
      S3x12_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_137075_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x12x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_492488_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x12xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_763635_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x13_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_683570_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x13x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_56334_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x13xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_17076_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x1x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_146506_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x1xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_63995_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_631046_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x2x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_552938_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x2xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_355803_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_815856_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x3x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_441666_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x3xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_827266_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x4_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_301053_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x4x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_366768_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x4xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_425116_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x5_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_316213_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x5x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_394297_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x5xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_360226_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x6_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_395667_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x6x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_503137_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x6xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_951810_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x7_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_858125_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x7x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_875104_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x7xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_2796_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x8_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_985460_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x8x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_202020_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x8xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_875505_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x9_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_113946_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x9x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_410924_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x9xIlac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_47848_value", "value", "", "", "")
        ),
        "boolean"
      ),
      FormId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FormId ?? this.props.screenInputs.formid,
        "Guid"
      ),
      FormTarih_2: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      S6x1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_666388_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x1x1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_713132_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x1x2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_154499_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x1x3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_867208_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x1x4_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_622758_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x1x5_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_590152_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_700493_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_528451_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_581396_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_165036_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x4_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_996331_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x5_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_761897_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x6_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_782070_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x7_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_590758_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_847131_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_996970_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_736647_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_718707_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x4_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_285485_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x5_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_710319_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x6_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_659579_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x7_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_257868_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x8_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_560619_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x9_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_275784_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_736437_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4x1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_883656_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4x2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_627907_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4x3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_893733_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4x4_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_786497_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4x5_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_365318_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4x6_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_342249_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x5_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_739962_value", "value", "", "", "")
        ),
        "string"
      ),
      Sx6x5Check_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqformcopy_486661_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ParQFormcopy/ParQFormcopyComponent_518528_onClick13_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FormParQSave = result?.data.formParQSave;
    stateVars.FormParQSoru3Save = result?.data.formParQSoru3Save;
    stateVars.FormParQSoru6Save = result?.data.formParQSoru6Save;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParQFormcopyComponent_518528_onClick14_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormcopyComponent_518528_onClick14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
