import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISinifYeniOgrenci_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISinifYeniOgrenci_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  Kirilim2SelectById: any[];
  Kirilim2SelectById_dummy: any[];
  Kirilim1SelectById: any[];
  Kirilim1SelectById_dummy: any[];
  ProfilByKisiIdAndRolename: any[];
  ProfilByKisiIdAndRolename_dummy: any[];
  SinifOgrenciEkle: any[];
  SinifOgrenciEkle_dummy: any[];
  AktifKirilim1AltKirilim2leri: any[];
  AktifKirilim1AltKirilim2leri_dummy: any[];
  RolePerKurumTipi: any[];
  RolePerKurumTipi_dummy: any[];
  Rol: any[];
  Rol_dummy: any[];
  IsSinifExisting: any[];
  IsSinifExisting_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
  isComp331748Visible: "visible" | "hidden";
  isComp272749Visible: "visible" | "hidden";
  isComp832432Visible: "visible" | "hidden";
}

export class SinifYeniOgrenci_ScreenBase extends React.PureComponent<ISinifYeniOgrenci_ScreenProps, any> {
  sinifyeniogrenci_350368_value_kuikaTableRef: React.RefObject<any>;
  sinifyeniogrenci_753001_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "3ff60d98-73c8-4282-a965-020e65b37008",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 925082, PropertyName: "value", Value: ":" },
        { Id: 490663, PropertyName: "value", Value: ":" },
        { Id: 991744, PropertyName: "value", Value: "Sınıfsız Öğrencileri Göster" },
        { Id: 109239, PropertyName: "value", Value: "Diğer Sezon Sınıfları" },
        { Id: 456150, PropertyName: "placeholder", Value: "Sınıf Arama..." },
        { Id: 783639, PropertyName: "value", Value: "label" },
        { Id: 863981, PropertyName: "placeholder", Value: "Arama..." },
        { Id: 350368, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 271780, PropertyName: "value", Value: "[datafield:kirilim2]" },
        { Id: 131301, PropertyName: "value", Value: "[datafield:kirilim1]" },
        { Id: 832432, PropertyName: "title", Value: "Okul Numarası" },
        { Id: 968391, PropertyName: "value", Value: "[datafield:okulnumarasi]" },
        { Id: 999517, PropertyName: "title", Value: "Adı" },
        { Id: 11801, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 533601, PropertyName: "title", Value: "Soyadı" },
        { Id: 568408, PropertyName: "value", Value: "[datafield:soyadi]" },
        { Id: 306783, PropertyName: "title", Value: "Yaş" },
        { Id: 853913, PropertyName: "value", Value: "[datafield:yas]" },
        { Id: 325548, PropertyName: "title", Value: "Cinsiyet" },
        { Id: 220207, PropertyName: "value", Value: "[datafield:cinsiyet]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.sinifyeniogrenci_350368_value_kuikaTableRef = React.createRef();
    this.sinifyeniogrenci_753001_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      Kirilim2SelectById: [],
      Kirilim1SelectById: [],
      ProfilByKisiIdAndRolename: [],
      SinifOgrenciEkle: [],
      AktifKirilim1AltKirilim2leri: [],
      RolePerKurumTipi: [],
      Rol: [],
      IsSinifExisting: [],
      GoBack: "",
      SaveRecord: false,
      isComp331748Visible: "hidden",
      isComp272749Visible: "hidden",
      isComp832432Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("sinifyeniogrenci", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SinifYeniOgrenciPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("sinifyeniogrenci", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("sinifyeniogrenci", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SinifYeniOgrenciPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      sinifyeniogrenci_464576_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  SinifYeniOgrenciPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      kisiId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      rolename_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      KurumId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid,
        "Guid"
      ),
      roleFilter_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kirilim2Id_4: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      searchFor_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentkirilim2ID_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      kirilim2adi_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_456150_value", "value", "", "", "")
        ),
        "string"
      ),
      sinifsizswitch_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_464576_value", "value", "", "", "")
        ),
        "boolean"
      ),
      role_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      rolename_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SinifYeniOgrenci/SinifYeniOgrenciPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.sinifyeniogrenci_212739_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );
    formVars.sinifyeniogrenci_795781_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim1Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim1Adi
        : null
    );
    stateVars.Kirilim2SelectById = result?.data.kirilim2SelectById;
    formVars.sinifyeniogrenci_343117_value = ReactSystemFunctions.toString(
      this,
      stateVars.Kirilim2SelectById?.length > 0
        ? stateVars.Kirilim2SelectById[0].tanim
        : this.state.Kirilim2SelectById?.length > 0
        ? this.state.Kirilim2SelectById[0].tanim
        : null
    );
    stateVars.Kirilim1SelectById = result?.data.kirilim1SelectById;
    formVars.sinifyeniogrenci_954196_value = ReactSystemFunctions.toString(
      this,
      stateVars.Kirilim1SelectById?.length > 0
        ? stateVars.Kirilim1SelectById[0].tanim
        : this.state.Kirilim1SelectById?.length > 0
        ? this.state.Kirilim1SelectById[0].tanim
        : null
    );
    stateVars.ProfilByKisiIdAndRolename = result?.data.profilByKisiIdAndRolename;
    stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;

    stateVars.AktifKirilim1AltKirilim2leri = result?.data.aktifKirilim1AltKirilim2leri;
    stateVars.RolePerKurumTipi = result?.data.rolePerKurumTipi;
    formVars.sinifyeniogrenci_834413_value = ReactSystemFunctions.toString(
      this,
      stateVars.RolePerKurumTipi?.length > 0
        ? stateVars.RolePerKurumTipi[0].label
        : this.state.RolePerKurumTipi?.length > 0
        ? this.state.RolePerKurumTipi[0].label
        : null
    );
    stateVars.Rol = result?.data.rol;
    stateVars.IsSinifExisting = result?.data.isSinifExisting;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SinifYeniOgrenciPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifYeniOgrenciPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.sinifyeniogrenci_834413_value = ReactSystemFunctions.toString(
      this,
      stateVars.RolePerKurumTipi?.length > 0
        ? stateVars.RolePerKurumTipi[0].label
        : this.state.RolePerKurumTipi?.length > 0
        ? this.state.RolePerKurumTipi[0].label
        : null
    );

    formVars.sinifyeniogrenci_212739_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );

    formVars.sinifyeniogrenci_343117_value = ReactSystemFunctions.toString(
      this,
      stateVars.Kirilim2SelectById?.length > 0
        ? stateVars.Kirilim2SelectById[0].tanim
        : this.state.Kirilim2SelectById?.length > 0
        ? this.state.Kirilim2SelectById[0].tanim
        : null
    );

    formVars.sinifyeniogrenci_795781_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim1Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim1Adi
        : null
    );

    formVars.sinifyeniogrenci_954196_value = ReactSystemFunctions.toString(
      this,
      stateVars.Kirilim1SelectById?.length > 0
        ? stateVars.Kirilim1SelectById[0].tanim
        : this.state.Kirilim1SelectById?.length > 0
        ? this.state.Kirilim1SelectById[0].tanim
        : null
    );

    stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
    formVars.sinifyeniogrenci_331748_title = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );

    stateVars.isComp331748Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client") ===
      true
        ? "visible"
        : "hidden";
    formVars.sinifyeniogrenci_272749_title = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim1Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim1Adi
        : null
    );

    stateVars.isComp272749Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp832432Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client") ===
      true
        ? "visible"
        : "hidden";
    formVars.sinifyeniogrenci_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.SinifOgrenciEkle?.length > 0
        ? stateVars.SinifOgrenciEkle[0].personCount
        : this.state.SinifOgrenciEkle?.length > 0
        ? this.state.SinifOgrenciEkle[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SinifYeniOgrenciComponent_372921_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifYeniOgrenciComponent_464576_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentkirilim2ID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      kirilim2adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_456150_value", "value", "", "", "")
        ),
        "string"
      ),
      sinifsizswitch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_464576_value", "value", "", "", "")
        ),
        "boolean"
      ),
      kirilim1ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.Kirilim2SelectById?.length > 0
            ? stateVars.Kirilim2SelectById[0].kirilim1
            : this.state.Kirilim2SelectById?.length > 0
            ? this.state.Kirilim2SelectById[0].kirilim1
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifYeniOgrenci/SinifYeniOgrenciComponent_464576_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SinifYeniOgrenciComponent_464576_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifYeniOgrenciComponent_464576_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
    formVars.sinifyeniogrenci_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.SinifOgrenciEkle?.length > 0
        ? stateVars.SinifOgrenciEkle[0].personCount
        : this.state.SinifOgrenciEkle?.length > 0
        ? this.state.SinifOgrenciEkle[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifYeniOgrenciComponent_456150_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentkirilim2ID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      kirilim2adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_456150_value", "value", "", "", "")
        ),
        "string"
      ),
      sinifsizswitch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_464576_value", "value", "", "", "")
        ),
        "boolean"
      ),
      kirilim1ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.Kirilim2SelectById?.length > 0
            ? stateVars.Kirilim2SelectById[0].kirilim1
            : this.state.Kirilim2SelectById?.length > 0
            ? this.state.Kirilim2SelectById[0].kirilim1
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifYeniOgrenci/SinifYeniOgrenciComponent_456150_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SinifYeniOgrenciComponent_456150_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifYeniOgrenciComponent_456150_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
    formVars.sinifyeniogrenci_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.SinifOgrenciEkle?.length > 0
        ? stateVars.SinifOgrenciEkle[0].personCount
        : this.state.SinifOgrenciEkle?.length > 0
        ? this.state.SinifOgrenciEkle[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifYeniOgrenciComponent_863981_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentkirilim2ID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      kirilim2adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_456150_value", "value", "", "", "")
        ),
        "string"
      ),
      sinifsizswitch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_464576_value", "value", "", "", "")
        ),
        "boolean"
      ),
      kirilim1ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.Kirilim2SelectById?.length > 0
            ? stateVars.Kirilim2SelectById[0].kirilim1
            : this.state.Kirilim2SelectById?.length > 0
            ? this.state.Kirilim2SelectById[0].kirilim1
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifYeniOgrenci/SinifYeniOgrenciComponent_863981_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SinifYeniOgrenciComponent_863981_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifYeniOgrenciComponent_863981_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
    formVars.sinifyeniogrenci_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.SinifOgrenciEkle?.length > 0
        ? stateVars.SinifOgrenciEkle[0].personCount
        : this.state.SinifOgrenciEkle?.length > 0
        ? this.state.SinifOgrenciEkle[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifYeniOgrenciComponent_511637_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MevcutKirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "sinifyeniogrenci_350368_value", "id"),
        "Guid"
      ),
      Kirilim2Ids_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "string"
      ),
      Id_0_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "sinifyeniogrenci_350368_value", "profileId"),
        "Guid"
      ),
      currentRole_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_2: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentkirilim2ID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      kirilim2adi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_456150_value", "value", "", "", "")
        ),
        "string"
      ),
      sinifsizswitch_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_464576_value", "value", "", "", "")
        ),
        "boolean"
      ),
      kirilim1ID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.Kirilim2SelectById?.length > 0
            ? stateVars.Kirilim2SelectById[0].kirilim1
            : this.state.Kirilim2SelectById?.length > 0
            ? this.state.Kirilim2SelectById[0].kirilim1
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifYeniOgrenci/SinifYeniOgrenciComponent_511637_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SinifYeniOgrenciComponent_511637_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifYeniOgrenciComponent_511637_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
    formVars.sinifyeniogrenci_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.SinifOgrenciEkle?.length > 0
        ? stateVars.SinifOgrenciEkle[0].personCount
        : this.state.SinifOgrenciEkle?.length > 0
        ? this.state.SinifOgrenciEkle[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifYeniOgrenciComponent_753001_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      currentkirilim2ID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      kirilim2adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_456150_value", "value", "", "", "")
        ),
        "string"
      ),
      sinifsizswitch_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "sinifyeniogrenci_464576_value", "value", "", "", "")
        ),
        "boolean"
      ),
      kirilim1ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.Kirilim2SelectById?.length > 0
            ? stateVars.Kirilim2SelectById[0].kirilim1
            : this.state.Kirilim2SelectById?.length > 0
            ? this.state.Kirilim2SelectById[0].kirilim1
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifYeniOgrenci/SinifYeniOgrenciComponent_753001_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SinifYeniOgrenciComponent_753001_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifYeniOgrenciComponent_753001_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
    formVars.sinifyeniogrenci_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.SinifOgrenciEkle?.length > 0
        ? stateVars.SinifOgrenciEkle[0].personCount
        : this.state.SinifOgrenciEkle?.length > 0
        ? this.state.SinifOgrenciEkle[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
