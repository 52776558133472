import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITestDegisken_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITestDegisken_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  TestDegiskenById: any[];
  TestDegiskenById_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  SaveRecord: number;
  SaveRecord_dummy: number;
  isComp889801Visible: "visible" | "hidden";
}

export class TestDegisken_ScreenBase extends React.PureComponent<ITestDegisken_ScreenProps, any> {
  testdegisken_195614_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "b562bf4f-e747-4cbb-be63-6dbf8c1e52de",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "TEST TANIMI" },
        { Id: 547635, PropertyName: "value", Value: "Değişken Tipi" },
        { Id: 195614, PropertyName: "placeholder", Value: "Girdi / Ara Sonuç / Sonuç / Puan" },
        { Id: 732735, PropertyName: "value", Value: "Değişkenin Adı" },
        { Id: 361485, PropertyName: "placeholder", Value: "Koşu Seviyesi" },
        { Id: 603989, PropertyName: "value", Value: "Değişkenin Formülü" },
        { Id: 896060, PropertyName: "placeholder", Value: "SET @val = @val" },
        { Id: 529918, PropertyName: "label", Value: "EKLE" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.testdegisken_195614_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      TestDegiskenById: [],
      ChangeVisibilityOf: "",
      SaveRecord: false,
      dataSource_195614: [
        { key: "Girdi", text: "Girdi" },
        { key: "AraSonuc", text: "Ara Sonuç" },
        { key: "Sonuc", text: "Sonuç" },
        { key: "Puan", text: "Puan" }
      ],
      isComp889801Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("testdegisken", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TestDegiskenPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("testdegisken", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("testdegisken", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TestDegiskenPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      testdegisken_195614_value: this.state.TestDegiskenById?.at?.(0)?.degiskenTipi ?? undefined,
      testdegisken_361485_value: this.state.TestDegiskenById?.at?.(0)?.degiskenAdi ?? undefined,
      testdegisken_896060_value: this.state.TestDegiskenById?.at?.(0)?.formul ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TestDegiskenPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DegiskenId ?? this.props.screenInputs.degiskenid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestDegisken/TestDegiskenPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestDegiskenById = result?.data.testDegiskenById;
    formVars.testdegisken_195614_value =
      stateVars.TestDegiskenById?.length > 0
        ? stateVars.TestDegiskenById[0].degiskenTipi
        : this.state.TestDegiskenById?.length > 0
        ? this.state.TestDegiskenById[0].degiskenTipi
        : null;
    formVars.testdegisken_361485_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestDegiskenById?.length > 0
        ? stateVars.TestDegiskenById[0].degiskenAdi
        : this.state.TestDegiskenById?.length > 0
        ? this.state.TestDegiskenById[0].degiskenAdi
        : null
    );
    formVars.testdegisken_896060_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestDegiskenById?.length > 0
        ? stateVars.TestDegiskenById[0].formul
        : this.state.TestDegiskenById?.length > 0
        ? this.state.TestDegiskenById[0].formul
        : null
    );
    stateVars.isComp889801Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestDegiskenById?.length > 0
            ? stateVars.TestDegiskenById[0].degiskenTipi
            : this.state.TestDegiskenById?.length > 0
            ? this.state.TestDegiskenById[0].degiskenTipi
            : null
        ),
        "Girdi"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestDegiskenPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestDegiskenPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testdegisken_195614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestDegiskenById?.length > 0
        ? stateVars.TestDegiskenById[0].degiskenTipi
        : this.state.TestDegiskenById?.length > 0
        ? this.state.TestDegiskenById[0].degiskenTipi
        : null
    );

    formVars.testdegisken_361485_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestDegiskenById?.length > 0
        ? stateVars.TestDegiskenById[0].degiskenAdi
        : this.state.TestDegiskenById?.length > 0
        ? this.state.TestDegiskenById[0].degiskenAdi
        : null
    );

    stateVars.isComp889801Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestDegiskenById?.length > 0
            ? stateVars.TestDegiskenById[0].degiskenTipi
            : this.state.TestDegiskenById?.length > 0
            ? this.state.TestDegiskenById[0].degiskenTipi
            : null
        ),
        "Girdi"
      ) === true
        ? "visible"
        : "hidden";
    formVars.testdegisken_896060_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestDegiskenById?.length > 0
        ? stateVars.TestDegiskenById[0].formul
        : this.state.TestDegiskenById?.length > 0
        ? this.state.TestDegiskenById[0].formul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TestDegiskenComponent_107559_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestDegiskenComponent_195614_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp889801Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestDegiskenComponent_195614_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TestDegiskenComponent_195614_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdegisken_195614_value", "value", "", "key", "")
        ),
        "Girdi"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp889801Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  TestDegiskenComponent_529918_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdegisken_195614_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "testdegisken_195614_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdegisken_361485_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "testdegisken_361485_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdegisken_896060_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "testdegisken_896060_value",
      condition: ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdegisken_195614_value", "value", "", "key", "")
        ),
        "Girdi"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DegiskenId ?? this.props.screenInputs.degiskenid,
        "Guid"
      ),
      DegiskenTipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdegisken_195614_value", "value", "", "key", "")
        ),
        "string"
      ),
      DegiskenAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdegisken_361485_value", "value", "", "", "")
        ),
        "string"
      ),
      Formul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdegisken_896060_value", "value", "", "", "")
        ),
        "string"
      ),
      TestId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestDegisken/TestDegiskenComponent_529918_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestDegiskenComponent_529918_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestDegiskenComponent_529918_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
