import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISettings_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISettings_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  GetLanguage: string;
  GetLanguage_dummy: string;
  UserProfiles: any[];
  UserProfiles_dummy: any[];
  MesaiSaatByProfileId: any[];
  MesaiSaatByProfileId_dummy: any[];
  ProfilSelectById: any[];
  ProfilSelectById_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  SetLanguage: string;
  SetLanguage_dummy: string;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  DeleteRecord: number;
  DeleteRecord_dummy: number;
  isComp49691Visible: "visible" | "hidden";
  isComp631111Visible: "visible" | "hidden";
  isComp280186Visible: "visible" | "hidden";
  isComp772629Visible: "visible" | "hidden";
  isComp569600Visible: "visible" | "hidden";
  isComp497664Visible: "visible" | "hidden";
}

export class Settings_ScreenBase extends React.PureComponent<ISettings_ScreenProps, any> {
  settings_8802156_value_kuikaSelectBoxRef: React.RefObject<any>;
  settings_63894_value_kuikaDatetimeRef: React.RefObject<any>;
  settings_501688_value_kuikaDatetimeRef: React.RefObject<any>;
  settings_842550_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Profile", TagName: "lblHead_value" },
        { Id: 4616776, PropertyName: "value", Value: "Current Profile", TagName: "lblKurumId_value" },
        { Id: 8802156, PropertyName: "placeholder", Value: "Choose...", TagName: "selProfil_placeholder" },
        { Id: 161779, PropertyName: "value", Value: "Max. Concurrent Slot", TagName: "Label1_0_value" },
        { Id: 2197, PropertyName: "placeholder", Value: "15", TagName: "NumberInput1_placeholder" },
        { Id: 472121, PropertyName: "value", Value: "Working hours start", TagName: "Label2_value" },
        { Id: 161285, PropertyName: "value", Value: "Working hours end", TagName: "Label1_0_value" },
        { Id: 324289, PropertyName: "value", Value: "Working days", TagName: "Label1_0_value" },
        { Id: 799533, PropertyName: "value", Value: "Monday", TagName: "Label1_1_value" },
        { Id: 263451, PropertyName: "value", Value: "Tuesday", TagName: "Label1_1_value" },
        { Id: 206976, PropertyName: "value", Value: "Wensday", TagName: "Label1_1_value" },
        { Id: 760654, PropertyName: "value", Value: "Thursday", TagName: "Label1_1_value" },
        { Id: 819165, PropertyName: "value", Value: "Friday", TagName: "Label1_1_value" },
        { Id: 52700, PropertyName: "value", Value: "Saturday", TagName: "Label1_1_value" },
        { Id: 472784, PropertyName: "value", Value: "Sunday", TagName: "Label1_1_value" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "d59e71f8-6aa6-4934-9990-8092665c95dd",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Profil" },
        { Id: 417325, PropertyName: "value", Value: "Mevcut Profil" },
        { Id: 8802156, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 4616776, PropertyName: "value", Value: "Lisans Bitiş Tarihi" },
        { Id: 928014, PropertyName: "value", Value: "Arayüz Dili" },
        { Id: 456244, PropertyName: "value", Value: "TR" },
        { Id: 647761, PropertyName: "value", Value: "TR" },
        { Id: 693892, PropertyName: "value", Value: "EN" },
        { Id: 430299, PropertyName: "value", Value: "EN" },
        { Id: 569600, PropertyName: "header", Value: "Merkez Ayarları" },
        { Id: 161779, PropertyName: "value", Value: "Max Concurrent Slot" },
        { Id: 2197, PropertyName: "placeholder", Value: "15" },
        { Id: 738433, PropertyName: "value", Value: "Açılış Saati" },
        { Id: 63894, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 224212, PropertyName: "value", Value: "Kapanış Saati" },
        { Id: 501688, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 497664, PropertyName: "header", Value: "Seçeneklerim" },
        { Id: 842550, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 275460, PropertyName: "title", Value: "Gün" },
        { Id: 537374, PropertyName: "value", Value: "[datafield:gun]" },
        { Id: 579008, PropertyName: "title", Value: "Başlangıç" },
        { Id: 343643, PropertyName: "value", Value: "[datafield:baslangic]" },
        { Id: 327697, PropertyName: "title", Value: "Bitiş" },
        { Id: 713743, PropertyName: "value", Value: "[datafield:bitis]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.settings_8802156_value_kuikaSelectBoxRef = React.createRef();
    this.settings_63894_value_kuikaDatetimeRef = React.createRef();
    this.settings_501688_value_kuikaDatetimeRef = React.createRef();
    this.settings_842550_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      GetLanguage: "",
      UserProfiles: [],
      MesaiSaatByProfileId: [],
      ProfilSelectById: [],
      SaveRecord: false,
      SetLanguage: "",
      NAVIGATE: "",
      DeleteRecord: false,
      isComp49691Visible: "hidden",
      isComp631111Visible: "hidden",
      isComp280186Visible: "hidden",
      isComp772629Visible: "hidden",
      isComp569600Visible: "hidden",
      isComp497664Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("settings", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SettingsPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("settings", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("settings", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SettingsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      settings_8802156_value: this.state.MyContext?.at?.(0)?.lastChosenProfileId ?? undefined,
      settings_2197_value: this.state.MyContext?.at?.(0)?.maxConcurrentSlot ?? undefined,
      settings_63894_value: this.state.MyContext?.at?.(0)?.mesaiAcilis ?? undefined,
      settings_501688_value: this.state.MyContext?.at?.(0)?.mesaiKapanis ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  SettingsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Settings/SettingsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.settings_8802156_value =
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].lastChosenProfileId
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].lastChosenProfileId
        : null;
    formVars.settings_2197_value = ReactSystemFunctions.value(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].maxConcurrentSlot
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].maxConcurrentSlot
        : null
    );
    formVars.settings_63894_value = ReactSystemFunctions.value(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].mesaiAcilis
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].mesaiAcilis
        : null
    );
    formVars.settings_501688_value = ReactSystemFunctions.value(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].mesaiKapanis
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].mesaiKapanis
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SettingsPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SettingsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GetLanguage = await ReactSystemFunctions.getLanguage(this.ml, this.defaultML);
    stateVars.isComp49691Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp631111Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp280186Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp772629Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, stateVars.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SettingsPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SettingsPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      myRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      lang_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lang
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lang
            : null
        ),
        "string"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Settings/SettingsPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UserProfiles = result?.data.userProfiles;

    formVars.settings_8802156_options = stateVars.UserProfiles;
    stateVars.MesaiSaatByProfileId = result?.data.mesaiSaatByProfileId;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.settings_522773_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].expiration
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].expiration
        : null
    );
    stateVars.isComp569600Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].gymAyariDegistirebilir
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].gymAyariDegistirebilir
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497664Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].secenekleriDegistirebilirMi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].secenekleriDegistirebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SettingsPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SettingsPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.settings_8802156_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].lastChosenProfileId
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].lastChosenProfileId
        : null
    );

    stateVars.dataSource_8802156 = this.state.UserProfiles;
    stateVars.dataSource_8802156 = this.state.UserProfiles;
    formVars.settings_522773_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].expiration
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].expiration
        : null
    );

    stateVars.isComp49691Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp631111Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "tr_TR") === true
        ? "visible"
        : "hidden";
    stateVars.isComp280186Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp772629Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, this.state.GetLanguage), "en_US") === true
        ? "visible"
        : "hidden";
    stateVars.isComp569600Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].gymAyariDegistirebilir
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].gymAyariDegistirebilir
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.settings_2197_value = ReactSystemFunctions.value(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].maxConcurrentSlot
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].maxConcurrentSlot
        : null
    );

    formVars.settings_63894_value = ReactSystemFunctions.value(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].mesaiAcilis
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].mesaiAcilis
        : null
    );

    formVars.settings_501688_value = ReactSystemFunctions.value(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].mesaiKapanis
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].mesaiKapanis
        : null
    );

    stateVars.isComp497664Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].secenekleriDegistirebilirMi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].secenekleriDegistirebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_842550 = this.state.MesaiSaatByProfileId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SettingsComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SettingsComponent_8802156_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "settings_8802156_value", "value", "UserProfiles", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "settings_8802156_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      LastChosenProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "settings_8802156_value", "value", "UserProfiles", "id", "id")
        ),
        "Guid"
      ),
      KUserId_0_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].userId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].userId
            : null
        ),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "settings_8802156_value", "value", "UserProfiles", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Settings/SettingsComponent_8802156_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.settings_522773_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].expiration
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].expiration
        : null
    );
    stateVars.isComp569600Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].gymAyariDegistirebilir
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].gymAyariDegistirebilir
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497664Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].secenekleriDegistirebilirMi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].secenekleriDegistirebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SettingsComponent_8802156_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SettingsComponent_8802156_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.settings_522773_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].expiration
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].expiration
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SettingsComponent_647761_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "tr_TR");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SettingsComponent_430299_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetLanguage = await ReactSystemFunctions.setLanguage(this, "en_US");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SettingsComponent_2197_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      MaxConcurrentSlot_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "settings_2197_value", "value", "", "", "")),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Settings/SettingsComponent_2197_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SettingsComponent_63894_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      MesaiAcilis_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "settings_63894_value", "value", "", "", "")),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Settings/SettingsComponent_63894_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_3;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SettingsComponent_501688_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      MesaiKapanis_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "settings_501688_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Settings/SettingsComponent_501688_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_4;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SettingsComponent_468715_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm2",
      "ProfileId",
      ReactSystemFunctions.value(
        this,
        stateVars.MyContext?.length > 0
          ? stateVars.MyContext[0].lastChosenProfileId
          : this.state.MyContext?.length > 0
          ? this.state.MyContext[0].lastChosenProfileId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm2", "MesaiSaatId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Settings",
      "MesaiSaatiForm2",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "434372",
      null,
      null,
      "true",
      "300px",
      "500px",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SettingsComponent_466879_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "settings_842550_value", "id"),
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Settings/SettingsComponent_466879_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteRecord = result?.data.deleteRecord_1;
    stateVars.MesaiSaatByProfileId = result?.data.mesaiSaatByProfileId;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SettingsComponent_466879_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SettingsComponent_466879_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_842550 = this.state.MesaiSaatByProfileId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [434372] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SettingsPageInit();
    }
  }
}
