import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOrganisationCard_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOrganisationCard_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  KurumSelectById: any[];
  KurumSelectById_dummy: any[];
  HesapKrediHareketleri: any[];
  HesapKrediHareketleri_dummy: any[];
  KrediBakiye: any[];
  KrediBakiye_dummy: any[];
  KurumunEgitmenleri: any[];
  KurumunEgitmenleri_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp612070Visible: "visible" | "hidden";
  isComp233726Visible: "visible" | "hidden";
  isComp411851Visible: "visible" | "hidden";
  isComp751981Visible: "visible" | "hidden";
  isComp535575Visible: "visible" | "hidden";
  isComp756175Visible: "visible" | "hidden";
}

export class OrganisationCard_ScreenBase extends React.PureComponent<IOrganisationCard_ScreenProps, any> {
  organisationcard_761946_value_kuikaTableRef: React.RefObject<any>;
  organisationcard_7445753_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "fb02c692-5129-4a31-a01b-174b6caaa1ee",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 483561, PropertyName: "value", Value: "Kurum Kartı" },
        { Id: 177218, PropertyName: "tab", Value: "Hesap" },
        { Id: 144992, PropertyName: "value", Value: "Kredi" },
        { Id: 761946, PropertyName: "nodatafoundmessage", Value: "Kredi hareketi bulunamadı." },
        { Id: 813856, PropertyName: "title", Value: "Title" },
        { Id: 671545, PropertyName: "value", Value: "[datafield:tarih]" },
        { Id: 372146, PropertyName: "title", Value: "Title" },
        { Id: 703487, PropertyName: "value", Value: "[datafield:kredi]" },
        { Id: 579790, PropertyName: "title", Value: "Title" },
        { Id: 296248, PropertyName: "value", Value: "[datafield:hesaptanimi]" },
        { Id: 550051, PropertyName: "label", Value: "Kredi Yükle" },
        { Id: 863402, PropertyName: "label", Value: "Bu Hesaptan Gönder" },
        { Id: 342138, PropertyName: "tab", Value: "Eğitmenler" },
        { Id: 7445753, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 6071096, PropertyName: "title", Value: "Resim" },
        { Id: 6497983, PropertyName: "title", Value: "Adi" },
        { Id: 3999776, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 6582741, PropertyName: "title", Value: "Soyadi" },
        { Id: 3799077, PropertyName: "value", Value: "[datafield:soyadi]" },
        { Id: 6315889, PropertyName: "title", Value: "Eposta" },
        { Id: 2107075, PropertyName: "value", Value: "[datafield:eposta]" },
        { Id: 7087323, PropertyName: "title", Value: "Telefon" },
        { Id: 3097598, PropertyName: "value", Value: "[datafield:telefon]" },
        { Id: 3338172, PropertyName: "title", Value: "Dogum tarihi" },
        { Id: 8897817, PropertyName: "value", Value: "[datafield:dogumtarihi]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.organisationcard_761946_value_kuikaTableRef = React.createRef();
    this.organisationcard_7445753_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      KurumSelectById: [],
      HesapKrediHareketleri: [],
      KrediBakiye: [],
      KurumunEgitmenleri: [],
      NAVIGATE: "",
      isComp612070Visible: "hidden",
      isComp233726Visible: "hidden",
      isComp411851Visible: "hidden",
      isComp751981Visible: "hidden",
      isComp535575Visible: "hidden",
      isComp756175Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("organisationcard", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.OrganisationCardPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("organisationcard", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("organisationcard", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.OrganisationCardPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      organisationcard_320842_value: this.state.KurumSelectById?.at?.(0)?.logo ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  checkTabErrors = () => {
    document.querySelectorAll(".rc-tabs-tab").forEach((tab) => {
      (tab as HTMLElement).style.color = "";
    });

    const tabPanes = document.querySelectorAll(".rc-tabs-tabpane");
    const tabs = document.querySelectorAll(".rc-tabs-tab");

    tabPanes.forEach((tabPane, index) => {
      const uniquePaneId = `rc-tabs-tabpane-${index}`;
      if (!tabPane.hasAttribute("id")) {
        tabPane.setAttribute("id", uniquePaneId);
      }

      const uniqueTabId = `rc-tabs-tab-${index}`;
      if (tabs[index]) {
        if (!tabs[index].hasAttribute("id")) {
          tabs[index].setAttribute("id", uniqueTabId);
        }
        tabs[index].setAttribute("aria-controls", uniquePaneId);
      }
    });

    const errorElements = document.querySelectorAll(".rc-tabs .ant-form-item-explain-error");

    const tabsWithErrors = new Set<string>();

    errorElements.forEach((errorElement) => {
      const tabPane = errorElement.closest(".rc-tabs-tabpane");
      if (tabPane && tabPane.id) {
        tabsWithErrors.add(tabPane.id);
      }
    });

    tabsWithErrors.forEach((tabPaneId) => {
      const tab = document.querySelector(`.rc-tabs-tab[aria-controls="${tabPaneId}"]`);
      if (tab) {
        (tab as HTMLElement).style.color = "red";
      }
    });
  };

  OrganisationCardPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      kurumId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KurumId ?? this.props.screenInputs.kurumid,
        "Guid"
      ),
      kurumId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KurumId ?? this.props.screenInputs.kurumid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "OrganisationCard/OrganisationCardPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.KurumSelectById = result?.data.kurumSelectById;
    formVars.organisationcard_320842_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].logo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].logo
        : null
    );
    formVars.organisationcard_640843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].unvani
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].unvani
        : null
    );
    formVars.organisationcard_646751_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].kurumTipi
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].kurumTipi
        : null
    );
    stateVars.HesapKrediHareketleri = result?.data.hesapKrediHareketleri;

    stateVars.KrediBakiye = result?.data.krediBakiye;
    formVars.organisationcard_174116_value = ReactSystemFunctions.toString(
      this,
      stateVars.KrediBakiye?.length > 0
        ? stateVars.KrediBakiye[0].bakiye
        : this.state.KrediBakiye?.length > 0
        ? this.state.KrediBakiye[0].bakiye
        : null
    );
    stateVars.KurumunEgitmenleri = result?.data.kurumunEgitmenleri;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OrganisationCardPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrganisationCardPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.organisationcard_320842_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].logo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].logo
        : null
    );

    formVars.organisationcard_640843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].unvani
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].unvani
        : null
    );

    formVars.organisationcard_646751_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].kurumTipi
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].kurumTipi
        : null
    );

    formVars.organisationcard_174116_value = ReactSystemFunctions.toString(
      this,
      stateVars.KrediBakiye?.length > 0
        ? stateVars.KrediBakiye[0].bakiye
        : this.state.KrediBakiye?.length > 0
        ? this.state.KrediBakiye[0].bakiye
        : null
    );

    stateVars.dataSource_761946 = this.state.HesapKrediHareketleri;
    stateVars.isComp612070Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "organisationcard_761946_value", "isArtiVisible"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp233726Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "organisationcard_761946_value", "isMinusVisible"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp411851Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "organisationcard_761946_value", "isFromMe"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp751981Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "organisationcard_761946_value", "isFromMe"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp535575Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "organisationcard_761946_value", "isToMe"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp756175Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "organisationcard_761946_value", "isToMe"),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_7445753 = this.state.KurumunEgitmenleri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  OrganisationCardComponent_805655_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrganisationCardComponent_550051_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "KrediYukle",
      "KimeHesapId",
      ReactSystemFunctions.value(
        this,
        stateVars.KurumSelectById?.length > 0
          ? stateVars.KurumSelectById[0].hesapId
          : this.state.KurumSelectById?.length > 0
          ? this.state.KurumSelectById[0].hesapId
          : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrganisationCard",
      "KrediYukle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "101102",
      null,
      "right",
      null,
      "440px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrganisationCardComponent_863402_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "KrediTransferi",
      "KimdenHesapId",
      ReactSystemFunctions.value(
        this,
        stateVars.KurumSelectById?.length > 0
          ? stateVars.KurumSelectById[0].hesapId
          : this.state.KurumSelectById?.length > 0
          ? this.state.KurumSelectById[0].hesapId
          : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "OrganisationCard",
      "KrediTransferi",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "372956",
      null,
      "right",
      null,
      "440px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [101102, 372956] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.OrganisationCardPageInit();
    }
  }
}
