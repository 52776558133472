import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDrawer,
  KPanel,
  KRow,
  KTab,
  KTabItem,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  VerticalStack
} from "../../components/web-components";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { OrganisationCard_ScreenBase } from "./organisationcard-base";

import { KrediTransferi_Screen } from "../kreditransferi/kreditransferi";
import { KrediYukle_Screen } from "../krediyukle/krediyukle";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class OrganisationCard_Screen extends OrganisationCard_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              organisationcard_320842_value: this.state.KurumSelectById?.at?.(0)?.logo ?? undefined,
              organisationcard_668519_value: undefined,
              organisationcard_464517_value: "165cd63d-bfac-4128-c7a9-08dc41ea0a4e"
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="organisationcard_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 5,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              >
                <KRow
                  id="201603"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="866776"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="910191"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <Icon
                        id="805655"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.OrganisationCardComponent_805655_onClick();
                        }}
                        showCursorPointer
                        iconName="arrow_back"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "20px",
                            color: "rgba(155, 155, 155, 1)",
                            letterSpacing: "1px"
                          } as any
                        }
                      ></Icon>

                      <Label
                        id="483561"
                        value={ReactSystemFunctions.translate(this.ml, 483561, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></Label>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="884607"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>

                  <KCol
                    id="348288"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>

                  <KCol
                    id="280639"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>
                </KRow>
              </Header>

              <div
                id="organisationcard_body"
                style={
                  {
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="918067"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="24771"
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    xxl={3}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 0,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="organisationcard_320842_value">
                      <Image
                        id="320842"
                        zoomOnClick={false}
                        imageFit="fit"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            maxWidth: "80px",
                            maxHeight: "80px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></Image>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="993582"
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 32,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="640843"
                      value={this.state.KurumSelectById?.at?.(0)?.unvani ?? undefined}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "14pt",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    ></Label>

                    <Label
                      id="646751"
                      value={this.state.KurumSelectById?.at?.(0)?.kurumTipi ?? undefined}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="417071"
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>

                  <KCol
                    id="246112"
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>
                </KRow>

                <KRow
                  id="58838"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="408622"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="organisationcard_668519_value" noStyle>
                      <KTab
                        id="668519"
                        type="line"
                        size="default"
                        tabPosition="top"
                        selectedTabColor="#0078FF"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KTabItem
                          id="177218"
                          tab={ReactSystemFunctions.translate(this.ml, 177218, "tab", this.defaultML)}
                          forceRender={true}
                          key={177218}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="847792"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="278"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="579899"
                                direction="vertical"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KPanel
                                  id="619839"
                                  scrolling="hidden"
                                  scrollRadius={false}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="216902"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="636142"
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                      xl={8}
                                      xxl={8}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="organisationcard_464517_value">
                                        <Image
                                          id="464517"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              height: "60px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>
                                    </KCol>

                                    <KCol
                                      id="682519"
                                      xs={16}
                                      sm={16}
                                      md={16}
                                      lg={16}
                                      xl={16}
                                      xxl={16}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="144992"
                                        value={ReactSystemFunctions.translate(this.ml, 144992, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="174116"
                                        value={this.state.KrediBakiye?.at?.(0)?.bakiye ?? undefined}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "24px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>
                                  </KRow>
                                </KPanel>
                              </VerticalStack>
                            </KCol>

                            <KCol
                              id="676148"
                              xs={20}
                              sm={20}
                              md={20}
                              lg={20}
                              xl={20}
                              xxl={20}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 0,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KRow
                                id="57015"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="909096"
                                  xs={16}
                                  sm={16}
                                  md={16}
                                  lg={16}
                                  xl={16}
                                  xxl={16}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-right",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KTable
                                    id="761946"
                                    kuikaRef={this.organisationcard_761946_value_kuikaTableRef}
                                    form={this.props.form}
                                    dataSource={this.state.HesapKrediHareketleri}
                                    size="middle"
                                    bordered={true}
                                    showHeader={false}
                                    loading={false}
                                    nodatafoundmessage={ReactSystemFunctions.translate(
                                      this.ml,
                                      761946,
                                      "nodatafoundmessage",
                                      this.defaultML
                                    )}
                                    sorter={false}
                                    pagination={false}
                                    striped={false}
                                    stripedColor="#F5F7FA"
                                    insertRowActive={false}
                                    searchable={false}
                                    filtering={false}
                                    transformedOnMobileResolution={false}
                                    tableWidthMode="fit"
                                    columnChooser={false}
                                    fixedHeader={false}
                                    editable={false}
                                    globalSearch={false}
                                    pageSize={10}
                                    showSizeChanger={false}
                                    resizableColumns={false}
                                    insertRowPosition="top"
                                    showNoDataFound={true}
                                    multiSelect={false}
                                    export={false}
                                    editableAlwaysActive={false}
                                    multiSelectScope="page"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KTableHeader
                                      id="45439"
                                      hideOnMobileResolution={false}
                                      textDirection="Default"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          color: "rgba(10, 33, 64, 1)",
                                          lineHeight: "20px"
                                        } as any
                                      }
                                    ></KTableHeader>

                                    <KTableRow
                                      id="920600"
                                      hoverFontColor="red"
                                      hoverBgColor="#F5F5F5"
                                      style={
                                        {
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(218, 218, 218, 1)",
                                          borderStyle: "solid",
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <KTableColumn
                                        id="813856"
                                        title={ReactSystemFunctions.translate(this.ml, 813856, "title", this.defaultML)}
                                        alwaysVisibleOnMobileResolution={false}
                                        hideFiltering={false}
                                        hideSorting={false}
                                        hideSearch={false}
                                        footerOptions="none"
                                        footerFontSize={14}
                                        footerFontColor="rgb(68, 68, 68)"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            width: "150px",
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="671545"
                                          value="[datafield:tarih]"
                                          formatter="date"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </KTableColumn>

                                      <KTableColumn
                                        id="372146"
                                        title={ReactSystemFunctions.translate(this.ml, 372146, "title", this.defaultML)}
                                        alwaysVisibleOnMobileResolution={false}
                                        hideFiltering={false}
                                        hideSorting={false}
                                        hideSearch={false}
                                        footerOptions="none"
                                        footerFontSize={14}
                                        footerFontColor="rgb(68, 68, 68)"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            width: "150px",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <HorizontalStack
                                          id="63366"
                                          direction="horizontal"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Icon
                                            id="612070"
                                            visibility={this.state.isComp612070Visible}
                                            visibilityCondition={(rowData: object) =>
                                              ReactSystemFunctions.isEqualTo(rowData.isArtiVisible, "1")
                                            }
                                            iconName="add_circle"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "20px",
                                                color: "rgba(0, 175, 80, 1)",
                                                letterSpacing: "1px"
                                              } as any
                                            }
                                          ></Icon>

                                          <Icon
                                            id="233726"
                                            visibility={this.state.isComp233726Visible}
                                            visibilityCondition={(rowData: object) =>
                                              ReactSystemFunctions.isEqualTo(rowData.isMinusVisible, "1")
                                            }
                                            iconName="remove_circle"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "20px",
                                                color: "rgba(192, 0, 1, 1)",
                                                letterSpacing: "1px"
                                              } as any
                                            }
                                          ></Icon>

                                          <Label
                                            id="703487"
                                            value="[datafield:kredi]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </HorizontalStack>
                                      </KTableColumn>

                                      <KTableColumn
                                        id="579790"
                                        title={ReactSystemFunctions.translate(this.ml, 579790, "title", this.defaultML)}
                                        alwaysVisibleOnMobileResolution={false}
                                        hideFiltering={false}
                                        hideSorting={false}
                                        hideSearch={false}
                                        footerOptions="none"
                                        footerFontSize={14}
                                        footerFontColor="rgb(68, 68, 68)"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <HorizontalStack
                                          id="413698"
                                          direction="horizontal"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Form.Item className="kFormItem" name="organisationcard_411851_value">
                                            <Image
                                              id="411851"
                                              visibility={this.state.isComp411851Visible}
                                              visibilityCondition={(rowData: object) =>
                                                ReactSystemFunctions.isEqualTo(rowData.isFromMe, "1")
                                              }
                                              value="a23a7981-55c1-4bce-c7aa-08dc41ea0a4e"
                                              zoomOnClick={false}
                                              imageFit="fit"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  width: "20px",
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Image>
                                          </Form.Item>

                                          <Icon
                                            id="751981"
                                            visibility={this.state.isComp751981Visible}
                                            visibilityCondition={(rowData: object) =>
                                              ReactSystemFunctions.isEqualTo(rowData.isFromMe, "1")
                                            }
                                            iconName="forward"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "20px",
                                                color: "rgba(155, 155, 155, 1)",
                                                letterSpacing: "1px"
                                              } as any
                                            }
                                          ></Icon>

                                          <Label
                                            id="296248"
                                            value="[datafield:hesapTanimi]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <Icon
                                            id="535575"
                                            visibility={this.state.isComp535575Visible}
                                            visibilityCondition={(rowData: object) =>
                                              ReactSystemFunctions.isEqualTo(rowData.isToMe, "1")
                                            }
                                            iconName="forward"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "20px",
                                                color: "rgba(155, 155, 155, 1)",
                                                letterSpacing: "1px"
                                              } as any
                                            }
                                          ></Icon>

                                          <Form.Item className="kFormItem" name="organisationcard_756175_value">
                                            <Image
                                              id="756175"
                                              visibility={this.state.isComp756175Visible}
                                              visibilityCondition={(rowData: object) =>
                                                ReactSystemFunctions.isEqualTo(rowData.isToMe, "1")
                                              }
                                              value="a23a7981-55c1-4bce-c7aa-08dc41ea0a4e"
                                              zoomOnClick={false}
                                              imageFit="fit"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  width: "20px",
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Image>
                                          </Form.Item>
                                        </HorizontalStack>
                                      </KTableColumn>
                                    </KTableRow>
                                  </KTable>
                                </KCol>

                                <KCol
                                  id="718967"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-right",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <VerticalStack
                                    id="850963"
                                    direction="vertical"
                                    size={12}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Button
                                      id="550051"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.OrganisationCardComponent_550051_onClick();
                                      }}
                                      showCursorPointer
                                      label={ReactSystemFunctions.translate(this.ml, 550051, "label", this.defaultML)}
                                      size="middle"
                                      loading={false}
                                      ghost={false}
                                      block={false}
                                      htmlType="button"
                                      iconPosition="left"
                                      danger={false}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(0, 190, 214, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 16,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                          color: "rgba(255, 255, 255, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Button>

                                    <Button
                                      id="863402"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.OrganisationCardComponent_863402_onClick();
                                      }}
                                      showCursorPointer
                                      label={ReactSystemFunctions.translate(this.ml, 863402, "label", this.defaultML)}
                                      size="middle"
                                      loading={false}
                                      ghost={false}
                                      block={false}
                                      htmlType="button"
                                      iconPosition="left"
                                      danger={false}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(0, 190, 214, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 16,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "16px",
                                          color: "rgba(255, 255, 255, 1)",
                                          lineHeight: "22px"
                                        } as any
                                      }
                                    ></Button>
                                  </VerticalStack>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>
                        </KTabItem>

                        <KTabItem
                          id="342138"
                          tab={ReactSystemFunctions.translate(this.ml, 342138, "tab", this.defaultML)}
                          forceRender={true}
                          key={342138}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KTable
                            id="7445753"
                            kuikaRef={this.organisationcard_7445753_value_kuikaTableRef}
                            form={this.props.form}
                            dataSource={this.state.KurumunEgitmenleri}
                            size="middle"
                            bordered={true}
                            showHeader={true}
                            loading={false}
                            nodatafoundmessage={ReactSystemFunctions.translate(
                              this.ml,
                              7445753,
                              "nodatafoundmessage",
                              this.defaultML
                            )}
                            sorter={false}
                            filtering={false}
                            pagination={false}
                            striped={false}
                            stripedColor="#F5F7FA"
                            insertRowActive={false}
                            transformedOnMobileResolution={false}
                            tableWidthMode="fit"
                            searchable={false}
                            columnChooser={false}
                            fixedHeader={false}
                            editable={false}
                            globalSearch={false}
                            pageSize={10}
                            showSizeChanger={false}
                            resizableColumns={false}
                            insertRowPosition="top"
                            showNoDataFound={true}
                            multiSelect={false}
                            export={false}
                            editableAlwaysActive={false}
                            multiSelectScope="page"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KTableHeader
                              id="8290499"
                              hideOnMobileResolution={false}
                              textDirection="Default"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "rgba(10, 33, 64, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            ></KTableHeader>

                            <KTableRow
                              id="5379206"
                              hoverFontColor="red"
                              hoverBgColor="#F5F5F5"
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  height: "30px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KTableColumn
                                id="6071096"
                                title={ReactSystemFunctions.translate(this.ml, 6071096, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                footerFontSize={14}
                                footerFontColor="rgb(68, 68, 68)"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="organisationcard_7111705_value">
                                  <Image
                                    id="7111705"
                                    value="[datafield:resim]"
                                    zoomOnClick={false}
                                    imageFit="fit"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        height: "54px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>
                              </KTableColumn>

                              <KTableColumn
                                id="6497983"
                                title={ReactSystemFunctions.translate(this.ml, 6497983, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                footerFontSize={14}
                                footerFontColor="rgb(68, 68, 68)"
                                style={
                                  {
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="3999776"
                                  value="[datafield:adi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="6582741"
                                title={ReactSystemFunctions.translate(this.ml, 6582741, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                footerFontSize={14}
                                footerFontColor="rgb(68, 68, 68)"
                                style={
                                  {
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="3799077"
                                  value="[datafield:soyadi]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="6315889"
                                title={ReactSystemFunctions.translate(this.ml, 6315889, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                footerFontSize={14}
                                footerFontColor="rgb(68, 68, 68)"
                                style={
                                  {
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="2107075"
                                  value="[datafield:eposta]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="7087323"
                                title={ReactSystemFunctions.translate(this.ml, 7087323, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                footerFontSize={14}
                                footerFontColor="rgb(68, 68, 68)"
                                style={
                                  {
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="3097598"
                                  value="[datafield:telefon]"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>

                              <KTableColumn
                                id="3338172"
                                title={ReactSystemFunctions.translate(this.ml, 3338172, "title", this.defaultML)}
                                alwaysVisibleOnMobileResolution={false}
                                hideFiltering={false}
                                hideSorting={false}
                                hideSearch={false}
                                footerOptions="none"
                                footerFontSize={14}
                                footerFontColor="rgb(68, 68, 68)"
                                style={
                                  {
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="8897817"
                                  value="[datafield:dogumTarihi]"
                                  formatter="date"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KTableColumn>
                            </KTableRow>
                          </KTable>
                        </KTabItem>
                      </KTab>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KDrawer
                  id="101102"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible101102: false }, () => {
                      this.callCallbackFunction(101102);
                    });
                  }}
                  visible={this.state.NavVisible101102}
                  width="440px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible101102 && (
                      <KrediYukle_Screen
                        onClose={() => {
                          this.setState({ NavVisible101102: false }, () => {
                            this.callCallbackFunction(101102);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="372956"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible372956: false }, () => {
                      this.callCallbackFunction(372956);
                    });
                  }}
                  visible={this.state.NavVisible372956}
                  width="440px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible372956 && (
                      <KrediTransferi_Screen
                        onClose={() => {
                          this.setState({ NavVisible372956: false }, () => {
                            this.callCallbackFunction(372956);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(OrganisationCard_Screen))))
  )
);
export { tmp as OrganisationCard_Screen };
//export default tmp;
//export { tmp as OrganisationCard_Screen };
