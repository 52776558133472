import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBasvuruEgitmenDetay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBasvuruEgitmenDetay_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  SelectBasvuruEgitmen: any[];
  SelectBasvuruEgitmen_dummy: any[];
  ServiceAll: any[];
  ServiceAll_dummy: any[];
  MerkezAll: any[];
  MerkezAll_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  BasvuruEgitmenReddet: number;
  BasvuruEgitmenReddet_dummy: number;
  BasvuruEgitmenOnayla: number;
  BasvuruEgitmenOnayla_dummy: number;
  KurumLogoByKurumID: any[];
  KurumLogoByKurumID_dummy: any[];
  KUserByEposta: any[];
  KUserByEposta_dummy: any[];
  CreateUser: string;
  CreateUser_dummy: string;
  KisiByEmail: any[];
  KisiByEmail_dummy: any[];
  UserByEmail: any[];
  UserByEmail_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  BaseUrl: any[];
  BaseUrl_dummy: any[];
  TokenOfUser: any[];
  TokenOfUser_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  isComp8953007Visible: "visible" | "hidden";
  isComp3173525Visible: "visible" | "hidden";
  isComp8237260Visible: "visible" | "hidden";
  isComp6165486Visible: "visible" | "hidden";
  isComp8694517Visible: "visible" | "hidden";
  isComp4940011Visible: "visible" | "hidden";
  isComp5087320Visible: "visible" | "hidden";
  isComp874048Enabled: "enabled" | "disabled";
  isComp709260Enabled: "enabled" | "disabled";
}

export class BasvuruEgitmenDetay_ScreenBase extends React.PureComponent<IBasvuruEgitmenDetay_ScreenProps, any> {
  basvuruegitmendetay_804623_value_kuikaMultiSelectRef: React.RefObject<any>;
  basvuruegitmendetay_912613_value_kuikaSelectBoxRef: React.RefObject<any>;
  basvuruegitmendetay_545654_value_kuikaDateRef: React.RefObject<any>;
  basvuruegitmendetay_343725_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Trainer Application", TagName: "lblHead_value" },
        { Id: 1333779, PropertyName: "value", Value: "Name Surname", TagName: "lblAd_1333779_value" },
        { Id: 8406194, PropertyName: "value", Value: "Biological Gender", TagName: "lblCinsiyet_8406194_value" },
        { Id: 2304294, PropertyName: "value", Value: "E-mail", TagName: "lblEmail_2304294_value" },
        { Id: 9330945, PropertyName: "value", Value: "Mobile phone", TagName: "lblCepTelefonu_9330945_value" },
        { Id: 1116683, PropertyName: "value", Value: "Birth date", TagName: "lblDogumTarihi_1116683_value" },
        { Id: 7391551, PropertyName: "value", Value: "Education Level", TagName: "lblEgitimDuzey_7391551_value" },
        { Id: 3048911, PropertyName: "value", Value: "School of graduation", TagName: "lblLisansMezun_3048911_value" },
        { Id: 8768062, PropertyName: "value", Value: "Department", TagName: "lblLisansBolum_8768062_value" },
        { Id: 3982047, PropertyName: "value", Value: "Postgraduate", TagName: "lblLisansustuDerece_3982047_value" },
        { Id: 6878697, PropertyName: "value", Value: "Research topic", TagName: "lblLisansustuKonu_6878697_value" },
        { Id: 3056754, PropertyName: "value", Value: "Degree", TagName: "lblLiseDerece_3056754_value" },
        { Id: 5169745, PropertyName: "value", Value: "Certificate", TagName: "lblLiseSertifika_5169745_value" },
        { Id: 1372981, PropertyName: "value", Value: "University", TagName: "lblOgrenciUni_1372981_value" },
        { Id: 4489629, PropertyName: "value", Value: "Department", TagName: "lblOgrenciBolum_4489629_value" },
        { Id: 4642527, PropertyName: "value", Value: "Profession", TagName: "lblMeslek_4642527_value" },
        { Id: 8998200, PropertyName: "value", Value: "Experience", TagName: "lblTecrubeSure_8998200_value" },
        { Id: 865657, PropertyName: "value", Value: "Special Population", TagName: "label2_value" },
        { Id: 9975869, PropertyName: "value", Value: "Club", TagName: "lblAntrenorKlup_9975869_value" },
        { Id: 8786072, PropertyName: "value", Value: "Branch", TagName: "lblAntrenorBrans_8786072_value" },
        { Id: 2007466, PropertyName: "value", Value: "School", TagName: "lblBedenOkul_2007466_value" },
        { Id: 2355371, PropertyName: "value", Value: "Class", TagName: "lblBedenSinif_2355371_value" },
        { Id: 5532961, PropertyName: "value", Value: "Location", TagName: "lblFitnessMerkez_5532961_value" },
        {
          Id: 1709881,
          PropertyName: "value",
          Value: "The classes you provide",
          TagName: "lblFitnessDers_1709881_value"
        },
        {
          Id: 3647503,
          PropertyName: "value",
          Value: "Would you like to be a part of the MeMap platform?",
          TagName: "lblMemapYer_3647503_value"
        },
        {
          Id: 491633,
          PropertyName: "value",
          Value: "I want to get information about the benefits of the MeMap platform.",
          TagName: "Label1_0_value"
        },
        { Id: 233539, PropertyName: "label", Value: "Reject", TagName: "Button1_label" },
        { Id: 26578, PropertyName: "value", Value: "Accredited services", TagName: "label3_value" },
        { Id: 48637, PropertyName: "value", Value: "Location to work at", TagName: "label4_value" },
        { Id: 912613, PropertyName: "placeholder", Value: "Choose...", TagName: "selMerkez_placeholder" },
        { Id: 353692, PropertyName: "value", Value: "License due date", TagName: "label5_value" },
        { Id: 545654, PropertyName: "placeholder", Value: "Choose...", TagName: "dtExpiration_placeholder" },
        { Id: 391319, PropertyName: "label", Value: "Confirm", TagName: "Button2_label" },
        { Id: 303448, PropertyName: "value", Value: "Location where the trainer will work", TagName: "Label3_value" },
        { Id: 362689, PropertyName: "value", Value: "Licence Provider", TagName: "Label4_value" },
        { Id: 343725, PropertyName: "placeholder", Value: "Choose..", TagName: "selLicenceProvider_placeholder" },
        { Id: 934743, PropertyName: "value", Value: "Credit to be loaded", TagName: "Label2_value" },
        { Id: 412386, PropertyName: "placeholder", Value: "15", TagName: "numYuklenecekKredi_placeholder" },
        { Id: 5868, PropertyName: "value", Value: "Platform appearance", TagName: "Label57_value" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "cc4b9198-285c-453c-9007-7d4f631e30b6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Eğitmen Başvuru" },
        { Id: 1333779, PropertyName: "value", Value: "Ad Soyad" },
        { Id: 8406194, PropertyName: "value", Value: "Cinsiyet" },
        { Id: 2304294, PropertyName: "value", Value: "Email" },
        { Id: 9330945, PropertyName: "value", Value: "Cep Telefonu" },
        { Id: 1116683, PropertyName: "value", Value: "Dogum Tarihi" },
        { Id: 7391551, PropertyName: "value", Value: "Eğitim Düzeyi" },
        { Id: 3048911, PropertyName: "value", Value: "Mezun olduğu okul" },
        { Id: 8768062, PropertyName: "value", Value: "Bölüm" },
        { Id: 3982047, PropertyName: "value", Value: "Lisans Üstü" },
        { Id: 6878697, PropertyName: "value", Value: "Tez Konusu" },
        { Id: 3056754, PropertyName: "value", Value: "Derece" },
        { Id: 5169745, PropertyName: "value", Value: "Sertifika" },
        { Id: 1372981, PropertyName: "value", Value: "Üniversite" },
        { Id: 4489629, PropertyName: "value", Value: "Bölüm" },
        { Id: 4642527, PropertyName: "value", Value: "Meslek" },
        { Id: 8998200, PropertyName: "value", Value: "Meslek Tecrübesi" },
        { Id: 9975869, PropertyName: "value", Value: "Kulüp" },
        { Id: 8786072, PropertyName: "value", Value: "Branş" },
        { Id: 2007466, PropertyName: "value", Value: "Okul" },
        { Id: 2355371, PropertyName: "value", Value: "Sınıf" },
        { Id: 5532961, PropertyName: "value", Value: "Merkez" },
        { Id: 1709881, PropertyName: "value", Value: "Verdiği Dersler" },
        { Id: 865657, PropertyName: "value", Value: "Özel Populasyon" },
        { Id: 3647503, PropertyName: "value", Value: "MeMap platformunda yer almak ister misiniz ?" },
        { Id: 491633, PropertyName: "value", Value: "MeMap platformunun faydaları hakkında bilgi almak istiyorum" },
        { Id: 26578, PropertyName: "value", Value: "Akredite Hizmetler" },
        { Id: 804623, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 303448, PropertyName: "value", Value: "Çalışacağı Merkez" },
        { Id: 912613, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 48637, PropertyName: "value", Value: "Rate" },
        { Id: 353692, PropertyName: "value", Value: "Lisans Bitiş Tarihi" },
        { Id: 545654, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 362689, PropertyName: "value", Value: "Lisans Sağlayıcı" },
        { Id: 343725, PropertyName: "placeholder", Value: "Seçiniz.." },
        { Id: 934743, PropertyName: "value", Value: "Yüklenecek Kredi" },
        { Id: 412386, PropertyName: "placeholder", Value: "15" },
        { Id: 5868, PropertyName: "value", Value: "Platform Görünümü" },
        { Id: 233539, PropertyName: "label", Value: "Reddet" },
        { Id: 391319, PropertyName: "label", Value: "Onayla" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.basvuruegitmendetay_804623_value_kuikaMultiSelectRef = React.createRef();
    this.basvuruegitmendetay_912613_value_kuikaSelectBoxRef = React.createRef();
    this.basvuruegitmendetay_545654_value_kuikaDateRef = React.createRef();
    this.basvuruegitmendetay_343725_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      SelectBasvuruEgitmen: [],
      ServiceAll: [],
      MerkezAll: [],
      ChangeEnabledOf: "",
      BasvuruEgitmenReddet: 0,
      BasvuruEgitmenOnayla: 0,
      KurumLogoByKurumID: [],
      KUserByEposta: [],
      CreateUser: "",
      KisiByEmail: [],
      UserByEmail: [],
      SaveRecord: false,
      BaseUrl: [],
      TokenOfUser: [],
      IfThenElse: false,
      isComp8953007Visible: "hidden",
      isComp3173525Visible: "hidden",
      isComp8237260Visible: "hidden",
      isComp6165486Visible: "hidden",
      isComp8694517Visible: "hidden",
      isComp4940011Visible: "hidden",
      isComp5087320Visible: "hidden",
      isComp874048Enabled: "enabled",
      isComp709260Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("basvuruegitmendetay", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.BasvuruEgitmenDetayPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("basvuruegitmendetay", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("basvuruegitmendetay", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BasvuruEgitmenDetayPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      basvuruegitmendetay_7597451_value: this.state.SelectBasvuruEgitmen?.at?.(0)?.foto ?? undefined,
      basvuruegitmendetay_709260_value: this.state.SelectBasvuruEgitmen?.at?.(0)?.memapYer ?? undefined,
      basvuruegitmendetay_874048_value: this.state.SelectBasvuruEgitmen?.at?.(0)?.memapFayda ?? undefined,
      basvuruegitmendetay_262520_value: 3,
      basvuruegitmendetay_412386_value: undefined,
      basvuruegitmendetay_368150_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  BasvuruEgitmenDetayPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BasvuruEgitmenDetay/BasvuruEgitmenDetayPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.SelectBasvuruEgitmen = result?.data.selectBasvuruEgitmen;
    formVars.basvuruegitmendetay_7597451_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].foto
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].foto
        : null
    );
    formVars.basvuruegitmendetay_7965712_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ad
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ad
        : null
    );
    formVars.basvuruegitmendetay_4810327_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].soyad
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].soyad
        : null
    );
    formVars.basvuruegitmendetay_4430370_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].cinsiyet
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].cinsiyet
        : null
    );
    formVars.basvuruegitmendetay_1001417_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].email
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].email
        : null
    );
    formVars.basvuruegitmendetay_4391480_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].cepTelefonu
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].cepTelefonu
        : null
    );
    formVars.basvuruegitmendetay_1862274_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].dogumTarihi
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].dogumTarihi
        : null
    );
    formVars.basvuruegitmendetay_7868884_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
        : null
    );
    formVars.basvuruegitmendetay_6694261_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansMezun
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansMezun
        : null
    );
    formVars.basvuruegitmendetay_7497860_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansBolum
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansBolum
        : null
    );
    formVars.basvuruegitmendetay_4517527_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansustuDerece
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansustuDerece
        : null
    );
    formVars.basvuruegitmendetay_1319389_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansustuKonu
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansustuKonu
        : null
    );
    formVars.basvuruegitmendetay_7924933_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].liseDerece
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].liseDerece
        : null
    );
    formVars.basvuruegitmendetay_7563921_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].liseSertifika
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].liseSertifika
        : null
    );
    formVars.basvuruegitmendetay_9424610_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ogrenciUni
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ogrenciUni
        : null
    );
    formVars.basvuruegitmendetay_5457052_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ogrenciBolum
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ogrenciBolum
        : null
    );
    formVars.basvuruegitmendetay_4034303_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].meslek
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].meslek
        : null
    );
    formVars.basvuruegitmendetay_3957459_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].tecrubeSure
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].tecrubeSure
        : null
    );
    formVars.basvuruegitmendetay_1479436_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].antrenorKlup
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].antrenorKlup
        : null
    );
    formVars.basvuruegitmendetay_7983305_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].antrenorBrans
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].antrenorBrans
        : null
    );
    formVars.basvuruegitmendetay_8325109_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].bedenOkul
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].bedenOkul
        : null
    );
    formVars.basvuruegitmendetay_9637686_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].bedenSinif
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].bedenSinif
        : null
    );
    formVars.basvuruegitmendetay_6668765_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].fitnessMerkez
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].fitnessMerkez
        : null
    );
    formVars.basvuruegitmendetay_7596153_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].fitnessDers
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].fitnessDers
        : null
    );
    formVars.basvuruegitmendetay_6531374_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ozelPopulasyon
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ozelPopulasyon
        : null
    );
    formVars.basvuruegitmendetay_709260_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].memapYer
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].memapYer
        : null
    );
    formVars.basvuruegitmendetay_874048_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].memapFayda
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].memapFayda
        : null
    );
    stateVars.isComp8953007Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Lisans"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3173525Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Lisans üstü"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp8237260Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Lise"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp6165486Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Spor bilimleri fakültesinde öğrenciyim"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp8694517Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        "Antrenör"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp4940011Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        "Beden eğitimi öğretmeni"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp5087320Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        "Fitness eğitmeni"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.ServiceAll = result?.data.serviceAll;

    formVars.basvuruegitmendetay_804623_options = stateVars.ServiceAll;
    stateVars.MerkezAll = result?.data.merkezAll;

    formVars.basvuruegitmendetay_912613_options = stateVars.MerkezAll;

    formVars.basvuruegitmendetay_343725_options = stateVars.MerkezAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvuruEgitmenDetayPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvuruEgitmenDetayPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp874048Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp709260Enabled", "disabled");
    formVars.basvuruegitmendetay_7597451_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].foto
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].foto
        : null
    );

    formVars.basvuruegitmendetay_7965712_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ad
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ad
        : null
    );

    formVars.basvuruegitmendetay_4810327_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].soyad
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].soyad
        : null
    );

    formVars.basvuruegitmendetay_4430370_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].cinsiyet
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].cinsiyet
        : null
    );

    formVars.basvuruegitmendetay_1001417_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].email
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].email
        : null
    );

    formVars.basvuruegitmendetay_4391480_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].cepTelefonu
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].cepTelefonu
        : null
    );

    formVars.basvuruegitmendetay_1862274_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].dogumTarihi
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].dogumTarihi
        : null
    );

    formVars.basvuruegitmendetay_7868884_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
        : null
    );

    stateVars.isComp8953007Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Lisans"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvuruegitmendetay_6694261_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansMezun
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansMezun
        : null
    );

    formVars.basvuruegitmendetay_7497860_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansBolum
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansBolum
        : null
    );

    stateVars.isComp3173525Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Lisans üstü"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvuruegitmendetay_4517527_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansustuDerece
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansustuDerece
        : null
    );

    formVars.basvuruegitmendetay_1319389_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansustuKonu
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansustuKonu
        : null
    );

    stateVars.isComp8237260Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Lise"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvuruegitmendetay_7924933_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].liseDerece
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].liseDerece
        : null
    );

    formVars.basvuruegitmendetay_7563921_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].liseSertifika
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].liseSertifika
        : null
    );

    stateVars.isComp6165486Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Spor bilimleri fakültesinde öğrenciyim"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvuruegitmendetay_9424610_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ogrenciUni
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ogrenciUni
        : null
    );

    formVars.basvuruegitmendetay_5457052_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ogrenciBolum
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ogrenciBolum
        : null
    );

    formVars.basvuruegitmendetay_4034303_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].meslek
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].meslek
        : null
    );

    formVars.basvuruegitmendetay_3957459_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].tecrubeSure
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].tecrubeSure
        : null
    );

    stateVars.isComp8694517Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        "Antrenör"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvuruegitmendetay_1479436_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].antrenorKlup
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].antrenorKlup
        : null
    );

    formVars.basvuruegitmendetay_7983305_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].antrenorBrans
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].antrenorBrans
        : null
    );

    stateVars.isComp4940011Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        "Beden eğitimi öğretmeni"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvuruegitmendetay_8325109_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].bedenOkul
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].bedenOkul
        : null
    );

    formVars.basvuruegitmendetay_9637686_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].bedenSinif
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].bedenSinif
        : null
    );

    stateVars.isComp5087320Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        "Fitness eğitmeni"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvuruegitmendetay_6668765_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].fitnessMerkez
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].fitnessMerkez
        : null
    );

    formVars.basvuruegitmendetay_7596153_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].fitnessDers
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].fitnessDers
        : null
    );

    formVars.basvuruegitmendetay_6531374_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ozelPopulasyon
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ozelPopulasyon
        : null
    );

    formVars.basvuruegitmendetay_709260_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].memapYer
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].memapYer
        : null
    );

    formVars.basvuruegitmendetay_874048_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].memapFayda
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].memapFayda
        : null
    );

    stateVars.dataSource_804623 = this.state.ServiceAll;
    stateVars.dataSource_804623 = this.state.ServiceAll;

    stateVars.dataSource_912613 = this.state.MerkezAll;
    stateVars.dataSource_912613 = this.state.MerkezAll;

    stateVars.dataSource_343725 = this.state.MerkezAll;
    stateVars.dataSource_343725 = this.state.MerkezAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BasvuruEgitmenDetayComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BasvuruEgitmenDetayComponent_233539_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      basvuruEgitmenId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Id ?? this.props.screenInputs.id,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruEgitmenDetay/BasvuruEgitmenDetayComponent_233539_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BasvuruEgitmenReddet = result?.data.basvuruEgitmenReddet;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvuruEgitmenDetayComponent_233539_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvuruEgitmenDetayComponent_233539_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BasvuruEgitmenDetayComponent_391319_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "basvuruegitmendetay_912613_value", "value", "MerkezAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "basvuruegitmendetay_912613_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "basvuruegitmendetay_804623_value", "value", "ServiceAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "basvuruegitmendetay_804623_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvuruegitmendetay_545654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "basvuruegitmendetay_545654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      basvuruEgitmenId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Id ?? this.props.screenInputs.id,
        "Guid"
      ),
      seciliKurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvuruegitmendetay_912613_value", "value", "MerkezAll", "id", "id")
        ),
        "Guid"
      ),
      akrediteHizmetIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "basvuruegitmendetay_804623_value", "value", "ServiceAll", "id", "id")
        ),
        "string"
      ),
      exprationDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvuruegitmendetay_545654_value", "value", "", "", "")
        ),
        "Date"
      ),
      rate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvuruegitmendetay_262520_value", "value", "", "", "")
        ),
        "number"
      ),
      licenceProviderId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvuruegitmendetay_343725_value", "value", "MerkezAll", "id", "id")
        ),
        "Guid"
      ),
      yuklenecekKredi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvuruegitmendetay_412386_value", "value", "", "", "")
        ),
        "number"
      ),
      platformGorunumu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvuruegitmendetay_368150_value", "value", "", "", "")
        ),
        "boolean"
      ),
      kurumId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvuruegitmendetay_912613_value", "value", "MerkezAll", "id", "id")
        ),
        "Guid"
      ),
      Email_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].email
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].email
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruEgitmenDetay/BasvuruEgitmenDetayComponent_391319_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BasvuruEgitmenOnayla = result?.data.basvuruEgitmenOnayla;
    stateVars.KurumLogoByKurumID = result?.data.kurumLogoByKurumID;
    stateVars.KUserByEposta = result?.data.kUserByEposta;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvuruEgitmenDetayComponent_391319_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvuruEgitmenDetayComponent_391319_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KUserByEposta?.length > 0
            ? stateVars.KUserByEposta[0].id
            : this.state.KUserByEposta?.length > 0
            ? this.state.KUserByEposta[0].id
            : null
        ),
        null
      )
    ) {
      stateVars.CreateUser = await ReactSystemFunctions.createUser(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].email
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].email
            : null
        ),
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].ad
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].ad
            : null
        ),
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].soyad
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].soyad
            : null
        ),
        true,
        "",
        ""
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BasvuruEgitmenDetayComponent_391319_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BasvuruEgitmenDetayComponent_391319_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BasvuruEgitmenDetayComponent_391319_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].email
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].email
            : null
        ),
        "string"
      ),
      email_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].email
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].email
            : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruEgitmenDetay/BasvuruEgitmenDetayComponent_391319_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiByEmail = result?.data.kisiByEmail;
    stateVars.UserByEmail = result?.data.userByEmail;
    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.BaseUrl = result?.data.baseUrl;
    stateVars.TokenOfUser = result?.data.tokenOfUser;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvuruEgitmenDetayComponent_391319_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvuruEgitmenDetayComponent_391319_onClick_if3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("912b1e84-8a2a-478a-86ce-4cffc534c1aa", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].email
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].email
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("MeMap Eğitmen Hesabınız açıldı!", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BaseUrl?.length > 0
            ? stateVars.BaseUrl[0].baseUrl
            : this.state.BaseUrl?.length > 0
            ? this.state.BaseUrl[0].baseUrl
            : null
        ),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].email
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].email
            : null
        ),
        "string"
      ),
      verificationCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TokenOfUser?.length > 0
            ? stateVars.TokenOfUser[0].token
            : this.state.TokenOfUser?.length > 0
            ? this.state.TokenOfUser[0].token
            : null
        ),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lang
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lang
            : null
        ),
        "string"
      ),
      kurumlogo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KurumLogoByKurumID?.length > 0
            ? stateVars.KurumLogoByKurumID[0].kurumImgUrl
            : this.state.KurumLogoByKurumID?.length > 0
            ? this.state.KurumLogoByKurumID[0].kurumImgUrl
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruEgitmenDetay/BasvuruEgitmenDetayComponent_391319_onClick_if3",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  BasvuruEgitmenDetayComponent_391319_onClick_else3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("55f69deb-a389-4d15-9c8b-a84d0d8b1e95", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].email
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].email
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("MeMap Yeni Eğitmen Hesabınız açıldı!", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BaseUrl?.length > 0
            ? stateVars.BaseUrl[0].baseUrl
            : this.state.BaseUrl?.length > 0
            ? this.state.BaseUrl[0].baseUrl
            : null
        ),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].email
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].email
            : null
        ),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lang
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lang
            : null
        ),
        "string"
      ),
      kurumlogo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KurumLogoByKurumID?.length > 0
            ? stateVars.KurumLogoByKurumID[0].kurumImgUrl
            : this.state.KurumLogoByKurumID?.length > 0
            ? this.state.KurumLogoByKurumID[0].kurumImgUrl
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruEgitmenDetay/BasvuruEgitmenDetayComponent_391319_onClick_else3",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  BasvuruEgitmenDetayComponent_391319_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KUserByEposta?.length > 0
            ? stateVars.KUserByEposta[0].id
            : this.state.KUserByEposta?.length > 0
            ? this.state.KUserByEposta[0].id
            : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.BasvuruEgitmenDetayComponent_391319_onClick_if3();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.BasvuruEgitmenDetayComponent_391319_onClick_else3();
      if (isErrorOccurred) return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
