import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProtocolBilesenF2_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProtocolBilesenF2_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  SiniflandirmaAll: any[];
  SiniflandirmaAll_dummy: any[];
  ProtokolSelectById: any[];
  ProtokolSelectById_dummy: any[];
  ProtokolBilesenById: any[];
  ProtokolBilesenById_dummy: any[];
  BilesenSelectById: any[];
  BilesenSelectById_dummy: any[];
  BilesenSiniflandirmaRaporAciklama: any[];
  BilesenSiniflandirmaRaporAciklama_dummy: any[];
  SiniflandirmaSeviyeleri: any[];
  SiniflandirmaSeviyeleri_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  isComp594938Visible: "visible" | "hidden";
  isComp286959Visible: "visible" | "hidden";
  isComp154471Visible: "visible" | "hidden";
  isComp264710Visible: "visible" | "hidden";
  isComp882780Visible: "visible" | "hidden";
  isComp910124Visible: "visible" | "hidden";
  isComp514465Visible: "visible" | "hidden";
  isComp648318Visible: "visible" | "hidden";
}

export class ProtocolBilesenF2_ScreenBase extends React.PureComponent<IProtocolBilesenF2_ScreenProps, any> {
  protocolbilesenf2_436800_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "e0a77251-49dd-4c6e-8ffb-63c346377ec6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 634568, PropertyName: "value", Value: "Protokol Bileşen Sınıflandırma Seviyeleri Açıklamaları" },
        { Id: 196500, PropertyName: "value", Value: "Sınıflandırma" },
        { Id: 436800, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 967318, PropertyName: "value", Value: "Rapor Açıklaması" },
        { Id: 52244, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 509578, PropertyName: "value", Value: "Rapor Açıklaması" },
        { Id: 626565, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 253361, PropertyName: "value", Value: "Rapor Açıklaması" },
        { Id: 592190, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 934805, PropertyName: "value", Value: "Rapor Açıklaması" },
        { Id: 308696, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 496019, PropertyName: "value", Value: "Rapor Açıklaması" },
        { Id: 16146, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 114698, PropertyName: "value", Value: "Rapor Açıklaması" },
        { Id: 317609, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 336078, PropertyName: "value", Value: "Rapor Açıklaması" },
        { Id: 379240, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 268967, PropertyName: "value", Value: "Rapor Açıklaması" },
        { Id: 531390, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.protocolbilesenf2_436800_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SiniflandirmaAll: [],
      ProtokolSelectById: [],
      ProtokolBilesenById: [],
      BilesenSelectById: [],
      BilesenSiniflandirmaRaporAciklama: [],
      SiniflandirmaSeviyeleri: [],
      SaveRecord: false,
      isComp594938Visible: "hidden",
      isComp286959Visible: "hidden",
      isComp154471Visible: "hidden",
      isComp264710Visible: "hidden",
      isComp882780Visible: "hidden",
      isComp910124Visible: "hidden",
      isComp514465Visible: "hidden",
      isComp648318Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("protocolbilesenf2", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProtocolBilesenF2PageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("protocolbilesenf2", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("protocolbilesenf2", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProtocolBilesenF2PageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      protocolbilesenf2_436800_value: this.state.ProtokolBilesenById?.at?.(0)?.siniflandirmaId ?? undefined,
      protocolbilesenf2_52244_value:
        this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye1Aciklama ?? undefined,
      protocolbilesenf2_626565_value:
        this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye2Aciklama ?? undefined,
      protocolbilesenf2_592190_value:
        this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye3Aciklama ?? undefined,
      protocolbilesenf2_308696_value:
        this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye4Aciklama ?? undefined,
      protocolbilesenf2_16146_value:
        this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye5Aciklama ?? undefined,
      protocolbilesenf2_317609_value:
        this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye6Aciklama ?? undefined,
      protocolbilesenf2_379240_value:
        this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye7Aciklama ?? undefined,
      protocolbilesenf2_531390_value:
        this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye8Aciklama ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ProtocolBilesenF2PageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid,
        "Guid"
      ),
      protokolId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProtocolBilesenF2/ProtocolBilesenF2PageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiniflandirmaAll = result?.data.siniflandirmaAll;

    formVars.protocolbilesenf2_436800_value =
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].siniflandirmaId
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].siniflandirmaId
        : null;
    formVars.protocolbilesenf2_436800_options = stateVars.SiniflandirmaAll;
    stateVars.ProtokolSelectById = result?.data.protokolSelectById;
    stateVars.ProtokolBilesenById = result?.data.protokolBilesenById;
    formVars.protocolbilesenf2_436800_value =
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].siniflandirmaId
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].siniflandirmaId
        : null;
    formVars.protocolbilesenf2_436800_options = stateVars.SiniflandirmaAll;
    stateVars.BilesenSelectById = result?.data.bilesenSelectById;
    stateVars.BilesenSiniflandirmaRaporAciklama = result?.data.bilesenSiniflandirmaRaporAciklama;
    formVars.protocolbilesenf2_963811_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye1Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye1Adi
        : null
    );
    formVars.protocolbilesenf2_52244_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye1Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye1Aciklama
        : null
    );
    formVars.protocolbilesenf2_913903_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye2Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye2Adi
        : null
    );
    formVars.protocolbilesenf2_626565_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye2Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye2Aciklama
        : null
    );
    formVars.protocolbilesenf2_359711_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye3Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye3Adi
        : null
    );
    formVars.protocolbilesenf2_592190_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye3Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye3Aciklama
        : null
    );
    formVars.protocolbilesenf2_582368_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye4Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye4Adi
        : null
    );
    formVars.protocolbilesenf2_308696_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye4Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye4Aciklama
        : null
    );
    formVars.protocolbilesenf2_928483_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye5Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye5Adi
        : null
    );
    formVars.protocolbilesenf2_16146_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye5Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye5Aciklama
        : null
    );
    formVars.protocolbilesenf2_191609_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye6Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye6Adi
        : null
    );
    formVars.protocolbilesenf2_317609_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye6Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye6Aciklama
        : null
    );
    formVars.protocolbilesenf2_525404_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye7Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye7Adi
        : null
    );
    formVars.protocolbilesenf2_379240_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye7Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye7Aciklama
        : null
    );
    formVars.protocolbilesenf2_126655_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye8Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye8Adi
        : null
    );
    formVars.protocolbilesenf2_531390_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye8Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye8Aciklama
        : null
    );
    stateVars.isComp594938Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp286959Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp154471Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264710Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp882780Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp910124Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514465Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp648318Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SiniflandirmaSeviyeleri = result?.data.siniflandirmaSeviyeleri;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProtocolBilesenF2PageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProtocolBilesenF2PageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.protocolbilesenf2_436800_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].siniflandirmaId
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].siniflandirmaId
        : null
    );

    stateVars.dataSource_436800 = this.state.SiniflandirmaAll;
    stateVars.dataSource_436800 = this.state.SiniflandirmaAll;
    stateVars.isComp594938Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.protocolbilesenf2_963811_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye1Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye1Adi
        : null
    );

    formVars.protocolbilesenf2_52244_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye1Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye1Aciklama
        : null
    );

    stateVars.isComp286959Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.protocolbilesenf2_913903_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye2Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye2Adi
        : null
    );

    formVars.protocolbilesenf2_626565_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye2Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye2Aciklama
        : null
    );

    stateVars.isComp154471Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.protocolbilesenf2_359711_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye3Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye3Adi
        : null
    );

    formVars.protocolbilesenf2_592190_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye3Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye3Aciklama
        : null
    );

    stateVars.isComp264710Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    formVars.protocolbilesenf2_582368_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye4Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye4Adi
        : null
    );

    formVars.protocolbilesenf2_308696_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye4Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye4Aciklama
        : null
    );

    stateVars.isComp882780Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    formVars.protocolbilesenf2_928483_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye5Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye5Adi
        : null
    );

    formVars.protocolbilesenf2_16146_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye5Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye5Aciklama
        : null
    );

    stateVars.isComp910124Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    formVars.protocolbilesenf2_191609_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye6Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye6Adi
        : null
    );

    formVars.protocolbilesenf2_317609_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye6Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye6Aciklama
        : null
    );

    stateVars.isComp514465Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    formVars.protocolbilesenf2_525404_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye7Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye7Adi
        : null
    );

    formVars.protocolbilesenf2_379240_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye7Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye7Aciklama
        : null
    );

    stateVars.isComp648318Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    formVars.protocolbilesenf2_126655_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye8Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye8Adi
        : null
    );

    formVars.protocolbilesenf2_531390_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye8Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye8Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProtocolBilesenF2Component_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProtocolBilesenF2Component_436800_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_436800_value", "value", "SiniflandirmaAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "protocolbilesenf2_436800_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sinifId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_436800_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      bilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSelectById?.length > 0
            ? stateVars.BilesenSelectById[0].id
            : this.state.BilesenSelectById?.length > 0
            ? this.state.BilesenSelectById[0].id
            : null
        ),
        "Guid"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProtocolBilesenF2/ProtocolBilesenF2Component_436800_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BilesenSiniflandirmaRaporAciklama = result?.data.bilesenSiniflandirmaRaporAciklama;
    formVars.protocolbilesenf2_963811_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye1Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye1Adi
        : null
    );
    formVars.protocolbilesenf2_52244_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye1Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye1Aciklama
        : null
    );
    formVars.protocolbilesenf2_913903_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye2Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye2Adi
        : null
    );
    formVars.protocolbilesenf2_626565_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye2Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye2Aciklama
        : null
    );
    formVars.protocolbilesenf2_359711_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye3Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye3Adi
        : null
    );
    formVars.protocolbilesenf2_592190_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye3Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye3Aciklama
        : null
    );
    formVars.protocolbilesenf2_582368_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye4Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye4Adi
        : null
    );
    formVars.protocolbilesenf2_308696_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye4Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye4Aciklama
        : null
    );
    formVars.protocolbilesenf2_928483_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye5Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye5Adi
        : null
    );
    formVars.protocolbilesenf2_16146_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye5Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye5Aciklama
        : null
    );
    formVars.protocolbilesenf2_191609_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye6Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye6Adi
        : null
    );
    formVars.protocolbilesenf2_317609_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye6Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye6Aciklama
        : null
    );
    formVars.protocolbilesenf2_525404_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye7Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye7Adi
        : null
    );
    formVars.protocolbilesenf2_379240_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye7Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye7Aciklama
        : null
    );
    formVars.protocolbilesenf2_126655_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye8Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye8Adi
        : null
    );
    formVars.protocolbilesenf2_531390_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye8Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye8Aciklama
        : null
    );
    stateVars.isComp594938Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp286959Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp154471Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264710Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp882780Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp910124Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514465Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp648318Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProtocolBilesenF2Component_436800_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProtocolBilesenF2Component_436800_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.protocolbilesenf2_963811_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye1Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye1Adi
        : null
    );

    formVars.protocolbilesenf2_52244_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye1Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye1Aciklama
        : null
    );

    formVars.protocolbilesenf2_913903_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye2Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye2Adi
        : null
    );

    formVars.protocolbilesenf2_626565_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye2Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye2Aciklama
        : null
    );

    formVars.protocolbilesenf2_359711_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye3Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye3Adi
        : null
    );

    formVars.protocolbilesenf2_592190_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye3Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye3Aciklama
        : null
    );

    formVars.protocolbilesenf2_582368_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye4Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye4Adi
        : null
    );

    formVars.protocolbilesenf2_308696_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye4Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye4Aciklama
        : null
    );

    formVars.protocolbilesenf2_928483_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye5Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye5Adi
        : null
    );

    formVars.protocolbilesenf2_16146_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye5Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye5Aciklama
        : null
    );

    formVars.protocolbilesenf2_191609_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye6Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye6Adi
        : null
    );

    formVars.protocolbilesenf2_317609_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye6Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye6Aciklama
        : null
    );

    formVars.protocolbilesenf2_525404_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye7Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye7Adi
        : null
    );

    formVars.protocolbilesenf2_379240_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye7Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye7Aciklama
        : null
    );

    formVars.protocolbilesenf2_126655_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye8Adi
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye8Adi
        : null
    );

    formVars.protocolbilesenf2_531390_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye8Aciklama
        : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
        ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye8Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProtocolBilesenF2Component_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_436800_value", "value", "SiniflandirmaAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "protocolbilesenf2_436800_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid,
        "Guid"
      ),
      SiniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_436800_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama1Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama1Id
            : null
        ),
        "Guid"
      ),
      BilesenId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSelectById?.length > 0
            ? stateVars.BilesenSelectById[0].id
            : this.state.BilesenSelectById?.length > 0
            ? this.state.BilesenSelectById[0].id
            : null
        ),
        "Guid"
      ),
      ProtokolId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      SiniflandirmaId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_436800_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      SiniflandirmaSeviyeId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye1Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye1Id
            : null
        ),
        "Guid"
      ),
      Aciklama_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_52244_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama2Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama2Id
            : null
        ),
        "Guid"
      ),
      BilesenId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSelectById?.length > 0
            ? stateVars.BilesenSelectById[0].id
            : this.state.BilesenSelectById?.length > 0
            ? this.state.BilesenSelectById[0].id
            : null
        ),
        "Guid"
      ),
      ProtokolId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      SiniflandirmaId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_436800_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      SiniflandirmaSeviyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye2Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye2Id
            : null
        ),
        "Guid"
      ),
      Aciklama_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_626565_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama3Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama3Id
            : null
        ),
        "Guid"
      ),
      BilesenId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSelectById?.length > 0
            ? stateVars.BilesenSelectById[0].id
            : this.state.BilesenSelectById?.length > 0
            ? this.state.BilesenSelectById[0].id
            : null
        ),
        "Guid"
      ),
      ProtokolId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      SiniflandirmaId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_436800_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      SiniflandirmaSeviyeId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye3Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye3Id
            : null
        ),
        "Guid"
      ),
      Aciklama_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_592190_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama4Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama4Id
            : null
        ),
        "Guid"
      ),
      BilesenId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSelectById?.length > 0
            ? stateVars.BilesenSelectById[0].id
            : this.state.BilesenSelectById?.length > 0
            ? this.state.BilesenSelectById[0].id
            : null
        ),
        "Guid"
      ),
      ProtokolId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      SiniflandirmaId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_436800_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      SiniflandirmaSeviyeId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye4Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye4Id
            : null
        ),
        "Guid"
      ),
      Aciklama_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_308696_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama5Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama5Id
            : null
        ),
        "Guid"
      ),
      BilesenId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSelectById?.length > 0
            ? stateVars.BilesenSelectById[0].id
            : this.state.BilesenSelectById?.length > 0
            ? this.state.BilesenSelectById[0].id
            : null
        ),
        "Guid"
      ),
      ProtokolId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      SiniflandirmaId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_436800_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      SiniflandirmaSeviyeId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye5Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye5Id
            : null
        ),
        "Guid"
      ),
      Aciklama_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_16146_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama6Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama6Id
            : null
        ),
        "Guid"
      ),
      BilesenId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSelectById?.length > 0
            ? stateVars.BilesenSelectById[0].id
            : this.state.BilesenSelectById?.length > 0
            ? this.state.BilesenSelectById[0].id
            : null
        ),
        "Guid"
      ),
      ProtokolId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      SiniflandirmaId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_436800_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      SiniflandirmaSeviyeId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye6Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye6Id
            : null
        ),
        "Guid"
      ),
      Aciklama_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_317609_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama7Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama7Id
            : null
        ),
        "Guid"
      ),
      BilesenId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSelectById?.length > 0
            ? stateVars.BilesenSelectById[0].id
            : this.state.BilesenSelectById?.length > 0
            ? this.state.BilesenSelectById[0].id
            : null
        ),
        "Guid"
      ),
      ProtokolId_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      SiniflandirmaId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_436800_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      SiniflandirmaSeviyeId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye7Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye7Id
            : null
        ),
        "Guid"
      ),
      Aciklama_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_379240_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama8Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviyeAciklama8Id
            : null
        ),
        "Guid"
      ),
      BilesenId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSelectById?.length > 0
            ? stateVars.BilesenSelectById[0].id
            : this.state.BilesenSelectById?.length > 0
            ? this.state.BilesenSelectById[0].id
            : null
        ),
        "Guid"
      ),
      ProtokolId_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      SiniflandirmaId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_436800_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      SiniflandirmaSeviyeId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? stateVars.BilesenSiniflandirmaRaporAciklama[0].seviye8Id
            : this.state.BilesenSiniflandirmaRaporAciklama?.length > 0
            ? this.state.BilesenSiniflandirmaRaporAciklama[0].seviye8Id
            : null
        ),
        "Guid"
      ),
      Aciklama_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protocolbilesenf2_531390_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProtocolBilesenF2/ProtocolBilesenF2Component_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.SaveRecord = result?.data.saveRecord_3;
    stateVars.SaveRecord = result?.data.saveRecord_4;
    stateVars.SaveRecord = result?.data.saveRecord_5;
    stateVars.SaveRecord = result?.data.saveRecord_6;
    stateVars.SaveRecord = result?.data.saveRecord_7;
    stateVars.SaveRecord = result?.data.saveRecord_8;
    stateVars.SaveRecord = result?.data.saveRecord_9;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProtocolBilesenF2Component_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProtocolBilesenF2Component_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
