import { Form, Spin, Typography } from "antd/es";
import moment from "moment";
import { connect } from "react-redux";
import {
  ChartLabel,
  DonutChart,
  HorizontalStack,
  Icon,
  KCol,
  KDatePicker,
  KDrawer,
  KModal,
  KPanel,
  KRow,
  KTab,
  KTabItem,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  VerticalStack
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { Master_Screen } from "../master/master";
import { Dashboard_ScreenBase } from "./dashboard-base";

import { DashboardBilesenDetay_Screen } from "../dashboardbilesendetay/dashboardbilesendetay";
import { DashboardKatilimDetay_Screen } from "../dashboardkatilimdetay/dashboardkatilimdetay";
import { EgitmenSozlesmesiYeni_Screen } from "../egitmensozlesmesiyeni/egitmensozlesmesiyeni";
import { KurumSozlesmesi_Screen } from "../kurumsozlesmesi/kurumsozlesmesi";
import { KVKKAydinlatmaSozlesmesi_Screen } from "../kvkkaydinlatmasozlesmesi/kvkkaydinlatmasozlesmesi";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class Dashboard_Screen extends Dashboard_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Master_Screen
          form={this.props.form}
          initialValues={{
            dashboard_611755_value: this.state.fnPreviousMonth?.at?.(0)?.firstDayOfYear ?? undefined,
            dashboard_769300_value: moment().format("YYYY-MM-DDTHH:mm:ss"),
            dashboard_48309_value: this.state.ProtokolAll?.at?.(0)?.id ?? undefined,
            dashboard_936490_value: undefined
          }}
          screenInputs={this.props.screenInputs}
          setMasterPageInitLoaded={this.setMasterPageInitLoaded}
          setMasterPageRecurringLoaded={this.setMasterPageRecurringLoaded}
          isMasterPageInitLoaded={this.state.isMasterPageInitLoaded}
          isMasterPageRecurringLoaded={this.state.isMasterPageRecurringLoaded}
          shouldTriggerPageInit={this.state.shouldTriggerPageInit}
          setShouldTriggerPageInit={this.setShouldTriggerPageInit}
        >
          <Spin spinning={this.state.spinnerCount > 0}>
            <div
              id="dashboard_body"
              style={
                {
                  backgroundColor: "rgba(244, 244, 244, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block",
                  overflow: "auto",
                  minHeight: "calc(100vh - 60px - 60px - 0px - 0px)",
                  maxHeight: "calc(100vh - 60px - 60px - 0px - 0px)",
                  height: "calc(100vh - 60px - 60px - 0px - 0px)"
                } as any
              }
            >
              <KRow
                id="946201"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="255403"
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 24,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <Label
                    id="36536"
                    value={ReactSystemFunctions.getMyUsername()}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 5,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></Label>
                </KCol>

                <KCol
                  id="748517"
                  visibility={this.state.isComp748517Visible}
                  visibilityCondition={(rowData: object) =>
                    ReactSystemFunctions.isNotEqualTo(this.state.MyContext[0]?.rolename?.toString(), "Rol_Observer")
                  }
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-right",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <HorizontalStack
                    id="596772"
                    direction="horizontal"
                    size={12}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 5,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="dashboard_611755_value">
                      <KDatePicker
                        id="611755"
                        onChange={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.DashboardComponent_611755_onChange();
                        }}
                        kuikaRef={this.dashboard_611755_value_kuikaDateRef}
                        placeholder={ReactSystemFunctions.translate(this.ml, 611755, "placeholder", this.defaultML)}
                        allowClear={true}
                        format="DD/MM/YYYY"
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            width: "200px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></KDatePicker>
                    </Form.Item>

                    <Form.Item className="kFormItem" name="dashboard_769300_value">
                      <KDatePicker
                        id="769300"
                        onChange={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.DashboardComponent_769300_onChange();
                        }}
                        kuikaRef={this.dashboard_769300_value_kuikaDateRef}
                        placeholder={ReactSystemFunctions.translate(this.ml, 769300, "placeholder", this.defaultML)}
                        allowClear={true}
                        format="DD/MM/YYYY"
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            width: "200px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></KDatePicker>
                    </Form.Item>
                  </HorizontalStack>
                </KCol>

                <KCol
                  id="310903"
                  visibility={this.state.isComp310903Visible}
                  visibilityCondition={(rowData: object) =>
                    ReactSystemFunctions.isNotEqualTo(this.state.MyContext[0]?.rolename?.toString(), "Rol_Observer")
                  }
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <Form.Item className="kFormItem" name="dashboard_48309_value">
                    <KSelectBox
                      id="48309"
                      onChange={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.DashboardComponent_48309_onChange();
                      }}
                      kuikaRef={this.dashboard_48309_value_kuikaSelectBoxRef}
                      options={this.state.ProtokolAll}
                      placeholder={ReactSystemFunctions.translate(this.ml, 48309, "placeholder", this.defaultML)}
                      allowClear={false}
                      autoClearSearchValue={true}
                      showSearch={false}
                      widthType="fill"
                      containsNullItem={false}
                      sortBy="none"
                      datavaluefield="id"
                      datatextfield="tanim"
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderTopWidth: 1,
                          borderRightWidth: 1,
                          borderBottomWidth: 1,
                          borderLeftWidth: 1,
                          borderColor: "rgba(218, 218, 218, 1)",
                          borderStyle: "solid",
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    ></KSelectBox>
                  </Form.Item>
                </KCol>
              </KRow>

              <KRow
                id="724287"
                visibility={this.state.isComp724287Visible}
                visibilityCondition={(rowData: object) =>
                  ReactSystemFunctions.isNotEqualTo(this.state.MyContext[0]?.rolename?.toString(), "Rol_Observer")
                }
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="304957"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="852499"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="231189"
                      xs={14}
                      sm={14}
                      md={14}
                      lg={14}
                      xl={14}
                      xxl={14}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KPanel
                        id="353782"
                        scrolling="hidden"
                        scrollRadius={false}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 32,
                            paddingRight: 32,
                            paddingBottom: 32,
                            paddingLeft: 32,
                            height: "360px",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="586122"
                          value={ReactSystemFunctions.translate(this.ml, 586122, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 32,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: "15px",
                              color: "rgba(10, 34, 64, 1)"
                            } as any
                          }
                        ></Label>

                        <KRow
                          id="353398"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KCol
                            id="250654"
                            xs={10}
                            sm={10}
                            md={10}
                            lg={10}
                            xl={10}
                            xxl={10}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <DonutChart
                              id="880340"
                              visibility={this.state.isComp880340Visible}
                              dataSource={this.state.DashboardMevcutDurumGraph}
                              dataLabelKeyName="FitSkorKategori"
                              dataDisplayLabelKeyName="FitSkorKategori"
                              dataTooltipFooterDataLabel="Oran"
                              firstDataFieldKeyName="Adet"
                              firstDataBgColorKeyName="Renk"
                              leftAxis={false}
                              rightAxis={false}
                              beginAtZero={true}
                              showDataLabels={true}
                              legend=""
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  maxHeight: "300px",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "rgba(10, 33, 64, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            >
                              <ChartLabel
                                id="178518"
                                text="Ortalama"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></ChartLabel>

                              <ChartLabel
                                id="851530"
                                text="FitSkor®"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></ChartLabel>

                              <ChartLabel
                                id="828218"
                                text={this.state.OkulFitnessIstatistigi?.at?.(0)?.fitSkor ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "40px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></ChartLabel>
                            </DonutChart>

                            <DonutChart
                              id="966032"
                              visibility={this.state.isComp966032Visible}
                              visibilityCondition={(rowData: object) =>
                                ReactSystemFunctions.isEqualTo(
                                  this.state.DashboardMevcutDurumGraph[0]?.okulyadakulupmu?.toString(),
                                  "false"
                                )
                              }
                              dataSource={this.state.DashboardMevcutDurumGraph}
                              dataLabelKeyName="FitSkorKategori"
                              dataDisplayLabelKeyName="FitSkorKategori"
                              dataTooltipFooterDataLabel="Oran"
                              firstDataFieldKeyName="Adet"
                              firstDataBgColorKeyName="Renk"
                              leftAxis={false}
                              rightAxis={false}
                              beginAtZero={true}
                              showDataLabels={true}
                              legend=""
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  maxHeight: "300px",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "rgba(10, 33, 64, 1)",
                                  lineHeight: "20px"
                                } as any
                              }
                            >
                              <ChartLabel
                                id="247244"
                                text="Ortalama"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></ChartLabel>

                              <ChartLabel
                                id="384457"
                                text="FitSkor®"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></ChartLabel>

                              <ChartLabel
                                id="681839"
                                text={this.state.DashboardCheckuplariAvg?.at?.(0)?.fitskor ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "40px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></ChartLabel>
                            </DonutChart>
                          </KCol>

                          <KCol
                            id="874356"
                            xs={14}
                            sm={14}
                            md={14}
                            lg={14}
                            xl={14}
                            xxl={14}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KRow
                              id="664478"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KCol
                                id="506814"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="183470"
                                  direction="horizontal"
                                  size={4}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="616100"
                                    value={ReactSystemFunctions.translate(this.ml, 616100, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="401689"
                                    value={this.state.KurumTipiSelectById?.at?.(0)?.lowercheckupOzneAdi ?? undefined}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="512908"
                                    value={ReactSystemFunctions.translate(this.ml, 512908, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="653303"
                                    value={this.state.DashboardMevcutDurum?.at?.(0)?.adet ?? undefined}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </HorizontalStack>
                              </KCol>
                            </KRow>

                            <KRow
                              id="127219"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KCol
                                id="470607"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <VerticalStack
                                  id="738417"
                                  direction="vertical"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <HorizontalStack
                                    id="17029"
                                    direction="horizontal"
                                    size={4}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="281839"
                                      iconName="circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(192, 0, 1, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Label
                                      id="352822"
                                      value={ReactSystemFunctions.translate(this.ml, 352822, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="604297"
                                      value={this.state.DashboardMevcutDurum?.at?.(0)?.l1 ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="765937"
                                      value={this.state.KurumTipiSelectById?.at?.(0)?.lowercheckupOzneAdi ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </HorizontalStack>

                                  <HorizontalStack
                                    id="633270"
                                    direction="horizontal"
                                    size={4}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="920539"
                                      iconName="circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(236, 125, 49, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Label
                                      id="904338"
                                      value={ReactSystemFunctions.translate(this.ml, 904338, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="641373"
                                      value={this.state.DashboardMevcutDurum?.at?.(0)?.l2 ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="363632"
                                      value={this.state.KurumTipiSelectById?.at?.(0)?.lowercheckupOzneAdi ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </HorizontalStack>

                                  <HorizontalStack
                                    id="635130"
                                    direction="horizontal"
                                    size={4}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="778525"
                                      iconName="circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(254, 192, 8, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Label
                                      id="65752"
                                      value={ReactSystemFunctions.translate(this.ml, 65752, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="639869"
                                      value={this.state.DashboardMevcutDurum?.at?.(0)?.l3 ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="941747"
                                      value={this.state.KurumTipiSelectById?.at?.(0)?.lowercheckupOzneAdi ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </HorizontalStack>

                                  <HorizontalStack
                                    id="273702"
                                    direction="horizontal"
                                    size={4}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="524867"
                                      iconName="circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(0, 175, 80, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Label
                                      id="634452"
                                      value={ReactSystemFunctions.translate(this.ml, 634452, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="349053"
                                      value={this.state.DashboardMevcutDurum?.at?.(0)?.l4 ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="776212"
                                      value={this.state.KurumTipiSelectById?.at?.(0)?.lowercheckupOzneAdi ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 600,
                                          fontSize: "15px",
                                          color: "rgba(10, 34, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </HorizontalStack>
                                </VerticalStack>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>
                      </KPanel>
                    </KCol>

                    <KCol
                      id="977994"
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      xxl={10}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KPanel
                        id="218368"
                        visibility={this.state.isComp218368Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isNotEmpty(this.state.MyContext[0]?.checkupDonemiId?.toString(), "")
                        }
                        scrolling="hidden"
                        scrollRadius={false}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 32,
                            paddingRight: 0,
                            paddingBottom: 32,
                            paddingLeft: 0,
                            height: "360px",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="536589"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="979489"
                            value={ReactSystemFunctions.translate(this.ml, 979489, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 16,
                                paddingLeft: 32,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "15px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="437202"
                            value={this.state.MyContext?.at?.(0)?.checkupDonemi ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 16,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "15px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <DonutChart
                          id="753751"
                          dataSource={this.state.DashboarAktifCheckupDonemi}
                          dataDisplayLabelKeyName="Txt"
                          firstDataFieldKeyName="Val"
                          firstDataBgColorKeyName="Renk"
                          leftAxis={false}
                          rightAxis={false}
                          beginAtZero={true}
                          showDataLabels={false}
                          legend=""
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              maxHeight: "300px",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "12px",
                              color: "rgba(10, 33, 64, 1)",
                              lineHeight: "20px"
                            } as any
                          }
                        >
                          <ChartLabel
                            id="588440"
                            text={this.state.DashboarAktifCheckupDonemi?.at?.(0)?.yapilmaOrani ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "38px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></ChartLabel>
                        </DonutChart>

                        <KRow
                          id="504600"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KCol
                            id="550593"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-right",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <HorizontalStack
                              id="968982"
                              direction="horizontal"
                              size={2}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="601475"
                                value={ReactSystemFunctions.translate(this.ml, 601475, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="166050"
                                value={this.state.DashboarAktifCheckupDonemi?.at?.(0)?.yapildi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="43827"
                                value={this.state.KurumTipiSelectById?.at?.(0)?.lowercheckupOzneAdi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Icon
                                id="150279"
                                iconName="circle"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(0, 190, 214, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>
                            </HorizontalStack>
                          </KCol>

                          <KCol
                            id="872220"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 0,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <HorizontalStack
                              id="148043"
                              direction="horizontal"
                              size={2}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="284501"
                                iconName="circle"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(155, 155, 155, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="66513"
                                value={ReactSystemFunctions.translate(this.ml, 66513, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="932140"
                                value={this.state.DashboarAktifCheckupDonemi?.at?.(0)?.yapilmadi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="474298"
                                value={this.state.KurumTipiSelectById?.at?.(0)?.lowercheckupOzneAdi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    color: "rgba(10, 34, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </HorizontalStack>
                          </KCol>
                        </KRow>
                      </KPanel>
                    </KCol>
                  </KRow>
                </KCol>
              </KRow>

              <KRow
                id="212092"
                visibility={this.state.isComp212092Visible}
                visibilityCondition={(rowData: object) =>
                  ReactSystemFunctions.isNotEqualTo(this.state.MyContext[0]?.rolename?.toString(), "Rol_Observer")
                }
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="545296"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="951217"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="461504"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 0,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <Label
                        id="512011"
                        visibility={this.state.isComp512011Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isEqualTo(
                            this.state.DashboardMevcutDurum[0]?.okulyadakulupmu?.toString(),
                            "true"
                          )
                        }
                        value={ReactSystemFunctions.translate(this.ml, 512011, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 4,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "16px",
                            color: "rgba(10, 34, 64, 1)",
                            lineHeight: "20px"
                          } as any
                        }
                      ></Label>

                      <Label
                        id="110197"
                        value={ReactSystemFunctions.translate(this.ml, 110197, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "16px",
                            color: "rgba(10, 34, 64, 1)",
                            lineHeight: "20px"
                          } as any
                        }
                      ></Label>
                    </KCol>
                  </KRow>

                  <KRow
                    id="156797"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="595156"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KTable
                        id="5125580"
                        visibility={this.state.isComp5125580Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isEmpty(this.state.MyContext[0]?.checkupDonemiId?.toString(), "")
                        }
                        kuikaRef={this.dashboard_5125580_value_kuikaTableRef}
                        form={this.props.form}
                        dataSource={this.state.DashboardCheckuplari}
                        size="middle"
                        bordered={true}
                        loading={false}
                        transformedOnMobileResolution={false}
                        showHeader={true}
                        fixedHeader={false}
                        editable={false}
                        tableWidthMode="fit"
                        globalSearch={false}
                        searchable={false}
                        sorter={true}
                        filtering={false}
                        pagination={false}
                        pageSize={10}
                        showSizeChanger={false}
                        columnChooser={false}
                        resizableColumns={false}
                        striped={true}
                        stripedColor="#F5F7FA"
                        insertRowActive={false}
                        insertRowPosition="top"
                        showNoDataFound={true}
                        nodatafoundmessage={ReactSystemFunctions.translate(
                          this.ml,
                          5125580,
                          "nodatafoundmessage",
                          this.defaultML
                        )}
                        multiSelect={false}
                        export={false}
                        editableAlwaysActive={false}
                        multiSelectScope="page"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KTableHeader
                          id="1772294"
                          columnTitles={this.state.DashboardCheckuplariHeader?.at?.(0)?.header ?? undefined}
                          hideOnMobileResolution={false}
                          textDirection="Default"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontSize: "12px",
                              color: "rgba(10, 34, 64, 1)",
                              lineHeight: "20px"
                            } as any
                          }
                        ></KTableHeader>

                        <KTableRow
                          id="9144441"
                          hoverFontColor="red"
                          hoverBgColor="#F5F5F5"
                          style={
                            {
                              borderTopWidth: 1,
                              borderColor: "rgba(244, 244, 244, 1)",
                              borderStyle: "solid",
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              height: "30px",
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KTableColumn
                            id="8214351"
                            title={ReactSystemFunctions.translate(this.ml, 8214351, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="7421762"
                              value="[datafield:fullname]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="9172801"
                            visibility={this.state.isComp9172801Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "0"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 9172801, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="3118420"
                              value="[datafield:bilesen1Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="7997680"
                            visibility={this.state.isComp7997680Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "1"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 7997680, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="3625460"
                              value="[datafield:bilesen2Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="7071839"
                            visibility={this.state.isComp7071839Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "2"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 7071839, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="7003050"
                              value="[datafield:bilesen3Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="8989893"
                            visibility={this.state.isComp8989893Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "3"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 8989893, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="7276599"
                              value="[datafield:bilesen4Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="8216050"
                            visibility={this.state.isComp8216050Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "4"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 8216050, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="9224188"
                              value="[datafield:bilesen5Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="3531977"
                            visibility={this.state.isComp3531977Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "5"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 3531977, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="5703748"
                              value="[datafield:bilesen6Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="9859756"
                            visibility={this.state.isComp9859756Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "6"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 9859756, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="2559166"
                              value="[datafield:bilesen7Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="8692831"
                            visibility={this.state.isComp8692831Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "7"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 8692831, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="8635235"
                              value="[datafield:bilesen8Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="277347"
                            visibility={this.state.isComp277347Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "8"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 277347, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="590452"
                              value="[datafield:bilesen9Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="131879"
                            visibility={this.state.isComp131879Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "9"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 131879, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="200852"
                              value="[datafield:bilesen10Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="947232"
                            visibility={this.state.isComp947232Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "10"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 947232, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="424514"
                              value="[datafield:bilesen11Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="735273"
                            visibility={this.state.isComp735273Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "11"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 735273, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="454225"
                              value="[datafield:bilesen12Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="269779"
                            visibility={this.state.isComp269779Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isGreaterThan(
                                this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                "12"
                              )
                            }
                            title={ReactSystemFunctions.translate(this.ml, 269779, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="958721"
                              value="[datafield:bilesen13Puan]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>

                          <KTableColumn
                            id="8240454"
                            title={ReactSystemFunctions.translate(this.ml, 8240454, "title", this.defaultML)}
                            alwaysVisibleOnMobileResolution={false}
                            hideFiltering={false}
                            hideSorting={false}
                            hideSearch={false}
                            footerOptions="none"
                            footerFontSize={14}
                            footerFontColor="rgb(68, 68, 68)"
                            style={
                              {
                                borderTopWidth: 1,
                                borderColor: "rgba(244, 244, 244, 1)",
                                borderStyle: "solid",
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="2574500"
                              value="[datafield:fitSkor]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                  color: "rgba(10, 34, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </KTableColumn>
                        </KTableRow>
                      </KTable>

                      <Form.Item className="kFormItem" name="dashboard_936490_value" noStyle>
                        <KTab
                          id="936490"
                          visibility={this.state.isComp936490Visible}
                          visibilityCondition={(rowData: object) =>
                            ReactSystemFunctions.isNotEmpty(this.state.MyContext[0]?.checkupDonemiId?.toString(), "")
                          }
                          type="line"
                          size="default"
                          tabPosition="top"
                          selectedTabColor="rgba(0, 190, 214, 1)"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KTabItem
                            id="590367"
                            tab={ReactSystemFunctions.translate(this.ml, 590367, "tab", this.defaultML)}
                            forceRender={true}
                            key={590367}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "15px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KTable
                              id="566388"
                              kuikaRef={this.dashboard_566388_value_kuikaTableRef}
                              form={this.props.form}
                              dataSource={this.state.OkulKatilimIstatistikleri}
                              size="middle"
                              bordered={true}
                              loading={false}
                              transformedOnMobileResolution={false}
                              showHeader={true}
                              fixedHeader={false}
                              editable={false}
                              tableWidthMode="fit"
                              globalSearch={false}
                              searchable={false}
                              sorter={false}
                              filtering={false}
                              pagination={false}
                              pageSize={10}
                              showSizeChanger={false}
                              columnChooser={false}
                              resizableColumns={false}
                              striped={true}
                              stripedColor="#F5F7FA"
                              insertRowActive={false}
                              insertRowPosition="top"
                              showNoDataFound={true}
                              nodatafoundmessage={ReactSystemFunctions.translate(
                                this.ml,
                                566388,
                                "nodatafoundmessage",
                                this.defaultML
                              )}
                              multiSelect={false}
                              export={false}
                              editableAlwaysActive={false}
                              multiSelectScope="page"
                              style={
                                {
                                  borderTopLeftRadius: 200,
                                  borderTopRightRadius: 200,
                                  borderBottomRightRadius: 200,
                                  borderBottomLeftRadius: 200,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KTableHeader
                                id="736636"
                                hideOnMobileResolution={false}
                                textDirection="Default"
                                style={
                                  {
                                    borderTopLeftRadius: 200,
                                    borderTopRightRadius: 200,
                                    borderBottomRightRadius: 200,
                                    borderBottomLeftRadius: 200,
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(217, 217, 217, 0.88)",
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    color: "rgba(10, 34, 64, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></KTableHeader>

                              <KTableRow
                                id="809852"
                                hoverFontColor="red"
                                hoverBgColor="#F5F5F5"
                                style={
                                  {
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KTableColumn
                                  id="228026"
                                  title={this.state.KurumTipiSelectById?.at?.(0)?.ikinciKirilimAdi ?? undefined}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="851062"
                                    value="[datafield:sinifiAdi]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="136888"
                                  title={ReactSystemFunctions.translate(this.ml, 136888, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="826732"
                                    value="[datafield:mevcut]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="314050"
                                  title={this.state.OkulKatilimIstatistikleri?.at?.(0)?.checkupDonemi1Adi ?? undefined}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="53847"
                                    visibility={this.state.isComp53847Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isNotEqualTo(rowData.adet1, "0")
                                    }
                                    value="[datafield:adet1]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(192, 0, 1, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Icon
                                    id="43725"
                                    visibility={this.state.isComp43725Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isEqualTo(rowData.adet1, "0")
                                    }
                                    iconName="check"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(0, 190, 214, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </KTableColumn>

                                <KTableColumn
                                  id="226031"
                                  visibility={this.state.isComp226031Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isNotEmpty(
                                      this.state.CheckupDonemiByKirilim1Id[0]?.id2?.toString(),
                                      ""
                                    )
                                  }
                                  title={this.state.OkulKatilimIstatistikleri?.at?.(0)?.checkupDonemi2Adi ?? undefined}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="933926"
                                    visibility={this.state.isComp933926Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isNotEqualTo(rowData.adet2, "0")
                                    }
                                    value="[datafield:adet2]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(192, 0, 1, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Icon
                                    id="758432"
                                    visibility={this.state.isComp758432Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isEqualTo(rowData.adet2, "0")
                                    }
                                    iconName="check"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(0, 190, 214, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </KTableColumn>

                                <KTableColumn
                                  id="820245"
                                  visibility={this.state.isComp820245Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isNotEmpty(
                                      this.state.CheckupDonemiByKirilim1Id[0]?.id3?.toString(),
                                      ""
                                    )
                                  }
                                  title={this.state.OkulKatilimIstatistikleri?.at?.(0)?.checkupDonemi3Adi ?? undefined}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="354183"
                                    visibility={this.state.isComp354183Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isNotEqualTo(rowData.adet3, "0")
                                    }
                                    value="[datafield:adet3]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(192, 0, 1, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Icon
                                    id="306650"
                                    visibility={this.state.isComp306650Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isEqualTo(rowData.adet3, "0")
                                    }
                                    iconName="check"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(0, 190, 214, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </KTableColumn>

                                <KTableColumn
                                  id="706570"
                                  visibility={this.state.isComp706570Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isNotEmpty(
                                      this.state.CheckupDonemiByKirilim1Id[0]?.id4?.toString(),
                                      ""
                                    )
                                  }
                                  title={this.state.OkulKatilimIstatistikleri?.at?.(0)?.checkupDonemi4Adi ?? undefined}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="115236"
                                    visibility={this.state.isComp115236Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isNotEqualTo(rowData.adet4, "0")
                                    }
                                    value="[datafield:adet4]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(192, 0, 1, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Icon
                                    id="197969"
                                    visibility={this.state.isComp197969Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isEqualTo(rowData.adet4, "0")
                                    }
                                    iconName="check"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(0, 190, 214, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </KTableColumn>

                                <KTableColumn
                                  id="779525"
                                  visibility={this.state.isComp779525Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isNotEmpty(
                                      this.state.CheckupDonemiByKirilim1Id[0]?.id5?.toString(),
                                      ""
                                    )
                                  }
                                  title={this.state.OkulKatilimIstatistikleri?.at?.(0)?.checkupDonemi5Adi ?? undefined}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="41699"
                                    visibility={this.state.isComp41699Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isNotEqualTo(rowData.adet5, "0")
                                    }
                                    value="[datafield:adet5]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(192, 0, 1, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Icon
                                    id="75947"
                                    visibility={this.state.isComp75947Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isEqualTo(rowData.adet5, "0")
                                    }
                                    iconName="check"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(0, 190, 214, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </KTableColumn>

                                <KTableColumn
                                  id="506987"
                                  visibility={this.state.isComp506987Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isNotEmpty(
                                      this.state.CheckupDonemiByKirilim1Id[0]?.id6?.toString(),
                                      ""
                                    )
                                  }
                                  title={this.state.OkulKatilimIstatistikleri?.at?.(0)?.checkupDonemi6Adi ?? undefined}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="696253"
                                    visibility={this.state.isComp696253Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isNotEqualTo(rowData.adet6, "0")
                                    }
                                    value="[datafield:adet6]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(192, 0, 1, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Icon
                                    id="187555"
                                    visibility={this.state.isComp187555Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isEqualTo(rowData.adet6, "0")
                                    }
                                    iconName="check"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(0, 190, 214, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </KTableColumn>

                                <KTableColumn
                                  id="26262"
                                  title={ReactSystemFunctions.translate(this.ml, 26262, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="443195"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.DashboardComponent_443195_onClick();
                                    }}
                                    showCursorPointer
                                    iconName="remove_red_eye"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(0, 190, 214, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </KTableColumn>
                              </KTableRow>
                            </KTable>
                          </KTabItem>

                          <KTabItem
                            id="37333"
                            tab={ReactSystemFunctions.translate(this.ml, 37333, "tab", this.defaultML)}
                            forceRender={true}
                            key={37333}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "15px",
                                color: "rgba(10, 34, 64, 1)"
                              } as any
                            }
                          >
                            <KTable
                              id="5561"
                              kuikaRef={this.dashboard_5561_value_kuikaTableRef}
                              form={this.props.form}
                              dataSource={this.state.OkulFitnessIstatistigi}
                              size="middle"
                              bordered={true}
                              loading={false}
                              transformedOnMobileResolution={false}
                              showHeader={true}
                              fixedHeader={false}
                              editable={false}
                              tableWidthMode="fit"
                              globalSearch={false}
                              searchable={false}
                              sorter={true}
                              filtering={false}
                              pagination={false}
                              pageSize={10}
                              showSizeChanger={false}
                              columnChooser={false}
                              resizableColumns={false}
                              striped={true}
                              stripedColor="#F5F7FA"
                              insertRowActive={false}
                              insertRowPosition="top"
                              showNoDataFound={true}
                              nodatafoundmessage={ReactSystemFunctions.translate(
                                this.ml,
                                5561,
                                "nodatafoundmessage",
                                this.defaultML
                              )}
                              multiSelect={false}
                              export={false}
                              editableAlwaysActive={false}
                              multiSelectScope="page"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KTableHeader
                                id="204881"
                                columnTitles={this.state.DashboardCheckuplariHeader?.at?.(0)?.header ?? undefined}
                                hideOnMobileResolution={false}
                                textDirection="Default"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    color: "rgba(10, 34, 64, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></KTableHeader>

                              <KTableRow
                                id="756887"
                                hoverFontColor="red"
                                hoverBgColor="#F5F5F5"
                                style={
                                  {
                                    borderTopWidth: 1,
                                    borderColor: "rgba(244, 244, 244, 1)",
                                    borderStyle: "solid",
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    height: "30px",
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KTableColumn
                                  id="5480"
                                  title={ReactSystemFunctions.translate(this.ml, 5480, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="981262"
                                    value="[datafield:sinifAdi]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="879974"
                                  visibility={this.state.isComp879974Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "0"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 879974, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="832720"
                                    value="[datafield:bilesen1Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="115636"
                                  visibility={this.state.isComp115636Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "1"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 115636, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="389797"
                                    value="[datafield:bilesen2Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="57389"
                                  visibility={this.state.isComp57389Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "2"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 57389, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="580691"
                                    value="[datafield:bilesen3Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="564832"
                                  visibility={this.state.isComp564832Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "3"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 564832, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="610440"
                                    value="[datafield:bilesen4Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="143664"
                                  visibility={this.state.isComp143664Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "4"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 143664, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="329039"
                                    value="[datafield:bilesen5Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="374173"
                                  visibility={this.state.isComp374173Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "5"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 374173, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="377317"
                                    value="[datafield:bilesen6Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="987728"
                                  visibility={this.state.isComp987728Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "6"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 987728, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="123691"
                                    value="[datafield:bilesen7Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="679678"
                                  visibility={this.state.isComp679678Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "7"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 679678, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="25689"
                                    value="[datafield:bilesen8Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="843737"
                                  visibility={this.state.isComp843737Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "8"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 843737, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="803308"
                                    value="[datafield:bilesen9Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="621973"
                                  visibility={this.state.isComp621973Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "9"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 621973, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="426012"
                                    value="[datafield:bilesen10Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="350228"
                                  visibility={this.state.isComp350228Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "10"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 350228, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="309273"
                                    value="[datafield:bilesen11Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="139169"
                                  visibility={this.state.isComp139169Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "11"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 139169, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="409483"
                                    value="[datafield:bilesen12Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="390934"
                                  visibility={this.state.isComp390934Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isGreaterThan(
                                      this.state.ProtokolSelectById[0]?.bilesenAdedi?.toString(),
                                      "12"
                                    )
                                  }
                                  title={ReactSystemFunctions.translate(this.ml, 390934, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="877213"
                                    value="[datafield:bilesen13Puan]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="101727"
                                  title={ReactSystemFunctions.translate(this.ml, 101727, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="907410"
                                    value="[datafield:fitSkor]"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        color: "rgba(10, 34, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KTableColumn>

                                <KTableColumn
                                  id="411180"
                                  title={ReactSystemFunctions.translate(this.ml, 411180, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Icon
                                    id="294"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.DashboardComponent_294_onClick();
                                    }}
                                    showCursorPointer
                                    visibility={this.state.isComp294Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isNotEmpty(rowData.sinifId, "")
                                    }
                                    iconName="remove_red_eye"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "20px",
                                        color: "rgba(0, 190, 214, 1)",
                                        letterSpacing: "1px"
                                      } as any
                                    }
                                  ></Icon>
                                </KTableColumn>
                              </KTableRow>
                            </KTable>
                          </KTabItem>
                        </KTab>
                      </Form.Item>
                    </KCol>
                  </KRow>
                </KCol>
              </KRow>

              <KRow
                id="585735"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="631901"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KCol>
              </KRow>

              <KModal
                id="492360"
                visible={this.state.NavVisible492360}
                onOk={() => {
                  this.setState({ NavVisible492360: false }, () => {
                    this.callCallbackFunction(492360);
                  });
                }}
                onCancel={() => {
                  this.setState({ NavVisible492360: false }, () => {
                    this.callCallbackFunction(492360);
                  });
                }}
                closable={true}
                width="800px"
                footer={null}
                height="70vh"
                children={
                  this.state.NavVisible492360 && (
                    <KVKKAydinlatmaSozlesmesi_Screen
                      onClose={() => {
                        this.setState({ NavVisible492360: false }, () => {
                          this.callCallbackFunction(492360);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
              ></KModal>

              <KModal
                id="549614"
                visible={this.state.NavVisible549614}
                onOk={() => {
                  this.setState({ NavVisible549614: false }, () => {
                    this.callCallbackFunction(549614);
                  });
                }}
                onCancel={() => {
                  this.setState({ NavVisible549614: false }, () => {
                    this.callCallbackFunction(549614);
                  });
                }}
                closable={true}
                width="800px"
                footer={null}
                height="70vh"
                children={
                  this.state.NavVisible549614 && (
                    <KurumSozlesmesi_Screen
                      onClose={() => {
                        this.setState({ NavVisible549614: false }, () => {
                          this.callCallbackFunction(549614);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
              ></KModal>

              <KModal
                id="774566"
                visible={this.state.NavVisible774566}
                onOk={() => {
                  this.setState({ NavVisible774566: false }, () => {
                    this.callCallbackFunction(774566);
                  });
                }}
                onCancel={() => {
                  this.setState({ NavVisible774566: false }, () => {
                    this.callCallbackFunction(774566);
                  });
                }}
                closable={true}
                width="800px"
                footer={null}
                height="70vh"
                children={
                  this.state.NavVisible774566 && (
                    <EgitmenSozlesmesiYeni_Screen
                      onClose={() => {
                        this.setState({ NavVisible774566: false }, () => {
                          this.callCallbackFunction(774566);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
              ></KModal>

              <KDrawer
                id="354038"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible354038: false }, () => {
                    this.callCallbackFunction(354038);
                  });
                }}
                visible={this.state.NavVisible354038}
                width="1400px"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible354038 && (
                    <DashboardBilesenDetay_Screen
                      onClose={() => {
                        this.setState({ NavVisible354038: false }, () => {
                          this.callCallbackFunction(354038);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 5,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KDrawer
                id="854447"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible854447: false }, () => {
                    this.callCallbackFunction(854447);
                  });
                }}
                visible={this.state.NavVisible854447}
                width="1400px"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible854447 && (
                    <DashboardKatilimDetay_Screen
                      onClose={() => {
                        this.setState({ NavVisible854447: false }, () => {
                          this.callCallbackFunction(854447);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 5,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              ></KDrawer>
            </div>
          </Spin>
        </Master_Screen>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Dashboard_Screen)))))
);
export { tmp as Dashboard_Screen };
//export default tmp;
//export { tmp as Dashboard_Screen };
