import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IMaster_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IMaster_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  KurumSelectById: any[];
  KurumSelectById_dummy: any[];
  LeftMenu: any[];
  LeftMenu_dummy: any[];
  MasterScrYetki: any[];
  MasterScrYetki_dummy: any[];
  ReadFromLocalStorage: string;
  ReadFromLocalStorage_dummy: string;
  SetValueOf: any;
  SetValueOf_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  WriteToLocalStorage: any;
  WriteToLocalStorage_dummy: any;
  OpenInBrowser: any;
  OpenInBrowser_dummy: any;
  BaseUrl: any[];
  BaseUrl_dummy: any[];
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  isComp99793Visible: "visible" | "hidden";
}

export class Master_ScreenBase extends React.PureComponent<IMaster_ScreenProps, any> {
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "17b1e761-30df-4cf7-aeb8-249308c996f3",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 985455, PropertyName: "label", Value: "Menu Item 1" },
        { Id: 383613, PropertyName: "value", Value: "Kaynaklar" },
        { Id: 612605, PropertyName: "value", Value: "Mevcut Profil" },
        { Id: 179698, PropertyName: "value", Value: "LOGOUT" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      KurumSelectById: [],
      LeftMenu: [],
      MasterScrYetki: [],
      ReadFromLocalStorage: "",
      SetValueOf: "",
      NAVIGATE: "",
      WriteToLocalStorage: "",
      OpenInBrowser: "",
      BaseUrl: [],
      Logout: false,
      goToSignInActivity: "",
      isComp99793Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("master", "");
      return;
    }

    if (true) {
      await this.MasterPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("master", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("master", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.MasterPageInit();
    }

    if (
      prevProps.isMasterPageInitLoaded !== this.props.isMasterPageInitLoaded &&
      this.props.isMasterPageInitLoaded === true
    ) {
    }
    if (
      prevProps.shouldTriggerPageInit !== this.props.shouldTriggerPageInit &&
      this.props.shouldTriggerPageInit === true
    ) {
      this.MasterPageInit();
      this.props.setShouldTriggerPageInit(false);
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      master_189293_value: this.state.KurumSelectById?.at?.(0)?.logo ?? undefined,
      master_920284_value: { collapsed: false }
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  MasterPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Master/MasterPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.master_719694_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kurumAdi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kurumAdi
        : null
    );
    formVars.master_16000_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].rolenameDesc
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].rolenameDesc
        : null
    );
    stateVars.KurumSelectById = result?.data.kurumSelectById;
    formVars.master_189293_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].logo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].logo
        : null
    );
    stateVars.LeftMenu = result?.data.leftMenu;

    stateVars.MasterScrYetki = result?.data.masterScrYetki;
    stateVars.isComp99793Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MasterScrYetki?.length > 0
            ? stateVars.MasterScrYetki[0].kaynaklarYetki
            : this.state.MasterScrYetki?.length > 0
            ? this.state.MasterScrYetki[0].kaynaklarYetki
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MasterPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MasterPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ReadFromLocalStorage = await ReactSystemFunctions.readFromLocalStorage("menuKey");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MasterPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MasterPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.ReadFromLocalStorage), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "master_920284_value",
        ReactSystemFunctions.toString(this, this.state.ReadFromLocalStorage),
        "selectedKey"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MasterPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MasterPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MasterPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kvvkCalisanOkunmali
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kvvkCalisanOkunmali
            : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.MasterPageInit4_,
        "Master",
        "KVKKAydinlatmaSozlesmesi",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "516424",
        null,
        null,
        "true",
        "800px",
        "70vh",
        false
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MasterPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MasterPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kvvkMerkezSozlesmeOkunmali
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kvvkMerkezSozlesmeOkunmali
            : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.MasterPageInit5_,
        "Master",
        "KurumSozlesmesi",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "741722",
        null,
        null,
        "true",
        "800px",
        "70vh",
        false
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MasterPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MasterPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kvvkEgitmenSozlesmeOkunmali
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kvvkEgitmenSozlesmeOkunmali
            : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.MasterPageInit6_,
        "Master",
        "EgitmenSozlesmesiYeni",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "478348",
        null,
        null,
        "true",
        "800px",
        "70vh",
        false
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MasterPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MasterPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.master_189293_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].logo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].logo
        : null
    );

    stateVars.dataSource_920284 = this.state.LeftMenu;
    stateVars.dataSource_920284 = this.state.LeftMenu;
    stateVars.isComp99793Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MasterScrYetki?.length > 0
            ? stateVars.MasterScrYetki[0].kaynaklarYetki
            : this.state.MasterScrYetki?.length > 0
            ? this.state.MasterScrYetki[0].kaynaklarYetki
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.master_719694_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kurumAdi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kurumAdi
        : null
    );

    formVars.master_16000_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].rolenameDesc
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].rolenameDesc
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.props.setMasterPageInitLoaded(true);
    return isErrorOccurred;
  };

  MasterComponent_920284_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.WriteToLocalStorage = await ReactSystemFunctions.writeToLocalStorage(
      "menuKey",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "master_920284_value", "selectedKey", "LeftMenu", "", "")
      )
    );
    stateVars.WriteToLocalStorage = await ReactSystemFunctions.writeToLocalStorage("isMenuCollapsed_920284", "false");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MasterComponent_383613_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("http://yeninesilfitnessegitimi.com/", "newtab");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MasterComponent_862119_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.WriteToLocalStorage = await ReactSystemFunctions.writeToLocalStorage(
      "lastVisitedMenuId_920284",
      "Menu_GostergePaneli"
    );
    stateVars.WriteToLocalStorage = await ReactSystemFunctions.writeToLocalStorage("menuKey", "Menu_GostergePaneli");
    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.MasterComponent_862119_onClick1_,
      "Master",
      "Settings",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "762089",
      null,
      "left",
      null,
      "380px",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  MasterComponent_862119_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "master_920284_value",
      "Menu_GostergePaneli",
      "selectedKey"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MasterComponent_862119_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MasterComponent_862119_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Master/MasterComponent_862119_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BaseUrl = result?.data.baseUrl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MasterComponent_862119_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MasterComponent_862119_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.BaseUrl?.length > 0
          ? stateVars.BaseUrl[0].baseUrldashboard
          : this.state.BaseUrl?.length > 0
          ? this.state.BaseUrl[0].baseUrldashboard
          : null
      ),
      "current"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MasterComponent_77603_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MasterComponent_179698_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    if (diId == 516424) {
      isErrorOccurred = await this.MasterPageInit4_();
      if (isErrorOccurred) return true;
    } else if (diId == 741722) {
      isErrorOccurred = await this.MasterPageInit5_();
      if (isErrorOccurred) return true;
    } else if (diId == 478348) {
      isErrorOccurred = await this.MasterPageInit6_();
      if (isErrorOccurred) return true;
    } else if (diId == 762089) {
      isErrorOccurred = await this.MasterComponent_862119_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
