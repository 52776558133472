import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICheckupReportcopy_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICheckupReportcopy_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ComponentToPdf: string;
  ComponentToPdf_dummy: string;
  OpenPdf: any;
  OpenPdf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  CheckupById: any[];
  CheckupById_dummy: any[];
  KisiSelectById: any[];
  KisiSelectById_dummy: any[];
  ProtokolSelectById: any[];
  ProtokolSelectById_dummy: any[];
  ProtokolReportContentById: any[];
  ProtokolReportContentById_dummy: any[];
  ProtokolSinifSeviyeAciklamaBul: any[];
  ProtokolSinifSeviyeAciklamaBul_dummy: any[];
  KurumSelectById: any[];
  KurumSelectById_dummy: any[];
  CheckupDetaylari: any[];
  CheckupDetaylari_dummy: any[];
  CheckupDetaylari2: any[];
  CheckupDetaylari2_dummy: any[];
  CheckupDetaylari3: any[];
  CheckupDetaylari3_dummy: any[];
  CheckupDetaylariGraph: any[];
  CheckupDetaylariGraph_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  ChartLevels: any[];
  ChartLevels_dummy: any[];
  isComp442339Visible: "visible" | "hidden";
  isComp954556Visible: "visible" | "hidden";
  isComp137834Visible: "visible" | "hidden";
  isComp273823Visible: "visible" | "hidden";
}

export class CheckupReportcopy_ScreenBase extends React.PureComponent<ICheckupReportcopy_ScreenProps, any> {
  checkupreportcopy_442621_value_kuikaTableRef: React.RefObject<any>;
  checkupreportcopy_774833_value_kuikaTableRef: React.RefObject<any>;
  checkupreportcopy_560588_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 106566, PropertyName: "value", Value: "Name Surname", TagName: "Label0_0_value" },
        { Id: 933266, PropertyName: "value", Value: "Age:", TagName: "Label0_0_value" },
        { Id: 161339, PropertyName: "value", Value: "4/B", TagName: "Label0_1_value" },
        { Id: 425168, PropertyName: "value", Value: "Bilim Yasam Schools", TagName: "Label0_1_value" },
        { Id: 775226, PropertyName: "value", Value: "Report Date:", TagName: "Label0_0_value" },
        { Id: 726038, PropertyName: "value", Value: "FITNESS COMPONENT DETAILS", TagName: "Label0_0_value" },
        {
          Id: 442621,
          PropertyName: "nodatafoundmessage",
          Value: "No data found.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 967050, PropertyName: "title", Value: "Title", TagName: "TableTemplateColumn1_0_title" },
        { Id: 41680, PropertyName: "value", Value: "[datafield:component]", TagName: "Label0_0_value" },
        { Id: 794999, PropertyName: "value", Value: ":", TagName: "Label2_value" },
        { Id: 520599, PropertyName: "value", Value: "[datafield:puan]", TagName: "Label0_1_value" },
        { Id: 736702, PropertyName: "value", Value: "[datafield:sinifadi]", TagName: "Label0_0_value" },
        { Id: 733330, PropertyName: "value", Value: "[datafield:raporaciklama]", TagName: "Label0_5_value" },
        { Id: 35831, PropertyName: "value", Value: "[datafield:testraporsonuccumlesi]", TagName: "Label0_2_value" },
        {
          Id: 882954,
          PropertyName: "value",
          Value: "[datafield:bilesenpuansiniflandirmaaciklama]",
          TagName: "Label0_8_value"
        },
        { Id: 401691, PropertyName: "value", Value: "Instructor's Mark:", TagName: "Label0_0_value" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "eb2ca859-3cd6-432d-ba16-7b541ee6e08a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 106566, PropertyName: "value", Value: "Adı soyadı :" },
        { Id: 933266, PropertyName: "value", Value: "Yaş:" },
        { Id: 161339, PropertyName: "value", Value: "4 / B" },
        { Id: 775226, PropertyName: "value", Value: "Rapor Tarihi :" },
        { Id: 726038, PropertyName: "value", Value: "FITNESS BİLEŞEN DETAYLARI" },
        { Id: 442621, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 967050, PropertyName: "title", Value: "Title" },
        { Id: 41680, PropertyName: "value", Value: "[datafield:bilesen]" },
        { Id: 736702, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 733330, PropertyName: "value", Value: "[datafield:raporaciklama]" },
        { Id: 35831, PropertyName: "value", Value: "[datafield:testraporsonuccumlesi]" },
        { Id: 882954, PropertyName: "value", Value: "[datafield:bilesenpuansiniflandirmaaciklama]" },
        { Id: 774833, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 597291, PropertyName: "title", Value: "Title" },
        { Id: 795648, PropertyName: "value", Value: "[datafield:bilesen]" },
        { Id: 505076, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 958477, PropertyName: "value", Value: "[datafield:raporaciklama]" },
        { Id: 361016, PropertyName: "value", Value: "[datafield:testraporsonuccumlesi]" },
        { Id: 684187, PropertyName: "value", Value: "[datafield:bilesenpuansiniflandirmaaciklama]" },
        { Id: 560588, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 92999, PropertyName: "title", Value: "Title" },
        { Id: 342024, PropertyName: "value", Value: "[datafield:bilesen]" },
        { Id: 170646, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 870620, PropertyName: "value", Value: "[datafield:raporaciklama]" },
        { Id: 175063, PropertyName: "value", Value: "[datafield:testraporsonuccumlesi]" },
        { Id: 844424, PropertyName: "value", Value: "[datafield:bilesenpuansiniflandirmaaciklama]" },
        { Id: 401691, PropertyName: "value", Value: "Eğitmen Notu:" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.checkupreportcopy_442621_value_kuikaTableRef = React.createRef();
    this.checkupreportcopy_774833_value_kuikaTableRef = React.createRef();
    this.checkupreportcopy_560588_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ComponentToPdf: "",
      OpenPdf: "",
      GoBack: "",
      MyContext: [],
      CheckupById: [],
      KisiSelectById: [],
      ProtokolSelectById: [],
      ProtokolReportContentById: [],
      ProtokolSinifSeviyeAciklamaBul: [],
      KurumSelectById: [],
      CheckupDetaylari: [],
      CheckupDetaylari2: [],
      CheckupDetaylari3: [],
      CheckupDetaylariGraph: [],
      Notify: false,
      ChartLevels: [],
      isComp442339Visible: "hidden",
      isComp954556Visible: "hidden",
      isComp137834Visible: "hidden",
      isComp273823Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("checkupreportcopy", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CheckupReportcopyPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("checkupreportcopy", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("checkupreportcopy", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CheckupReportcopyPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      checkupreportcopy_954556_value: this.state.KurumSelectById?.at?.(0)?.logo ?? undefined,
      checkupreportcopy_13060_value: this.state.ProtokolReportContentById?.at?.(0)?.moreInfoQR ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  CheckupReportcopyPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_9: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_10: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupReportcopy/CheckupReportcopyPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.checkupreportcopy_920538_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );
    formVars.checkupreportcopy_710186_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim1Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim1Adi
        : null
    );
    stateVars.isComp137834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].organizasyonelKirilimVarMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].organizasyonelKirilimVarMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp273823Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].organizasyonelKirilimVarMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].organizasyonelKirilimVarMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.CheckupById = result?.data.checkupById;
    formVars.checkupreportcopy_968453_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].yas
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].yas
        : null
    );
    formVars.checkupreportcopy_133156_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].checkupTarihi
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].checkupTarihi
        : null
    );
    formVars.checkupreportcopy_569367_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].egitmeninNotu
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].egitmeninNotu
        : null
    );
    stateVars.KisiSelectById = result?.data.kisiSelectById;
    formVars.checkupreportcopy_120520_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );
    formVars.checkupreportcopy_174781_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );
    stateVars.ProtokolSelectById = result?.data.protokolSelectById;
    stateVars.ProtokolReportContentById = result?.data.protokolReportContentById;
    formVars.checkupreportcopy_753275_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].reportH1
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].reportH1
        : null
    );
    formVars.checkupreportcopy_838293_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].reportH2
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].reportH2
        : null
    );
    formVars.checkupreportcopy_645805_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].introductionHeader
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].introductionHeader
        : null
    );
    formVars.checkupreportcopy_38974_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].introductionContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].introductionContent
        : null
    );
    formVars.checkupreportcopy_91382_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].graphHeader
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].graphHeader
        : null
    );
    formVars.checkupreportcopy_555177_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].resultSummaryHeader
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].resultSummaryHeader
        : null
    );
    formVars.checkupreportcopy_77306_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoContent
        : null
    );
    formVars.checkupreportcopy_396130_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoLink
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoLink
        : null
    );
    formVars.checkupreportcopy_13060_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoQR
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoQR
        : null
    );
    formVars.checkupreportcopy_13060_placeholderimage = "";
    stateVars.ProtokolSinifSeviyeAciklamaBul = result?.data.protokolSinifSeviyeAciklamaBul;
    formVars.checkupreportcopy_462819_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolSinifSeviyeAciklamaBul?.length > 0
        ? stateVars.ProtokolSinifSeviyeAciklamaBul[0].raporAciklama
        : this.state.ProtokolSinifSeviyeAciklamaBul?.length > 0
        ? this.state.ProtokolSinifSeviyeAciklamaBul[0].raporAciklama
        : null
    );
    stateVars.KurumSelectById = result?.data.kurumSelectById;
    formVars.checkupreportcopy_954556_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].logo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].logo
        : null
    );
    formVars.checkupreportcopy_425168_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].unvani
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].unvani
        : null
    );
    stateVars.isComp954556Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].logo
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].logo
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.CheckupDetaylari = result?.data.checkupDetaylari;

    stateVars.CheckupDetaylari2 = result?.data.checkupDetaylari2;

    stateVars.CheckupDetaylari3 = result?.data.checkupDetaylari3;

    stateVars.CheckupDetaylariGraph = result?.data.checkupDetaylariGraph;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupReportcopyPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupReportcopyPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupDetaylari3?.length > 0
          ? stateVars.CheckupDetaylari3[stateVars.CheckupDetaylari3.length - 1].bilesen
          : this.state.CheckupDetaylari3?.length > 0
          ? this.state.CheckupDetaylari3[this.state.CheckupDetaylari3.length - 1].bilesen
          : null
      ),
      "default",
      "bottom-right",
      0,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupReportcopyPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CheckupReportcopyPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      olcumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupReportcopy/CheckupReportcopyPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ChartLevels = result?.data.chartLevels;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupReportcopyPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupReportcopyPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp442339Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.ShowDownload ?? this.props.screenInputs.showdownload,
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupreportcopy_753275_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].reportH1
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].reportH1
        : null
    );

    formVars.checkupreportcopy_838293_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].reportH2
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].reportH2
        : null
    );

    formVars.checkupreportcopy_954556_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].logo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].logo
        : null
    );

    stateVars.isComp954556Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].logo
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].logo
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupreportcopy_120520_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );

    formVars.checkupreportcopy_174781_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );

    formVars.checkupreportcopy_968453_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].yas
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].yas
        : null
    );

    stateVars.isComp137834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].organizasyonelKirilimVarMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].organizasyonelKirilimVarMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupreportcopy_920538_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );

    stateVars.isComp273823Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].organizasyonelKirilimVarMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].organizasyonelKirilimVarMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupreportcopy_710186_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim1Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim1Adi
        : null
    );

    formVars.checkupreportcopy_425168_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].unvani
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].unvani
        : null
    );

    formVars.checkupreportcopy_133156_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].checkupTarihi
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].checkupTarihi
        : null
    );

    formVars.checkupreportcopy_645805_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].introductionHeader
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].introductionHeader
        : null
    );

    formVars.checkupreportcopy_38974_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].introductionContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].introductionContent
        : null
    );

    formVars.checkupreportcopy_91382_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].graphHeader
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].graphHeader
        : null
    );

    stateVars.dataSource_761662 = this.state.CheckupDetaylariGraph;
    stateVars.dataSource_761662 = this.state.CheckupDetaylariGraph;
    formVars.checkupreportcopy_155443_text = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].fitSkor
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].fitSkor
        : null
    );

    formVars.checkupreportcopy_555177_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].resultSummaryHeader
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].resultSummaryHeader
        : null
    );

    formVars.checkupreportcopy_462819_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolSinifSeviyeAciklamaBul?.length > 0
        ? stateVars.ProtokolSinifSeviyeAciklamaBul[0].raporAciklama
        : this.state.ProtokolSinifSeviyeAciklamaBul?.length > 0
        ? this.state.ProtokolSinifSeviyeAciklamaBul[0].raporAciklama
        : null
    );

    stateVars.dataSource_442621 = this.state.CheckupDetaylari;

    stateVars.dataSource_774833 = this.state.CheckupDetaylari2;

    stateVars.dataSource_560588 = this.state.CheckupDetaylari3;

    formVars.checkupreportcopy_569367_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].egitmeninNotu
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].egitmeninNotu
        : null
    );

    formVars.checkupreportcopy_77306_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoContent
        : null
    );

    formVars.checkupreportcopy_396130_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoLink
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoLink
        : null
    );

    formVars.checkupreportcopy_13060_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoQR
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoQR
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CheckupReportcopyComponent_442339_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ComponentToPdf = await ReactSystemFunctions.componentToPDF("810538", "HIGH", "portrait");
    stateVars.OpenPdf = await ReactSystemFunctions.openPdf(
      ReactSystemFunctions.toString(this, stateVars.ComponentToPdf),
      false,
      "newTab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupReportcopyComponent_436910_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupReportcopyComponent_927174_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
