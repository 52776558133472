import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IInvitation_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IInvitation_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  SelectDavetiyeById: any[];
  SelectDavetiyeById_dummy: any[];
  Davetliler: any[];
  Davetliler_dummy: any[];
  DavetHizmetler: any[];
  DavetHizmetler_dummy: any[];
  DavetMerkezler: any[];
  DavetMerkezler_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  DavetAntrenorler: any[];
  DavetAntrenorler_dummy: any[];
  BaseUrl: any[];
  BaseUrl_dummy: any[];
  KullaniciMailLogoUrl: any[];
  KullaniciMailLogoUrl_dummy: any[];
  DavetiyeKaydet: number;
  DavetiyeKaydet_dummy: number;
  SendMail: boolean;
  SendMail_dummy: boolean;
  isComp811270Visible: "visible" | "hidden";
  isComp792819Visible: "visible" | "hidden";
  isComp375753Visible: "visible" | "hidden";
  isComp172851Visible: "visible" | "hidden";
  isComp891262Visible: "visible" | "hidden";
  isComp577924Visible: "visible" | "hidden";
  isComp829537Enabled: "enabled" | "disabled";
  isComp551362Enabled: "enabled" | "disabled";
}

export class Invitation_ScreenBase extends React.PureComponent<IInvitation_ScreenProps, any> {
  invitation_792514_value_kuikaSelectBoxRef: React.RefObject<any>;
  invitation_577888_value_kuikaSelectBoxRef: React.RefObject<any>;
  invitation_74108_value_kuikaMultiSelectRef: React.RefObject<any>;
  invitation_708102_value_kuikaMultiSelectRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Invitation Form", TagName: "lblHead_value" },
        { Id: 893031, PropertyName: "value", Value: "Invitation Number", TagName: "Label3_value" },
        { Id: 33190, PropertyName: "value", Value: "Will payed by", TagName: "Label1_0_value" },
        { Id: 54084, PropertyName: "value", Value: "Invited", TagName: "Label1_0_value" },
        { Id: 792514, PropertyName: "placeholder", Value: "Choose...", TagName: "selDavetli_placeholder" },
        { Id: 691266, PropertyName: "value", Value: "The service it includes", TagName: "Label2_value" },
        { Id: 577888, PropertyName: "placeholder", Value: "Choose...", TagName: "selHizmet_placeholder" },
        { Id: 980896, PropertyName: "value", Value: "Instructors it includes", TagName: "Label1_0_value" },
        { Id: 709349, PropertyName: "value", Value: "Locations it includes", TagName: "Label1_0_value" },
        { Id: 415869, PropertyName: "value", Value: "Credit", TagName: "Label1_0_value" },
        { Id: 990823, PropertyName: "placeholder", Value: "15", TagName: "numKredi_placeholder" },
        { Id: 829537, PropertyName: "label", Value: "SAVE", TagName: "Button1_label" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "2ea5b88a-022d-4d70-aed6-97d36918891c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Davetiye Form" },
        { Id: 893031, PropertyName: "value", Value: "Davetiye Numarası" },
        { Id: 33190, PropertyName: "value", Value: "Ödeyecek Hesap" },
        { Id: 54084, PropertyName: "value", Value: "Davetli" },
        { Id: 792514, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 691266, PropertyName: "value", Value: "Kapsadığı Hizmet" },
        { Id: 577888, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 257450, PropertyName: "value", Value: "Hizmet Bedeli" },
        { Id: 551362, PropertyName: "placeholder", Value: "15" },
        { Id: 980896, PropertyName: "value", Value: "Geçerli Antrenörler" },
        { Id: 74108, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 709349, PropertyName: "value", Value: "Geçerli Merkezler" },
        { Id: 708102, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 829537, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.invitation_792514_value_kuikaSelectBoxRef = React.createRef();
    this.invitation_577888_value_kuikaSelectBoxRef = React.createRef();
    this.invitation_74108_value_kuikaMultiSelectRef = React.createRef();
    this.invitation_708102_value_kuikaMultiSelectRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      SelectDavetiyeById: [],
      Davetliler: [],
      DavetHizmetler: [],
      DavetMerkezler: [],
      SetValueOf: "",
      ChangeEnabledOf: "",
      DavetAntrenorler: [],
      BaseUrl: [],
      KullaniciMailLogoUrl: [],
      DavetiyeKaydet: 0,
      SendMail: false,
      isComp811270Visible: "hidden",
      isComp792819Visible: "hidden",
      isComp375753Visible: "hidden",
      isComp172851Visible: "hidden",
      isComp891262Visible: "hidden",
      isComp577924Visible: "hidden",
      isComp829537Enabled: "enabled",
      isComp551362Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("invitation", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.InvitationPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("invitation", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("invitation", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.InvitationPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      invitation_792514_value: this.state.SelectDavetiyeById?.at?.(-1)?.davetliId ?? undefined,
      invitation_577888_value: this.state.SelectDavetiyeById?.at?.(0)?.kisitHizmetId ?? undefined,
      invitation_551362_value: this.state.SelectDavetiyeById?.at?.(0)?.kredi ?? undefined,
      invitation_74108_value: this.state.SelectDavetiyeById?.at?.(0)?.kisitAntrenorIds ?? undefined,
      invitation_708102_value: this.state.SelectDavetiyeById?.at?.(0)?.kisitMerkezIds ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  InvitationPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Invitation/InvitationPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.invitation_375753_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].hesapAdi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].hesapAdi
        : null
    );
    stateVars.isComp891262Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].davetiyedeEgitmenSecebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].davetiyedeEgitmenSecebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp577924Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].davetiyedeMerkezSecebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].davetiyedeMerkezSecebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SelectDavetiyeById = result?.data.selectDavetiyeById;
    formVars.invitation_172851_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[0].hesap
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[0].hesap
        : null
    );
    formVars.invitation_792514_value =
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[stateVars.SelectDavetiyeById.length - 1].davetliId
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[this.state.SelectDavetiyeById.length - 1].davetliId
        : null;
    formVars.invitation_792514_options = stateVars.Davetliler;
    formVars.invitation_577888_value =
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[0].kisitHizmetId
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[0].kisitHizmetId
        : null;
    formVars.invitation_551362_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[0].kredi
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[0].kredi
        : null
    );
    formVars.invitation_74108_value =
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[0].kisitAntrenorIds
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[0].kisitAntrenorIds
        : null;
    formVars.invitation_708102_value =
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[0].kisitMerkezIds
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[0].kisitMerkezIds
        : null;
    stateVars.isComp375753Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectDavetiyeById?.length > 0
            ? stateVars.SelectDavetiyeById[0].id
            : this.state.SelectDavetiyeById?.length > 0
            ? this.state.SelectDavetiyeById[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172851Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectDavetiyeById?.length > 0
            ? stateVars.SelectDavetiyeById[0].id
            : this.state.SelectDavetiyeById?.length > 0
            ? this.state.SelectDavetiyeById[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.Davetliler = result?.data.davetliler;

    formVars.invitation_792514_value =
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[stateVars.SelectDavetiyeById.length - 1].davetliId
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[this.state.SelectDavetiyeById.length - 1].davetliId
        : null;
    formVars.invitation_792514_options = stateVars.Davetliler;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      davetliKisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectDavetiyeById?.length > 0
            ? stateVars.SelectDavetiyeById[0].davetliId
            : this.state.SelectDavetiyeById?.length > 0
            ? this.state.SelectDavetiyeById[0].davetliId
            : null
        ),
        "Guid"
      ),
      seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectDavetiyeById?.length > 0
            ? stateVars.SelectDavetiyeById[0].kisitHizmetId
            : this.state.SelectDavetiyeById?.length > 0
            ? this.state.SelectDavetiyeById[0].kisitHizmetId
            : null
        ),
        "Guid"
      ),
      seciliAntrenorIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectDavetiyeById?.length > 0
            ? stateVars.SelectDavetiyeById[0].kisitAntrenorIds
            : this.state.SelectDavetiyeById?.length > 0
            ? this.state.SelectDavetiyeById[0].kisitAntrenorIds
            : null
        ),
        "string"
      ),
      seciliMerkezIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectDavetiyeById?.length > 0
            ? stateVars.SelectDavetiyeById[0].kisitMerkezIds
            : this.state.SelectDavetiyeById?.length > 0
            ? this.state.SelectDavetiyeById[0].kisitMerkezIds
            : null
        ),
        "string"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      davetliKisiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "id")
        ),
        "Guid"
      ),
      seciliHizmetId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "hizmetId")
        ),
        "Guid"
      ),
      seciliAntrenorIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_74108_value", "value", "DavetAntrenorler", "id", "id")
        ),
        "string"
      ),
      seciliMerkezIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_708102_value", "value", "DavetMerkezler", "kurumId", "kurumId")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Invitation/InvitationPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DavetHizmetler = result?.data.davetHizmetler;

    formVars.invitation_577888_options = stateVars.DavetHizmetler;
    stateVars.DavetMerkezler = result?.data.davetMerkezler;

    formVars.invitation_708102_options = stateVars.DavetMerkezler;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "invitation_708102_value",
      ReactSystemFunctions.toString(
        this,
        stateVars.DavetMerkezler?.length > 0
          ? stateVars.DavetMerkezler[0].kurumId
          : this.state.DavetMerkezler?.length > 0
          ? this.state.DavetMerkezler[0].kurumId
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  InvitationPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].davetOlusturmaHakkiVarMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].davetOlusturmaHakkiVarMi
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp829537Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.InvitationPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.InvitationPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  InvitationPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.invitation_375753_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].hesapAdi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].hesapAdi
        : null
    );

    stateVars.isComp375753Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectDavetiyeById?.length > 0
            ? stateVars.SelectDavetiyeById[0].id
            : this.state.SelectDavetiyeById?.length > 0
            ? this.state.SelectDavetiyeById[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.invitation_172851_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[0].hesap
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[0].hesap
        : null
    );

    stateVars.isComp172851Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.SelectDavetiyeById?.length > 0
            ? stateVars.SelectDavetiyeById[0].id
            : this.state.SelectDavetiyeById?.length > 0
            ? this.state.SelectDavetiyeById[0].id
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.invitation_792514_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[stateVars.SelectDavetiyeById.length - 1].davetliId
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[this.state.SelectDavetiyeById.length - 1].davetliId
        : null
    );

    stateVars.dataSource_792514 = this.state.Davetliler;
    stateVars.dataSource_792514 = this.state.Davetliler;
    formVars.invitation_577888_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[0].kisitHizmetId
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[0].kisitHizmetId
        : null
    );

    stateVars.dataSource_577888 = this.state.DavetHizmetler;
    stateVars.dataSource_577888 = this.state.DavetHizmetler;
    formVars.invitation_551362_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[0].kredi
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[0].kredi
        : null
    );

    stateVars.isComp891262Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].davetiyedeEgitmenSecebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].davetiyedeEgitmenSecebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.invitation_74108_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[0].kisitAntrenorIds
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[0].kisitAntrenorIds
        : null
    );

    stateVars.isComp577924Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].davetiyedeMerkezSecebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].davetiyedeMerkezSecebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.invitation_708102_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectDavetiyeById?.length > 0
        ? stateVars.SelectDavetiyeById[0].kisitMerkezIds
        : this.state.SelectDavetiyeById?.length > 0
        ? this.state.SelectDavetiyeById[0].kisitMerkezIds
        : null
    );

    stateVars.dataSource_708102 = this.state.DavetMerkezler;
    stateVars.dataSource_708102 = this.state.DavetMerkezler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  InvitationComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  InvitationComponent_792514_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      davetliKisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "id")
        ),
        "Guid"
      ),
      seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "hizmetId")
        ),
        "Guid"
      ),
      seciliAntrenorIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_74108_value", "value", "DavetAntrenorler", "id", "id")
        ),
        "string"
      ),
      seciliMerkezIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_708102_value", "value", "DavetMerkezler", "kurumId", "kurumId")
        ),
        "string"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      davetliKisiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "id")
        ),
        "Guid"
      ),
      seciliHizmetId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "hizmetId")
        ),
        "Guid"
      ),
      seciliAntrenorIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_74108_value", "value", "DavetAntrenorler", "id", "id")
        ),
        "string"
      ),
      seciliMerkezIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_708102_value", "value", "DavetMerkezler", "kurumId", "kurumId")
        ),
        "string"
      ),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      davetliKisiId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "id")
        ),
        "Guid"
      ),
      seciliHizmetId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "hizmetId")
        ),
        "Guid"
      ),
      seciliAntrenorIds_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_74108_value", "value", "DavetAntrenorler", "id", "id")
        ),
        "string"
      ),
      seciliMerkezIds_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_708102_value", "value", "DavetMerkezler", "kurumId", "kurumId")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Invitation/InvitationComponent_792514_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DavetAntrenorler = result?.data.davetAntrenorler;

    formVars.invitation_74108_options = stateVars.DavetAntrenorler;
    stateVars.DavetHizmetler = result?.data.davetHizmetler;

    formVars.invitation_577888_options = stateVars.DavetHizmetler;
    stateVars.DavetMerkezler = result?.data.davetMerkezler;

    formVars.invitation_708102_options = stateVars.DavetMerkezler;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationComponent_792514_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationComponent_792514_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_577888 = this.state.DavetHizmetler;
    stateVars.dataSource_577888 = this.state.DavetHizmetler;

    stateVars.dataSource_74108 = this.state.DavetAntrenorler;
    stateVars.dataSource_74108 = this.state.DavetAntrenorler;

    stateVars.dataSource_708102 = this.state.DavetMerkezler;
    stateVars.dataSource_708102 = this.state.DavetMerkezler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  InvitationComponent_577888_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      davetliKisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "id")
        ),
        "Guid"
      ),
      seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "hizmetId")
        ),
        "Guid"
      ),
      seciliAntrenorIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_74108_value", "value", "DavetAntrenorler", "id", "id")
        ),
        "string"
      ),
      seciliMerkezIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_708102_value", "value", "DavetMerkezler", "kurumId", "kurumId")
        ),
        "string"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      davetliKisiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "id")
        ),
        "Guid"
      ),
      seciliHizmetId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "hizmetId")
        ),
        "Guid"
      ),
      seciliAntrenorIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_74108_value", "value", "DavetAntrenorler", "id", "id")
        ),
        "string"
      ),
      seciliMerkezIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_708102_value", "value", "DavetMerkezler", "kurumId", "kurumId")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Invitation/InvitationComponent_577888_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DavetAntrenorler = result?.data.davetAntrenorler;

    formVars.invitation_74108_options = stateVars.DavetAntrenorler;
    stateVars.DavetMerkezler = result?.data.davetMerkezler;

    formVars.invitation_708102_options = stateVars.DavetMerkezler;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationComponent_577888_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationComponent_577888_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "")
        ),
        null
      ),
      message: "*",
      formName: "invitation_577888_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "invitation_551362_value",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "kredi")
      ),
      null
    );

    stateVars.dataSource_74108 = this.state.DavetAntrenorler;
    stateVars.dataSource_74108 = this.state.DavetAntrenorler;

    stateVars.dataSource_708102 = this.state.DavetMerkezler;
    stateVars.dataSource_708102 = this.state.DavetMerkezler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  InvitationComponent_74108_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      davetliKisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "id")
        ),
        "Guid"
      ),
      seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "hizmetId")
        ),
        "Guid"
      ),
      seciliAntrenorIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_74108_value", "value", "DavetAntrenorler", "id", "id")
        ),
        "string"
      ),
      seciliMerkezIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_708102_value", "value", "DavetMerkezler", "kurumId", "kurumId")
        ),
        "string"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      davetliKisiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "id")
        ),
        "Guid"
      ),
      seciliHizmetId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "hizmetId")
        ),
        "Guid"
      ),
      seciliAntrenorIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_74108_value", "value", "DavetAntrenorler", "id", "id")
        ),
        "string"
      ),
      seciliMerkezIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_708102_value", "value", "DavetMerkezler", "kurumId", "kurumId")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Invitation/InvitationComponent_74108_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DavetHizmetler = result?.data.davetHizmetler;

    formVars.invitation_577888_options = stateVars.DavetHizmetler;
    stateVars.DavetMerkezler = result?.data.davetMerkezler;

    formVars.invitation_708102_options = stateVars.DavetMerkezler;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationComponent_74108_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationComponent_74108_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_577888 = this.state.DavetHizmetler;
    stateVars.dataSource_577888 = this.state.DavetHizmetler;

    stateVars.dataSource_708102 = this.state.DavetMerkezler;
    stateVars.dataSource_708102 = this.state.DavetMerkezler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  InvitationComponent_708102_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      davetliKisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "id")
        ),
        "Guid"
      ),
      seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "hizmetId")
        ),
        "Guid"
      ),
      seciliAntrenorIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_74108_value", "value", "DavetAntrenorler", "id", "id")
        ),
        "string"
      ),
      seciliMerkezIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_708102_value", "value", "DavetMerkezler", "kurumId", "kurumId")
        ),
        "string"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      davetliKisiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "id")
        ),
        "Guid"
      ),
      seciliHizmetId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "hizmetId")
        ),
        "Guid"
      ),
      seciliAntrenorIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_74108_value", "value", "DavetAntrenorler", "id", "id")
        ),
        "string"
      ),
      seciliMerkezIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_708102_value", "value", "DavetMerkezler", "kurumId", "kurumId")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Invitation/InvitationComponent_708102_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DavetAntrenorler = result?.data.davetAntrenorler;

    formVars.invitation_74108_options = stateVars.DavetAntrenorler;
    stateVars.DavetHizmetler = result?.data.davetHizmetler;

    formVars.invitation_577888_options = stateVars.DavetHizmetler;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationComponent_708102_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationComponent_708102_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_577888 = this.state.DavetHizmetler;
    stateVars.dataSource_577888 = this.state.DavetHizmetler;

    stateVars.dataSource_74108 = this.state.DavetAntrenorler;
    stateVars.dataSource_74108 = this.state.DavetAntrenorler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  InvitationComponent_829537_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "invitation_792514_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_74108_value", "value", "DavetAntrenorler", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "invitation_74108_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].davetiyedeEgitmenSecebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].davetiyedeEgitmenSecebilirMi
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "")
        ),
        null
      ),
      message: "*",
      formName: "invitation_577888_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      davetiyeId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid,
        "Guid"
      ),
      davetliId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "id")
        ),
        "Guid"
      ),
      kredi_3: ReactSystemFunctions.convertToTypeByName(0, "number"),
      tarih_3: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      kisiAntrenorIds_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_74108_value", "value", "DavetAntrenorler", "id", "id")
        ),
        "string"
      ),
      kisiMerkezIds_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_708102_value", "value", "DavetMerkezler", "kurumId", "kurumId")
        ),
        "string"
      ),
      kisiHizmetId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitation_577888_value", "value", "DavetHizmetler", "hizmetId", "hizmetId")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Invitation/InvitationComponent_829537_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.invitation_375753_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].hesapAdi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].hesapAdi
        : null
    );
    stateVars.isComp891262Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].davetiyedeEgitmenSecebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].davetiyedeEgitmenSecebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp577924Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].davetiyedeMerkezSecebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].davetiyedeMerkezSecebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.BaseUrl = result?.data.baseUrl;
    stateVars.KullaniciMailLogoUrl = result?.data.kullaniciMailLogoUrl;
    stateVars.DavetiyeKaydet = result?.data.davetiyeKaydet;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationComponent_829537_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationComponent_829537_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "invitation_792514_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("97f9bf74-9dd0-46d6-8d03-05293ea9fe4c", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "eposta")
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("Yeni davetiyeniz var", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      kurumlogo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciMailLogoUrl?.length > 0
            ? stateVars.KullaniciMailLogoUrl[0].maillogourl
            : this.state.KullaniciMailLogoUrl?.length > 0
            ? this.state.KullaniciMailLogoUrl[0].maillogourl
            : null
        ),
        "string"
      ),
      Gonderen_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.DavetMerkezler?.length > 0
            ? stateVars.DavetMerkezler[0].kurumUnvan
            : this.state.DavetMerkezler?.length > 0
            ? this.state.DavetMerkezler[0].kurumUnvan
            : null
        ),
        "string"
      ),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BaseUrl?.length > 0
            ? stateVars.BaseUrl[0].baseUrl
            : this.state.BaseUrl?.length > 0
            ? this.state.BaseUrl[0].baseUrl
            : null
        ),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.Davetliler?.length > 0
            ? stateVars.Davetliler[0].fullname
            : this.state.Davetliler?.length > 0
            ? this.state.Davetliler[0].fullname
            : null
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "invitation_792514_value", "value", "Davetliler", "id", "eposta")
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Invitation/InvitationComponent_829537_onClick1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.InvitationComponent_829537_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.InvitationComponent_829537_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationComponent_829537_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);
    formVars.invitation_375753_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].hesapAdi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].hesapAdi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
