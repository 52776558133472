import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import { Image, KCol, KRow, Label, VerticalStack } from "../../components/web-components";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { Simay_ScreenBase } from "./simay-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class Simay_Screen extends Simay_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              simay_83670_value: null,
              simay_507873_value: null,
              simay_183004_value: null,
              simay_810_value: null,
              simay_74080_value: null,
              simay_984671_value: null,
              simay_184072_value: null
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div
              id="simay_body"
              style={
                {
                  backgroundColor: "rgba(244, 244, 244, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block"
                } as any
              }
            >
              <KRow
                id="197887"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="647524"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KRow
                    id="4031"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 5,
                        paddingLeft: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="620959"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="66608"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="119117"
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          xxl={4}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="448725"
                            value={ReactSystemFunctions.translate(this.ml, 448725, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "18px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>

                          <VerticalStack
                            id="448545"
                            direction="vertical"
                            size={6}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(10, 33, 64, 0.1)",
                                borderStyle: "solid",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="simay_83670_value">
                              <Image
                                id="83670"
                                zoomOnClick={false}
                                imageFit="fill"
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Image>
                            </Form.Item>

                            <Label
                              id="847830"
                              value={ReactSystemFunctions.translate(this.ml, 847830, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(0, 190, 214, 1)"
                                } as any
                              }
                            ></Label>
                          </VerticalStack>
                        </KCol>

                        <KCol
                          id="338973"
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          xxl={10}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="408131"
                            value={ReactSystemFunctions.translate(this.ml, 408131, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "18px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>

                          <KRow
                            id="884977"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(10, 33, 64, 0.1)",
                                borderStyle: "solid",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="349853"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="225360"
                                direction="vertical"
                                size={6}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="simay_507873_value">
                                  <Image
                                    id="507873"
                                    zoomOnClick={false}
                                    imageFit="fill"
                                    style={
                                      {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>

                                <Label
                                  id="904984"
                                  value={ReactSystemFunctions.translate(this.ml, 904984, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(0, 190, 214, 1)"
                                    } as any
                                  }
                                ></Label>
                              </VerticalStack>
                            </KCol>

                            <KCol
                              id="120588"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="707786"
                                direction="vertical"
                                size={6}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="simay_183004_value">
                                  <Image
                                    id="183004"
                                    zoomOnClick={false}
                                    imageFit="fill"
                                    style={
                                      {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>

                                <Label
                                  id="240025"
                                  value={ReactSystemFunctions.translate(this.ml, 240025, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(0, 190, 214, 1)"
                                    } as any
                                  }
                                ></Label>
                              </VerticalStack>
                            </KCol>

                            <KCol
                              id="219009"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="32238"
                                direction="vertical"
                                size={6}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="simay_810_value">
                                  <Image
                                    id="810"
                                    zoomOnClick={false}
                                    imageFit="fill"
                                    style={
                                      {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>

                                <Label
                                  id="858322"
                                  value={ReactSystemFunctions.translate(this.ml, 858322, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(0, 190, 214, 1)"
                                    } as any
                                  }
                                ></Label>
                              </VerticalStack>
                            </KCol>
                          </KRow>
                        </KCol>

                        <KCol
                          id="272"
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          xxl={10}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="77035"
                            value={ReactSystemFunctions.translate(this.ml, 77035, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "18px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>

                          <KRow
                            id="212485"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(10, 33, 64, 0.1)",
                                borderStyle: "solid",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="305846"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="907708"
                                direction="vertical"
                                size={6}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="simay_74080_value">
                                  <Image
                                    id="74080"
                                    zoomOnClick={false}
                                    imageFit="fill"
                                    style={
                                      {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>

                                <Label
                                  id="548140"
                                  value={ReactSystemFunctions.translate(this.ml, 548140, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(0, 190, 214, 1)"
                                    } as any
                                  }
                                ></Label>
                              </VerticalStack>
                            </KCol>

                            <KCol
                              id="608933"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="605848"
                                direction="vertical"
                                size={6}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="simay_984671_value">
                                  <Image
                                    id="984671"
                                    zoomOnClick={false}
                                    imageFit="fill"
                                    style={
                                      {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>

                                <Label
                                  id="918739"
                                  value={ReactSystemFunctions.translate(this.ml, 918739, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(0, 190, 214, 1)"
                                    } as any
                                  }
                                ></Label>
                              </VerticalStack>
                            </KCol>

                            <KCol
                              id="273761"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="36781"
                                direction="vertical"
                                size={6}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="simay_184072_value">
                                  <Image
                                    id="184072"
                                    zoomOnClick={false}
                                    imageFit="fill"
                                    style={
                                      {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Image>
                                </Form.Item>

                                <Label
                                  id="147773"
                                  value={ReactSystemFunctions.translate(this.ml, 147773, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(0, 190, 214, 1)"
                                    } as any
                                  }
                                ></Label>
                              </VerticalStack>
                            </KCol>
                          </KRow>
                        </KCol>
                      </KRow>
                    </KCol>
                  </KRow>
                </KCol>
              </KRow>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Simay_Screen)))))
);
export { tmp as Simay_Screen };
//export default tmp;
//export { tmp as Simay_Screen };
