import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEgitmenAyarlari_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEgitmenAyarlari_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  ProfilSelectById: any[];
  ProfilSelectById_dummy: any[];
  MesaiSaatiD1ByProfile: any[];
  MesaiSaatiD1ByProfile_dummy: any[];
  MesaiSaatiD2ByProfile: any[];
  MesaiSaatiD2ByProfile_dummy: any[];
  MesaiSaatiD3ByProfile: any[];
  MesaiSaatiD3ByProfile_dummy: any[];
  MesaiSaatiD4ByProfile: any[];
  MesaiSaatiD4ByProfile_dummy: any[];
  MesaiSaatiD5ByProfile: any[];
  MesaiSaatiD5ByProfile_dummy: any[];
  MesaiSaatiD6ByProfile: any[];
  MesaiSaatiD6ByProfile_dummy: any[];
  MesaiSaatiD7ByProfile: any[];
  MesaiSaatiD7ByProfile_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  MesaiSaatDelete: number;
  MesaiSaatDelete_dummy: number;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  DeleteRecord: number;
  DeleteRecord_dummy: number;
  isComp887131Visible: "visible" | "hidden";
  isComp531055Visible: "visible" | "hidden";
  isComp414317Visible: "visible" | "hidden";
  isComp425192Visible: "visible" | "hidden";
  isComp207274Visible: "visible" | "hidden";
  isComp86252Visible: "visible" | "hidden";
  isComp969348Visible: "visible" | "hidden";
}

export class EgitmenAyarlari_ScreenBase extends React.PureComponent<IEgitmenAyarlari_ScreenProps, any> {
  egitmenayarlari_451781_value_kuikaTableRef: React.RefObject<any>;
  egitmenayarlari_772261_value_kuikaTableRef: React.RefObject<any>;
  egitmenayarlari_404781_value_kuikaTableRef: React.RefObject<any>;
  egitmenayarlari_735791_value_kuikaTableRef: React.RefObject<any>;
  egitmenayarlari_924530_value_kuikaTableRef: React.RefObject<any>;
  egitmenayarlari_419941_value_kuikaTableRef: React.RefObject<any>;
  egitmenayarlari_538102_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Instructor's Settings", TagName: "lblHead_value" },
        { Id: 472121, PropertyName: "value", Value: "Working hours Start", TagName: "Label2_value" },
        { Id: 161285, PropertyName: "value", Value: "Working hours Finish", TagName: "Label1_0_value" },
        { Id: 324289, PropertyName: "value", Value: "Working Days", TagName: "Label1_0_value" },
        { Id: 799533, PropertyName: "value", Value: "Monday", TagName: "Label1_1_value" },
        { Id: 263451, PropertyName: "value", Value: "Tuesday", TagName: "Label1_1_value" },
        { Id: 206976, PropertyName: "value", Value: "Wednesday", TagName: "Label1_1_value" },
        { Id: 760654, PropertyName: "value", Value: "Thursday", TagName: "Label1_1_value" },
        { Id: 819165, PropertyName: "value", Value: "Friday", TagName: "Label1_1_value" },
        { Id: 52700, PropertyName: "value", Value: "Saturday", TagName: "Label1_1_value" },
        { Id: 472784, PropertyName: "value", Value: "Sunday", TagName: "Label1_1_value" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "0200253a-3cc1-4811-a075-3bd5850381a9",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Eğitmen Mesai Saatleri" },
        { Id: 608619, PropertyName: "value", Value: "Pazartesi" },
        { Id: 451781, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 393817, PropertyName: "title", Value: "Title" },
        { Id: 528013, PropertyName: "value", Value: "[datafield:baslangic]" },
        { Id: 224278, PropertyName: "title", Value: "Title" },
        { Id: 367242, PropertyName: "value", Value: "[datafield:bitis]" },
        { Id: 390329, PropertyName: "title", Value: "Title" },
        { Id: 422570, PropertyName: "value", Value: "Salı" },
        { Id: 772261, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 637533, PropertyName: "title", Value: "Title" },
        { Id: 310858, PropertyName: "value", Value: "[datafield:baslangic]" },
        { Id: 419683, PropertyName: "title", Value: "Title" },
        { Id: 698229, PropertyName: "value", Value: "[datafield:bitis]" },
        { Id: 928438, PropertyName: "title", Value: "Title" },
        { Id: 961387, PropertyName: "value", Value: "Çarşamba" },
        { Id: 404781, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 751984, PropertyName: "title", Value: "Title" },
        { Id: 826311, PropertyName: "value", Value: "[datafield:baslangic]" },
        { Id: 888855, PropertyName: "title", Value: "Title" },
        { Id: 53171, PropertyName: "value", Value: "[datafield:bitis]" },
        { Id: 76445, PropertyName: "title", Value: "Title" },
        { Id: 601926, PropertyName: "value", Value: "Perşembe" },
        { Id: 735791, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 432613, PropertyName: "title", Value: "Title" },
        { Id: 825102, PropertyName: "value", Value: "[datafield:baslangic]" },
        { Id: 287958, PropertyName: "title", Value: "Title" },
        { Id: 187324, PropertyName: "value", Value: "[datafield:bitis]" },
        { Id: 727135, PropertyName: "title", Value: "Title" },
        { Id: 746567, PropertyName: "value", Value: "Cuma" },
        { Id: 924530, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 904252, PropertyName: "title", Value: "Title" },
        { Id: 281851, PropertyName: "value", Value: "[datafield:baslangic]" },
        { Id: 685462, PropertyName: "title", Value: "Title" },
        { Id: 138879, PropertyName: "value", Value: "[datafield:bitis]" },
        { Id: 864201, PropertyName: "title", Value: "Title" },
        { Id: 178993, PropertyName: "value", Value: "Cumartesi" },
        { Id: 419941, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 985719, PropertyName: "title", Value: "Title" },
        { Id: 167320, PropertyName: "value", Value: "[datafield:baslangic]" },
        { Id: 673583, PropertyName: "title", Value: "Title" },
        { Id: 172860, PropertyName: "value", Value: "[datafield:bitis]" },
        { Id: 646462, PropertyName: "title", Value: "Title" },
        { Id: 13683, PropertyName: "value", Value: "Pazar" },
        { Id: 538102, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 160874, PropertyName: "title", Value: "Title" },
        { Id: 618769, PropertyName: "value", Value: "[datafield:baslangic]" },
        { Id: 265373, PropertyName: "title", Value: "Title" },
        { Id: 653195, PropertyName: "value", Value: "[datafield:bitis]" },
        { Id: 534552, PropertyName: "title", Value: "Title" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.egitmenayarlari_451781_value_kuikaTableRef = React.createRef();
    this.egitmenayarlari_772261_value_kuikaTableRef = React.createRef();
    this.egitmenayarlari_404781_value_kuikaTableRef = React.createRef();
    this.egitmenayarlari_735791_value_kuikaTableRef = React.createRef();
    this.egitmenayarlari_924530_value_kuikaTableRef = React.createRef();
    this.egitmenayarlari_419941_value_kuikaTableRef = React.createRef();
    this.egitmenayarlari_538102_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      ProfilSelectById: [],
      MesaiSaatiD1ByProfile: [],
      MesaiSaatiD2ByProfile: [],
      MesaiSaatiD3ByProfile: [],
      MesaiSaatiD4ByProfile: [],
      MesaiSaatiD5ByProfile: [],
      MesaiSaatiD6ByProfile: [],
      MesaiSaatiD7ByProfile: [],
      IfThenElse: false,
      NAVIGATE: "",
      DeleteRecord: false,
      isComp887131Visible: "hidden",
      isComp531055Visible: "hidden",
      isComp414317Visible: "hidden",
      isComp425192Visible: "hidden",
      isComp207274Visible: "hidden",
      isComp86252Visible: "hidden",
      isComp969348Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("egitmenayarlari", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EgitmenAyarlariPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("egitmenayarlari", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("egitmenayarlari", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EgitmenAyarlariPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      egitmenayarlari_599914_value: this.state.ProfilSelectById?.at?.(0)?.mesaiPazartesiVarMi ?? undefined,
      egitmenayarlari_468074_value: this.state.ProfilSelectById?.at?.(0)?.mesaiSaliVarMi ?? undefined,
      egitmenayarlari_543912_value: this.state.ProfilSelectById?.at?.(0)?.mesaiCarsambaVarMi ?? undefined,
      egitmenayarlari_923958_value: this.state.ProfilSelectById?.at?.(0)?.mesaiPersembeVarMi ?? undefined,
      egitmenayarlari_569316_value: this.state.ProfilSelectById?.at?.(0)?.mesaiCumaVarMi ?? undefined,
      egitmenayarlari_714798_value: this.state.ProfilSelectById?.at?.(0)?.mesaiCumartesiVarMi ?? undefined,
      egitmenayarlari_871729_value: this.state.ProfilSelectById?.at?.(0)?.mesaiPazarVarMi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EgitmenAyarlariPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "EgitmenAyarlari/EgitmenAyarlariPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.MesaiSaatiD1ByProfile = result?.data.mesaiSaatiD1ByProfile;

    stateVars.MesaiSaatiD2ByProfile = result?.data.mesaiSaatiD2ByProfile;

    stateVars.MesaiSaatiD3ByProfile = result?.data.mesaiSaatiD3ByProfile;

    stateVars.MesaiSaatiD4ByProfile = result?.data.mesaiSaatiD4ByProfile;

    stateVars.MesaiSaatiD5ByProfile = result?.data.mesaiSaatiD5ByProfile;

    stateVars.MesaiSaatiD6ByProfile = result?.data.mesaiSaatiD6ByProfile;

    stateVars.MesaiSaatiD7ByProfile = result?.data.mesaiSaatiD7ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_451781 = this.state.MesaiSaatiD1ByProfile;
    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_772261 = this.state.MesaiSaatiD2ByProfile;
    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_404781 = this.state.MesaiSaatiD3ByProfile;
    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_735791 = this.state.MesaiSaatiD4ByProfile;
    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_924530 = this.state.MesaiSaatiD5ByProfile;
    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_419941 = this.state.MesaiSaatiD6ByProfile;
    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_538102 = this.state.MesaiSaatiD7ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_599914_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiPazartesi_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_599914_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_599914_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiPazartesi_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      gun_1: ReactSystemFunctions.convertToTypeByName("D1", "string"),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_599914_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.MesaiSaatDelete = result?.data.mesaiSaatDelete;
    stateVars.MesaiSaatiD1ByProfile = result?.data.mesaiSaatiD1ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_599914_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlari_599914_value", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_599914_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlariComponent_599914_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_599914_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_599914_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_599914_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_599914_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_599914_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_887131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D1");
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "MesaiSaatId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_887131_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "697057",
      null,
      null,
      "true",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_887131_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_887131_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD1ByProfile = result?.data.mesaiSaatiD1ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_887131_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_887131_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_451781 = this.state.MesaiSaatiD1ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_311820_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D1");
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "MesaiSaatId",
      ReactSystemFunctions.value(this, "egitmenayarlari_451781_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_311820_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "376176",
      null,
      null,
      "true",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_311820_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_311820_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD1ByProfile = result?.data.mesaiSaatiD1ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_311820_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_311820_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_451781 = this.state.MesaiSaatiD1ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_748612_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "egitmenayarlari_451781_value", "id"),
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_748612_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteRecord = result?.data.deleteRecord_1;
    stateVars.MesaiSaatiD1ByProfile = result?.data.mesaiSaatiD1ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_748612_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_748612_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_451781 = this.state.MesaiSaatiD1ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_468074_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiSali_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_468074_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_3;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_468074_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiSali_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      gun_1: ReactSystemFunctions.convertToTypeByName("D2", "string"),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_468074_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_4;
    stateVars.MesaiSaatDelete = result?.data.mesaiSaatDelete;
    stateVars.MesaiSaatiD2ByProfile = result?.data.mesaiSaatiD2ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_468074_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlari_468074_value", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_468074_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlariComponent_468074_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_468074_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_468074_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_468074_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_468074_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_468074_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_531055_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D2");
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "MesaiSaatId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_531055_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "780475",
      null,
      null,
      "true",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_531055_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_531055_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD2ByProfile = result?.data.mesaiSaatiD2ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_531055_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_531055_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_772261 = this.state.MesaiSaatiD2ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_243135_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D2");
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "MesaiSaatId",
      ReactSystemFunctions.value(this, "egitmenayarlari_772261_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_243135_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "745889",
      null,
      null,
      "true",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_243135_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_243135_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD2ByProfile = result?.data.mesaiSaatiD2ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_243135_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_243135_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_772261 = this.state.MesaiSaatiD2ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_696953_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "egitmenayarlari_772261_value", "id"),
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_696953_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteRecord = result?.data.deleteRecord_2;
    stateVars.MesaiSaatiD2ByProfile = result?.data.mesaiSaatiD2ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_696953_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_696953_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_772261 = this.state.MesaiSaatiD2ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_543912_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiCarsamba_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_543912_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_5;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_543912_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiCarsamba_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      gun_1: ReactSystemFunctions.convertToTypeByName("D3", "string"),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_543912_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_6;
    stateVars.MesaiSaatDelete = result?.data.mesaiSaatDelete;
    stateVars.MesaiSaatiD3ByProfile = result?.data.mesaiSaatiD3ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_543912_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlari_543912_value", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_543912_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlariComponent_543912_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_543912_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_543912_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_543912_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_543912_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_543912_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_414317_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D3");
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "MesaiSaatId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_414317_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "652218",
      null,
      null,
      "true",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_414317_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_414317_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD3ByProfile = result?.data.mesaiSaatiD3ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_414317_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_414317_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_404781 = this.state.MesaiSaatiD3ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_76315_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D3");
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "MesaiSaatId",
      ReactSystemFunctions.value(this, "egitmenayarlari_404781_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_76315_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "356357",
      null,
      null,
      "true",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_76315_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_76315_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD3ByProfile = result?.data.mesaiSaatiD3ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_76315_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_76315_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_404781 = this.state.MesaiSaatiD3ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_282594_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "egitmenayarlari_404781_value", "id"),
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_282594_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteRecord = result?.data.deleteRecord_3;
    stateVars.MesaiSaatiD3ByProfile = result?.data.mesaiSaatiD3ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_282594_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_282594_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_404781 = this.state.MesaiSaatiD3ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_923958_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiPersembe_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_923958_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_7;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_923958_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiPersembe_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      gun_1: ReactSystemFunctions.convertToTypeByName("D4", "string"),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_923958_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_8;
    stateVars.MesaiSaatDelete = result?.data.mesaiSaatDelete;
    stateVars.MesaiSaatiD4ByProfile = result?.data.mesaiSaatiD4ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_923958_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlari_923958_value", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_923958_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlariComponent_923958_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_923958_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_923958_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_923958_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_923958_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_923958_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_425192_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D4");
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "MesaiSaatId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_425192_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "223900",
      null,
      null,
      "true",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_425192_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_425192_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD4ByProfile = result?.data.mesaiSaatiD4ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_425192_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_425192_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_735791 = this.state.MesaiSaatiD4ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_384572_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D4");
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "MesaiSaatId",
      ReactSystemFunctions.value(this, "egitmenayarlari_735791_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_384572_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "620288",
      null,
      null,
      "true",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_384572_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_384572_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD4ByProfile = result?.data.mesaiSaatiD4ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_384572_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_384572_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_735791 = this.state.MesaiSaatiD4ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_360637_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "egitmenayarlari_735791_value", "id"),
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_360637_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteRecord = result?.data.deleteRecord_4;
    stateVars.MesaiSaatiD4ByProfile = result?.data.mesaiSaatiD4ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_360637_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_360637_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_735791 = this.state.MesaiSaatiD4ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_569316_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiCuma_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_569316_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_9;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_569316_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiCuma_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      gun_1: ReactSystemFunctions.convertToTypeByName("D5", "string"),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_569316_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_10;
    stateVars.MesaiSaatDelete = result?.data.mesaiSaatDelete;
    stateVars.MesaiSaatiD5ByProfile = result?.data.mesaiSaatiD5ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_569316_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlari_569316_value", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_569316_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlariComponent_569316_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_569316_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_569316_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_569316_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_569316_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_569316_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_207274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D5");
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "MesaiSaatId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_207274_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "984783",
      null,
      null,
      "true",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_207274_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_207274_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD5ByProfile = result?.data.mesaiSaatiD5ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_207274_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_207274_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_924530 = this.state.MesaiSaatiD5ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_211917_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D5");
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "MesaiSaatId",
      ReactSystemFunctions.value(this, "egitmenayarlari_924530_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_211917_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "970577",
      null,
      null,
      "true",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_211917_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_211917_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD5ByProfile = result?.data.mesaiSaatiD5ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_211917_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_211917_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_924530 = this.state.MesaiSaatiD5ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_762519_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "egitmenayarlari_924530_value", "id"),
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_762519_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteRecord = result?.data.deleteRecord_5;
    stateVars.MesaiSaatiD5ByProfile = result?.data.mesaiSaatiD5ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_762519_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_762519_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_924530 = this.state.MesaiSaatiD5ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_714798_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiCumartesi_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_714798_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_11;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_714798_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiCumartesi_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      gun_1: ReactSystemFunctions.convertToTypeByName("D6", "string"),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_714798_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_12;
    stateVars.MesaiSaatDelete = result?.data.mesaiSaatDelete;
    stateVars.MesaiSaatiD6ByProfile = result?.data.mesaiSaatiD6ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_714798_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlari_714798_value", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_714798_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlariComponent_714798_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_714798_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_714798_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_714798_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_714798_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_714798_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_86252_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D6");
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "MesaiSaatId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_86252_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "273611",
      null,
      null,
      "True",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_86252_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_86252_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD6ByProfile = result?.data.mesaiSaatiD6ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_86252_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_86252_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_419941 = this.state.MesaiSaatiD6ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_409804_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D6");
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "MesaiSaatId",
      ReactSystemFunctions.value(this, "egitmenayarlari_419941_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_409804_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "345798",
      null,
      null,
      "true",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_409804_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_409804_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD6ByProfile = result?.data.mesaiSaatiD6ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_409804_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_409804_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_419941 = this.state.MesaiSaatiD6ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_137827_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "egitmenayarlari_419941_value", "id"),
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_137827_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteRecord = result?.data.deleteRecord_6;
    stateVars.MesaiSaatiD6ByProfile = result?.data.mesaiSaatiD6ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_137827_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_137827_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_419941 = this.state.MesaiSaatiD6ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_871729_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiPazar_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_871729_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_13;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_871729_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiPazar_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      gun_1: ReactSystemFunctions.convertToTypeByName("D7", "string"),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_871729_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_14;
    stateVars.MesaiSaatDelete = result?.data.mesaiSaatDelete;
    stateVars.MesaiSaatiD7ByProfile = result?.data.mesaiSaatiD7ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_871729_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlari_871729_value", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_871729_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlariComponent_871729_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_871729_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_871729_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_871729_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_871729_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_871729_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlari_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlari_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlari_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlari_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlari_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlari_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlari_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_969348_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D7");
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "MesaiSaatId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_969348_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "289773",
      null,
      null,
      "True",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_969348_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_969348_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD7ByProfile = result?.data.mesaiSaatiD7ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_969348_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_969348_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_538102 = this.state.MesaiSaatiD7ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_541438_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "ProfileId",
      this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid
    );
    KuikaAppManager.addToPageInputVariables("MesaiSaatiForm", "Gun", "D7");
    KuikaAppManager.addToPageInputVariables(
      "MesaiSaatiForm",
      "MesaiSaatId",
      ReactSystemFunctions.value(this, "egitmenayarlari_538102_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.EgitmenAyarlariComponent_541438_onClick1_,
      "EgitmenAyarlari",
      "MesaiSaatiForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "925605",
      null,
      null,
      "true",
      "400px",
      "350px",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_541438_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_541438_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatiD7ByProfile = result?.data.mesaiSaatiD7ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_541438_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_541438_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_538102 = this.state.MesaiSaatiD7ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlariComponent_618139_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "egitmenayarlari_538102_value", "id"),
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlari/EgitmenAyarlariComponent_618139_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteRecord = result?.data.deleteRecord_7;
    stateVars.MesaiSaatiD7ByProfile = result?.data.mesaiSaatiD7ByProfile;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlariComponent_618139_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlariComponent_618139_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_538102 = this.state.MesaiSaatiD7ByProfile;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    if (diId == 697057) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_887131_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 376176) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_311820_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 780475) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_531055_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 745889) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_243135_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 652218) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_414317_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 356357) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_76315_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 223900) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_425192_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 620288) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_384572_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 984783) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_207274_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 970577) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_211917_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 273611) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_86252_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 345798) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_409804_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 289773) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_969348_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 925605) {
      isErrorOccurred = await this.EgitmenAyarlariComponent_541438_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
