import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRandevuSelfService3_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRandevuSelfService3_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  HizmetById: any[];
  HizmetById_dummy: any[];
  MerkezById: any[];
  MerkezById_dummy: any[];
  RandevuEgitmenler: any[];
  RandevuEgitmenler_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp341303Enabled: "enabled" | "disabled";
  isComp951289Enabled: "enabled" | "disabled";
  isComp539079Enabled: "enabled" | "disabled";
}

export class RandevuSelfService3_ScreenBase extends React.PureComponent<IRandevuSelfService3_ScreenProps, any> {
  randevuselfservice3_62162_value_kuikaFlexGridRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "baa16dfc-c0a8-45ec-b21e-f2ecb7b32988",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Randevu" },
        { Id: 674513, PropertyName: "value", Value: "Hizmet" },
        { Id: 412775, PropertyName: "value", Value: "Süre" },
        { Id: 478580, PropertyName: "value", Value: "dk" },
        { Id: 267877, PropertyName: "value", Value: "Merkez" },
        { Id: 375332, PropertyName: "value", Value: "Almak istediğiniz eğitmeni seçiniz..." },
        { Id: 62162, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 74345, PropertyName: "value", Value: "[datafield:unvan]" },
        { Id: 179387, PropertyName: "value", Value: "[datafield:fullname]" },
        { Id: 9844, PropertyName: "value", Value: "Yaş" },
        { Id: 83285, PropertyName: "value", Value: "[datafield:yas]" },
        { Id: 468500, PropertyName: "value", Value: "[datafield:sehir]" },
        { Id: 834268, PropertyName: "value", Value: "[datafield:ilce]" },
        { Id: 341303, PropertyName: "label", Value: "İLERİ" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.randevuselfservice3_62162_value_kuikaFlexGridRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      HizmetById: [],
      MerkezById: [],
      RandevuEgitmenler: [],
      ChangeEnabledOf: "",
      NAVIGATE: "",
      isComp341303Enabled: "enabled",
      isComp951289Enabled: "disabled",
      isComp539079Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("randevuselfservice3", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.RandevuSelfService3PageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("randevuselfservice3", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("randevuselfservice3", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.RandevuSelfService3PageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      randevuselfservice3_703048_value: this.state.HizmetById?.at?.(0)?.image ?? undefined,
      randevuselfservice3_30848_value: this.state.MerkezById?.at?.(0)?.logo ?? undefined,
      randevuselfservice3_951289_value: this.state.MerkezById?.at?.(0)?.rate ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  RandevuSelfService3PageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid,
        "Guid"
      ),
      merkezId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MerkezId ?? this.props.screenInputs.merkezid,
        "Guid"
      ),
      rendevuId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid,
        "Guid"
      ),
      seciliHizmetId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid,
        "Guid"
      ),
      seciliMerkezId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MerkezId ?? this.props.screenInputs.merkezid,
        "Guid"
      ),
      seciliEgitmenId_3: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "RandevuSelfService3/RandevuSelfService3PageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.HizmetById = result?.data.hizmetById;
    formVars.randevuselfservice3_703048_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].image
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].image
        : null
    );
    formVars.randevuselfservice3_974562_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].title
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].title
        : null
    );
    formVars.randevuselfservice3_743015_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].aciklama
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].aciklama
        : null
    );
    formVars.randevuselfservice3_863032_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].sure
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].sure
        : null
    );
    stateVars.MerkezById = result?.data.merkezById;
    formVars.randevuselfservice3_30848_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].logo
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].logo
        : null
    );
    formVars.randevuselfservice3_30848_placeholderimage = "";
    formVars.randevuselfservice3_883082_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].unvani
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].unvani
        : null
    );
    formVars.randevuselfservice3_780642_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].acikAdres
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].acikAdres
        : null
    );
    stateVars.RandevuEgitmenler = result?.data.randevuEgitmenler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RandevuSelfService3PageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RandevuSelfService3PageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp341303Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RandevuSelfService3PageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RandevuSelfService3PageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.RandevuEgitmenler?.length > 0
            ? stateVars.RandevuEgitmenler[0].seciliEgitmenId
            : this.state.RandevuEgitmenler?.length > 0
            ? this.state.RandevuEgitmenler[0].seciliEgitmenId
            : null
        ),
        null
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp341303Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.RandevuSelfService3PageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.RandevuSelfService3PageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  RandevuSelfService3PageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.randevuselfservice3_703048_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].image
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].image
        : null
    );

    formVars.randevuselfservice3_974562_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].title
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].title
        : null
    );

    formVars.randevuselfservice3_743015_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].aciklama
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].aciklama
        : null
    );

    formVars.randevuselfservice3_863032_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].sure
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].sure
        : null
    );

    formVars.randevuselfservice3_30848_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].logo
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].logo
        : null
    );

    formVars.randevuselfservice3_883082_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].unvani
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].unvani
        : null
    );

    formVars.randevuselfservice3_780642_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].acikAdres
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].acikAdres
        : null
    );

    formVars.randevuselfservice3_951289_value = ReactSystemFunctions.value(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].rate
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].rate
        : null
    );

    stateVars.dataSource_62162 = this.state.RandevuEgitmenler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RandevuSelfService3Component_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RandevuSelfService3Component_53595_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      rendevuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid,
        "Guid"
      ),
      seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid,
        "Guid"
      ),
      seciliMerkezId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.MerkezId ?? this.props.screenInputs.merkezid,
        "Guid"
      ),
      seciliEgitmenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "randevuselfservice3_62162_value", "id"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RandevuSelfService3/RandevuSelfService3Component_53595_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RandevuEgitmenler = result?.data.randevuEgitmenler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RandevuSelfService3Component_53595_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RandevuSelfService3Component_53595_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp341303Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RandevuSelfService3Component_53595_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RandevuSelfService3Component_53595_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.RandevuEgitmenler?.length > 0
            ? stateVars.RandevuEgitmenler[0].seciliEgitmenId
            : this.state.RandevuEgitmenler?.length > 0
            ? this.state.RandevuEgitmenler[0].seciliEgitmenId
            : null
        ),
        null
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp341303Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.RandevuSelfService3Component_53595_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.RandevuSelfService3Component_53595_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  RandevuSelfService3Component_53595_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_62162 = this.state.RandevuEgitmenler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RandevuSelfService3Component_341303_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "RandevuSlot",
      "RandevuId",
      this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuSlot",
      "EgitmenId",
      ReactSystemFunctions.value(
        this,
        stateVars.RandevuEgitmenler?.length > 0
          ? stateVars.RandevuEgitmenler[0].seciliEgitmenId
          : this.state.RandevuEgitmenler?.length > 0
          ? this.state.RandevuEgitmenler[0].seciliEgitmenId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuSlot",
      "HizmetId",
      this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuSlot",
      "MerkezId",
      this.props.screenInputs.MerkezId ?? this.props.screenInputs.merkezid
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuSlot",
      "DavetiyeId",
      this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuSlot",
      "DanisanKisiId",
      this.props.screenInputs.DanisanKisiId ?? this.props.screenInputs.danisankisiid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.RandevuSelfService3Component_341303_onClick1_,
      "RandevuSelfService3",
      "RandevuSlot",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "764431",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  RandevuSelfService3Component_341303_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [764431] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.RandevuSelfService3PageInit();
    }
    if (diId == 764431) {
      isErrorOccurred = await this.RandevuSelfService3Component_341303_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
