import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEgitmenAyarlaricopy_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEgitmenAyarlaricopy_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MesaiSaatleriSave: number;
  MesaiSaatleriSave_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  ProfilSelectById: any[];
  ProfilSelectById_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  UpdateProfilMesaiPazartesi: number;
  UpdateProfilMesaiPazartesi_dummy: number;
  UpdateProfilMesaiSali: number;
  UpdateProfilMesaiSali_dummy: number;
  UpdateProfilMesaiCarsamba: number;
  UpdateProfilMesaiCarsamba_dummy: number;
  UpdateProfilMesaiPersembe: number;
  UpdateProfilMesaiPersembe_dummy: number;
  UpdateProfilMesaiCuma: number;
  UpdateProfilMesaiCuma_dummy: number;
  UpdateProfilMesaiCumartesi: number;
  UpdateProfilMesaiCumartesi_dummy: number;
  UpdateProfilMesaiPazar: number;
  UpdateProfilMesaiPazar_dummy: number;
  isComp887131Visible: "visible" | "hidden";
  isComp908868Visible: "visible" | "hidden";
  isComp701902Visible: "visible" | "hidden";
  isComp422789Visible: "visible" | "hidden";
  isComp531055Visible: "visible" | "hidden";
  isComp434978Visible: "visible" | "hidden";
  isComp917702Visible: "visible" | "hidden";
  isComp87292Visible: "visible" | "hidden";
  isComp414317Visible: "visible" | "hidden";
  isComp402638Visible: "visible" | "hidden";
  isComp814101Visible: "visible" | "hidden";
  isComp704992Visible: "visible" | "hidden";
  isComp425192Visible: "visible" | "hidden";
  isComp55384Visible: "visible" | "hidden";
  isComp691711Visible: "visible" | "hidden";
  isComp500249Visible: "visible" | "hidden";
  isComp207274Visible: "visible" | "hidden";
  isComp493428Visible: "visible" | "hidden";
  isComp963730Visible: "visible" | "hidden";
  isComp680356Visible: "visible" | "hidden";
  isComp86252Visible: "visible" | "hidden";
  isComp454077Visible: "visible" | "hidden";
  isComp735317Visible: "visible" | "hidden";
  isComp349412Visible: "visible" | "hidden";
  isComp969348Visible: "visible" | "hidden";
  isComp72071Visible: "visible" | "hidden";
  isComp145759Visible: "visible" | "hidden";
  isComp96879Visible: "visible" | "hidden";
}

export class EgitmenAyarlaricopy_ScreenBase extends React.PureComponent<IEgitmenAyarlaricopy_ScreenProps, any> {
  egitmenayarlaricopy_230290_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_678199_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_642305_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_234120_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_904745_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_719092_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_350794_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_652067_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_294598_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_650898_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_807430_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_918143_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_968417_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_89257_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_369138_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_825386_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_363686_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_129998_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_28205_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_628531_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_737116_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_129503_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_466123_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_577013_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_561929_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_689830_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_519253_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_673849_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_317621_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_862953_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_838360_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_554107_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_226461_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_811736_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_566229_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_34896_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_786025_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_6605_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_43236_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_220098_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_528777_value_kuikaDatetimeRef: React.RefObject<any>;
  egitmenayarlaricopy_592177_value_kuikaDatetimeRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Instructor's Settings", TagName: "lblHead_value" },
        { Id: 472121, PropertyName: "value", Value: "Working hours Start", TagName: "Label2_value" },
        { Id: 161285, PropertyName: "value", Value: "Working hours Finish", TagName: "Label1_0_value" },
        { Id: 324289, PropertyName: "value", Value: "Working Days", TagName: "Label1_0_value" },
        { Id: 799533, PropertyName: "value", Value: "Monday", TagName: "Label1_1_value" },
        { Id: 263451, PropertyName: "value", Value: "Tuesday", TagName: "Label1_1_value" },
        { Id: 206976, PropertyName: "value", Value: "Wednesday", TagName: "Label1_1_value" },
        { Id: 760654, PropertyName: "value", Value: "Thursday", TagName: "Label1_1_value" },
        { Id: 819165, PropertyName: "value", Value: "Friday", TagName: "Label1_1_value" },
        { Id: 52700, PropertyName: "value", Value: "Saturday", TagName: "Label1_1_value" },
        { Id: 472784, PropertyName: "value", Value: "Sunday", TagName: "Label1_1_value" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "5517e36d-c9c9-4d90-9612-30e301d91fc5",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 284984, PropertyName: "label", Value: "Kaydet" },
        { Id: 796540, PropertyName: "value", Value: "Eğitmen Mesai Saatleri" },
        { Id: 608619, PropertyName: "value", Value: "Pazartesi" },
        { Id: 230290, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 678199, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 642305, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 234120, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 904745, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 719092, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 422570, PropertyName: "value", Value: "Salı" },
        { Id: 350794, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 652067, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 294598, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 650898, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 807430, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 918143, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 961387, PropertyName: "value", Value: "Çarşamba" },
        { Id: 968417, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 89257, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 369138, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 825386, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 363686, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 129998, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 601926, PropertyName: "value", Value: "Perşembe" },
        { Id: 28205, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 628531, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 737116, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 129503, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 466123, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 577013, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 746567, PropertyName: "value", Value: "Cuma" },
        { Id: 561929, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 689830, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 519253, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 673849, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 317621, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 862953, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 178993, PropertyName: "value", Value: "Cumartesi" },
        { Id: 838360, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 554107, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 226461, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 811736, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 566229, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 34896, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 13683, PropertyName: "value", Value: "Pazar" },
        { Id: 786025, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 6605, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 43236, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 220098, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 528777, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 592177, PropertyName: "placeholder", Value: "Bitiş" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.egitmenayarlaricopy_230290_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_678199_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_642305_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_234120_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_904745_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_719092_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_350794_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_652067_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_294598_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_650898_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_807430_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_918143_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_968417_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_89257_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_369138_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_825386_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_363686_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_129998_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_28205_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_628531_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_737116_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_129503_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_466123_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_577013_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_561929_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_689830_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_519253_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_673849_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_317621_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_862953_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_838360_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_554107_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_226461_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_811736_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_566229_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_34896_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_786025_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_6605_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_43236_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_220098_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_528777_value_kuikaDatetimeRef = React.createRef();
    this.egitmenayarlaricopy_592177_value_kuikaDatetimeRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MesaiSaatleriSave: 0,
      GoBack: "",
      ProfilSelectById: [],
      IfThenElse: false,
      UpdateProfilMesaiPazartesi: 0,
      UpdateProfilMesaiSali: 0,
      UpdateProfilMesaiCarsamba: 0,
      UpdateProfilMesaiPersembe: 0,
      UpdateProfilMesaiCuma: 0,
      UpdateProfilMesaiCumartesi: 0,
      UpdateProfilMesaiPazar: 0,
      isComp887131Visible: "hidden",
      isComp908868Visible: "hidden",
      isComp701902Visible: "hidden",
      isComp422789Visible: "hidden",
      isComp531055Visible: "hidden",
      isComp434978Visible: "hidden",
      isComp917702Visible: "hidden",
      isComp87292Visible: "hidden",
      isComp414317Visible: "hidden",
      isComp402638Visible: "hidden",
      isComp814101Visible: "hidden",
      isComp704992Visible: "hidden",
      isComp425192Visible: "hidden",
      isComp55384Visible: "hidden",
      isComp691711Visible: "hidden",
      isComp500249Visible: "hidden",
      isComp207274Visible: "hidden",
      isComp493428Visible: "hidden",
      isComp963730Visible: "hidden",
      isComp680356Visible: "hidden",
      isComp86252Visible: "hidden",
      isComp454077Visible: "hidden",
      isComp735317Visible: "hidden",
      isComp349412Visible: "hidden",
      isComp969348Visible: "hidden",
      isComp72071Visible: "hidden",
      isComp145759Visible: "hidden",
      isComp96879Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("egitmenayarlaricopy", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EgitmenAyarlaricopyPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("egitmenayarlaricopy", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("egitmenayarlaricopy", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EgitmenAyarlaricopyPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      egitmenayarlaricopy_599914_value: this.state.ProfilSelectById?.at?.(0)?.mesaiPazartesiVarMi ?? undefined,
      egitmenayarlaricopy_468074_value: this.state.ProfilSelectById?.at?.(0)?.mesaiSaliVarMi ?? undefined,
      egitmenayarlaricopy_543912_value: this.state.ProfilSelectById?.at?.(0)?.mesaiCarsambaVarMi ?? undefined,
      egitmenayarlaricopy_923958_value: this.state.ProfilSelectById?.at?.(0)?.mesaiPersembeVarMi ?? undefined,
      egitmenayarlaricopy_569316_value: this.state.ProfilSelectById?.at?.(0)?.mesaiCumaVarMi ?? undefined,
      egitmenayarlaricopy_714798_value: this.state.ProfilSelectById?.at?.(0)?.mesaiCumartesiVarMi ?? undefined,
      egitmenayarlaricopy_871729_value: this.state.ProfilSelectById?.at?.(0)?.mesaiPazarVarMi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EgitmenAyarlaricopyPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "EgitmenAyarlaricopy/EgitmenAyarlaricopyPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_284984_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ptsBas1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_230290_value", "value", "", "", "")
        ),
        "Date"
      ),
      ptsBit1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_678199_value", "value", "", "", "")
        ),
        "Date"
      ),
      ptsBas2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_642305_value", "value", "", "", "")
        ),
        "Date"
      ),
      ptsBit2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_234120_value", "value", "", "", "")
        ),
        "Date"
      ),
      ptsBas3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_904745_value", "value", "", "", "")
        ),
        "Date"
      ),
      ptsBit3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_719092_value", "value", "", "", "")
        ),
        "Date"
      ),
      salBas1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_350794_value", "value", "", "", "")
        ),
        "Date"
      ),
      salBit1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_652067_value", "value", "", "", "")
        ),
        "Date"
      ),
      salBas2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_294598_value", "value", "", "", "")
        ),
        "Date"
      ),
      salBit2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_650898_value", "value", "", "", "")
        ),
        "Date"
      ),
      salBas3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_807430_value", "value", "", "", "")
        ),
        "Date"
      ),
      salBit3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_918143_value", "value", "", "", "")
        ),
        "Date"
      ),
      carBas1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_968417_value", "value", "", "", "")
        ),
        "Date"
      ),
      carBit1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_89257_value", "value", "", "", "")
        ),
        "Date"
      ),
      carBas2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_369138_value", "value", "", "", "")
        ),
        "Date"
      ),
      carBit2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_825386_value", "value", "", "", "")
        ),
        "Date"
      ),
      carBas3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_363686_value", "value", "", "", "")
        ),
        "Date"
      ),
      carBit3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_129998_value", "value", "", "", "")
        ),
        "Date"
      ),
      perBas1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_28205_value", "value", "", "", "")
        ),
        "Date"
      ),
      perBit1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_628531_value", "value", "", "", "")
        ),
        "Date"
      ),
      perBas2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_737116_value", "value", "", "", "")
        ),
        "Date"
      ),
      perBit2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_129503_value", "value", "", "", "")
        ),
        "Date"
      ),
      perBas3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_466123_value", "value", "", "", "")
        ),
        "Date"
      ),
      perBit3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_577013_value", "value", "", "", "")
        ),
        "Date"
      ),
      cumBas1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_561929_value", "value", "", "", "")
        ),
        "Date"
      ),
      cumBit1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_689830_value", "value", "", "", "")
        ),
        "Date"
      ),
      cumBas2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_519253_value", "value", "", "", "")
        ),
        "Date"
      ),
      cumBit2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_673849_value", "value", "", "", "")
        ),
        "Date"
      ),
      cumBas3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_317621_value", "value", "", "", "")
        ),
        "Date"
      ),
      cumBit3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_862953_value", "value", "", "", "")
        ),
        "Date"
      ),
      ctsBas1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_838360_value", "value", "", "", "")
        ),
        "Date"
      ),
      ctsBit1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_554107_value", "value", "", "", "")
        ),
        "Date"
      ),
      ctsBas2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_226461_value", "value", "", "", "")
        ),
        "Date"
      ),
      ctsBit2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_811736_value", "value", "", "", "")
        ),
        "Date"
      ),
      ctsBas3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_566229_value", "value", "", "", "")
        ),
        "Date"
      ),
      ctsBit3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_34896_value", "value", "", "", "")
        ),
        "Date"
      ),
      pzrBas1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_786025_value", "value", "", "", "")
        ),
        "Date"
      ),
      pzrBit1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_6605_value", "value", "", "", "")
        ),
        "Date"
      ),
      pzrBas2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_43236_value", "value", "", "", "")
        ),
        "Date"
      ),
      pzrBit2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_220098_value", "value", "", "", "")
        ),
        "Date"
      ),
      pzrBas3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_528777_value", "value", "", "", "")
        ),
        "Date"
      ),
      pzrBit3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_592177_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_284984_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MesaiSaatleriSave = result?.data.mesaiSaatleriSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_284984_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_284984_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_599914_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiPazartesi_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_599914_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_599914_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiPazartesi_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_599914_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_599914_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_599914_checked", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_599914_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_599914_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_599914_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_599914_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_599914_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_599914_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_599914_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_887131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_887131_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiPazartesi = result?.data.updateProfilMesaiPazartesi;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_887131_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_887131_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_928434_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_928434_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiPazartesi = result?.data.updateProfilMesaiPazartesi;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_928434_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_928434_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_202057_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_202057_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiPazartesi = result?.data.updateProfilMesaiPazartesi;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_202057_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_202057_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_936091_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_936091_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiPazartesi = result?.data.updateProfilMesaiPazartesi;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_936091_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_936091_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_468074_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiSali_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_468074_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_3;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_468074_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiSali_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_468074_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_4;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_468074_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_468074_checked", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_468074_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_468074_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_468074_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_468074_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_468074_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_468074_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_468074_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_531055_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_531055_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiSali = result?.data.updateProfilMesaiSali;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_531055_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_531055_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_575551_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_575551_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiSali = result?.data.updateProfilMesaiSali;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_575551_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_575551_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_218752_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_218752_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiSali = result?.data.updateProfilMesaiSali;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_218752_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_218752_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_525632_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_525632_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiSali = result?.data.updateProfilMesaiSali;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_525632_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_525632_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_543912_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiCarsamba_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_543912_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_5;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_543912_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiCarsamba_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_543912_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_6;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_543912_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_543912_checked", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_543912_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_543912_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_543912_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_543912_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_543912_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_543912_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_543912_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_414317_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_414317_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiCarsamba = result?.data.updateProfilMesaiCarsamba;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_414317_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_414317_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_939746_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_939746_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiCarsamba = result?.data.updateProfilMesaiCarsamba;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_939746_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_939746_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_363694_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_363694_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiCarsamba = result?.data.updateProfilMesaiCarsamba;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_363694_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_363694_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_665858_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_665858_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiCarsamba = result?.data.updateProfilMesaiCarsamba;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_665858_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_665858_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_923958_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiPersembe_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_923958_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_7;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_923958_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiPersembe_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_923958_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_8;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_923958_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_923958_checked", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_923958_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_923958_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_923958_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_923958_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_923958_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_923958_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_923958_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_425192_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_425192_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiPersembe = result?.data.updateProfilMesaiPersembe;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_425192_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_425192_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_178457_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_178457_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiPersembe = result?.data.updateProfilMesaiPersembe;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_178457_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_178457_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_478714_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_478714_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiPersembe = result?.data.updateProfilMesaiPersembe;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_478714_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_478714_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_582900_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_582900_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiPersembe = result?.data.updateProfilMesaiPersembe;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_582900_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_582900_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_569316_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiCuma_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_569316_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_9;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_569316_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiCuma_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_569316_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_10;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_569316_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_569316_checked", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_569316_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_569316_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_569316_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_569316_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_569316_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_569316_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_569316_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_207274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_207274_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiCuma = result?.data.updateProfilMesaiCuma;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_207274_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_207274_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_688921_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_688921_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiCuma = result?.data.updateProfilMesaiCuma;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_688921_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_688921_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_489953_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_489953_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiCuma = result?.data.updateProfilMesaiCuma;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_489953_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_489953_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_466567_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_466567_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiCuma = result?.data.updateProfilMesaiCuma;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_466567_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_466567_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_714798_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiCumartesi_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_714798_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_11;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_714798_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiCumartesi_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_714798_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_12;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_714798_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_714798_checked", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_714798_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_714798_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_714798_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_714798_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_714798_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_714798_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_714798_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_86252_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_86252_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiCumartesi = result?.data.updateProfilMesaiCumartesi;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_86252_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_86252_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_978413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_978413_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiCumartesi = result?.data.updateProfilMesaiCumartesi;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_978413_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_978413_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_170736_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_170736_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiCumartesi = result?.data.updateProfilMesaiCumartesi;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_170736_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_170736_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_17279_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_17279_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiCumartesi = result?.data.updateProfilMesaiCumartesi;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_17279_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_17279_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_871729_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiPazar_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_871729_onChange_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_13;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_871729_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      MesaiPazar_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_871729_onChange_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_14;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_871729_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenayarlaricopy_871729_checked", "value", "", "", "")
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_871729_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenAyarlaricopyComponent_871729_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_871729_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_871729_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_871729_onChange1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_871729_onChange2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_871729_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_969348_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_969348_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiPazar = result?.data.updateProfilMesaiPazar;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_969348_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_969348_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_659214_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_659214_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiPazar = result?.data.updateProfilMesaiPazar;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_659214_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_659214_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_531612_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_531612_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiPazar = result?.data.updateProfilMesaiPazar;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_531612_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_531612_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenAyarlaricopyComponent_808841_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      adet_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      profilId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenAyarlaricopy/EgitmenAyarlaricopyComponent_808841_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UpdateProfilMesaiPazar = result?.data.updateProfilMesaiPazar;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );
    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );
    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );
    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );
    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );
    stateVars.isComp887131Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908868Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp701902Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422789Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531055Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434978Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917702Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp87292Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiSali
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiSali
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp414317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp402638Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814101Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704992Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCarsamba
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCarsamba
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp425192Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55384Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp691711Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp500249Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPersembe
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPersembe
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207274Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493428Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp963730Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680356Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCuma
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCuma
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp86252Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp454077Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735317Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349412Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiCumartesi
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiCumartesi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp969348Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp72071Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145759Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp96879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].mesaiPazar
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].mesaiPazar
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenAyarlaricopyComponent_808841_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenAyarlaricopyComponent_808841_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.egitmenayarlaricopy_599914_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_468074_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiSaliVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiSaliVarMi
        : null
    );

    formVars.egitmenayarlaricopy_543912_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCarsambaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCarsambaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_923958_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPersembeVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPersembeVarMi
        : null
    );

    formVars.egitmenayarlaricopy_569316_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumaVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumaVarMi
        : null
    );

    formVars.egitmenayarlaricopy_714798_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiCumartesiVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiCumartesiVarMi
        : null
    );

    formVars.egitmenayarlaricopy_871729_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].mesaiPazarVarMi
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].mesaiPazarVarMi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
