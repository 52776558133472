import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISignup_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISignup_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  SehirAll: any[];
  SehirAll_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  OpenInBrowser: any;
  OpenInBrowser_dummy: any;
  DummySave: any[];
  DummySave_dummy: any[];
  UserByEmail: any[];
  UserByEmail_dummy: any[];
  Signup: any;
  Signup_dummy: any;
  BaseUrl: any[];
  BaseUrl_dummy: any[];
  NewId: any[];
  NewId_dummy: any[];
  KisiByEmail: any[];
  KisiByEmail_dummy: any[];
  TokenOfUser: any[];
  TokenOfUser_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  SaveRecord: number;
  SaveRecord_dummy: number;
  KurumByAd: any[];
  KurumByAd_dummy: any[];
  ProfilByDetails: any[];
  ProfilByDetails_dummy: any[];
  goToNextScreenAfterSignup: any;
  goToNextScreenAfterSignup_dummy: any;
}

export class Signup_ScreenBase extends React.PureComponent<ISignup_ScreenProps, any> {
  signup_718412_value_kuikaSelectBoxRef: React.RefObject<any>;
  signup_508762_value_kuikaDateRef: React.RefObject<any>;
  signup_701847_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 606162, PropertyName: "value", Value: "Name", TagName: "Label8_0_value" },
        { Id: 388216, PropertyName: "placeholder", Value: "John", TagName: "txtName_placeholder" },
        { Id: 847671, PropertyName: "value", Value: "Surname", TagName: "Label9_0_value" },
        { Id: 100948, PropertyName: "placeholder", Value: "Stamp", TagName: "txtSurname_placeholder" },
        { Id: 797650, PropertyName: "value", Value: "Biological gender", TagName: "Label7_0_value" },
        { Id: 718412, PropertyName: "placeholder", Value: "Choose..", TagName: "selCinsiyet_placeholder" },
        { Id: 472725, PropertyName: "value", Value: "Birth Date", TagName: "Label8_0_value" },
        { Id: 508762, PropertyName: "placeholder", Value: "day/month/year", TagName: "dtDogumTarihi_placeholder" },
        { Id: 520043, PropertyName: "value", Value: "E-mail", TagName: "Label9_0_value" },
        { Id: 298012, PropertyName: "placeholder", Value: "john@name.com", TagName: "txtEmail_placeholder" },
        { Id: 833859, PropertyName: "value", Value: "Phone Number", TagName: "Label19_0_value" },
        { Id: 583989, PropertyName: "placeholder", Value: "0(5__) __ __ __", TagName: "numTelefon_placeholder" },
        { Id: 728076, PropertyName: "value", Value: "City", TagName: "Label18_0_value" },
        { Id: 701847, PropertyName: "placeholder", Value: "Choose...", TagName: "selSehir_placeholder" },
        { Id: 478461, PropertyName: "value", Value: "Password", TagName: "Label10_0_value" },
        { Id: 717714, PropertyName: "placeholder", Value: "********", TagName: "txtPassword_placeholder" },
        { Id: 366692, PropertyName: "value", Value: "Terms of Use", TagName: "Label10_1_value" },
        { Id: 922332, PropertyName: "value", Value: "I read, understand and I agree.", TagName: "Label10_2_value" },
        { Id: 844412, PropertyName: "value", Value: "Privacy Policy", TagName: "Label10_1_value" },
        { Id: 171085, PropertyName: "value", Value: "I read, and I agree.", TagName: "Label10_2_value" },
        { Id: 203218, PropertyName: "value", Value: "Open consent text", TagName: "Label16_1_value" },
        { Id: 221458, PropertyName: "value", Value: "I read, understand and I agree.", TagName: "Label16_2_value" },
        { Id: 76006, PropertyName: "value", Value: "commercial communication", TagName: "Label14_1_value" },
        { Id: 658292, PropertyName: "value", Value: "I read, understand and I agree.", TagName: "Label14_2_value" },
        { Id: 387716, PropertyName: "label", Value: "Sign Up", TagName: "btnGetStarted_label" },
        { Id: 593542, PropertyName: "value", Value: "Allready have an account?", TagName: "lblQ_value" },
        { Id: 687615, PropertyName: "value", Value: "Sign in", TagName: "lnkSignIn_value" },
        { Id: 717912, PropertyName: "value", Value: "Create an account.", TagName: "lblTitle_value" },
        { Id: 997730, PropertyName: "value", Value: "Sign up to get started.", TagName: "lblSubTitle_value" },
        { Id: 621746, PropertyName: "label", Value: "Profesional applications", TagName: "Button1_0_label" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "666d92b2-0e3b-4762-bad3-fc32e24d8eca",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 717912, PropertyName: "value", Value: "Bir hesap oluşturun" },
        { Id: 997730, PropertyName: "value", Value: "Başlamak için kayıt olun." },
        { Id: 621746, PropertyName: "label", Value: "Profesyonel Başvuru" },
        { Id: 606162, PropertyName: "value", Value: "Ad" },
        { Id: 388216, PropertyName: "placeholder", Value: "Deren" },
        { Id: 847671, PropertyName: "value", Value: "Soyad" },
        { Id: 100948, PropertyName: "placeholder", Value: "Mavişehir" },
        { Id: 797650, PropertyName: "value", Value: "Cinsiyet" },
        { Id: 718412, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 472725, PropertyName: "value", Value: "Doğum Tarihi" },
        { Id: 508762, PropertyName: "placeholder", Value: "01/01/2000" },
        { Id: 520043, PropertyName: "value", Value: "E-posta" },
        { Id: 298012, PropertyName: "placeholder", Value: "ali.mavisehir@mail.com" },
        { Id: 833859, PropertyName: "value", Value: "Telefon" },
        { Id: 583989, PropertyName: "placeholder", Value: "0(5__) __ __ __" },
        { Id: 728076, PropertyName: "value", Value: "Şehir" },
        { Id: 701847, PropertyName: "placeholder", Value: "Seçiniz.." },
        { Id: 478461, PropertyName: "value", Value: "Şifre" },
        { Id: 717714, PropertyName: "placeholder", Value: "******" },
        { Id: 366692, PropertyName: "value", Value: "Üyelik sözleşmesi" },
        { Id: 922332, PropertyName: "value", Value: "'ni Okudum, Anladım, Kabul ediyorum." },
        { Id: 844412, PropertyName: "value", Value: "KVKK Aydınlatma Metni" },
        { Id: 171085, PropertyName: "value", Value: "'ni Okudum, Anladım." },
        { Id: 203218, PropertyName: "value", Value: "Açık Rıza Metni" },
        { Id: 221458, PropertyName: "value", Value: "'ni Okudum, Anladım, Kabul ediyorum." },
        { Id: 76006, PropertyName: "value", Value: "Ticari İİetişim" },
        { Id: 658292, PropertyName: "value", Value: "'ni Okudum, Anladım, Kabul ediyorum." },
        { Id: 387716, PropertyName: "label", Value: "Kayıt Ol" },
        { Id: 593542, PropertyName: "value", Value: "Zaten bir hesabın var mı?" },
        { Id: 687615, PropertyName: "value", Value: "Giriş yap" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.signup_718412_value_kuikaSelectBoxRef = React.createRef();
    this.signup_508762_value_kuikaDateRef = React.createRef();
    this.signup_701847_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      SehirAll: [],
      NAVIGATE: "",
      OpenInBrowser: "",
      DummySave: [],
      UserByEmail: [],
      Signup: "",
      BaseUrl: [],
      NewId: [],
      KisiByEmail: [],
      TokenOfUser: [],
      SendMail: false,
      SaveRecord: false,
      KurumByAd: [],
      ProfilByDetails: [],
      goToNextScreenAfterSignup: "",
      dataSource_718412: [
        { key: "Kadin", text: "Kadın" },
        { key: "Erkek", text: "Erkek" }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("signup", "");
      return;
    }

    if (true) {
      await this.SignupPageInit();
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("signup", "");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("387716");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("signup", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SignupPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      signup_17097_value: false,
      signup_52370_value: false,
      signup_431243_value: false,
      signup_476929_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  SignupPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SehirAll = result?.data.sehirAll;

    formVars.signup_701847_options = stateVars.SehirAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_701847 = this.state.SehirAll;
    stateVars.dataSource_701847 = this.state.SehirAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SignupComponent_621746_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Signup",
      "SigninPro",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_366692_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/terms_of_use_tr/",
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_844412_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/privacy_policy_tr/",
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_203218_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://www.memap.com.tr/tr/pages/permitionfortransferdataabroad/",
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_76006_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/permission_for_contact/",
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_387716_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_17097_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "signup_17097_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_52370_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "signup_52370_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_431243_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "signup_431243_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_388216_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_100948_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_717714_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_717714_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_17097_value", "value", "", "", "")
        ),
        "string"
      ),
      prm2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_52370_value", "value", "", "", "")
        ),
        "string"
      ),
      prm3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_431243_value", "value", "", "", "")
        ),
        "string"
      ),
      prm1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")),
        "string"
      ),
      prm2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_100948_value", "value", "", "", "")),
        "string"
      ),
      prm3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_717714_value", "value", "", "", "")),
        "string"
      ),
      email_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DummySave = result?.data.dummySave;
    stateVars.DummySave = result?.data.dummySave;
    stateVars.UserByEmail = result?.data.userByEmail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupComponent_387716_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_717714_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_717714_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_388216_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_100948_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UserByEmail?.length > 0
            ? stateVars.UserByEmail[0].id
            : this.state.UserByEmail?.length > 0
            ? this.state.UserByEmail[0].id
            : null
        ),
        null
      )
    ) {
      stateVars.Signup = (
        (await ReactSystemFunctions.signup(
          this,
          ReactSystemFunctions.value(
            this,
            ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
          ),
          ReactSystemFunctions.value(
            this,
            ReactSystemFunctions.value(this, "signup_717714_value", "value", "", "", "")
          ),
          ReactSystemFunctions.value(
            this,
            ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")
          ),
          ReactSystemFunctions.value(
            this,
            ReactSystemFunctions.value(this, "signup_100948_value", "value", "", "", "")
          ),
          true,
          true,
          "",
          ""
        )) as any
      ).data;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupComponent_387716_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      existingId_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      email_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")),
        "string"
      ),
      email_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BaseUrl = result?.data.baseUrl;
    stateVars.NewId = result?.data.newId;
    stateVars.KisiByEmail = result?.data.kisiByEmail;
    stateVars.UserByEmail = result?.data.userByEmail;
    stateVars.TokenOfUser = result?.data.tokenOfUser;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupComponent_387716_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("2a24a374-5443-4bda-ad66-b8ae9a2e5cbc", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("E-posta doğrulama", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      memap_0: ReactSystemFunctions.convertToTypeByName("MeMap", "string"),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BaseUrl?.length > 0
            ? stateVars.BaseUrl[0].baseUrl
            : this.state.BaseUrl?.length > 0
            ? this.state.BaseUrl[0].baseUrl
            : null
        ),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")),
        "string"
      ),
      verificationCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TokenOfUser?.length > 0
            ? stateVars.TokenOfUser[0].token
            : this.state.TokenOfUser?.length > 0
            ? this.state.TokenOfUser[0].token
            : null
        ),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string")
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TokenOfUser?.length > 0
            ? stateVars.TokenOfUser[0].token
            : this.state.TokenOfUser?.length > 0
            ? this.state.TokenOfUser[0].token
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick3_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.NewId?.length > 0
            ? stateVars.NewId[0].newId
            : this.state.NewId?.length > 0
            ? this.state.NewId[0].newId
            : null
        ),
        "Guid"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName("Hesap", "string")
    };

    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiByEmail?.length > 0
            ? stateVars.KisiByEmail[0].id
            : this.state.KisiByEmail?.length > 0
            ? this.state.KisiByEmail[0].id
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick4_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_1;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_388216_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_718412_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_718412_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_508762_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "signup_508762_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_100948_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_583989_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_583989_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_701847_value", "value", "SehirAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_701847_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")),
        "string"
      ),
      Cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_718412_value", "value", "", "key", "")
        ),
        "string"
      ),
      DogumTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_508762_value", "value", "", "", "")),
        "Date"
      ),
      Eposta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")),
        "string"
      ),
      Soyadi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_100948_value", "value", "", "", "")),
        "string"
      ),
      Telefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_583989_value", "value", "", "", "")),
        "string"
      ),
      SehirId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signup_701847_value", "value", "SehirAll", "id", "id")
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiByEmail?.length > 0
            ? stateVars.KisiByEmail[0].id
            : this.state.KisiByEmail?.length > 0
            ? this.state.KisiByEmail[0].id
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick5_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_2;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick6_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")),
        "string"
      ),
      kurumAdi_1: ReactSystemFunctions.convertToTypeByName("MeMap", "string"),
      rolename_2: ReactSystemFunctions.convertToTypeByName("Rol_Client", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick6_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiByEmail = result?.data.kisiByEmail;
    stateVars.KurumByAd = result?.data.kurumByAd;
    stateVars.ProfilByDetails = result?.data.profilByDetails;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupComponent_387716_onClick7_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiByEmail?.length > 0
            ? stateVars.KisiByEmail[0].id
            : this.state.KisiByEmail?.length > 0
            ? this.state.KisiByEmail[0].id
            : null
        ),
        "Guid"
      ),
      Rolename_0: ReactSystemFunctions.convertToTypeByName("Rol_Client", "string"),
      KurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumByAd?.length > 0
            ? stateVars.KurumByAd[0].id
            : this.state.KurumByAd?.length > 0
            ? this.state.KurumByAd[0].id
            : null
        ),
        "Guid"
      ),
      Expiration_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.DummySave?.length > 0
            ? stateVars.DummySave[0].nextYear
            : this.state.DummySave?.length > 0
            ? this.state.DummySave[0].nextYear
            : null
        ),
        "Date"
      ),
      HesapId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.NewId?.length > 0
            ? stateVars.NewId[0].newId
            : this.state.NewId?.length > 0
            ? this.state.NewId[0].newId
            : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilByDetails?.length > 0
            ? stateVars.ProfilByDetails[0].id
            : this.state.ProfilByDetails?.length > 0
            ? this.state.ProfilByDetails[0].id
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick7_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_3;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupComponent_387716_onClick8_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiByEmail?.length > 0
            ? stateVars.KisiByEmail[0].id
            : this.state.KisiByEmail?.length > 0
            ? this.state.KisiByEmail[0].id
            : null
        ),
        "Guid"
      ),
      rolename_0: ReactSystemFunctions.convertToTypeByName("Rol_Client", "string"),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumByAd?.length > 0
            ? stateVars.KurumByAd[0].id
            : this.state.KurumByAd?.length > 0
            ? this.state.KurumByAd[0].id
            : null
        ),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      KUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.UserByEmail?.length > 0
            ? stateVars.UserByEmail[0].id
            : this.state.UserByEmail?.length > 0
            ? this.state.UserByEmail[0].id
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Signup/SignupComponent_387716_onClick8_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfilByDetails = result?.data.profilByDetails;
    stateVars.SaveRecord = result?.data.saveRecord_4;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupComponent_387716_onClick9_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupComponent_387716_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.SignupComponent_387716_onClick10_,
      "Signup",
      "VerificationCode",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  SignupComponent_387716_onClick10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterSignup(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_687615_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Signup",
      "Signin",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SignupPageInit();
    }
  }
}
