import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEgitmenSozlesmesi_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEgitmenSozlesmesi_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  UserSettingsByUserId: any[];
  UserSettingsByUserId_dummy: any[];
  AnyProfileOfEmail: any[];
  AnyProfileOfEmail_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  isComp217376Enabled: "enabled" | "disabled";
}

export class EgitmenSozlesmesi_ScreenBase extends React.PureComponent<IEgitmenSozlesmesi_ScreenProps, any> {
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "06367d5f-afe8-4342-bb9d-0ad25117e31f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 552925, PropertyName: "value", Value: "SCORESOFT KULLANICI SÖZLESMESI" },
        { Id: 136149, PropertyName: "value", Value: "Madde 1. TARAFLAR:" },
        {
          Id: 971386,
          PropertyName: "value",
          Value:
            "Isbu ScoreSoft Kullanici Sözlesmesi (“Sözlesme”); MeMap Internet Sitesi üye olan ve asagida kisisel bilgileri yer alan ScoreSoft Kullanicisi (“Egitmen”) ile “Çubuklu Mah. Bogaziçi Cad. No:9/1 Beykoz/ ISTANBUL” adresinde mukim SCORESOFT SOLUTIONS A.S.(“MeMap veya Sirket”) arasinda imzalanmistir."
        },
        { Id: 768671, PropertyName: "value", Value: "ScoreSoft Kulanicisinin:" },
        { Id: 448925, PropertyName: "value", Value: "ADI SOYADI:" },
        { Id: 219848, PropertyName: "value", Value: "TCKN:" },
        { Id: 807162, PropertyName: "value", Value: "ADRES:" },
        { Id: 686798, PropertyName: "value", Value: "E-POSTA ADRESI:" },
        { Id: 280703, PropertyName: "value", Value: "TELEFON NUMARASI:" },
        { Id: 517167, PropertyName: "value", Value: "Madde 2. TANIMLAR:" },
        { Id: 495160, PropertyName: "value", Value: "MeMap Internet Sitesi:" },
        { Id: 60155, PropertyName: "value", Value: "www.memap.com.tr URL adresli internet sitesini ifade eder." },
        { Id: 310532, PropertyName: "value", Value: "MeMap Platformu:" },
        {
          Id: 20613,
          PropertyName: "value",
          Value:
            "ScoreSoft kullanicisinin FitSkor lisanslarinin dagitilmasi, hizmete iliskin randevularin yönetilmesi ve danisanlarin FitSkor puanlamasini takip etmesine dair islemlerin yapildigi dijital fitness platformudur."
        },
        { Id: 132098, PropertyName: "value", Value: "Madde 2. TANIMLAR:" },
        { Id: 368472, PropertyName: "value", Value: "MeMap Hizmetleri:" },
        {
          Id: 725672,
          PropertyName: "value",
          Value:
            "Detaylari MeMap Internet Sitesinde bulunan ve MeMap Hizmet Sertifikasi’na sahip Egitmenler tarafindan Danisan’a verilen hizmetlerdir."
        },
        { Id: 219523, PropertyName: "value", Value: "MeMap Hizmet Sertifikasi:" },
        {
          Id: 954823,
          PropertyName: "value",
          Value:
            "MeMap Fitness Akademisi tarafindan, ilgili MeMap Hizmetini verebilecegini sinav ile kanitlamis ScoreSoft Kullanicisina verilen sertifikadir. ScoreSoft Kullanicisinin sahip oldugu sertifikalar ve bu sertifikalara istinaden verebilecegi MeMap Hizmetleri ilgili ScoreSoft Kullanicisinin profil sayfasinda yer alir."
        },
        { Id: 909611, PropertyName: "value", Value: "Üye:" },
        { Id: 109905, PropertyName: "value", Value: "MeMap platformuna kaydolan kisiyi ifade eder." },
        { Id: 581538, PropertyName: "value", Value: "Danisan:" },
        { Id: 625933, PropertyName: "value", Value: "Geçerli FitSkor Lisansina sahip, MeMap üyesini ifade eder." },
        { Id: 39247, PropertyName: "value", Value: "FitSkor:" },
        {
          Id: 751477,
          PropertyName: "value",
          Value:
            "Kisinin yas ve cinsiyetine göre fiziksel uygunluk seviyesini sayisal olarak ifade eden puanlama sistemidir."
        },
        { Id: 938339, PropertyName: "value", Value: "FitSkor Lisansi:" },
        {
          Id: 566443,
          PropertyName: "value",
          Value:
            "MeMap Dijital Platformuna kaydolan kisilerin, e-posta adreslerine özel, tek kisilik, FitSkor hesaplama ve raporlama amaçli yillik ücretlendirilen dijital hizmettir. MeMap Internet Sitesi’ne kayit yaptirilip hesap açilmasina müteakip isbu lisansi satin alinmasiyla birlikte Üye, “Danisan” sifatina haiz olur."
        },
        { Id: 376798, PropertyName: "value", Value: "ScoreSoft Yazilimi:" },
        {
          Id: 289571,
          PropertyName: "value",
          Value:
            "ScoreSoft Lisansi ile kullanilabilen, fitness bilesenlerini gelistirmek için kisilerin fiziksel ve fizyolojik verilerini degerlendirerek kisinin yasa ve cinsiyete göre fiziksel uygunluk notunu belirleyen ve buna bagli olarak tavsiye niteliginde egzersiz stratejisi olusturan dijital içerikli hizmet sunan bir yazilimdir."
        },
        { Id: 141238, PropertyName: "value", Value: "ScoreSoft Lisansi:" },
        {
          Id: 812790,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanicilari için, e-posta adresine özel, tek kisilik ScoreSoft Yazilimini kullanma amaçli yillik ücretlendirilen dijital hizmettir."
        },
        { Id: 37379, PropertyName: "value", Value: "ScoreSoft Kullanicisi:" },
        {
          Id: 402883,
          PropertyName: "value",
          Value: "FitSkor Lisanslarini saglayan, geçerli ScoreSoft Lisansina sahip kullaniciyi ifade eder."
        },
        { Id: 110863, PropertyName: "value", Value: "MeMap Egitmeni:" },
        {
          Id: 133149,
          PropertyName: "value",
          Value:
            "FitSkor Lisanslarini saglayan, kendisi veya merkez tarafindan satin alinmis geçerli ScoreSoft Lisansina sahip, MeMap Fitness Akademisi tarafindan belirlenen egitim kriterlerini karsilayan ve MeMap Hizmetlerini verebilecegini sinav ile ispatlamis ancak halka açik MeMap platformunda yer almayan egitmeni ifade eder. Kisaca “Egitmen” ‘olarak anilacaktir."
        },
        { Id: 629039, PropertyName: "value", Value: "MeMap Platform Egitmeni:" },
        {
          Id: 39294,
          PropertyName: "value",
          Value:
            "FitSkor Lisanslarini saglayan, kendisi tarafindan satin alinmis geçerli ScoreSoft Lisansina sahip, MeMap Fitness Akademisi tarafindan belirlenen egitim kriterlerini karsilayan ve MeMap Hizmetlerini verebilecegini sinav ile ispatlayarak halka açik MeMap platformunda yer alan egitmeni ifade eder. Kisaca “Egitmen” olarak anilacaktir."
        },
        { Id: 947027, PropertyName: "value", Value: "MeMap Elçisi:" },
        {
          Id: 732090,
          PropertyName: "value",
          Value:
            "MeMap felsefesini, standartlarini ve is modelini lisans saglayicilarina aktaran ve bu saglayicilar ile MeMap arasindaki is iliskilerini yürüten kisidir."
        },
        { Id: 136232, PropertyName: "value", Value: "Merkez:" },
        {
          Id: 436113,
          PropertyName: "value",
          Value: "Geçerli Merkez Lisansina sahip, FitSkor Lisanslarini saglayan ticari isletmeyi ifade eder."
        },
        { Id: 103963, PropertyName: "value", Value: "Merkez Lisansi:" },
        {
          Id: 738447,
          PropertyName: "value",
          Value:
            "FitSkor Lisanslarini dagitilmasi ve buna bagli olarak MeMap Hizmetlerinin satilabilmesi için MeMap Dijital Platformunu kullanma hakkinin temin edilmesi suretiyle yillik ücretlendirilen dijital hizmettir."
        },
        { Id: 738124, PropertyName: "value", Value: "ScoreSoft Merkezi:" },
        {
          Id: 586117,
          PropertyName: "value",
          Value: "Geçerli Merkez Lisansina sahip, FitSkor Lisanslarini saglayan ticari isletmeyi ifade eder."
        },
        { Id: 314493, PropertyName: "value", Value: "MeMap Merkezi:" },
        {
          Id: 466031,
          PropertyName: "value",
          Value:
            "Geçerli Merkez Lisansina sahip, MeMap Hizmetlerini sunmak üzere MeMap Merkez standartlarini karsilamis ve bünyesinde minimum bir MeMap Egitmeni barindiran, ancak halka açik MeMap Platformunda yer almayan ve MeMap FitSkor Lisanslarini saglayan ticari isletmeyi ifade eder."
        },
        { Id: 905605, PropertyName: "value", Value: "MeMap Platform Merkezi:" },
        {
          Id: 466604,
          PropertyName: "value",
          Value:
            "Geçerli Merkez Lisansina sahip, MeMap Hizmetlerini sunmak üzere MeMap Merkez standartlarini karsilamis ve bünyesinde minimum bir MeMap Egitmeni barindiran, bununla birlikte halka açik MeMap Platformunda yer alan ve MeMap FitSkor Lisanslarini saglayan ticari isletmeyi ifade eder."
        },
        { Id: 629873, PropertyName: "value", Value: "Kurum:" },
        {
          Id: 108866,
          PropertyName: "value",
          Value:
            "Bir ya da birden fazla MeMap Merkezi ile anlasmali FitSkor Lisanslarini saglayan ticari isletmeyi ifade eder."
        },
        { Id: 219799, PropertyName: "value", Value: "MeMap Fitness Akademisi:" },
        {
          Id: 604677,
          PropertyName: "value",
          Value:
            "www.yeninesilfitnessegitimi.com URL adresli, egitmenlerin standardize edilmis MeMap Hizmetlerini verebilmek amaçli egitimler alabildikleri çevrimiçi Fitness Egitim Platformunu ifade eder."
        },
        { Id: 833317, PropertyName: "value", Value: "Egzersiz Reçetelendirme:" },
        {
          Id: 103810,
          PropertyName: "value",
          Value:
            "FitSkor’u hesaplanmis kisilere, haftada egzersize ayrilacak gün ve süresine bagli, kisiye özel tavsiye niteligindeki önceliklendirilmis fitness bilesenlerinin siralanisini ifade eder."
        },
        { Id: 847408, PropertyName: "value", Value: "Veri:" },
        {
          Id: 274271,
          PropertyName: "value",
          Value:
            "ScoreSoft yaziliminin hesaplamalar yapabilmesi için danisan ve/veya egitmen tarafindan saglanan kisisel verilerdir."
        },
        { Id: 370060, PropertyName: "value", Value: "Know-How:" },
        {
          Id: 82942,
          PropertyName: "value",
          Value:
            "Isbu Sözlesme ile ScoreSoft Kullanicisinin kullanimina tahsis edilen alanlarda bulundugu sekli ile ScoreSoft Yazilimi, MeMap’in isletme sistemleri ve metotlarini ifade eder."
        },
        { Id: 91447, PropertyName: "value", Value: "Madde 3. SÖZLESMENIN KONUSU:" },
        {
          Id: 812249,
          PropertyName: "value",
          Value: "Isbu sözlesmenin konusu MeMap Hizmetlerinin ScoreSoft Lisansina sahip olan;"
        },
        { Id: 84315, PropertyName: "value", Value: "- ScoreSoft Kullanicisi," },
        { Id: 920192, PropertyName: "value", Value: "- MeMap Egitmeni ve/veya," },
        { Id: 198510, PropertyName: "value", Value: "- MeMap Platform Egitmeni," },
        {
          Id: 545131,
          PropertyName: "value",
          Value:
            "kisaca “ScoreSoft Kullanicisi”) tarafindan Danisan’a sunulmasina iliskin olarak Sirket ile Egitmenler arasindaki hak ve yükümlüklerin belirlenmesidir."
        },
        { Id: 43367, PropertyName: "value", Value: "MADDE 4. MEMAP HAKKINDA GENEL BILGILENDIRME" },
        {
          Id: 438083,
          PropertyName: "value",
          Value:
            "MeMap, fiziksel uygunluk ile ilgili hizmetler sunan dijital fitness platformudur. MeMap Platformu’na üye olan kisiler, MeMap hizmetlerinden yararlanabilmek için FitSkor® lisansi edinirler. FitSkor® lisansina sahip üyeler danisan olarak adlandirilirlar. Danisanlar MeMap hizmetlerini, ScoreSoft® yazilimini kullanan egitmenler tarafindan, ilgili egitmenin bagli oldugu MeMap akredite merkezlerde alirlar."
        },
        {
          Id: 290563,
          PropertyName: "value",
          Value:
            "MeMap Platformunda yer alan MeMap Platform Egitmeni, MeMap Fitness Akademisi tarafindan belirlenmis kriterleri karsilayan ve MeMap hizmetlerini verebilecegini sinav ile ispatlamis egitmenlerdir. MeMap Platformu’nda yer alan merkezler ise MeMap merkez standartlarini karsilayan ve bünyesinde en az bir MeMap Platform Egitmeni bulunduran merkezlerdir. MeMap egitmenleri ve merkezleri, ayni zamanda FitSkor® lisanslarinin saglayicilaridir."
        },
        {
          Id: 94310,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanicisi ise FitSkor Lisanslarini saglayan, geçerli ScoreSoft Lisansina sahip kullaniciyi ifade eder. ScoreSoft Kullanicisinin ScoreSoft Lisansina sahip olmasina ragmen MeMap Hizmetlerini, MeMap Fitness Akademisi tarafindan belirlenmis kriter ve standartlara göre verme taahhüdü bulunmamaktadir. Bu bakimdan ScoreSoft Kullanicisi MeMap Dijital Platformu’nda yer almamakta ve MeMap Hizmetlerini verememektedir."
        },
        {
          Id: 396821,
          PropertyName: "value",
          Value:
            "Egitmenlerin kullanimi için gelistirilen ScoreSoft yazilimi, kisinin fiziksel ve fizyolojik verilerini degerlendirerek, yasina ve cinsiyetine göre fiziksel uygunluk notunu (FitSkor®) hesaplayan ve buna bagli olarak tavsiye niteliginde egzersiz stratejisi olusturan bir dijital içerikli hizmet yazilimidir."
        },
        {
          Id: 955947,
          PropertyName: "value",
          Value:
            "Egitmenlerin kullanimi için gelistirilen ScoreSoft yazilimi, kisinin fiziksel ve fizyolojik verilerini degerlendirerek, yasina ve cinsiyetine göre fiziksel uygunluk notunu (FitSkor®) hesaplayan ve buna bagli olarak tavsiye niteliginde egzersiz stratejisi olusturan bir dijital içerikli hizmet yazilimidir."
        },
        {
          Id: 643470,
          PropertyName: "value",
          Value:
            "ScoreSoft yaziliminin tasarlandigi amaca uygun hesaplamalar yapabilmesi için, danisanin platforma kayit esnasinda girilen yas ve cinsiyet bilgisi kullanilir. Danisan tarafindan saglanan diger saglik bilgileri danisanin ibraz ettigi raporlar ve beyanina istinaden egitmen tarafindan kaydedilir. Bunlar disinda hesaplamalar için kullanilan tüm veriler, egitmenin yaptigi ölçümlerden elde edilir. Egitmen, vücut kompozisyonu, kas kuvveti ve esneklik ölçümlerinden elde ettigi fiziksel veriler ile kardiyorespiratuar kapasite ölçümlerinden elde ettigi fizyolojik verileri yazilima girer. Tüm veriler girildikten sonra yazilim, danisanin FitSkor®’unu hesaplar. Egzersiz reçetelendirme stratejisi, danisanin FitSkor®’una, egzersize ayiracagi haftalik gün sayisina ve egzersiz süresine göre tamamen kisisellestirilmis olarak olusturulur."
        },
        { Id: 235635, PropertyName: "value", Value: "MADDE 5: SIRKET’IN HAK VE YÜKÜMLÜLÜKLERI" },
        {
          Id: 21334,
          PropertyName: "value",
          Value:
            "5.1. Sirket, ScoreSoft Kullanicisinin, ScoreSoft Yazilimi’na sunmus oldugu kisisel verileri 6698 sayili Kisisel Verilerin Korunmasi Kanunu’na uygun sekilde isleyecegini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 598864,
          PropertyName: "value",
          Value:
            "5.2. Sirket tarafindan olusturulan ScoreSoft Yazilimi’na kisisel veriler, ScoreSoft Kullanicisi araciligiyla girildikten sonra, üyeye ait fiziksel uygunluk notu (fitskor) ve buna bagli belirlenmis bireysel egzersiz reçetelendirme tavsiyesi saglanacaktir. ScoreSoft Yazilimi’nin sundugu bilgi ve içerik, tavsiye niteliginde olup, buna iliskin sair uygulama, degerlendirme ve tespitten dolayi Sirket sorumlu tutulamaz. Bu dogrultuda, ScoreSoft Kullanicilari, ScoreSoft Yazilimi ve Internet sitesi içindeki her türlü bilgi, egzersiz ve içerigin tavsiye niteliginde oldugunu ve hiçbir hastalik veya saglik durumunun teshis ve tedavisini saglamadigini, bu içerikleri kullanip kullanmamasi konusundaki bütün takdir ve sorumlulugun kendisinde oldugunu gayri kabili rücu kabul, beyan ve taahhüt eder. Sirket, ScoreSoft Kullanicilarinin kendilerinin seçerek uyguladigi programla ilgili olarak sorumlu tutulamaz."
        },
        {
          Id: 373250,
          PropertyName: "value",
          Value:
            "5.3. Sirket, ScoreSoft Kullanicisina bagli olan Danisana daha iyi bir hizmet verebilmek ya da Danisani çesitli kampanyalara davet edebilmek amaciyla Danisanin kisisel verilerini talep edebilir ve bu amaçla Danisana anket ya da bilgi formu doldurtabilir. Sirket sunucu ve bilgisayarlarinda toplanan bu bilgiler, dönemsel kampanya çalismalari, kullanici profillerine yönelik özel promosyon faaliyetlerinin kurgulanmasi ve istenmeyen iletilere yönelik Danisan siniflandirma çalismalarinda Sirket tarafindan kullanilabilecektir."
        },
        {
          Id: 695639,
          PropertyName: "value",
          Value:
            "5.4. Sirket, ScoreSoft Kullanicisi ile Danisan arasindaki iletisimle ilgili olusabilecek herhangi bir hata, ihmal, kesinti, silinme, bozulma, islem veya iletimde gecikme ya da iletisim agi basarisizligindan sorumlu tutulamaz."
        },
        {
          Id: 586818,
          PropertyName: "value",
          Value:
            "5.5. Sirket, uygun gördügü zamanlarda önceden hiçbir bildirimde bulunmadan ScoreSoft Yazilimini gelistirebilir, degistirebilir ve bazi hizmetler için güncellestirmeler ve hatta eksiltmeler yapabilir, yazilimla ilgili görsel, içeriksel ve uygulamaya yönelik degisiklige gidebilir. ScoreSoft Kullanicisi, Sirket’in MeMap üyeligi için öngördügü üyelik bedelini, yazilim içi fiyatlari, kampanya ve paketleri her zaman tek tarafli olarak degistirebilecegini, ek ödemeler olusabilecegini, güncel degisikliklerden yararlanabilmesi için güncellemeleri düzenli olarak yüklemesi ve bunun için bedel ödemesi gerektigini kabul, beyan ve taahhüt eder. Yazilimsal güncellemeleri kabul edip yükleyen üyeler hizmet almaya devam eder."
        },
        {
          Id: 247392,
          PropertyName: "value",
          Value:
            "5.6. Sirket, takdir hakki münhasiran kendisine ait olmak üzere, kendisi veya yetkilendirdigi MeMap Elçisi tarafindan MeMap Egitmeni’ni isbu Sözlesmedeki yükümlülüklerini sözlesmeye uygun sekilde yerine getirip getirmedigine yönelik hiçbir ihbarda bulunmaksizin ve ek yetkilendirmeye gerek olmaksizin her zaman denetleme hak ve yetkisine sahiptir. Sirketin isbu madde kapsamindaki denetim yetkisi, MeMap Hizmetlerini MeMap Fitness Akademisi tarafindan belirlenmis kriter ve standartlara göre verme taahhüdü bulunmayan ScoreSoft Kullanicisi için geçerli degildir."
        },
        {
          Id: 21417,
          PropertyName: "value",
          Value:
            "5.7. Sirket kalite standartlari uyarinca; kendisini temsil eden Memap Egitmeni’nin, fitskor ölçümünde kullandigi bireysel ekipmanlari ile MeMap Hizmetlerini Danisan’a sunacagi esnada kullanacagi kilik ve kiyafetleri denetleme, fitskor olusturma ve uygulama seklini belirleme haklarina sahiptir."
        },
        {
          Id: 203881,
          PropertyName: "value",
          Value:
            "5.7. Sirket kalite standartlari uyarinca; kendisini temsil eden Memap Egitmeni’nin, fitskor ölçümünde kullandigi bireysel ekipmanlari ile MeMap Hizmetlerini Danisan’a sunacagi esnada kullanacagi kilik ve kiyafetleri denetleme, fitskor olusturma ve uygulama seklini belirleme haklarina sahiptir."
        },
        { Id: 872578, PropertyName: "value", Value: "MADDE 6: SCORESOFT KULLANICISININ HAK VE YÜKÜMLÜLÜKLERI" },
        {
          Id: 100638,
          PropertyName: "value",
          Value:
            "6.1. ScoreSoft Kullanicisinin kaydinin yapilabilmesi için EK-1’de yer alan Egitmen Kayit Formu, isbu sözlesmenin akdedildigi tarihte, ilgili ScoreSoft Kullanicisinin bizzat kendisi tarafindan doldurulacaktir. Elektronik veya fiziki ortamda doldurulan form isiginda ScoreSoft Kullanici kayit islemleri tamamlanir. ScoreSoft Kullanicisinin, MeMap Fitness Akademisi tarafindan belirlenmis kriterleri karsilamasi ve MeMap hizmetlerini verebilecegini sinav ile ispatlamis olmasi gerekmektedir."
        },
        {
          Id: 134596,
          PropertyName: "value",
          Value:
            "6.2. Egitmen Kayit Formunda bulunan bilgilerin ScoreSoft Kullanicisinin kendisine ait olmasi gerekmektedir. ScoreSoft Kullanicisinin kendisinden baska bir kisiye ait belge, bilgi vermesinden olusabilecek aksakliklardan ve zararlardan ScoreSoft Kullanicisinin bizatihi kendisi sorumludur. ScoreSoft Kullanicisi, bu nedenle dogabilecek zararlardan dolayi Sirkete rücu hakki bulunmadigini gayri kabili rücu kabul, beyan ve taahhüt eder."
        },
        {
          Id: 422464,
          PropertyName: "value",
          Value:
            "6.3. ScoreSoft Kullanicisinin profil sayfasinda kullanilan fotograflar, logolar ve diger görseller, ScoreSoft Kullanicisinin kendisine ait kabul edilir. ScoreSoft Kullanicisinin; MeMap Platformuna üye olurken verdigi e-posta adresinin, ScoreSoft Kullanicisina ait ve aktif bir e-posta adresi olma zorunlulugu bulunmaktadir. Sözlesme kapsaminda yapilacak tüm yazismalarda, MeMap Platformunda yer alan “sifremi unuttum”, “mesaji sikâyet et” bölümünde sisteme üye olurken verilen e-posta adresi esas alinir ve bu adres kullanilir. Yanlis ve/veya gerçek disi beyan edilen e-postalardan olusabilecek aksaklik ve zararlarin tümünden ScoreSoft Kullanicisi tek basina sorumludur."
        },
        {
          Id: 154868,
          PropertyName: "value",
          Value:
            "6.4. Taraflarin yapacagi müzakere neticesinde alinacak ortak karara göre ScoreSoft Kullanicilarina üç farkli üyelik tanimlanabilir:"
        },
        {
          Id: 70846,
          PropertyName: "value",
          Value: "6.4.1. Halka açik MeMap Platformunda yer alan MeMap Platform Egitmeni,"
        },
        {
          Id: 321305,
          PropertyName: "value",
          Value: "6.4.2. Halka açik MeMap Platformunda yer almayan MeMap Egitmeni veya"
        },
        {
          Id: 354685,
          PropertyName: "value",
          Value: "6.4.3. Halka açik MeMap Platformunda yer almayan ScoreSoft Kullanicisi"
        },
        {
          Id: 202552,
          PropertyName: "value",
          Value:
            "halka açik olan MeMap Platformu Egitmeni’nin profil sayfasi tüm MeMap üyelerine açik iken, sadece kendi üyelerine hizmet veren Egitmen, MeMap platformunda yer almadigindan üçüncü kisilerin ulasimina kapalidir."
        },
        {
          Id: 14482,
          PropertyName: "value",
          Value:
            "6.5. ScoreSoft Kullanicisi, kisisel sosyal medya hesaplarinda veya fiziki ortamda MeMap Platformuyla ilgili olarak Sirket bilgisi disinda herhangi bir sekilde reklam yapamaz. Reklam içerigi ancak önceden Sirketle yazili mutabakat yapilarak Sirketin rizasiyla yapilabilir."
        },
        {
          Id: 14673,
          PropertyName: "value",
          Value:
            "6.6. ScoreSoft Kullanicisinin, ScoreSoft Yazilimini kullanabilmesi için 18 yasindan büyük olmasi gerekmektedir."
        },
        {
          Id: 140424,
          PropertyName: "value",
          Value:
            "6.7. MeMap Egitmeni, Danisan’a Sirket’in ilgili MeMap Hizmetine özgülenmis sekilde özel olarak belirledigi standartlara uymasini temin etmesi suretiyle, Danisan’in saglik kontrolünden geçmesini saglamak, saglik durumu degerlendirmesi yapmak, Danisan’a uygun sekilde MeMap standartlarindaki ölçüm protokollerini uygulayarak FitScore olusturmak, makul sürede randevu ayarlamak, Danisana verilerini kontrol ettirmek ve onaylattirmakla yükümlü ve dogrudan sorumludur. Isbu maddede belirtilen yükümlülükler, MeMap Hizmetlerini MeMap Fitness Akademisi tarafindan belirlenmis kriter ve standartlara göre sunma taahhüdü bulunmayan ScoreSoft Kullanicisi için geçerli degildir."
        },
        {
          Id: 469889,
          PropertyName: "value",
          Value:
            "6.8. ScoreSoft Kullanicisi, Danisandan talep ettigi ya da talep etmese dahi Danisan tarafindan yazili ve/veya sözlü olarak bildirilen kisisel verilerin, dogru ve gerçegi yansittigi faraziyesiyle ScoreSoft Yazilimina girmektedir. Danisan tarafindan yazili ve/veya sözlü olarak bildirilen verilerin, sisteme dogru olarak islenmesindeki münhasir sorumluluk -verilerin, danisan tarafindan dogru verilmemesi hali hariç olmak üzere- ScoreSoft Kullanicisina aittir. Danisan tarafindan kisisel verilerin yazili ve/veya sözlü olarak dogru bildirildigi ancak ScoreSoft Kullanicisi tarafindan yanlis kaydedilmesi halinde Danisanin ugrayacagi dogrudan ve/veya dolayli ve sair zararlardan ScoreSoft Kullanicisi münhasiran sorumludur. Isbu halde, ScoreSoft Kullanicisi Sirkete karsi her türlü talep ve/veya rücu hakkindan feragat ettigini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 533019,
          PropertyName: "value",
          Value:
            "6.9. MeMap Egitmeni ve/veya Danisan randevusunu 6 saat öncesine kadar iptal edebilir. Randevu saatinden 6 saatten daha az kaldigi durumlarda, hastalik, ölüm hallerinde rapor ya da belge sunuldugunda seans ücretsiz olarak iptal edilecektir. Egitmen, onayladigi randevuya katilmamasi halinde danisan ölçüm ücretini iade alabilir veya yeni bir randevu olusturabilir. Ancak Danisan, randevusunu yukarida yer alan sartlar dahilinde iptal etmez ve/veya randevu saatinde hazir bulunmazsa egitmene seans ücreti ödeyecegini kabul, beyan ve taahhüt etmistir. Egitmen ile Danisanin randevulasma sürecinde Sirket taraf degildir. Taraflar isbu minvalde Sirketten hiçbir hak talebinde bulunmayacagini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 868310,
          PropertyName: "value",
          Value:
            "6.10. ScoreSoft Kullanicisi, kendisine tahsis edilen ScoreSoft Yazilimi üzerinde sadece bir kisinin kullanimina özgü olan FitSkor Lisansi ile ayni anda 2 (iki) veya daha fazla Danisana hizmet vermeyecegini kabul, taahhüt ve beyan eder."
        },
        {
          Id: 516580,
          PropertyName: "value",
          Value:
            "6.11. ScoreSoft Kullanicisi gerek Sirket’in kendisini tanitici reklam, görsel ve malzemelerinde gerekse sponsorluk sözlesmelerinden dogan gerekler dogrultusunda Sirket’in talimatlarina uyacagini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 895638,
          PropertyName: "value",
          Value:
            "6.12. ScoreSoft Kullanicisi her ne sekil, suret ve nam altinda olursa olsun herhangi bir üyenin ve/veya Sirket’in her türlü verilerinin gizliligini, fikri ve mülkiyet haklarini ve sair kanuni haklardan kaynaklanan bilgi, yazilim ya da baska bir malzemeyi hak sahibinden ya da yararlananindan izin almaksizin, kullanmayacagini, yayinlamayacagini ya da paylasmayacagini kabul, beyan ve taahhüt eder. Isbu ihlallerden dolayi ScoreSoft Kullanicisi, Sirketin kendisine cezai yaptirimda bulunma hakkinin sakli bulundugunu kabul eder."
        },
        {
          Id: 547766,
          PropertyName: "value",
          Value:
            "6.13. ScoreSoft Kullanicisi, sadece Sirket tarafindan saglanmis veya onaylanmis reklam ve tanitim malzeme ve programlarini kullanabilir. Sirket tarafindan yazili olarak kararlastirilmayan her türlü reklam harcamasi ScoreSoft Kullanicisinin sahsina ait olacaktir"
        },
        {
          Id: 81446,
          PropertyName: "value",
          Value:
            '6.14. ScoreSoft Kullanicisi, yazilim veri tabaninda, blog ya da yorum ekranlarinda, MeMap’in bilgisi disinda ticari amaç tasiyan ya da reklam içeren hiçbir bilgi, yazilim ya da malzemenin ticareti, yayinlanmasini, reklamini veya paylasimini yapmayacagini kabul ve beyan eder. Bu amaçla üyelere yönelik "e-posta zincirleri"; "gereksiz e-posta" gönderemeyecegini kabul, beyan ve taahhüt eder.'
        },
        {
          Id: 238609,
          PropertyName: "value",
          Value:
            "6.15. ScoreSoft Kullanicisinin, Danisan’a yönelik aktif ya da pasif hareketi ile taksiren, kasten ya da kusuru sebebiyle Danisan nezdinde ortaya çikabilecek tüm zararlarin tazmini gayrikabili rücu sekilde ScoreSoft Kullanicisina aittir."
        },
        {
          Id: 150100,
          PropertyName: "value",
          Value:
            "6.16. ScoreSoft Kullanicisi, isbu sözlesmenin ihlal edilmesinden dolayi Sirketin, subelerinin, yetkililerinin, temsilcilerinin ve diger çalisanlarinin ugradigi ya da ugrayacagi maddi ve manevi zararlari ile yoksun kalinan karlari karsilamayi kabul, beyan ve taahhüt eder."
        },
        {
          Id: 508640,
          PropertyName: "value",
          Value:
            "6.17. ScoreSoft Kullanicisi, Sirket’in yazili onayini almak kaydiyla baska markalar ile sponsorluk sözlesmeleri yapabilir.  Egitmen’in isbu sponsorluk sözlesmesi kapsaminda elde ettigi gelirlerin paylasilmasina iliskin hükümler saklidir."
        },
        {
          Id: 900970,
          PropertyName: "value",
          Value:
            "6.18. ScoreSoft Kullanicisi, Sirket tarafindan imzalanan sponsorluk sözlesmelerinden hiçbir nam ve ad adi altinda ücret talep etmeyecegini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 529971,
          PropertyName: "value",
          Value:
            "6.19. ScoreSoft Kullanicisi, kendisinin ve/veya Danisani’nin; kisisel verilerinde, ölçüm degerleri ve fitskor parametrelerinde olagandisi hareketlilik, devamli olarak yeni deger ve veriler girdiginin tespit edilmesi halinde ScoreSoft Kullanicisinin sisteme kullanma hakki geçici olarak dondurulacaktir."
        },
        {
          Id: 283539,
          PropertyName: "value",
          Value:
            "6.20. ScoreSoft Kullanicisi tarafindan kendi uzmanlik alanlarina girmeyen konulara ve/veya Memap hizmet standartlarina aykiri faaliyet gösterilmesine iliskin ortaya çikan is ve islemlerden ötürü, müsteri ve/veya sair 3. kisiler nezdinde ortaya çikabilecek her türlü hasar, zarar vb. tazmini gerektirir hususa iliskin sorumluluk ScoreSoft Kullanicisina aittir. Söz konusu durumlar uyarinca MEMAP’e gelebilecek her türlü talep kapsaminda, MEMAP’i tazmin edecek ve herhangi bir zarar görmemesini temin edecektir. Herhangi bir sekilde MEMAP’in zarar görmesi halinde söz konusu zarari derhal, aynen, nakden ve defaten tazmin edecektir. ScoreSoft Kullanicisi yanlis veri kullanimdan dolayi yazilim tarafindan olusturulan sonuçlardan MEMAP’in sorumlu tutulamayacagini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 291136,
          PropertyName: "value",
          Value:
            "6.21. ScoreSoft Kullanicisi, Sirket ismini kullanirken yazili izin almak ve rekabet yasagi kuralina uyacagini kabul, beyan ve taahhüt eder."
        },
        { Id: 603992, PropertyName: "value", Value: "MADDE 7: LISANS TÜRLERI ILE LISANS TÜRÜNE BAGLI GELIR PAYLASIMI" },
        {
          Id: 530546,
          PropertyName: "value",
          Value:
            "7.1. Sirket’in ScoreSoft Kullanicisina vermis oldugu inhisari olmayan (basit) Lisans Hakki; Türkiye Cumhuriyeti cografi bölgesiyle ile sinirli olmak üzere isbu sözlesmenin 7.2. maddesinde tanimli ürünlerin (lisanslarin) satis ve dagitimiyla sinirlidir. MeMap Platformu faaliyetine özgü tüm kulanim alanlari ile ilgili kullanma, üretim, satis, dagitim, ithalat, ihracat, pazarlama ve benzeri tüm kullanim haklari Sirket’e aittir."
        },
        {
          Id: 492275,
          PropertyName: "value",
          Value:
            "7.2. MeMap Internet Sitesine kaydolan üye henüz bir “FitSkor Lisansi”na sahip degildir. Üyenin MeMap Internet Sitesi’ne kaydolma nedeni MeMap Hizmetlerini sunan Merkez ve Egitmenlerin detaylarina ulasmaktir. Isbu sözlesmeye istinaden ScoreSoft Kullanicisi, Danisan’a FitSkor Lisansini sadece FullCard lisans türüyle sunabilirken, MeMap Egitmeni üç farkli seçenek ile FitSkor Lisansi sunabilir. Söz konusu lisans türleri ve her bir lisans türüne iliskin gelir paylasim modeli asagidaki sekildedir."
        },
        {
          Id: 328326,
          PropertyName: "value",
          Value:
            "7.2.1. FullCard: Danisan FullCard ile Yillik FitSkor Lisansini ve bir adet MeMap Hizmetini herhangi bir ücret ödemeden elde eder. FullCard ürününde Egitmen, FitSkor Lisansi’ni önce Sirket araciligiyla satin alir. MeMap Egitmeni, FullCard lisansina bagli olan MeMap hizmetini isbu sözlesmenin 9.6. maddesi uyarinca Sirket tarafindan MeMap Platformunda belirlenmis olan hizmet bedellerinin üzerinde bir fiyat ile Danisanlara temin edemeyecegini ve fakat MeMap platformunda üst limit olarak belirlenen fiyati geçmemek sartiyla bu ürünü diledigi fiyata Danisana satabilecegini kabul eder."
        },
        {
          Id: 339439,
          PropertyName: "value",
          Value:
            "7.2.2. ScoreCard: Danisan ScoreCard ile Yillik FitSkor Lisans ücretini ödemekte, MeMap Hizmeti için herhangi bir ücret ödememektedir. Bu dogrultuda, MeMap Egitmeni ScoreCard satin alir ve söz konusu ScoreCard’i kendi Danisan’ina tahsis ederek MeMap Hizmetini ücretsiz vermeyi taahhüt etmis olur. MeMap Platformuna kaydolan üye ise, FitSkor Lisansini dogrudan kendisi satin almis olur ve Danisan sifatini kazanir. Böylelikle Danisan ilk randevusunu Lisans Saglayicisindan alirken daha sonraki randevularini MeMap platformunda yer alan Merkezlerden herhangi birinden alma seçenegine sahip olur. MeMap Egitmeni tarafindan MeMap Platformuna davet edilen ve bu sekilde satilan FitSkor Lisans ücretinin %50’si (banka komisyonlari düsüldükten sonra) MeMap Egitmenine Sirket tarafindan geri ödenir."
        },
        {
          Id: 397431,
          PropertyName: "value",
          Value:
            "7.2.3. LicenseCard: Danisan LicenseCard ile Yillik FitSkor Lisans ücretini ödememekte, MeMap Hizmeti için ücret ödemektedir. LicenceCard ürününde MeMap Egitmeni, FitSkor Lisansi’ni önce Sirket araciligiyla satin alir. Bu dogrultuda, MeMap Platformuna giren Danisan, sadece Lisans Saglayicidan randevu alabilir ve MeMap hizmet bedelini MeMap Platformu araciligiyla öder. Sirket, bu yöntemle satilan LicenceCard ücretinin (banka komisyonlari düsüldükten sonra) %25 komisyonunu tahsil ettikten sonra geri kalan meblagi MeMap Egitmenine geri öder."
        },
        {
          Id: 340552,
          PropertyName: "value",
          Value:
            "7.3. Ödeme Metodu: Ödemelere iliskin sartlar, Egitmenle yapilacak karsilikli görüsmeler sonucunda, taraflarin ortak iradelerine göre sekillendirilecektir. Buna göre ödemeler, FullCard ve LicenceCard için Egitmenin tercihine göre iki farkli sekilde yapilabilir:"
        },
        {
          Id: 131088,
          PropertyName: "value",
          Value:
            "7.3.1. ScoreSoft Kullanicisinin talebine bagli olarak MeMap Platformunda sayisi önceden belirlenmis miktarlar üzerinden lisans seçimi yapilmasi suretiyle."
        },
        {
          Id: 410550,
          PropertyName: "value",
          Value:
            "7.3.2. MeMap Platformunda belirlenen miktarlarin haricinde ScoreSoft Kullanicisi tarafindan toplu lisans alimi yapilmasi suretiyle."
        },
        {
          Id: 929170,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanicisinin satin alacagi ScoreSoft Lisansi bir (1) seneliktir. Yazilim Kullanim Lisansina iliskin ödeme, elektronik ortamda kredi karti ya da havale veya EFT ile …………………………. IBAN numarali hesap numarasina yapilacaktir."
        },
        {
          Id: 19845,
          PropertyName: "value",
          Value:
            "7.4. Üyeligin Devam Ettirilmesi: Sirket; ScoreSoft Kullanicisina MeMap Üyeliginin sona erecegi dönem sonundan 30. gün önce, e-mail göndererek Egitmenin yazilimi kullanmaya devam edecegini ve yeni dönemde faturalandirmaya devam edilecegi hususunu yazili olarak bildirecektir. ScoreSoft Kullanicisi, yeni dönemde de isbu sözlesmeye devam etmek isterse, ödemesini yaparak, Sirket’e sözlesmeye devam etmek istedigine yönelik olumlu görüsünü e-posta marifetiyle bildirecektir."
        },
        {
          Id: 817747,
          PropertyName: "value",
          Value:
            "7.5. MeMap, ScoreSoft Kullanicisinin satin aldigi her bir FitSkor lisansi için üyeligin baslamasindan itibaren ücreti karsiligi bir kisiye has bir adet FitSkor® olusturur ve isbu sözlesme kapsaminda bu hizmeti kullanan Danisanlara üyelik tarihinden itibaren bir yil boyunca Fitness ile ilgili gelisim kayitlarinin dönemsel takibi hizmetini promosyon olarak bedelsiz sunar."
        },
        {
          Id: 583249,
          PropertyName: "value",
          Value:
            "7.6. ScoreSoft Kullanicisi, Sirket'in uygulama fiyatini ve uygulama içi fiyatlari, kampanya ve paketleri her zaman tek tarafli olarak degistirebilecegini, yapilan degisiklikler için güncelleme ve uygulama bilgilerini düzenli olarak kontrol edecegini kabul ve beyan eder. Egitmen pesin ödemeli lisans alimlarinda; iptal gerçeklestirilmeyecegini ve para iadesi yapilmayacagini kabul eder."
        },
        {
          Id: 883824,
          PropertyName: "value",
          Value:
            "7.7. ScoreSoft Kullanicisi, Sirket tarafindan www.memap.com.tr internet adresinde belirlenmis olan hizmet bedellerinin üzerinde bir fiyat ile Danisanlara isbu sözlesmeye konu hizmetleri temin edemeyecegini, hizmet bedelleri konusunda www.memap.com.tr  internet sitesinde belirlenmis fiyatlara riayet edecegini kabul eder."
        },
        {
          Id: 478937,
          PropertyName: "value",
          Value:
            "7.8. MeMap Egitmenleri tarafindan www.memap.com.tr internet adresinde belirlenmis olan hizmet bedellerinin altinda bir fiyat ile Danisanlara isbu sözlesmeye konu hizmetlerin temin edilmesinin talep edilmesi halinde, öncelikle ilgili lisans türü, hizmet kapsami ve hizmet bedeli hakkinda Sirket yazili olarak bilgilendirecektir. Sirket’in ilgili lisans türü, hizmet kapsami ve hizmet bedeline yazili sekilde onay vermesi halinde MeMap Egitmeni belirlemis oldugu fiyati kendi bölgesi içerisinde uygulamaya geçirebilecektir."
        },
        {
          Id: 205670,
          PropertyName: "value",
          Value:
            "7.9. FullCard, ScoreCard ve LicenceCard ürünleri araciligiyla elde edilen gelirler, isbu sözlesmenin 7.2. maddesinde belirtilen oranlara göre Lisans Saglayicilari arasinda paylastirilir. Bu dogrultuda, Danisanin tercihine göre Fitskor Lisansi ile MeMap Hizmetlerine (FullCard, ScoreCard, LicenceCard) dair satislar Lisans Saglayicilari araciligiyla gerçeklestirilebilir. Ilgili ürünlere dair satislarin dogrudan Merkez araciligi ile gerçeklestirilmesi ve/veya Danisanin seçtigi hizmetin Merkez tarafindan temin edilmesi halinde, MeMap Egitmeni herhangi bir ücrete hak kazanmaz. Bununla birlikte, söz konusu satislarin MeMap Platformu araciligiyla, dogrudan MeMap Egitmeni tarafindan gerçeklestirilmesi halinde isbu sözlesmenin 7.10. maddesinde belirtilen komisyon orani uygulanmak suretiyle MeMap Egitmeni ücrete hak kazanir."
        },
        {
          Id: 402837,
          PropertyName: "value",
          Value:
            "7.10. MeMap Egitmenleri, isbu sözlesme kapsaminda yer alan hizmetleri, sözlesmeye uygun sekilde Danisanlara sunmayi kabul, beyan ve taahhüt eder. Sirket, sözlesme kapsamindaki FitSkor Lisansi ve MeMap Hizmetlerini kendisine ait Memap Platformu araciligiyla da satabilir. Sirket araciligiyla Egitmene yönlendirilmis ve bu sayede verilmis hizmet ücretlerinden Sirket, %25 komisyon aldiktan sonra geri kalan meblagi Lisans Saglayicisina aktarir."
        },
        {
          Id: 922647,
          PropertyName: "value",
          Value:
            "7.11. Isbu sözlesmenin Sirket tarafindan feshedilmesi halinde ScoreSoft Kullanicilarinin ödedigi lisans bedelleri için iadeyi talep hakkindan feragat ettigini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 978171,
          PropertyName: "value",
          Value:
            "7.12. MeMap Egitmeninin MeMap Platformu’nun “Egitmen” bölümünde yer alabilmesi için ScoreSoft Lisansini kendisi satin almalidir. Merkez tarafindan satin alinmis ScoresSoft lisansi ile MeMap Egitmeni Merkezin altinda hizmet veren Egitmenler bölümünde yer alir."
        },
        { Id: 854607, PropertyName: "value", Value: "MADDE 8: DEVIR YASAKLARI" },
        {
          Id: 170136,
          PropertyName: "value",
          Value:
            "8.1. ScoreSoft Kullanicisi isbu Sözlesme altindaki yükümlülüklerini bizzat ifa etmekle yükümlüdür. ScoreSoft Kullanicisinin isbu Sözlesmeyi veya Sözlesmeden dogan haklarini hiçbir sekilde kismen veya tamamen devir hakki bulunmamakta olup isbu madde Sözlesmenin esasli unsurlarindan birisidir. ScoreSoft Kullanicisinin kullanimina tahsis edilen ScoreSoft Yazilimi sadece kendisine tahsis edilmistir. Geçici surette de olsa yerine geçmek üzere farkli bir ScoreSoft Kullanicisini görevlendiremez. ScoreSoft Kullanicisi, kullanimina tahsis edilen ScoreSoft Yazilimini hiçbir sekilde devredemez, alt kiralama konusu edemez, kismen veya tamamen baskasina kullandiramaz, ciro edemez ve/veya benzeri anlama gelebilecek böyle bir tesebbüste bulunamaz."
        },
        {
          Id: 206806,
          PropertyName: "value",
          Value:
            "8.2. MEMAP, ScoreSoft Kullanicisinin onay veya iznine tabi olmaksizin diledigi zaman isbu Sözlesmeyi tamamen veya kismen devredebilecegi gibi Sözlesmeden dogan hak ve borçlarini da kismen ya da tamamen bir baskasina devir ve temlik edebilir."
        },
        {
          Id: 846674,
          PropertyName: "value",
          Value: "MADDE 9: SCORESOFT KULLANICISININ AKDE AYKIRILIK TESKIL EDEN EYLEMLERI"
        },
        { Id: 118849, PropertyName: "value", Value: "Egitmenin;" },
        {
          Id: 875587,
          PropertyName: "value",
          Value:
            "9.1. MeMap standartlarindaki ölçüm protokollerini dogru uygulamamak, kullanilacak ekipmanlarin dogru kullanilmamasi, MEMAP tarafindan öngörülen standartlara uygun bir sekilde davranilmamasi,"
        },
        {
          Id: 426383,
          PropertyName: "value",
          Value: "9.2. MeMap’e ödenmesi gereken ücretleri, bu ödemelerin muaccel oldugu tarihte ödememesi,"
        },
        {
          Id: 891261,
          PropertyName: "value",
          Value: "9.3. ScoreSoft Kullanicisinin isbu Sözlesme hükümlerine aykiri olarak devirler gerçeklestirmesi,"
        },
        {
          Id: 508373,
          PropertyName: "value",
          Value:
            "9.4. Sözlesmeye aykiri olarak MeMap Platformu ve/veya ScoreSoft Yazilimini çogaltmasi veya taklit etmesi, Markalarin tüketicide iltibas yaratacak (ihtimali de dahil olmak üzere) sekilde birebir aynisini veya ayirt edilemeyecek kadar benzerini veya benzerini kullanmasi,"
        },
        { Id: 916144, PropertyName: "value", Value: "9.5. Gizlilik yükümlülügünü yerine getirmemesi," },
        {
          Id: 419949,
          PropertyName: "value",
          Value:
            "9.6. Hizmet verdigi Merkez tarafindan kullanimina tahsisli alanlar ve Egitmenlik statüsü için gerekli izin veya ruhsati ilgili resmi mercilerden almamasi,"
        },
        {
          Id: 813341,
          PropertyName: "value",
          Value: "9.7. Ekipman güvenligi ile ilgili tedbirlerin alinmasi yükümlülüklerini yerine getirmemesi,"
        },
        {
          Id: 491626,
          PropertyName: "value",
          Value:
            "9.8. Is mevzuati ve MEMAP tarafindan belirlenmis talimatlara, isletme standartlara ve/veya prosedür ve politikalara uymamasi,"
        },
        {
          Id: 330066,
          PropertyName: "value",
          Value: "9.9. MeMap platformuna üye olurken baskasina ait kisisel verileri kullanmasi,"
        },
        {
          Id: 663547,
          PropertyName: "value",
          Value:
            "ve isbu maddede yer alan haller sinirli olmayip, ScoreSoft Kullanicisinin Sözlesmenin diger hükümlerine aykiri davranislari da sözlesmenin ihlali teskil eder."
        },
        { Id: 133108, PropertyName: "value", Value: "MADDE 10: SORUMSUZLUK" },
        {
          Id: 352006,
          PropertyName: "value",
          Value:
            "10.1. ScoreSoft Yazilimi ve Internet Sitesi içindeki her türlü bilgi, egzersiz ve içerik Danisanin ortalama olarak herhangi bir tibbi veya psikolojik rahatsizligi olmadigi varsayilarak tavsiye niteliginde hazirlanmistir. Danisan, bu durumu bilerek ScoreSoft Yazilimi’ni kullanmakla ve kullanim öncesi gerek görüyorsa doktorunun onayini almakla yükümlüdür. Sirket, Danisanin tibbi veya psikolojik rahatsizligi nedeni ile ortaya çikacak herhangi bir sonuçtan sorumlu tutulamaz."
        },
        {
          Id: 397301,
          PropertyName: "value",
          Value:
            "10.2 Danisan, ScoreSoft Yazilimi ve Internet sitesi içindeki her türlü bilgi, egzersiz ve içerigin tavsiye niteliginde oldugunu ve hiçbir hastalik veya saglik durumunun teshis ve tedavisini saglamadigini, bu içerikleri kullanip kullanmamasi konusundaki bütün takdir ve sorumlulugun kendisinde oldugunu kabul, beyan ve taahhüt eder. Sirket, Danisan’in seçerek uyguladigi programla ilgili olarak sorumlu tutulamaz. Bu dogrultuda, Danisan’in kisisel verilerini tam ve dogru olarak bildirildigi faraziyesiyle hazirlanan ve ScoreSoft Yazilimi tarafindan sunulan içerik ve egzersiz programlarinin bir uzmana danisilmasi ya da uzman esliginde uygulanmasi gerektiginin kendisine bildirilmis oldugunu kabul, taahhüt ve beyan eder. ScoreSoft Yazilimi, hastalik veya saglik durumunun teshisi veya tedavisini saglamamaktadir. Danisan, bildirmedigi veya eksik ya da yanlis bildirdigi verilerinden kaynakli rahatsizlik, sakatlik, zafiyet ve benzeri sebeplerden dolayi meydana gelebilecek dogrudan ya da dolayli zararlardan Sirketin sorumlu olmadigini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 597309,
          PropertyName: "value",
          Value:
            "10.3. Sirket, Internet Sitesi’ne kaydolan Danisana verdigi bilgilerle ve önerilerle hedefledigi kilo ve vücut ölçülerine ulasma ya da bunlarin Danisanin sahip oldugu çevre ve saglik kosullarina uygunluguna veya sonuçlarinin basarili olacagina dair garanti vermez. Aksi iddia edilemeyecegi gibi, bu hususta Danisan maddi ve manevi herhangi bir zarar talebinde bulunmayacagini gayri kabili rücu kabul, beyan ve taahhüt eder."
        },
        { Id: 83422, PropertyName: "value", Value: "MADDE 11: GIZLILIK VE KISISEL VERILERIN KORUNMASI" },
        { Id: 63924, PropertyName: "value", Value: "11.1. Gizlilik" },
        {
          Id: 690615,
          PropertyName: "value",
          Value: "11.1.1. Isbu Sözlesme yürürlükte oldugu sürece ve sona erdikten sonra ScoreSoft Kullanicisi;"
        },
        {
          Id: 229514,
          PropertyName: "value",
          Value:
            "- Sirket’e ait üretim, isletme, pazarlama ve yazilim sistemi vb. ticari süreçler ile ilgili vakif oldugu bilgileri, egitim kitapçiklari, prosedür ve politikalar ile isletme standartlarini, 3. kisilere aktarmamak; Ticari sir kapsamina giren bilgilerden bir bölümünü veya tamamini kopyalayip çogaltmamak ve"
        },
        {
          Id: 212272,
          PropertyName: "value",
          Value:
            "- Ticari Sirlarla ilgili tüm dokümantasyon ve bilgilerin 3. kisilerin ulasamayacagi sekilde güvenli bir ortamda muhafaza edilmesini"
        },
        { Id: 540685, PropertyName: "value", Value: "saglamakla yükümlüdür." },
        {
          Id: 544610,
          PropertyName: "value",
          Value:
            "11.1.2. Taraflar isbu Sözlesme sona erse dahi, birbirlerinin finansal, ticari, teknolojik vb. bilgi ve sirlarini saklamayi, herhangi bir sekilde elde edecekleri birbirlerine ait dolayli veya dolaysiz gizli bilgiyi kismen veya tamamen üçüncü sahislara açiklamayacaklarini, bunlara iliskin belge ve dokümanlari üçüncü sahislara vermeyeceklerini, tüm gizli bilgilerin kopyalarini, nüshalarini veya sair araçlarini diger taraf istedigi takdirde geri vereceklerini veya bunlari yok edeceklerini kabul ve taahhüt ederler. Taraflar gizli bilgilerin kendi çalisanlari ya da üçüncü sahislar tarafindan bu Sözlesme’nin hükümlerine aykiri sekilde ifsa edilmemesini ya da dagitilmamasini temin etmek için kendilerinden beklenen tüm önlemleri almayi kabul ve taahhüt ederler."
        },
        {
          Id: 190019,
          PropertyName: "value",
          Value:
            "11.1.3. Taraflar birbirlerinin çalisanlarina, müsterilerine ve muhatap olduklari diger kategorideki ilgili kisilere dair kisisel verileri yasal isleme sartlari ve kurallari dogrultusunda kullanacaklarini, bunlarin gizliligine dair her tür idari ve teknik tedbiri alacaklarini taahhüt ederler."
        },
        { Id: 242652, PropertyName: "value", Value: "11.2. Kisisel Verilerin Korunmasi" },
        {
          Id: 120134,
          PropertyName: "value",
          Value:
            "11.2.1. Taraflar, isbu sözlesmenin akdi yahut ifasi kapsaminda, yazili, sözlü, fiziki, elektronik veyahut sistem erisimi saglamak suretiyle olarak paylasmis olduklari her türlü kisisel verinin, KVKK’ya, ikincil mevzuatina, Kurul düzenlemeleri ile kararlarina ve sair mevzuata uygun sekilde elde edildigini, islendigini ve aktarildigini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 688138,
          PropertyName: "value",
          Value:
            "11.2.2. Taraflar, taraflar arasindaki hizmet tedarikine iliskin tüm ticari iliskilere yönelik yürürlükteki sözlesme veya sözlesmelerin kapsamindaki süreçler sirasinda, ilgili sözlesme veya sözlesmelerin ifasi için zorunlu olan ve taraflarin birbirlerine kisisel veri aktarimi gerçeklestirdigi hallerde, kisisel verilerin hukuka aykiri olarak islenmesini önlemek, kisisel verilere hukuka aykiri olarak erisilmesini önlemek ve kisisel verilerin muhafazasini saglamak amaciyla kisisel verinin niteligine göre uygun güvenlik düzeyini temin etmeye yönelik gerekli her türlü teknik ve idari tedbirleri almakla yükümlüdür."
        },
        {
          Id: 938990,
          PropertyName: "value",
          Value:
            "11.2.3. Taraflar, isbu Sözlesme kapsaminda kendilerine iletilen her türlü kisisel veriyi KVKK ve sair mevzuat kapsaminda tamamen gizli tutacaklarini, kisisel verileri yalnizca isbu sözlesme konusu hizmetlerin yerine getirilebilmesi bakimindan zorunlu oldugu kadariyla isleyeceklerini kabul, beyan ve taahhüt eder. Veri Sorumlusu olan taraf; Veri Isleyen tarafa hizmet ile sinirli, baglantili ve ölçülü olmayan kisisel verileri aktarmayacagini; aksi halde sorumlulugun kendisine ait oldugunu pesinen kabul, beyan ve taahhüt eder."
        },
        {
          Id: 6493,
          PropertyName: "value",
          Value:
            "11.2.4. ScoreSoft Kullanicisi, sözlesmenin geçerli oldugu süre boyunca Danisaninin kisisel verilerine isbu Sözlesme’nin ifasi amaciyla sinirli olarak erisebilir. Danisanin, ScoreSoft Kullanicisi ile paylastigi kisisel verilerin temin edilmesi suretiyle hazirlanacak tavsiye niteligindeki programin, ScoreSoft Kullanicisi tarafindan isbu sözlesmenin amacina aykiri sekilde ve/veya amaci asacak sekilde uygulanmasindan kaynaklanacak tüm ihtilaflarda Sirket’e karsi herhangi bir sorumluluk yükletilemez. ScoreSoft Kullanicisi isbu durumu kabul, taahhüt ve beyan eder."
        },
        { Id: 649857, PropertyName: "value", Value: "11.3. Aydinlatma Yükümlülügü" },
        {
          Id: 692404,
          PropertyName: "value",
          Value:
            "Sözlesme taraflari, birbirlerinin hissedarlarinin, ortaklarinin, yetkililerinin ve çalisanlarinin (hepsi birlikte “taraf temsilcisi” olarak anilir) verilerini faaliyetlerin mevzuata uygun yürütülmesi ve sözlesmenin kurulusu ve dogrudan ifasi amaçlari ve sartlariyla sinirli olarak isler. Her taraf süreç boyunca kullanilacak ve aktarilacak kisisel verilerle ilgili olarak kendi taraf temsilcisini diger taraf adina aydinlatmakla ve bu islemi tevsik etmekle yükümlüdür. Bu dogrultuda, isbu sözlesmenin ifasina dair süreçler sirasinda, Veri Sorumlusu taraf, kisisel verileri diger taraf ile paylastigi hallerde, ilgili kisisel verilerin diger tarafa aktarilmasina ve diger tarafça islenmesine iliskin olarak, ilgili kisilere (veri sahiplerine) yönelik KVKK ve yürürlükte bulunan her türlü sair mevzuat kapsaminda aydinlatma yükümlülügünü yerine getirdigini/getirecegini ve ilgili kisilerin (veri sahiplerinin) açik rizalarinin alinmasinin gerektigi hallerde, ilgili kisilerin (veri sahiplerinin) bahsi geçen mevzuata uygun içerik ve formatta rizalarini aldigini/alacagini kabul, beyan ve taahhüt eder."
        },
        { Id: 135907, PropertyName: "value", Value: "11.4. Kisisel Verileri Isleme ve Aktarma Kurallari" },
        {
          Id: 873917,
          PropertyName: "value",
          Value:
            "Taraflar sözlesmenin ifasi için gerekli olan asgari kategorideki kisisel verileri güvenli yöntemlerle birbirlerine aktarirlar. Bu veriler, sözlesmenin ifasi disinda yalnizca taraf temsilcisinin ayrica aydinlatilmasi halinde ve mevzuatla öngörülen hukuki sebepler dogrultusunda kullanilir. Taraflar bu verileri güvenli ortamlarda saklamak ve sözlesmenin sona ermesinden itibaren saklama ve imha politikalarina uygun olarak ve en geç on yil içerisinde imha etmekle yükümlüdürler. Aksi durumlarda her bir taraf kendi hukuka aykiri eyleminden sorumludur."
        },
        { Id: 753205, PropertyName: "value", Value: "MADDE 12: SÜRÜM/VERSIYON DEGISIMI VE BAKIM" },
        {
          Id: 961692,
          PropertyName: "value",
          Value:
            'Sirket önceden haber vermeksizin sistemde ve/veya yazili dokümanlarinda degisiklikler yapma, sistem ürünlerinin yeni versiyon ve/veya sürümlerini çikarma hakkina sahiptir, Danisan bu yeni sistemi ve/veya yeni yazili dokümanlari ancak "güncelleme” bedelini ödeyerek güncel sistemi kullanabilir. Sirket isterse güncellemeleri belli sürelerle ücretsiz sekilde  yapabilir.'
        },
        { Id: 456884, PropertyName: "value", Value: "MADDE 13: FIKRI MÜLKIYET HAKLARININ KULLANIMI" },
        {
          Id: 9426,
          PropertyName: "value",
          Value:
            "13.1. ScoreSoft Yazilimi ve Internet Sitesi’nin bütün fikri ve sinai mülkiyet haklari Sirket’e aittir. Yazilimin yapisi, düzeni, tasarimi, görselleri, içinde yer alan her türlü egzersiz, içerik ve bilgi ile yazilimin kaynak kodlari Sirket’e ait fikri ve sinai mülkiyet varliklarini olusturur. Tüm bu varliklar, 6769 sayili Sinai Mülkiyet Kanunu (SMK) ve 5846 sayili Fikir ve Sanat Eserleri Kanunu’na göre korunmaktadir. Isbu Sözlesme, yazilimin ve/veya içerigin herhangi bir fikri mülkiyet hakkini ScoreSoft Kullanicisi ve/veya Danisan’a vermemekte ve buna iliskin tüm haklar Sirket tarafindan sakli tutulmaktadir. Bu dogrultuda, Sirket, 6769 sayili Sinai Mülkiyet Kanunu’na (SMK) göre ortaya çikabilecek marka, patent, faydali model, ticari sir, süreç, üretim yöntemi, fikir, bulus, taslak, tasarim, model arastirma sonucu, test sonucu, üretim bilgisi, performans bilgisi, plan, metot, spesifikasyon gibi SMK kapsamindaki tüm sinai ürünler (kisaca “Sinai Ürünler”) ve 5846 sayili Fikir ve Sanat Eserleri Kanunu’na göre ortaya çikabilecek telif hukukunun kapsamina giren tüm fikri ürünler (kisaca “Fikri Ürünler”) ile bunlarin mali haklarinin ve diger yan haklarinin tamami üzerinde münhasir ve süresiz olarak tek hak sahibidir.Kullanim lisans hakki disinda kalan hukuka aykiri kopya eylemi ve islemler eylemin nevine göre cezai yaptirima sahiptir."
        },
        {
          Id: 193018,
          PropertyName: "value",
          Value:
            "13.2. Sirket, “MeMap” markasi ve yazilim üzerindeki Know–How kullanim hakki sahibi oldugunu garanti eder. ScoreSoft Kullanicisi, MeMap Markasi ve Know-How üzerinde Sirket’in münhasir mülkiyeti ve her türlü fikri ve sinai mülkiyet haklari, lisans, kullanim, kopyalama, çogaltma, isleme haklarini haiz oldugunu kabul, beyan ve taahhüt eder."
        },
        {
          Id: 356246,
          PropertyName: "value",
          Value:
            "13.3. Danisan ve ScoreSoft Kullanicisi Türkiye’de ve Türkiye disinda Markalarin aynisinin, herhangi bir sekilde degisiklik yapilmis halinin ve/veya Marka ile iltibas yaratacak benzer markalarin ve/veya en genis sekilde fikri ve sinai mülkiyet haklarinin tümünün veya bir kisminin kendi adina tescili için basvurmayagini, MeMap Markasi’ni hiçbir zaman isbu Sözlesmede belirtilen amaçlar disinda kullanmayacagini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 611137,
          PropertyName: "value",
          Value:
            "13.4. ScoreSoft Kullanicisi, MeMap Markasi’ni, isbu Sözlesme kapsami disinda bulunan faaliyet alanlari, reklamlarda, internet sitesinde kullanmayacagini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 112301,
          PropertyName: "value",
          Value:
            "13.5. ScoreSoft Kullanicisi, “MeMap” Markasi’ni aynen kullanmakla yükümlü olup, ekleme ya da degisiklik yaparak dahi olsa kendi ya da baskasi adi altinda tescil edemez ve herhangi bir gerçek veya tüzel kisiye alt lisanslama vb. yollarla kullandiramaz."
        },
        { Id: 329861, PropertyName: "value", Value: "MADDE 14: MUHTELIF HÜKÜMLER" },
        { Id: 439519, PropertyName: "value", Value: "14.1. FERAGAT OLMAMASI" },
        {
          Id: 204198,
          PropertyName: "value",
          Value:
            "Isbu Sözlesme ile taninan haklardan feragat, ilgili Tarafça yazili olarak yapilmadigi ve imzalanmadigi sürece baglayici olmayacaktir. Herhangi bir Tarafin isbu Sözlesmedeki herhangi bir hakkini, yetkisini veya imtiyazini kullanmamasi veya kullanmakta gecikmesi, söz konusu haktan, yetkiden veya imtiyazdan feragat olarak kabul edilmeyecektir veya tek basina veya kismen kullanim, söz konusu hakkin, yetkisini veya imtiyazin baskaca kullanimini veya gelecekte kullanimini veya isbu Sözlesmedeki herhangi bir diger hakkin, yetkinin veya imtiyazin kullanilmasini engellemeyecektir."
        },
        { Id: 559256, PropertyName: "value", Value: "14.2. BÖLÜNEBILIRLIK" },
        {
          Id: 720656,
          PropertyName: "value",
          Value:
            "Isbu Sözlesmenin, yetkili bir mahkeme tarafindan yasadisi, geçersiz veya icra edilemez olduguna karar verilen bir hükmü veya sarti, isbu Sözlesmenin diger hüküm ve sartlarinin geçerliligini etkilemeyecektir. Taraflar söz konusu yasadisi, geçersiz veya icra edilemez hüküm veya sarti, niyetlerini mümkün olan en yakin sekilde yansitan bir hüküm ile degistirmeyi kabul ederler."
        },
        { Id: 871832, PropertyName: "value", Value: "14.3. DEGISIKLIKLER" },
        {
          Id: 777464,
          PropertyName: "value",
          Value:
            "Isbu Sözlesmenin hükümlerinden herhangi birinde yapilacak degisiklik, tadilat veya ekleme, Taraflarca yazili olarak yapilmadigi ve imzalanmadigi sürece baglayici olmayacaktir."
        },
        { Id: 663719, PropertyName: "value", Value: "14.4 DEVIR ve TEMLIK" },
        {
          Id: 76395,
          PropertyName: "value",
          Value:
            "Taraflar isbu Sözlesmeden dogan hak ve yükümlülüklerini diger tarafin açik ve yazili izni olmaksizin gerçek ve/veya tüzel üçüncü kisilere/kurumlara devredemez. Aksi durumda yapilan devir veya temlik taraflar açisindan geçerli kabul edilmeyecektir."
        },
        { Id: 354693, PropertyName: "value", Value: "14.5 MUCBIR SEBEP" },
        {
          Id: 229017,
          PropertyName: "value",
          Value:
            "Isbu sözlesme, 6098 sayili Türk Borçlar Kanunu’nda belirtilen ve taraflarin kendi iradeleri disindaki veya kendilerine isnat olunamayacak sebeplerle (deprem, sel, olaganüstü hal, terör, savas, Dünya Saglik Örgütü tarafinda küresel ölçekte salgin hastalik-pandemi ilan edilmesi ve bu ilana bagli olarak devlet makamlari tarafindan ülkesel salgin hastalik hali veya devletin resmi kurumlarinca ya da Istanbul Ticaret Odasi tarafindan ekonomik kriz olarak kabul edilen ekonomik dalgalanmalar, grev, lokavt, vb.) borçlarin ifasi imkânsiz hale gelirse ve mücbir sebep durumu üç (3) ayi asarsa taraflarin, o güne kadarki yükümlülüklerini karsilikli olarak yerine getirmesi sartiyla, Sözlesmeyi tazminatsiz olarak feshetme hakki dogar."
        },
        { Id: 828025, PropertyName: "value", Value: "MADDE 15. TEBLIG ADRESI" },
        {
          Id: 190762,
          PropertyName: "value",
          Value:
            "Taraflar, isbu sözlesmeden dogan uyusmazliklarda ve diger hususlarda Isbu Sözlesme’nin 1. Maddesinde belirtilen adreslerine yapilacak tebligatlarin kanuni ikametgahlarina yapilmis sayilacagini, adres degisikliklerini degisiklikten itibaren 2 (iki) gün içinde diger tarafa yazili olarak bildirmedikleri takdirde bu hususun geçerli olmaya devam edecegini kabul ve beyan ederler. Isbu Sözlesme çerçevesinde temerrüde iliskin ihbarlar, fesih ihbarlari ve bu konulara cevaben yapilacak ihbar ve ihtarlar noter kanali ile yapilmadikça baglayici olmaz."
        },
        { Id: 161099, PropertyName: "value", Value: "MADDE 16. SÖZLESMENIN SONA ERMESI" },
        {
          Id: 918300,
          PropertyName: "value",
          Value:
            "16.1. Isbu Sözlesme ile Sirket’in tek tarafli fesih yetkisi taninmasi hususunda Taraflar mutabik kalmis olup, Sirket herhangi bir gerekçe göstermeksizin Sözlesmeyi tek tarafli olarak fesih yetkisini haizdir."
        },
        {
          Id: 259657,
          PropertyName: "value",
          Value:
            "16.2. Sirket’in fazlaya iliskin haklari sakli kalmak kaydiyla, ScoreSoft Kullanicisinin Memap Yazilim ve/veya lisans bedelinin ödememesi veya eksik ödemesi, Sirket’in telif haklarini ihlal etmesi halinde isbu sözlesmesi baska bir hükme, ihtara, ihbara gerek olmaksizin bozucu sartla kendiliginden sona erecektir."
        },
        {
          Id: 802121,
          PropertyName: "value",
          Value:
            "16.3. ScoreSoft Kullanicisi, bu Sözlesmeden dogan yükümlülüklerinden herhangi birini, her nasil olursa olsun yerine getiremez ise, MEMAP geçici veya süresiz MeMap platformunu ve ScoreSoft Yazilimini kullanimina kapatabilir."
        },
        {
          Id: 489041,
          PropertyName: "value",
          Value: "16.4. Bu Sözlesmenin sona ermesi veya erdirilmesi durumunda ScoreSoft Kullanicisi;"
        },
        {
          Id: 828143,
          PropertyName: "value",
          Value: "16.4.1. Sözlesme uygun sekilde ödemekle yükümlü oldugu bedelleri derhal ödemek,"
        },
        {
          Id: 811253,
          PropertyName: "value",
          Value: "16.4.2. Sirket tarafindan temin edilen tüm malzeme, ekipman Sirket’e iade etmek,"
        },
        {
          Id: 29358,
          PropertyName: "value",
          Value:
            "16.4.3. Her türlü ürün, yazilim, fiziki ve elektronik ortamda tutulan belge ve Sirket’e ait Know-How içeren materyali teslim etmek,"
        },
        { Id: 977931, PropertyName: "value", Value: "16.4.5. Bu sözlesme ve eklerine uygun davranmak," },
        {
          Id: 228284,
          PropertyName: "value",
          Value:
            "16.4.6. Sözlesmenin sona ermesinden sonra, Sirket ile rekabet yasagi uyarinca hareket etmek, Sirket’e ait tasarim, konsept ve içerigin aynisini veya benzerini kullanmayacagini kabul,beyan ve taahhüt eder."
        },
        {
          Id: 39999,
          PropertyName: "value",
          Value:
            "16.5. ScoreSoft Kullanicisi, sözlesmeyi 1(bir) ay önceden yazili bildirimde bulunmak sartiyla Sözlesmeyi tek tarafli olarak fesih yetkisini haizdir."
        },
        { Id: 764133, PropertyName: "value", Value: "MADDE 17: SÖZLESME SAKLAMA SÜRESI" },
        {
          Id: 793728,
          PropertyName: "value",
          Value:
            "Isbu sözlesme 6698 sayili Kisisel Verilerin Korunmasi Kanunu, 6502 sayili Tüketicilerin Korunmasi Hakkindaki Kanun ve Mesafeli Sözlesmeler Yönetmeligi ile diger sair ilgili mevzuat hükümleri dikkate alinarak Sirket tarafindan elektronik ortamda 3 yil süre ile saklanacaktir, ScoreSoft Kullanicisi isbu süre boyunca, elektronik ortamda sözlesmeye erisecektir."
        },
        { Id: 887198, PropertyName: "value", Value: "MADDE 18: SON HÜKÜMLER" },
        {
          Id: 689262,
          PropertyName: "value",
          Value:
            "Sirket’in, www.memap.com.tr internet Sitesinde ödeme sayfasinda yer alan ön bilgilendirme formu ve satisa iliskin fatura isbu Sözlesmenin ayrilmaz parçalaridir."
        },
        { Id: 624845, PropertyName: "value", Value: "MADDE 19: BILDIRIMLER ve DELIL SÖZLESMESI" },
        {
          Id: 623561,
          PropertyName: "value",
          Value:
            "Isbu Sözlesme uyarinca Taraflar arasinda yapilacak her türlü yazisma, mevzuatta sayilan zorunlu haller disinda, e-mail araciligiyla yapilacaktir. ScoreSoft Kullanicisi, isbu Sözlesme’ den dogabilecek ihtilaflarda Sirket’in resmi defter, ticari kayitlarinin, veri tabaninin, sunucularinda tuttugu elektronik bilgilerin ve bilgisayar kayitlarinin, baglayici, kesin ve münhasir delil teskil edecegini, bu maddenin Hukuk Muhakemeleri Kanunu'nun 193. maddesi anlaminda delil sözlesmesi niteliginde oldugunu kabul, beyan ve taahhüt eder."
        },
        { Id: 655603, PropertyName: "value", Value: "MADDE 20: YETKILI MAHKEME" },
        {
          Id: 11242,
          PropertyName: "value",
          Value:
            "Isbu Sözlesmenin uygulanmasindan dogacak tüm uyusmazliklarda Istanbul (Çaglayan) Mahkemeleri ve Icra Daireleri yetkilidir."
        },
        { Id: 38440, PropertyName: "value", Value: "MADDE 21: SÖZLESMENIN SÜRESI" },
        {
          Id: 180381,
          PropertyName: "value",
          Value:
            "22.1. Sözlesme, Taraflarca karsilikli olarak imzalandigi tarihten itibaren bir (1) yil süre ile yürürlükte kalacaktir."
        },
        {
          Id: 890656,
          PropertyName: "value",
          Value:
            "22.2. Isbu sözlesme süresinin bitimine en geç bir (1) ay kala, sözlesmenin sonlandirilmasi, uzatilmasi veya degisiklik istenmesi yönünde istegi olan Taraf, bu istedigini yazili olarak diger Tarafa bildirecektir.  Bu dogrultuda, sözlesmenin sonlandirilmasi, uzatilmasi veya degistirilmesine yönelik taleplere iliskin alinacak kararlar, bahsi geçen süre içerisinde, Taraflarin müsterek degerlendirmeleri sonucunda ortaklasa alinacaktir. Sözlesme süresinin sona ermesine müteakip Taraflarin, sözlesmenin uzatilmasina karar vermesi halinde münferit bir sözlesme düzenlenecektir."
        },
        { Id: 885076, PropertyName: "value", Value: "MADDE 22: YÜRÜRLÜK MADDESI" },
        {
          Id: 246369,
          PropertyName: "value",
          Value:
            "Isbu Sözlesme, ScoreSoft Kullanici Sözlesmesi’nin ve MeMap KVKK ScoreSoft Kullanici Aydinlatma Metni’nin ayri ayri tiklanmak suretiyle dijital ortamda onaylanmasi ya da isbu Sözlesmeyi fiziki olarak imzalanmasi ile ScoreSoft Kullanici Sözlesmesi’nin 23 ana maddeleri hakkinda bilgilendirildigini, tüm hüküm ve kosullarin yürürlüge girdigini kabul, beyan ve taahhüt eder. "
        },
        { Id: 850089, PropertyName: "value", Value: "Okudum, anladim." },
        { Id: 217376, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      UserSettingsByUserId: [],
      AnyProfileOfEmail: [],
      SaveRecord: false,
      ChangeEnabledOf: "",
      GoBack: "",
      isComp217376Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("egitmensozlesmesi", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EgitmenSozlesmesiPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("egitmensozlesmesi", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("egitmensozlesmesi", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EgitmenSozlesmesiPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      egitmensozlesmesi_295296_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EgitmenSozlesmesiPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      email_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "EgitmenSozlesmesi/EgitmenSozlesmesiPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.UserSettingsByUserId = result?.data.userSettingsByUserId;
    stateVars.AnyProfileOfEmail = result?.data.anyProfileOfEmail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenSozlesmesiPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenSozlesmesiPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].userId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].userId
            : null
        ),
        "Guid"
      ),
      LastChosenProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.AnyProfileOfEmail?.length > 0
            ? stateVars.AnyProfileOfEmail[0].id
            : this.state.AnyProfileOfEmail?.length > 0
            ? this.state.AnyProfileOfEmail[0].id
            : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UserSettingsByUserId?.length > 0
            ? stateVars.UserSettingsByUserId[0].id
            : this.state.UserSettingsByUserId?.length > 0
            ? this.state.UserSettingsByUserId[0].id
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "EgitmenSozlesmesi/EgitmenSozlesmesiPageInit1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_1;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenSozlesmesiPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenSozlesmesiPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenSozlesmesiPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp217376Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EgitmenSozlesmesiComponent_295296_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp217376Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenSozlesmesiComponent_295296_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenSozlesmesiComponent_295296_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmensozlesmesi_295296_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp217376Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EgitmenSozlesmesiComponent_217376_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KvkkOkudum_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenSozlesmesi/EgitmenSozlesmesiComponent_217376_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenSozlesmesiComponent_217376_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenSozlesmesiComponent_217376_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
