import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBasvuruKurumDetay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBasvuruKurumDetay_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  BasvuruKurumById: any[];
  BasvuruKurumById_dummy: any[];
  ServiceAll: any[];
  ServiceAll_dummy: any[];
  MerkezAll: any[];
  MerkezAll_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  BasvuruKurumReddet: number;
  BasvuruKurumReddet_dummy: number;
  MyContext: any[];
  MyContext_dummy: any[];
  BasvuruKurumOnayla: number;
  BasvuruKurumOnayla_dummy: number;
  KUserByEposta: any[];
  KUserByEposta_dummy: any[];
  CreateUser: string;
  CreateUser_dummy: string;
  KisiByEmail: any[];
  KisiByEmail_dummy: any[];
  UserByEmail: any[];
  UserByEmail_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  BaseUrl: any[];
  BaseUrl_dummy: any[];
  TokenOfUser: any[];
  TokenOfUser_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  isComp588758Enabled: "enabled" | "disabled";
  isComp818039Enabled: "enabled" | "disabled";
}

export class BasvuruKurumDetay_ScreenBase extends React.PureComponent<IBasvuruKurumDetay_ScreenProps, any> {
  basvurukurumdetay_321577_value_kuikaDateRef: React.RefObject<any>;
  basvurukurumdetay_376233_value_kuikaMultiSelectRef: React.RefObject<any>;
  basvurukurumdetay_280445_value_kuikaMultiSelectRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Corporate Application", TagName: "lblHead_value" },
        { Id: 106495, PropertyName: "value", Value: "Name", TagName: "Label2_value" },
        { Id: 6002481, PropertyName: "value", Value: "Gender", TagName: "lblCinsiyet_6002481_value" },
        { Id: 7845220, PropertyName: "value", Value: "E-mail", TagName: "lblEposta_7845220_value" },
        { Id: 7097982, PropertyName: "value", Value: "Phone", TagName: "lblTelefon_7097982_value" },
        { Id: 542774, PropertyName: "value", Value: "Company name", TagName: "label1_0_value" },
        { Id: 7125791, PropertyName: "value", Value: "Website adress", TagName: "lblWebAdres_7125791_value" },
        { Id: 3477945, PropertyName: "value", Value: "MeMap Services", TagName: "lblGrupDers_3477945_value" },
        { Id: 1168348, PropertyName: "value", Value: "Location Phone", TagName: "lblMerkezTelefon_1168348_value" },
        { Id: 5986501, PropertyName: "value", Value: "Adress", TagName: "lblAdres_5986501_value" },
        { Id: 1389709, PropertyName: "value", Value: "Service Location", TagName: "lblPopi_1389709_value" },
        {
          Id: 5577885,
          PropertyName: "value",
          Value: "I want to be a part of MeMap Platform",
          TagName: "lblMemapYer_5577885_value"
        },
        {
          Id: 6149798,
          PropertyName: "value",
          Value: "I want to get information about the benefits of the MeMap platform.",
          TagName: "lblMemapFayda_6149798_value"
        },
        { Id: 584431, PropertyName: "label", Value: "Reject", TagName: "Button2_label" },
        { Id: 542282, PropertyName: "label", Value: "Approve", TagName: "Button1_label" },
        { Id: 536265, PropertyName: "value", Value: "License end date", TagName: "Label1_0_value" },
        { Id: 321577, PropertyName: "placeholder", Value: "Choose...", TagName: "dtExpiration_placeholder" },
        { Id: 768566, PropertyName: "value", Value: "Credit to be uploaded", TagName: "Label3_value" },
        { Id: 484841, PropertyName: "placeholder", Value: "15", TagName: "numCredit_placeholder" },
        { Id: 976780, PropertyName: "value", Value: "Services to be provided", TagName: "Label4_value" },
        { Id: 786070, PropertyName: "value", Value: "Fitness Locations", TagName: "Label1_0_value" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "5579b2f2-b948-4f75-8dd1-d60041cc2946",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Başvuru Kurum" },
        { Id: 106495, PropertyName: "value", Value: "Ad Soyad" },
        { Id: 6002481, PropertyName: "value", Value: "Biyolojik Cinsiyet" },
        { Id: 7845220, PropertyName: "value", Value: "E-posta" },
        { Id: 7097982, PropertyName: "value", Value: "Telefon" },
        { Id: 542774, PropertyName: "value", Value: "Kurum Adı" },
        { Id: 7125791, PropertyName: "value", Value: "Web Adresi" },
        { Id: 3477945, PropertyName: "value", Value: "Kurumunuz hangi merkezlerde hizmet sunacak?" },
        { Id: 1168348, PropertyName: "value", Value: "Merkez Telefonu" },
        { Id: 5986501, PropertyName: "value", Value: "Adres" },
        { Id: 1389709, PropertyName: "value", Value: "Hizmet Merkezi" },
        { Id: 5577885, PropertyName: "value", Value: "MeMap Platformunda yer almak istiyorum." },
        { Id: 6149798, PropertyName: "value", Value: "MeMap Platformunun faydaları hakkında bilgi almak istiyorum." },
        { Id: 536265, PropertyName: "value", Value: "Lisans Bitiş Tarihi" },
        { Id: 321577, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 768566, PropertyName: "value", Value: "Yüklenecek Kredi" },
        { Id: 484841, PropertyName: "placeholder", Value: "15" },
        { Id: 976780, PropertyName: "value", Value: "Verilecek Hizmetler" },
        { Id: 376233, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 786070, PropertyName: "value", Value: "Fitness Merkezleri" },
        { Id: 280445, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 584431, PropertyName: "label", Value: "Reddet" },
        { Id: 542282, PropertyName: "label", Value: "Onayla" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.basvurukurumdetay_321577_value_kuikaDateRef = React.createRef();
    this.basvurukurumdetay_376233_value_kuikaMultiSelectRef = React.createRef();
    this.basvurukurumdetay_280445_value_kuikaMultiSelectRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      BasvuruKurumById: [],
      ServiceAll: [],
      MerkezAll: [],
      ChangeEnabledOf: "",
      BasvuruKurumReddet: 0,
      MyContext: [],
      BasvuruKurumOnayla: 0,
      KUserByEposta: [],
      CreateUser: "",
      KisiByEmail: [],
      UserByEmail: [],
      SaveRecord: false,
      BaseUrl: [],
      TokenOfUser: [],
      IfThenElse: false,
      isComp588758Enabled: "enabled",
      isComp818039Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("basvurukurumdetay", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.BasvuruKurumDetayPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("basvurukurumdetay", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("basvurukurumdetay", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BasvuruKurumDetayPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      basvurukurumdetay_2457184_value: this.state.BasvuruKurumById?.at?.(0)?.logo ?? undefined,
      basvurukurumdetay_818039_value: this.state.BasvuruKurumById?.at?.(-1)?.memapPlatformIstek ?? undefined,
      basvurukurumdetay_588758_value: this.state.BasvuruKurumById?.at?.(0)?.memapFayda ?? undefined,
      basvurukurumdetay_484841_value: undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  BasvuruKurumDetayPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BasvuruKurumDetay/BasvuruKurumDetayPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BasvuruKurumById = result?.data.basvuruKurumById;
    formVars.basvurukurumdetay_2457184_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].logo
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].logo
        : null
    );
    formVars.basvurukurumdetay_1185662_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].ad
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].ad
        : null
    );
    formVars.basvurukurumdetay_9976439_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].soyad
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].soyad
        : null
    );
    formVars.basvurukurumdetay_3654551_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].cinsiyet
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].cinsiyet
        : null
    );
    formVars.basvurukurumdetay_5417189_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].email
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].email
        : null
    );
    formVars.basvurukurumdetay_4307517_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].cepTelefonu
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].cepTelefonu
        : null
    );
    formVars.basvurukurumdetay_775924_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].kurumIsim
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].kurumIsim
        : null
    );
    formVars.basvurukurumdetay_7970217_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].kurumWebAdresi
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].kurumWebAdresi
        : null
    );
    formVars.basvurukurumdetay_9928368_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].memapHizmetleri
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].memapHizmetleri
        : null
    );
    formVars.basvurukurumdetay_2570251_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].kurumTelefon
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].kurumTelefon
        : null
    );
    formVars.basvurukurumdetay_3509379_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].kurumAdres
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].kurumAdres
        : null
    );
    formVars.basvurukurumdetay_2432978_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].hizmetSunulanMerkez
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].hizmetSunulanMerkez
        : null
    );
    formVars.basvurukurumdetay_818039_value = ReactSystemFunctions.value(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[stateVars.BasvuruKurumById.length - 1].memapPlatformIstek
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[this.state.BasvuruKurumById.length - 1].memapPlatformIstek
        : null
    );
    formVars.basvurukurumdetay_588758_value = ReactSystemFunctions.value(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].memapFayda
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].memapFayda
        : null
    );
    stateVars.ServiceAll = result?.data.serviceAll;

    formVars.basvurukurumdetay_376233_options = stateVars.ServiceAll;
    stateVars.MerkezAll = result?.data.merkezAll;

    formVars.basvurukurumdetay_280445_options = stateVars.MerkezAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvuruKurumDetayPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvuruKurumDetayPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp588758Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp818039Enabled", "disabled");
    formVars.basvurukurumdetay_2457184_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].logo
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].logo
        : null
    );

    formVars.basvurukurumdetay_1185662_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].ad
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].ad
        : null
    );

    formVars.basvurukurumdetay_9976439_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].soyad
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].soyad
        : null
    );

    formVars.basvurukurumdetay_3654551_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].cinsiyet
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].cinsiyet
        : null
    );

    formVars.basvurukurumdetay_5417189_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].email
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].email
        : null
    );

    formVars.basvurukurumdetay_4307517_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].cepTelefonu
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].cepTelefonu
        : null
    );

    formVars.basvurukurumdetay_775924_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].kurumIsim
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].kurumIsim
        : null
    );

    formVars.basvurukurumdetay_7970217_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].kurumWebAdresi
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].kurumWebAdresi
        : null
    );

    formVars.basvurukurumdetay_9928368_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].memapHizmetleri
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].memapHizmetleri
        : null
    );

    formVars.basvurukurumdetay_2570251_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].kurumTelefon
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].kurumTelefon
        : null
    );

    formVars.basvurukurumdetay_3509379_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].kurumAdres
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].kurumAdres
        : null
    );

    formVars.basvurukurumdetay_2432978_value = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].hizmetSunulanMerkez
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].hizmetSunulanMerkez
        : null
    );

    formVars.basvurukurumdetay_818039_value = ReactSystemFunctions.value(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[stateVars.BasvuruKurumById.length - 1].memapPlatformIstek
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[this.state.BasvuruKurumById.length - 1].memapPlatformIstek
        : null
    );

    formVars.basvurukurumdetay_588758_value = ReactSystemFunctions.value(
      this,
      stateVars.BasvuruKurumById?.length > 0
        ? stateVars.BasvuruKurumById[0].memapFayda
        : this.state.BasvuruKurumById?.length > 0
        ? this.state.BasvuruKurumById[0].memapFayda
        : null
    );

    stateVars.dataSource_376233 = this.state.ServiceAll;
    stateVars.dataSource_376233 = this.state.ServiceAll;

    stateVars.dataSource_280445 = this.state.MerkezAll;
    stateVars.dataSource_280445 = this.state.MerkezAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BasvuruKurumDetayComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BasvuruKurumDetayComponent_584431_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      basvuruKurumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Id ?? this.props.screenInputs.id,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruKurumDetay/BasvuruKurumDetayComponent_584431_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BasvuruKurumReddet = result?.data.basvuruKurumReddet;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvuruKurumDetayComponent_584431_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvuruKurumDetayComponent_584431_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BasvuruKurumDetayComponent_542282_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvurukurumdetay_321577_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "basvurukurumdetay_321577_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "basvurukurumdetay_376233_value", "value", "ServiceAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "basvurukurumdetay_376233_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "basvurukurumdetay_280445_value", "value", "MerkezAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "basvurukurumdetay_280445_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      basvuruMerkezId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Id ?? this.props.screenInputs.id,
        "Guid"
      ),
      licenceExpirationDate_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvurukurumdetay_321577_value", "value", "", "", "")
        ),
        "Date"
      ),
      mulHizmetIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "basvurukurumdetay_376233_value", "value", "ServiceAll", "id", "id")
        ),
        "string"
      ),
      mulMerkezIds_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "basvurukurumdetay_280445_value", "value", "MerkezAll", "id", "id")
        ),
        "string"
      ),
      credit_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvurukurumdetay_484841_value", "value", "", "", "")
        ),
        "number"
      ),
      Email_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BasvuruKurumById?.length > 0
            ? stateVars.BasvuruKurumById[0].email
            : this.state.BasvuruKurumById?.length > 0
            ? this.state.BasvuruKurumById[0].email
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruKurumDetay/BasvuruKurumDetayComponent_542282_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.BasvuruKurumOnayla = result?.data.basvuruKurumOnayla;
    stateVars.KUserByEposta = result?.data.kUserByEposta;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvuruKurumDetayComponent_542282_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvuruKurumDetayComponent_542282_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KUserByEposta?.length > 0
            ? stateVars.KUserByEposta[0].id
            : this.state.KUserByEposta?.length > 0
            ? this.state.KUserByEposta[0].id
            : null
        ),
        null
      )
    ) {
      stateVars.CreateUser = await ReactSystemFunctions.createUser(
        ReactSystemFunctions.toString(
          this,
          stateVars.BasvuruKurumById?.length > 0
            ? stateVars.BasvuruKurumById[0].email
            : this.state.BasvuruKurumById?.length > 0
            ? this.state.BasvuruKurumById[0].email
            : null
        ),
        ReactSystemFunctions.toString(
          this,
          stateVars.BasvuruKurumById?.length > 0
            ? stateVars.BasvuruKurumById[0].ad
            : this.state.BasvuruKurumById?.length > 0
            ? this.state.BasvuruKurumById[0].ad
            : null
        ),
        ReactSystemFunctions.toString(
          this,
          stateVars.BasvuruKurumById?.length > 0
            ? stateVars.BasvuruKurumById[0].soyad
            : this.state.BasvuruKurumById?.length > 0
            ? this.state.BasvuruKurumById[0].soyad
            : null
        ),
        true,
        "",
        ""
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BasvuruKurumDetayComponent_542282_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BasvuruKurumDetayComponent_542282_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BasvuruKurumDetayComponent_542282_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BasvuruKurumById?.length > 0
            ? stateVars.BasvuruKurumById[0].email
            : this.state.BasvuruKurumById?.length > 0
            ? this.state.BasvuruKurumById[0].email
            : null
        ),
        "string"
      ),
      email_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BasvuruKurumById?.length > 0
            ? stateVars.BasvuruKurumById[0].email
            : this.state.BasvuruKurumById?.length > 0
            ? this.state.BasvuruKurumById[0].email
            : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruKurumDetay/BasvuruKurumDetayComponent_542282_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiByEmail = result?.data.kisiByEmail;
    stateVars.UserByEmail = result?.data.userByEmail;
    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.BaseUrl = result?.data.baseUrl;
    stateVars.TokenOfUser = result?.data.tokenOfUser;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvuruKurumDetayComponent_542282_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvuruKurumDetayComponent_542282_onClick_if3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("912b1e84-8a2a-478a-86ce-4cffc534c1aa", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BasvuruKurumById?.length > 0
            ? stateVars.BasvuruKurumById[0].email
            : this.state.BasvuruKurumById?.length > 0
            ? this.state.BasvuruKurumById[0].email
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("MeMap İş Hesabınız açıldı!", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BaseUrl?.length > 0
            ? stateVars.BaseUrl[0].baseUrl
            : this.state.BaseUrl?.length > 0
            ? this.state.BaseUrl[0].baseUrl
            : null
        ),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BasvuruKurumById?.length > 0
            ? stateVars.BasvuruKurumById[0].email
            : this.state.BasvuruKurumById?.length > 0
            ? this.state.BasvuruKurumById[0].email
            : null
        ),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lang
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lang
            : null
        ),
        "string"
      ),
      verificationCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TokenOfUser?.length > 0
            ? stateVars.TokenOfUser[0].token
            : this.state.TokenOfUser?.length > 0
            ? this.state.TokenOfUser[0].token
            : null
        ),
        "string"
      ),
      kurumlogo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BasvuruKurumById?.length > 0
            ? stateVars.BasvuruKurumById[0].kurumImgUrl
            : this.state.BasvuruKurumById?.length > 0
            ? this.state.BasvuruKurumById[0].kurumImgUrl
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruKurumDetay/BasvuruKurumDetayComponent_542282_onClick_if3",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  BasvuruKurumDetayComponent_542282_onClick_else3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("55f69deb-a389-4d15-9c8b-a84d0d8b1e95", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BasvuruKurumById?.length > 0
            ? stateVars.BasvuruKurumById[0].email
            : this.state.BasvuruKurumById?.length > 0
            ? this.state.BasvuruKurumById[0].email
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("MeMap Yeni İş Hesabınız açıldı!", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BaseUrl?.length > 0
            ? stateVars.BaseUrl[0].baseUrl
            : this.state.BaseUrl?.length > 0
            ? this.state.BaseUrl[0].baseUrl
            : null
        ),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BasvuruKurumById?.length > 0
            ? stateVars.BasvuruKurumById[0].email
            : this.state.BasvuruKurumById?.length > 0
            ? this.state.BasvuruKurumById[0].email
            : null
        ),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lang
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lang
            : null
        ),
        "string"
      ),
      kurumlogo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BasvuruKurumById?.length > 0
            ? stateVars.BasvuruKurumById[0].kurumImgUrl
            : this.state.BasvuruKurumById?.length > 0
            ? this.state.BasvuruKurumById[0].kurumImgUrl
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruKurumDetay/BasvuruKurumDetayComponent_542282_onClick_else3",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  BasvuruKurumDetayComponent_542282_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KUserByEposta?.length > 0
            ? stateVars.KUserByEposta[0].id
            : this.state.KUserByEposta?.length > 0
            ? this.state.KUserByEposta[0].id
            : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.BasvuruKurumDetayComponent_542282_onClick_if3();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.BasvuruKurumDetayComponent_542282_onClick_else3();
      if (isErrorOccurred) return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
