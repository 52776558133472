import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDanisanSozlesmesiYeni_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDanisanSozlesmesiYeni_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  UserSettingsByUserId: any[];
  UserSettingsByUserId_dummy: any[];
  AnyProfileOfEmail: any[];
  AnyProfileOfEmail_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  isComp217376Enabled: "enabled" | "disabled";
}

export class DanisanSozlesmesiYeni_ScreenBase extends React.PureComponent<IDanisanSozlesmesiYeni_ScreenProps, any> {
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "dc19e963-52bf-42ae-bd89-4746188fcbf4",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 552925, PropertyName: "value", Value: "MEMAP ÜYELİK SÖZLEŞMESİ" },
        { Id: 136149, PropertyName: "value", Value: "Madde 1: SÖZLEŞMENİN KONUSU:" },
        {
          Id: 971386,
          PropertyName: "value",
          Value:
            "İşbu sözleşmenin konusu, MeMap Platformuna üye olan kişinin genel kullanım koşullarının belirlenmesi, MeMap Platformu aracılığıyla MeMap Hizmet Kredilerinin satın alınması, bu satın alma işlemi sonunda kişinin “Danışan” sıfatını kazanması ve satın alınan MeMap Hizmetlerine dair Tarafların karşılıklı hak ve yükümlülüklerin tespitidir."
        },
        { Id: 417792, PropertyName: "value", Value: "Madde 2. TARAFLAR:" },
        {
          Id: 938513,
          PropertyName: "value",
          Value:
            'İşbu Üyelik Sözleşmesi ("Sözleşme") MeMap Platformuna (www.fitnesscheckup.com) üye olan Fitness Danışanı ("Danışan veya Üye") ile “Çubuklu Mah. Boğaziçi Cad. No:9/1 Beykoz/ İSTANBUL” adresinde mukim SCORESOFT SOLUTIONS A.Ş. (“Şirket") arasında kurulmuş olup, MeMap Platformunun kullanım koşulları ile tarafların hak ve yükümlülüklerini düzenler.'
        },
        { Id: 895103, PropertyName: "value", Value: "Madde 2.1: TARAFLAR İLE İLGİLİ DETAYLAR" },
        { Id: 786724, PropertyName: "value", Value: "FİRMA ADI: SCORESOFT SOLUTIONS TEKNOLOJİ A.Ş." },
        {
          Id: 644795,
          PropertyName: "value",
          Value: "POSTA ADRESİ: Çubuklu Mah. Boğaziçi Cad. No:9/1 K.2 Beykoz/İstanbul"
        },
        { Id: 113168, PropertyName: "value", Value: "TELEFON: 0532 468 89 87" },
        { Id: 852797, PropertyName: "value", Value: "E-POSTA ADRESİ: info@memap.com.tr" },
        { Id: 150350, PropertyName: "value", Value: "VERGİ DAİRESİ: 7571003233" },
        {
          Id: 955516,
          PropertyName: "value",
          Value: 'Yukarıda bilgileri bulunan firma sözleşmede "MeMap" veya "Şirket" olarak anılacaktır.'
        },
        { Id: 668134, PropertyName: "value", Value: "FİRMA ADI: ___________________" },
        { Id: 639548, PropertyName: "value", Value: "POSTA ADRESİ: ________________" },
        { Id: 886456, PropertyName: "value", Value: "TELEFON: ____________________" },
        { Id: 746518, PropertyName: "value", Value: "E-POSTA ADRESİ: _______________" },
        { Id: 652102, PropertyName: "value", Value: "VERGİ DAİRESİ:_________________" },
        {
          Id: 851000,
          PropertyName: "value",
          Value: 'Yukarıda bilgileri bulunan firma sözleşmede "Kurum" olarak anılacaktır.'
        },
        { Id: 517167, PropertyName: "value", Value: "Madde 3: TANIMLAR:" },
        { Id: 495160, PropertyName: "value", Value: "MeMap Internet Sitesi:" },
        { Id: 60155, PropertyName: "value", Value: "www.memap.com.tr URL adresli internet sitesini ifade eder." },
        { Id: 310532, PropertyName: "value", Value: "MeMap Platformu:" },
        {
          Id: 20613,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanıcısının MeMap Hizmet Kredilerinin dağıtılması, MeMap hizmetlerine ilişkin randevuların yönetilmesi ve danişanların FitSkor puanlamasını takip etmesine dair işlemlerin yapıldığı dijital fitness platformudur."
        },
        { Id: 368472, PropertyName: "value", Value: "MeMap Hizmetleri:" },
        {
          Id: 725672,
          PropertyName: "value",
          Value:
            "Detayları memap.com.tr adresinde bulunan ve MeMap Hizmet Sertifikası’na sahip Eğitmenler tarafından Danışan’a verilen hizmetlerdir."
        },
        { Id: 219523, PropertyName: "value", Value: "MeMap Hizmet Sertifikası:" },
        {
          Id: 954823,
          PropertyName: "value",
          Value:
            "MeMap Fitness Akademisi tarafından, ilgili MeMap Hizmetini verebileceğini sınav ile kanıtlamış ScoreSoft Kullanıcısına verilen sertifikadır. ScoreSoft Kullanıcısının sahip olduğu sertifikalar ve bu sertifikalara istinaden verebileceği MeMap Hizmetleri ilgili ScoreSoft Kullanıcısının profil sayfasında yer alır."
        },
        { Id: 909611, PropertyName: "value", Value: "Hizmet Kredisi:" },
        {
          Id: 109905,
          PropertyName: "value",
          Value: "Hizmet Kredisi her MeMap hizmeti için kullanılan hayali bir para birimidir."
        },
        { Id: 512678, PropertyName: "value", Value: "Üye:" },
        { Id: 615110, PropertyName: "value", Value: "MeMap platformuna kaydolan kişiyi ifade eder." },
        { Id: 581538, PropertyName: "value", Value: "Danisan:" },
        { Id: 625933, PropertyName: "value", Value: "MeMap hizmetlerinden faydalanmış MeMap üyesini ifade eder." },
        { Id: 39247, PropertyName: "value", Value: "FitSkor:" },
        {
          Id: 751477,
          PropertyName: "value",
          Value:
            "Kişinin yaş ve cinsiyetine göre amaca uygun fiziksel uygunluk seviyesini sayısal olarak ifade eden puanlama sisteminin sonucudur."
        },
        { Id: 376798, PropertyName: "value", Value: "ScoreSoft Yazılımı:" },
        {
          Id: 289571,
          PropertyName: "value",
          Value:
            "ScoreSoft Lisansı ile kullanılabilen, fitness bileşenlerini geliştirmek için kişilerin fiziksel ve fizyolojik verilerini değerlendirerek kişinin yaşa ve cinsiyete göre fiziksel uygunluk notunu belirleyen ve buna bağlı olarak tavsiye niteliğinde egzersiz stratejisi oluşturan dijital içerikli hizmet sunan bir yazılımdır."
        },
        { Id: 141238, PropertyName: "value", Value: "ScoreSoft Lisansı:" },
        {
          Id: 812790,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanıcıları için, e-posta adresine özel, tek kişilik ScoreSoft Yazılımını kullanma amaçlı yıllık ücretlendirilen dijital hizmettir."
        },
        { Id: 37379, PropertyName: "value", Value: "ScoreSoft Kullanıcısı:" },
        {
          Id: 402883,
          PropertyName: "value",
          Value: "MeMap Hizmet Kredilerini sağlayan, geçerli ScoreSoft Lisansına sahip kullanıcıyı ifade eder."
        },
        { Id: 110863, PropertyName: "value", Value: "MeMap Eğitmeni:" },
        {
          Id: 133149,
          PropertyName: "value",
          Value:
            "MeMap Hizmet Kredilerini sağlayan, kendisi veya merkez tarafından satın alınmış geçerli ScoreSoft Lisansına sahip, MeMap Fitness Akademisi tarafından belirlenen eğitim kriterlerini karşılayan ve MeMap Hizmetlerini verebileceğini sınav ile ispatlamış ancak halka açık MeMap platformunda yer almayan eğitmeni ifade eder. Kısaca “Eğitmen” ‘olarak anılacaktır."
        },
        { Id: 629039, PropertyName: "value", Value: "MeMap Platform Eğitmeni:" },
        {
          Id: 39294,
          PropertyName: "value",
          Value:
            "MeMap Hizmet Kredilerini sağlayan, kendisi tarafından satın alınmış geçerli ScoreSoft Lisansına sahip, MeMap Fitness Akademisi tarafından belirlenen eğitim kriterlerini karşılayan ve MeMap Hizmetlerini verebileceğini sınav ile ispatlayarak halka açık MeMap platformunda yer alan eğitmeni ifade eder. Kısaca “Eğitmen” olarak anılacaktır."
        },
        { Id: 947027, PropertyName: "value", Value: "MeMap Elçisi:" },
        {
          Id: 732090,
          PropertyName: "value",
          Value:
            "MeMap felsefesini, standartlarını ve iş modelini lisans sağlayıcılarına aktaran ve bu sağlayıcılar ile MeMap arasındaki iş ilişkilerini yürüten kişidir."
        },
        { Id: 136232, PropertyName: "value", Value: "Kurum:" },
        {
          Id: 436113,
          PropertyName: "value",
          Value:
            "Geçerli Kurum Lisansına sahip, MeMap Hizmet Kredilerini sağlayan ticari işletmeyi ifade eder. Fitness Merkezlerini, Okulları ve Spor Kulüpleri için kullanılan ortak bir tanımdır."
        },
        { Id: 103963, PropertyName: "value", Value: "Kurum Lisansı:" },
        {
          Id: 738447,
          PropertyName: "value",
          Value:
            "MeMap Hizmet Kredilerinin sağlanması ve buna bağlı olarak MeMap Hizmetlerinin satılabilmesi için MeMap Dijital Platformunu kullanma hakkının temin edilmesi suretiyle yıllık ücretlendirilen dijital hizmettir."
        },
        { Id: 738124, PropertyName: "value", Value: "ScoreSoft Kurumu:" },
        {
          Id: 586117,
          PropertyName: "value",
          Value: "Geçerli Kurum Lisansına sahip, MeMap Hizmet Kredilerini sağlayan ticari işletmeyi ifade eder."
        },
        { Id: 314493, PropertyName: "value", Value: "MeMap Kurumu:" },
        {
          Id: 466031,
          PropertyName: "value",
          Value:
            "Geçerli Kurum Lisansına sahip, MeMap Hizmetlerini sunmak üzere MeMap Kurum standartlarını karşılamış ve bünyesinde minimum bir MeMap Eğitmeni barındıran, ancak halka açık MeMap Platformunda yer almayan ve MeMap MeMap Hizmet Kredilerini sağlayan ticari işletmeyi ifade eder."
        },
        { Id: 905605, PropertyName: "value", Value: "MeMap Platform Kurumu:" },
        {
          Id: 466604,
          PropertyName: "value",
          Value:
            "Geçerli Kurum Lisansına sahip, MeMap Hizmetlerini sunmak üzere MeMap Kurum standartlarını karşılamış ve bünyesinde minimum bir MeMap Eğitmeni barındıran, bununla birlikte halka açık MeMap Platformunda yer alan ve MeMap MeMap Hizmet Kredilerini sağlayan ticari işletmeyi ifade eder."
        },
        { Id: 384576, PropertyName: "value", Value: "MeMap Platform Lisansı:" },
        {
          Id: 5421,
          PropertyName: "value",
          Value:
            "MeMap eğitmeninin ve MeMap Kurumunun halka açık MeMap Platformunda yer alarak tüm MeMap üyelerine hizmet vermesini sağlayan ücretli dijital hizmettir."
        },
        { Id: 629873, PropertyName: "value", Value: "Kurumsal Şirket:" },
        {
          Id: 108866,
          PropertyName: "value",
          Value:
            "Bir ya da birden fazla MeMap Merkezi ile anlasmali MeMap Hizmet Kredilerini sağlayan ticari isletmeyi ifade eder."
        },
        { Id: 219799, PropertyName: "value", Value: "MeMap Fitness Akademisi:" },
        {
          Id: 604677,
          PropertyName: "value",
          Value:
            "www.yeninesilfitnessegitimi.com URL adresli, eğitmenlerin standardize edilmiş MeMap Hizmetlerini verebilmek amaçlı eğitimler alabildikleri çevrimiçi Fitness Eğitim Platformunu ifade eder."
        },
        { Id: 833317, PropertyName: "value", Value: "Egzersiz Reçetelendirme:" },
        {
          Id: 103810,
          PropertyName: "value",
          Value:
            "FitSkor’u hesaplanmış kişilere, haftada egzersize ayrılacak gün ve süresine bağlı, kişiye özel tavsiye niteliğindeki önceliklendirilmiş fitness bileşenlerinin sıralanışını ifade eder."
        },
        { Id: 847408, PropertyName: "value", Value: "Veri:" },
        {
          Id: 274271,
          PropertyName: "value",
          Value:
            "ScoreSoft yazılımının hesaplamalar yapabilmesi için danışan ve/veya eğitmen tarafından sağlanan kişisel verilerdir."
        },
        { Id: 370060, PropertyName: "value", Value: "Know-How:" },
        {
          Id: 82942,
          PropertyName: "value",
          Value:
            "İş bu Sözleşme ile ScoreSoft Kullanıcısının kullanımına tahsis edilen alanlarda bulunduğu şekli ile ScoreSoft Yazılımı, MeMap’in işletme sistemleri ve metotlarını ifade eder."
        },
        {
          Id: 869171,
          PropertyName: "value",
          Value:
            "İşbu sözleşme kapsamında ScoreSoft Kullanıcısı, MeMap Eğitmeni ve MeMap Platform Eğitmeni birlikte kullanıldığında “ScoreSoft Kullanıcısı” olarak anılacaktır."
        },
        {
          Id: 460847,
          PropertyName: "value",
          Value:
            "İşbu sözleşme kapsamında MeMap Eğitmeni ve MeMap Platform Eğitmeni birlikte kullanıldığında “Eğitmen” olarak anılacaktır."
        },
        {
          Id: 613553,
          PropertyName: "value",
          Value:
            "İşbu sözleşme kapsamında Kurum ve MeMap Platform Kurumu birlikte kullanıldığında “Kurum” olarak anılacaktır."
        },
        {
          Id: 137907,
          PropertyName: "value",
          Value:
            "İşbu Sözleşme kapsamında ScoreSoft Kullanıcısı, MeMap Eğitmeni, MeMap Platform Eğitmeni, ScoreSoft Kurumu, MeMap Kurumu, MeMap Platform Kurumu ve Kurumsal Şirket birlikte “MeMap Hizmet Kredi Sağlayıcıları” olarak anılacaktır."
        },
        { Id: 43367, PropertyName: "value", Value: "MADDE 4: MEMAP PLATFORMU HAKKINDA GENEL BİLGİLENDİRME" },
        {
          Id: 438083,
          PropertyName: "value",
          Value:
            "MeMap, fiziksel uygunluk ile ilgili hizmetler sunan dijital fitness platformudur. MeMap Platformu’na üye olan kişiler, MeMap hizmetlerinden yararlanabilmek için MeMap Hizmet Kredisi edinirler. MeMap Hizmet Kredisine sahip üyeler danışan olarak adlandırılırlar. Danışanlar MeMap hizmetlerini, ScoreSoft® yazılımını kullanan eğitmenler tarafından, ilgili eğitmenin bağlı olduğu MeMap akredite kurumlarda alırlar."
        },
        {
          Id: 290563,
          PropertyName: "value",
          Value:
            "MeMap Platformunda yer alan MeMap Platform Eğitmeni, MeMap Fitness Akademisi tarafından belirlenmiş kriterleri karşılayan ve MeMap hizmetlerini verebileceğini sınav ile ispatlamış eğitmenlerdir. MeMap Platformu’nda yer alan kurumlar ise MeMap kurum standartlarını karşılayan ve bünyesinde en az bir MeMap Platform Eğitmeni bulunduran kurumlardır. MeMap eğitmenleri ve Kurumları, aynı zamanda MeMap Hizmet Kredi Sağlayıcılarıdır."
        },
        {
          Id: 94310,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanıcısı ise MeMap Hizmet Kredilerini sağlayan, geçerli ScoreSoft Lisansına sahip kullanıcıyı ifade eder. ScoreSoft Kullanıcısının ScoreSoft Lisansına sahip olmasına rağmen MeMap Hizmetlerini, MeMap Fitness Akademisi tarafından belirlenmiş kriter ve standartlara göre verme taahhüdü bulunmamaktadır. Bu bakımdan ScoreSoft Kullanıcısı MeMap Dijital Platformu’nda yer almamakta ve MeMap Hizmetlerini verememektedir."
        },
        {
          Id: 396821,
          PropertyName: "value",
          Value:
            "Eğitmenlerin kullanımı için geliştirilen ScoreSoft yazılımı, kişinin fiziksel ve fizyolojik verilerini değerlendirerek, yaşına ve cinsiyetine göre fiziksel uygunluk notunu (FitSkor®) hesaplayan ve buna bağlı olarak tavsiye niteliğinde egzersiz stratejisi oluşturan bir dijital içerikli hizmet yazılımıdır."
        },
        {
          Id: 955947,
          PropertyName: "value",
          Value:
            "ScoreSoft yazılımının tasarlandığı amaca uygun hesaplamalar yapabilmesi için, danışanın platforma kayıt esnasında girilen yaş ve cinsiyet bilgisi kullanılır. Danışan tarafından sağlanan diğer sağlık bilgileri danışanın ibraz ettiği raporlar ve beyanına istinaden eğitmen tarafından kaydedilir. Bunlar dışında hesaplamalar için kullanılan tüm veriler, eğitmenin yaptığı testlerden elde edilir. Eğitmen, vücut kompozisyonu, kas kuvveti ve esneklik ölçümlerinden elde ettiği fiziksel veriler ile kardiyorespiratuar kapasite ölçümlerinden elde ettiği fizyolojik verileri yazılıma girer. Tüm veriler girildikten sonra yazılım, danışanın FitSkor®’unu hesaplar. Egzersiz reçetelendirme stratejisi, danışanın FitSkor®’una, egzersize ayıracağı haftalık gün sayısına ve egzersiz süresine göre tamamen kişiselleştirilmiş olarak oluşturulur."
        },
        { Id: 235635, PropertyName: "value", Value: "MADDE 5: ŞİRKETİN HAK VE YÜKÜMLÜLÜKLERİ" },
        {
          Id: 21334,
          PropertyName: "value",
          Value:
            "5.1. Şirket, MeMap’e üye olan Danışana daha iyi hizmet verebilmek amacıyla Danışanın, MeMap Platformu’na sunmuş olduğu tüm kişisel verilerini 6698 sayılı Kişisel Verilerin Korunması Kanunu’na uygun şekilde işleyeceğini kabul, beyan ve taahhüt eder. Bu doğrultuda işbu sözleşmenin ekinde yer alan MeMap İnternet Sitesi Aydınlatma Metni (EK-1) sözleşmenin ayrılmaz bir parçası olarak kabul edilir."
        },
        {
          Id: 598864,
          PropertyName: "value",
          Value:
            "5.2. MeMap Hizmetlerininin satın alınmasının akabinde Danışan’a ait kişisel veriler satın alma öncesinde Üyenin bilgisine sunulan Kişisel Verilerin Paylaşımına İlişkin Aydınlatma Metni doğrultusunda ScoreSoft Kullanıcısı ile paylaşılır ve ScoreSoft Kullanıcısı tarafından sisteme girilir, Danışan’a ait fiziksel uygunluk notunu (FitSkor®) ve buna bağlı belirlenmiş bireysel egzersiz reçetelendirme tavsiyesi sağlanır. Şirket’in sunduğu bilgi ve içerik, tavsiye niteliğinde olup, buna ilişkin sair uygulama, değerlendirme ve tespitten dolayı Şirket sorumlu tutulamaz. Bu doğrultuda, Üye ve/veya Danışan, ScoreSoft Yazılımı ve İnternet sitesi içindeki her türlü bilgi, egzersiz ve içeriğin tavsiye niteliğinde olduğunu ve hiçbir hastalık veya sağlık durumunun teşhis ve tedavisini sağlamadığını, bu içerikleri kullanıp kullanmaması konusundaki bütün takdir ve sorumluluğun kendisinde olduğunu gayri kabili rücu kabul, beyan ve taahhüt eder. Şirket, ScoreSoft Kullanıcısı önerisi doğrultusunda Danışan’ın seçerek uyguladığı programla ilgili olarak sorumlu tutulamaz."
        },
        {
          Id: 373250,
          PropertyName: "value",
          Value:
            "5.3. Şirket, Danışanın ScoreSoft Yazılımından faydalanmak için ScoreSoft Kullanıcısına vermiş olduğu yazılı ve/veya sözlü olarak bildirilen verileri yazılım içerisinde bulundurur. Danışan, kendi beyanına dayalı olarak vermiş olduğu ilgili verilerde herhangi bir farklılık ya da hata bulunması halinde bundan doğabilecek tüm sorumluluğun kendisine ait olduğu gayri kabili rücu kabul, beyan ve taahhüt eder."
        },
        {
          Id: 695639,
          PropertyName: "value",
          Value:
            "5.4. Şirket, Üye ve/veya Danışmana daha iyi bir hizmet verebilmek ya da Üyeyi ve Danışmanı çeşitli kampanyalara davet edebilmek amacıyla Üyenin ve Danışmanın kişisel bilgilerini (ad ve soyad, elektronik posta adresi, telefon numarası) talep edebilir ve bu amaçla Üyeye ve Danışmana anket ya da bilgi formu doldurtabilir. Şirket sunucu ve bilgisayarlarında toplanan bu bilgiler, dönemsel kampanya çalışmaları, kullanıcı profillerine yönelik özel promosyon faaliyetlerinin kurgulanması ve istenmeyen iletilere yönelik Üye sınıflandırma çalışmalarında Şirket tarafından kullanılabilecektir. Şirket bu faaliyetlerini MeMap İnternet Sitesi Aydınlatma Metni ile Ticari İletişim ve Ticari Elektronik İleti Onay Formu’nun (EK-2) onaylanması suretiyle yürüteceğini beyan ve taahhüt eder."
        },
        {
          Id: 586818,
          PropertyName: "value",
          Value:
            "5.5. Şirket, mücbir sebepler, bağlantı sorunları, internet kesintileri, tedarikçilerden kaynaklanan nedenler, kullanım hataları ve Üyenin ScoreSoft Yazılımı ya da İnternet Sitesi’ni kullandığı cihaza veya cihazın işletim sistemi versiyonuna bağlı olan nedenler ile ScoreSoft Yazılımı’nda ve İnternet Sitesi’nde oluşabilecek kesinti ya da aksaklıklardan sorumlu değildir."
        },
        {
          Id: 247392,
          PropertyName: "value",
          Value:
            "5.6. Şirket, uygun gördüğü zamanlarda önceden hiçbir bildirimde bulunmadan ScoreSoft Yazılımını geliştirebilir, değiştirebilir ve bazı hizmetler için güncelleştirmeler yapabilir, yazılımla ilgili görsel, içeriksel ve uygulamaya yönelik değişikliğe gidebilir. Şirket, Üye’nin FitSkor Lisansını aldıktan sonra Danışan olmasına müteakip Danışan’ın MeMap üyeliği için öngördüğü üyelik bedelini, yazılım içi fiyatları, kampanya ve paketleri her zaman tek taraflı olarak değiştirebileceğini, ek ödemeler oluşabileceğini, güncel değişikliklerden yararlanabilmesi için güncellemeleri düzenli olarak yüklemesi ve bunun için bedel ödemesi gerektiğini kabul, beyan ve taahhüt eder. Söz konusu yazılım güncellemelerini kabul edip yükleyen üyeler hizmet almaya devam eder. Bu doğrultuda, işbu sözleşmenin 13. maddesinde yer alan hükümler saklıdır."
        },
        {
          Id: 531195,
          PropertyName: "value",
          Value:
            "5.7. Şirket hizmetlerin sürekliliğini sağlamak için, işbu Sözleşme üzerinde ayrıca herhangi bir bildirimde bulunmaksızın tek taraflı olarak değişiklik yapabilir ve her zaman herhangi bir gerekçe göstermeksizin, tek taraflı olarak verdiği hizmeti sürekli veya geçici olarak durdurma, hizmetin içeriğini değiştirme veya iptal etme hakkına sahiptir. Şirket, herhangi bir değişiklik olması halinde güncel Üyelik Sözleşmesi’ni aynı internet bağlantısı altında yeni tarih güncellemesi ile ScoreSoft Yazılımı ve İnternet Sitesinde yayınlayacak, gerek görmesi halinde elektronik posta ile Danışana bildirecektir. Güncel Kullanıcı Sözleşmesi, ScoreSoft Yazılımı ve İnternet Sitesi'nde yayınlandığı andan itibaren geçerli olacak ve İnternet Sitesi’nin ve/veya ScoreSoft Yazılımının kullanımı o andan itibaren yenilenmiş Üyelik Sözleşmesi’ne tabi olacaktır."
        },
        {
          Id: 373237,
          PropertyName: "value",
          Value:
            "5.8. ScoreSoft Yazılımı ve İnternet Sitesine üyelik kişiye özel ve bireysel kullanım içindir. Şirket, ScoreSoft Yazılımının veya İnternet Sitesi’nin işbu Sözleşme’de yer alan maddelere aykırı olarak kullandığını tespit etmesi durumunda üyeliği durdurabilir, askıya alabilir ya da tamamen sonlandırabilir. Bu halde Üye Şirket’ten herhangi bir isim altında hiçbir talepte bulunamayacağını kabul, beyan ve taahhüt etmiştir."
        },
        {
          Id: 584146,
          PropertyName: "value",
          Value:
            "5.9. Şirket, işbu Sözleşme kapsamındaki hizmetin Merkez’e bağlı MeMap Eğitmeni tarafından verilmesi halinde, Merkez’e atfedilen yükümlülükler bakımından Merkez’in münhasır sorumluluğuna halel getirmeksizin, takdir hakkı münhasıran kendisine ait olmak üzere, kendisi veya yetkilendirdiği MeMap Elçisi tarafından işbu Sözleşmeye uygunluğunu hiçbir ihbarda bulunmaksızın ve ek yetkilendirmeye gerek olmaksızın her zaman denetleme hak ve yetkisine sahiptir."
        },
        { Id: 872578, PropertyName: "value", Value: "MADDE 6: ÜYE VE DANIŞAN’NIN HAK VE YÜKÜMLÜLÜKLERİ" },
        {
          Id: 100638,
          PropertyName: "value",
          Value:
            "6.1. Danışan, MeMap’e üye olurken verdiği e-posta adresi kendisine ait ve aktif bir hesap olmalıdır. Danışan sistemsel bir arıza olmadıkça yalnızca bir e-posta adresini yazılıma tanıtabilir. Üyelikle ilgili tüm aktivasyon, bilgilendirme ve yazışmalarda sisteme üye olurken verilen e-posta adresi iletişim adresi olarak esas alınır. İletişim adresinin yanlış verilmesinden dolayı meydana gelecek gecikmelerden ve olası, doğrudan ve/veya dolaylı zararlardan Şirket sorumlu tutulamaz.  E-posta adresleri ile şifrelerin korunması, kopyalanması ya da çalınmasıyla ilgili oluşabilecek zararlardan münhasıran Danışan sorumludur. İşbu nedenle, oluşabilecek zararlardan dolayı Şirkete karşı rücu hakkından feragat ettiğini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 232224,
          PropertyName: "value",
          Value:
            "6.2. Üye, FitSkor Lisansını satın almasına müteakip kendi belirlediği şifre ile üyelik süresi boyunca söz konusu lisansı kullanabilir ve Danışan sıfatına haiz olur. Danışan, internet sitesine kaydettirdiği kişisel verilerini ve bunların değerlendirilmesi sonucunda oluşturulan fiziksel uygunluk sonuçlarını görüntüleyebilir. Danışan, kendi beyanına dayalı olarak ilettiği kişisel verilerin doğru olduğunu kabul, beyan ve taahhüt eder. Danışan, kişisel verileriyle ilgili bir hata veya eksiklik gördüğünde ivedi olarak ScoreSoft Kullanıcısına bildirmeli ve düzeltilmiş ilgili kişisel verinin sisteme kaydını temin etmelidir. Danışan, bilgi ve/veya kişisel verilerinin eksik veya gerçeğe uygun olarak doldurulmaması durumundan kaynaklanacak her türlü zarardan kendisi sorumludur."
        },
        {
          Id: 134596,
          PropertyName: "value",
          Value:
            "6.3. Danışan, MeMap platformunda yer alan MeMap Eğitmeninden randevu alabilir. İşbu sözleşmenin 7.4. hükümleri uyarınca Danışan randevusunu iptal etme hakkına sahiptir."
        },
        {
          Id: 475367,
          PropertyName: "value",
          Value:
            "6.4. Danışan kendi iradesiyle seçerek randevu aldığı ScoreSoft Kullanıcısına, işbu sözleşmeye konu hizmetin ifa edilmesi amacıyla sınırlı şekilde işlenmesi gereken kişisel verilerini (ScoreSoft Yazılımı üzerinden) Kişisel Verilerin Paylaşımına İlişkin Aydınlatma Metni uyarınca paylaştığı kabul etmektedir. Danışan, ScoreSoft Kullanıcısını dilediği zaman değiştirme hakkına sahiptir."
        },
        {
          Id: 154868,
          PropertyName: "value",
          Value:
            "6.5. Danışan, sözleşme süresince ve sözleşme sona erdikten sonra, Şirket ve/veya Şirket’in işbirlikçileri ve/veya iştirakleri tarafından gönderilecek faks, elektronik posta, kısa mesaj gibi sair iletişim vasıtaları ile bilgilendirme, pazarlama ve/veya reklam amacıyla bildirimler almayı Ticari İletişim ve Ticari Elektronik İleti Onay Formu’nu onaylamak suretiyle kabul eder. Danışan, işbu bilgilendirme sisteminden çıkma talebini Şirket’in info@memap.com.tr adresine gönderecektir. İşbu e-postanın Şirkete ulaşmasından itibaren 48 saat içinde Şirket bilgilendirme mesajlarına son verilecektir."
        },
        {
          Id: 286153,
          PropertyName: "value",
          Value:
            "6.6. Üye olunmasına müteakip FitSkor Lisans alımıyla birlikte Danışan üyeliğinin aktifleştirilmesinden itibaren Şirket, tahsil edilen ücret karşılığı olan bir adet FitSkor hesaplama ve raporlama hizmeti sunmaktadır."
        },
        {
          Id: 587050,
          PropertyName: "value",
          Value:
            "6.7. Danışanlara üyelik tarihinden itibaren bir yıl boyunca platform kullanımı Şirket tarafından ücretsiz olarak verecektir."
        },
        {
          Id: 280564,
          PropertyName: "value",
          Value:
            "6.8. Sözleşme konusu hizmetten Merkez aracılığıyla faydalanan Danışan, işbu sözleşmenin 5.9. maddesi uyarınca Şirketin kendisi veya yetkilendirdiği MeMap Elçisi tarafından Merkez üzerinde denetim yetkisi olduğunu kabul eder."
        },
        { Id: 603992, PropertyName: "value", Value: "MADDE 7: SCORESOFT KULLANICISI VE MEMAP HİZMET KREDİLERİ" },
        {
          Id: 439857,
          PropertyName: "value",
          Value:
            "MEMAP HİZMET KREDİSİ: MeMap Hizmet Kredisi her MeMap hizmeti için kullanılan hayali bir para birimidir.  Danışan MeMap Hizmet Kredisini kendi satın alabilir veya MeMap Sağlayıcılarından elde edebilir. "
        },
        {
          Id: 812604,
          PropertyName: "value",
          Value: "7.1. Danışan’ın FitSkor Lisansını kullanma ve yönetme hakkı, lisans ücretini ödeyen Danışan’a aittir."
        },
        {
          Id: 215733,
          PropertyName: "value",
          Value:
            "7.2. Danışan, ScoreSoft Kullanıcısı vasıtası ile MeMap kredisi elde etmiş ise işbu hizmet kredisi ScoreSoft Kullanıcısının ve/veya ScoreSoft Merkezinin belirlediği MeMap Hizmetini ve Hizmetin alınabileceği Eğitmenden alabilir. Başkaca bir deyişle, Şirket’e herhangi bir ücret ödemeksizin, Merkez ya da ScoreSoft Kullanıcısı vasıtası ile MeMap Hizmetlerinden yararlanır."
        },
        {
          Id: 154561,
          PropertyName: "value",
          Value:
            "7.3. Danışan sıfatına haiz olan Üye, 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamındaki haklarını Şirket’ten talep edebilir."
        },
        {
          Id: 605843,
          PropertyName: "value",
          Value:
            "7.4. Danışan, ScoreSoft Yazılımına gireceği kişisel verilerini sistem üzerinden kendisinin yönetmesini istemesi halinde ek bedel ödeyerek ScoreSoft Lisansına sahip olması gerektiğini kabul eder."
        },
        {
          Id: 795510,
          PropertyName: "value",
          Value:
            "7.5. Danışan, Şirket’ten kendisi ile ilgili olarak saklanan kişisel verilerle ilgili haklarını 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 11. maddesi kapsamında talep edebilir."
        },
        { Id: 115698, PropertyName: "value", Value: "MADDE 10: SORUMSUZLUK" },
        {
          Id: 523188,
          PropertyName: "value",
          Value:
            "10.1. ScoreSoft Yazılımı ve İnternet Sitesi içindeki her türlü bilgi, egzersiz ve içerik Danışanın “Egzersiz Katılım Öncesi Sağlık Değerlendirme Rehberi”nde beyan ettiği bilgilere istinaden ortalama olarak herhangi bir tıbbi veya psikolojik rahatsızlığı olmadığı varsayılarak tavsiye niteliğinde hazırlanmıştır. Danışan, bu durumu bilerek MeMap Hizmetlerini satın almakta ve kullanım öncesi gerek görüyorsa doktorunun onayını almakla yükümlüdür. Şirket, Danışanın tıbbi veya psikolojik rahatsızlığı nedeni ile ortaya çıkacak herhangi bir sonuçtan sorumlu tutulamaz."
        },
        {
          Id: 114374,
          PropertyName: "value",
          Value:
            "10.2. Danışan, İnternet sitesi içindeki her türlü bilgi, egzersiz ve içeriğin tavsiye niteliğinde olduğunu ve hiçbir hastalık veya sağlık durumunun teşhis ve tedavisini sağlamadığını, bu içerikleri kullanıp kullanmaması konusundaki bütün takdir ve sorumluluğun kendisinde olduğunu kabul, beyan ve taahhüt eder. Şirket, Danışan’ın seçerek uyguladığı programla ilgili olarak sorumlu tutulamaz. Bu doğrultuda, Danışan’ın kişisel verilerini tam ve doğru olarak bildirildiği faraziyesiyle hazırlanan ve ScoreSoft Yazılımı tarafından sunulan içerik ve egzersiz programlarının bir uzmana danışılması ya da uzman eşliğinde uygulanması gerektiğinin kendisine bildirilmiş olduğunu gayri kabili rücu kabul, taahhüt ve beyan eder. ScoreSoft Yazılımı, hastalık veya sağlık durumunun teşhisi veya tedavisini sağlamamaktadır. Danışan, bildirmediği veya eksik ya da yanlış bildirdiği verilerinden kaynaklı rahatsızlık, sakatlık, zafiyet ve benzeri sebeplerden dolayı meydana gelebilecek doğrudan ya da dolaylı zararlardan Şirketin sorumlu olmadığını kabul, beyan ve taahhüt eder."
        },
        {
          Id: 858671,
          PropertyName: "value",
          Value:
            "10.3. Şirket, İnternet Sitesi’ne kaydolan Danışana verdiği bilgilerle ve önerilerle hedeflenen kilo ve vücut ölçülerine ulaşma ya da bunların Danışanın sahip olduğu çevre ve sağlık koşullarına uygunluğuna veya sonuçlarının başarılı olacağına dair garanti vermez. Aksi iddia edilemeyeceği gibi, bu hususa dair Danışan maddi ve manevi herhangi bir zarar talebinde bulunmayacağını gayri kabili rücu kabul, beyan ve taahhüt eder."
        },
        {
          Id: 466720,
          PropertyName: "value",
          Value:
            "10.4. MeMap Platformunun sağladığı hizmetler, bu konuda yeterliliği belirlenmiş “MeMap Hizmet Sertifikası”na sahip MeMap Eğitmenleri tarafından verilebilir. MeMap Hizmet Sertifikasına sahip olmayan ScoreSoft Kullanıcılarının işbu sözleşme kapsamına giren hizmetleri vermeye yetkisi yoktur. Bu kapsamda, Danışanın sözleşmeye konu hizmeti, Şirketin veya hizmeti aldığı Merkezin bilgisi dışında MeMap Hizmet Sertifikasına sahip olmayan ScoreSoft Kullanıcısı ve/veya ScoreSoft Kullanıcısılarından alması halinde oluşabilecek herhangi bir zarara ilişkin Şirket’in sorumluluğu bulunmamaktadır."
        },
        { Id: 163542, PropertyName: "value", Value: "MADDE 11: GİZLİLİK VE KİŞİSEL VERİLERİN KORUNMASI" },
        {
          Id: 744898,
          PropertyName: "value",
          Value:
            "11.1. Gizlilik Taraflar işbu Sözleşme sona erse dahi, birbirlerinin finansal, ticari, teknolojik vb. bilgi ve sırlarını saklamayı, herhangi bir şekilde elde edecekleri birbirlerine ait dolaylı veya dolaysız gizli bilgiyi kısmen veya tamamen üçüncü şahıslara açıklamayacaklarını, bunlara ilişkin belge ve dokümanları üçüncü şahıslara vermeyeceklerini, tüm gizli bilgilerin kopyalarını, nüshalarını veya sair araçlarını diğer taraf istediği takdirde geri vereceklerini veya bunları yok edeceklerini kabul ve taahhüt ederler. Taraflar gizli bilgilerin kendi çalışanları ya da üçüncü şahıslar tarafından bu Sözleşme’nin hükümlerine aykırı şekilde ifşa edilmemesini ya da dağıtılmamasını temin etmek için kendilerinden beklenen tüm önlemleri almayı kabul ve taahhüt ederler. Taraflar birbirlerinin çalışanlarına, müşterilerine ve muhatap oldukları diğer kategorideki ilgili kişilere dair kişisel verileri yasal işleme şartları ve kuralları doğrultusunda kullanacaklarını, bunların gizliliğine dair her tür idari ve teknik tedbiri alacaklarını taahhüt ederler."
        },
        {
          Id: 56190,
          PropertyName: "value",
          Value:
            "11.2. Aydınlatma Yükümlülüğü Sözleşme tarafları, birbirlerinin hissedarlarının, ortaklarının, yetkililerinin ve çalışanlarının (hepsi birlikte “taraf temsilcisi” olarak anılır) verilerini faaliyetlerin mevzuata uygun yürütülmesi ve sözleşmenin kuruluşu ve doğrudan ifası amaçları ve şartlarıyla sınırlı olarak işler. Her taraf süreç boyunca kullanılacak ve aktarılacak kişisel verilerle ilgili olarak kendi taraf temsilcisini diğer taraf adına aydınlatmakla ve bu işlemi tevsik etmekle yükümlüdür."
        },
        {
          Id: 762067,
          PropertyName: "value",
          Value:
            "11.3. Kişisel Verileri İşleme ve Aktarma Kuralları Taraflar sözleşmenin ifası için gerekli olan asgari kategorideki kişisel verileri güvenli yöntemlerle birbirlerine aktarırlar. Bu veriler, sözleşmenin ifası dışında yalnızca taraf temsilcisinin ayrıca aydınlatılması halinde ve mevzuatla öngörülen hukuki sebepler doğrultusunda kullanılır. Taraflar bu verileri güvenli ortamlarda saklamak ve sözleşmenin sona ermesinden itibaren saklama ve imha politikalarına uygun olarak ve en geç on yıl içerisinde imha etmekle yükümlüdürler. Aksi durumlarda her bir taraf kendi hukuka aykırı eyleminden sorumludur."
        },
        { Id: 242652, PropertyName: "value", Value: "MADDE 12: SÜRÜM/VERSİYON DEĞİŞİMİ VE BAKIM" },
        {
          Id: 120134,
          PropertyName: "value",
          Value:
            'Şirket önceden haber vermeksizin sistemde ve/veya yazılı dokümanlarında değişiklikler yapma, sistem ürünlerinin yeni versiyon ve/veya sürümlerini çıkarma hakkına sahiptir, Danışan bu yeni sistemi ve/veya yeni yazılı dokümanları ancak "güncelleme” bedelini ödeyerek güncel sistemi kullanabilir. Şirket isterse güncellemeleri belli sürelerle ücretsiz şekilde yapabilir.'
        },
        { Id: 649857, PropertyName: "value", Value: "MADDE 13: FİKRİ MÜLKİYET HAKLARININ KULLANIMI" },
        {
          Id: 692404,
          PropertyName: "value",
          Value:
            "ScoreSoft Yazılımı ve İnternet Sitesi’nin bütün fikri ve sınai mülkiyet hakları Şirket’e aittir. Yazılımın yapısı, düzeni, tasarımı, görselleri, içinde yer alan her türlü egzersiz, içerik ve bilgi ile yazılımın kaynak kodları Şirket’e ait fikri ve sınai mülkiyet varlıklarını oluşturur. Tüm bu varlıklar, 6769 sayılı Sınai Mülkiyet Kanunu (SMK) ve 5846 sayılı Fikir ve Sanat Eserleri Kanunu’na göre korunmaktadır. İşbu Sözleşme, yazılımın ve/veya içeriğin herhangi bir fikri mülkiyet hakkını ScoreSoft Kullanıcısına ve/veya Danışana vermemekte ve buna ilişkin tüm haklar Şirket tarafından saklı tutulmaktadır. Bu doğrultuda, Şirket, 6769 sayılı Sınai Mülkiyet Kanunu’na (SMK) göre ortaya çıkabilecek marka, patent, faydalı model, ticari sır, süreç, üretim yöntemi, fikir, buluş, taslak, tasarım, model araştırma sonucu, test sonucu, üretim bilgisi, performans bilgisi, plan, metot, spesifikasyon gibi SMK kapsamındaki tüm sınai ürünler (kısaca “Sınai Ürünler”) ve 5846 sayılı Fikir ve Sanat Eserleri Kanunu’na göre ortaya çıkabilecek telif hukukunun kapsamına giren tüm fikri ürünler (kısaca “Fikri Ürünler”) ile bunların mali haklarının ve diğer yan haklarının tamamı üzerinde münhasır ve süresiz olarak tek hak sahibidir. Kullanım lisans hakkı dışında kalan hukuka aykırı kopya eylemi ve işlemler eylemin nev'ine göre cezai yaptırıma sahiptir."
        },
        { Id: 135907, PropertyName: "value", Value: "MADDE 14: MUHTELİF HÜKÜMLER" },
        { Id: 492467, PropertyName: "value", Value: "14.1. FERAGAT OLMAMASI" },
        {
          Id: 873917,
          PropertyName: "value",
          Value:
            "İşbu Sözleşme ile tanınan haklardan feragat, ilgili Tarafça yazılı olarak yapılmadığı ve imzalanmadığı sürece bağlayıcı olmayacaktır. Herhangi bir Tarafın işbu Sözleşmedeki herhangi bir hakkını, yetkisini veya imtiyazını kullanmaması veya kullanmakta gecikmesi, söz konusu haktan, yetkiden veya imtiyazdan feragat olarak kabul edilmeyecektir veya tek başına veya kısmen kullanım, söz konusu hakkın, yetkisini veya imtiyazın başkaca kullanımını veya gelecekte kullanımını veya işbu Sözleşmedeki herhangi bir diğer hakkın, yetkinin veya imtiyazın kullanılmasını engellemeyecektir."
        },
        { Id: 240352, PropertyName: "value", Value: "14.2. BÖLÜNEBİLİRLİK" },
        {
          Id: 727636,
          PropertyName: "value",
          Value:
            "İşbu Sözleşmenin, yetkili bir mahkeme tarafından yasadışı, geçersiz veya icra edilemez olduğuna karar verilen bir hükmü veya şartı, işbu Sözleşmenin diğer hüküm ve şartlarının geçerliliğini etkilemeyecektir. Taraflar söz konusu yasadışı, geçersiz veya icra edilemez hüküm veya şartı, niyetlerini mümkün olan en yakın şekilde yansıtan bir hüküm ile değiştirmeyi kabul ederler."
        },
        { Id: 486680, PropertyName: "value", Value: "14.3. DEĞİŞİKLİKLER" },
        {
          Id: 624057,
          PropertyName: "value",
          Value:
            "İşbu Sözleşmenin hükümlerinden herhangi birinde yapılacak değişiklik, tadilat veya ekleme, Taraflarca yazılı olarak yapılmadığı ve imzalanmadığı sürece bağlayıcı olmayacaktır."
        },
        { Id: 133869, PropertyName: "value", Value: "14.4. DEVİR ve TEMLİK" },
        {
          Id: 862670,
          PropertyName: "value",
          Value:
            "Taraflar işbu Sözleşmeden doğan hak ve yükümlülüklerini diğer tarafın açık ve yazılı izni olmaksızın gerçek ve/veya tüzel üçüncü kişilere/kurumlara devredemez. Aksi durumda yapılan devir veya temlik taraflar açısından geçerli kabul edilmeyecektir."
        },
        { Id: 147800, PropertyName: "value", Value: "14.5. DELİL SÖZLEŞMESİ" },
        {
          Id: 252952,
          PropertyName: "value",
          Value:
            "Taraflar, işbu sözleşmenin yürütülmesi, yorumu veya doğacak her türlü ihtilaflarda her iki tarafın defter, evrak, eposta yazışmaları ve bilgisayar kayıtlarının HMK 193. maddesi gereğince münhasır delil teşkil edeceğini kabul ve taahhüt eder."
        },
        { Id: 855821, PropertyName: "value", Value: "14.6. MÜCBİR SEBEP" },
        {
          Id: 636258,
          PropertyName: "value",
          Value:
            "İşbu sözleşme, 6098 sayılı Türk Borçlar Kanunu’nda belirtilen ve tarafların kendi iradeleri dışındaki veya kendilerine isnat olunamayacak sebeplerle (deprem, sel, olağanüstü hal, terör, savaş, Dünya Sağlık Örgütü tarafında küresel ölçekte salgın hastalık-pandemi ilan edilmesi ve bu ilana bağlı olarak devlet makamları tarafından ülkesel salgın hastalık hali veya devletin resmi kurumlarınca ya da İstanbul Ticaret Odası tarafından ekonomik kriz olarak kabul edilen ekonomik dalgalanmalar, grev, lokavt, vb.) borçların ifası imkânsız hale gelirse ve mücbir sebep durumu üç (3) ayı aşarsa tarafların, o güne kadarki yükümlülüklerini karşılıklı olarak yerine getirmesi şartıyla, Sözleşmeyi tazminatsız olarak feshetme hakkı doğar."
        },
        { Id: 874215, PropertyName: "value", Value: "MADDE 15. TEBLİĞ ADRESİ" },
        {
          Id: 511913,
          PropertyName: "value",
          Value:
            "Taraflar, işbu sözleşmeden doğan uyuşmazlıklarda ve diğer hususlarda İşbu Sözleşme’nin 1. Maddesinde belirtilen adreslerine yapılacak tebligatların kanuni ikametgahlarına yapılmış sayılacağını, adres değişikliklerini değişiklikten itibaren 2 (iki) gün içinde diğer tarafa yazılı olarak bildirmedikleri takdirde bu hususun geçerli olmaya devam edeceğini kabul ve beyan ederler. İşbu Sözleşme çerçevesinde temerrüde ilişkin ihbarlar, fesih ihbarları ve bu konulara cevaben yapılacak ihbar ve ihtarlar noter kanalı ile yapılmadıkça bağlayıcı olmaz."
        },
        { Id: 392782, PropertyName: "value", Value: "MADDE 16: SÖZLEŞMENİN SONA ERMESİ" },
        {
          Id: 952833,
          PropertyName: "value",
          Value:
            "Fazlaya ilişkin haklarımız saklı kalmak kaydıyla, Danışanın MeMap yazılım ve/veya lisans bedelinin ödememesi veya eksik ödemesi, Şirket’in telif haklarını ihlal etmesi halinde işbu sözleşmesi başka bir hükme, ihtara, ihbara gerek olmaksızın bozucu şartla kendiliğinden sona erecektir."
        },
        { Id: 270104, PropertyName: "value", Value: "MADDE 17. SÖZLEŞME SAKLAMA SÜRESİ" },
        {
          Id: 62665,
          PropertyName: "value",
          Value:
            "İşbu sözleşme 6698 sayılı Kişisel Verilerin Korunması Kanunu, 6502 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeler Yönetmeliği ile diğer sair ilgili mevzuat hükümleri dikkate alınarak Şirket tarafından elektronik ortamda 3 yıl süre ile saklanacaktır, Danışan işbu süre boyunca, elektronik ortamda sözleşmeye erişecektir."
        },
        { Id: 85733, PropertyName: "value", Value: "MADDE 18: BİLDİRİMLER ve DELİL SÖZLEŞMESİ" },
        {
          Id: 162712,
          PropertyName: "value",
          Value:
            "İşbu Sözleşme tahtında Taraflar arasında yapılacak her türlü yazışma, mevzuatta sayılan zorunlu haller dışında, e-mail aracılığıyla yapılacaktır. Danışan, işbu Sözleşme’ den doğabilecek ihtilaflarda Şirket’in resmi defter, ticari kayıtlarının, veri tabanının, sunucularında tuttuğu elektronik bilgilerin ve bilgisayar kayıtlarının, bağlayıcı, kesin ve münhasır delil teşkil edeceğini, bu maddenin Hukuk Muhakemeleri Kanunu'nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt eder."
        },
        { Id: 655603, PropertyName: "value", Value: "MADDE 19: YETKİLİ MAHKEME" },
        {
          Id: 11242,
          PropertyName: "value",
          Value:
            "İşbu Sözleşmenin yorumunda ve işbu Sözleşme sebebiyle ortaya çıkacak olan tüm uyuşmazlıklarda İstanbul (Anadolu ve Beykoz) Mahkemeleri ve İcra Daireleri yetkili olup, Türk hukuku uygulanır. Ticari veya mesleki olmayan amaçlarla hareket eden tüketici niteliğindeki Üye şikâyet ve itirazları konusunda başvurularını, Ticaret Bakanlığı tarafından her yıl Aralık ayında belirlenen parasal sınırlar dâhilinde Üye’nin mal veya hizmeti satın aldığı veya ikametgâhının bulunduğu yerdeki Tüketici Sorunları İl ve İlçe Hakem Heyetine veya Tüketici Mahkemesine yapabilir."
        },
        { Id: 885076, PropertyName: "value", Value: "MADDE 18: YÜRÜRLÜK MADDESI" },
        {
          Id: 246369,
          PropertyName: "value",
          Value:
            "İşbu Sözleşme, MeMap İnternet Sitesi Üyelik Sözleşmesi'nin ve MeMap İnternet Sitesi Aydınlatma Metni’nin ayrı ayrı tıklanmak suretiyle dijital ortamda onaylanması ya da işbu Sözleşmeyi fiziki olarak imzalanması ile Danışan Sözleşmesi’nin 20 ana maddeleri hakkında bilgilendirildiğini, tüm hüküm ve koşulların yürürlüğe girdiğini kabul, beyan ve taahhüt eder."
        },
        { Id: 850089, PropertyName: "value", Value: "Okudum, anladim." },
        { Id: 217376, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      UserSettingsByUserId: [],
      AnyProfileOfEmail: [],
      SaveRecord: false,
      ChangeEnabledOf: "",
      GoBack: "",
      isComp217376Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("danisansozlesmesiyeni", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DanisanSozlesmesiYeniPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("danisansozlesmesiyeni", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("danisansozlesmesiyeni", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DanisanSozlesmesiYeniPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      danisansozlesmesiyeni_295296_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  DanisanSozlesmesiYeniPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      email_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DanisanSozlesmesiYeni/DanisanSozlesmesiYeniPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.UserSettingsByUserId = result?.data.userSettingsByUserId;
    stateVars.AnyProfileOfEmail = result?.data.anyProfileOfEmail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DanisanSozlesmesiYeniPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanSozlesmesiYeniPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].userId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].userId
            : null
        ),
        "Guid"
      ),
      LastChosenProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.AnyProfileOfEmail?.length > 0
            ? stateVars.AnyProfileOfEmail[0].id
            : this.state.AnyProfileOfEmail?.length > 0
            ? this.state.AnyProfileOfEmail[0].id
            : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UserSettingsByUserId?.length > 0
            ? stateVars.UserSettingsByUserId[0].id
            : this.state.UserSettingsByUserId?.length > 0
            ? this.state.UserSettingsByUserId[0].id
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "DanisanSozlesmesiYeni/DanisanSozlesmesiYeniPageInit1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_1;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DanisanSozlesmesiYeniPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DanisanSozlesmesiYeniPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanSozlesmesiYeniPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp217376Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DanisanSozlesmesiYeniComponent_295296_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp217376Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DanisanSozlesmesiYeniComponent_295296_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DanisanSozlesmesiYeniComponent_295296_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "danisansozlesmesiyeni_295296_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp217376Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  DanisanSozlesmesiYeniComponent_217376_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KvkkOkudum_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DanisanSozlesmesiYeni/DanisanSozlesmesiYeniComponent_217376_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DanisanSozlesmesiYeniComponent_217376_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DanisanSozlesmesiYeniComponent_217376_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
