import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOgretmenSiniflari_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOgretmenSiniflari_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  ProfilByKisiIdAndRolename: any[];
  ProfilByKisiIdAndRolename_dummy: any[];
  Kirilim2ByKirilim1: any[];
  Kirilim2ByKirilim1_dummy: any[];
  KurumTipiSelectById: any[];
  KurumTipiSelectById_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
}

export class OgretmenSiniflari_ScreenBase extends React.PureComponent<IOgretmenSiniflari_ScreenProps, any> {
  ogretmensiniflari_977816_value_kuikaMultiSelectRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "029745b7-1d31-42bb-8ae1-ffb1b1a6707a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 260433, PropertyName: "value", Value: "Listesi" },
        { Id: 977816, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 532205, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.ogretmensiniflari_977816_value_kuikaMultiSelectRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      ProfilByKisiIdAndRolename: [],
      Kirilim2ByKirilim1: [],
      KurumTipiSelectById: [],
      SaveRecord: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ogretmensiniflari", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.OgretmenSiniflariPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("ogretmensiniflari", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("ogretmensiniflari", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.OgretmenSiniflariPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      ogretmensiniflari_977816_value: this.state.ProfilByKisiIdAndRolename?.at?.(0)?.kirilim2Ids ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  OgretmenSiniflariPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName("Rol_Instructor", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "OgretmenSiniflari/OgretmenSiniflariPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.ProfilByKisiIdAndRolename = result?.data.profilByKisiIdAndRolename;
    formVars.ogretmensiniflari_977816_value =
      stateVars.ProfilByKisiIdAndRolename?.length > 0
        ? stateVars.ProfilByKisiIdAndRolename[0].kirilim2Ids
        : this.state.ProfilByKisiIdAndRolename?.length > 0
        ? this.state.ProfilByKisiIdAndRolename[0].kirilim2Ids
        : null;
    formVars.ogretmensiniflari_977816_options = stateVars.Kirilim2ByKirilim1;
    stateVars.Kirilim2ByKirilim1 = result?.data.kirilim2ByKirilim1;

    formVars.ogretmensiniflari_977816_value =
      stateVars.ProfilByKisiIdAndRolename?.length > 0
        ? stateVars.ProfilByKisiIdAndRolename[0].kirilim2Ids
        : this.state.ProfilByKisiIdAndRolename?.length > 0
        ? this.state.ProfilByKisiIdAndRolename[0].kirilim2Ids
        : null;
    formVars.ogretmensiniflari_977816_options = stateVars.Kirilim2ByKirilim1;
    stateVars.KurumTipiSelectById = result?.data.kurumTipiSelectById;
    formVars.ogretmensiniflari_923160_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].kirilim2lari
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].kirilim2lari
        : null
    );
    formVars.ogretmensiniflari_305365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].ikinciKirilimAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].ikinciKirilimAdi
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OgretmenSiniflariPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmenSiniflariPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.ogretmensiniflari_923160_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].kirilim2lari
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].kirilim2lari
        : null
    );

    formVars.ogretmensiniflari_305365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].ikinciKirilimAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].ikinciKirilimAdi
        : null
    );

    formVars.ogretmensiniflari_977816_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProfilByKisiIdAndRolename?.length > 0
        ? stateVars.ProfilByKisiIdAndRolename[0].kirilim2Ids
        : this.state.ProfilByKisiIdAndRolename?.length > 0
        ? this.state.ProfilByKisiIdAndRolename[0].kirilim2Ids
        : null
    );

    stateVars.dataSource_977816 = this.state.Kirilim2ByKirilim1;
    stateVars.dataSource_977816 = this.state.Kirilim2ByKirilim1;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  OgretmenSiniflariComponent_321766_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OgretmenSiniflariComponent_532205_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "ogretmensiniflari_977816_value", "value", "Kirilim2ByKirilim1", "id", "id")
        ),
        "string"
      ),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilByKisiIdAndRolename?.length > 0
            ? stateVars.ProfilByKisiIdAndRolename[0].id
            : this.state.ProfilByKisiIdAndRolename?.length > 0
            ? this.state.ProfilByKisiIdAndRolename[0].id
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "OgretmenSiniflari/OgretmenSiniflariComponent_532205_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OgretmenSiniflariComponent_532205_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OgretmenSiniflariComponent_532205_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
