import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IComposants_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IComposants_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  BilesenCount: any[];
  BilesenCount_dummy: any[];
  BilesenSearch: any[];
  BilesenSearch_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  BilesenDeleteById: number;
  BilesenDeleteById_dummy: number;

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class Composants_ScreenBase extends React.PureComponent<IComposants_ScreenProps, any> {
  composants_399769_value_kuikaTableRef: React.RefObject<any>;
  composants_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 703532, PropertyName: "value", Value: "COMPONENTS", TagName: "Label5_value" },
        { Id: 416063, PropertyName: "placeholder", Value: "Search...", TagName: "txtSearch_placeholder" },
        { Id: 176906, PropertyName: "label", Value: "NEW COMPONENT", TagName: "Button1_label" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "No data found",
          TagName: "tblFitnessbileseni_nodatafoundmessage"
        },
        { Id: 5713656, PropertyName: "title", Value: "Component Description", TagName: "clmTanim_title" },
        { Id: 3648064, PropertyName: "value", Value: "[datafield:tanim]", TagName: "lblTanim_value" },
        { Id: 849477, PropertyName: "title", Value: "Classification", TagName: "TableColumn5_title" },
        { Id: 533578, PropertyName: "value", Value: "[datafield:siniflandirma]", TagName: "Label2_0_value" },
        { Id: 781885, PropertyName: "title", Value: "Sub Components", TagName: "TableColumn4_title" },
        { Id: 726461, PropertyName: "value", Value: "[datafield:adet]", TagName: "Label4_value" },
        {
          Id: 96374325,
          PropertyName: "fe852656-41cb-0d69-f310-feba936ad060_confirmation",
          Value: "Are you sure you want to delete this record?",
          TagName: "BilesenDeleteById_confirmation",
          IsConfirmation: true
        }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "aa06c150-cf4e-4d96-bdda-6936d585b3e4",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 703532, PropertyName: "value", Value: "BİLEŞENLER" },
        { Id: 416063, PropertyName: "placeholder", Value: "Search..." },
        { Id: 176906, PropertyName: "label", Value: "YENİ BİLEŞEN" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 5713656, PropertyName: "title", Value: "Bileşen Tanımı" },
        { Id: 3648064, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 849477, PropertyName: "title", Value: "Sınıflandırma" },
        { Id: 533578, PropertyName: "value", Value: "[datafield:siniflandirma]" },
        { Id: 781885, PropertyName: "title", Value: "Alt Bileşenler" },
        { Id: 726461, PropertyName: "value", Value: "[datafield:adet]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.composants_399769_value_kuikaTableRef = React.createRef();
    this.composants_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      BilesenCount: [],
      BilesenSearch: [],
      SetValueOf: "",
      NAVIGATE: "",
      BilesenDeleteById: 0,

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("composants", "master_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.ComposantsPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("composants", "master_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("composants", "master_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ComposantsPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.ComposantsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  ComposantsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Composants/ComposantsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BilesenCount = result?.data.bilesenCount;
    stateVars.BilesenSearch = result?.data.bilesenSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ComposantsPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ComposantsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.BilesenSearch;
    formVars.composants_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.BilesenCount?.length > 0
        ? stateVars.BilesenCount[0].count
        : this.state.BilesenCount?.length > 0
        ? this.state.BilesenCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ComposantsComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "composants_172599_value", 1, null);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ComposantsComponent_416063_onPressEnter1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ComposantsComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Composants/ComposantsComponent_416063_onPressEnter1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BilesenCount = result?.data.bilesenCount;
    stateVars.BilesenSearch = result?.data.bilesenSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ComposantsComponent_416063_onPressEnter2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ComposantsComponent_416063_onPressEnter2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.BilesenSearch;
    formVars.composants_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.BilesenCount?.length > 0
        ? stateVars.BilesenCount[0].count
        : this.state.BilesenCount?.length > 0
        ? this.state.BilesenCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ComposantsComponent_416063_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "composants_172599_value", 1, null);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ComposantsComponent_416063_onBlur1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ComposantsComponent_416063_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Composants/ComposantsComponent_416063_onBlur1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BilesenCount = result?.data.bilesenCount;
    stateVars.BilesenSearch = result?.data.bilesenSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ComposantsComponent_416063_onBlur2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ComposantsComponent_416063_onBlur2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.BilesenSearch;
    formVars.composants_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.BilesenCount?.length > 0
        ? stateVars.BilesenCount[0].count
        : this.state.BilesenCount?.length > 0
        ? this.state.BilesenCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ComposantsComponent_176906_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Composant", "BilesenId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Composants",
      "Composant",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "549210",
      null,
      "right",
      null,
      "450px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ComposantsComponent_666080_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BilesenAltBilesenleriList",
      "BilesenId",
      ReactSystemFunctions.value(this, "composants_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("BilesenAltBilesenleriList", "ShowDownload", false);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Composants",
      "BilesenAltBilesenleriList",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "192768",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ComposantsComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Composant",
      "BilesenId",
      ReactSystemFunctions.value(this, "composants_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Composants",
      "Composant",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "450px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ComposantsComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "composants_399769_value", "id"),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Are you sure you want to delete this record?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Composants/ComposantsComponent_505853_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.BilesenDeleteById = result?.data.bilesenDeleteById;
      stateVars.BilesenCount = result?.data.bilesenCount;
      stateVars.BilesenSearch = result?.data.bilesenSearch;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ComposantsComponent_505853_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ComposantsComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.BilesenSearch;
    formVars.composants_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.BilesenCount?.length > 0
        ? stateVars.BilesenCount[0].count
        : this.state.BilesenCount?.length > 0
        ? this.state.BilesenCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ComposantsComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "composants_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Composants/ComposantsComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BilesenCount = result?.data.bilesenCount;
    stateVars.BilesenSearch = result?.data.bilesenSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ComposantsComponent_172599_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ComposantsComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.BilesenSearch;
    formVars.composants_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.BilesenCount?.length > 0
        ? stateVars.BilesenCount[0].count
        : this.state.BilesenCount?.length > 0
        ? this.state.BilesenCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [549210, 192768, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ComposantsPageInit();
    }
  }
}
