import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  BarChart,
  Button,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDrawer,
  KPanel,
  KRow,
  KTab,
  KTabItem,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  MultiSelect,
  Switch,
  VerticalStack
} from "../../components/web-components";
import { KRadioButton, KRadioGroup, KSelectBox } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { PersonCard_ScreenBase } from "./personcard-base";

import { CheckupF_Screen } from "../checkupf/checkupf";
import { CheckupReport_Screen } from "../checkupreport/checkupreport";
import { CheckupReport3_Screen } from "../checkupreport3/checkupreport3";
import { EkTestF_Screen } from "../ektestf/ektestf";
import { EkTestGecmisi_Screen } from "../ektestgecmisi/ektestgecmisi";
import { KrediTransferi_Screen } from "../kreditransferi/kreditransferi";
import { KrediYukle_Screen } from "../krediyukle/krediyukle";
import { ParQFormcopy_Screen } from "../parqformcopy/parqformcopy";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class PersonCard_Screen extends PersonCard_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              personcard_320842_value: "cea5d764-20eb-47e9-b3a0-08dc41da3488",
              personcard_542065_value: "6198ce14-f34a-4e34-b3a1-08dc41da3488",
              personcard_703229_value: this.state.KisiSonCheckupProtokolu?.at?.(0)?.protokolId ?? undefined,
              personcard_470071_value: "81411694-dd2a-49cf-b296-08dc580083ad",
              personcard_879779_value: undefined,
              personcard_702470_value: this.state.KisiCheckupBilesenleri?.at?.(0)?.bilesenId ?? undefined,
              personcard_340341_value: "33a9607b-d874-4b8e-56e9-08dc3f3e7417",
              personcard_40070_value: "7df2e8e0-8ee0-4035-56ea-08dc3f3e7417",
              personcard_161606_value: "fc1b744f-4409-4131-56eb-08dc3f3e7417",
              personcard_211053_value: "3",
              personcard_39371_value: "40",
              personcard_464517_value: "165cd63d-bfac-4128-c7a9-08dc41ea0a4e",
              personcard_296047_value: this.state.SelectBasvuruEgitmen?.at?.(0)?.foto ?? undefined,
              personcard_802342_value: this.state.KisiSelectById?.at?.(0)?.yetkiliHizmetIds ?? undefined,
              personcard_551151_value: this.state.SelectBasvuruEgitmen?.at?.(0)?.memapYer ?? undefined,
              personcard_798203_value: this.state.SelectBasvuruEgitmen?.at?.(0)?.memapFayda ?? undefined
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="personcard_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 5,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              >
                <KRow
                  id="201603"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="866776"
                    xs={18}
                    sm={18}
                    md={18}
                    lg={18}
                    xl={18}
                    xxl={18}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="910191"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <Icon
                        id="805655"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.PersonCardComponent_805655_onClick();
                        }}
                        showCursorPointer
                        iconName="arrow_back"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "20px",
                            color: "rgba(155, 155, 155, 1)",
                            letterSpacing: "1px"
                          } as any
                        }
                      ></Icon>

                      <HorizontalStack
                        id="834814"
                        direction="horizontal"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="personcard_320842_value">
                          <Image
                            id="320842"
                            visibility={this.state.isComp320842Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isEqualTo(this.state.KisiSelectById[0]?.cinsiyet?.toString(), "E")
                            }
                            zoomOnClick={false}
                            imageFit="fit"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                height: "20px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Image>
                        </Form.Item>

                        <Form.Item className="kFormItem" name="personcard_542065_value">
                          <Image
                            id="542065"
                            visibility={this.state.isComp542065Visible}
                            visibilityCondition={(rowData: object) =>
                              ReactSystemFunctions.isEqualTo(this.state.KisiSelectById[0]?.cinsiyet?.toString(), "K")
                            }
                            zoomOnClick={false}
                            imageFit="fit"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                height: "20px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Image>
                        </Form.Item>

                        <HorizontalStack
                          id="119647"
                          direction="horizontal"
                          size={4}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 4,
                              paddingRight: 0,
                              paddingBottom: 4,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="640843"
                            value={this.state.KisiSelectById?.at?.(0)?.adi ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="152914"
                            value={this.state.KisiSelectById?.at?.(0)?.soyadi ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <HorizontalStack
                          id="785417"
                          direction="horizontal"
                          size={2}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="122247"
                            value={ReactSystemFunctions.translate(this.ml, 122247, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "10px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="313997"
                            value={this.state.KisiSelectById?.at?.(0)?.yas ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="179008"
                            value={ReactSystemFunctions.translate(this.ml, 179008, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "10px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>
                      </HorizontalStack>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="348288"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="personcard_703229_value">
                      <KSelectBox
                        id="703229"
                        onChange={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.PersonCardComponent_703229_onChange();
                        }}
                        kuikaRef={this.personcard_703229_value_kuikaSelectBoxRef}
                        options={this.state.KisiCheckupProtokolleri}
                        placeholder={ReactSystemFunctions.translate(this.ml, 703229, "placeholder", this.defaultML)}
                        allowClear={false}
                        autoClearSearchValue={true}
                        showSearch={false}
                        widthType="fill"
                        containsNullItem={false}
                        sortBy="none"
                        datavaluefield="protokolId"
                        datatextfield="protokolAdi"
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></KSelectBox>
                    </Form.Item>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="personcard_body"
                style={
                  {
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="918067"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="993582"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="543635"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "flex-end",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <VerticalStack
                        id="735571"
                        visibility={this.state.isComp735571Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "0"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="922015"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="428415"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="512012"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="327360"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen1Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="441256"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="542190"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="211038"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen1Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="144418"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen1Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="363331"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="427734"
                                visibility={this.state.isComp427734Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen1IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="627849"
                                visibility={this.state.isComp627849Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen1IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="36088"
                                visibility={this.state.isComp36088Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen1IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="598455"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen1Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="818771"
                        visibility={this.state.isComp818771Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "1"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="406868"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="48142"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderBottomWidth: 1,
                                borderColor: "rgba(140, 140, 140, 1)",
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="467415"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="357392"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen2Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="908286"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="817915"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="997297"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen2Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="962491"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen2Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="533566"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="142319"
                                visibility={this.state.isComp142319Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen2IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="222946"
                                visibility={this.state.isComp222946Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen2IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="98387"
                                visibility={this.state.isComp98387Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen2IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="592523"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen2Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="446402"
                        visibility={this.state.isComp446402Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "2"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="871485"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="244886"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="840357"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="658734"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen3Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="751913"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="392084"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="25555"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen3Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="667910"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen3Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="665900"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="74481"
                                visibility={this.state.isComp74481Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen3IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="517796"
                                visibility={this.state.isComp517796Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen3IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="238074"
                                visibility={this.state.isComp238074Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen3IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="453982"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen3Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="386981"
                        visibility={this.state.isComp386981Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "3"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="839421"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="75605"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="128498"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="490016"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen4Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="263711"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="851040"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="343790"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen4Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="999283"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen4Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="779792"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="955426"
                                visibility={this.state.isComp955426Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen4IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="573165"
                                visibility={this.state.isComp573165Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen4IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="712032"
                                visibility={this.state.isComp712032Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen4IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="870222"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen4Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="456460"
                        visibility={this.state.isComp456460Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "4"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="473329"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="618034"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="773064"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="330982"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen5Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="374867"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="558076"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="723972"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen5Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="206133"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen5Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="854492"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="830535"
                                visibility={this.state.isComp830535Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen5IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="709495"
                                visibility={this.state.isComp709495Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen5IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="924193"
                                visibility={this.state.isComp924193Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen5IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="456557"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen5Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="824502"
                        visibility={this.state.isComp824502Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "5"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="927467"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="64424"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="344941"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingRight: 0,
                                  paddingBottom: 4,
                                  paddingLeft: 0,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="960004"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen6Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="632315"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="447418"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="262452"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen6Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="171927"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen6Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="22856"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="893443"
                                visibility={this.state.isComp893443Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen6IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="598572"
                                visibility={this.state.isComp598572Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen6IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="449370"
                                visibility={this.state.isComp449370Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen6IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="275807"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen6Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="57776"
                        visibility={this.state.isComp57776Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "6"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="704329"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="236226"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="540818"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="699661"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen7Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="605799"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="269485"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="782898"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen7Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="677416"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen7Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="410669"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="338549"
                                visibility={this.state.isComp338549Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen7IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="522321"
                                visibility={this.state.isComp522321Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen7IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="434984"
                                visibility={this.state.isComp434984Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen7IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="354184"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen7Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="163026"
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="854256"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="268299"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="157042"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="896455"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.fitSkorAdi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="106556"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="937081"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="714865"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.fitSkorVal1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="656078"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.fitSkorVal2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="396121"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="161828"
                                visibility={this.state.isComp161828Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.fitSkorIsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="273336"
                                visibility={this.state.isComp273336Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.fitSkorIsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="524096"
                                visibility={this.state.isComp524096Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.fitSkorIsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="288450"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.fitSkorVal3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="128719"
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="323275"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 16,
                              paddingLeft: 16,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Form.Item className="kFormItem" name="personcard_470071_value">
                            <Image
                              id="470071"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.PersonCardComponent_470071_onClick();
                              }}
                              showCursorPointer
                              zoomOnClick={false}
                              imageFit="fit"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  height: "80px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></Image>
                          </Form.Item>
                        </KPanel>
                      </VerticalStack>
                    </HorizontalStack>

                    <HorizontalStack
                      id="227349"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <VerticalStack
                        id="420641"
                        visibility={this.state.isComp420641Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "7"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="863908"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="134782"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="318735"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="750354"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen8Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="381463"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="518393"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="717005"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen8Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="841994"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen8Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="661275"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="78756"
                                visibility={this.state.isComp78756Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen8IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="303063"
                                visibility={this.state.isComp303063Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen8IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="420323"
                                visibility={this.state.isComp420323Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen8IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="241365"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen8Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="524924"
                        visibility={this.state.isComp524924Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "8"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="384698"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="448678"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="830211"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="910562"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen9Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="274290"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="592047"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="923616"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen9Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="157993"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen9Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="390942"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="418834"
                                visibility={this.state.isComp418834Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen9IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="802760"
                                visibility={this.state.isComp802760Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen9IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="193852"
                                visibility={this.state.isComp193852Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen8IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="43927"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen9Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="526112"
                        visibility={this.state.isComp526112Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "9"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="344752"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="354989"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="687964"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="156520"
                                value={this.state.KisiProtokolSonDurum?.at?.(-1)?.bilesen10Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="64293"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="819871"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="333745"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen10Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="705132"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen10Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="676543"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="753567"
                                visibility={this.state.isComp753567Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen10IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="703182"
                                visibility={this.state.isComp703182Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen10IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="372424"
                                visibility={this.state.isComp372424Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen10IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="668156"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen10Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="973684"
                        visibility={this.state.isComp973684Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "10"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="495770"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="592518"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="671089"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="786453"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen11Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="811384"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="365640"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="569873"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen11Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="941843"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen11Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="494963"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="812300"
                                visibility={this.state.isComp812300Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen11IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="271000"
                                visibility={this.state.isComp271000Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen11IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="836912"
                                visibility={this.state.isComp836912Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen11IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="71576"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen11Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="122401"
                        visibility={this.state.isComp122401Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "11"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="830315"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="672360"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="463965"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="433063"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen12Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="639660"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="620786"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="387408"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen12Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="543796"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen12Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="707950"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="209066"
                                visibility={this.state.isComp209066Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen12IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="141414"
                                visibility={this.state.isComp141414Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen12IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="750815"
                                visibility={this.state.isComp750815Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen12IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="468041"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen12Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>

                      <VerticalStack
                        id="627968"
                        visibility={this.state.isComp627968Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isGreaterThan(
                            this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                            "12"
                          )
                        }
                        direction="vertical"
                        size={12}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KPanel
                          id="358839"
                          scrolling="hidden"
                          scrollRadius={false}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(140, 140, 140, 1)",
                              borderStyle: "solid",
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              width: "165px",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="292591"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="227148"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(140, 140, 140, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 4,
                                  paddingBottom: 4,
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="339049"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen13Adi ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "160px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>

                          <KRow
                            id="490187"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="27291"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="613210"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen13Val1 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Label
                                id="755444"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen13Val2 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(140, 140, 140, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>

                            <KCol
                              id="615773"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="854157"
                                visibility={this.state.isComp854157Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen13IsAugmented?.toString(),
                                    "-1"
                                  )
                                }
                                iconName="trending_down"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(192, 0, 1, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="738176"
                                visibility={this.state.isComp738176Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen13IsAugmented?.toString(),
                                    "1"
                                  )
                                }
                                iconName="trending_up"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(82, 196, 26, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Icon
                                id="818465"
                                visibility={this.state.isComp818465Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiProtokolSonDurum[0]?.bilesen13IsAugmented?.toString(),
                                    "0"
                                  )
                                }
                                iconName="remove"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(250, 173, 20, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>

                              <Label
                                id="399100"
                                value={this.state.KisiProtokolSonDurum?.at?.(0)?.bilesen13Val3 ?? undefined}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    width: "60px",
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KCol>
                          </KRow>
                        </KPanel>
                      </VerticalStack>
                    </HorizontalStack>
                  </KCol>
                </KRow>

                <KRow
                  id="230471"
                  visibility={this.state.isComp230471Visible}
                  visibilityCondition={(rowData: object) =>
                    ReactSystemFunctions.isEqualTo(this.state.KisiSelectById[0]?.sozlesmeOnayliMi?.toString(), "0")
                  }
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 16,
                      paddingBottom: 0,
                      paddingLeft: 16,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="905400"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(245, 34, 45, 0.1)",
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="821887"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <Icon
                        id="26459"
                        iconName="warning_amber"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "20px",
                            color: "rgba(155, 155, 155, 1)",
                            letterSpacing: "1px"
                          } as any
                        }
                      ></Icon>

                      <Label
                        id="487611"
                        value={ReactSystemFunctions.translate(this.ml, 487611, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></Label>
                    </HorizontalStack>
                  </KCol>
                </KRow>

                <KRow
                  id="871039"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="747773"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="personcard_879779_value" noStyle>
                      <KTab
                        id="879779"
                        type="line"
                        size="default"
                        tabPosition="top"
                        selectedTabColor="#0078FF"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KTabItem
                          id="434658"
                          tab={ReactSystemFunctions.translate(this.ml, 434658, "tab", this.defaultML)}
                          forceRender={true}
                          key={434658}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="210597"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="203377"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="796569"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="95283"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="53737"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="158614"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="126976"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Button
                                id="974303"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.PersonCardComponent_974303_onClick();
                                }}
                                showCursorPointer
                                visibility={this.state.isComp974303Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.MyContext[0]?.txnEkleyebilirMi?.toString(),
                                    "1"
                                  ) &&
                                  ReactSystemFunctions.isNotEqualTo(
                                    this.state.KisiObservedMi[0]?.kisiObservedMi?.toString(),
                                    "1"
                                  )
                                }
                                editability={this.state.isComp974303Enabled}
                                editabilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiSelectById[0]?.sozlesmeOnayliMi?.toString(),
                                    "1"
                                  )
                                }
                                label={ReactSystemFunctions.translate(this.ml, 974303, "label", this.defaultML)}
                                size="middle"
                                loading={false}
                                ghost={false}
                                block={false}
                                htmlType="button"
                                iconPosition="left"
                                danger={false}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(0, 190, 214, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 16,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    color: "rgba(255, 255, 255, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Button>
                            </KCol>
                          </KRow>

                          <KRow
                            id="543451"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="702141"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KTable
                                id="843663"
                                kuikaRef={this.personcard_843663_value_kuikaTableRef}
                                form={this.props.form}
                                dataSource={this.state.KisiCheckuplari}
                                size="middle"
                                bordered={true}
                                loading={false}
                                transformedOnMobileResolution={false}
                                showHeader={true}
                                fixedHeader={false}
                                editable={false}
                                tableWidthMode="fit"
                                globalSearch={false}
                                searchable={false}
                                sorter={false}
                                filtering={false}
                                pagination={false}
                                pageSize={10}
                                showSizeChanger={false}
                                columnChooser={false}
                                resizableColumns={false}
                                striped={false}
                                stripedColor="#F5F7FA"
                                insertRowActive={false}
                                insertRowPosition="top"
                                showNoDataFound={true}
                                nodatafoundmessage={ReactSystemFunctions.translate(
                                  this.ml,
                                  843663,
                                  "nodatafoundmessage",
                                  this.defaultML
                                )}
                                multiSelect={false}
                                export={false}
                                editableAlwaysActive={false}
                                multiSelectScope="page"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KTableHeader
                                  id="841035"
                                  columnTitles={this.state.KisiCheckuplariHeader?.at?.(0)?.header ?? undefined}
                                  hideOnMobileResolution={false}
                                  textDirection="Default"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(10, 33, 64, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></KTableHeader>

                                <KTableRow
                                  id="662773"
                                  hoverFontColor="red"
                                  hoverBgColor="#F5F5F5"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KTableColumn
                                    id="191658"
                                    visibility={this.state.isComp191658Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isNotEmpty(
                                        this.state.MyContext[0]?.kirilim1Adi?.toString(),
                                        ""
                                      )
                                    }
                                    title={this.state.MyContext?.at?.(0)?.kirilim1Adi ?? undefined}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "200px",
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="18222"
                                      value="[datafield:kirilim1]"
                                      formatter=""
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="661755"
                                    visibility={this.state.isComp661755Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isNotEmpty(
                                        this.state.MyContext[0]?.kirilim2Adi?.toString(),
                                        ""
                                      )
                                    }
                                    title={this.state.MyContext?.at?.(0)?.kirilim2Adi ?? undefined}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "160px",
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="295818"
                                      value="[datafield:kirilim2]"
                                      formatter=""
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="11037"
                                    title={ReactSystemFunctions.translate(this.ml, 11037, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "100px",
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="730227"
                                      value="[datafield:checkupTarihi]"
                                      formatter="date"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="268173"
                                    title={ReactSystemFunctions.translate(this.ml, 268173, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="622863"
                                      value="[datafield:yas]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="365811"
                                    visibility={this.state.isComp365811Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "0"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 365811, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="85409"
                                      value="[datafield:bilesen1Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="327706"
                                    visibility={this.state.isComp327706Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "1"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 327706, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="566640"
                                      value="[datafield:bilesen2Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="541772"
                                    visibility={this.state.isComp541772Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "2"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 541772, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="89272"
                                      value="[datafield:bilesen3Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="989056"
                                    visibility={this.state.isComp989056Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "3"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 989056, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="396921"
                                      value="[datafield:bilesen4Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="954440"
                                    visibility={this.state.isComp954440Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "4"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 954440, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="686111"
                                      value="[datafield:bilesen5Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="344016"
                                    visibility={this.state.isComp344016Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "5"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 344016, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="45932"
                                      value="[datafield:bilesen6Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="343409"
                                    visibility={this.state.isComp343409Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "6"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 343409, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="290476"
                                      value="[datafield:bilesen7Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="666219"
                                    visibility={this.state.isComp666219Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "7"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 666219, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="27133"
                                      value="[datafield:bilesen8Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="891845"
                                    visibility={this.state.isComp891845Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "8"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 891845, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="763659"
                                      value="[datafield:bilesen9Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="631461"
                                    visibility={this.state.isComp631461Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "9"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 631461, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="672831"
                                      value="[datafield:bilesen10Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="177133"
                                    visibility={this.state.isComp177133Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "10"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 177133, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="669806"
                                      value="[datafield:bilesen11Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="270588"
                                    visibility={this.state.isComp270588Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "11"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 270588, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="292678"
                                      value="[datafield:bilesen12Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="108489"
                                    visibility={this.state.isComp108489Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isGreaterThan(
                                        this.state.KisiProtokolSonDurum[0]?.bilesenAdedi?.toString(),
                                        "12"
                                      )
                                    }
                                    title={ReactSystemFunctions.translate(this.ml, 108489, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="600356"
                                      value="[datafield:bilesen13Puan]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="975968"
                                    title={ReactSystemFunctions.translate(this.ml, 975968, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="926429"
                                      value="[datafield:fitSkor]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="616534"
                                    title={ReactSystemFunctions.translate(this.ml, 616534, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "120px",
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="208952"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="587740"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.PersonCardComponent_587740_onClick();
                                        }}
                                        showCursorPointer
                                        visibility={this.state.isComp587740Visible}
                                        iconName="remove_red_eye"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color: "rgba(0, 190, 214, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Icon
                                        id="734592"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.PersonCardComponent_734592_onClick();
                                        }}
                                        showCursorPointer
                                        iconName="remove_red_eye"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color: "rgba(0, 190, 214, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Icon
                                        id="368889"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.PersonCardComponent_368889_onClick();
                                        }}
                                        showCursorPointer
                                        visibility={this.state.isComp368889Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(rowData.guncellenebilirMi, "1") &&
                                          ReactSystemFunctions.isNotEqualTo(
                                            this.state.KisiObservedMi[0]?.kisiObservedMi?.toString(),
                                            "1"
                                          )
                                        }
                                        iconName="border_color"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color: "rgba(155, 155, 155, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Icon
                                        id="504776"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.PersonCardComponent_504776_onClick();
                                        }}
                                        showCursorPointer
                                        visibility={this.state.isComp504776Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(rowData.silinebilirMi, "1") &&
                                          ReactSystemFunctions.isNotEqualTo(
                                            this.state.KisiObservedMi[0]?.kisiObservedMi?.toString(),
                                            "1"
                                          )
                                        }
                                        iconName="delete"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color: "rgba(155, 155, 155, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>
                                    </HorizontalStack>
                                  </KTableColumn>
                                </KTableRow>
                              </KTable>
                            </KCol>
                          </KRow>
                        </KTabItem>

                        <KTabItem
                          id="995168"
                          visibility={this.state.isComp995168Visible}
                          visibilityCondition={(rowData: object) =>
                            ReactSystemFunctions.isEqualTo(
                              this.state.KisiObservedMi[0]?.kisiObservedMi?.toString(),
                              "0"
                            )
                          }
                          tab={ReactSystemFunctions.translate(this.ml, 995168, "tab", this.defaultML)}
                          forceRender={true}
                          key={995168}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="860523"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="698958"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="363002"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="567344"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="91545"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="570037"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Button
                                id="389332"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.PersonCardComponent_389332_onClick();
                                }}
                                showCursorPointer
                                visibility={this.state.isComp389332Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.MyContext[0]?.txnEkleyebilirMi?.toString(),
                                    "1"
                                  )
                                }
                                editability={this.state.isComp389332Enabled}
                                editabilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiSelectById[0]?.sozlesmeOnayliMi?.toString(),
                                    "1"
                                  )
                                }
                                label={ReactSystemFunctions.translate(this.ml, 389332, "label", this.defaultML)}
                                size="middle"
                                loading={false}
                                ghost={false}
                                block={false}
                                htmlType="button"
                                iconPosition="left"
                                danger={false}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(0, 190, 214, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 16,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    color: "rgba(255, 255, 255, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Button>
                            </KCol>

                            <KCol
                              id="120790"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>
                          </KRow>

                          <KRow
                            id="579946"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="431034"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KTable
                                id="670480"
                                kuikaRef={this.personcard_670480_value_kuikaTableRef}
                                form={this.props.form}
                                dataSource={this.state.FormParQByKisi}
                                size="middle"
                                bordered={true}
                                transformedOnMobileResolution={false}
                                showHeader={true}
                                loading={false}
                                nodatafoundmessage={ReactSystemFunctions.translate(
                                  this.ml,
                                  670480,
                                  "nodatafoundmessage",
                                  this.defaultML
                                )}
                                tableWidthMode="fit"
                                searchable={false}
                                sorter={false}
                                filtering={false}
                                pagination={false}
                                columnChooser={false}
                                striped={false}
                                stripedColor="#F5F7FA"
                                insertRowActive={false}
                                insertRowPosition="top"
                                multiSelect={false}
                                pageSize={10}
                                showSizeChanger={false}
                                fixedHeader={false}
                                resizableColumns={false}
                                editable={false}
                                globalSearch={false}
                                showNoDataFound={true}
                                export={false}
                                editableAlwaysActive={false}
                                multiSelectScope="page"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KTableHeader
                                  id="328797"
                                  hideOnMobileResolution={false}
                                  textDirection="Default"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(10, 33, 64, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></KTableHeader>

                                <KTableRow
                                  id="529156"
                                  hoverFontColor="red"
                                  hoverBgColor="#F5F5F5"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KTableColumn
                                    id="11761"
                                    title={ReactSystemFunctions.translate(this.ml, 11761, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "200px",
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="658808"
                                      value="[datafield:formTarih]"
                                      formatter="date"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="704384"
                                    title={ReactSystemFunctions.translate(this.ml, 704384, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="427972"
                                      visibility={this.state.isComp427972Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isEqualTo(rowData.sedanterDegil, "true")
                                      }
                                      value={ReactSystemFunctions.translate(this.ml, 427972, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="475091"
                                      visibility={this.state.isComp475091Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isEqualTo(rowData.sedanterDegil, "false")
                                      }
                                      value={ReactSystemFunctions.translate(this.ml, 475091, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="346795"
                                    title={ReactSystemFunctions.translate(this.ml, 346795, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="390107"
                                      visibility={this.state.isComp390107Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isEqualTo(rowData.semptom, "true")
                                      }
                                      value={ReactSystemFunctions.translate(this.ml, 390107, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="923815"
                                      visibility={this.state.isComp923815Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isEqualTo(rowData.semptom, "false")
                                      }
                                      value={ReactSystemFunctions.translate(this.ml, 923815, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="509004"
                                    title={ReactSystemFunctions.translate(this.ml, 509004, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="421899"
                                      visibility={this.state.isComp421899Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isEqualTo(rowData.s3, "true")
                                      }
                                      iconName="check_circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(82, 196, 26, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="825628"
                                      visibility={this.state.isComp825628Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(rowData.s3, "true")
                                      }
                                      iconName="dangerous"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(245, 34, 45, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="33592"
                                    title={ReactSystemFunctions.translate(this.ml, 33592, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="882027"
                                      direction="vertical"
                                      size={4}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="583842"
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="246088"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            246088,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Icon
                                          id="641546"
                                          visibility={this.state.isComp641546Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(rowData.s4x1, "true")
                                          }
                                          iconName="check_circle"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(82, 196, 26, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="292193"
                                          visibility={this.state.isComp292193Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isNotEqualTo(rowData.s4x1, "true")
                                          }
                                          iconName="dangerous"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(245, 34, 45, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>
                                      </HorizontalStack>

                                      <HorizontalStack
                                        id="843571"
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="353045"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            353045,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Icon
                                          id="68103"
                                          visibility={this.state.isComp68103Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(rowData.s4x2, "true")
                                          }
                                          iconName="check_circle"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(82, 196, 26, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="303224"
                                          visibility={this.state.isComp303224Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isNotEqualTo(rowData.s4x2, "true")
                                          }
                                          iconName="dangerous"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(245, 34, 45, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>
                                      </HorizontalStack>
                                    </VerticalStack>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="532356"
                                    title={ReactSystemFunctions.translate(this.ml, 532356, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="238370"
                                      visibility={this.state.isComp238370Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isEqualTo(rowData.s5, "true")
                                      }
                                      iconName="check_circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(82, 196, 26, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="349106"
                                      visibility={this.state.isComp349106Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(rowData.s5, "true")
                                      }
                                      iconName="dangerous"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(245, 34, 45, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="736763"
                                    title={ReactSystemFunctions.translate(this.ml, 736763, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="493373"
                                      visibility={this.state.isComp493373Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isEqualTo(rowData.s6, "true")
                                      }
                                      iconName="check_circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(82, 196, 26, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="987773"
                                      visibility={this.state.isComp987773Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(rowData.s6, "true")
                                      }
                                      iconName="dangerous"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(245, 34, 45, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="406373"
                                    title={ReactSystemFunctions.translate(this.ml, 406373, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="656371"
                                      visibility={this.state.isComp656371Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isEqualTo(rowData.s7, "true")
                                      }
                                      iconName="check_circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(82, 196, 26, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="74551"
                                      visibility={this.state.isComp74551Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(rowData.s7, "true")
                                      }
                                      iconName="dangerous"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(245, 34, 45, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="749732"
                                    title={ReactSystemFunctions.translate(this.ml, 749732, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="739595"
                                      visibility={this.state.isComp739595Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isEqualTo(rowData.s8, "true")
                                      }
                                      iconName="check_circle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(82, 196, 26, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="994383"
                                      visibility={this.state.isComp994383Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEqualTo(rowData.s8, "true")
                                      }
                                      iconName="dangerous"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(245, 34, 45, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="353673"
                                    title={ReactSystemFunctions.translate(this.ml, 353673, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="362318"
                                      value="[datafield:formSonuc1]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          width: "200px",
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="838421"
                                    title={ReactSystemFunctions.translate(this.ml, 838421, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="690844"
                                      value="[datafield:formSonuc2]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          width: "200px",
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="456364"
                                    title={ReactSystemFunctions.translate(this.ml, 456364, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "120px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="353129"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="687408"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.PersonCardComponent_687408_onClick();
                                        }}
                                        showCursorPointer
                                        iconName="remove_red_eye"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color: "rgba(0, 190, 214, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Icon
                                        id="631645"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.PersonCardComponent_631645_onClick();
                                        }}
                                        showCursorPointer
                                        iconName="border_color"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color: "rgba(155, 155, 155, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Icon
                                        id="219962"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.PersonCardComponent_219962_onClick();
                                        }}
                                        showCursorPointer
                                        iconName="clear"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color: "rgba(155, 155, 155, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>
                                    </HorizontalStack>
                                  </KTableColumn>
                                </KTableRow>
                              </KTable>
                            </KCol>
                          </KRow>
                        </KTabItem>

                        <KTabItem
                          id="849168"
                          tab={ReactSystemFunctions.translate(this.ml, 849168, "tab", this.defaultML)}
                          forceRender={true}
                          key={849168}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="301126"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="290036"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="788537"
                                value={ReactSystemFunctions.translate(this.ml, 788537, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>

                              <Form.Item className="kFormItem" name="personcard_702470_value">
                                <KSelectBox
                                  id="702470"
                                  onChange={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.PersonCardComponent_702470_onChange();
                                  }}
                                  kuikaRef={this.personcard_702470_value_kuikaSelectBoxRef}
                                  options={this.state.KisiCheckupBilesenleri}
                                  placeholder={ReactSystemFunctions.translate(
                                    this.ml,
                                    702470,
                                    "placeholder",
                                    this.defaultML
                                  )}
                                  allowClear={false}
                                  autoClearSearchValue={true}
                                  showSearch={false}
                                  widthType="fill"
                                  containsNullItem={false}
                                  sortBy="none"
                                  datavaluefield="bilesenId"
                                  datatextfield="bilesenAdi"
                                  style={
                                    {
                                      borderTopLeftRadius: 8,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></KSelectBox>
                              </Form.Item>
                            </KCol>

                            <KCol
                              id="334709"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 32,
                                  paddingRight: 32,
                                  paddingBottom: 8,
                                  paddingLeft: 32,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <BarChart
                                id="267342"
                                dataSource={this.state.KisiCheckupBilesenTarihcesi}
                                dataLabelKeyName="CheckupTarihi"
                                dataDisplayLabelKeyName="CheckupTarihi"
                                firstDataFieldKeyName="Puan"
                                firstDataBgColorKeyName="Renk"
                                leftAxis={true}
                                rightAxis={true}
                                beginAtZero={true}
                                showDataLabels={false}
                                legend=""
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    height: "300px",
                                    display: "block",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(10, 33, 64, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></BarChart>
                            </KCol>
                          </KRow>

                          <KRow
                            id="919825"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="267641"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingRight: 64,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="402167"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>
                          </KRow>
                        </KTabItem>

                        <KTabItem
                          id="669047"
                          onActive={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.PersonCardComponent_669047_onActive();
                          }}
                          visibility={this.state.isComp669047Visible}
                          visibilityCondition={(rowData: object) =>
                            ReactSystemFunctions.isEqualTo(
                              this.state.KisiObservedMi[0]?.kisiObservedMi?.toString(),
                              "0"
                            )
                          }
                          tab={ReactSystemFunctions.translate(this.ml, 669047, "tab", this.defaultML)}
                          forceRender={true}
                          key={669047}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="71921"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="159010"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="735788"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="420774"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="651200"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="639129"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></KCol>

                            <KCol
                              id="444026"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-right",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Button
                                id="677294"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.PersonCardComponent_677294_onClick();
                                }}
                                showCursorPointer
                                visibility={this.state.isComp677294Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.MyContext[0]?.txnEkleyebilirMi?.toString(),
                                    "1"
                                  )
                                }
                                editability={this.state.isComp677294Enabled}
                                editabilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(
                                    this.state.KisiSelectById[0]?.sozlesmeOnayliMi?.toString(),
                                    "1"
                                  )
                                }
                                label={ReactSystemFunctions.translate(this.ml, 677294, "label", this.defaultML)}
                                size="middle"
                                loading={false}
                                ghost={false}
                                block={false}
                                htmlType="button"
                                iconPosition="left"
                                danger={false}
                                style={
                                  {
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    borderBottomLeftRadius: 8,
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(0, 190, 214, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 16,
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    alignItems: "center",
                                    textAlign: "-webkit-center",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    color: "rgba(255, 255, 255, 1)",
                                    lineHeight: "22px"
                                  } as any
                                }
                              ></Button>
                            </KCol>
                          </KRow>

                          <KRow
                            id="883019"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="708018"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KTable
                                id="1293305"
                                kuikaRef={this.personcard_1293305_value_kuikaTableRef}
                                form={this.props.form}
                                dataSource={this.state.KisiEkTestleri}
                                size="middle"
                                bordered={true}
                                transformedOnMobileResolution={false}
                                showHeader={true}
                                loading={false}
                                nodatafoundmessage={ReactSystemFunctions.translate(
                                  this.ml,
                                  1293305,
                                  "nodatafoundmessage",
                                  this.defaultML
                                )}
                                tableWidthMode="fit"
                                searchable={false}
                                sorter={false}
                                filtering={false}
                                pagination={false}
                                columnChooser={false}
                                striped={true}
                                stripedColor="#F5F7FA"
                                insertRowActive={false}
                                insertRowPosition="top"
                                multiSelect={false}
                                pageSize={10}
                                showSizeChanger={false}
                                fixedHeader={false}
                                resizableColumns={false}
                                editable={false}
                                globalSearch={false}
                                showNoDataFound={true}
                                export={false}
                                editableAlwaysActive={false}
                                multiSelectScope="page"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KTableHeader
                                  id="1024053"
                                  hideOnMobileResolution={false}
                                  textDirection="Default"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(10, 33, 64, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></KTableHeader>

                                <KTableRow
                                  id="8339278"
                                  hoverFontColor="red"
                                  hoverBgColor="#F5F5F5"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      height: "30px",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KTableColumn
                                    id="2043624"
                                    title={ReactSystemFunctions.translate(this.ml, 2043624, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="3662414"
                                      value="[datafield:testTarihi]"
                                      formatter="date"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="1936218"
                                    title={ReactSystemFunctions.translate(this.ml, 1936218, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="2076860"
                                      value="[datafield:testAdi]"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="1247357"
                                    title={ReactSystemFunctions.translate(this.ml, 1247357, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="570957"
                                      direction="vertical"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="137269"
                                        visibility={this.state.isComp137269Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEmpty(rowData.girdi1Adi, "")
                                        }
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="340338"
                                          value="[datafield:girdi1Adi]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(140, 140, 140, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="1052734"
                                          value="[datafield:girdi1]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </HorizontalStack>

                                      <HorizontalStack
                                        id="85021"
                                        visibility={this.state.isComp85021Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEmpty(rowData.girdi2Adi, "")
                                        }
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="921182"
                                          value="[datafield:girdi2Adi]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(140, 140, 140, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="925581"
                                          value="[datafield:girdi2]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </HorizontalStack>

                                      <HorizontalStack
                                        id="249558"
                                        visibility={this.state.isComp249558Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEmpty(rowData.girdi3Adi, "")
                                        }
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="753983"
                                          value="[datafield:girdi3Adi]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(140, 140, 140, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="989099"
                                          value="[datafield:girdi3]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </HorizontalStack>

                                      <HorizontalStack
                                        id="495097"
                                        visibility={this.state.isComp495097Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEmpty(rowData.girdi4Adi, "")
                                        }
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="910695"
                                          value="[datafield:girdi4Adi]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(140, 140, 140, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="692747"
                                          value="[datafield:girdi4]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </HorizontalStack>
                                    </VerticalStack>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="999042"
                                    title={ReactSystemFunctions.translate(this.ml, 999042, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <VerticalStack
                                      id="344757"
                                      direction="vertical"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="834791"
                                        visibility={this.state.isComp834791Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEmpty(rowData.araSonuc1Adi, "")
                                        }
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="929793"
                                          value="[datafield:araSonuc1Adi]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(140, 140, 140, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="843549"
                                          value="[datafield:araSonuc1]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </HorizontalStack>

                                      <HorizontalStack
                                        id="819355"
                                        visibility={this.state.isComp819355Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEmpty(rowData.araSonuc2Adi, "")
                                        }
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="161566"
                                          value="[datafield:araSonuc2Adi]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(140, 140, 140, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="794358"
                                          value="[datafield:araSonuc2]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </HorizontalStack>

                                      <HorizontalStack
                                        id="291452"
                                        visibility={this.state.isComp291452Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEmpty(rowData.araSonuc3Adi, "")
                                        }
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="696730"
                                          value="[datafield:araSonuc3Adi]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(140, 140, 140, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="434974"
                                          value="[datafield:araSonuc3]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Label>
                                      </HorizontalStack>
                                    </VerticalStack>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="517338"
                                    title={ReactSystemFunctions.translate(this.ml, 517338, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="456374"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="971318"
                                        value="[datafield:gecmis]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>

                                      <Icon
                                        id="477648"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.PersonCardComponent_477648_onClick();
                                        }}
                                        showCursorPointer
                                        iconName="arrow_forward_ios"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color: "rgba(155, 155, 155, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>
                                    </HorizontalStack>
                                  </KTableColumn>
                                </KTableRow>
                              </KTable>
                            </KCol>
                          </KRow>
                        </KTabItem>

                        <KTabItem
                          id="899495"
                          onActive={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.PersonCardComponent_899495_onActive();
                          }}
                          visibility={this.state.isComp899495Visible}
                          visibilityCondition={(rowData: object) =>
                            ReactSystemFunctions.isEqualTo(
                              this.state.KisiObservedMi[0]?.kisiObservedMi?.toString(),
                              "0"
                            )
                          }
                          tab={ReactSystemFunctions.translate(this.ml, 899495, "tab", this.defaultML)}
                          forceRender={true}
                          key={899495}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="222312"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="431163"
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              xxl={4}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="221079"
                                direction="vertical"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KPanel
                                  id="168889"
                                  scrolling="hidden"
                                  scrollRadius={false}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="284707"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="981545"
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                      xl={8}
                                      xxl={8}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="personcard_340341_value">
                                        <Image
                                          id="340341"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              height: "60px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>
                                    </KCol>

                                    <KCol
                                      id="338824"
                                      xs={16}
                                      sm={16}
                                      md={16}
                                      lg={16}
                                      xl={16}
                                      xxl={16}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="797227"
                                        value={ReactSystemFunctions.translate(this.ml, 797227, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="644887"
                                        value={this.state.ReceteBilesenPuanlariHesapla?.at?.(0)?.kalpGucu ?? undefined}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "24px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>
                                  </KRow>
                                </KPanel>

                                <KPanel
                                  id="403938"
                                  scrolling="hidden"
                                  scrollRadius={false}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="590165"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="753768"
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                      xl={8}
                                      xxl={8}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="personcard_40070_value">
                                        <Image
                                          id="40070"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              height: "60px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>
                                    </KCol>

                                    <KCol
                                      id="331855"
                                      xs={16}
                                      sm={16}
                                      md={16}
                                      lg={16}
                                      xl={16}
                                      xxl={16}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="113491"
                                        value={ReactSystemFunctions.translate(this.ml, 113491, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="111350"
                                        value={this.state.ReceteBilesenPuanlariHesapla?.at?.(0)?.esneklik ?? undefined}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "24px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>
                                  </KRow>
                                </KPanel>

                                <KPanel
                                  id="898564"
                                  scrolling="hidden"
                                  scrollRadius={false}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="525928"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="552325"
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                      xl={8}
                                      xxl={8}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="personcard_161606_value">
                                        <Image
                                          id="161606"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              height: "60px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>
                                    </KCol>

                                    <KCol
                                      id="954243"
                                      xs={16}
                                      sm={16}
                                      md={16}
                                      lg={16}
                                      xl={16}
                                      xxl={16}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="153545"
                                        value={ReactSystemFunctions.translate(this.ml, 153545, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-center",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="293313"
                                        value={this.state.ReceteBilesenPuanlariHesapla?.at?.(0)?.kuvvet ?? undefined}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "24px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>
                                  </KRow>
                                </KPanel>
                              </VerticalStack>
                            </KCol>

                            <KCol
                              id="194161"
                              xs={20}
                              sm={20}
                              md={20}
                              lg={20}
                              xl={20}
                              xxl={20}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 0,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KRow
                                id="130459"
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="575296"
                                  xs={4}
                                  sm={4}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  xxl={4}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="361721"
                                    value={ReactSystemFunctions.translate(this.ml, 361721, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Label
                                    id="111248"
                                    value={this.state.ReceteBilesenPuanlariHesapla?.at?.(0)?.strateji ?? undefined}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "36px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </KCol>

                                <KCol
                                  id="972915"
                                  xs={10}
                                  sm={10}
                                  md={10}
                                  lg={10}
                                  xl={10}
                                  xxl={10}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="431647"
                                    value={ReactSystemFunctions.translate(this.ml, 431647, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Form.Item className="kFormItem" name="personcard_211053_value">
                                    <KRadioGroup
                                      id="211053"
                                      onChange={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.PersonCardComponent_211053_onChange();
                                      }}
                                      disabled={false}
                                      space={4}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 0,
                                          paddingBottom: 8,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <KRadioButton
                                        id="195414"
                                        value="1"
                                        label="1"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>

                                      <KRadioButton
                                        id="659276"
                                        value="2"
                                        label="2"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>

                                      <KRadioButton
                                        id="640903"
                                        value="3"
                                        label="3"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>

                                      <KRadioButton
                                        id="934912"
                                        value="4"
                                        label="4"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>

                                      <KRadioButton
                                        id="330174"
                                        value="5"
                                        label="5"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>

                                      <KRadioButton
                                        id="401072"
                                        value="6"
                                        label="6"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>

                                      <KRadioButton
                                        id="767147"
                                        value="7"
                                        label="7"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>
                                    </KRadioGroup>
                                  </Form.Item>
                                </KCol>

                                <KCol
                                  id="479861"
                                  xs={10}
                                  sm={10}
                                  md={10}
                                  lg={10}
                                  xl={10}
                                  xxl={10}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 0,
                                      paddingBottom: 8,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <Label
                                    id="641210"
                                    value={ReactSystemFunctions.translate(this.ml, 641210, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 5,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  ></Label>

                                  <Form.Item className="kFormItem" name="personcard_39371_value">
                                    <KRadioGroup
                                      id="39371"
                                      onChange={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.PersonCardComponent_39371_onChange();
                                      }}
                                      disabled={false}
                                      space={4}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 0,
                                          paddingBottom: 8,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <KRadioButton
                                        id="964550"
                                        value="30"
                                        label="30"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>

                                      <KRadioButton
                                        id="163056"
                                        value="40"
                                        label="40"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>

                                      <KRadioButton
                                        id="588708"
                                        value="50"
                                        label="50"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>

                                      <KRadioButton
                                        id="735080"
                                        value="60"
                                        label="60"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>

                                      <KRadioButton
                                        id="682482"
                                        value="70"
                                        label="70"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>

                                      <KRadioButton
                                        id="318088"
                                        value="80"
                                        label="80"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>

                                      <KRadioButton
                                        id="806528"
                                        value="90"
                                        label="90"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid"
                                          } as any
                                        }
                                      ></KRadioButton>
                                    </KRadioGroup>
                                  </Form.Item>
                                </KCol>
                              </KRow>

                              <KRow
                                id="480431"
                                visibility={this.state.isComp480431Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isGreaterThan(
                                    this.state.StratejiUret[0]?.frekans?.toString(),
                                    "0"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="704137"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="588142"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="345509"
                                      xs={4}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      xxl={4}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-right",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="847034"
                                        value={ReactSystemFunctions.translate(this.ml, 847034, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="957923"
                                      xs={20}
                                      sm={20}
                                      md={20}
                                      lg={20}
                                      xl={20}
                                      xxl={20}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="227073"
                                        direction="horizontal"
                                        size={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <KPanel
                                          id="26445"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(192, 0, 1, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="894262"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              894262,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="178136"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="343143"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="613634"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="775907"
                                              value={this.state.StratejiUret?.at?.(0)?.label1 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="347799"
                                            value={this.state.StratejiUret?.at?.(0)?.l1 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="396495"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="538837"
                                              value={this.state.StratejiUret?.at?.(0)?.detay1 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="971308"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="780783"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              780783,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="341111"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(0, 190, 214, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="653094"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              653094,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="300771"
                                          direction="vertical"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <VerticalStack
                                            id="654206"
                                            direction="vertical"
                                            size={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundColor: "rgba(217, 217, 217, 1)",
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 8,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="353736"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Icon
                                                id="418282"
                                                iconName="looks_one"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "20px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    letterSpacing: "1px"
                                                  } as any
                                                }
                                              ></Icon>

                                              <Label
                                                id="885114"
                                                value={this.state.StratejiUret?.at?.(0)?.label2 ?? undefined}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>

                                            <Label
                                              id="965597"
                                              value={this.state.StratejiUret?.at?.(0)?.l2 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 4,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(217, 217, 217, 1)"
                                                } as any
                                              }
                                            ></Label>

                                            <HorizontalStack
                                              id="728108"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 16,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="521814"
                                                value={this.state.StratejiUret?.at?.(0)?.detay2 ?? undefined}
                                                style={
                                                  {
                                                    borderTopLeftRadius: 8,
                                                    borderTopRightRadius: 8,
                                                    borderBottomRightRadius: 8,
                                                    borderBottomLeftRadius: 8,
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    paddingTop: 4,
                                                    paddingRight: 8,
                                                    paddingBottom: 4,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </VerticalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="859492"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="117653"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              117653,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="234690"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(250, 173, 20, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="263926"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              263926,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="777398"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="917790"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="17437"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="65357"
                                              value={this.state.StratejiUret?.at?.(0)?.label3 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="138937"
                                            value={this.state.StratejiUret?.at?.(0)?.l3 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="810632"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="163583"
                                              value={this.state.StratejiUret?.at?.(0)?.detay3 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>
                                      </HorizontalStack>
                                    </KCol>
                                  </KRow>
                                </KCol>
                              </KRow>

                              <KRow
                                id="13127"
                                visibility={this.state.isComp13127Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isGreaterThan(
                                    this.state.StratejiUret[0]?.frekans?.toString(),
                                    "1"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="124768"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="576809"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="481629"
                                      xs={4}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      xxl={4}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-right",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="665508"
                                        value={ReactSystemFunctions.translate(this.ml, 665508, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="750686"
                                      xs={20}
                                      sm={20}
                                      md={20}
                                      lg={20}
                                      xl={20}
                                      xxl={20}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="568262"
                                        direction="horizontal"
                                        size={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <KPanel
                                          id="723191"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(192, 0, 1, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="981282"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              981282,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="882328"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="831028"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="604205"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="835090"
                                              value={this.state.StratejiUret?.at?.(0)?.g2_Label1 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="358734"
                                            value={this.state.StratejiUret?.at?.(0)?.g2_L1 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="971492"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="489625"
                                              value={this.state.StratejiUret?.at?.(0)?.g2_Detay1 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="111664"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="75294"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              75294,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="135531"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(0, 190, 214, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="797639"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              797639,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="995106"
                                          direction="vertical"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <VerticalStack
                                            id="864118"
                                            direction="vertical"
                                            size={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundColor: "rgba(217, 217, 217, 1)",
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 8,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="683194"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Icon
                                                id="147931"
                                                iconName="looks_one"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "20px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    letterSpacing: "1px"
                                                  } as any
                                                }
                                              ></Icon>

                                              <Label
                                                id="48054"
                                                value={this.state.StratejiUret?.at?.(0)?.g2_Label2 ?? undefined}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>

                                            <Label
                                              id="297361"
                                              value={this.state.StratejiUret?.at?.(0)?.g2_L2 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 4,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(217, 217, 217, 1)"
                                                } as any
                                              }
                                            ></Label>

                                            <HorizontalStack
                                              id="23950"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 16,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="434314"
                                                value={this.state.StratejiUret?.at?.(0)?.g2_Detay2 ?? undefined}
                                                style={
                                                  {
                                                    borderTopLeftRadius: 8,
                                                    borderTopRightRadius: 8,
                                                    borderBottomRightRadius: 8,
                                                    borderBottomLeftRadius: 8,
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    paddingTop: 4,
                                                    paddingRight: 8,
                                                    paddingBottom: 4,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </VerticalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="844154"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="28403"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              28403,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="274220"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(250, 173, 20, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="131001"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              131001,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="377043"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="725590"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="194760"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="145588"
                                              value={this.state.StratejiUret?.at?.(0)?.g2_Label3 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="868545"
                                            value={this.state.StratejiUret?.at?.(0)?.g2_L3 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="26867"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="432098"
                                              value={this.state.StratejiUret?.at?.(0)?.g2_Detay3 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>
                                      </HorizontalStack>
                                    </KCol>
                                  </KRow>
                                </KCol>
                              </KRow>

                              <KRow
                                id="91061"
                                visibility={this.state.isComp91061Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isGreaterThan(
                                    this.state.StratejiUret[0]?.frekans?.toString(),
                                    "2"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="341324"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="54289"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="952468"
                                      xs={4}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      xxl={4}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-right",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="522665"
                                        value={ReactSystemFunctions.translate(this.ml, 522665, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="900983"
                                      xs={20}
                                      sm={20}
                                      md={20}
                                      lg={20}
                                      xl={20}
                                      xxl={20}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="977328"
                                        direction="horizontal"
                                        size={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <KPanel
                                          id="287338"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(192, 0, 1, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="796672"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              796672,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="582367"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="156810"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="805504"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="887087"
                                              value={this.state.StratejiUret?.at?.(0)?.g3_Label1 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="521491"
                                            value={this.state.StratejiUret?.at?.(0)?.g3_L1 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="993143"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="740059"
                                              value={this.state.StratejiUret?.at?.(0)?.g3_Detay1 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="101034"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="325290"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              325290,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="528113"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(0, 190, 214, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="491201"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              491201,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="215077"
                                          direction="vertical"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <VerticalStack
                                            id="282182"
                                            direction="vertical"
                                            size={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundColor: "rgba(217, 217, 217, 1)",
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 8,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="21102"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Icon
                                                id="797312"
                                                iconName="looks_one"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "20px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    letterSpacing: "1px"
                                                  } as any
                                                }
                                              ></Icon>

                                              <Label
                                                id="714773"
                                                value={this.state.StratejiUret?.at?.(0)?.g3_Label2 ?? undefined}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>

                                            <Label
                                              id="885883"
                                              value={this.state.StratejiUret?.at?.(0)?.g3_L2 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 4,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(217, 217, 217, 1)"
                                                } as any
                                              }
                                            ></Label>

                                            <HorizontalStack
                                              id="512747"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 16,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="558594"
                                                value={this.state.StratejiUret?.at?.(0)?.g3_Detay2 ?? undefined}
                                                style={
                                                  {
                                                    borderTopLeftRadius: 8,
                                                    borderTopRightRadius: 8,
                                                    borderBottomRightRadius: 8,
                                                    borderBottomLeftRadius: 8,
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    paddingTop: 4,
                                                    paddingRight: 8,
                                                    paddingBottom: 4,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </VerticalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="220612"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="471552"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              471552,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="861671"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(250, 173, 20, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="105120"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              105120,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="420966"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="420679"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="938192"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="338187"
                                              value={this.state.StratejiUret?.at?.(0)?.g3_Label3 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="161152"
                                            value={this.state.StratejiUret?.at?.(0)?.g3_L3 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="890891"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="193584"
                                              value={this.state.StratejiUret?.at?.(0)?.g3_Detay3 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>
                                      </HorizontalStack>
                                    </KCol>
                                  </KRow>
                                </KCol>
                              </KRow>

                              <KRow
                                id="68062"
                                visibility={this.state.isComp68062Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isGreaterThan(
                                    this.state.StratejiUret[0]?.frekans?.toString(),
                                    "3"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="871963"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="532654"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="161786"
                                      xs={4}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      xxl={4}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-right",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="340315"
                                        value={ReactSystemFunctions.translate(this.ml, 340315, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="89017"
                                      xs={20}
                                      sm={20}
                                      md={20}
                                      lg={20}
                                      xl={20}
                                      xxl={20}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="309188"
                                        direction="horizontal"
                                        size={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <KPanel
                                          id="7612"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(192, 0, 1, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="680492"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              680492,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="222071"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="783036"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="619924"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="148587"
                                              value={this.state.StratejiUret?.at?.(0)?.g4_Label1 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="895889"
                                            value={this.state.StratejiUret?.at?.(0)?.g4_L1 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="803292"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="371726"
                                              value={this.state.StratejiUret?.at?.(0)?.g4_Detay1 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="250317"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="812072"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              812072,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="852688"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(0, 190, 214, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="898199"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              898199,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="910916"
                                          direction="vertical"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <VerticalStack
                                            id="489427"
                                            direction="vertical"
                                            size={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundColor: "rgba(217, 217, 217, 1)",
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 8,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="832770"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Icon
                                                id="563886"
                                                iconName="looks_one"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "20px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    letterSpacing: "1px"
                                                  } as any
                                                }
                                              ></Icon>

                                              <Label
                                                id="105723"
                                                value={this.state.StratejiUret?.at?.(0)?.g4_Label2 ?? undefined}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>

                                            <Label
                                              id="846253"
                                              value={this.state.StratejiUret?.at?.(0)?.g4_L2 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 4,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(217, 217, 217, 1)"
                                                } as any
                                              }
                                            ></Label>

                                            <HorizontalStack
                                              id="540455"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 16,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="395840"
                                                value={this.state.StratejiUret?.at?.(0)?.g4_Detay2 ?? undefined}
                                                style={
                                                  {
                                                    borderTopLeftRadius: 8,
                                                    borderTopRightRadius: 8,
                                                    borderBottomRightRadius: 8,
                                                    borderBottomLeftRadius: 8,
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    paddingTop: 4,
                                                    paddingRight: 8,
                                                    paddingBottom: 4,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </VerticalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="478901"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="405921"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              405921,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="297541"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(250, 173, 20, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="855616"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              855616,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="550728"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="908046"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="235809"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="603649"
                                              value={this.state.StratejiUret?.at?.(0)?.g4_Label3 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="2815"
                                            value={this.state.StratejiUret?.at?.(0)?.g4_L3 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="274610"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="883218"
                                              value={this.state.StratejiUret?.at?.(0)?.g4_Detay3 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>
                                      </HorizontalStack>
                                    </KCol>
                                  </KRow>
                                </KCol>
                              </KRow>

                              <KRow
                                id="709685"
                                visibility={this.state.isComp709685Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isGreaterThan(
                                    this.state.StratejiUret[0]?.frekans?.toString(),
                                    "4"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="321708"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="363219"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="238971"
                                      xs={4}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      xxl={4}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-right",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="498494"
                                        value={ReactSystemFunctions.translate(this.ml, 498494, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="422278"
                                      xs={20}
                                      sm={20}
                                      md={20}
                                      lg={20}
                                      xl={20}
                                      xxl={20}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="916438"
                                        direction="horizontal"
                                        size={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <KPanel
                                          id="873733"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(192, 0, 1, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="959496"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              959496,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="546423"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="44809"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="44720"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="922089"
                                              value={this.state.StratejiUret?.at?.(0)?.g5_Label1 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="246082"
                                            value={this.state.StratejiUret?.at?.(0)?.g5_L1 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="714796"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="92428"
                                              value={this.state.StratejiUret?.at?.(0)?.g5_Detay1 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="288440"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="577062"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              577062,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="357872"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(0, 190, 214, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="574505"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              574505,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="889357"
                                          direction="vertical"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <VerticalStack
                                            id="495842"
                                            direction="vertical"
                                            size={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundColor: "rgba(217, 217, 217, 1)",
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 8,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="743038"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Icon
                                                id="185923"
                                                iconName="looks_one"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "20px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    letterSpacing: "1px"
                                                  } as any
                                                }
                                              ></Icon>

                                              <Label
                                                id="192797"
                                                value={this.state.StratejiUret?.at?.(0)?.g5_Label2 ?? undefined}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>

                                            <Label
                                              id="806011"
                                              value={this.state.StratejiUret?.at?.(0)?.g5_L2 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 4,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(217, 217, 217, 1)"
                                                } as any
                                              }
                                            ></Label>

                                            <HorizontalStack
                                              id="198030"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 16,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="857336"
                                                value={this.state.StratejiUret?.at?.(0)?.g5_Detay2 ?? undefined}
                                                style={
                                                  {
                                                    borderTopLeftRadius: 8,
                                                    borderTopRightRadius: 8,
                                                    borderBottomRightRadius: 8,
                                                    borderBottomLeftRadius: 8,
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    paddingTop: 4,
                                                    paddingRight: 8,
                                                    paddingBottom: 4,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </VerticalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="992133"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="987728"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              987728,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="633335"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(250, 173, 20, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="663009"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              663009,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="610531"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="556965"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="739525"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="592544"
                                              value={this.state.StratejiUret?.at?.(0)?.g5_Label3 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="586114"
                                            value={this.state.StratejiUret?.at?.(0)?.g5_L3 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="599053"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="100879"
                                              value={this.state.StratejiUret?.at?.(0)?.g5_Detay3 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>
                                      </HorizontalStack>
                                    </KCol>
                                  </KRow>
                                </KCol>
                              </KRow>

                              <KRow
                                id="966909"
                                visibility={this.state.isComp966909Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isGreaterThan(
                                    this.state.StratejiUret[0]?.frekans?.toString(),
                                    "5"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="332268"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="616801"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="327470"
                                      xs={4}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      xxl={4}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-right",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="266223"
                                        value={ReactSystemFunctions.translate(this.ml, 266223, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="751515"
                                      xs={20}
                                      sm={20}
                                      md={20}
                                      lg={20}
                                      xl={20}
                                      xxl={20}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="924552"
                                        direction="horizontal"
                                        size={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <KPanel
                                          id="607531"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(192, 0, 1, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="671295"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              671295,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="849628"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="445804"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="567876"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="335090"
                                              value={this.state.StratejiUret?.at?.(0)?.g6_Label1 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="741104"
                                            value={this.state.StratejiUret?.at?.(0)?.g6_L1 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="489291"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="4768"
                                              value={this.state.StratejiUret?.at?.(0)?.g6_Detay1 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="447838"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="922094"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              922094,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="196350"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(0, 190, 214, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="310739"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              310739,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="464282"
                                          direction="vertical"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <VerticalStack
                                            id="981012"
                                            direction="vertical"
                                            size={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundColor: "rgba(217, 217, 217, 1)",
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 8,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="235293"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Icon
                                                id="251719"
                                                visibility={this.state.isComp251719Visible}
                                                visibilityCondition={(rowData: object) =>
                                                  ReactSystemFunctions.isEqualTo(
                                                    this.state.KisiProtokolSonDurum[0]?.bilesen9IsAugmented?.toString(),
                                                    "0"
                                                  )
                                                }
                                                iconName="looks_one"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "20px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    letterSpacing: "1px"
                                                  } as any
                                                }
                                              ></Icon>

                                              <Label
                                                id="242605"
                                                value={this.state.StratejiUret?.at?.(0)?.g6_Label2 ?? undefined}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>

                                            <Label
                                              id="64395"
                                              value={this.state.StratejiUret?.at?.(0)?.g6_L2 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 4,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(217, 217, 217, 1)"
                                                } as any
                                              }
                                            ></Label>

                                            <HorizontalStack
                                              id="886998"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 16,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="889575"
                                                value={this.state.StratejiUret?.at?.(0)?.g6_Detay2 ?? undefined}
                                                style={
                                                  {
                                                    borderTopLeftRadius: 8,
                                                    borderTopRightRadius: 8,
                                                    borderBottomRightRadius: 8,
                                                    borderBottomLeftRadius: 8,
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    paddingTop: 4,
                                                    paddingRight: 8,
                                                    paddingBottom: 4,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </VerticalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="187434"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="407592"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              407592,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="128195"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(250, 173, 20, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="568763"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              568763,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="377413"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="796028"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="638370"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="337300"
                                              value={this.state.StratejiUret?.at?.(0)?.g6_Label3 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="311043"
                                            value={this.state.StratejiUret?.at?.(0)?.g6_L3 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="862089"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="457860"
                                              value={this.state.StratejiUret?.at?.(0)?.g6_Detay3 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>
                                      </HorizontalStack>
                                    </KCol>
                                  </KRow>
                                </KCol>
                              </KRow>

                              <KRow
                                id="694754"
                                visibility={this.state.isComp694754Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isGreaterThan(
                                    this.state.StratejiUret[0]?.frekans?.toString(),
                                    "6"
                                  )
                                }
                                align="top"
                                justify="start"
                                horizontalGutter={0}
                                verticalGutter={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KCol
                                  id="959788"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                  xl={24}
                                  xxl={24}
                                  order={0}
                                  pull={0}
                                  push={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="979002"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="38704"
                                      xs={4}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                      xxl={4}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 16,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-right",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="125445"
                                        value={ReactSystemFunctions.translate(this.ml, 125445, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="832690"
                                      xs={20}
                                      sm={20}
                                      md={20}
                                      lg={20}
                                      xl={20}
                                      xxl={20}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="189223"
                                        direction="horizontal"
                                        size={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <KPanel
                                          id="270013"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(192, 0, 1, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="384504"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              384504,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="30149"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="317161"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="589637"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="648009"
                                              value={this.state.StratejiUret?.at?.(0)?.g7_Label1 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="149249"
                                            value={this.state.StratejiUret?.at?.(0)?.g7_L1 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="62373"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="40399"
                                              value={this.state.StratejiUret?.at?.(0)?.g7_Detay1 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="230556"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="866730"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              866730,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="96157"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(0, 190, 214, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="421970"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              421970,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="556028"
                                          direction="vertical"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <VerticalStack
                                            id="552741"
                                            direction="vertical"
                                            size={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundColor: "rgba(217, 217, 217, 1)",
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 8,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="565176"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Icon
                                                id="426896"
                                                iconName="looks_one"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "20px",
                                                    color: "rgba(0, 0, 0, 1)",
                                                    letterSpacing: "1px"
                                                  } as any
                                                }
                                              ></Icon>

                                              <Label
                                                id="610227"
                                                value={this.state.StratejiUret?.at?.(0)?.g7_Label2 ?? undefined}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 5,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>

                                            <Label
                                              id="772499"
                                              value={this.state.StratejiUret?.at?.(0)?.g7_L2 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 4,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(217, 217, 217, 1)"
                                                } as any
                                              }
                                            ></Label>

                                            <HorizontalStack
                                              id="616733"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 16,
                                                  paddingBottom: 5,
                                                  paddingLeft: 16,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="833458"
                                                value={this.state.StratejiUret?.at?.(0)?.g7_Detay2 ?? undefined}
                                                style={
                                                  {
                                                    borderTopLeftRadius: 8,
                                                    borderTopRightRadius: 8,
                                                    borderBottomRightRadius: 8,
                                                    borderBottomLeftRadius: 8,
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    paddingTop: 4,
                                                    paddingRight: 8,
                                                    paddingBottom: 4,
                                                    paddingLeft: 8,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "15px",
                                                    color: "rgba(10, 33, 64, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </VerticalStack>
                                        </VerticalStack>

                                        <KPanel
                                          id="316717"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "100px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="389329"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              389329,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <KPanel
                                          id="774755"
                                          scrolling="hidden"
                                          scrollRadius={false}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(250, 173, 20, 1)",
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "10px",
                                              height: "102px",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="301923"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              301923,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </KPanel>

                                        <VerticalStack
                                          id="380917"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(217, 217, 217, 1)",
                                              paddingTop: 8,
                                              paddingRight: 0,
                                              paddingBottom: 8,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="474658"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="139077"
                                              iconName="looks_one"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(0, 0, 0, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="888775"
                                              value={this.state.StratejiUret?.at?.(0)?.g7_Label3 ?? undefined}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>

                                          <Label
                                            id="534786"
                                            value={this.state.StratejiUret?.at?.(0)?.g7_L3 ?? undefined}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 4,
                                                paddingRight: 0,
                                                paddingBottom: 5,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(217, 217, 217, 1)"
                                              } as any
                                            }
                                          ></Label>

                                          <HorizontalStack
                                            id="196828"
                                            direction="horizontal"
                                            size={12}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 16,
                                                paddingBottom: 5,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="693339"
                                              value={this.state.StratejiUret?.at?.(0)?.g7_Detay3 ?? undefined}
                                              style={
                                                {
                                                  borderTopLeftRadius: 8,
                                                  borderTopRightRadius: 8,
                                                  borderBottomRightRadius: 8,
                                                  borderBottomLeftRadius: 8,
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                                  paddingTop: 4,
                                                  paddingRight: 8,
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </VerticalStack>
                                      </HorizontalStack>
                                    </KCol>
                                  </KRow>
                                </KCol>
                              </KRow>
                            </KCol>
                          </KRow>
                        </KTabItem>

                        <KTabItem
                          id="844832"
                          onActive={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.PersonCardComponent_844832_onActive();
                          }}
                          visibility={this.state.isComp844832Visible}
                          visibilityCondition={(rowData: object) =>
                            ReactSystemFunctions.isEqualTo(
                              this.state.KisiObservedMi[0]?.kisiObservedMi?.toString(),
                              "0"
                            )
                          }
                          tab={ReactSystemFunctions.translate(this.ml, 844832, "tab", this.defaultML)}
                          forceRender={true}
                          key={844832}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="892198"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="420430"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="944423"
                                value={ReactSystemFunctions.translate(this.ml, 944423, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    color: "rgba(10, 33, 64, 1)",
                                    lineHeight: "20px"
                                  } as any
                                }
                              ></Label>

                              <HorizontalStack
                                id="766291"
                                direction="horizontal"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Label
                                  id="589386"
                                  value={this.state.KisiSelectById?.at?.(0)?.hesapId ?? undefined}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Icon
                                  id="369076"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.PersonCardComponent_369076_onClick();
                                  }}
                                  showCursorPointer
                                  visibility={this.state.isComp369076Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isNotEmpty(
                                      this.state.KisiSelectById[0]?.hesapId?.toString(),
                                      ""
                                    )
                                  }
                                  iconName="content_copy"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </HorizontalStack>
                            </KCol>

                            <KCol
                              id="901499"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-end",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <HorizontalStack
                                id="168280"
                                direction="horizontal"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <Button
                                  id="550051"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.PersonCardComponent_550051_onClick();
                                  }}
                                  showCursorPointer
                                  visibility={this.state.isComp550051Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isEqualTo(
                                      this.state.MyContext[0]?.hesaptanParaTransferEdebilir?.toString(),
                                      "1"
                                    )
                                  }
                                  editability={this.state.isComp550051Enabled}
                                  editabilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isEqualTo(
                                      this.state.KisiSelectById[0]?.hesabiHazirMi?.toString(),
                                      "1"
                                    )
                                  }
                                  label={ReactSystemFunctions.translate(this.ml, 550051, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 8,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(0, 190, 214, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>

                                <Button
                                  id="863402"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.PersonCardComponent_863402_onClick();
                                  }}
                                  showCursorPointer
                                  visibility={this.state.isComp863402Visible}
                                  visibilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isEqualTo(
                                      this.state.MyContext[0]?.hesaptanParaTransferEdebilir?.toString(),
                                      "1"
                                    )
                                  }
                                  editability={this.state.isComp863402Enabled}
                                  editabilityCondition={(rowData: object) =>
                                    ReactSystemFunctions.isEqualTo(
                                      this.state.KisiSelectById[0]?.hesabiHazirMi?.toString(),
                                      "1"
                                    )
                                  }
                                  label={ReactSystemFunctions.translate(this.ml, 863402, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 8,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(0, 190, 214, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingRight: 16,
                                      paddingBottom: 8,
                                      paddingLeft: 16,
                                      alignItems: "center",
                                      textAlign: "-webkit-center",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      lineHeight: "22px"
                                    } as any
                                  }
                                ></Button>
                              </HorizontalStack>
                            </KCol>
                          </KRow>

                          <KRow
                            id="847792"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="278"
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              xxl={8}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <VerticalStack
                                id="579899"
                                direction="vertical"
                                size={12}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KPanel
                                  id="619839"
                                  scrolling="hidden"
                                  scrollRadius={false}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      textAlign: "-webkit-center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="216902"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="636142"
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                      xl={8}
                                      xxl={8}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="personcard_464517_value">
                                        <Image
                                          id="464517"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              height: "60px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>
                                    </KCol>

                                    <KCol
                                      id="682519"
                                      xs={16}
                                      sm={16}
                                      md={16}
                                      lg={16}
                                      xl={16}
                                      xxl={16}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="144992"
                                        value={ReactSystemFunctions.translate(this.ml, 144992, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="174116"
                                        value={this.state.KrediBakiye?.at?.(0)?.bakiye ?? undefined}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "24px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>
                                  </KRow>
                                </KPanel>
                              </VerticalStack>
                            </KCol>

                            <KCol
                              id="676148"
                              xs={16}
                              sm={16}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={16}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 0,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KTable
                                id="761946"
                                kuikaRef={this.personcard_761946_value_kuikaTableRef}
                                form={this.props.form}
                                dataSource={this.state.HesapKrediHareketleri}
                                size="middle"
                                bordered={true}
                                transformedOnMobileResolution={false}
                                showHeader={false}
                                loading={false}
                                nodatafoundmessage={ReactSystemFunctions.translate(
                                  this.ml,
                                  761946,
                                  "nodatafoundmessage",
                                  this.defaultML
                                )}
                                tableWidthMode="fit"
                                searchable={false}
                                sorter={false}
                                filtering={false}
                                pagination={false}
                                columnChooser={false}
                                striped={false}
                                stripedColor="#F5F7FA"
                                insertRowActive={false}
                                insertRowPosition="top"
                                multiSelect={false}
                                pageSize={10}
                                showSizeChanger={false}
                                fixedHeader={false}
                                resizableColumns={false}
                                editable={false}
                                globalSearch={false}
                                showNoDataFound={true}
                                export={false}
                                editableAlwaysActive={false}
                                multiSelectScope="page"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              >
                                <KTableHeader
                                  id="45439"
                                  hideOnMobileResolution={false}
                                  textDirection="Default"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      color: "rgba(10, 33, 64, 1)",
                                      lineHeight: "20px"
                                    } as any
                                  }
                                ></KTableHeader>

                                <KTableRow
                                  id="920600"
                                  hoverFontColor="red"
                                  hoverBgColor="#F5F5F5"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KTableColumn
                                    id="813856"
                                    title={ReactSystemFunctions.translate(this.ml, 813856, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "150px",
                                        alignItems: "center",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="671545"
                                      value="[datafield:tarih]"
                                      formatter="date"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="372146"
                                    title={ReactSystemFunctions.translate(this.ml, 372146, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        width: "150px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="63366"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="612070"
                                        visibility={this.state.isComp612070Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(rowData.isArtiVisible, "1")
                                        }
                                        iconName="add_circle"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color: "rgba(0, 175, 80, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Icon
                                        id="233726"
                                        visibility={this.state.isComp233726Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(rowData.isMinusVisible, "1")
                                        }
                                        iconName="remove_circle"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color: "rgba(192, 0, 1, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Label
                                        id="703487"
                                        value="[datafield:kredi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>
                                  </KTableColumn>

                                  <KTableColumn
                                    id="579790"
                                    title={ReactSystemFunctions.translate(this.ml, 579790, "title", this.defaultML)}
                                    alwaysVisibleOnMobileResolution={false}
                                    hideFiltering={false}
                                    hideSorting={false}
                                    hideSearch={false}
                                    footerOptions="none"
                                    footerFontSize={14}
                                    footerFontColor="rgb(68, 68, 68)"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="413698"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="personcard_411851_value">
                                        <Image
                                          id="411851"
                                          visibility={this.state.isComp411851Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(rowData.isFromMe, "1")
                                          }
                                          value="a23a7981-55c1-4bce-c7aa-08dc41ea0a4e"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "20px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>

                                      <Icon
                                        id="751981"
                                        visibility={this.state.isComp751981Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(rowData.isFromMe, "1")
                                        }
                                        iconName="forward"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color: "rgba(155, 155, 155, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Label
                                        id="296248"
                                        value="[datafield:hesapTanimi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      ></Label>

                                      <Icon
                                        id="535575"
                                        visibility={this.state.isComp535575Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(rowData.isToMe, "1")
                                        }
                                        iconName="forward"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 5,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color: "rgba(155, 155, 155, 1)",
                                            letterSpacing: "1px"
                                          } as any
                                        }
                                      ></Icon>

                                      <Form.Item className="kFormItem" name="personcard_756175_value">
                                        <Image
                                          id="756175"
                                          visibility={this.state.isComp756175Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(rowData.isToMe, "1")
                                          }
                                          value="a23a7981-55c1-4bce-c7aa-08dc41ea0a4e"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              width: "20px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>
                                    </HorizontalStack>
                                  </KTableColumn>
                                </KTableRow>
                              </KTable>
                            </KCol>
                          </KRow>
                        </KTabItem>

                        <KTabItem
                          id="999340"
                          onActive={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.PersonCardComponent_999340_onActive();
                          }}
                          visibility={this.state.isComp999340Visible}
                          visibilityCondition={(rowData: object) =>
                            ReactSystemFunctions.isEqualTo(
                              this.state.KisiSelectById[0]?.egitimProfiliGorunmeliMi?.toString(),
                              "1"
                            )
                          }
                          tab={ReactSystemFunctions.translate(this.ml, 999340, "tab", this.defaultML)}
                          forceRender={true}
                          key={999340}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KRow
                            id="991357"
                            align="top"
                            justify="start"
                            horizontalGutter={0}
                            verticalGutter={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KCol
                              id="711446"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              xxl={24}
                              order={0}
                              pull={0}
                              push={0}
                              style={
                                {
                                  borderTopLeftRadius: 8,
                                  borderTopRightRadius: 8,
                                  borderBottomRightRadius: 8,
                                  borderBottomLeftRadius: 8,
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <KPanel id="141578" scrolling="hidden" scrollRadius={false} style={{} as any}>
                                <KRow
                                  id="193496"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "15px",
                                      color: "rgba(10, 33, 64, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="730218"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        borderBottomRightRadius: 8,
                                        borderBottomLeftRadius: 8,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        color: "rgba(10, 33, 64, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="194881"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="415104"
                                        xs={8}
                                        sm={8}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                        xxl={8}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="personcard_296047_value">
                                          <Image
                                            id="296047"
                                            zoomOnClick={true}
                                            imageFit="fit"
                                            style={{ height: "108px", display: "block" } as any}
                                          ></Image>
                                        </Form.Item>
                                      </KCol>

                                      <KCol
                                        id="506118"
                                        xs={16}
                                        sm={16}
                                        md={16}
                                        lg={16}
                                        xl={16}
                                        xxl={16}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="814154"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            814154,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "12px",
                                              color: "rgba(10, 33, 64, 1)",
                                              lineHeight: "20px"
                                            } as any
                                          }
                                        ></Label>

                                        <Form.Item className="kFormItem" name="personcard_802342_value">
                                          <MultiSelect
                                            id="802342"
                                            onChange={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.PersonCardComponent_802342_onChange();
                                            }}
                                            kuikaRef={this.personcard_802342_value_kuikaMultiSelectRef}
                                            options={this.state.ServiceAll}
                                            placeholder={ReactSystemFunctions.translate(
                                              this.ml,
                                              802342,
                                              "placeholder",
                                              this.defaultML
                                            )}
                                            allowClear={false}
                                            autoClearSearchValue={true}
                                            mode="tags"
                                            showSearch={true}
                                            autoRefresh={true}
                                            datavaluefield="id"
                                            datatextfield="title"
                                            style={
                                              {
                                                borderTopLeftRadius: 8,
                                                borderTopRightRadius: 8,
                                                borderBottomRightRadius: 8,
                                                borderBottomLeftRadius: 8,
                                                borderTopWidth: 1,
                                                borderRightWidth: 1,
                                                borderBottomWidth: 1,
                                                borderLeftWidth: 1,
                                                borderColor: "rgba(218, 218, 218, 1)",
                                                borderStyle: "solid",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "block",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></MultiSelect>
                                        </Form.Item>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="149979"
                                      visibility={this.state.isComp149979Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEmpty(
                                          this.state.SelectBasvuruEgitmen[0]?.egitimDuzey?.toString(),
                                          ""
                                        )
                                      }
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="208732"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8,
                                            borderBottomRightRadius: 8,
                                            borderBottomLeftRadius: 8,
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(236, 125, 49, 0.08)",
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="14906"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="285935"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={24}
                                            xxl={24}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="848586"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                848586,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="807712"
                                              value={this.state.SelectBasvuruEgitmen?.at?.(0)?.egitimDuzey ?? undefined}
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>
                                        </KRow>

                                        <KRow
                                          id="434713"
                                          visibility={this.state.isComp434713Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.SelectBasvuruEgitmen[0]?.egitimDuzey?.toString(),
                                              "Lisans"
                                            )
                                          }
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="907676"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="156711"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                156711,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="269774"
                                              value={this.state.SelectBasvuruEgitmen?.at?.(0)?.lisansMezun ?? undefined}
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="57980"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="647962"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                647962,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="915098"
                                              value={this.state.SelectBasvuruEgitmen?.at?.(0)?.lisansBolum ?? undefined}
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="960590"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></KCol>
                                        </KRow>

                                        <KRow
                                          id="468216"
                                          visibility={this.state.isComp468216Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.SelectBasvuruEgitmen[0]?.egitimDuzey?.toString(),
                                              "Lisans üstü"
                                            )
                                          }
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="184408"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="733446"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                733446,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="907571"
                                              value={
                                                this.state.SelectBasvuruEgitmen?.at?.(0)?.lisansustuDerece ?? undefined
                                              }
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="382749"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="29894"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                29894,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="144184"
                                              value={
                                                this.state.SelectBasvuruEgitmen?.at?.(0)?.lisansustuKonu ?? undefined
                                              }
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="708654"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></KCol>
                                        </KRow>

                                        <KRow
                                          id="469426"
                                          visibility={this.state.isComp469426Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.SelectBasvuruEgitmen[0]?.egitimDuzey?.toString(),
                                              "Lise"
                                            )
                                          }
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="325556"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="647178"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                647178,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="95422"
                                              value={this.state.SelectBasvuruEgitmen?.at?.(0)?.liseDerece ?? undefined}
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="573257"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="492965"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                492965,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="107717"
                                              value={
                                                this.state.SelectBasvuruEgitmen?.at?.(0)?.liseSertifika ?? undefined
                                              }
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="353314"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></KCol>
                                        </KRow>

                                        <KRow
                                          id="590486"
                                          visibility={this.state.isComp590486Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.SelectBasvuruEgitmen[0]?.egitimDuzey?.toString(),
                                              "Spor bilimleri fakültesinde öğrenciyim"
                                            )
                                          }
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="945930"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="568137"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                568137,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="352479"
                                              value={this.state.SelectBasvuruEgitmen?.at?.(0)?.ogrenciUni ?? undefined}
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="656953"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="697525"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                697525,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="337786"
                                              value={
                                                this.state.SelectBasvuruEgitmen?.at?.(0)?.ogrenciBolum ?? undefined
                                              }
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="580018"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="227725"
                                      visibility={this.state.isComp227725Visible}
                                      visibilityCondition={(rowData: object) =>
                                        ReactSystemFunctions.isNotEmpty(
                                          this.state.SelectBasvuruEgitmen[0]?.meslek?.toString(),
                                          ""
                                        )
                                      }
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopLeftRadius: 8,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          borderBottomLeftRadius: 8,
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="795223"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8,
                                            borderBottomRightRadius: 8,
                                            borderBottomLeftRadius: 8,
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(10, 33, 64, 0.08)",
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="403516"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="819394"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="268373"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                268373,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="342304"
                                              value={this.state.SelectBasvuruEgitmen?.at?.(0)?.meslek ?? undefined}
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="345364"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="991751"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                991751,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="248469"
                                              value={this.state.SelectBasvuruEgitmen?.at?.(0)?.tecrubeSure ?? undefined}
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="269521"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></KCol>
                                        </KRow>

                                        <KRow
                                          id="514246"
                                          visibility={this.state.isComp514246Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.SelectBasvuruEgitmen[0]?.meslek?.toString(),
                                              "Antrenör"
                                            )
                                          }
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="771329"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="157905"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                157905,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="341468"
                                              value={
                                                this.state.SelectBasvuruEgitmen?.at?.(0)?.antrenorKlup ?? undefined
                                              }
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="568423"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="701989"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                701989,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="784681"
                                              value={
                                                this.state.SelectBasvuruEgitmen?.at?.(0)?.antrenorBrans ?? undefined
                                              }
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="790023"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></KCol>
                                        </KRow>

                                        <KRow
                                          id="205140"
                                          visibility={this.state.isComp205140Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.SelectBasvuruEgitmen[0]?.meslek?.toString(),
                                              "Beden eğitimi öğretmeni"
                                            )
                                          }
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="342538"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="412055"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                412055,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="510422"
                                              value={this.state.SelectBasvuruEgitmen?.at?.(0)?.bedenOkul ?? undefined}
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="173413"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="745735"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                745735,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="904969"
                                              value={this.state.SelectBasvuruEgitmen?.at?.(0)?.bedenSinif ?? undefined}
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="74011"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          ></KCol>
                                        </KRow>

                                        <KRow
                                          id="802946"
                                          visibility={this.state.isComp802946Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.SelectBasvuruEgitmen[0]?.meslek?.toString(),
                                              "Fitness eğitmeni"
                                            )
                                          }
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="463074"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="646823"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                646823,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="781236"
                                              value={
                                                this.state.SelectBasvuruEgitmen?.at?.(0)?.fitnessMerkez ?? undefined
                                              }
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="43584"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="228320"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                228320,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="385449"
                                              value={this.state.SelectBasvuruEgitmen?.at?.(0)?.fitnessDers ?? undefined}
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="956118"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "15px",
                                                color: "rgba(10, 33, 64, 1)"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="530808"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                530808,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 400,
                                                  fontSize: "12px",
                                                  color: "rgba(10, 33, 64, 1)",
                                                  lineHeight: "20px"
                                                } as any
                                              }
                                            ></Label>

                                            <Label
                                              id="846382"
                                              value={
                                                this.state.SelectBasvuruEgitmen?.at?.(0)?.ozelPopulasyon ?? undefined
                                              }
                                              style={{ display: "block" } as any}
                                            ></Label>
                                          </KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="322105"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 5,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "15px",
                                          color: "rgba(10, 33, 64, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="141706"
                                        xs={24}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <HorizontalStack
                                          id="315018"
                                          direction="horizontal"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Form.Item className="kFormItem" name="personcard_551151_value">
                                            <Switch
                                              id="551151"
                                              disabled={false}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Switch>
                                          </Form.Item>

                                          <Label
                                            id="945197"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              945197,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "12px",
                                                color: "rgba(10, 33, 64, 1)",
                                                lineHeight: "20px"
                                              } as any
                                            }
                                          ></Label>
                                        </HorizontalStack>
                                      </KCol>

                                      <KCol
                                        id="771408"
                                        xs={24}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            color: "rgba(10, 33, 64, 1)"
                                          } as any
                                        }
                                      >
                                        <HorizontalStack
                                          id="910498"
                                          direction="horizontal"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 5,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "15px",
                                              color: "rgba(10, 33, 64, 1)"
                                            } as any
                                          }
                                        >
                                          <Form.Item className="kFormItem" name="personcard_798203_value">
                                            <Switch
                                              id="798203"
                                              disabled={false}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 5,
                                                  paddingLeft: 0,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "15px",
                                                  color: "rgba(10, 33, 64, 1)"
                                                } as any
                                              }
                                            ></Switch>
                                          </Form.Item>

                                          <Label
                                            id="503945"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              503945,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "12px",
                                                color: "rgba(10, 33, 64, 1)",
                                                lineHeight: "20px"
                                              } as any
                                            }
                                          ></Label>
                                        </HorizontalStack>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>
                              </KPanel>
                            </KCol>
                          </KRow>
                        </KTabItem>
                      </KTab>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KDrawer
                  id="1514"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible1514: false }, () => {
                      this.callCallbackFunction(1514);
                    });
                  }}
                  visible={this.state.NavVisible1514}
                  width="70%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible1514 && (
                      <CheckupF_Screen
                        onClose={() => {
                          this.setState({ NavVisible1514: false }, () => {
                            this.callCallbackFunction(1514);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="101102"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible101102: false }, () => {
                      this.callCallbackFunction(101102);
                    });
                  }}
                  visible={this.state.NavVisible101102}
                  width="440px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible101102 && (
                      <KrediYukle_Screen
                        onClose={() => {
                          this.setState({ NavVisible101102: false }, () => {
                            this.callCallbackFunction(101102);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="372956"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible372956: false }, () => {
                      this.callCallbackFunction(372956);
                    });
                  }}
                  visible={this.state.NavVisible372956}
                  width="440px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible372956 && (
                      <KrediTransferi_Screen
                        onClose={() => {
                          this.setState({ NavVisible372956: false }, () => {
                            this.callCallbackFunction(372956);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="189806"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible189806: false }, () => {
                      this.callCallbackFunction(189806);
                    });
                  }}
                  visible={this.state.NavVisible189806}
                  width="1080px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible189806 && (
                      <CheckupReport_Screen
                        onClose={() => {
                          this.setState({ NavVisible189806: false }, () => {
                            this.callCallbackFunction(189806);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="277918"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible277918: false }, () => {
                      this.callCallbackFunction(277918);
                    });
                  }}
                  visible={this.state.NavVisible277918}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible277918 && (
                      <EkTestF_Screen
                        onClose={() => {
                          this.setState({ NavVisible277918: false }, () => {
                            this.callCallbackFunction(277918);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="633023"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible633023: false }, () => {
                      this.callCallbackFunction(633023);
                    });
                  }}
                  visible={this.state.NavVisible633023}
                  width="680px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible633023 && (
                      <EkTestGecmisi_Screen
                        onClose={() => {
                          this.setState({ NavVisible633023: false }, () => {
                            this.callCallbackFunction(633023);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="177324"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible177324: false }, () => {
                      this.callCallbackFunction(177324);
                    });
                  }}
                  visible={this.state.NavVisible177324}
                  width="70%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible177324 && (
                      <ParQFormcopy_Screen
                        onClose={() => {
                          this.setState({ NavVisible177324: false }, () => {
                            this.callCallbackFunction(177324);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="168529"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible168529: false }, () => {
                      this.callCallbackFunction(168529);
                    });
                  }}
                  visible={this.state.NavVisible168529}
                  width="1080px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible168529 && (
                      <CheckupReport_Screen
                        onClose={() => {
                          this.setState({ NavVisible168529: false }, () => {
                            this.callCallbackFunction(168529);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="537386"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible537386: false }, () => {
                      this.callCallbackFunction(537386);
                    });
                  }}
                  visible={this.state.NavVisible537386}
                  width="100%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible537386 && (
                      <CheckupF_Screen
                        onClose={() => {
                          this.setState({ NavVisible537386: false }, () => {
                            this.callCallbackFunction(537386);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="635164"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible635164: false }, () => {
                      this.callCallbackFunction(635164);
                    });
                  }}
                  visible={this.state.NavVisible635164}
                  width="70%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible635164 && (
                      <ParQFormcopy_Screen
                        onClose={() => {
                          this.setState({ NavVisible635164: false }, () => {
                            this.callCallbackFunction(635164);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="550396"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible550396: false }, () => {
                      this.callCallbackFunction(550396);
                    });
                  }}
                  visible={this.state.NavVisible550396}
                  width="70%"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible550396 && (
                      <ParQFormcopy_Screen
                        onClose={() => {
                          this.setState({ NavVisible550396: false }, () => {
                            this.callCallbackFunction(550396);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KRow
                  id="933341"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="619156"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>
                </KRow>

                <KDrawer
                  id="395237"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible395237: false }, () => {
                      this.callCallbackFunction(395237);
                    });
                  }}
                  visible={this.state.NavVisible395237}
                  width="1080px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible395237 && (
                      <CheckupReport3_Screen
                        onClose={() => {
                          this.setState({ NavVisible395237: false }, () => {
                            this.callCallbackFunction(395237);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(PersonCard_Screen)))))
);
export { tmp as PersonCard_Screen };
//export default tmp;
//export { tmp as PersonCard_Screen };
