import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRandevuFromEgitmen_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRandevuFromEgitmen_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  DavetiyeById: any[];
  DavetiyeById_dummy: any[];
  RandevuById: any[];
  RandevuById_dummy: any[];
  EgitmenById: any[];
  EgitmenById_dummy: any[];
  RandevuHizmetler: any[];
  RandevuHizmetler_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp341303Enabled: "enabled" | "disabled";
  isComp477590Enabled: "enabled" | "disabled";
}

export class RandevuFromEgitmen_ScreenBase extends React.PureComponent<IRandevuFromEgitmen_ScreenProps, any> {
  randevufromegitmen_502333_value_kuikaFlexGridRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "34985c62-b489-40b7-87f9-ec9c1e2fd65c",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Randevu" },
        { Id: 579589, PropertyName: "value", Value: "Eğitmen" },
        { Id: 343251, PropertyName: "value", Value: "Yaş" },
        { Id: 667050, PropertyName: "value", Value: "Randevu almak istediğiniz hizmeti seçiniz..." },
        { Id: 502333, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 345849, PropertyName: "value", Value: "[datafield:title]" },
        { Id: 652630, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 842853, PropertyName: "value", Value: "Süre" },
        { Id: 875569, PropertyName: "value", Value: "[datafield:sure]" },
        { Id: 321303, PropertyName: "value", Value: "dk" },
        { Id: 341303, PropertyName: "label", Value: "İLERİ" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.randevufromegitmen_502333_value_kuikaFlexGridRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      DavetiyeById: [],
      RandevuById: [],
      EgitmenById: [],
      RandevuHizmetler: [],
      ChangeEnabledOf: "",
      NAVIGATE: "",
      isComp341303Enabled: "enabled",
      isComp477590Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("randevufromegitmen", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.RandevuFromEgitmenPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("randevufromegitmen", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("randevufromegitmen", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.RandevuFromEgitmenPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      randevufromegitmen_420686_value: this.state.EgitmenById?.at?.(0)?.resim ?? undefined,
      randevufromegitmen_477590_value: this.state.EgitmenById?.at?.(0)?.rate ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  RandevuFromEgitmenPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      davetiyeId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid,
        "Guid"
      ),
      randevuId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid,
        "Guid"
      ),
      kisiId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.EgitmenId ?? this.props.screenInputs.egitmenid,
        "Guid"
      ),
      randevuId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid,
        "Guid"
      ),
      davetId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid,
        "Guid"
      ),
      seciliHizmetId_5: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      seciliMerkezId_5: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      seciliEgitmenId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.EgitmenId ?? this.props.screenInputs.egitmenid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "RandevuFromEgitmen/RandevuFromEgitmenPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.DavetiyeById = result?.data.davetiyeById;
    stateVars.RandevuById = result?.data.randevuById;
    stateVars.EgitmenById = result?.data.egitmenById;
    formVars.randevufromegitmen_420686_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].resim
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].resim
        : null
    );
    formVars.randevufromegitmen_420686_placeholderimage = "";
    formVars.randevufromegitmen_41519_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].fullname
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].fullname
        : null
    );
    formVars.randevufromegitmen_661792_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].unvan
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].unvan
        : null
    );
    formVars.randevufromegitmen_285573_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].yas
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].yas
        : null
    );
    stateVars.RandevuById = result?.data.randevuById;
    stateVars.RandevuHizmetler = result?.data.randevuHizmetler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RandevuFromEgitmenPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RandevuFromEgitmenPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp341303Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RandevuFromEgitmenPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RandevuFromEgitmenPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].isButtonEnabled
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].isButtonEnabled
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp341303Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.RandevuFromEgitmenPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.RandevuFromEgitmenPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  RandevuFromEgitmenPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.randevufromegitmen_420686_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].resim
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].resim
        : null
    );

    formVars.randevufromegitmen_41519_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].fullname
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].fullname
        : null
    );

    formVars.randevufromegitmen_661792_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].unvan
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].unvan
        : null
    );

    formVars.randevufromegitmen_285573_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].yas
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].yas
        : null
    );

    formVars.randevufromegitmen_477590_value = ReactSystemFunctions.value(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].rate
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].rate
        : null
    );

    stateVars.dataSource_502333 = this.state.RandevuHizmetler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RandevuFromEgitmenComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RandevuFromEgitmenComponent_657215_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      davetId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid,
        "Guid"
      ),
      seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "randevufromegitmen_502333_value", "id"),
        "Guid"
      ),
      seciliMerkezId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      seciliEgitmenId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.EgitmenId ?? this.props.screenInputs.egitmenid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RandevuFromEgitmen/RandevuFromEgitmenComponent_657215_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RandevuHizmetler = result?.data.randevuHizmetler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RandevuFromEgitmenComponent_657215_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RandevuFromEgitmenComponent_657215_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp341303Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RandevuFromEgitmenComponent_657215_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  RandevuFromEgitmenComponent_657215_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.RandevuHizmetler?.length > 0
            ? stateVars.RandevuHizmetler[0].seciliHizmetId
            : this.state.RandevuHizmetler?.length > 0
            ? this.state.RandevuHizmetler[0].seciliHizmetId
            : null
        ),
        null
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp341303Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.RandevuFromEgitmenComponent_657215_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.RandevuFromEgitmenComponent_657215_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  RandevuFromEgitmenComponent_657215_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_502333 = this.state.RandevuHizmetler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RandevuFromEgitmenComponent_341303_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "RandevuFromEgitmen2",
      "DavetiyeId",
      this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuFromEgitmen2",
      "EgitmenId",
      this.props.screenInputs.EgitmenId ?? this.props.screenInputs.egitmenid
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuFromEgitmen2",
      "HizmetId",
      ReactSystemFunctions.value(
        this,
        stateVars.RandevuHizmetler?.length > 0
          ? stateVars.RandevuHizmetler[0].seciliHizmetId
          : this.state.RandevuHizmetler?.length > 0
          ? this.state.RandevuHizmetler[0].seciliHizmetId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuFromEgitmen2",
      "RandevuId",
      this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid
    );
    KuikaAppManager.addToPageInputVariables(
      "RandevuFromEgitmen2",
      "DanisanKisiId",
      this.props.screenInputs.DanisanKisiId ?? this.props.screenInputs.danisankisiid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.RandevuFromEgitmenComponent_341303_onClick1_,
      "RandevuFromEgitmen",
      "RandevuFromEgitmen2",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "287620",
      null,
      "right",
      null,
      "1198px",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  RandevuFromEgitmenComponent_341303_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    if (diId == 287620) {
      isErrorOccurred = await this.RandevuFromEgitmenComponent_341303_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
