import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface INormDetay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface INormDetay_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  NormById: any[];
  NormById_dummy: any[];
  NormDetayById: any[];
  NormDetayById_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  isComp900213Visible: "visible" | "hidden";
  isComp360244Visible: "visible" | "hidden";
  isComp565433Visible: "visible" | "hidden";
  isComp467294Visible: "visible" | "hidden";
  isComp354927Visible: "visible" | "hidden";
  isComp884781Visible: "visible" | "hidden";
  isComp473982Visible: "visible" | "hidden";
  isComp373047Visible: "visible" | "hidden";
}

export class NormDetay_ScreenBase extends React.PureComponent<INormDetay_ScreenProps, any> {
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "123b31f4-0702-4717-8ed0-ebad47cba58f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Detay" },
        { Id: 866081, PropertyName: "value", Value: "Yaş" },
        { Id: 214156, PropertyName: "placeholder", Value: "15" },
        { Id: 980666, PropertyName: "value", Value: "ERKEK" },
        { Id: 418703, PropertyName: "value", Value: "KADIN" },
        { Id: 589818, PropertyName: "value", Value: "Değer 1" },
        { Id: 151377, PropertyName: "placeholder", Value: "15" },
        { Id: 28136, PropertyName: "value", Value: "Değer 1" },
        { Id: 746102, PropertyName: "placeholder", Value: "15" },
        { Id: 660179, PropertyName: "value", Value: "Değer 2" },
        { Id: 835159, PropertyName: "placeholder", Value: "15" },
        { Id: 789338, PropertyName: "value", Value: "Değer 2" },
        { Id: 331844, PropertyName: "placeholder", Value: "15" },
        { Id: 738372, PropertyName: "value", Value: "Değer 3" },
        { Id: 125164, PropertyName: "placeholder", Value: "15" },
        { Id: 230059, PropertyName: "value", Value: "Değer 3" },
        { Id: 415181, PropertyName: "placeholder", Value: "15" },
        { Id: 79604, PropertyName: "value", Value: "Değer 4" },
        { Id: 279169, PropertyName: "placeholder", Value: "15" },
        { Id: 681678, PropertyName: "value", Value: "Değer 4" },
        { Id: 654107, PropertyName: "placeholder", Value: "15" },
        { Id: 565773, PropertyName: "value", Value: "Değer 5" },
        { Id: 205938, PropertyName: "placeholder", Value: "15" },
        { Id: 515090, PropertyName: "value", Value: "Değer 5" },
        { Id: 268029, PropertyName: "placeholder", Value: "15" },
        { Id: 355440, PropertyName: "value", Value: "Değer 6" },
        { Id: 985387, PropertyName: "placeholder", Value: "15" },
        { Id: 545832, PropertyName: "value", Value: "Değer 6" },
        { Id: 576399, PropertyName: "placeholder", Value: "15" },
        { Id: 278723, PropertyName: "value", Value: "Değer 7" },
        { Id: 156666, PropertyName: "placeholder", Value: "15" },
        { Id: 180332, PropertyName: "value", Value: "Değer 7" },
        { Id: 289074, PropertyName: "placeholder", Value: "15" },
        { Id: 567675, PropertyName: "value", Value: "Değer 8" },
        { Id: 849712, PropertyName: "placeholder", Value: "15" },
        { Id: 637519, PropertyName: "value", Value: "Değer 8" },
        { Id: 549249, PropertyName: "placeholder", Value: "15" },
        { Id: 9544, PropertyName: "label", Value: "Save" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      NormById: [],
      NormDetayById: [],
      SaveRecord: false,
      isComp900213Visible: "hidden",
      isComp360244Visible: "hidden",
      isComp565433Visible: "hidden",
      isComp467294Visible: "hidden",
      isComp354927Visible: "hidden",
      isComp884781Visible: "hidden",
      isComp473982Visible: "hidden",
      isComp373047Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("normdetay", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.NormDetayPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("normdetay", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("normdetay", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.NormDetayPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      normdetay_214156_value: this.state.NormDetayById?.at?.(0)?.yas ?? undefined,
      normdetay_151377_value: this.state.NormDetayById?.at?.(0)?.mVal1 ?? undefined,
      normdetay_746102_value: this.state.NormDetayById?.at?.(0)?.fVal1 ?? undefined,
      normdetay_835159_value: this.state.NormDetayById?.at?.(0)?.mVal2 ?? undefined,
      normdetay_331844_value: this.state.NormDetayById?.at?.(0)?.fVal2 ?? undefined,
      normdetay_125164_value: this.state.NormDetayById?.at?.(0)?.mVal3 ?? undefined,
      normdetay_415181_value: this.state.NormDetayById?.at?.(0)?.fVal3 ?? undefined,
      normdetay_279169_value: this.state.NormDetayById?.at?.(0)?.mVal4 ?? undefined,
      normdetay_654107_value: this.state.NormDetayById?.at?.(0)?.fVal4 ?? undefined,
      normdetay_205938_value: this.state.NormDetayById?.at?.(0)?.mVal5 ?? undefined,
      normdetay_268029_value: this.state.NormDetayById?.at?.(0)?.fVal5 ?? undefined,
      normdetay_985387_value: this.state.NormDetayById?.at?.(0)?.mVal6 ?? undefined,
      normdetay_576399_value: this.state.NormDetayById?.at?.(0)?.fVal6 ?? undefined,
      normdetay_156666_value: this.state.NormDetayById?.at?.(0)?.mVal7 ?? undefined,
      normdetay_289074_value: this.state.NormDetayById?.at?.(0)?.fVal7 ?? undefined,
      normdetay_849712_value: this.state.NormDetayById?.at?.(0)?.mVal8 ?? undefined,
      normdetay_549249_value: this.state.NormDetayById?.at?.(0)?.fVal8 ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  NormDetayPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      normId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.NormId ?? this.props.screenInputs.normid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DetayId ?? this.props.screenInputs.detayid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "NormDetay/NormDetayPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.NormById = result?.data.normById;
    stateVars.isComp900213Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360244Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565433Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp467294Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354927Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp884781Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp473982Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp373047Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.NormDetayById = result?.data.normDetayById;
    formVars.normdetay_214156_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].yas
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].yas
        : null
    );
    formVars.normdetay_151377_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal1
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal1
        : null
    );
    formVars.normdetay_746102_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal1
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal1
        : null
    );
    formVars.normdetay_835159_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal2
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal2
        : null
    );
    formVars.normdetay_331844_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal2
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal2
        : null
    );
    formVars.normdetay_125164_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal3
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal3
        : null
    );
    formVars.normdetay_415181_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal3
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal3
        : null
    );
    formVars.normdetay_279169_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal4
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal4
        : null
    );
    formVars.normdetay_654107_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal4
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal4
        : null
    );
    formVars.normdetay_205938_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal5
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal5
        : null
    );
    formVars.normdetay_268029_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal5
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal5
        : null
    );
    formVars.normdetay_985387_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal6
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal6
        : null
    );
    formVars.normdetay_576399_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal6
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal6
        : null
    );
    formVars.normdetay_156666_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal7
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal7
        : null
    );
    formVars.normdetay_289074_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal7
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal7
        : null
    );
    formVars.normdetay_849712_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal8
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal8
        : null
    );
    formVars.normdetay_549249_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal8
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal8
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.NormDetayPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  NormDetayPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.normdetay_214156_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].yas
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].yas
        : null
    );

    stateVars.isComp900213Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.normdetay_151377_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal1
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal1
        : null
    );

    formVars.normdetay_746102_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal1
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal1
        : null
    );

    stateVars.isComp360244Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.normdetay_835159_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal2
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal2
        : null
    );

    formVars.normdetay_331844_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal2
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal2
        : null
    );

    stateVars.isComp565433Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.normdetay_125164_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal3
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal3
        : null
    );

    formVars.normdetay_415181_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal3
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal3
        : null
    );

    stateVars.isComp467294Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    formVars.normdetay_279169_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal4
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal4
        : null
    );

    formVars.normdetay_654107_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal4
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal4
        : null
    );

    stateVars.isComp354927Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    formVars.normdetay_205938_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal5
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal5
        : null
    );

    formVars.normdetay_268029_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal5
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal5
        : null
    );

    stateVars.isComp884781Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    formVars.normdetay_985387_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal6
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal6
        : null
    );

    formVars.normdetay_576399_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal6
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal6
        : null
    );

    stateVars.isComp473982Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    formVars.normdetay_156666_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal7
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal7
        : null
    );

    formVars.normdetay_289074_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal7
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal7
        : null
    );

    stateVars.isComp373047Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.NormById?.length > 0
            ? stateVars.NormById[0].esikAdedi
            : this.state.NormById?.length > 0
            ? this.state.NormById[0].esikAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    formVars.normdetay_849712_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].mVal8
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].mVal8
        : null
    );

    formVars.normdetay_549249_value = ReactSystemFunctions.value(
      this,
      stateVars.NormDetayById?.length > 0
        ? stateVars.NormDetayById[0].fVal8
        : this.state.NormDetayById?.length > 0
        ? this.state.NormDetayById[0].fVal8
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  NormDetayComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  NormDetayComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_214156_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "normdetay_214156_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DetayId ?? this.props.screenInputs.detayid,
        "Guid"
      ),
      NormId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.NormId ?? this.props.screenInputs.normid,
        "Guid"
      ),
      Yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_214156_value", "value", "", "", "")
        ),
        "number"
      ),
      FVal1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_746102_value", "value", "", "", "")
        ),
        "number"
      ),
      FVal2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_331844_value", "value", "", "", "")
        ),
        "number"
      ),
      FVal3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_415181_value", "value", "", "", "")
        ),
        "number"
      ),
      FVal4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_654107_value", "value", "", "", "")
        ),
        "number"
      ),
      FVal5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_268029_value", "value", "", "", "")
        ),
        "number"
      ),
      FVal6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_576399_value", "value", "", "", "")
        ),
        "number"
      ),
      FVal7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_289074_value", "value", "", "", "")
        ),
        "number"
      ),
      MVal1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_151377_value", "value", "", "", "")
        ),
        "number"
      ),
      MVal2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_835159_value", "value", "", "", "")
        ),
        "number"
      ),
      MVal3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_125164_value", "value", "", "", "")
        ),
        "number"
      ),
      MVal4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_279169_value", "value", "", "", "")
        ),
        "number"
      ),
      MVal5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_205938_value", "value", "", "", "")
        ),
        "number"
      ),
      MVal6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_985387_value", "value", "", "", "")
        ),
        "number"
      ),
      MVal7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_156666_value", "value", "", "", "")
        ),
        "number"
      ),
      MVal8_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_849712_value", "value", "", "", "")
        ),
        "number"
      ),
      FVal8_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "normdetay_549249_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "NormDetay/NormDetayComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.NormDetayComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  NormDetayComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
