import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProtokolBilesenF_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProtokolBilesenF_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  BilesenAll: any[];
  BilesenAll_dummy: any[];
  NormmAll: any[];
  NormmAll_dummy: any[];
  ProtokolBilesenById: any[];
  ProtokolBilesenById_dummy: any[];
  AltBilesenByBilesenId: any[];
  AltBilesenByBilesenId_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
}

export class ProtokolBilesenF_ScreenBase extends React.PureComponent<IProtokolBilesenF_ScreenProps, any> {
  protokolbilesenf_544298_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "ccc4e0c0-2866-4f21-864b-84bc562c3fc3",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Protokol Bileşen Tanımı" },
        { Id: 907900, PropertyName: "value", Value: "Bileşen" },
        { Id: 544298, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 822712, PropertyName: "value", Value: "Pay / Ağırlık" },
        { Id: 33057, PropertyName: "placeholder", Value: "15" },
        { Id: 796292, PropertyName: "value", Value: "Sıra No" },
        { Id: 489639, PropertyName: "placeholder", Value: "15" },
        { Id: 6775017, PropertyName: "value", Value: "Rapor Açıklama" },
        { Id: 889383, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 909295, PropertyName: "value", Value: "Rapor Açıklama (EN)" },
        { Id: 722065, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 9544, PropertyName: "label", Value: "İLERİ" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.protokolbilesenf_544298_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      BilesenAll: [],
      NormmAll: [],
      ProtokolBilesenById: [],
      AltBilesenByBilesenId: [],
      SaveRecord: false,
      NAVIGATE: ""
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("protokolbilesenf", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProtokolBilesenFPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("protokolbilesenf", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("protokolbilesenf", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProtokolBilesenFPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      protokolbilesenf_544298_value: this.state.ProtokolBilesenById?.at?.(0)?.bilesenId ?? undefined,
      protokolbilesenf_33057_value: this.state.ProtokolBilesenById?.at?.(0)?.pay ?? undefined,
      protokolbilesenf_489639_value: this.state.ProtokolBilesenById?.at?.(0)?.sira ?? undefined,
      protokolbilesenf_889383_value: this.state.ProtokolBilesenById?.at?.(0)?.raporAciklama ?? undefined,
      protokolbilesenf_722065_value: this.state.ProtokolBilesenById?.at?.(0)?.raporAciklamaEN ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ProtokolBilesenFPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ProtokolBilesenF/ProtokolBilesenFPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BilesenAll = result?.data.bilesenAll;

    formVars.protokolbilesenf_544298_value =
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].bilesenId
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].bilesenId
        : null;
    formVars.protokolbilesenf_544298_options = stateVars.BilesenAll;
    stateVars.NormmAll = result?.data.normmAll;
    stateVars.ProtokolBilesenById = result?.data.protokolBilesenById;
    formVars.protokolbilesenf_544298_value =
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].bilesenId
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].bilesenId
        : null;
    formVars.protokolbilesenf_544298_options = stateVars.BilesenAll;
    formVars.protokolbilesenf_33057_value = ReactSystemFunctions.value(
      this,
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].pay
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].pay
        : null
    );
    formVars.protokolbilesenf_489639_value = ReactSystemFunctions.value(
      this,
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].sira
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].sira
        : null
    );
    formVars.protokolbilesenf_889383_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].raporAciklama
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].raporAciklama
        : null
    );
    formVars.protokolbilesenf_722065_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].raporAciklamaEN
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].raporAciklamaEN
        : null
    );
    stateVars.AltBilesenByBilesenId = result?.data.altBilesenByBilesenId;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProtokolBilesenFPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProtokolBilesenFPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.protokolbilesenf_544298_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].bilesenId
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].bilesenId
        : null
    );

    stateVars.dataSource_544298 = this.state.BilesenAll;
    stateVars.dataSource_544298 = this.state.BilesenAll;
    formVars.protokolbilesenf_33057_value = ReactSystemFunctions.value(
      this,
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].pay
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].pay
        : null
    );

    formVars.protokolbilesenf_489639_value = ReactSystemFunctions.value(
      this,
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].sira
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].sira
        : null
    );

    formVars.protokolbilesenf_889383_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].raporAciklama
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].raporAciklama
        : null
    );

    formVars.protokolbilesenf_722065_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolBilesenById?.length > 0
        ? stateVars.ProtokolBilesenById[0].raporAciklamaEN
        : this.state.ProtokolBilesenById?.length > 0
        ? this.state.ProtokolBilesenById[0].raporAciklamaEN
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProtokolBilesenFComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProtokolBilesenFComponent_544298_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protokolbilesenf_544298_value", "value", "BilesenAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "protokolbilesenf_544298_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      bilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protokolbilesenf_544298_value", "value", "BilesenAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProtokolBilesenF/ProtokolBilesenFComponent_544298_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AltBilesenByBilesenId = result?.data.altBilesenByBilesenId;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  ProtokolBilesenFComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protokolbilesenf_33057_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "protokolbilesenf_33057_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protokolbilesenf_489639_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "protokolbilesenf_489639_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protokolbilesenf_889383_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "protokolbilesenf_889383_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protokolbilesenf_544298_value", "value", "BilesenAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "protokolbilesenf_544298_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid,
        "Guid"
      ),
      ProtokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      Pay_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protokolbilesenf_33057_value", "value", "", "", "")
        ),
        "number"
      ),
      Sira_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protokolbilesenf_489639_value", "value", "", "", "")
        ),
        "number"
      ),
      RaporAciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protokolbilesenf_889383_value", "value", "", "", "")
        ),
        "string"
      ),
      RaporAciklamaEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "protokolbilesenf_722065_value", "value", "", "", "")
        ),
        "string"
      ),
      BilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "protokolbilesenf_544298_value", "value", "BilesenAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProtokolBilesenF/ProtokolBilesenFComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProtokolBilesenFComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProtokolBilesenFComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ProtocolBilesenF2",
      "ProtokolId",
      this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid
    );
    KuikaAppManager.addToPageInputVariables(
      "ProtocolBilesenF2",
      "ProtokolBilesenId",
      this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.ProtokolBilesenFComponent_9544_onClick2_,
      "ProtokolBilesenF",
      "ProtocolBilesenF2",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "512441",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  ProtokolBilesenFComponent_9544_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [512441] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ProtokolBilesenFPageInit();
    }
    if (diId == 512441) {
      isErrorOccurred = await this.ProtokolBilesenFComponent_9544_onClick2_();
      if (isErrorOccurred) return true;
    }
  }
}
