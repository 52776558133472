import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IMyProfile_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IMyProfile_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  KisiById: any[];
  KisiById_dummy: any[];
  MyprofileYetki: any[];
  MyprofileYetki_dummy: any[];
  HesapKrediHareketleri: any[];
  HesapKrediHareketleri_dummy: any[];
  KrediBakiye: any[];
  KrediBakiye_dummy: any[];
  MyProfileBasvuruByKisiId: any[];
  MyProfileBasvuruByKisiId_dummy: any[];
  EgitmenProfilMerkez: any[];
  EgitmenProfilMerkez_dummy: any[];
  EgitmenProfilHizmetler: any[];
  EgitmenProfilHizmetler_dummy: any[];
  PhotoFromGallery: string;
  PhotoFromGallery_dummy: string;
  SaveRecord: number;
  SaveRecord_dummy: number;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  SetValueOf: any;
  SetValueOf_dummy: any;
  CopyToClipboard: any;
  CopyToClipboard_dummy: any;
  isComp496824Visible: "visible" | "hidden";
  isComp181611Visible: "visible" | "hidden";
  isComp178094Visible: "visible" | "hidden";
  isComp836892Visible: "visible" | "hidden";
  isComp313262Visible: "visible" | "hidden";
  isComp320486Visible: "visible" | "hidden";
  isComp280888Visible: "visible" | "hidden";
  isComp415420Visible: "visible" | "hidden";
  isComp759942Visible: "visible" | "hidden";
  isComp550682Visible: "visible" | "hidden";
  isComp887500Visible: "visible" | "hidden";
  isComp760068Visible: "visible" | "hidden";
  isComp67333Visible: "visible" | "hidden";
  isComp657399Visible: "visible" | "hidden";
  isComp31136Visible: "visible" | "hidden";
  isComp672577Visible: "visible" | "hidden";
  isComp74029Visible: "visible" | "hidden";
  isComp952299Visible: "visible" | "hidden";
  isComp573906Visible: "visible" | "hidden";
  isComp501539Visible: "visible" | "hidden";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class MyProfile_ScreenBase extends React.PureComponent<IMyProfile_ScreenProps, any> {
  myprofile_864253_value_kuikaTableRef: React.RefObject<any>;
  myprofile_375239_value_kuikaFlexGridRef: React.RefObject<any>;
  myprofile_254953_value_kuikaFlexGridRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 611755, PropertyName: "placeholder", Value: "Start", TagName: "dtBaslangic_placeholder" },
        { Id: 769300, PropertyName: "placeholder", Value: "Finish", TagName: "dtBitis_placeholder" },
        { Id: 48309, PropertyName: "placeholder", Value: "Protocol", TagName: "selProtokol_placeholder" },
        { Id: 586122, PropertyName: "value", Value: "Curent Status", TagName: "Label3_value" },
        { Id: 616100, PropertyName: "value", Value: "Total number of consultants with data:", TagName: "Label8_value" },
        { Id: 352822, PropertyName: "value", Value: "Those with FitScore < 25", TagName: "Label4_value" },
        { Id: 765937, PropertyName: "value", Value: "consultant", TagName: "Label14_value" },
        { Id: 904338, PropertyName: "value", Value: "Those with FitScore 25 - 50", TagName: "Label5_1_value" },
        { Id: 363632, PropertyName: "value", Value: "consultant", TagName: "Label16_value" },
        { Id: 65752, PropertyName: "value", Value: "Those with FitScore 50 - 75", TagName: "Label4_1_value" },
        { Id: 941747, PropertyName: "value", Value: "consultant", TagName: "Label18_value" },
        { Id: 634452, PropertyName: "value", Value: "Those with FitScore 75 - 100", TagName: "Label6_1_value" },
        { Id: 776212, PropertyName: "value", Value: "consultant", TagName: "Label19_value" },
        { Id: 979489, PropertyName: "value", Value: "Active Period:", TagName: "Label2_value" },
        { Id: 601475, PropertyName: "value", Value: "Evaluated", TagName: "Label10_value" },
        { Id: 43827, PropertyName: "value", Value: "consultant", TagName: "Label32_value" },
        { Id: 66513, PropertyName: "value", Value: "Not evaluated", TagName: "Label9_value" },
        { Id: 474298, PropertyName: "value", Value: "consultant", TagName: "Label34_value" },
        {
          Id: 110197,
          PropertyName: "value",
          Value: "PARTICIPATION AND CURRENT STATUS STATITISTICS",
          TagName: "Label11_value"
        },
        {
          Id: 5125580,
          PropertyName: "nodatafoundmessage",
          Value: "No data found.",
          TagName: "tblDashboardcheckuplari_5125580_nodatafoundmessage"
        },
        { Id: 8214351, PropertyName: "title", Value: "Consultant", TagName: "clmFullname_8214351_title" },
        { Id: 7421762, PropertyName: "value", Value: "[datafield:fullname]", TagName: "lblFullname_7421762_value" },
        { Id: 9172801, PropertyName: "title", Value: "Component 1", TagName: "clmBilesen1Puan_9172801_title" },
        {
          Id: 3118420,
          PropertyName: "value",
          Value: "[datafield:bilesen1puan]",
          TagName: "lblBilesen1Puan_3118420_value"
        },
        { Id: 7997680, PropertyName: "title", Value: "Component 2", TagName: "clmBilesen2Puan_7997680_title" },
        {
          Id: 3625460,
          PropertyName: "value",
          Value: "[datafield:bilesen2puan]",
          TagName: "lblBilesen2Puan_3625460_value"
        },
        { Id: 7071839, PropertyName: "title", Value: "Component 3", TagName: "clmBilesen3Puan_7071839_title" },
        {
          Id: 7003050,
          PropertyName: "value",
          Value: "[datafield:bilesen3puan]",
          TagName: "lblBilesen3Puan_7003050_value"
        },
        { Id: 8989893, PropertyName: "title", Value: "Component 4", TagName: "clmBilesen4Puan_8989893_title" },
        {
          Id: 7276599,
          PropertyName: "value",
          Value: "[datafield:bilesen4puan]",
          TagName: "lblBilesen4Puan_7276599_value"
        },
        { Id: 8216050, PropertyName: "title", Value: "Component 5", TagName: "clmBilesen5Puan_8216050_title" },
        {
          Id: 9224188,
          PropertyName: "value",
          Value: "[datafield:bilesen5puan]",
          TagName: "lblBilesen5Puan_9224188_value"
        },
        { Id: 3531977, PropertyName: "title", Value: "Component 6", TagName: "clmBilesen6Puan_3531977_title" },
        {
          Id: 5703748,
          PropertyName: "value",
          Value: "[datafield:bilesen6puan]",
          TagName: "lblBilesen6Puan_5703748_value"
        },
        { Id: 9859756, PropertyName: "title", Value: "Component 7", TagName: "clmBilesen7Puan_9859756_title" },
        {
          Id: 2559166,
          PropertyName: "value",
          Value: "[datafield:bilesen7puan]",
          TagName: "lblBilesen7Puan_2559166_value"
        },
        { Id: 8692831, PropertyName: "title", Value: "Component 8", TagName: "clmBilesen8Puan_8692831_title" },
        {
          Id: 8635235,
          PropertyName: "value",
          Value: "[datafield:bilesen8puan]",
          TagName: "lblBilesen8Puan_8635235_value"
        },
        { Id: 8240454, PropertyName: "title", Value: "FitSkor®", TagName: "clmFitSkor_8240454_title" },
        { Id: 2574500, PropertyName: "value", Value: "[datafield:fitskor]", TagName: "lblFitSkor_2574500_value" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "3820505e-be1a-44d6-9665-57064b4b4d43",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 375781, PropertyName: "placeholder", Value: "Ad" },
        { Id: 68417, PropertyName: "placeholder", Value: "Soyad" },
        { Id: 207, PropertyName: "label", Value: "Kaydet" },
        { Id: 655203, PropertyName: "value", Value: "Email" },
        { Id: 462395, PropertyName: "value", Value: "Cinsiyet" },
        { Id: 306215, PropertyName: "value", Value: "Dogum Tarihi" },
        { Id: 421584, PropertyName: "value", Value: "Cep Telefonu" },
        { Id: 328576, PropertyName: "tab", Value: "Hesap" },
        { Id: 559825, PropertyName: "value", Value: "Hesap No" },
        { Id: 861158, PropertyName: "value", Value: "Kredi" },
        { Id: 864253, PropertyName: "nodatafoundmessage", Value: "Kredi hareketi bulunamadı." },
        { Id: 401424, PropertyName: "title", Value: "Title" },
        { Id: 507830, PropertyName: "value", Value: "[datafield:tarih]" },
        { Id: 872637, PropertyName: "title", Value: "Title" },
        { Id: 134716, PropertyName: "value", Value: "[datafield:kredi]" },
        { Id: 148320, PropertyName: "title", Value: "Title" },
        { Id: 152917, PropertyName: "value", Value: "[datafield:hesaptanimi]" },
        { Id: 759942, PropertyName: "tab", Value: "Eğitmen Profili" },
        { Id: 447643, PropertyName: "value", Value: "Eğitim Düzeyi" },
        { Id: 588187, PropertyName: "value", Value: "Mezun olduğu okul" },
        { Id: 259278, PropertyName: "value", Value: "Bölüm" },
        { Id: 585711, PropertyName: "value", Value: "Lisans Üstü" },
        { Id: 769874, PropertyName: "value", Value: "Tez Konusu" },
        { Id: 130636, PropertyName: "value", Value: "Derece" },
        { Id: 808798, PropertyName: "value", Value: "Sertifika" },
        { Id: 198467, PropertyName: "value", Value: "Üniversite" },
        { Id: 689007, PropertyName: "value", Value: "Bölüm" },
        { Id: 892804, PropertyName: "value", Value: "Meslek" },
        { Id: 55795, PropertyName: "value", Value: "Meslek Tecrübesi" },
        { Id: 320297, PropertyName: "value", Value: "Kulüp" },
        { Id: 587329, PropertyName: "value", Value: "Brans" },
        { Id: 472873, PropertyName: "value", Value: "Okul" },
        { Id: 711486, PropertyName: "value", Value: "Sınıf" },
        { Id: 979106, PropertyName: "value", Value: "Merkez" },
        { Id: 296152, PropertyName: "value", Value: "Verdiği Dersler" },
        { Id: 665401, PropertyName: "value", Value: "Özel Populasyon" },
        { Id: 573906, PropertyName: "tab", Value: "Merkezler" },
        { Id: 375239, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 631102, PropertyName: "value", Value: "[datafield:unvani]" },
        { Id: 835149, PropertyName: "value", Value: "[datafield:acikadres]" },
        { Id: 501539, PropertyName: "tab", Value: "Hizmetler" },
        { Id: 254953, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 921807, PropertyName: "value", Value: "[datafield:title]" },
        { Id: 975919, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 262978, PropertyName: "value", Value: "Süre" },
        { Id: 817154, PropertyName: "value", Value: "[datafield:sure]" },
        { Id: 402248, PropertyName: "value", Value: "dk" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.myprofile_864253_value_kuikaTableRef = React.createRef();
    this.myprofile_375239_value_kuikaFlexGridRef = React.createRef();
    this.myprofile_254953_value_kuikaFlexGridRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      KisiById: [],
      MyprofileYetki: [],
      HesapKrediHareketleri: [],
      KrediBakiye: [],
      MyProfileBasvuruByKisiId: [],
      EgitmenProfilMerkez: [],
      EgitmenProfilHizmetler: [],
      PhotoFromGallery: "",
      SaveRecord: false,
      ChangeVisibilityOf: "",
      SetValueOf: "",
      CopyToClipboard: "",
      isComp496824Visible: "hidden",
      isComp181611Visible: "hidden",
      isComp178094Visible: "hidden",
      isComp836892Visible: "hidden",
      isComp313262Visible: "hidden",
      isComp320486Visible: "hidden",
      isComp280888Visible: "hidden",
      isComp415420Visible: "hidden",
      isComp759942Visible: "hidden",
      isComp550682Visible: "hidden",
      isComp887500Visible: "hidden",
      isComp760068Visible: "hidden",
      isComp67333Visible: "hidden",
      isComp657399Visible: "hidden",
      isComp31136Visible: "hidden",
      isComp672577Visible: "hidden",
      isComp74029Visible: "hidden",
      isComp952299Visible: "hidden",
      isComp573906Visible: "hidden",
      isComp501539Visible: "hidden",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("myprofile", "master_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.MyProfilePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("myprofile", "master_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("myprofile", "master_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.MyProfilePageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.MyProfilePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      myprofile_473096_value: this.state.KisiById?.at?.(0)?.resim ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  checkTabErrors = () => {
    document.querySelectorAll(".rc-tabs-tab").forEach((tab) => {
      (tab as HTMLElement).style.color = "";
    });

    const tabPanes = document.querySelectorAll(".rc-tabs-tabpane");
    const tabs = document.querySelectorAll(".rc-tabs-tab");

    tabPanes.forEach((tabPane, index) => {
      const uniquePaneId = `rc-tabs-tabpane-${index}`;
      if (!tabPane.hasAttribute("id")) {
        tabPane.setAttribute("id", uniquePaneId);
      }

      const uniqueTabId = `rc-tabs-tab-${index}`;
      if (tabs[index]) {
        if (!tabs[index].hasAttribute("id")) {
          tabs[index].setAttribute("id", uniqueTabId);
        }
        tabs[index].setAttribute("aria-controls", uniquePaneId);
      }
    });

    const errorElements = document.querySelectorAll(".rc-tabs .ant-form-item-explain-error");

    const tabsWithErrors = new Set<string>();

    errorElements.forEach((errorElement) => {
      const tabPane = errorElement.closest(".rc-tabs-tabpane");
      if (tabPane && tabPane.id) {
        tabsWithErrors.add(tabPane.id);
      }
    });

    tabsWithErrors.forEach((tabPaneId) => {
      const tab = document.querySelector(`.rc-tabs-tab[aria-controls="${tabPaneId}"]`);
      if (tab) {
        (tab as HTMLElement).style.color = "red";
      }
    });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  MyProfilePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MyProfile/MyProfilePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.myprofile_868610_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].hesapId
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].hesapId
        : null
    );
    stateVars.isComp181611Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].hesapId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].hesapId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KisiById = result?.data.kisiById;
    formVars.myprofile_473096_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].resim
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].resim
        : null
    );
    formVars.myprofile_531789_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].adi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].adi
        : null
    );
    formVars.myprofile_700025_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].soyadi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].soyadi
        : null
    );
    formVars.myprofile_142748_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].eposta
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].eposta
        : null
    );
    formVars.myprofile_741078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].cinsiyet
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].cinsiyet
        : null
    );
    formVars.myprofile_742265_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].dogumTarihi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].dogumTarihi
        : null
    );
    formVars.myprofile_288912_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].telefon
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].telefon
        : null
    );
    stateVars.isComp759942Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiById?.length > 0
            ? stateVars.KisiById[0].basvuruEgitmenId
            : this.state.KisiById?.length > 0
            ? this.state.KisiById[0].basvuruEgitmenId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.MyprofileYetki = result?.data.myprofileYetki;
    stateVars.isComp573906Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyprofileYetki?.length > 0
            ? stateVars.MyprofileYetki[0].merkezlerTab
            : this.state.MyprofileYetki?.length > 0
            ? this.state.MyprofileYetki[0].merkezlerTab
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp501539Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyprofileYetki?.length > 0
            ? stateVars.MyprofileYetki[0].hizmetlerTab
            : this.state.MyprofileYetki?.length > 0
            ? this.state.MyprofileYetki[0].hizmetlerTab
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.HesapKrediHareketleri = result?.data.hesapKrediHareketleri;

    stateVars.KrediBakiye = result?.data.krediBakiye;
    formVars.myprofile_592037_value = ReactSystemFunctions.toString(
      this,
      stateVars.KrediBakiye?.length > 0
        ? stateVars.KrediBakiye[0].bakiye
        : this.state.KrediBakiye?.length > 0
        ? this.state.KrediBakiye[0].bakiye
        : null
    );
    stateVars.MyProfileBasvuruByKisiId = result?.data.myProfileBasvuruByKisiId;
    formVars.myprofile_643608_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].egitimDuzey
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].egitimDuzey
        : null
    );
    formVars.myprofile_419377_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].lisansMezun
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].lisansMezun
        : null
    );
    formVars.myprofile_8731_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].lisansBolum
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].lisansBolum
        : null
    );
    formVars.myprofile_607663_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].lisansustuDerece
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].lisansustuDerece
        : null
    );
    formVars.myprofile_706480_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].lisansustuKonu
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].lisansustuKonu
        : null
    );
    formVars.myprofile_227075_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].liseDerece
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].liseDerece
        : null
    );
    formVars.myprofile_817930_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].liseSertifika
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].liseSertifika
        : null
    );
    formVars.myprofile_106253_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].ogrenciUni
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].ogrenciUni
        : null
    );
    formVars.myprofile_528008_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].ogrenciBolum
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].ogrenciBolum
        : null
    );
    formVars.myprofile_399091_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].meslek
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].meslek
        : null
    );
    formVars.myprofile_710290_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].tecrubeSure
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].tecrubeSure
        : null
    );
    formVars.myprofile_562893_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].antrenorKlup
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].antrenorKlup
        : null
    );
    formVars.myprofile_482152_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].antrenorBrans
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].antrenorBrans
        : null
    );
    formVars.myprofile_679412_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].bedenOkul
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].bedenOkul
        : null
    );
    formVars.myprofile_139656_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].bedenSinif
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].bedenSinif
        : null
    );
    formVars.myprofile_203763_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].fitnessMerkez
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].fitnessMerkez
        : null
    );
    formVars.myprofile_73541_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].fitnessDers
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].fitnessDers
        : null
    );
    formVars.myprofile_472696_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].ozelPopulasyon
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].ozelPopulasyon
        : null
    );
    stateVars.isComp550682Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].egitimDuzey
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].egitimDuzey
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887500Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].egitimDuzey
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].egitimDuzey
            : null
        ),
        "Lisans"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp760068Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].egitimDuzey
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].egitimDuzey
            : null
        ),
        "Lisans üstü"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp67333Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].egitimDuzey
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].egitimDuzey
            : null
        ),
        "Lise"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp657399Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].egitimDuzey
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].egitimDuzey
            : null
        ),
        "Spor bilimleri fakültesinde öğrenciyim"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp31136Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].meslek
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].meslek
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp672577Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].meslek
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].meslek
            : null
        ),
        "Antrenör"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp74029Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].meslek
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].meslek
            : null
        ),
        "Beden eğitimi öğretmeni"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp952299Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].meslek
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].meslek
            : null
        ),
        "Fitness eğitmeni"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.EgitmenProfilMerkez = result?.data.egitmenProfilMerkez;

    stateVars.EgitmenProfilHizmetler = result?.data.egitmenProfilHizmetler;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyProfilePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyProfilePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.myprofile_473096_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].resim
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].resim
        : null
    );

    formVars.myprofile_531789_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].adi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].adi
        : null
    );

    formVars.myprofile_700025_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].soyadi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].soyadi
        : null
    );

    formVars.myprofile_142748_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].eposta
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].eposta
        : null
    );

    formVars.myprofile_741078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].cinsiyet
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].cinsiyet
        : null
    );

    formVars.myprofile_742265_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].dogumTarihi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].dogumTarihi
        : null
    );

    formVars.myprofile_288912_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].telefon
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].telefon
        : null
    );

    formVars.myprofile_868610_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].hesapId
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].hesapId
        : null
    );

    stateVars.isComp181611Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].hesapId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].hesapId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.myprofile_592037_value = ReactSystemFunctions.toString(
      this,
      stateVars.KrediBakiye?.length > 0
        ? stateVars.KrediBakiye[0].bakiye
        : this.state.KrediBakiye?.length > 0
        ? this.state.KrediBakiye[0].bakiye
        : null
    );

    stateVars.dataSource_864253 = this.state.HesapKrediHareketleri;
    stateVars.isComp178094Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "myprofile_864253_value", "isArtiVisible"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp836892Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "myprofile_864253_value", "isMinusVisible"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp313262Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "myprofile_864253_value", "isFromMe"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp320486Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "myprofile_864253_value", "isFromMe"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp280888Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "myprofile_864253_value", "isFromMe"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp415420Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "myprofile_864253_value", "isFromMe"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp759942Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiById?.length > 0
            ? stateVars.KisiById[0].basvuruEgitmenId
            : this.state.KisiById?.length > 0
            ? this.state.KisiById[0].basvuruEgitmenId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550682Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].egitimDuzey
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].egitimDuzey
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.myprofile_643608_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].egitimDuzey
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].egitimDuzey
        : null
    );

    stateVars.isComp887500Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].egitimDuzey
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].egitimDuzey
            : null
        ),
        "Lisans"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myprofile_419377_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].lisansMezun
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].lisansMezun
        : null
    );

    formVars.myprofile_8731_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].lisansBolum
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].lisansBolum
        : null
    );

    stateVars.isComp760068Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].egitimDuzey
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].egitimDuzey
            : null
        ),
        "Lisans üstü"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myprofile_607663_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].lisansustuDerece
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].lisansustuDerece
        : null
    );

    formVars.myprofile_706480_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].lisansustuKonu
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].lisansustuKonu
        : null
    );

    stateVars.isComp67333Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].egitimDuzey
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].egitimDuzey
            : null
        ),
        "Lise"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myprofile_227075_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].liseDerece
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].liseDerece
        : null
    );

    formVars.myprofile_817930_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].liseSertifika
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].liseSertifika
        : null
    );

    stateVars.isComp657399Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].egitimDuzey
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].egitimDuzey
            : null
        ),
        "Spor bilimleri fakültesinde öğrenciyim"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myprofile_106253_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].ogrenciUni
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].ogrenciUni
        : null
    );

    formVars.myprofile_528008_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].ogrenciBolum
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].ogrenciBolum
        : null
    );

    stateVars.isComp31136Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].meslek
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].meslek
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.myprofile_399091_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].meslek
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].meslek
        : null
    );

    formVars.myprofile_710290_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].tecrubeSure
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].tecrubeSure
        : null
    );

    stateVars.isComp672577Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].meslek
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].meslek
            : null
        ),
        "Antrenör"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myprofile_562893_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].antrenorKlup
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].antrenorKlup
        : null
    );

    formVars.myprofile_482152_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].antrenorBrans
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].antrenorBrans
        : null
    );

    stateVars.isComp74029Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].meslek
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].meslek
            : null
        ),
        "Beden eğitimi öğretmeni"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myprofile_679412_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].bedenOkul
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].bedenOkul
        : null
    );

    formVars.myprofile_139656_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].bedenSinif
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].bedenSinif
        : null
    );

    stateVars.isComp952299Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyProfileBasvuruByKisiId?.length > 0
            ? stateVars.MyProfileBasvuruByKisiId[0].meslek
            : this.state.MyProfileBasvuruByKisiId?.length > 0
            ? this.state.MyProfileBasvuruByKisiId[0].meslek
            : null
        ),
        "Fitness eğitmeni"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myprofile_203763_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].fitnessMerkez
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].fitnessMerkez
        : null
    );

    formVars.myprofile_73541_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].fitnessDers
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].fitnessDers
        : null
    );

    formVars.myprofile_472696_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyProfileBasvuruByKisiId?.length > 0
        ? stateVars.MyProfileBasvuruByKisiId[0].ozelPopulasyon
        : this.state.MyProfileBasvuruByKisiId?.length > 0
        ? this.state.MyProfileBasvuruByKisiId[0].ozelPopulasyon
        : null
    );

    stateVars.isComp573906Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyprofileYetki?.length > 0
            ? stateVars.MyprofileYetki[0].merkezlerTab
            : this.state.MyprofileYetki?.length > 0
            ? this.state.MyprofileYetki[0].merkezlerTab
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_375239 = this.state.EgitmenProfilMerkez;
    stateVars.isComp501539Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyprofileYetki?.length > 0
            ? stateVars.MyprofileYetki[0].hizmetlerTab
            : this.state.MyprofileYetki?.length > 0
            ? this.state.MyprofileYetki[0].hizmetlerTab
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_254953 = this.state.EgitmenProfilHizmetler;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  MyProfileComponent_41413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyProfileComponent_41413_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MyProfileComponent_41413_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      ),
      Resim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.PhotoFromGallery),
        "string"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "MyProfile/MyProfileComponent_41413_onClick1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_1;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MyProfileComponent_41413_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MyProfileComponent_41413_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyProfileComponent_41413_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MyProfile/MyProfileComponent_41413_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiById = result?.data.kisiById;
    formVars.myprofile_473096_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].resim
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].resim
        : null
    );
    formVars.myprofile_531789_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].adi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].adi
        : null
    );
    formVars.myprofile_700025_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].soyadi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].soyadi
        : null
    );
    formVars.myprofile_142748_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].eposta
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].eposta
        : null
    );
    formVars.myprofile_741078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].cinsiyet
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].cinsiyet
        : null
    );
    formVars.myprofile_742265_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].dogumTarihi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].dogumTarihi
        : null
    );
    formVars.myprofile_288912_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].telefon
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].telefon
        : null
    );
    stateVars.isComp759942Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiById?.length > 0
            ? stateVars.KisiById[0].basvuruEgitmenId
            : this.state.KisiById?.length > 0
            ? this.state.KisiById[0].basvuruEgitmenId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyProfileComponent_41413_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyProfileComponent_41413_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.myprofile_473096_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].resim
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].resim
        : null
    );

    formVars.myprofile_531789_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].adi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].adi
        : null
    );

    formVars.myprofile_700025_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].soyadi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].soyadi
        : null
    );

    formVars.myprofile_142748_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].eposta
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].eposta
        : null
    );

    formVars.myprofile_741078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].cinsiyet
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].cinsiyet
        : null
    );

    formVars.myprofile_742265_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].dogumTarihi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].dogumTarihi
        : null
    );

    formVars.myprofile_288912_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].telefon
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].telefon
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MyProfileComponent_971525_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Resim_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MyProfile/MyProfileComponent_971525_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.KisiById = result?.data.kisiById;
    formVars.myprofile_473096_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].resim
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].resim
        : null
    );
    formVars.myprofile_531789_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].adi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].adi
        : null
    );
    formVars.myprofile_700025_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].soyadi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].soyadi
        : null
    );
    formVars.myprofile_142748_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].eposta
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].eposta
        : null
    );
    formVars.myprofile_741078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].cinsiyet
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].cinsiyet
        : null
    );
    formVars.myprofile_742265_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].dogumTarihi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].dogumTarihi
        : null
    );
    formVars.myprofile_288912_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].telefon
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].telefon
        : null
    );
    stateVars.isComp759942Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiById?.length > 0
            ? stateVars.KisiById[0].basvuruEgitmenId
            : this.state.KisiById?.length > 0
            ? this.state.KisiById[0].basvuruEgitmenId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyProfileComponent_971525_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyProfileComponent_971525_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.myprofile_473096_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].resim
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].resim
        : null
    );

    formVars.myprofile_531789_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].adi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].adi
        : null
    );

    formVars.myprofile_700025_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].soyadi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].soyadi
        : null
    );

    formVars.myprofile_142748_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].eposta
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].eposta
        : null
    );

    formVars.myprofile_741078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].cinsiyet
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].cinsiyet
        : null
    );

    formVars.myprofile_742265_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].dogumTarihi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].dogumTarihi
        : null
    );

    formVars.myprofile_288912_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].telefon
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].telefon
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MyProfileComponent_5202_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp496824Visible",
      "visible"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MyProfileComponent_207_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "myprofile_375781_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "myprofile_375781_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "myprofile_68417_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "myprofile_68417_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "myprofile_68417_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "myprofile_68417_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      ),
      Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "myprofile_375781_value", "value", "", "", "")
        ),
        "string"
      ),
      Soyadi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "myprofile_68417_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MyProfile/MyProfileComponent_207_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_3;
    stateVars.KisiById = result?.data.kisiById;
    formVars.myprofile_473096_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].resim
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].resim
        : null
    );
    formVars.myprofile_531789_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].adi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].adi
        : null
    );
    formVars.myprofile_700025_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].soyadi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].soyadi
        : null
    );
    formVars.myprofile_142748_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].eposta
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].eposta
        : null
    );
    formVars.myprofile_741078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].cinsiyet
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].cinsiyet
        : null
    );
    formVars.myprofile_742265_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].dogumTarihi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].dogumTarihi
        : null
    );
    formVars.myprofile_288912_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].telefon
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].telefon
        : null
    );
    stateVars.isComp759942Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiById?.length > 0
            ? stateVars.KisiById[0].basvuruEgitmenId
            : this.state.KisiById?.length > 0
            ? this.state.KisiById[0].basvuruEgitmenId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyProfileComponent_207_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyProfileComponent_207_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "myprofile_375781_value", "", null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "myprofile_68417_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp496824Visible", "hidden");
    formVars.myprofile_473096_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].resim
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].resim
        : null
    );

    formVars.myprofile_531789_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].adi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].adi
        : null
    );

    formVars.myprofile_700025_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].soyadi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].soyadi
        : null
    );

    formVars.myprofile_142748_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].eposta
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].eposta
        : null
    );

    formVars.myprofile_741078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].cinsiyet
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].cinsiyet
        : null
    );

    formVars.myprofile_742265_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].dogumTarihi
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].dogumTarihi
        : null
    );

    formVars.myprofile_288912_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiById?.length > 0
        ? stateVars.KisiById[0].telefon
        : this.state.KisiById?.length > 0
        ? this.state.KisiById[0].telefon
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MyProfileComponent_181611_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.CopyToClipboard = await ReactSystemFunctions.copyToClipboard(
      ReactSystemFunctions.toString(
        this,
        stateVars.MyContext?.length > 0
          ? stateVars.MyContext[0].hesapId
          : this.state.MyContext?.length > 0
          ? this.state.MyContext[0].hesapId
          : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
