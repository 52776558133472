import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IMyReports_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IMyReports_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  KisiProtokolSonDurum: any[];
  KisiProtokolSonDurum_dummy: any[];
  KisiProtokolCheckupList: any[];
  KisiProtokolCheckupList_dummy: any[];
  KisiCheckuplari: any[];
  KisiCheckuplari_dummy: any[];
  MyContext: any[];
  MyContext_dummy: any[];
  KisiSelectById: any[];
  KisiSelectById_dummy: any[];
  KisiUygulanmisCheckupProtokolleri: any[];
  KisiUygulanmisCheckupProtokolleri_dummy: any[];
  KisiCheckupBilesenleri: any[];
  KisiCheckupBilesenleri_dummy: any[];
  KisiCheckupBilesenTarihcesi: any[];
  KisiCheckupBilesenTarihcesi_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp320842Visible: "visible" | "hidden";
  isComp542065Visible: "visible" | "hidden";
  isComp735571Visible: "visible" | "hidden";
  isComp427734Visible: "visible" | "hidden";
  isComp627849Visible: "visible" | "hidden";
  isComp950775Visible: "visible" | "hidden";
  isComp181085Visible: "visible" | "hidden";
  isComp225756Visible: "visible" | "hidden";
  isComp446402Visible: "visible" | "hidden";
  isComp74481Visible: "visible" | "hidden";
  isComp517796Visible: "visible" | "hidden";
  isComp386981Visible: "visible" | "hidden";
  isComp955426Visible: "visible" | "hidden";
  isComp573165Visible: "visible" | "hidden";
  isComp456460Visible: "visible" | "hidden";
  isComp830535Visible: "visible" | "hidden";
  isComp709495Visible: "visible" | "hidden";
  isComp824502Visible: "visible" | "hidden";
  isComp893443Visible: "visible" | "hidden";
  isComp598572Visible: "visible" | "hidden";
  isComp57776Visible: "visible" | "hidden";
  isComp338549Visible: "visible" | "hidden";
  isComp522321Visible: "visible" | "hidden";
  isComp420641Visible: "visible" | "hidden";
  isComp78756Visible: "visible" | "hidden";
  isComp303063Visible: "visible" | "hidden";
  isComp313800Visible: "visible" | "hidden";
  isComp138106Visible: "visible" | "hidden";
  isComp296097Visible: "visible" | "hidden";
  isComp32816Visible: "visible" | "hidden";
  isComp739924Visible: "visible" | "hidden";
  isComp693741Visible: "visible" | "hidden";
  isComp412710Visible: "visible" | "hidden";
  isComp151278Visible: "visible" | "hidden";
  isComp366311Visible: "visible" | "hidden";
  isComp263780Visible: "visible" | "hidden";
  isComp506737Visible: "visible" | "hidden";
  isComp281194Visible: "visible" | "hidden";
  isComp905068Visible: "visible" | "hidden";
  isComp56344Visible: "visible" | "hidden";
  isComp553325Visible: "visible" | "hidden";
  isComp699930Visible: "visible" | "hidden";
  isComp427034Visible: "visible" | "hidden";
  isComp587740Visible: "visible" | "hidden";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class MyReports_ScreenBase extends React.PureComponent<IMyReports_ScreenProps, any> {
  myreports_703229_value_kuikaSelectBoxRef: React.RefObject<any>;
  myreports_843663_value_kuikaTableRef: React.RefObject<any>;
  myreports_380483_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "c6345b4f-c1f7-4437-a73b-2abaad7d6c5e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 483561, PropertyName: "value", Value: "Raporlarım" },
        { Id: 703229, PropertyName: "placeholder", Value: "Protokol" },
        { Id: 122247, PropertyName: "value", Value: "Yaş:" },
        { Id: 477003, PropertyName: "tab", Value: "Check-Up'lar" },
        { Id: 843663, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 191658, PropertyName: "title", Value: "Checkup Tarihi" },
        { Id: 18222, PropertyName: "value", Value: "[datafield:checkuptarihi]" },
        { Id: 849429, PropertyName: "title", Value: "Protokol Adı" },
        { Id: 81786, PropertyName: "value", Value: "[datafield:protokol]" },
        { Id: 268173, PropertyName: "title", Value: "Yaş" },
        { Id: 622863, PropertyName: "value", Value: "[datafield:yas]" },
        { Id: 785524, PropertyName: "title", Value: "Boy" },
        { Id: 305063, PropertyName: "value", Value: "[datafield:boy]" },
        { Id: 677870, PropertyName: "title", Value: "Ağırlık" },
        { Id: 251421, PropertyName: "value", Value: "[datafield:boy]" },
        { Id: 975968, PropertyName: "title", Value: "FitSkor" },
        { Id: 926429, PropertyName: "value", Value: "[datafield:fitskor]" },
        { Id: 245986, PropertyName: "tab", Value: "Analizler" },
        { Id: 371040, PropertyName: "value", Value: "Bileşen" },
        { Id: 380483, PropertyName: "placeholder", Value: "Seçiniz..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.myreports_703229_value_kuikaSelectBoxRef = React.createRef();
    this.myreports_843663_value_kuikaTableRef = React.createRef();
    this.myreports_380483_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      KisiProtokolSonDurum: [],
      KisiProtokolCheckupList: [],
      KisiCheckuplari: [],
      MyContext: [],
      KisiSelectById: [],
      KisiUygulanmisCheckupProtokolleri: [],
      KisiCheckupBilesenleri: [],
      KisiCheckupBilesenTarihcesi: [],
      NAVIGATE: "",
      isComp320842Visible: "hidden",
      isComp542065Visible: "hidden",
      isComp735571Visible: "hidden",
      isComp427734Visible: "hidden",
      isComp627849Visible: "hidden",
      isComp950775Visible: "hidden",
      isComp181085Visible: "hidden",
      isComp225756Visible: "hidden",
      isComp446402Visible: "hidden",
      isComp74481Visible: "hidden",
      isComp517796Visible: "hidden",
      isComp386981Visible: "hidden",
      isComp955426Visible: "hidden",
      isComp573165Visible: "hidden",
      isComp456460Visible: "hidden",
      isComp830535Visible: "hidden",
      isComp709495Visible: "hidden",
      isComp824502Visible: "hidden",
      isComp893443Visible: "hidden",
      isComp598572Visible: "hidden",
      isComp57776Visible: "hidden",
      isComp338549Visible: "hidden",
      isComp522321Visible: "hidden",
      isComp420641Visible: "hidden",
      isComp78756Visible: "hidden",
      isComp303063Visible: "hidden",
      isComp313800Visible: "hidden",
      isComp138106Visible: "hidden",
      isComp296097Visible: "hidden",
      isComp32816Visible: "hidden",
      isComp739924Visible: "hidden",
      isComp693741Visible: "hidden",
      isComp412710Visible: "hidden",
      isComp151278Visible: "hidden",
      isComp366311Visible: "hidden",
      isComp263780Visible: "hidden",
      isComp506737Visible: "hidden",
      isComp281194Visible: "hidden",
      isComp905068Visible: "hidden",
      isComp56344Visible: "hidden",
      isComp553325Visible: "hidden",
      isComp699930Visible: "hidden",
      isComp427034Visible: "hidden",
      isComp587740Visible: "hidden",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("myreports", "master_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.MyReportsPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("myreports", "master_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("myreports", "master_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.MyReportsPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.MyReportsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      myreports_703229_value: this.state.KisiUygulanmisCheckupProtokolleri?.at?.(0)?.protokolId ?? undefined,
      myreports_380483_value: this.state.KisiCheckupBilesenleri?.at?.(0)?.bilesenId ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  checkTabErrors = () => {
    document.querySelectorAll(".rc-tabs-tab").forEach((tab) => {
      (tab as HTMLElement).style.color = "";
    });

    const tabPanes = document.querySelectorAll(".rc-tabs-tabpane");
    const tabs = document.querySelectorAll(".rc-tabs-tab");

    tabPanes.forEach((tabPane, index) => {
      const uniquePaneId = `rc-tabs-tabpane-${index}`;
      if (!tabPane.hasAttribute("id")) {
        tabPane.setAttribute("id", uniquePaneId);
      }

      const uniqueTabId = `rc-tabs-tab-${index}`;
      if (tabs[index]) {
        if (!tabs[index].hasAttribute("id")) {
          tabs[index].setAttribute("id", uniqueTabId);
        }
        tabs[index].setAttribute("aria-controls", uniquePaneId);
      }
    });

    const errorElements = document.querySelectorAll(".rc-tabs .ant-form-item-explain-error");

    const tabsWithErrors = new Set<string>();

    errorElements.forEach((errorElement) => {
      const tabPane = errorElement.closest(".rc-tabs-tabpane");
      if (tabPane && tabPane.id) {
        tabsWithErrors.add(tabPane.id);
      }
    });

    tabsWithErrors.forEach((tabPaneId) => {
      const tab = document.querySelector(`.rc-tabs-tab[aria-controls="${tabPaneId}"]`);
      if (tab) {
        (tab as HTMLElement).style.color = "red";
      }
    });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  MyReportsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MyReports/MyReportsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.KisiSelectById = result?.data.kisiSelectById;
    formVars.myreports_640843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );
    formVars.myreports_152914_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );
    formVars.myreports_313997_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].yas
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].yas
        : null
    );
    stateVars.isComp320842Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "E"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp542065Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "K"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KisiUygulanmisCheckupProtokolleri = result?.data.kisiUygulanmisCheckupProtokolleri;

    formVars.myreports_703229_value =
      stateVars.KisiUygulanmisCheckupProtokolleri?.length > 0
        ? stateVars.KisiUygulanmisCheckupProtokolleri[0].protokolId
        : this.state.KisiUygulanmisCheckupProtokolleri?.length > 0
        ? this.state.KisiUygulanmisCheckupProtokolleri[0].protokolId
        : null;
    formVars.myreports_703229_options = stateVars.KisiUygulanmisCheckupProtokolleri;
    stateVars.KisiProtokolSonDurum = result?.data.kisiProtokolSonDurum;
    formVars.myreports_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );
    formVars.myreports_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );
    formVars.myreports_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );
    formVars.myreports_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );
    formVars.myreports_974768_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );
    formVars.myreports_957267_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );
    formVars.myreports_108465_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );
    formVars.myreports_655714_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );
    formVars.myreports_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );
    formVars.myreports_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );
    formVars.myreports_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );
    formVars.myreports_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );
    formVars.myreports_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );
    formVars.myreports_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );
    formVars.myreports_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );
    formVars.myreports_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );
    formVars.myreports_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );
    formVars.myreports_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );
    formVars.myreports_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );
    formVars.myreports_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );
    formVars.myreports_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );
    formVars.myreports_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );
    formVars.myreports_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );
    formVars.myreports_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );
    formVars.myreports_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );
    formVars.myreports_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );
    formVars.myreports_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );
    formVars.myreports_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );
    formVars.myreports_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );
    formVars.myreports_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );
    formVars.myreports_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );
    formVars.myreports_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );
    formVars.myreports_546478_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );
    formVars.myreports_389339_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );
    formVars.myreports_961248_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );
    formVars.myreports_720325_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen9Val3
        : null
    );
    formVars.myreports_784409_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Adi
        : null
    );
    formVars.myreports_868525_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );
    formVars.myreports_413774_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );
    formVars.myreports_315611_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Val3
        : null
    );
    formVars.myreports_537639_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );
    formVars.myreports_186776_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );
    formVars.myreports_238860_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );
    formVars.myreports_609477_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );
    formVars.myreports_238173_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );
    formVars.myreports_239736_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );
    formVars.myreports_570485_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );
    formVars.myreports_209038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );
    formVars.myreports_846822_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );
    formVars.myreports_557697_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );
    formVars.myreports_321900_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );
    formVars.myreports_344866_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );
    formVars.myreports_280807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );
    formVars.myreports_20841_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );
    formVars.myreports_429796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );
    formVars.myreports_204492_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].fitSkorVal3
        : null
    );
    stateVars.isComp735571Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp427734Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627849Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp950775Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp181085Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp446402Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp74481Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517796Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp386981Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp955426Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp573165Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456460Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830535Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp824502Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp893443Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp598572Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57776Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp338549Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp522321Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420641Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp313800Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138106Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp296097Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp32816Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp739924Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp693741Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp412710Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp151278Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp366311Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp263780Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506737Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp281194Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905068Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp56344Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp553325Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp699930Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp427034Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KisiProtokolCheckupList = result?.data.kisiProtokolCheckupList;
    stateVars.KisiCheckupBilesenleri = result?.data.kisiCheckupBilesenleri;

    formVars.myreports_380483_value =
      stateVars.KisiCheckupBilesenleri?.length > 0
        ? stateVars.KisiCheckupBilesenleri[0].bilesenId
        : this.state.KisiCheckupBilesenleri?.length > 0
        ? this.state.KisiCheckupBilesenleri[0].bilesenId
        : null;
    formVars.myreports_380483_options = stateVars.KisiCheckupBilesenleri;
    stateVars.KisiCheckupBilesenTarihcesi = result?.data.kisiCheckupBilesenTarihcesi;

    stateVars.KisiCheckuplari = result?.data.kisiCheckuplari;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyReportsPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyReportsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.myreports_703229_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiUygulanmisCheckupProtokolleri?.length > 0
        ? stateVars.KisiUygulanmisCheckupProtokolleri[0].protokolId
        : this.state.KisiUygulanmisCheckupProtokolleri?.length > 0
        ? this.state.KisiUygulanmisCheckupProtokolleri[0].protokolId
        : null
    );

    formVars.myreports_703229_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiUygulanmisCheckupProtokolleri?.length > 0
        ? stateVars.KisiUygulanmisCheckupProtokolleri[0].protokolId
        : this.state.KisiUygulanmisCheckupProtokolleri?.length > 0
        ? this.state.KisiUygulanmisCheckupProtokolleri[0].protokolId
        : null
    );

    stateVars.dataSource_703229 = this.state.KisiUygulanmisCheckupProtokolleri;
    stateVars.dataSource_703229 = this.state.KisiUygulanmisCheckupProtokolleri;
    stateVars.isComp320842Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "E"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp542065Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "K"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_640843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );

    formVars.myreports_152914_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );

    formVars.myreports_313997_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].yas
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].yas
        : null
    );

    stateVars.isComp735571Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );

    formVars.myreports_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );

    formVars.myreports_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );

    stateVars.isComp427734Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627849Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );

    stateVars.isComp950775Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_974768_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );

    formVars.myreports_957267_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );

    formVars.myreports_108465_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );

    stateVars.isComp181085Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_655714_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );

    stateVars.isComp446402Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );

    formVars.myreports_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );

    formVars.myreports_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );

    stateVars.isComp74481Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517796Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );

    stateVars.isComp386981Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );

    formVars.myreports_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );

    formVars.myreports_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );

    stateVars.isComp955426Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp573165Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );

    stateVars.isComp456460Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );

    formVars.myreports_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );

    formVars.myreports_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );

    stateVars.isComp830535Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );

    stateVars.isComp824502Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );

    formVars.myreports_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );

    formVars.myreports_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );

    stateVars.isComp893443Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp598572Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );

    stateVars.isComp57776Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );

    formVars.myreports_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );

    formVars.myreports_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );

    stateVars.isComp338549Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp522321Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );

    stateVars.isComp420641Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );

    formVars.myreports_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );

    formVars.myreports_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );

    stateVars.isComp78756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );

    stateVars.isComp313800Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_546478_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );

    formVars.myreports_389339_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );

    formVars.myreports_961248_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );

    stateVars.isComp138106Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp296097Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_720325_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen9Val3
        : null
    );

    stateVars.isComp32816Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_784409_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Adi
        : null
    );

    formVars.myreports_868525_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );

    formVars.myreports_413774_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );

    stateVars.isComp739924Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp693741Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_315611_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Val3
        : null
    );

    stateVars.isComp412710Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_537639_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );

    formVars.myreports_186776_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );

    formVars.myreports_238860_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );

    stateVars.isComp151278Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp366311Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_609477_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );

    stateVars.isComp263780Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_238173_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );

    formVars.myreports_239736_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );

    formVars.myreports_570485_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );

    stateVars.isComp506737Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp281194Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_209038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );

    stateVars.isComp905068Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_846822_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );

    formVars.myreports_557697_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );

    formVars.myreports_321900_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );

    stateVars.isComp56344Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp553325Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_344866_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );

    formVars.myreports_280807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );

    formVars.myreports_20841_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );

    formVars.myreports_429796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );

    stateVars.isComp699930Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp427034Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.myreports_204492_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].fitSkorVal3
        : null
    );

    stateVars.dataSource_843663 = this.state.KisiCheckuplari;
    formVars.myreports_380483_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiCheckupBilesenleri?.length > 0
        ? stateVars.KisiCheckupBilesenleri[0].bilesenId
        : this.state.KisiCheckupBilesenleri?.length > 0
        ? this.state.KisiCheckupBilesenleri[0].bilesenId
        : null
    );

    formVars.myreports_380483_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiCheckupBilesenleri?.length > 0
        ? stateVars.KisiCheckupBilesenleri[0].bilesenId
        : this.state.KisiCheckupBilesenleri?.length > 0
        ? this.state.KisiCheckupBilesenleri[0].bilesenId
        : null
    );

    stateVars.dataSource_380483 = this.state.KisiCheckupBilesenleri;
    stateVars.dataSource_380483 = this.state.KisiCheckupBilesenleri;

    stateVars.dataSource_26391 = this.state.KisiCheckupBilesenTarihcesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  MyReportsComponent_703229_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "myreports_703229_value",
            "value",
            "KisiUygulanmisCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      ),
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "myreports_703229_value",
            "value",
            "KisiUygulanmisCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      ),
      rolename_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      ),
      protokolId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "myreports_703229_value",
            "value",
            "KisiUygulanmisCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MyReports/MyReportsComponent_703229_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiProtokolSonDurum = result?.data.kisiProtokolSonDurum;
    formVars.myreports_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );
    formVars.myreports_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );
    formVars.myreports_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );
    formVars.myreports_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );
    formVars.myreports_974768_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );
    formVars.myreports_957267_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );
    formVars.myreports_108465_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );
    formVars.myreports_655714_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );
    formVars.myreports_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );
    formVars.myreports_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );
    formVars.myreports_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );
    formVars.myreports_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );
    formVars.myreports_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );
    formVars.myreports_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );
    formVars.myreports_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );
    formVars.myreports_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );
    formVars.myreports_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );
    formVars.myreports_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );
    formVars.myreports_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );
    formVars.myreports_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );
    formVars.myreports_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );
    formVars.myreports_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );
    formVars.myreports_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );
    formVars.myreports_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );
    formVars.myreports_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );
    formVars.myreports_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );
    formVars.myreports_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );
    formVars.myreports_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );
    formVars.myreports_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );
    formVars.myreports_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );
    formVars.myreports_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );
    formVars.myreports_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );
    formVars.myreports_546478_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );
    formVars.myreports_389339_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );
    formVars.myreports_961248_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );
    formVars.myreports_720325_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen9Val3
        : null
    );
    formVars.myreports_784409_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Adi
        : null
    );
    formVars.myreports_868525_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );
    formVars.myreports_413774_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );
    formVars.myreports_315611_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Val3
        : null
    );
    formVars.myreports_537639_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );
    formVars.myreports_186776_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );
    formVars.myreports_238860_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );
    formVars.myreports_609477_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );
    formVars.myreports_238173_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );
    formVars.myreports_239736_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );
    formVars.myreports_570485_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );
    formVars.myreports_209038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );
    formVars.myreports_846822_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );
    formVars.myreports_557697_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );
    formVars.myreports_321900_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );
    formVars.myreports_344866_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );
    formVars.myreports_280807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );
    formVars.myreports_20841_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );
    formVars.myreports_429796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );
    formVars.myreports_204492_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].fitSkorVal3
        : null
    );
    stateVars.isComp735571Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp427734Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627849Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp950775Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp181085Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp446402Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp74481Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517796Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp386981Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp955426Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp573165Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456460Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830535Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp824502Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp893443Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp598572Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57776Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp338549Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp522321Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420641Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp313800Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138106Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp296097Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp32816Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp739924Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp693741Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp412710Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp151278Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp366311Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp263780Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506737Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp281194Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905068Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp56344Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp553325Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp699930Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp427034Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KisiProtokolCheckupList = result?.data.kisiProtokolCheckupList;
    stateVars.KisiCheckuplari = result?.data.kisiCheckuplari;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyReportsComponent_703229_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyReportsComponent_703229_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.myreports_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );

    formVars.myreports_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );

    formVars.myreports_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );

    formVars.myreports_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );

    formVars.myreports_974768_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );

    formVars.myreports_957267_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );

    formVars.myreports_108465_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );

    formVars.myreports_655714_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );

    formVars.myreports_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );

    formVars.myreports_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );

    formVars.myreports_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );

    formVars.myreports_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );

    formVars.myreports_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );

    formVars.myreports_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );

    formVars.myreports_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );

    formVars.myreports_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );

    formVars.myreports_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );

    formVars.myreports_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );

    formVars.myreports_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );

    formVars.myreports_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );

    formVars.myreports_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );

    formVars.myreports_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );

    formVars.myreports_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );

    formVars.myreports_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );

    formVars.myreports_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );

    formVars.myreports_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );

    formVars.myreports_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );

    formVars.myreports_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );

    formVars.myreports_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );

    formVars.myreports_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );

    formVars.myreports_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );

    formVars.myreports_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );

    formVars.myreports_546478_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );

    formVars.myreports_389339_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );

    formVars.myreports_961248_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );

    formVars.myreports_720325_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen9Val3
        : null
    );

    formVars.myreports_784409_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Adi
        : null
    );

    formVars.myreports_868525_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );

    formVars.myreports_413774_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );

    formVars.myreports_315611_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Val3
        : null
    );

    formVars.myreports_537639_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );

    formVars.myreports_186776_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );

    formVars.myreports_238860_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );

    formVars.myreports_609477_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );

    formVars.myreports_238173_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );

    formVars.myreports_239736_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );

    formVars.myreports_570485_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );

    formVars.myreports_209038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );

    formVars.myreports_846822_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );

    formVars.myreports_557697_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );

    formVars.myreports_321900_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );

    formVars.myreports_344866_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );

    formVars.myreports_280807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );

    formVars.myreports_20841_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );

    formVars.myreports_429796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );

    formVars.myreports_204492_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].fitSkorVal3
        : null
    );

    stateVars.dataSource_843663 = this.state.KisiCheckuplari;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MyReportsComponent_587740_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CheckupReport", "ShowDownload", true);
    KuikaAppManager.addToPageInputVariables(
      "CheckupReport",
      "CheckupId",
      ReactSystemFunctions.value(this, "myreports_843663_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MyReports",
      "CheckupReport",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "417648",
      null,
      "right",
      null,
      "1080px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MyReportsComponent_720422_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CheckupReport3", "ShowDownload", true);
    KuikaAppManager.addToPageInputVariables(
      "CheckupReport3",
      "CheckupId",
      ReactSystemFunctions.value(this, "myreports_843663_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MyReports",
      "CheckupReport3",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "957099",
      null,
      "right",
      null,
      "1080px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MyReportsComponent_380483_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      ),
      bilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "myreports_380483_value",
            "value",
            "KisiCheckupBilesenleri",
            "bilesenId",
            "bilesenId"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MyReports/MyReportsComponent_380483_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiCheckupBilesenTarihcesi = result?.data.kisiCheckupBilesenTarihcesi;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyReportsComponent_380483_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyReportsComponent_380483_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_26391 = this.state.KisiCheckupBilesenTarihcesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [417648, 957099] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.MyReportsPageInit();
    }
  }
}
