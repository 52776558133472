import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKurumProfile_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKurumProfile_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  KurumProfilYetki: any[];
  KurumProfilYetki_dummy: any[];
  KurumSelectById: any[];
  KurumSelectById_dummy: any[];
  KrediBakiye: any[];
  KrediBakiye_dummy: any[];
  HesapKrediHareketleri: any[];
  HesapKrediHareketleri_dummy: any[];
  KurumProfilHizmetler: any[];
  KurumProfilHizmetler_dummy: any[];
  KurumProfilMerkez: any[];
  KurumProfilMerkez_dummy: any[];
  KurumunEgitmenleri: any[];
  KurumunEgitmenleri_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp56729Visible: "visible" | "hidden";
  isComp675792Visible: "visible" | "hidden";
  isComp710614Visible: "visible" | "hidden";
  isComp609724Visible: "visible" | "hidden";
  isComp978433Visible: "visible" | "hidden";
  isComp286624Visible: "visible" | "hidden";
  isComp14765Visible: "visible" | "hidden";
  isComp11825Visible: "visible" | "hidden";
  isComp782242Visible: "visible" | "hidden";
  isComp290396Visible: "visible" | "hidden";
  isComp225899Visible: "visible" | "hidden";
  isComp563057Visible: "visible" | "hidden";
  isComp419389Visible: "visible" | "hidden";
  isComp471331Visible: "visible" | "hidden";
  isComp379830Visible: "visible" | "hidden";
  isComp198847Visible: "visible" | "hidden";
  isComp207918Enabled: "enabled" | "disabled";
  isComp35132Enabled: "enabled" | "disabled";
  isComp422527Enabled: "enabled" | "disabled";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class KurumProfile_ScreenBase extends React.PureComponent<IKurumProfile_ScreenProps, any> {
  kurumprofile_454389_value_kuikaFlexGridRef: React.RefObject<any>;
  kurumprofile_285820_value_kuikaFlexGridRef: React.RefObject<any>;
  kurumprofile_842605_value_kuikaTableRef: React.RefObject<any>;
  kurumprofile_163621_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "b48d22da-f3de-4a1a-a874-6b57e7494e80",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 382551, PropertyName: "value", Value: "Branş" },
        { Id: 923656, PropertyName: "value", Value: "Değerlendirme" },
        { Id: 115778, PropertyName: "value", Value: "Faaliyet Süresi" },
        { Id: 975030, PropertyName: "value", Value: "Lisans Bitiş Tarihi" },
        { Id: 333201, PropertyName: "tab", Value: "Kurum Profili" },
        { Id: 248927, PropertyName: "value", Value: "Kurum Adı" },
        { Id: 964641, PropertyName: "value", Value: "Web Adresi" },
        { Id: 38622, PropertyName: "value", Value: "Merkez Telefonu" },
        { Id: 295055, PropertyName: "value", Value: "Adres" },
        { Id: 11825, PropertyName: "tab", Value: "Merkezler" },
        { Id: 454389, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 202374, PropertyName: "value", Value: "[datafield:unvani]" },
        { Id: 35639, PropertyName: "value", Value: "[datafield:acikadres]" },
        { Id: 622517, PropertyName: "tab", Value: "Hizmetler" },
        { Id: 285820, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 435581, PropertyName: "value", Value: "[datafield:title]" },
        { Id: 375927, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 800595, PropertyName: "value", Value: "Süre" },
        { Id: 702056, PropertyName: "value", Value: "[datafield:sure]" },
        { Id: 418116, PropertyName: "value", Value: "dk" },
        { Id: 842605, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 452391, PropertyName: "title", Value: "Ad Soyad" },
        { Id: 409171, PropertyName: "value", Value: "[datafield:fullname]" },
        { Id: 246891, PropertyName: "title", Value: "Telefon" },
        { Id: 348274, PropertyName: "value", Value: "[datafield:telefon]" },
        { Id: 804996, PropertyName: "tab", Value: "Hesap" },
        { Id: 545131, PropertyName: "value", Value: "Hesap No" },
        { Id: 603575, PropertyName: "value", Value: "Kredi" },
        { Id: 163621, PropertyName: "nodatafoundmessage", Value: "Kredi hareketi bulunamadı." },
        { Id: 94930, PropertyName: "title", Value: "Title" },
        { Id: 240344, PropertyName: "value", Value: "[datafield:tarih]" },
        { Id: 307677, PropertyName: "title", Value: "Title" },
        { Id: 52183, PropertyName: "value", Value: "[datafield:kredi]" },
        { Id: 701043, PropertyName: "title", Value: "Title" },
        { Id: 670767, PropertyName: "value", Value: "[datafield:hesaptanimi]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.kurumprofile_454389_value_kuikaFlexGridRef = React.createRef();
    this.kurumprofile_285820_value_kuikaFlexGridRef = React.createRef();
    this.kurumprofile_842605_value_kuikaTableRef = React.createRef();
    this.kurumprofile_163621_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      KurumProfilYetki: [],
      KurumSelectById: [],
      KrediBakiye: [],
      HesapKrediHareketleri: [],
      KurumProfilHizmetler: [],
      KurumProfilMerkez: [],
      KurumunEgitmenleri: [],
      NAVIGATE: "",
      isComp56729Visible: "hidden",
      isComp675792Visible: "hidden",
      isComp710614Visible: "hidden",
      isComp609724Visible: "hidden",
      isComp978433Visible: "hidden",
      isComp286624Visible: "hidden",
      isComp14765Visible: "hidden",
      isComp11825Visible: "hidden",
      isComp782242Visible: "hidden",
      isComp290396Visible: "hidden",
      isComp225899Visible: "hidden",
      isComp563057Visible: "hidden",
      isComp419389Visible: "hidden",
      isComp471331Visible: "hidden",
      isComp379830Visible: "hidden",
      isComp198847Visible: "hidden",
      isComp207918Enabled: "disabled",
      isComp35132Enabled: "disabled",
      isComp422527Enabled: "disabled",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("kurumprofile", "master_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.KurumProfilePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("kurumprofile", "master_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("kurumprofile", "master_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.KurumProfilePageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.KurumProfilePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      kurumprofile_829952_value: this.state.KurumSelectById?.at?.(0)?.logo ?? undefined,
      kurumprofile_207918_value: this.state.KurumSelectById?.at?.(0)?.rate ?? undefined,
      kurumprofile_480747_value: this.state.KurumSelectById?.at?.(0)?.ortakLogo ?? undefined,
      kurumprofile_35132_value: this.state.KurumSelectById?.at?.(0)?.ortakRate ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  checkTabErrors = () => {
    document.querySelectorAll(".rc-tabs-tab").forEach((tab) => {
      (tab as HTMLElement).style.color = "";
    });

    const tabPanes = document.querySelectorAll(".rc-tabs-tabpane");
    const tabs = document.querySelectorAll(".rc-tabs-tab");

    tabPanes.forEach((tabPane, index) => {
      const uniquePaneId = `rc-tabs-tabpane-${index}`;
      if (!tabPane.hasAttribute("id")) {
        tabPane.setAttribute("id", uniquePaneId);
      }

      const uniqueTabId = `rc-tabs-tab-${index}`;
      if (tabs[index]) {
        if (!tabs[index].hasAttribute("id")) {
          tabs[index].setAttribute("id", uniqueTabId);
        }
        tabs[index].setAttribute("aria-controls", uniquePaneId);
      }
    });

    const errorElements = document.querySelectorAll(".rc-tabs .ant-form-item-explain-error");

    const tabsWithErrors = new Set<string>();

    errorElements.forEach((errorElement) => {
      const tabPane = errorElement.closest(".rc-tabs-tabpane");
      if (tabPane && tabPane.id) {
        tabsWithErrors.add(tabPane.id);
      }
    });

    tabsWithErrors.forEach((tabPaneId) => {
      const tab = document.querySelector(`.rc-tabs-tab[aria-controls="${tabPaneId}"]`);
      if (tab) {
        (tab as HTMLElement).style.color = "red";
      }
    });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  KurumProfilePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "KurumProfile/KurumProfilePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.isComp290396Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].hesapId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].hesapId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KurumProfilYetki = result?.data.kurumProfilYetki;
    stateVars.isComp675792Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumProfilYetki?.length > 0
            ? stateVars.KurumProfilYetki[0].degerlendirme
            : this.state.KurumProfilYetki?.length > 0
            ? this.state.KurumProfilYetki[0].degerlendirme
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp710614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumProfilYetki?.length > 0
            ? stateVars.KurumProfilYetki[0].faaliyetSure
            : this.state.KurumProfilYetki?.length > 0
            ? this.state.KurumProfilYetki[0].faaliyetSure
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp609724Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumProfilYetki?.length > 0
            ? stateVars.KurumProfilYetki[0].telefonYetki
            : this.state.KurumProfilYetki?.length > 0
            ? this.state.KurumProfilYetki[0].telefonYetki
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp978433Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumProfilYetki?.length > 0
            ? stateVars.KurumProfilYetki[0].adres
            : this.state.KurumProfilYetki?.length > 0
            ? this.state.KurumProfilYetki[0].adres
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp286624Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumProfilYetki?.length > 0
            ? stateVars.KurumProfilYetki[0].isOrtagiKolon
            : this.state.KurumProfilYetki?.length > 0
            ? this.state.KurumProfilYetki[0].isOrtagiKolon
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp782242Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumProfilYetki?.length > 0
            ? stateVars.KurumProfilYetki[0].egitmenTab
            : this.state.KurumProfilYetki?.length > 0
            ? this.state.KurumProfilYetki[0].egitmenTab
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KurumSelectById = result?.data.kurumSelectById;
    formVars.kurumprofile_829952_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].logo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].logo
        : null
    );
    formVars.kurumprofile_341862_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].unvani
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].unvani
        : null
    );
    formVars.kurumprofile_202366_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].branstanim
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].branstanim
        : null
    );
    formVars.kurumprofile_21401_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].licenceExpiry
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].licenceExpiry
        : null
    );
    formVars.kurumprofile_519437_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[stateVars.KurumSelectById.length - 1].unvani
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[this.state.KurumSelectById.length - 1].unvani
        : null
    );
    formVars.kurumprofile_582743_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].webAdresi
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].webAdresi
        : null
    );
    formVars.kurumprofile_876932_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].phoneNumber
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].phoneNumber
        : null
    );
    formVars.kurumprofile_753915_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].acikAdres
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].acikAdres
        : null
    );
    formVars.kurumprofile_480747_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].ortakLogo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].ortakLogo
        : null
    );
    formVars.kurumprofile_127851_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].ortakUnvan
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].ortakUnvan
        : null
    );
    formVars.kurumprofile_861305_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].ortakAcikAdres
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].ortakAcikAdres
        : null
    );
    formVars.kurumprofile_819935_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].hesapId
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].hesapId
        : null
    );
    stateVars.isComp56729Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].kurumTipi
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].kurumTipi
            : null
        ),
        "KT_Club"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp14765Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].isOrtagiKurumId
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].isOrtagiKurumId
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KrediBakiye = result?.data.krediBakiye;
    formVars.kurumprofile_944806_value = ReactSystemFunctions.toString(
      this,
      stateVars.KrediBakiye?.length > 0
        ? stateVars.KrediBakiye[0].bakiye
        : this.state.KrediBakiye?.length > 0
        ? this.state.KrediBakiye[0].bakiye
        : null
    );
    stateVars.HesapKrediHareketleri = result?.data.hesapKrediHareketleri;

    stateVars.KurumProfilHizmetler = result?.data.kurumProfilHizmetler;

    stateVars.KurumProfilMerkez = result?.data.kurumProfilMerkez;

    stateVars.KurumunEgitmenleri = result?.data.kurumunEgitmenleri;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KurumProfilePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KurumProfilePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.kurumprofile_829952_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].logo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].logo
        : null
    );

    formVars.kurumprofile_341862_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].unvani
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].unvani
        : null
    );

    stateVars.isComp56729Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].kurumTipi
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].kurumTipi
            : null
        ),
        "KT_Club"
      ) === true
        ? "visible"
        : "hidden";
    formVars.kurumprofile_202366_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].branstanim
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].branstanim
        : null
    );

    stateVars.isComp675792Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumProfilYetki?.length > 0
            ? stateVars.KurumProfilYetki[0].degerlendirme
            : this.state.KurumProfilYetki?.length > 0
            ? this.state.KurumProfilYetki[0].degerlendirme
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.kurumprofile_207918_value = ReactSystemFunctions.value(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].rate
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].rate
        : null
    );

    stateVars.isComp710614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumProfilYetki?.length > 0
            ? stateVars.KurumProfilYetki[0].faaliyetSure
            : this.state.KurumProfilYetki?.length > 0
            ? this.state.KurumProfilYetki[0].faaliyetSure
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.kurumprofile_21401_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].licenceExpiry
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].licenceExpiry
        : null
    );

    formVars.kurumprofile_519437_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[stateVars.KurumSelectById.length - 1].unvani
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[this.state.KurumSelectById.length - 1].unvani
        : null
    );

    formVars.kurumprofile_582743_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].webAdresi
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].webAdresi
        : null
    );

    stateVars.isComp609724Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumProfilYetki?.length > 0
            ? stateVars.KurumProfilYetki[0].telefonYetki
            : this.state.KurumProfilYetki?.length > 0
            ? this.state.KurumProfilYetki[0].telefonYetki
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.kurumprofile_876932_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].phoneNumber
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].phoneNumber
        : null
    );

    stateVars.isComp978433Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumProfilYetki?.length > 0
            ? stateVars.KurumProfilYetki[0].adres
            : this.state.KurumProfilYetki?.length > 0
            ? this.state.KurumProfilYetki[0].adres
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.kurumprofile_753915_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].acikAdres
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].acikAdres
        : null
    );

    stateVars.isComp286624Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumProfilYetki?.length > 0
            ? stateVars.KurumProfilYetki[0].isOrtagiKolon
            : this.state.KurumProfilYetki?.length > 0
            ? this.state.KurumProfilYetki[0].isOrtagiKolon
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp14765Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].isOrtagiKurumId
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].isOrtagiKurumId
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.kurumprofile_480747_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].ortakLogo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].ortakLogo
        : null
    );

    formVars.kurumprofile_127851_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].ortakUnvan
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].ortakUnvan
        : null
    );

    formVars.kurumprofile_861305_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].ortakAcikAdres
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].ortakAcikAdres
        : null
    );

    formVars.kurumprofile_35132_value = ReactSystemFunctions.value(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].ortakRate
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].ortakRate
        : null
    );

    stateVars.dataSource_454389 = this.state.KurumProfilMerkez;

    stateVars.dataSource_285820 = this.state.KurumProfilHizmetler;
    formVars.kurumprofile_782242_tab = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].egitmenlerAdi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].egitmenlerAdi
        : null
    );

    stateVars.isComp782242Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumProfilYetki?.length > 0
            ? stateVars.KurumProfilYetki[0].egitmenTab
            : this.state.KurumProfilYetki?.length > 0
            ? this.state.KurumProfilYetki[0].egitmenTab
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_842605 = this.state.KurumunEgitmenleri;
    formVars.kurumprofile_819935_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].hesapId
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].hesapId
        : null
    );

    stateVars.isComp290396Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].hesapId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].hesapId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.kurumprofile_944806_value = ReactSystemFunctions.toString(
      this,
      stateVars.KrediBakiye?.length > 0
        ? stateVars.KrediBakiye[0].bakiye
        : this.state.KrediBakiye?.length > 0
        ? this.state.KrediBakiye[0].bakiye
        : null
    );

    stateVars.dataSource_163621 = this.state.HesapKrediHareketleri;
    stateVars.isComp225899Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "kurumprofile_163621_value", "isArtiVisible"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp563057Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "kurumprofile_163621_value", "isMinusVisible"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp419389Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "kurumprofile_163621_value", "isToMe"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp471331Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "kurumprofile_163621_value", "isToMe"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp379830Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "kurumprofile_163621_value", "isFromMe"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp198847Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "kurumprofile_163621_value", "isFromMe"), "1") ===
      true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  KurumProfileComponent_286131_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PersonCard",
      "kisiId",
      ReactSystemFunctions.value(this, "kurumprofile_842605_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KurumProfile",
      "PersonCard",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.KurumProfilePageInit();
    }
  }
}
