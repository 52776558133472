import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITopluCheckupForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITopluCheckupForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ComponentToPdf: string;
  ComponentToPdf_dummy: string;
  OpenPdf: any;
  OpenPdf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  ProtokolAll: any[];
  ProtokolAll_dummy: any[];
  TopluCheckupProtokolleri: any[];
  TopluCheckupProtokolleri_dummy: any[];
  TopluOlcumById: any[];
  TopluOlcumById_dummy: any[];
  CheckupDonemiSelectById: any[];
  CheckupDonemiSelectById_dummy: any[];
  Kirilim2SelectById: any[];
  Kirilim2SelectById_dummy: any[];
  TopluOlcumListSinif: any[];
  TopluOlcumListSinif_dummy: any[];
  TopluCheckupData: any[];
  TopluCheckupData_dummy: any[];
  TopluCheckupMeta: any[];
  TopluCheckupMeta_dummy: any[];
  TopluCheckupHeader: any[];
  TopluCheckupHeader_dummy: any[];
  TopluProtokolunHizmetleri: any[];
  TopluProtokolunHizmetleri_dummy: any[];
  KurumTipiSelectById: any[];
  KurumTipiSelectById_dummy: any[];
  CheckupYapabilirMi: any[];
  CheckupYapabilirMi_dummy: any[];
  DummySave: any[];
  DummySave_dummy: any[];
  CheckupSave: number;
  CheckupSave_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp481026Visible: "visible" | "hidden";
  isComp898895Visible: "visible" | "hidden";
  isComp497250Visible: "visible" | "hidden";
  isComp979561Visible: "visible" | "hidden";
  isComp424498Visible: "visible" | "hidden";
  isComp546133Visible: "visible" | "hidden";
  isComp462202Visible: "visible" | "hidden";
  isComp835411Visible: "visible" | "hidden";
  isComp151351Visible: "visible" | "hidden";
  isComp784165Visible: "visible" | "hidden";
  isComp91584Visible: "visible" | "hidden";
  isComp310545Visible: "visible" | "hidden";
  isComp160474Visible: "visible" | "hidden";
  isComp78167Visible: "visible" | "hidden";
  isComp387430Visible: "visible" | "hidden";
  isComp704141Visible: "visible" | "hidden";
  isComp336857Visible: "visible" | "hidden";
  isComp792927Visible: "visible" | "hidden";
  isComp42999Visible: "visible" | "hidden";
  isComp226875Visible: "visible" | "hidden";
  isComp359189Visible: "visible" | "hidden";
  isComp954429Visible: "visible" | "hidden";
  isComp431272Visible: "visible" | "hidden";
  isComp678647Visible: "visible" | "hidden";
  isComp199695Visible: "visible" | "hidden";
  isComp202915Visible: "visible" | "hidden";
  isComp594223Visible: "visible" | "hidden";
  isComp475997Visible: "visible" | "hidden";
  isComp847159Visible: "visible" | "hidden";
  isComp463640Visible: "visible" | "hidden";
  isComp172581Visible: "visible" | "hidden";
  isComp110161Visible: "visible" | "hidden";
  isComp582884Visible: "visible" | "hidden";
  isComp23814Visible: "visible" | "hidden";
  isComp645853Visible: "visible" | "hidden";
  isComp28192Visible: "visible" | "hidden";
  isComp453979Visible: "visible" | "hidden";
  isComp548700Visible: "visible" | "hidden";
  isComp721230Visible: "visible" | "hidden";
  isComp429685Visible: "visible" | "hidden";
  isComp263652Visible: "visible" | "hidden";
  isComp960655Visible: "visible" | "hidden";
  isComp906742Visible: "visible" | "hidden";
  isComp56785Visible: "visible" | "hidden";
  isComp356694Visible: "visible" | "hidden";
  isComp622949Visible: "visible" | "hidden";
  isComp497496Visible: "visible" | "hidden";
  isComp557923Visible: "visible" | "hidden";
  isComp560680Visible: "visible" | "hidden";
  isComp736403Visible: "visible" | "hidden";
  isComp666108Visible: "visible" | "hidden";
  isComp932627Visible: "visible" | "hidden";
  isComp813286Visible: "visible" | "hidden";
  isComp61000Visible: "visible" | "hidden";
  isComp705881Visible: "visible" | "hidden";
  isComp517439Visible: "visible" | "hidden";
  isComp895563Visible: "visible" | "hidden";
  isComp968208Visible: "visible" | "hidden";
  isComp644514Enabled: "enabled" | "disabled";
  isComp81288Enabled: "enabled" | "disabled";
  isComp323452Enabled: "enabled" | "disabled";
  isComp999173Enabled: "enabled" | "disabled";
  isComp450440Enabled: "enabled" | "disabled";
}

export class TopluCheckupForm_ScreenBase extends React.PureComponent<ITopluCheckupForm_ScreenProps, any> {
  toplucheckupform_323452_value_kuikaSelectBoxRef: React.RefObject<any>;
  toplucheckupform_999173_value_kuikaSelectBoxRef: React.RefObject<any>;
  toplucheckupform_450440_value_kuikaDateRef: React.RefObject<any>;
  toplucheckupform_682481_value_kuikaSelectBoxRef: React.RefObject<any>;
  toplucheckupform_66828_value_kuikaTableRef: React.RefObject<any>;
  toplucheckupform_968208_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "aef7a4df-bf13-4558-841a-41de6107492e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 834770, PropertyName: "value", Value: "Toplu Check-Up" },
        { Id: 201103, PropertyName: "value", Value: "Check-Up Dönemi" },
        { Id: 644514, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 81288, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 560729, PropertyName: "value", Value: "Protokol" },
        { Id: 323452, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 716586, PropertyName: "value", Value: "Hizmet" },
        { Id: 999173, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 592376, PropertyName: "value", Value: "Check-Up Tarihi" },
        { Id: 450440, PropertyName: "placeholder", Value: "Tarih seçiniz..." },
        { Id: 830465, PropertyName: "value", Value: "Filtre" },
        { Id: 682481, PropertyName: "placeholder", Value: "Kız/Erkek" },
        { Id: 66828, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 497250, PropertyName: "title", Value: "Title" },
        { Id: 564522, PropertyName: "value", Value: "[datafield:okulnumarasi]" },
        { Id: 979561, PropertyName: "title", Value: "Title" },
        { Id: 658492, PropertyName: "value", Value: "[datafield:dogumtarihi]" },
        { Id: 221634, PropertyName: "title", Value: "Title" },
        { Id: 763435, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 340114, PropertyName: "title", Value: "Title" },
        { Id: 804455, PropertyName: "value", Value: "[datafield:yas]" },
        { Id: 307288, PropertyName: "title", Value: "Title" },
        { Id: 689148, PropertyName: "value", Value: "[datafield:cinsiyet]" },
        { Id: 961470, PropertyName: "title", Value: "Title" },
        { Id: 327388, PropertyName: "value", Value: "[datafield:boy]" },
        { Id: 106761, PropertyName: "placeholder", Value: "15" },
        { Id: 40132, PropertyName: "title", Value: "Title" },
        { Id: 363607, PropertyName: "value", Value: "[datafield:agirlik]" },
        { Id: 501608, PropertyName: "placeholder", Value: "15" },
        { Id: 424498, PropertyName: "title", Value: "Title" },
        { Id: 277958, PropertyName: "value", Value: "[datafield:test1girdi1]" },
        { Id: 20172, PropertyName: "placeholder", Value: "15" },
        { Id: 546133, PropertyName: "title", Value: "Title" },
        { Id: 465045, PropertyName: "value", Value: "[datafield:test1girdi2]" },
        { Id: 978563, PropertyName: "placeholder", Value: "15" },
        { Id: 462202, PropertyName: "title", Value: "Title" },
        { Id: 854388, PropertyName: "value", Value: "[datafield:test1girdi3]" },
        { Id: 74054, PropertyName: "placeholder", Value: "15" },
        { Id: 835411, PropertyName: "title", Value: "Title" },
        { Id: 165526, PropertyName: "value", Value: "[datafield:test1girdi4]" },
        { Id: 763715, PropertyName: "placeholder", Value: "15" },
        { Id: 151351, PropertyName: "title", Value: "Title" },
        { Id: 675576, PropertyName: "value", Value: "[datafield:test2girdi1]" },
        { Id: 35500, PropertyName: "placeholder", Value: "15" },
        { Id: 784165, PropertyName: "title", Value: "Title" },
        { Id: 205224, PropertyName: "value", Value: "[datafield:test2girdi2]" },
        { Id: 192241, PropertyName: "placeholder", Value: "15" },
        { Id: 91584, PropertyName: "title", Value: "Title" },
        { Id: 53111, PropertyName: "value", Value: "[datafield:test2girdi3]" },
        { Id: 111731, PropertyName: "placeholder", Value: "15" },
        { Id: 310545, PropertyName: "title", Value: "Title" },
        { Id: 680077, PropertyName: "value", Value: "[datafield:test2girdi4]" },
        { Id: 950852, PropertyName: "placeholder", Value: "15" },
        { Id: 160474, PropertyName: "title", Value: "Title" },
        { Id: 909671, PropertyName: "value", Value: "[datafield:test3girdi1]" },
        { Id: 217109, PropertyName: "placeholder", Value: "15" },
        { Id: 78167, PropertyName: "title", Value: "Title" },
        { Id: 285169, PropertyName: "value", Value: "[datafield:test3girdi2]" },
        { Id: 254678, PropertyName: "placeholder", Value: "15" },
        { Id: 387430, PropertyName: "title", Value: "Title" },
        { Id: 618845, PropertyName: "value", Value: "[datafield:test3girdi3]" },
        { Id: 40931, PropertyName: "placeholder", Value: "15" },
        { Id: 704141, PropertyName: "title", Value: "Title" },
        { Id: 207824, PropertyName: "value", Value: "[datafield:test3girdi4]" },
        { Id: 824081, PropertyName: "placeholder", Value: "15" },
        { Id: 336857, PropertyName: "title", Value: "Title" },
        { Id: 286601, PropertyName: "value", Value: "[datafield:test4girdi1]" },
        { Id: 280163, PropertyName: "placeholder", Value: "15" },
        { Id: 792927, PropertyName: "title", Value: "Title" },
        { Id: 920512, PropertyName: "value", Value: "[datafield:test4girdi2]" },
        { Id: 34244, PropertyName: "placeholder", Value: "15" },
        { Id: 42999, PropertyName: "title", Value: "Title" },
        { Id: 962138, PropertyName: "value", Value: "[datafield:test4girdi3]" },
        { Id: 154393, PropertyName: "placeholder", Value: "15" },
        { Id: 226875, PropertyName: "title", Value: "Title" },
        { Id: 367204, PropertyName: "value", Value: "[datafield:test4girdi4]" },
        { Id: 386934, PropertyName: "placeholder", Value: "15" },
        { Id: 359189, PropertyName: "title", Value: "Title" },
        { Id: 382882, PropertyName: "value", Value: "[datafield:test5girdi1]" },
        { Id: 250498, PropertyName: "placeholder", Value: "15" },
        { Id: 954429, PropertyName: "title", Value: "Title" },
        { Id: 124470, PropertyName: "value", Value: "[datafield:test5girdi2]" },
        { Id: 43286, PropertyName: "placeholder", Value: "15" },
        { Id: 431272, PropertyName: "title", Value: "Title" },
        { Id: 838832, PropertyName: "value", Value: "[datafield:test5girdi3]" },
        { Id: 662592, PropertyName: "placeholder", Value: "15" },
        { Id: 678647, PropertyName: "title", Value: "Title" },
        { Id: 822378, PropertyName: "value", Value: "[datafield:test5girdi4]" },
        { Id: 616716, PropertyName: "placeholder", Value: "15" },
        { Id: 199695, PropertyName: "title", Value: "Title" },
        { Id: 230023, PropertyName: "value", Value: "[datafield:test6girdi1]" },
        { Id: 626394, PropertyName: "placeholder", Value: "15" },
        { Id: 202915, PropertyName: "title", Value: "Title" },
        { Id: 47499, PropertyName: "value", Value: "[datafield:test6girdi2]" },
        { Id: 310060, PropertyName: "placeholder", Value: "15" },
        { Id: 594223, PropertyName: "title", Value: "Title" },
        { Id: 604739, PropertyName: "value", Value: "[datafield:test6girdi3]" },
        { Id: 355110, PropertyName: "placeholder", Value: "15" },
        { Id: 475997, PropertyName: "title", Value: "Title" },
        { Id: 489725, PropertyName: "value", Value: "[datafield:test6girdi4]" },
        { Id: 496577, PropertyName: "placeholder", Value: "15" },
        { Id: 847159, PropertyName: "title", Value: "Title" },
        { Id: 893911, PropertyName: "value", Value: "[datafield:test7girdi1]" },
        { Id: 492880, PropertyName: "placeholder", Value: "15" },
        { Id: 463640, PropertyName: "title", Value: "Title" },
        { Id: 991527, PropertyName: "value", Value: "[datafield:test7girdi2]" },
        { Id: 945278, PropertyName: "placeholder", Value: "15" },
        { Id: 172581, PropertyName: "title", Value: "Title" },
        { Id: 162222, PropertyName: "value", Value: "[datafield:test7girdi3]" },
        { Id: 584466, PropertyName: "placeholder", Value: "15" },
        { Id: 110161, PropertyName: "title", Value: "Title" },
        { Id: 431376, PropertyName: "value", Value: "[datafield:test7girdi4]" },
        { Id: 150156, PropertyName: "placeholder", Value: "15" },
        { Id: 582884, PropertyName: "title", Value: "Title" },
        { Id: 612164, PropertyName: "value", Value: "[datafield:test8girdi1]" },
        { Id: 205173, PropertyName: "placeholder", Value: "15" },
        { Id: 23814, PropertyName: "title", Value: "Title" },
        { Id: 438898, PropertyName: "value", Value: "[datafield:test8girdi2]" },
        { Id: 988697, PropertyName: "placeholder", Value: "15" },
        { Id: 645853, PropertyName: "title", Value: "Title" },
        { Id: 72104, PropertyName: "value", Value: "[datafield:test8girdi3]" },
        { Id: 250187, PropertyName: "placeholder", Value: "15" },
        { Id: 28192, PropertyName: "title", Value: "Title" },
        { Id: 75408, PropertyName: "value", Value: "[datafield:test8girdi4]" },
        { Id: 209223, PropertyName: "placeholder", Value: "15" },
        { Id: 453979, PropertyName: "title", Value: "Title" },
        { Id: 865337, PropertyName: "value", Value: "[datafield:test9girdi1]" },
        { Id: 404106, PropertyName: "placeholder", Value: "15" },
        { Id: 548700, PropertyName: "title", Value: "Title" },
        { Id: 395191, PropertyName: "value", Value: "[datafield:test9girdi2]" },
        { Id: 154035, PropertyName: "placeholder", Value: "15" },
        { Id: 721230, PropertyName: "title", Value: "Title" },
        { Id: 255429, PropertyName: "value", Value: "[datafield:test9girdi3]" },
        { Id: 140783, PropertyName: "placeholder", Value: "15" },
        { Id: 429685, PropertyName: "title", Value: "Title" },
        { Id: 6902, PropertyName: "value", Value: "[datafield:test9girdi4]" },
        { Id: 31400, PropertyName: "placeholder", Value: "15" },
        { Id: 263652, PropertyName: "title", Value: "Title" },
        { Id: 145384, PropertyName: "value", Value: "[datafield:test10girdi1]" },
        { Id: 208759, PropertyName: "placeholder", Value: "15" },
        { Id: 960655, PropertyName: "title", Value: "Title" },
        { Id: 245427, PropertyName: "value", Value: "[datafield:test10girdi2]" },
        { Id: 780443, PropertyName: "placeholder", Value: "15" },
        { Id: 906742, PropertyName: "title", Value: "Title" },
        { Id: 714252, PropertyName: "value", Value: "[datafield:test10girdi3]" },
        { Id: 904100, PropertyName: "placeholder", Value: "15" },
        { Id: 56785, PropertyName: "title", Value: "Title" },
        { Id: 734865, PropertyName: "value", Value: "[datafield:test10girdi4]" },
        { Id: 475128, PropertyName: "placeholder", Value: "15" },
        { Id: 356694, PropertyName: "title", Value: "Title" },
        { Id: 187230, PropertyName: "value", Value: "[datafield:test11girdi1]" },
        { Id: 107764, PropertyName: "placeholder", Value: "15" },
        { Id: 622949, PropertyName: "title", Value: "Title" },
        { Id: 866622, PropertyName: "value", Value: "[datafield:test11girdi2]" },
        { Id: 603345, PropertyName: "placeholder", Value: "15" },
        { Id: 497496, PropertyName: "title", Value: "Title" },
        { Id: 724442, PropertyName: "value", Value: "[datafield:test11girdi3]" },
        { Id: 217953, PropertyName: "placeholder", Value: "15" },
        { Id: 557923, PropertyName: "title", Value: "Title" },
        { Id: 505810, PropertyName: "value", Value: "[datafield:test11girdi4]" },
        { Id: 418578, PropertyName: "placeholder", Value: "15" },
        { Id: 560680, PropertyName: "title", Value: "Title" },
        { Id: 723489, PropertyName: "value", Value: "[datafield:test12girdi1]" },
        { Id: 701982, PropertyName: "placeholder", Value: "15" },
        { Id: 736403, PropertyName: "title", Value: "Title" },
        { Id: 949838, PropertyName: "value", Value: "[datafield:test12girdi2]" },
        { Id: 640717, PropertyName: "placeholder", Value: "15" },
        { Id: 666108, PropertyName: "title", Value: "Title" },
        { Id: 452739, PropertyName: "value", Value: "[datafield:test12girdi3]" },
        { Id: 267965, PropertyName: "placeholder", Value: "15" },
        { Id: 932627, PropertyName: "title", Value: "Title" },
        { Id: 491441, PropertyName: "value", Value: "[datafield:test12girdi4]" },
        { Id: 562440, PropertyName: "placeholder", Value: "15" },
        { Id: 813286, PropertyName: "title", Value: "Title" },
        { Id: 995903, PropertyName: "value", Value: "[datafield:test13girdi1]" },
        { Id: 934419, PropertyName: "placeholder", Value: "15" },
        { Id: 61000, PropertyName: "title", Value: "Title" },
        { Id: 453231, PropertyName: "value", Value: "[datafield:test13girdi2]" },
        { Id: 685011, PropertyName: "placeholder", Value: "15" },
        { Id: 705881, PropertyName: "title", Value: "Title" },
        { Id: 216601, PropertyName: "value", Value: "[datafield:test13girdi3]" },
        { Id: 142883, PropertyName: "placeholder", Value: "15" },
        { Id: 517439, PropertyName: "title", Value: "Title" },
        { Id: 12377, PropertyName: "value", Value: "[datafield:test13girdi4]" },
        { Id: 785136, PropertyName: "placeholder", Value: "15" },
        { Id: 250611, PropertyName: "title", Value: "Title" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.toplucheckupform_323452_value_kuikaSelectBoxRef = React.createRef();
    this.toplucheckupform_999173_value_kuikaSelectBoxRef = React.createRef();
    this.toplucheckupform_450440_value_kuikaDateRef = React.createRef();
    this.toplucheckupform_682481_value_kuikaSelectBoxRef = React.createRef();
    this.toplucheckupform_66828_value_kuikaTableRef = React.createRef();
    this.toplucheckupform_968208_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ComponentToPdf: "",
      OpenPdf: "",
      GoBack: "",
      MyContext: [],
      ProtokolAll: [],
      TopluCheckupProtokolleri: [],
      TopluOlcumById: [],
      CheckupDonemiSelectById: [],
      Kirilim2SelectById: [],
      TopluOlcumListSinif: [],
      TopluCheckupData: [],
      TopluCheckupMeta: [],
      TopluCheckupHeader: [],
      TopluProtokolunHizmetleri: [],
      KurumTipiSelectById: [],
      CheckupYapabilirMi: [],
      IfThenElse: false,
      NAVIGATE: "",
      dataSource_682481: [
        { key: "E", text: "Erkek" },
        { key: "K", text: "Kız" }
      ],
      isComp481026Visible: "hidden",
      isComp898895Visible: "hidden",
      isComp497250Visible: "hidden",
      isComp979561Visible: "hidden",
      isComp424498Visible: "hidden",
      isComp546133Visible: "hidden",
      isComp462202Visible: "hidden",
      isComp835411Visible: "hidden",
      isComp151351Visible: "hidden",
      isComp784165Visible: "hidden",
      isComp91584Visible: "hidden",
      isComp310545Visible: "hidden",
      isComp160474Visible: "hidden",
      isComp78167Visible: "hidden",
      isComp387430Visible: "hidden",
      isComp704141Visible: "hidden",
      isComp336857Visible: "hidden",
      isComp792927Visible: "hidden",
      isComp42999Visible: "hidden",
      isComp226875Visible: "hidden",
      isComp359189Visible: "hidden",
      isComp954429Visible: "hidden",
      isComp431272Visible: "hidden",
      isComp678647Visible: "hidden",
      isComp199695Visible: "hidden",
      isComp202915Visible: "hidden",
      isComp594223Visible: "hidden",
      isComp475997Visible: "hidden",
      isComp847159Visible: "hidden",
      isComp463640Visible: "hidden",
      isComp172581Visible: "hidden",
      isComp110161Visible: "hidden",
      isComp582884Visible: "hidden",
      isComp23814Visible: "hidden",
      isComp645853Visible: "hidden",
      isComp28192Visible: "hidden",
      isComp453979Visible: "hidden",
      isComp548700Visible: "hidden",
      isComp721230Visible: "hidden",
      isComp429685Visible: "hidden",
      isComp263652Visible: "hidden",
      isComp960655Visible: "hidden",
      isComp906742Visible: "hidden",
      isComp56785Visible: "hidden",
      isComp356694Visible: "hidden",
      isComp622949Visible: "hidden",
      isComp497496Visible: "hidden",
      isComp557923Visible: "hidden",
      isComp560680Visible: "hidden",
      isComp736403Visible: "hidden",
      isComp666108Visible: "hidden",
      isComp932627Visible: "hidden",
      isComp813286Visible: "hidden",
      isComp61000Visible: "hidden",
      isComp705881Visible: "hidden",
      isComp517439Visible: "hidden",
      isComp895563Visible: "hidden",
      isComp968208Visible: "hidden",
      isComp644514Enabled: "disabled",
      isComp81288Enabled: "disabled",
      isComp323452Enabled: "disabled",
      isComp999173Enabled: "disabled",
      isComp450440Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("toplucheckupform", "");
      return;
    }

    if (true) {
      await this.TopluCheckupFormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("toplucheckupform", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("toplucheckupform", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TopluCheckupFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      toplucheckupform_644514_value: this.state.CheckupDonemiSelectById?.at?.(0)?.donemAdi ?? undefined,
      toplucheckupform_81288_value: this.state.Kirilim2SelectById?.at?.(0)?.tanim ?? undefined,
      toplucheckupform_323452_value: this.state.TopluOlcumById?.at?.(0)?.protokolId ?? undefined,
      toplucheckupform_999173_value: this.state.TopluOlcumById?.at?.(0)?.hizmetID ?? undefined,
      toplucheckupform_450440_value: this.state.TopluOlcumById?.at?.(0)?.tarih ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TopluCheckupFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      kirilim2Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TopluOlcumId ?? this.props.screenInputs.topluolcumid,
        "Guid"
      ),
      Id_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid,
        "Guid"
      ),
      Id_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      topluolcumId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TopluOlcumId ?? this.props.screenInputs.topluolcumid,
        "Guid"
      ),
      TopluOlcumId_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TopluOlcumId ?? this.props.screenInputs.topluolcumid,
        "Guid"
      ),
      kirilim2Id_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      checkupdonemiID_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid,
        "Guid"
      ),
      cinsiyet_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_682481_value", "value", "", "key", "")
        ),
        "string"
      ),
      currentpage_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_968208_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_968208_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      kirilim2ID_10: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TopluCheckupForm/TopluCheckupFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.ProtokolAll = result?.data.protokolAll;
    stateVars.TopluCheckupProtokolleri = result?.data.topluCheckupProtokolleri;

    formVars.toplucheckupform_323452_value =
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].protokolId
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].protokolId
        : null;
    formVars.toplucheckupform_323452_options = stateVars.TopluCheckupProtokolleri;
    stateVars.TopluOlcumById = result?.data.topluOlcumById;
    formVars.toplucheckupform_323452_value =
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].protokolId
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].protokolId
        : null;
    formVars.toplucheckupform_323452_options = stateVars.TopluCheckupProtokolleri;
    formVars.toplucheckupform_999173_value =
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].hizmetID
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].hizmetID
        : null;
    formVars.toplucheckupform_999173_options = stateVars.TopluProtokolunHizmetleri;
    formVars.toplucheckupform_450440_value = ReactSystemFunctions.value(
      this,
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].tarih
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].tarih
        : null
    );
    stateVars.isComp323452Enabled =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].protokolId
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].protokolId
            : null
        ),
        null
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp999173Enabled =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].hizmetID
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].hizmetID
            : null
        ),
        null
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp450440Enabled =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].tarih
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].tarih
            : null
        ),
        null
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.CheckupDonemiSelectById = result?.data.checkupDonemiSelectById;
    formVars.toplucheckupform_644514_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupDonemiSelectById?.length > 0
        ? stateVars.CheckupDonemiSelectById[0].donemAdi
        : this.state.CheckupDonemiSelectById?.length > 0
        ? this.state.CheckupDonemiSelectById[0].donemAdi
        : null
    );
    stateVars.Kirilim2SelectById = result?.data.kirilim2SelectById;
    formVars.toplucheckupform_81288_value = ReactSystemFunctions.toString(
      this,
      stateVars.Kirilim2SelectById?.length > 0
        ? stateVars.Kirilim2SelectById[0].tanim
        : this.state.Kirilim2SelectById?.length > 0
        ? this.state.Kirilim2SelectById[0].tanim
        : null
    );
    stateVars.TopluOlcumListSinif = result?.data.topluOlcumListSinif;
    stateVars.TopluCheckupData = result?.data.topluCheckupData;

    stateVars.TopluCheckupMeta = result?.data.topluCheckupMeta;

    stateVars.isComp424498Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp546133Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp462202Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp835411Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp151351Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp784165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp91584Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp310545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78167Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp387430Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704141Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336857Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp792927Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp42999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp226875Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp359189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954429Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431272Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678647Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp199695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202915Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp594223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475997Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847159Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172581Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110161Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp582884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp23814Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp645853Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28192Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453979Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp548700Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp721230Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp429685Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp263652Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp960655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906742Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp56785Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp356694Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp622949Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497496Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp557923Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp560680Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp736403Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp666108Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp932627Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813286Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61000Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp705881Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517439Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.TopluCheckupHeader = result?.data.topluCheckupHeader;
    stateVars.TopluProtokolunHizmetleri = result?.data.topluProtokolunHizmetleri;

    formVars.toplucheckupform_999173_value =
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].hizmetID
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].hizmetID
        : null;
    formVars.toplucheckupform_999173_options = stateVars.TopluProtokolunHizmetleri;
    stateVars.KurumTipiSelectById = result?.data.kurumTipiSelectById;
    formVars.toplucheckupform_139057_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].ikinciKirilimAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].ikinciKirilimAdi
        : null
    );
    formVars.toplucheckupform_282632_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].ikinciKirilimAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].ikinciKirilimAdi
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TopluCheckupFormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TopluCheckupFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.toplucheckupform_139057_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].ikinciKirilimAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].ikinciKirilimAdi
        : null
    );

    formVars.toplucheckupform_644514_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupDonemiSelectById?.length > 0
        ? stateVars.CheckupDonemiSelectById[0].donemAdi
        : this.state.CheckupDonemiSelectById?.length > 0
        ? this.state.CheckupDonemiSelectById[0].donemAdi
        : null
    );

    formVars.toplucheckupform_282632_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].ikinciKirilimAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].ikinciKirilimAdi
        : null
    );

    formVars.toplucheckupform_81288_value = ReactSystemFunctions.toString(
      this,
      stateVars.Kirilim2SelectById?.length > 0
        ? stateVars.Kirilim2SelectById[0].tanim
        : this.state.Kirilim2SelectById?.length > 0
        ? this.state.Kirilim2SelectById[0].tanim
        : null
    );

    formVars.toplucheckupform_323452_value = ReactSystemFunctions.toString(
      this,
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].protokolId
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].protokolId
        : null
    );

    stateVars.dataSource_323452 = this.state.TopluCheckupProtokolleri;
    stateVars.dataSource_323452 = this.state.TopluCheckupProtokolleri;
    stateVars.isComp323452Enabled =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].protokolId
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].protokolId
            : null
        ),
        null
      ) === true
        ? "enabled"
        : "disabled";
    formVars.toplucheckupform_999173_value = ReactSystemFunctions.toString(
      this,
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].hizmetID
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].hizmetID
        : null
    );

    stateVars.dataSource_999173 = this.state.TopluProtokolunHizmetleri;
    stateVars.dataSource_999173 = this.state.TopluProtokolunHizmetleri;
    stateVars.isComp999173Enabled =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].hizmetID
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].hizmetID
            : null
        ),
        null
      ) === true
        ? "enabled"
        : "disabled";
    formVars.toplucheckupform_450440_value = ReactSystemFunctions.value(
      this,
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].tarih
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].tarih
        : null
    );

    stateVars.isComp450440Enabled =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].tarih
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].tarih
            : null
        ),
        null
      ) === true
        ? "enabled"
        : "disabled";

    stateVars.dataSource_66828 = this.state.TopluCheckupData;
    formVars.toplucheckupform_835992_columnTitles = ReactSystemFunctions.toString(
      this,
      stateVars.TopluCheckupMeta?.length > 0
        ? stateVars.TopluCheckupMeta[0].header
        : this.state.TopluCheckupMeta?.length > 0
        ? this.state.TopluCheckupMeta[0].header
        : null
    );

    stateVars.isComp481026Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "toplucheckupform_66828_value", "cinsiyet"),
        "E"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp898895Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "toplucheckupform_66828_value", "cinsiyet"),
        "K"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp424498Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp546133Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp462202Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp835411Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp151351Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp784165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp91584Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp310545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78167Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp387430Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704141Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336857Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp792927Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp42999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp226875Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp359189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954429Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431272Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678647Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp199695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202915Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp594223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475997Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847159Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172581Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110161Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp582884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp23814Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp645853Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28192Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453979Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp548700Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp721230Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp429685Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp263652Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp960655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906742Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp56785Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp356694Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp622949Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497496Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp557923Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp560680Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp736403Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp666108Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp932627Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813286Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61000Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp705881Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517439Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895563Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "toplucheckupform_66828_value", "checkupID"),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.toplucheckupform_968208_total = ReactSystemFunctions.value(
      this,
      stateVars.TopluCheckupData?.length > 0
        ? stateVars.TopluCheckupData[0].totalkisi
        : this.state.TopluCheckupData?.length > 0
        ? this.state.TopluCheckupData[0].totalkisi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TopluCheckupFormComponent_989386_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ComponentToPdf = await ReactSystemFunctions.componentToPDF("444040", "MEDIUM", "landscape");
    stateVars.OpenPdf = await ReactSystemFunctions.openPdf(
      ReactSystemFunctions.toString(this, stateVars.ComponentToPdf),
      false,
      "newTab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TopluCheckupFormComponent_720782_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TopluCheckupFormComponent_323452_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "toplucheckupform_323452_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      TopluOlcumId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TopluOlcumId ?? this.props.screenInputs.topluolcumid,
        "Guid"
      ),
      kirilim2Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      checkupdonemiID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid,
        "Guid"
      ),
      cinsiyet_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_682481_value", "value", "", "key", "")
        ),
        "string"
      ),
      protokolId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(1, "number"),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_968208_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      protokolId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      kirilim2ID_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TopluCheckupForm/TopluCheckupFormComponent_323452_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TopluCheckupHeader = result?.data.topluCheckupHeader;
    stateVars.TopluCheckupMeta = result?.data.topluCheckupMeta;

    stateVars.isComp424498Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp546133Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp462202Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp835411Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp151351Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp784165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp91584Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp310545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78167Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp387430Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704141Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336857Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp792927Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp42999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp226875Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp359189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954429Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431272Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678647Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp199695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202915Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp594223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475997Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847159Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172581Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110161Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp582884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp23814Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp645853Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp28192Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453979Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp548700Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp721230Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp429685Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp263652Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp960655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906742Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp56785Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp356694Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp622949Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp497496Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp557923Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp560680Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Parametre1Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp736403Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp666108Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp932627Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813286Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61000Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Parametre2Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp705881Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Parametre3Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517439Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Parametre4Adi
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.TopluCheckupData = result?.data.topluCheckupData;

    stateVars.TopluProtokolunHizmetleri = result?.data.topluProtokolunHizmetleri;

    formVars.toplucheckupform_999173_options = stateVars.TopluProtokolunHizmetleri;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TopluCheckupFormComponent_323452_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TopluCheckupFormComponent_323452_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_999173 = this.state.TopluProtokolunHizmetleri;
    stateVars.dataSource_999173 = this.state.TopluProtokolunHizmetleri;

    stateVars.dataSource_66828 = this.state.TopluCheckupData;
    formVars.toplucheckupform_835992_columnTitles = ReactSystemFunctions.toString(
      this,
      stateVars.TopluCheckupMeta?.length > 0
        ? stateVars.TopluCheckupMeta[0].header
        : this.state.TopluCheckupMeta?.length > 0
        ? this.state.TopluCheckupMeta[0].header
        : null
    );

    formVars.toplucheckupform_968208_total = ReactSystemFunctions.value(
      this,
      stateVars.TopluCheckupData?.length > 0
        ? stateVars.TopluCheckupData[0].totalkisi
        : this.state.TopluCheckupData?.length > 0
        ? this.state.TopluCheckupData[0].totalkisi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TopluCheckupFormComponent_682481_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "toplucheckupform_323452_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TopluOlcumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TopluOlcumId ?? this.props.screenInputs.topluolcumid,
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      checkupdonemiID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid,
        "Guid"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_682481_value", "value", "", "key", "")
        ),
        "string"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_968208_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TopluCheckupForm/TopluCheckupFormComponent_682481_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TopluCheckupData = result?.data.topluCheckupData;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TopluCheckupFormComponent_682481_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TopluCheckupFormComponent_682481_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_66828 = this.state.TopluCheckupData;
    formVars.toplucheckupform_968208_total = ReactSystemFunctions.value(
      this,
      stateVars.TopluCheckupData?.length > 0
        ? stateVars.TopluCheckupData[0].totalkisi
        : this.state.TopluCheckupData?.length > 0
        ? this.state.TopluCheckupData[0].totalkisi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TopluCheckupFormComponent_66828_onRowEditFinished = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "toplucheckupform_323452_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_999173_value",
            "value",
            "TopluProtokolunHizmetleri",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "toplucheckupform_999173_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      hesapId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "toplucheckupform_66828_value", ".hesapId"),
        "Guid"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      checkupyapanhesapID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].hesapId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].hesapId
            : null
        ),
        "Guid"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      hizmetid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_999173_value",
            "value",
            "TopluProtokolunHizmetleri",
            "id",
            "id"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TopluCheckupForm/TopluCheckupFormComponent_66828_onRowEditFinished",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupYapabilirMi = result?.data.checkupYapabilirMi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TopluCheckupFormComponent_66828_onRowEditFinished1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TopluCheckupFormComponent_66828_onRowEditFinished_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TopluCheckupFormComponent_66828_onRowEditFinished_if11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TopluCheckupFormComponent_66828_onRowEditFinished_if1f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "toplucheckupform_323452_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_999173_value",
            "value",
            "TopluProtokolunHizmetleri",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "toplucheckupform_999173_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_450440_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "toplucheckupform_450440_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            "id"
          )
        ),
        "string"
      ),
      prm2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_999173_value",
            "value",
            "TopluProtokolunHizmetleri",
            "id",
            "title"
          )
        ),
        "string"
      ),
      prm3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_450440_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TopluCheckupForm/TopluCheckupFormComponent_66828_onRowEditFinished_if1f1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DummySave = result?.data.dummySave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TopluCheckupFormComponent_66828_onRowEditFinished_if1f11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TopluCheckupFormComponent_66828_onRowEditFinished_if1f11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CheckUpId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "toplucheckupform_66828_value", ".checkupID"),
        "Guid"
      ),
      checkupyapankisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      kisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "toplucheckupform_66828_value", ".id"),
        "Guid"
      ),
      TopluOlcumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TopluOlcumId ?? this.props.screenInputs.topluolcumid,
        "Guid"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      hizmetID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_999173_value",
            "value",
            "TopluProtokolunHizmetleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      checkuptarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_450440_value", "value", "", "", "")
        ),
        "Date"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "toplucheckupform_66828_value", ".cinsiyet"),
        "string"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_66828_boy", "value", "", "", "")),
        "number"
      ),
      agirlik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_66828_agirlik", "value", "", "", "")),
        "number"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "toplucheckupform_66828_value", ".yas"),
        "number"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KurumId ?? this.props.screenInputs.kurumid,
        "Guid"
      ),
      checkupdonemiID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid,
        "Guid"
      ),
      kirilim1Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id,
        "Guid"
      ),
      kirilim2ID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      TestParam1x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test1Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam1x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test1Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam1x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test1Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam1x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test1Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam2x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test2Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam2x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test2Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam2x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test2Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam2x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test2Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam3x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test3Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam3x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test3Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam3x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test3Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam3x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test3Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam4x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test4Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam4x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test4Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam4x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test4Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam4x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test4Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam5x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test5Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam5x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test5Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam5x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test5Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam5x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test5Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam6x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test6Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam6x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test6Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam6x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test6Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam6x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test6Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam7x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test7Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam7x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test7Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam7x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test7Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam7x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test7Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam8x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test8Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam8x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test8Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam8x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test8Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam8x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test8Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam9x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test9Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam9x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test9Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam9x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test9Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam9x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test9Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam10x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test10Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam10x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test10Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam10x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test10Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam10x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test10Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam11x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test11Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam11x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test11Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam11x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test11Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam11x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test11Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam12x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test12Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam12x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test12Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam12x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test12Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam12x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test12Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam13x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test13Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam13x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test13Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam13x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test13Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam13x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test13Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      Test1ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Id
            : null
        ),
        "Guid"
      ),
      Test2ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Id
            : null
        ),
        "Guid"
      ),
      Test3ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Id
            : null
        ),
        "Guid"
      ),
      Test4ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Id
            : null
        ),
        "Guid"
      ),
      Test5ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Id
            : null
        ),
        "Guid"
      ),
      Test6ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Id
            : null
        ),
        "Guid"
      ),
      Test7ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Id
            : null
        ),
        "Guid"
      ),
      Test8ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Id
            : null
        ),
        "Guid"
      ),
      Test9ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Id
            : null
        ),
        "Guid"
      ),
      Test10ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Id
            : null
        ),
        "Guid"
      ),
      Test11ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Id
            : null
        ),
        "Guid"
      ),
      Test12ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Id
            : null
        ),
        "Guid"
      ),
      Test13ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Id
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TopluCheckupForm/TopluCheckupFormComponent_66828_onRowEditFinished_if1f11_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupSave = result?.data.checkupSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TopluCheckupFormComponent_66828_onRowEditFinished_if1f12_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TopluCheckupFormComponent_66828_onRowEditFinished_if1f12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "toplucheckupform_323452_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TopluOlcumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TopluOlcumId ?? this.props.screenInputs.topluolcumid,
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      checkupdonemiID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid,
        "Guid"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_682481_value", "value", "", "key", "")
        ),
        "string"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_968208_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_968208_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TopluOlcumId ?? this.props.screenInputs.topluolcumid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TopluCheckupForm/TopluCheckupFormComponent_66828_onRowEditFinished_if1f12_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TopluCheckupData = result?.data.topluCheckupData;

    stateVars.TopluOlcumById = result?.data.topluOlcumById;
    formVars.toplucheckupform_323452_value =
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].protokolId
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].protokolId
        : null;
    formVars.toplucheckupform_999173_value =
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].hizmetID
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].hizmetID
        : null;
    formVars.toplucheckupform_450440_value = ReactSystemFunctions.value(
      this,
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].tarih
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].tarih
        : null
    );
    stateVars.isComp323452Enabled =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].protokolId
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].protokolId
            : null
        ),
        null
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp999173Enabled =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].hizmetID
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].hizmetID
            : null
        ),
        null
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp450440Enabled =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].tarih
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].tarih
            : null
        ),
        null
      ) === true
        ? "enabled"
        : "disabled";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TopluCheckupFormComponent_66828_onRowEditFinished_if1e1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupYapabilirMi?.length > 0
          ? stateVars.CheckupYapabilirMi[0].notify
          : this.state.CheckupYapabilirMi?.length > 0
          ? this.state.CheckupYapabilirMi[0].notify
          : null
      ),
      "warning",
      "bottom-right",
      0,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  TopluCheckupFormComponent_66828_onRowEditFinished_if11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupYapabilirMi?.length > 0
            ? stateVars.CheckupYapabilirMi[0].yapabilir
            : this.state.CheckupYapabilirMi?.length > 0
            ? this.state.CheckupYapabilirMi[0].yapabilir
            : null
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.TopluCheckupFormComponent_66828_onRowEditFinished_if1f1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.TopluCheckupFormComponent_66828_onRowEditFinished_if1e1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  TopluCheckupFormComponent_66828_onRowEditFinished_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "toplucheckupform_323452_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_999173_value",
            "value",
            "TopluProtokolunHizmetleri",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "toplucheckupform_999173_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_450440_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "toplucheckupform_450440_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            "id"
          )
        ),
        "string"
      ),
      prm2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_999173_value",
            "value",
            "TopluProtokolunHizmetleri",
            "id",
            "title"
          )
        ),
        "string"
      ),
      prm3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_450440_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TopluCheckupForm/TopluCheckupFormComponent_66828_onRowEditFinished_else1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DummySave = result?.data.dummySave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TopluCheckupFormComponent_66828_onRowEditFinished_else11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TopluCheckupFormComponent_66828_onRowEditFinished_else11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CheckUpId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "toplucheckupform_66828_value", ".checkupID"),
        "Guid"
      ),
      checkupyapankisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      kisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "toplucheckupform_66828_value", ".id"),
        "Guid"
      ),
      TopluOlcumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TopluOlcumId ?? this.props.screenInputs.topluolcumid,
        "Guid"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      hizmetID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_999173_value",
            "value",
            "TopluProtokolunHizmetleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      checkuptarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_450440_value", "value", "", "", "")
        ),
        "Date"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "toplucheckupform_66828_value", ".cinsiyet"),
        "string"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_66828_boy", "value", "", "", "")),
        "number"
      ),
      agirlik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_66828_agirlik", "value", "", "", "")),
        "number"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "toplucheckupform_66828_value", ".yas"),
        "number"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KurumId ?? this.props.screenInputs.kurumid,
        "Guid"
      ),
      checkupdonemiID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid,
        "Guid"
      ),
      kirilim1Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id,
        "Guid"
      ),
      kirilim2ID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      TestParam1x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test1Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam1x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test1Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam1x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test1Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam1x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test1Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam2x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test2Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam2x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test2Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam2x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test2Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam2x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test2Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam3x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test3Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam3x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test3Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam3x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test3Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam3x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test3Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam4x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test4Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam4x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test4Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam4x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test4Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam4x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test4Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam5x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test5Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam5x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test5Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam5x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test5Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam5x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test5Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam6x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test6Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam6x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test6Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam6x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test6Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam6x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test6Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam7x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test7Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam7x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test7Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam7x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test7Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam7x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test7Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam8x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test8Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam8x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test8Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam8x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test8Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam8x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test8Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam9x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test9Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam9x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test9Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam9x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test9Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam9x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test9Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam10x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test10Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam10x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test10Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam10x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test10Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam10x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test10Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam11x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test11Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam11x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test11Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam11x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test11Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam11x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test11Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam12x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test12Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam12x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test12Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam12x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test12Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam12x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test12Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      TestParam13x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test13Girdi1", "value", "", "", "")
        ),
        "number"
      ),
      TestParam13x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test13Girdi2", "value", "", "", "")
        ),
        "number"
      ),
      TestParam13x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test13Girdi3", "value", "", "", "")
        ),
        "number"
      ),
      TestParam13x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_66828_test13Girdi4", "value", "", "", "")
        ),
        "number"
      ),
      Test1ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test1Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test1Id
            : null
        ),
        "Guid"
      ),
      Test2ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test2Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test2Id
            : null
        ),
        "Guid"
      ),
      Test3ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test3Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test3Id
            : null
        ),
        "Guid"
      ),
      Test4ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test4Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test4Id
            : null
        ),
        "Guid"
      ),
      Test5ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test5Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test5Id
            : null
        ),
        "Guid"
      ),
      Test6ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test6Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test6Id
            : null
        ),
        "Guid"
      ),
      Test7ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test7Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test7Id
            : null
        ),
        "Guid"
      ),
      Test8ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test8Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test8Id
            : null
        ),
        "Guid"
      ),
      Test9ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test9Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test9Id
            : null
        ),
        "Guid"
      ),
      Test10ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test10Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test10Id
            : null
        ),
        "Guid"
      ),
      Test11ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test11Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test11Id
            : null
        ),
        "Guid"
      ),
      Test12ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test12Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test12Id
            : null
        ),
        "Guid"
      ),
      Test13ID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluCheckupMeta?.length > 0
            ? stateVars.TopluCheckupMeta[0].test13Id
            : this.state.TopluCheckupMeta?.length > 0
            ? this.state.TopluCheckupMeta[0].test13Id
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TopluCheckupForm/TopluCheckupFormComponent_66828_onRowEditFinished_else11_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupSave = result?.data.checkupSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TopluCheckupFormComponent_66828_onRowEditFinished_else12_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TopluCheckupFormComponent_66828_onRowEditFinished_else12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "toplucheckupform_323452_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TopluOlcumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TopluOlcumId ?? this.props.screenInputs.topluolcumid,
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      checkupdonemiID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid,
        "Guid"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_682481_value", "value", "", "key", "")
        ),
        "string"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "toplucheckupform_323452_value",
            "value",
            "TopluCheckupProtokolleri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_968208_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_968208_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TopluOlcumId ?? this.props.screenInputs.topluolcumid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TopluCheckupForm/TopluCheckupFormComponent_66828_onRowEditFinished_else12_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TopluCheckupData = result?.data.topluCheckupData;

    stateVars.TopluOlcumById = result?.data.topluOlcumById;
    formVars.toplucheckupform_323452_value =
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].protokolId
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].protokolId
        : null;
    formVars.toplucheckupform_999173_value =
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].hizmetID
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].hizmetID
        : null;
    formVars.toplucheckupform_450440_value = ReactSystemFunctions.value(
      this,
      stateVars.TopluOlcumById?.length > 0
        ? stateVars.TopluOlcumById[0].tarih
        : this.state.TopluOlcumById?.length > 0
        ? this.state.TopluOlcumById[0].tarih
        : null
    );
    stateVars.isComp323452Enabled =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].protokolId
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].protokolId
            : null
        ),
        null
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp999173Enabled =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].hizmetID
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].hizmetID
            : null
        ),
        null
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp450440Enabled =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].tarih
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].tarih
            : null
        ),
        null
      ) === true
        ? "enabled"
        : "disabled";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  TopluCheckupFormComponent_66828_onRowEditFinished1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, "toplucheckupform_66828_value", ".checkupID"), null)
    ) {
      isErrorOccurred = await this.TopluCheckupFormComponent_66828_onRowEditFinished_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.TopluCheckupFormComponent_66828_onRowEditFinished_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TopluCheckupFormComponent_895563_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CheckupReport3", "ShowDownload", true);
    KuikaAppManager.addToPageInputVariables(
      "CheckupReport3",
      "CheckupId",
      ReactSystemFunctions.value(this, "toplucheckupform_66828_value", "checkupID")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TopluCheckupForm",
      "CheckupReport3",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "496838",
      null,
      "right",
      null,
      "1080px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TopluCheckupFormComponent_968208_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      TopluOlcumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TopluOlcumId ?? this.props.screenInputs.topluolcumid,
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      checkupdonemiID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupDonemiID ?? this.props.screenInputs.checkupdonemiid,
        "Guid"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_682481_value", "value", "", "key", "")
        ),
        "string"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.TopluOlcumById?.length > 0
            ? stateVars.TopluOlcumById[0].protokolId
            : this.state.TopluOlcumById?.length > 0
            ? this.state.TopluOlcumById[0].protokolId
            : null
        ),
        "Guid"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_968208_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "toplucheckupform_968208_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TopluCheckupForm/TopluCheckupFormComponent_968208_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TopluCheckupData = result?.data.topluCheckupData;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TopluCheckupFormComponent_968208_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TopluCheckupFormComponent_968208_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_66828 = this.state.TopluCheckupData;
    formVars.toplucheckupform_968208_total = ReactSystemFunctions.value(
      this,
      stateVars.TopluCheckupData?.length > 0
        ? stateVars.TopluCheckupData[0].totalkisi
        : this.state.TopluCheckupData?.length > 0
        ? this.state.TopluCheckupData[0].totalkisi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [496838] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TopluCheckupFormPageInit();
    }
  }
}
