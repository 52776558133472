import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBasvurular_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBasvurular_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  BasvuruTabTitle: any[];
  BasvuruTabTitle_dummy: any[];
  BasvuruEgitmenAll: any[];
  BasvuruEgitmenAll_dummy: any[];
  BasvuruMerkezAll: any[];
  BasvuruMerkezAll_dummy: any[];
  BasvuruKurumAll: any[];
  BasvuruKurumAll_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class Basvurular_ScreenBase extends React.PureComponent<IBasvurular_ScreenProps, any> {
  basvurular_179648_value_kuikaTableRef: React.RefObject<any>;
  basvurular_36660_value_kuikaTableRef: React.RefObject<any>;
  basvurular_579324_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 669468, PropertyName: "value", Value: "Applications", TagName: "Label10_0_value" },
        {
          Id: 179648,
          PropertyName: "nodatafoundmessage",
          Value: "No data found",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 703389, PropertyName: "title", Value: "Name Surname", TagName: "TableTemplateColumn1_0_title" },
        { Id: 231125, PropertyName: "title", Value: "Profession", TagName: "TableTemplateColumn2_title" },
        { Id: 790083, PropertyName: "title", Value: "E-mail", TagName: "TableTemplateColumn3_title" },
        { Id: 739254, PropertyName: "title", Value: "Education", TagName: "TableTemplateColumn4_title" },
        { Id: 169537, PropertyName: "title", Value: "Experience", TagName: "TableTemplateColumn6_title" },
        { Id: 36660, PropertyName: "nodatafoundmessage", Value: "No data found", TagName: "Table2_nodatafoundmessage" },
        { Id: 522362, PropertyName: "title", Value: "Name", TagName: "TableTemplateColumn7_0_title" },
        { Id: 764173, PropertyName: "title", Value: "Web site", TagName: "TableTemplateColumn8_title" },
        { Id: 617854, PropertyName: "title", Value: "City - District", TagName: "TableTemplateColumn9_title" },
        { Id: 885623, PropertyName: "title", Value: "Comunication Name", TagName: "TableTemplateColumn10_title" },
        { Id: 94843, PropertyName: "title", Value: "Comunication phone", TagName: "TableTemplateColumn11_title" },
        {
          Id: 579324,
          PropertyName: "nodatafoundmessage",
          Value: "No data found",
          TagName: "Table3_nodatafoundmessage"
        },
        { Id: 477204, PropertyName: "title", Value: "Name", TagName: "TableTemplateColumn10_0_title" },
        { Id: 16754, PropertyName: "title", Value: "Web site", TagName: "TableTemplateColumn10_1_title" },
        { Id: 879424, PropertyName: "title", Value: "City - District", TagName: "TableTemplateColumn10_2_title" },
        { Id: 283040, PropertyName: "title", Value: "Comunication name", TagName: "TableTemplateColumn10_3_title" },
        { Id: 937031, PropertyName: "title", Value: "Comunication phone", TagName: "TableTemplateColumn10_4_title" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "3aff1bc0-04d1-457c-a74e-cbe723cf9dae",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 669468, PropertyName: "value", Value: "BAŞVURULAR" },
        { Id: 179648, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 703389, PropertyName: "title", Value: "Ad Soyad" },
        { Id: 735192, PropertyName: "value", Value: "[datafield:tamad]" },
        { Id: 231125, PropertyName: "title", Value: "Meslek" },
        { Id: 883562, PropertyName: "value", Value: "[datafield:meslek]" },
        { Id: 790083, PropertyName: "title", Value: "E-posta" },
        { Id: 14917, PropertyName: "value", Value: "[datafield:email]" },
        { Id: 739254, PropertyName: "title", Value: "Eğitim" },
        { Id: 134188, PropertyName: "value", Value: "[datafield:egitimduzey]" },
        { Id: 169537, PropertyName: "title", Value: "Tecrübe" },
        { Id: 994758, PropertyName: "value", Value: "[datafield:tecrubesure]" },
        { Id: 36660, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 522362, PropertyName: "title", Value: "İsim" },
        { Id: 421842, PropertyName: "value", Value: "[datafield:merkezadi]" },
        { Id: 764173, PropertyName: "title", Value: "Web Site" },
        { Id: 812212, PropertyName: "value", Value: "[datafield:webadres]" },
        { Id: 617854, PropertyName: "title", Value: "Şehir - İlçe" },
        { Id: 62191, PropertyName: "value", Value: "[datafield:adres]" },
        { Id: 885623, PropertyName: "title", Value: "İletişim Ad" },
        { Id: 572568, PropertyName: "value", Value: "[datafield:tamad]" },
        { Id: 94843, PropertyName: "title", Value: "İletişim Telefon" },
        { Id: 539979, PropertyName: "value", Value: "[datafield:telefon]" },
        { Id: 579324, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 477204, PropertyName: "title", Value: "İsim" },
        { Id: 464774, PropertyName: "value", Value: "[datafield:kurumisim]" },
        { Id: 16754, PropertyName: "title", Value: "Web Site" },
        { Id: 125480, PropertyName: "value", Value: "[datafield:kurumwebadresi]" },
        { Id: 879424, PropertyName: "title", Value: "Şehir - İlçe" },
        { Id: 84754, PropertyName: "value", Value: "[datafield:kurumadres]" },
        { Id: 283040, PropertyName: "title", Value: "İletişim Ad" },
        { Id: 919036, PropertyName: "value", Value: "[datafield:tamad]" },
        { Id: 937031, PropertyName: "title", Value: "İletişim Telefon" },
        { Id: 829987, PropertyName: "value", Value: "[datafield:ceptelefonu]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.basvurular_179648_value_kuikaTableRef = React.createRef();
    this.basvurular_36660_value_kuikaTableRef = React.createRef();
    this.basvurular_579324_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      BasvuruTabTitle: [],
      BasvuruEgitmenAll: [],
      BasvuruMerkezAll: [],
      BasvuruKurumAll: [],
      NAVIGATE: "",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("basvurular", "master_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.BasvurularPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("basvurular", "master_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("basvurular", "master_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BasvurularPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.BasvurularPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  checkTabErrors = () => {
    document.querySelectorAll(".rc-tabs-tab").forEach((tab) => {
      (tab as HTMLElement).style.color = "";
    });

    const tabPanes = document.querySelectorAll(".rc-tabs-tabpane");
    const tabs = document.querySelectorAll(".rc-tabs-tab");

    tabPanes.forEach((tabPane, index) => {
      const uniquePaneId = `rc-tabs-tabpane-${index}`;
      if (!tabPane.hasAttribute("id")) {
        tabPane.setAttribute("id", uniquePaneId);
      }

      const uniqueTabId = `rc-tabs-tab-${index}`;
      if (tabs[index]) {
        if (!tabs[index].hasAttribute("id")) {
          tabs[index].setAttribute("id", uniqueTabId);
        }
        tabs[index].setAttribute("aria-controls", uniquePaneId);
      }
    });

    const errorElements = document.querySelectorAll(".rc-tabs .ant-form-item-explain-error");

    const tabsWithErrors = new Set<string>();

    errorElements.forEach((errorElement) => {
      const tabPane = errorElement.closest(".rc-tabs-tabpane");
      if (tabPane && tabPane.id) {
        tabsWithErrors.add(tabPane.id);
      }
    });

    tabsWithErrors.forEach((tabPaneId) => {
      const tab = document.querySelector(`.rc-tabs-tab[aria-controls="${tabPaneId}"]`);
      if (tab) {
        (tab as HTMLElement).style.color = "red";
      }
    });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  BasvurularPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Basvurular/BasvurularPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BasvuruTabTitle = result?.data.basvuruTabTitle;
    stateVars.BasvuruEgitmenAll = result?.data.basvuruEgitmenAll;

    stateVars.BasvuruMerkezAll = result?.data.basvuruMerkezAll;

    stateVars.BasvuruKurumAll = result?.data.basvuruKurumAll;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvurularPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvurularPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.basvurular_827906_tab = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruTabTitle?.length > 0
        ? stateVars.BasvuruTabTitle[0].egitmenTab
        : this.state.BasvuruTabTitle?.length > 0
        ? this.state.BasvuruTabTitle[0].egitmenTab
        : null
    );

    stateVars.dataSource_179648 = this.state.BasvuruEgitmenAll;
    formVars.basvurular_559676_tab = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruTabTitle?.length > 0
        ? stateVars.BasvuruTabTitle[0].merkezTab
        : this.state.BasvuruTabTitle?.length > 0
        ? this.state.BasvuruTabTitle[0].merkezTab
        : null
    );

    stateVars.dataSource_36660 = this.state.BasvuruMerkezAll;
    formVars.basvurular_101024_tab = ReactSystemFunctions.toString(
      this,
      stateVars.BasvuruTabTitle?.length > 0
        ? stateVars.BasvuruTabTitle[0].kurumTab
        : this.state.BasvuruTabTitle?.length > 0
        ? this.state.BasvuruTabTitle[0].kurumTab
        : null
    );

    stateVars.dataSource_579324 = this.state.BasvuruKurumAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BasvurularComponent_348872_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BasvuruEgitmenDetay",
      "Id",
      ReactSystemFunctions.value(this, "basvurular_179648_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Basvurular",
      "BasvuruEgitmenDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "289749",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BasvurularComponent_945928_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BasvuruMerkezDetay",
      "Id",
      ReactSystemFunctions.value(this, "basvurular_36660_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Basvurular",
      "BasvuruMerkezDetay",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BasvurularComponent_318519_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BasvuruMerkezDetay",
      "Id",
      ReactSystemFunctions.value(this, "basvurular_36660_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Basvurular",
      "BasvuruMerkezDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "1365",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BasvurularComponent_679633_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BasvuruKurumDetay",
      "Id",
      ReactSystemFunctions.value(this, "basvurular_579324_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Basvurular",
      "BasvuruKurumDetay",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BasvurularComponent_89396_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "BasvuruKurumDetay",
      "Id",
      ReactSystemFunctions.value(this, "basvurular_579324_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Basvurular",
      "BasvuruKurumDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "862839",
      null,
      "right",
      null,
      "80vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [289749, , 1365, , 862839] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.BasvurularPageInit();
    }
  }
}
