import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITestDenemeDegiskenF_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITestDenemeDegiskenF_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  TestDenemeDetayById: any[];
  TestDenemeDetayById_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  TestBilesenKaydet: number;
  TestBilesenKaydet_dummy: number;
  isComp889801Visible: "visible" | "hidden";
}

export class TestDenemeDegiskenF_ScreenBase extends React.PureComponent<ITestDenemeDegiskenF_ScreenProps, any> {
  testdenemedegiskenf_195614_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "27a89a40-6db7-4539-afdc-889472887786",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "DEĞİŞKEN TANIMI" },
        { Id: 547635, PropertyName: "value", Value: "Değişken Tipi" },
        { Id: 195614, PropertyName: "placeholder", Value: "Girdi / Ara Sonuç / Sonuç / Puan" },
        { Id: 732735, PropertyName: "value", Value: "Değişkenin Adı" },
        { Id: 361485, PropertyName: "placeholder", Value: "Koşu Seviyesi" },
        { Id: 197123, PropertyName: "value", Value: "Yer Doldurucu Metin" },
        { Id: 922570, PropertyName: "placeholder", Value: "Koşu Seviyesi" },
        { Id: 603989, PropertyName: "value", Value: "Değişkenin Formülü" },
        {
          Id: 953975,
          PropertyName: "title",
          Value:
            "Kullanılabilecek parametreler: @yas, @boy, @cinsiyet, @vucutAgirligi, @min, @max,  @normalMin, @normalMax, @parametre1, @parametre2, @parametre3, @parametre4,  @araSonuc1, @araSonuc2, @araSonuc3, @puan"
        },
        { Id: 896060, PropertyName: "placeholder", Value: "SET @puan = 100-((@araSonuc3-@max)/(@min-@max)*100)" },
        { Id: 529918, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.testdenemedegiskenf_195614_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      TestDenemeDetayById: [],
      ChangeVisibilityOf: "",
      TestBilesenKaydet: 0,
      dataSource_195614: [
        { key: "K01Girdi", text: "Girdi" },
        { key: "K02AraSonuc", text: "Ara Sonuç" },
        { key: "K03Sonuc", text: "Sonuç" },
        { key: "K04Puan", text: "Puan" }
      ],
      isComp889801Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("testdenemedegiskenf", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TestDenemeDegiskenFPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("testdenemedegiskenf", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("testdenemedegiskenf", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TestDenemeDegiskenFPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      testdenemedegiskenf_195614_value: this.state.TestDenemeDetayById?.at?.(0)?.degiskenTipi ?? undefined,
      testdenemedegiskenf_361485_value: this.state.TestDenemeDetayById?.at?.(0)?.degiskenAdi ?? undefined,
      testdenemedegiskenf_922570_value: this.state.TestDenemeDetayById?.at?.(0)?.placeholder ?? undefined,
      testdenemedegiskenf_896060_value: this.state.TestDenemeDetayById?.at?.(0)?.formul ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TestDenemeDegiskenFPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DegiskenId ?? this.props.screenInputs.degiskenid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestDenemeDegiskenF/TestDenemeDegiskenFPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestDenemeDetayById = result?.data.testDenemeDetayById;
    formVars.testdenemedegiskenf_195614_value =
      stateVars.TestDenemeDetayById?.length > 0
        ? stateVars.TestDenemeDetayById[0].degiskenTipi
        : this.state.TestDenemeDetayById?.length > 0
        ? this.state.TestDenemeDetayById[0].degiskenTipi
        : null;
    formVars.testdenemedegiskenf_361485_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestDenemeDetayById?.length > 0
        ? stateVars.TestDenemeDetayById[0].degiskenAdi
        : this.state.TestDenemeDetayById?.length > 0
        ? this.state.TestDenemeDetayById[0].degiskenAdi
        : null
    );
    formVars.testdenemedegiskenf_922570_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestDenemeDetayById?.length > 0
        ? stateVars.TestDenemeDetayById[0].placeholder
        : this.state.TestDenemeDetayById?.length > 0
        ? this.state.TestDenemeDetayById[0].placeholder
        : null
    );
    formVars.testdenemedegiskenf_896060_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestDenemeDetayById?.length > 0
        ? stateVars.TestDenemeDetayById[0].formul
        : this.state.TestDenemeDetayById?.length > 0
        ? this.state.TestDenemeDetayById[0].formul
        : null
    );
    stateVars.isComp889801Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestDenemeDetayById?.length > 0
            ? stateVars.TestDenemeDetayById[0].degiskenTipi
            : this.state.TestDenemeDetayById?.length > 0
            ? this.state.TestDenemeDetayById[0].degiskenTipi
            : null
        ),
        "K01Girdi"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestDenemeDegiskenFPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestDenemeDegiskenFPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testdenemedegiskenf_195614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestDenemeDetayById?.length > 0
        ? stateVars.TestDenemeDetayById[0].degiskenTipi
        : this.state.TestDenemeDetayById?.length > 0
        ? this.state.TestDenemeDetayById[0].degiskenTipi
        : null
    );

    formVars.testdenemedegiskenf_361485_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestDenemeDetayById?.length > 0
        ? stateVars.TestDenemeDetayById[0].degiskenAdi
        : this.state.TestDenemeDetayById?.length > 0
        ? this.state.TestDenemeDetayById[0].degiskenAdi
        : null
    );

    formVars.testdenemedegiskenf_922570_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestDenemeDetayById?.length > 0
        ? stateVars.TestDenemeDetayById[0].placeholder
        : this.state.TestDenemeDetayById?.length > 0
        ? this.state.TestDenemeDetayById[0].placeholder
        : null
    );

    stateVars.isComp889801Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestDenemeDetayById?.length > 0
            ? stateVars.TestDenemeDetayById[0].degiskenTipi
            : this.state.TestDenemeDetayById?.length > 0
            ? this.state.TestDenemeDetayById[0].degiskenTipi
            : null
        ),
        "K01Girdi"
      ) === true
        ? "visible"
        : "hidden";
    formVars.testdenemedegiskenf_896060_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestDenemeDetayById?.length > 0
        ? stateVars.TestDenemeDetayById[0].formul
        : this.state.TestDenemeDetayById?.length > 0
        ? this.state.TestDenemeDetayById[0].formul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TestDenemeDegiskenFComponent_107559_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestDenemeDegiskenFComponent_195614_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp889801Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestDenemeDegiskenFComponent_195614_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TestDenemeDegiskenFComponent_195614_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdenemedegiskenf_195614_value", "value", "", "key", "")
        ),
        "K01Girdi"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp889801Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  TestDenemeDegiskenFComponent_529918_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdenemedegiskenf_195614_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "testdenemedegiskenf_195614_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdenemedegiskenf_361485_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "testdenemedegiskenf_361485_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdenemedegiskenf_361485_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "testdenemedegiskenf_922570_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdenemedegiskenf_896060_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "testdenemedegiskenf_896060_value",
      condition: ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdenemedegiskenf_195614_value", "value", "", "key", "")
        ),
        "K01Girdi"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testDenemeId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      testDenemeDetayId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DegiskenId ?? this.props.screenInputs.degiskenid,
        "Guid"
      ),
      degiskenId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.DegiskenId ?? this.props.screenInputs.degiskenid,
        "Guid"
      ),
      degiskenTipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdenemedegiskenf_195614_value", "value", "", "key", "")
        ),
        "string"
      ),
      degiskenAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdenemedegiskenf_361485_value", "value", "", "", "")
        ),
        "string"
      ),
      degiskenPlaceholder_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdenemedegiskenf_922570_value", "value", "", "", "")
        ),
        "string"
      ),
      degiskenFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdenemedegiskenf_896060_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TestDenemeDegiskenF/TestDenemeDegiskenFComponent_529918_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestBilesenKaydet = result?.data.testBilesenKaydet;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestDenemeDegiskenFComponent_529918_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestDenemeDegiskenFComponent_529918_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
