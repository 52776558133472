import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEgitmenBasvuruFormu_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEgitmenBasvuruFormu_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  GoBack: any;
  GoBack_dummy: any;
  SehirAll: any[];
  SehirAll_dummy: any[];
  PhotoFromGallery: string;
  PhotoFromGallery_dummy: string;
  SetValueOf: any;
  SetValueOf_dummy: any;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  OpenInBrowser: any;
  OpenInBrowser_dummy: any;
  MailSubject: any[];
  MailSubject_dummy: any[];
  BasvuruEgitmenSave: number;
  BasvuruEgitmenSave_dummy: number;
  SendMail: boolean;
  SendMail_dummy: boolean;
  Notify: boolean;
  Notify_dummy: boolean;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp722897Visible: "visible" | "hidden";
  isComp360741Visible: "visible" | "hidden";
  isComp209875Visible: "visible" | "hidden";
  isComp60255Visible: "visible" | "hidden";
  isComp291069Visible: "visible" | "hidden";
  isComp350067Visible: "visible" | "hidden";
  isComp598097Visible: "visible" | "hidden";
  isComp898108Visible: "visible" | "hidden";
  isComp778064Visible: "visible" | "hidden";
  isComp599127Visible: "visible" | "hidden";
}

export class EgitmenBasvuruFormu_ScreenBase extends React.PureComponent<IEgitmenBasvuruFormu_ScreenProps, any> {
  egitmenbasvuruformu_158152_value_kuikaDateRef: React.RefObject<any>;
  egitmenbasvuruformu_708851_value_kuikaSelectBoxRef: React.RefObject<any>;
  egitmenbasvuruformu_815431_value_kuikaSelectBoxRef: React.RefObject<any>;
  egitmenbasvuruformu_481655_value_kuikaSelectBoxRef: React.RefObject<any>;
  egitmenbasvuruformu_796110_value_kuikaMultiSelectRef: React.RefObject<any>;
  egitmenbasvuruformu_336190_value_kuikaSelectBoxRef: React.RefObject<any>;
  egitmenbasvuruformu_466313_value_kuikaMultiSelectRef: React.RefObject<any>;
  egitmenbasvuruformu_290283_value_kuikaMultiSelectRef: React.RefObject<any>;
  egitmenbasvuruformu_911324_value_kuikaMultiSelectRef: React.RefObject<any>;
  egitmenbasvuruformu_172727_value_kuikaSelectBoxRef: React.RefObject<any>;
  egitmenbasvuruformu_644651_value_kuikaSelectBoxRef: React.RefObject<any>;
  egitmenbasvuruformu_344987_value_kuikaSelectBoxRef: React.RefObject<any>;
  egitmenbasvuruformu_954103_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 702105, PropertyName: "value", Value: "Trainer/Teacher/Coach Application Form", TagName: "Label1_value" },
        {
          Id: 777862,
          PropertyName: "value",
          Value: "Your application will be evaluated by MeMap, and once approved, you will be contacted by e-mail. ",
          TagName: "Label5_value"
        },
        { Id: 194783, PropertyName: "value", Value: "Personal Information", TagName: "Label2_value" },
        { Id: 482943, PropertyName: "value", Value: "Name", TagName: "Label1_0_value" },
        { Id: 378355, PropertyName: "placeholder", Value: "Name", TagName: "txtAd_placeholder" },
        { Id: 50174, PropertyName: "value", Value: "Surname", TagName: "Label1_0_value" },
        { Id: 503657, PropertyName: "placeholder", Value: "Surname", TagName: "txtSoyad_placeholder" },
        { Id: 276803, PropertyName: "value", Value: "Birth Date", TagName: "Label1_0_value" },
        { Id: 158152, PropertyName: "placeholder", Value: "01/01/1978", TagName: "dtDogumTarihi_placeholder" },
        { Id: 995851, PropertyName: "value", Value: "Biological Gender", TagName: "Label1_0_value" },
        { Id: 708851, PropertyName: "placeholder", Value: "♀ / ♂", TagName: "sbCinsiyet_placeholder" },
        { Id: 216394, PropertyName: "value", Value: "E-mail", TagName: "Label2_value" },
        { Id: 615228, PropertyName: "placeholder", Value: "name@domainname.com", TagName: "txtEmail_placeholder" },
        { Id: 560047, PropertyName: "value", Value: "Mobile Phone", TagName: "Label6_value" },
        { Id: 449007, PropertyName: "placeholder", Value: "0(5__) ___ __ __", TagName: "txtCebTelefonu_placeholder" },
        { Id: 806533, PropertyName: "value", Value: "Upload", TagName: "Label70_value" },
        { Id: 94596, PropertyName: "value", Value: "Professional Information", TagName: "Label1_0_value" },
        { Id: 54628, PropertyName: "value", Value: "What is your profession?", TagName: "Label1_0_value" },
        { Id: 481655, PropertyName: "placeholder", Value: "Choose...", TagName: "sbMeslek_placeholder" },
        {
          Id: 997537,
          PropertyName: "value",
          Value: "The name of the fitness center where you work",
          TagName: "Label7_value"
        },
        { Id: 629367, PropertyName: "placeholder", Value: "Write down...", TagName: "txtFitnesAd_placeholder" },
        { Id: 445773, PropertyName: "value", Value: "The group classes you provide", TagName: "Label1_0_value" },
        {
          Id: 480287,
          PropertyName: "value",
          Value: "If available, your fitness federation instructor rank",
          TagName: "Label1_0_value"
        },
        { Id: 336190, PropertyName: "placeholder", Value: "Choose...", TagName: "sbFederasyonDerece_placeholder" },
        {
          Id: 185184,
          PropertyName: "value",
          Value: "If available, your other professional certificates",
          TagName: "Label1_0_value"
        },
        { Id: 16558, PropertyName: "placeholder", Value: "Write down...", TagName: "txtSertifika_placeholder" },
        {
          Id: 725813,
          PropertyName: "value",
          Value: "The special populations you are interested in",
          TagName: "Label3_value"
        },
        {
          Id: 253056,
          PropertyName: "value",
          Value: "The name of the school where you work",
          TagName: "Label1_0_value"
        },
        { Id: 272525, PropertyName: "placeholder", Value: "Write down...", TagName: "txtCalislanOkul_placeholder" },
        { Id: 810469, PropertyName: "value", Value: "The classes you teach", TagName: "Label1_0_value" },
        {
          Id: 826496,
          PropertyName: "value",
          Value: "The name of the sports club where you work",
          TagName: "Label1_0_value"
        },
        { Id: 82680, PropertyName: "placeholder", Value: "Write down...", TagName: "txtSporOkulu_placeholder" },
        { Id: 694210, PropertyName: "value", Value: "The sports branches you coach in", TagName: "Label1_0_value" },
        { Id: 551137, PropertyName: "value", Value: "Professional Experience", TagName: "Label1_0_value" },
        { Id: 172727, PropertyName: "placeholder", Value: "Choose...", TagName: "sbTecrube_placeholder" },
        { Id: 467148, PropertyName: "value", Value: "What is your educational level?", TagName: "Label1_0_value" },
        { Id: 644651, PropertyName: "placeholder", Value: "Choose...", TagName: "sbEgitimDuzeyi_placeholder" },
        {
          Id: 693595,
          PropertyName: "value",
          Value: "The name of the university you graduated from",
          TagName: "Label1_0_value"
        },
        { Id: 173977, PropertyName: "placeholder", Value: "Write down...", TagName: "txtMezunUni_placeholder" },
        { Id: 304886, PropertyName: "value", Value: "The department you graduated from", TagName: "Label1_0_value" },
        { Id: 215258, PropertyName: "placeholder", Value: "Write down...", TagName: "txtUniBolum_placeholder" },
        { Id: 603262, PropertyName: "value", Value: "Your postgraduate degree", TagName: "Label1_0_value" },
        { Id: 344987, PropertyName: "placeholder", Value: "Choose...", TagName: "sbLisansUstu_placeholder" },
        { Id: 823986, PropertyName: "value", Value: "Your research topic", TagName: "Label1_0_value" },
        { Id: 267744, PropertyName: "placeholder", Value: "Write down...", TagName: "txtArastirmaKonu_placeholder" },
        { Id: 95995, PropertyName: "value", Value: "The university you attended", TagName: "Label1_0_value" },
        { Id: 465934, PropertyName: "placeholder", Value: "Choose...", TagName: "txtOgrenciUni_placeholder" },
        { Id: 183081, PropertyName: "value", Value: "The department you studied in", TagName: "Label1_0_value" },
        { Id: 954103, PropertyName: "placeholder", Value: "Choose...", TagName: "sbOgrenciBolum_placeholder" },
        {
          Id: 572567,
          PropertyName: "value",
          Value: "I want to be a part of the MeMap platform.",
          TagName: "Label73_value"
        },
        {
          Id: 306763,
          PropertyName: "value",
          Value: "I want to get information about the benefits of the MeMap platform.",
          TagName: "Label2_value"
        },
        { Id: 34124, PropertyName: "value", Value: "Consent Statement", TagName: "Label1_1_value" },
        { Id: 479811, PropertyName: "value", Value: "Disclosure Text.", TagName: "Label1_1_value" },
        { Id: 438696, PropertyName: "label", Value: "Send", TagName: "Button1_label" },
        { Id: 947437, PropertyName: "value", Value: "Explore", TagName: "Label1_0_value" },
        { Id: 274130, PropertyName: "value", Value: "Fitness Check-Up", TagName: "Label1_1_value" },
        { Id: 705103, PropertyName: "value", Value: "FitSkor®", TagName: "Label1_2_value" },
        { Id: 119947, PropertyName: "value", Value: "Pricing", TagName: "Label1_3_value" },
        { Id: 306952, PropertyName: "value", Value: "Where to find", TagName: "Label1_4_value" },
        { Id: 806290, PropertyName: "value", Value: "Thought Leadership", TagName: "Label1_5_value" },
        { Id: 229104, PropertyName: "value", Value: "Terms of Use", TagName: "Label1_6_value" },
        { Id: 359646, PropertyName: "value", Value: "Privacy Policy", TagName: "Label1_7_value" },
        { Id: 154721, PropertyName: "value", Value: "Platform Guide", TagName: "Label1_8_value" },
        { Id: 779380, PropertyName: "value", Value: "What is MeMap?", TagName: "Label1_0_value" },
        { Id: 850534, PropertyName: "value", Value: "For You", TagName: "Label1_1_value" },
        { Id: 201275, PropertyName: "value", Value: "For Trainers", TagName: "Label1_2_value" },
        { Id: 722869, PropertyName: "value", Value: "For Gyms", TagName: "Label1_3_value" },
        { Id: 913155, PropertyName: "value", Value: "For Companies", TagName: "Label1_4_value" },
        { Id: 42267, PropertyName: "value", Value: "For Academics", TagName: "Label1_5_value" },
        { Id: 16264, PropertyName: "value", Value: "FAQ", TagName: "Label1_6_value" },
        { Id: 766495, PropertyName: "value", Value: "Distance Sale Agreement", TagName: "Label1_7_value" },
        { Id: 431125, PropertyName: "value", Value: "Contact", TagName: "Label1_8_value" },
        {
          Id: 718047,
          PropertyName: "value",
          Value:
            "© 2023 MeMap All rights reserved. MeMap is a method for creating personalized exercise strategies and prescriptions. It is a technology protected by patent applications pending in the United States Patent and Trademark Office, the European Patent Office, and other patent offices.",
          TagName: "Label1_0_value"
        },
        {
          Id: 9377816,
          PropertyName: "3247fbaa-6700-80b9-e758-535c6a7ec5aa_notify",
          Value: "I have read the text and agree.",
          TagName: "Notify_notify",
          IsNotify: true
        },
        {
          Id: 78525048,
          PropertyName: "717d3336-4480-4968-5837-dc406483bb0c_notify",
          Value: "Your application has been successfully completed.",
          TagName: "Notify_notify",
          IsNotify: true
        },
        {
          Id: 39239636,
          PropertyName: "102bbe15-f83b-0e38-50ba-c4fa4e7ee2d6_notify",
          Value: "I have read the consent statement and agree.",
          TagName: "Notify_notify",
          IsNotify: true
        },
        { Id: 778064, PropertyName: "label", Value: "Trigger1", TagName: "triggerButton_label" },
        { Id: 599127, PropertyName: "label", Value: "Trigger2", TagName: "triggerEgitim_label" },
        { Id: 405282, PropertyName: "value", Value: "City", TagName: "Label9_value" },
        { Id: 815431, PropertyName: "placeholder", Value: "Choose...", TagName: "selSehir_placeholder" },
        { Id: 619844, PropertyName: "value", Value: "I have read, understood, and accepted.", TagName: "Label4_value" },
        { Id: 179843, PropertyName: "value", Value: "I have read and understood.", TagName: "Label8_value" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "ea89996d-4013-44be-ba06-ee9c66d30fd0",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 778064, PropertyName: "label", Value: "Trigger1" },
        { Id: 599127, PropertyName: "label", Value: "Trigger2" },
        { Id: 702105, PropertyName: "value", Value: "Eğitmen/Öğretmen/Antrenör Başvuru Formu" },
        {
          Id: 777862,
          PropertyName: "value",
          Value: "Başvurunuz MeMap tarafından değerlendirilecek ve onaylandığında e-postanıza bildirim verilecektir."
        },
        { Id: 194783, PropertyName: "value", Value: "Kişisel Bilgiler" },
        { Id: 482943, PropertyName: "value", Value: "Adınız" },
        { Id: 378355, PropertyName: "placeholder", Value: "Adınız" },
        { Id: 50174, PropertyName: "value", Value: "Soyadınız" },
        { Id: 503657, PropertyName: "placeholder", Value: "Soyadınız" },
        { Id: 216394, PropertyName: "value", Value: "E-posta" },
        { Id: 615228, PropertyName: "placeholder", Value: "name@domainname.com" },
        { Id: 276803, PropertyName: "value", Value: "Doğum Tarihiniz" },
        { Id: 158152, PropertyName: "placeholder", Value: "01.01.2030" },
        { Id: 995851, PropertyName: "value", Value: "Biyolojik Cinsiyetiniz" },
        { Id: 708851, PropertyName: "placeholder", Value: "♀ / ♂" },
        { Id: 560047, PropertyName: "value", Value: "Cep Telefonu" },
        { Id: 449007, PropertyName: "placeholder", Value: "0(5__) ___ __ __" },
        { Id: 405282, PropertyName: "value", Value: "Şehir" },
        { Id: 815431, PropertyName: "placeholder", Value: "Seçiniz.." },
        { Id: 806533, PropertyName: "value", Value: "Yükle" },
        { Id: 94596, PropertyName: "value", Value: "Mesleki Bilgiler" },
        { Id: 54628, PropertyName: "value", Value: "Mesleğiniz nedir?" },
        { Id: 481655, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 997537, PropertyName: "value", Value: "Çalıştığınız fitness merkezinin adı" },
        { Id: 629367, PropertyName: "placeholder", Value: "Yazınız..." },
        { Id: 445773, PropertyName: "value", Value: "Verdiğiniz grup dersleri" },
        { Id: 796110, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 480287, PropertyName: "value", Value: "Varsa Fitness Federasyonu eğitmenlik dereceniz" },
        { Id: 336190, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 185184, PropertyName: "value", Value: "Varsa diğer mesleki sertifikalarınız" },
        { Id: 16558, PropertyName: "placeholder", Value: "Yazınız..." },
        { Id: 725813, PropertyName: "value", Value: "İlgilendiğiniz özel popülasyonlar" },
        { Id: 466313, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 253056, PropertyName: "value", Value: "Çalıştığınız okulun adı" },
        { Id: 272525, PropertyName: "placeholder", Value: "Yazınız..." },
        { Id: 810469, PropertyName: "value", Value: "Ders verdiğiniz sınıflar" },
        { Id: 290283, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 826496, PropertyName: "value", Value: "Çalıştığınız spor kulübünün adı" },
        { Id: 82680, PropertyName: "placeholder", Value: "Yazınız..." },
        { Id: 694210, PropertyName: "value", Value: "Antrenörlük yaptığınız spor branşları" },
        { Id: 911324, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 551137, PropertyName: "value", Value: "Mesleki Tecrübe" },
        { Id: 172727, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 467148, PropertyName: "value", Value: "Eğitim düzeyiniz nedir?" },
        { Id: 644651, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 693595, PropertyName: "value", Value: "Mezun olduğunuz üniversite adı" },
        { Id: 173977, PropertyName: "placeholder", Value: "Yazınız..." },
        { Id: 304886, PropertyName: "value", Value: "Mezun olduğunuz bölüm" },
        { Id: 215258, PropertyName: "placeholder", Value: "Yazınız..." },
        { Id: 603262, PropertyName: "value", Value: "Lisans üstü dereceniz" },
        { Id: 344987, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 823986, PropertyName: "value", Value: "Araştırma konunuz" },
        { Id: 267744, PropertyName: "placeholder", Value: "Yazınız..." },
        { Id: 95995, PropertyName: "value", Value: "Okuduğunuz üniversite" },
        { Id: 465934, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 183081, PropertyName: "value", Value: "Okuduğunuz bölüm" },
        { Id: 954103, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 572567, PropertyName: "value", Value: "MeMap platformunda yer almak istiyorum." },
        { Id: 306763, PropertyName: "value", Value: "MeMap platformunun faydaları hakkında bilgi almak istiyorum." },
        { Id: 34124, PropertyName: "value", Value: "Açık Rıza Metni" },
        { Id: 619844, PropertyName: "value", Value: "ni okudum, anladım, kabul ediyorum." },
        { Id: 479811, PropertyName: "value", Value: "KVKK Aydınlatma Metni" },
        { Id: 179843, PropertyName: "value", Value: "'ni okudum, anladım." },
        { Id: 438696, PropertyName: "label", Value: "Gönder" },
        { Id: 947437, PropertyName: "value", Value: "Keşfet" },
        { Id: 274130, PropertyName: "value", Value: "Fitness Check-Up" },
        { Id: 705103, PropertyName: "value", Value: "FitSkor®" },
        { Id: 119947, PropertyName: "value", Value: "Fiyatlandırma" },
        { Id: 306952, PropertyName: "value", Value: "Nerede Bulurum" },
        { Id: 806290, PropertyName: "value", Value: "Düşünce Liderliği" },
        { Id: 229104, PropertyName: "value", Value: "Üyelik Sözleşmesi" },
        { Id: 359646, PropertyName: "value", Value: "Aydınlatma Metni" },
        { Id: 154721, PropertyName: "value", Value: "İşlem Rehberi" },
        { Id: 779380, PropertyName: "value", Value: "MeMap Nedir?" },
        { Id: 850534, PropertyName: "value", Value: "Senin için" },
        { Id: 201275, PropertyName: "value", Value: "Eğitmenler için" },
        { Id: 722869, PropertyName: "value", Value: "Merkezler için" },
        { Id: 913155, PropertyName: "value", Value: "Kurumlar için" },
        { Id: 42267, PropertyName: "value", Value: "Akademik Kullanım" },
        { Id: 16264, PropertyName: "value", Value: "Sıkça Sorulan Sorular" },
        { Id: 766495, PropertyName: "value", Value: "Mesafeli Satış Sözleşmesi" },
        { Id: 431125, PropertyName: "value", Value: "İletişim" },
        {
          Id: 718047,
          PropertyName: "value",
          Value:
            "© 2017 MeMap tüm hakları saklıdır. MeMap, özelleştirilmiş egzersiz stratejisi ve reçetesi hazırlamak için bir yöntemdir. ABD Patent ofisi ve EPO patent ofisi ve diğer patent bekleyen başvurularla korunan bir teknolojidir."
        }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.egitmenbasvuruformu_158152_value_kuikaDateRef = React.createRef();
    this.egitmenbasvuruformu_708851_value_kuikaSelectBoxRef = React.createRef();
    this.egitmenbasvuruformu_815431_value_kuikaSelectBoxRef = React.createRef();
    this.egitmenbasvuruformu_481655_value_kuikaSelectBoxRef = React.createRef();
    this.egitmenbasvuruformu_796110_value_kuikaMultiSelectRef = React.createRef();
    this.egitmenbasvuruformu_336190_value_kuikaSelectBoxRef = React.createRef();
    this.egitmenbasvuruformu_466313_value_kuikaMultiSelectRef = React.createRef();
    this.egitmenbasvuruformu_290283_value_kuikaMultiSelectRef = React.createRef();
    this.egitmenbasvuruformu_911324_value_kuikaMultiSelectRef = React.createRef();
    this.egitmenbasvuruformu_172727_value_kuikaSelectBoxRef = React.createRef();
    this.egitmenbasvuruformu_644651_value_kuikaSelectBoxRef = React.createRef();
    this.egitmenbasvuruformu_344987_value_kuikaSelectBoxRef = React.createRef();
    this.egitmenbasvuruformu_954103_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ChangeVisibilityOf: "",
      GoBack: "",
      SehirAll: [],
      PhotoFromGallery: "",
      SetValueOf: "",
      TriggerEvent: "",
      OpenInBrowser: "",
      MailSubject: [],
      IfThenElse: false,
      NAVIGATE: "",
      dataSource_708851: [
        { key: "K", text: "Kadın" },
        { key: "E", text: "Erkek" }
      ],
      dataSource_481655: [
        { key: "Fitness eğitmeni", text: "Fitness eğitmeni" },
        { key: "Beden eğitimi öğretmeni", text: "Beden eğitimi öğretmeni" },
        { key: "Antrenör", text: "Antrenör" }
      ],
      dataSource_796110: [
        { key: "Spinning", text: "Spinning" },
        { key: "Step", text: "Step" },
        { key: "Zumba", text: "Zumba" },
        { key: "TRX", text: "TRX" },
        { key: "Yoga", text: "Yoga" },
        { key: "Mat pilates", text: "Mat pilates" },
        { key: "Reformer pilates", text: "Reformer pilates" },
        { key: "Bosu", text: "Bosu" },
        { key: "Fonksiyonel", text: "Fonksiyonel" },
        { key: "Bootcamp", text: "Bootcamp" }
      ],
      dataSource_336190: [
        { key: "1. Kademe", text: "1. Kademe" },
        { key: "2. Kademe", text: "2. Kademe" },
        { key: "3. Kademe", text: "3. Kademe" },
        { key: "4. Kademe", text: "4. Kademe" },
        { key: "5. Kademe", text: "5. Kademe" }
      ],
      dataSource_466313: [
        { key: "Hamilelik", text: "Hamilelik" },
        { key: "Yaşlılık", text: "Yaşlılık" },
        { key: "Çocuk", text: "Çocuk" },
        { key: "Obezite", text: "Obezite" },
        { key: "Koroner kalp hastalıkları", text: "Koroner kalp hastalıkları" },
        { key: "Diyabet", text: "Diyabet" }
      ],
      dataSource_290283: [
        { key: "Okul öncesi", text: "Okul öncesi" },
        { key: "İlk okul", text: "İlk okul" },
        { key: "Orta okul", text: "Orta okul" },
        { key: "Lise", text: "Lise" }
      ],
      dataSource_911324: [
        { key: "Atletizm", text: "Atletizm" },
        { key: "Futbol", text: "Futbol" },
        { key: "Basketbol", text: "Basketbol" },
        { key: "Voleybol", text: "Voleybol" },
        { key: "Hentbol", text: "Hentbol" },
        { key: "Tenis", text: "Tenis" },
        { key: "Kayak", text: "Kayak" },
        { key: "Yüzme", text: "Yüzme" },
        { key: "Bisiklet", text: "Bisiklet" },
        { key: "Triatlon", text: "Triatlon" }
      ],
      dataSource_172727: [
        { key: "1 yıldan az", text: "1 yıldan az" },
        { key: "1-3 yıl", text: "1-3 yıl" },
        { key: "4-5 yıl", text: "4-5 yıl" },
        { key: "5-10 yıl", text: "5-10 yıl" },
        { key: "10 yıldan fazla", text: "10 yıldan fazla" }
      ],
      dataSource_644651: [
        { key: "Lise", text: "Lise" },
        { key: "Spor bilimleri fakültesinde öğrenciyim", text: "Spor bilimleri fakültesinde öğrenciyim" },
        { key: "Lisans üstü", text: "Lisans üstü" },
        { key: "Lisans", text: "Lisans" }
      ],
      dataSource_344987: [
        { key: "Yüksek lisans (M.Sci.)", text: "Yüksek lisans (M.Sci.)" },
        { key: "Doktora (PhD)", text: "Doktora (PhD)" }
      ],
      dataSource_954103: [
        { key: "Antrenörlük", text: "Antrenörlük" },
        { key: "Beden Eğitimi Öğretmenliği", text: "Beden Eğitimi Öğretmenliği" },
        { key: "Spor Yöneticiliği", text: "Spor Yöneticiliği" },
        { key: "Rekreasyon", text: "Rekreasyon" }
      ],
      isComp722897Visible: "hidden",
      isComp360741Visible: "hidden",
      isComp209875Visible: "hidden",
      isComp60255Visible: "hidden",
      isComp291069Visible: "hidden",
      isComp350067Visible: "hidden",
      isComp598097Visible: "hidden",
      isComp898108Visible: "hidden",
      isComp778064Visible: "hidden",
      isComp599127Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("egitmenbasvuruformu", "");
      return;
    }

    if (true) {
      await this.EgitmenBasvuruFormuPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("egitmenbasvuruformu", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("egitmenbasvuruformu", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EgitmenBasvuruFormuPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      egitmenbasvuruformu_262958_value: false,
      egitmenbasvuruformu_29927_value: false,
      egitmenbasvuruformu_882933_value: false,
      egitmenbasvuruformu_840312_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EgitmenBasvuruFormuPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "EgitmenBasvuruFormu/EgitmenBasvuruFormuPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SehirAll = result?.data.sehirAll;

    formVars.egitmenbasvuruformu_815431_options = stateVars.SehirAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenBasvuruFormuPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenBasvuruFormuPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_815431 = this.state.SehirAll;
    stateVars.dataSource_815431 = this.state.SehirAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_778064_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp722897Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp360741Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp209875Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp60255Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp291069Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_599127_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp350067Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp598097Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp898108Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_513932_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_806533_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(true, "");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenBasvuruFormuComponent_806533_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_806533_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "egitmenbasvuruformu_784192_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_481655_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.EgitmenBasvuruFormuComponent_778064_onClick()) return true;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenBasvuruFormuComponent_481655_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_481655_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "Fitness eğitmeni"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp722897Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_481655_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_481655_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_481655_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "Fitness eğitmeni"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp291069Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_481655_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_481655_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_481655_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "Fitness eğitmeni"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp60255Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_481655_onChange4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_481655_onChange4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_481655_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "Beden eğitimi öğretmeni"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp209875Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_481655_onChange5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_481655_onChange5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_481655_onChange5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "Antrenör"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp360741Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_644651_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.EgitmenBasvuruFormuComponent_599127_onClick()) return true;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenBasvuruFormuComponent_644651_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_644651_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_644651_value", "value", "", "key", "")
        ),
        "Spor bilimleri fakültesinde öğrenciyim"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp350067Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_644651_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_644651_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_644651_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_644651_value", "value", "", "key", "")
        ),
        "Lisans üstü"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp598097Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_644651_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_644651_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_644651_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_644651_value", "value", "", "key", "")
        ),
        "Lisans"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp898108Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_34124_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://www.memap.com.tr/tr/pages/permitionfortransferdataabroad/",
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_479811_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/privacy_policy_tr/",
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_438696_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_481655_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      lang_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      basvurutipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenBasvuruFormu/EgitmenBasvuruFormuComponent_438696_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MailSubject = result?.data.mailSubject;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenBasvuruFormuComponent_438696_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_438696_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenBasvuruFormuComponent_438696_onClick_if11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_438696_onClick_if11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_378355_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_378355_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_911324_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_911324_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "Antrenör"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_82680_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_82680_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "Antrenör"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_272525_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_272525_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "Beden eğitimi öğretmeni"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_290283_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_290283_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "Beden eğitimi öğretmeni"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_449007_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_449007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_708851_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_708851_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_158152_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_158152_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_615228_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_615228_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_615228_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "egitmenbasvuruformu_615228_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_629367_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_629367_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "Fitness eğitmeni"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_784192_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_784192_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_215258_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_215258_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_644651_value", "value", "", "key", "")
        ),
        "Lisans"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_173977_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_173977_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_644651_value", "value", "", "key", "")
        ),
        "Lisans"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_344987_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_344987_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_644651_value", "value", "", "key", "")
        ),
        "Lisans üstü"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_267744_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_267744_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_644651_value", "value", "", "key", "")
        ),
        "Lisans üstü"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_336190_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_336190_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_644651_value", "value", "", "key", "")
        ),
        "Lise"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_629367_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_16558_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_644651_value", "value", "", "key", "")
        ),
        "Lise"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_481655_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_954103_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_954103_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_644651_value", "value", "", "key", "")
        ),
        "Spor bilimleri fakültesinde öğrenciyim"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_465934_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_465934_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_644651_value", "value", "", "key", "")
        ),
        "Spor bilimleri fakültesinde öğrenciyim"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_503657_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_503657_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_172727_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_172727_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_815431_value", "value", "SehirAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_815431_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Ad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_378355_value", "value", "", "", "")
        ),
        "string"
      ),
      AntrenorBrans_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_911324_value", "value", "", "key", "")
        ),
        "string"
      ),
      AntrenorKlup_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_82680_value", "value", "", "", "")
        ),
        "string"
      ),
      BedenOkul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_272525_value", "value", "", "", "")
        ),
        "string"
      ),
      BedenSinif_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_290283_value", "value", "", "key", "")
        ),
        "string"
      ),
      CepTelefonu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_449007_value", "value", "", "", "")
        ),
        "string"
      ),
      Cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_708851_value", "value", "", "key", "")
        ),
        "string"
      ),
      DogumTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_158152_value", "value", "", "", "")
        ),
        "Date"
      ),
      EgitimDuzey_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_644651_value", "value", "", "key", "")
        ),
        "string"
      ),
      Email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_615228_value", "value", "", "", "")
        ),
        "string"
      ),
      FitnessDers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_290283_value", "value", "", "key", "")
        ),
        "string"
      ),
      FitnessMerkez_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_629367_value", "value", "", "", "")
        ),
        "string"
      ),
      Foto_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_784192_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansBolum_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_215258_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansMezun_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_173977_value", "value", "", "", "")
        ),
        "string"
      ),
      LisansustuDerece_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_344987_value", "value", "", "key", "")
        ),
        "string"
      ),
      LisansustuKonu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_267744_value", "value", "", "", "")
        ),
        "string"
      ),
      LiseDerece_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_336190_value", "value", "", "key", "")
        ),
        "string"
      ),
      LiseSertifika_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_16558_value", "value", "", "", "")
        ),
        "string"
      ),
      MemapFayda_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_29927_value", "value", "", "", "")
        ),
        "boolean"
      ),
      MemapYer_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_262958_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Meslek_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "string"
      ),
      OgrenciBolum_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_954103_value", "value", "", "key", "")
        ),
        "string"
      ),
      OgrenciUni_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_465934_value", "value", "", "", "")
        ),
        "string"
      ),
      OzelPopulasyon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_466313_value", "value", "", "key", "")
        ),
        "string"
      ),
      Soyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_503657_value", "value", "", "", "")
        ),
        "string"
      ),
      TecrubeSure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_172727_value", "value", "", "key", "")
        ),
        "string"
      ),
      sehirId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_815431_value", "value", "SehirAll", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_840312_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EgitmenBasvuruFormu/EgitmenBasvuruFormuComponent_438696_onClick_if11_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.BasvuruEgitmenSave = result?.data.basvuruEgitmenSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_438696_onClick_if12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_438696_onClick_if12_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_438696_onClick_if12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_481655_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_378355_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_378355_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_503657_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "egitmenbasvuruformu_503657_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("0d0e1234-fabf-43d2-a60f-79a9933c8fb7", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName("info@memap.com.tr", "string"),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MailSubject?.length > 0
            ? stateVars.MailSubject[0].mail5Subject
            : this.state.MailSubject?.length > 0
            ? this.state.MailSubject[0].mail5Subject
            : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      basvurutipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_481655_value", "value", "", "key", "")
        ),
        "string"
      ),
      ad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_378355_value", "value", "", "", "")
        ),
        "string"
      ),
      soyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_503657_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_840312_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EgitmenBasvuruFormu/EgitmenBasvuruFormuComponent_438696_onClick_if12_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_438696_onClick_if13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_438696_onClick_if13_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_438696_onClick_if13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_840312_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "717d3336_4480_4968_5837_dc406483bb0c_notify",
          this.defaultML,
          "Başvurunuz başarı ile tamamlandı"
        ),
        "success",
        "bottom-right",
        4,
        "",
        "",
        0
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_438696_onClick_if14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_438696_onClick_if14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_438696_onClick_if14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_840312_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_438696_onClick_if15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenBasvuruFormuComponent_438696_onClick_if15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  EgitmenBasvuruFormuComponent_438696_onClick_if15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_840312_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "3247fbaa_6700_80b9_e758_535c6a7ec5aa_notify",
          this.defaultML,
          "KVKK metnini okuyup onay vermelisiniz."
        ),
        "default",
        "bottom-right",
        4,
        "",
        "",
        0
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_438696_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "102bbe15_f83b_0e38_50ba_c4fa4e7ee2d6_notify",
        this.defaultML,
        "Açık rıza metnini okuyup onay vermelisiniz."
      ),
      "default",
      "bottom-right",
      4,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_438696_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmenbasvuruformu_882933_value", "value", "", "", "")
        ),
        true
      )
    ) {
      isErrorOccurred = await this.EgitmenBasvuruFormuComponent_438696_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.EgitmenBasvuruFormuComponent_438696_onClick_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_274130_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/page/fitness_checkup/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_705103_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/page/fitskor/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_119947_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/page/pricing/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_306952_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/gyms/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_806290_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/page/thought-leadership/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_229104_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/terms_of_use_tr/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_359646_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/privacy_policy_tr/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_154721_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/platform_guide/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_850534_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/page/for-you/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_201275_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EgitmenBasvuruFormu",
      "EgitmenBasvuruFormu",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_722869_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EgitmenBasvuruFormu",
      "MerkezBasvuruForm",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_913155_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EgitmenBasvuruFormu",
      "KurumBasvuruForm",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_42267_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/page/for-schools/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_16264_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/faq/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_766495_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/sale_agreement/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EgitmenBasvuruFormuComponent_431125_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/memap_contact/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.EgitmenBasvuruFormuPageInit();
    }
  }
}
