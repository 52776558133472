import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITestDeneme2Test_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITestDeneme2Test_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  BilesenAll: any[];
  BilesenAll_dummy: any[];
  PopulasyonAll: any[];
  PopulasyonAll_dummy: any[];
  AltBilesenByBilesenId: any[];
  AltBilesenByBilesenId_dummy: any[];
  TestDenemeAsTest: any[];
  TestDenemeAsTest_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
}

export class TestDeneme2Test_ScreenBase extends React.PureComponent<ITestDeneme2Test_ScreenProps, any> {
  testdeneme2test_556057_value_kuikaSelectBoxRef: React.RefObject<any>;
  testdeneme2test_227743_value_kuikaSelectBoxRef: React.RefObject<any>;
  testdeneme2test_835245_value_kuikaSelectBoxRef: React.RefObject<any>;
  testdeneme2test_242650_value_kuikaSelectBoxRef: React.RefObject<any>;
  testdeneme2test_849550_value_kuikaSelectBoxRef: React.RefObject<any>;
  testdeneme2test_626153_value_kuikaSelectBoxRef: React.RefObject<any>;
  testdeneme2test_549317_value_kuikaSelectBoxRef: React.RefObject<any>;
  testdeneme2test_572033_value_kuikaMultiSelectRef: React.RefObject<any>;
  testdeneme2test_636352_value_kuikaSelectBoxRef: React.RefObject<any>;
  testdeneme2test_60614_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "73234d6b-fbb7-4ecb-8feb-f6f04bebf798",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Test Olarak Kaydet" },
        { Id: 5259063, PropertyName: "value", Value: "Test Adı (public)" },
        { Id: 476469, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 701867, PropertyName: "value", Value: "Test Adı (İç kullanım)" },
        { Id: 209326, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 6858922, PropertyName: "value", Value: "Bileşen" },
        { Id: 556057, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 765773, PropertyName: "value", Value: "Alt Bileşen" },
        { Id: 227743, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 393960, PropertyName: "value", Value: "Genel Görünüm" },
        { Id: 835245, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 390090, PropertyName: "value", Value: "Amaç" },
        { Id: 242650, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 193587, PropertyName: "value", Value: "Norm" },
        { Id: 849550, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 124606, PropertyName: "value", Value: "Yıldız Numarası" },
        { Id: 975857, PropertyName: "value", Value: "Popülasyon" },
        { Id: 626153, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 838865, PropertyName: "value", Value: "Cinsiyet" },
        { Id: 549317, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 615895, PropertyName: "value", Value: "Alan" },
        { Id: 572033, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 892503, PropertyName: "value", Value: "Yüklenme" },
        { Id: 636352, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 128097, PropertyName: "value", Value: "Yaş Aralığı" },
        { Id: 239641, PropertyName: "placeholder", Value: "En Küçük" },
        { Id: 274316, PropertyName: "placeholder", Value: "En Büyük" },
        { Id: 872570, PropertyName: "value", Value: "Risk Sınıfı" },
        { Id: 60614, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.testdeneme2test_556057_value_kuikaSelectBoxRef = React.createRef();
    this.testdeneme2test_227743_value_kuikaSelectBoxRef = React.createRef();
    this.testdeneme2test_835245_value_kuikaSelectBoxRef = React.createRef();
    this.testdeneme2test_242650_value_kuikaSelectBoxRef = React.createRef();
    this.testdeneme2test_849550_value_kuikaSelectBoxRef = React.createRef();
    this.testdeneme2test_626153_value_kuikaSelectBoxRef = React.createRef();
    this.testdeneme2test_549317_value_kuikaSelectBoxRef = React.createRef();
    this.testdeneme2test_572033_value_kuikaMultiSelectRef = React.createRef();
    this.testdeneme2test_636352_value_kuikaSelectBoxRef = React.createRef();
    this.testdeneme2test_60614_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      BilesenAll: [],
      PopulasyonAll: [],
      AltBilesenByBilesenId: [],
      TestDenemeAsTest: [],
      SaveRecord: false,
      NAVIGATE: "",
      dataSource_835245: [
        { key: "Acik", text: "Açık" },
        { key: "Kapali", text: "Kapalı" }
      ],
      dataSource_242650: [
        { key: "Saglik", text: "Sağlık" },
        { key: "Beceri", text: "Beceri" },
        { key: "Performans", text: "Performans" }
      ],
      dataSource_849550: [
        { key: "1", text: "Var" },
        { key: "0", text: "Yok" }
      ],
      dataSource_549317: [
        { key: "Tumu", text: "Tümü" },
        { key: "Erkek", text: "Erkek" },
        { key: "Kadin", text: "Kadın" }
      ],
      dataSource_572033: [
        { key: "Fitness", text: "Fitness" },
        { key: "Saha", text: "Saha" },
        { key: "Laboratuvar", text: "Laboratuvar" }
      ],
      dataSource_636352: [
        { key: "Bilateral", text: "Bilateral" },
        { key: "Unilateral", text: "Unilateral" }
      ],
      dataSource_60614: [
        { key: "Dusuk", text: "Düşük" },
        { key: "Orta", text: "Orta" },
        { key: "Yuksek", text: "Yüksek" }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("testdeneme2test", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TestDeneme2TestPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("testdeneme2test", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("testdeneme2test", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TestDeneme2TestPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      testdeneme2test_951285_value: 3,
      testdeneme2test_239641_value: undefined,
      testdeneme2test_274316_value: undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TestDeneme2TestPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestDeneme2Test/TestDeneme2TestPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BilesenAll = result?.data.bilesenAll;

    formVars.testdeneme2test_556057_options = stateVars.BilesenAll;
    stateVars.PopulasyonAll = result?.data.populasyonAll;

    formVars.testdeneme2test_626153_options = stateVars.PopulasyonAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestDeneme2TestPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestDeneme2TestPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_556057 = this.state.BilesenAll;
    stateVars.dataSource_556057 = this.state.BilesenAll;

    stateVars.dataSource_626153 = this.state.PopulasyonAll;
    stateVars.dataSource_626153 = this.state.PopulasyonAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TestDeneme2TestComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestDeneme2TestComponent_556057_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_556057_value", "value", "BilesenAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "testdeneme2test_556057_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      bilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_556057_value", "value", "BilesenAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TestDeneme2Test/TestDeneme2TestComponent_556057_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AltBilesenByBilesenId = result?.data.altBilesenByBilesenId;

    formVars.testdeneme2test_227743_options = stateVars.AltBilesenByBilesenId;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestDeneme2TestComponent_556057_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestDeneme2TestComponent_556057_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_227743 = this.state.AltBilesenByBilesenId;
    stateVars.dataSource_227743 = this.state.AltBilesenByBilesenId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestDeneme2TestComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_476469_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "testdeneme2test_476469_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_209326_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "testdeneme2test_209326_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_556057_value", "value", "BilesenAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "testdeneme2test_227743_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_274316_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "testdeneme2test_274316_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_239641_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "testdeneme2test_239641_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testDenemeId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestDenemeId ?? this.props.screenInputs.testdenemeid,
        "Guid"
      ),
      testAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_476469_value", "value", "", "", "")
        ),
        "string"
      ),
      testAdiInternal_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_209326_value", "value", "", "", "")
        ),
        "string"
      ),
      altBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_227743_value", "value", "AltBilesenByBilesenId", "id", "id")
        ),
        "Guid"
      ),
      YasAraligiUst_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_274316_value", "value", "", "", "")
        ),
        "number"
      ),
      YasAraligiAlt_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_239641_value", "value", "", "", "")
        ),
        "number"
      ),
      Tanim_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_476469_value", "value", "", "", "")
        ),
        "string"
      ),
      TanimInternal_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_209326_value", "value", "", "", "")
        ),
        "string"
      ),
      AltBilesenId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testdeneme2test_227743_value", "value", "AltBilesenByBilesenId", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TestDeneme2Test/TestDeneme2TestComponent_9544_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestDenemeAsTest = result?.data.testDenemeAsTest;
    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestDeneme2TestComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestDeneme2TestComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TestDeneme2Test",
      "Tests",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TestDeneme2TestPageInit();
    }
  }
}
