import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProfile_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProfile_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  ProfilSelectById: any[];
  ProfilSelectById_dummy: any[];
  KUserSelectAll: any[];
  KUserSelectAll_dummy: any[];
  KurumSelectAll: any[];
  KurumSelectAll_dummy: any[];
  Rol: any[];
  Rol_dummy: any[];
  KisiSearch: any[];
  KisiSearch_dummy: any[];
  Kirilim2ByKirilim1: any[];
  Kirilim2ByKirilim1_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  ProfileSave: number;
  ProfileSave_dummy: number;
  isComp881092Visible: "visible" | "hidden";
  isComp98582Visible: "visible" | "hidden";
  isComp202221Visible: "visible" | "hidden";
}

export class Profile_ScreenBase extends React.PureComponent<IProfile_ScreenProps, any> {
  profile_8802156_value_kuikaSelectBoxRef: React.RefObject<any>;
  profile_685451_value_kuikaSelectBoxRef: React.RefObject<any>;
  profile_834708_value_kuikaSelectBoxRef: React.RefObject<any>;
  profile_76441_value_kuikaMultiSelectRef: React.RefObject<any>;
  profile_391054_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "10ef6de2-4b19-4fda-bb8c-7b706408ca19",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Profil Tanımı" },
        { Id: 4616776, PropertyName: "value", Value: "Kurum" },
        { Id: 8802156, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 8163186, PropertyName: "value", Value: "Rol" },
        { Id: 685451, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 952323, PropertyName: "value", Value: "Öğrenci" },
        { Id: 834708, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 591502, PropertyName: "value", Value: "Sınıf" },
        { Id: 76441, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 981243, PropertyName: "value", Value: "Lisans Geçerlilik Tarihi" },
        { Id: 391054, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 683487, PropertyName: "value", Value: "Is Freelance" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.profile_8802156_value_kuikaSelectBoxRef = React.createRef();
    this.profile_685451_value_kuikaSelectBoxRef = React.createRef();
    this.profile_834708_value_kuikaSelectBoxRef = React.createRef();
    this.profile_76441_value_kuikaMultiSelectRef = React.createRef();
    this.profile_391054_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      ProfilSelectById: [],
      KUserSelectAll: [],
      KurumSelectAll: [],
      Rol: [],
      KisiSearch: [],
      Kirilim2ByKirilim1: [],
      ChangeVisibilityOf: "",
      ProfileSave: 0,
      isComp881092Visible: "hidden",
      isComp98582Visible: "hidden",
      isComp202221Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("profile", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProfilePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("profile", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("profile", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProfilePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      profile_8802156_value: this.state.ProfilSelectById?.at?.(0)?.kurumId ?? undefined,
      profile_685451_value: this.state.ProfilSelectById?.at?.(0)?.rolename ?? undefined,
      profile_834708_value: this.state.ProfilSelectById?.at?.(0)?.izledigiKisiId ?? undefined,
      profile_76441_value: this.state.ProfilSelectById?.at?.(0)?.kirilim2Ids ?? undefined,
      profile_391054_value: this.state.ProfilSelectById?.at?.(0)?.expiration ?? undefined,
      profile_792719_value: this.state.ProfilSelectById?.at?.(0)?.isFreelance ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ProfilePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      roleFilter_5: ReactSystemFunctions.convertToTypeByName("Rol_Client", "string"),
      kirilim2Id_5: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      searchFor_5: ReactSystemFunctions.convertToTypeByName("%", "string"),
      currentpage_5: ReactSystemFunctions.convertToTypeByName(1, "number"),
      pagesize_5: ReactSystemFunctions.convertToTypeByName(1000, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Profile/ProfilePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.ProfilSelectById = result?.data.profilSelectById;
    formVars.profile_8802156_value =
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].kurumId
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].kurumId
        : null;
    formVars.profile_8802156_options = stateVars.KurumSelectAll;
    formVars.profile_685451_value =
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].rolename
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].rolename
        : null;
    formVars.profile_685451_options = stateVars.Rol;
    formVars.profile_834708_value =
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].izledigiKisiId
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].izledigiKisiId
        : null;
    formVars.profile_834708_options = stateVars.KisiSearch;
    formVars.profile_76441_value =
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].kirilim2Ids
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].kirilim2Ids
        : null;
    formVars.profile_76441_options = stateVars.Kirilim2ByKirilim1;
    formVars.profile_391054_value = ReactSystemFunctions.value(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].expiration
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].expiration
        : null
    );
    formVars.profile_792719_value = ReactSystemFunctions.value(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].isFreelance
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].isFreelance
        : null
    );
    stateVars.isComp881092Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].rolename
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].rolename
            : null
        ),
        "Rol_Observer"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202221Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].rolename
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].rolename
            : null
        ),
        "Rol_Ogretmen"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp98582Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].isInstructor
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].isInstructor
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KUserSelectAll = result?.data.kUserSelectAll;
    stateVars.KurumSelectAll = result?.data.kurumSelectAll;

    formVars.profile_8802156_value =
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].kurumId
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].kurumId
        : null;
    formVars.profile_8802156_options = stateVars.KurumSelectAll;
    stateVars.Rol = result?.data.rol;

    formVars.profile_685451_value =
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].rolename
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].rolename
        : null;
    formVars.profile_685451_options = stateVars.Rol;
    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.profile_834708_value =
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].izledigiKisiId
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].izledigiKisiId
        : null;
    formVars.profile_834708_options = stateVars.KisiSearch;
    stateVars.Kirilim2ByKirilim1 = result?.data.kirilim2ByKirilim1;

    formVars.profile_76441_value =
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].kirilim2Ids
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].kirilim2Ids
        : null;
    formVars.profile_76441_options = stateVars.Kirilim2ByKirilim1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProfilePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProfilePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.profile_8802156_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].kurumId
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].kurumId
        : null
    );

    stateVars.dataSource_8802156 = this.state.KurumSelectAll;
    stateVars.dataSource_8802156 = this.state.KurumSelectAll;
    formVars.profile_685451_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].rolename
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].rolename
        : null
    );

    stateVars.dataSource_685451 = this.state.Rol;
    stateVars.dataSource_685451 = this.state.Rol;
    stateVars.isComp881092Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].rolename
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].rolename
            : null
        ),
        "Rol_Observer"
      ) === true
        ? "visible"
        : "hidden";
    formVars.profile_834708_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].izledigiKisiId
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].izledigiKisiId
        : null
    );

    stateVars.dataSource_834708 = this.state.KisiSearch;
    stateVars.dataSource_834708 = this.state.KisiSearch;
    stateVars.isComp202221Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].rolename
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].rolename
            : null
        ),
        "Rol_Ogretmen"
      ) === true
        ? "visible"
        : "hidden";
    formVars.profile_76441_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].kirilim2Ids
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].kirilim2Ids
        : null
    );

    stateVars.dataSource_76441 = this.state.Kirilim2ByKirilim1;
    stateVars.dataSource_76441 = this.state.Kirilim2ByKirilim1;
    formVars.profile_391054_value = ReactSystemFunctions.value(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].expiration
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].expiration
        : null
    );

    stateVars.isComp98582Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.ProfilSelectById?.length > 0
            ? stateVars.ProfilSelectById[0].isInstructor
            : this.state.ProfilSelectById?.length > 0
            ? this.state.ProfilSelectById[0].isInstructor
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.profile_792719_value = ReactSystemFunctions.value(
      this,
      stateVars.ProfilSelectById?.length > 0
        ? stateVars.ProfilSelectById[0].isFreelance
        : this.state.ProfilSelectById?.length > 0
        ? this.state.ProfilSelectById[0].isFreelance
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProfileComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProfileComponent_8802156_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profile_8802156_value", "value", "KurumSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "profile_8802156_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "profile_8802156_value", "value", "KurumSelectAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Profile/ProfileComponent_8802156_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Kirilim2ByKirilim1 = result?.data.kirilim2ByKirilim1;

    formVars.profile_76441_options = stateVars.Kirilim2ByKirilim1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProfileComponent_8802156_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProfileComponent_8802156_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_76441 = this.state.Kirilim2ByKirilim1;
    stateVars.dataSource_76441 = this.state.Kirilim2ByKirilim1;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProfileComponent_685451_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp881092Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProfileComponent_685451_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProfileComponent_685451_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profile_685451_value", "value", "Rol", "val", "val")
        ),
        "Rol_Observer"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp881092Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ProfileComponent_685451_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ProfileComponent_685451_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ProfileComponent_685451_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp98582Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProfileComponent_685451_onChange3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProfileComponent_685451_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profile_685451_value", "value", "Rol", "val", "val")
        ),
        "Rol_Instructor"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp98582Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ProfileComponent_685451_onChange4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ProfileComponent_685451_onChange4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ProfileComponent_685451_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp202221Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProfileComponent_685451_onChange5_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ProfileComponent_685451_onChange5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profile_685451_value", "value", "Rol", "val", "val")
        ),
        "Rol_Ogretmen"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp202221Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ProfileComponent_685451_onChange6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ProfileComponent_685451_onChange6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ProfileComponent_685451_onChange6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profile_8802156_value", "value", "KurumSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "profile_8802156_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName("Rol_Client", "string"),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "profile_8802156_value", "value", "KurumSelectAll", "id", "id")
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      searchFor_0: ReactSystemFunctions.convertToTypeByName("%", "string"),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(1000, "number")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Profile/ProfileComponent_685451_onChange6_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.profile_834708_options = stateVars.KisiSearch;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProfileComponent_685451_onChange7_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProfileComponent_685451_onChange7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_834708 = this.state.KisiSearch;
    stateVars.dataSource_834708 = this.state.KisiSearch;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProfileComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profile_685451_value", "value", "Rol", "val", "")
        ),
        null
      ),
      message: "*",
      formName: "profile_685451_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profile_8802156_value", "value", "KurumSelectAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "profile_8802156_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "profile_391054_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "profile_391054_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "profile_8802156_value",
            "value",
            "KurumSelectAll",
            "id",
            "expirationTakipEdilsin"
          )
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profile_834708_value", "value", "KisiSearch", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "profile_834708_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profile_685451_value", "value", "Rol", "val", "val")
        ),
        "Rol_Observer"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid,
        "Guid"
      ),
      rolename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profile_685451_value", "value", "Rol", "val", "val")
        ),
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "profile_8802156_value", "value", "KurumSelectAll", "id", "id")
        ),
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      expiration_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "profile_391054_value", "value", "", "", "")),
        "Date"
      ),
      isFreelance_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "profile_792719_value", "value", "", "", "")),
        "boolean"
      ),
      izledigiKisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "profile_834708_value", "value", "KisiSearch", "id", "id")
        ),
        "Guid"
      ),
      kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "profile_76441_value", "value", "Kirilim2ByKirilim1", "id", "id")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Profile/ProfileComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProfileSave = result?.data.profileSave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProfileComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProfileComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
