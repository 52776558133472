import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICheckupF_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICheckupF_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  CheckupData: any[];
  CheckupData_dummy: any[];
  CheckupMeta: any[];
  CheckupMeta_dummy: any[];
  KisiSelectById: any[];
  KisiSelectById_dummy: any[];
  AltBilesen1Testleri: any[];
  AltBilesen1Testleri_dummy: any[];
  AltBilesen2Testleri: any[];
  AltBilesen2Testleri_dummy: any[];
  AltBilesen3Testleri: any[];
  AltBilesen3Testleri_dummy: any[];
  AltBilesen4Testleri: any[];
  AltBilesen4Testleri_dummy: any[];
  AltBilesen5Testleri: any[];
  AltBilesen5Testleri_dummy: any[];
  AltBilesen6Testleri: any[];
  AltBilesen6Testleri_dummy: any[];
  AltBilesen7Testleri: any[];
  AltBilesen7Testleri_dummy: any[];
  AltBilesen8Testleri: any[];
  AltBilesen8Testleri_dummy: any[];
  AltBilesen9Testleri: any[];
  AltBilesen9Testleri_dummy: any[];
  AltBilesen10Testleri: any[];
  AltBilesen10Testleri_dummy: any[];
  AltBilesen11Testleri: any[];
  AltBilesen11Testleri_dummy: any[];
  AltBilesen12Testleri: any[];
  AltBilesen12Testleri_dummy: any[];
  AltBilesen13Testleri: any[];
  AltBilesen13Testleri_dummy: any[];
  ProtokolunHizmetleri: any[];
  ProtokolunHizmetleri_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  DummySave: any[];
  DummySave_dummy: any[];
  CheckUpFYasHesapla: any[];
  CheckUpFYasHesapla_dummy: any[];
  BaseUrl: any[];
  BaseUrl_dummy: any[];
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  OpenInBrowser: any;
  OpenInBrowser_dummy: any;
  TestExecute: any[];
  TestExecute_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  CheckupTekliSave: number;
  CheckupTekliSave_dummy: number;
  CheckupBilesenleriKaydet: number;
  CheckupBilesenleriKaydet_dummy: number;
  CheckupData2: any[];
  CheckupData2_dummy: any[];
  SiniflandirmaHesapla: any[];
  SiniflandirmaHesapla_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  FitSkorHesapla: any[];
  FitSkorHesapla_dummy: any[];
  KisiCheckupAdedi: any[];
  KisiCheckupAdedi_dummy: any[];
  KullaniciMailLogoUrl: any[];
  KullaniciMailLogoUrl_dummy: any[];
  CheckupMail: any[];
  CheckupMail_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  Notify: boolean;
  Notify_dummy: boolean;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  isComp543454Visible: "visible" | "hidden";
  isComp699912Visible: "visible" | "hidden";
  isComp319999Visible: "visible" | "hidden";
  isComp944490Visible: "visible" | "hidden";
  isComp7471646Visible: "visible" | "hidden";
  isComp510803Visible: "visible" | "hidden";
  isComp216233Visible: "visible" | "hidden";
  isComp179819Visible: "visible" | "hidden";
  isComp905629Visible: "visible" | "hidden";
  isComp731109Visible: "visible" | "hidden";
  isComp906929Visible: "visible" | "hidden";
  isComp15252Visible: "visible" | "hidden";
  isComp330353Visible: "visible" | "hidden";
  isComp868884Visible: "visible" | "hidden";
  isComp559118Visible: "visible" | "hidden";
  isComp778240Visible: "visible" | "hidden";
  isComp809924Visible: "visible" | "hidden";
  isComp333825Visible: "visible" | "hidden";
  isComp715446Visible: "visible" | "hidden";
  isComp794446Visible: "visible" | "hidden";
  isComp908895Visible: "visible" | "hidden";
  isComp62484Visible: "visible" | "hidden";
  isComp737319Visible: "visible" | "hidden";
  isComp213419Visible: "visible" | "hidden";
  isComp671173Visible: "visible" | "hidden";
  isComp160509Visible: "visible" | "hidden";
  isComp564442Visible: "visible" | "hidden";
  isComp486342Visible: "visible" | "hidden";
  isComp866359Visible: "visible" | "hidden";
  isComp970568Visible: "visible" | "hidden";
  isComp484054Visible: "visible" | "hidden";
  isComp459319Visible: "visible" | "hidden";
  isComp552646Visible: "visible" | "hidden";
  isComp858132Visible: "visible" | "hidden";
  isComp833754Visible: "visible" | "hidden";
  isComp148686Visible: "visible" | "hidden";
  isComp315640Visible: "visible" | "hidden";
  isComp431362Visible: "visible" | "hidden";
  isComp934273Visible: "visible" | "hidden";
  isComp172628Visible: "visible" | "hidden";
  isComp178101Visible: "visible" | "hidden";
  isComp604698Visible: "visible" | "hidden";
  isComp409984Visible: "visible" | "hidden";
  isComp918055Visible: "visible" | "hidden";
  isComp330672Visible: "visible" | "hidden";
  isComp775669Visible: "visible" | "hidden";
  isComp127185Visible: "visible" | "hidden";
  isComp942457Visible: "visible" | "hidden";
  isComp972311Visible: "visible" | "hidden";
  isComp410574Visible: "visible" | "hidden";
  isComp229454Visible: "visible" | "hidden";
  isComp847379Visible: "visible" | "hidden";
  isComp110347Visible: "visible" | "hidden";
  isComp401993Visible: "visible" | "hidden";
  isComp737330Visible: "visible" | "hidden";
  isComp303017Visible: "visible" | "hidden";
  isComp879819Visible: "visible" | "hidden";
  isComp551320Visible: "visible" | "hidden";
  isComp703893Visible: "visible" | "hidden";
  isComp984127Visible: "visible" | "hidden";
  isComp802104Visible: "visible" | "hidden";
  isComp407695Visible: "visible" | "hidden";
  isComp55955Visible: "visible" | "hidden";
  isComp841154Visible: "visible" | "hidden";
  isComp61460Visible: "visible" | "hidden";
  isComp426204Visible: "visible" | "hidden";
  isComp663238Visible: "visible" | "hidden";
  isComp579165Visible: "visible" | "hidden";
  isComp368313Visible: "visible" | "hidden";
  isComp293494Visible: "visible" | "hidden";
  isComp431891Visible: "visible" | "hidden";
  isComp637907Visible: "visible" | "hidden";
  isComp900697Visible: "visible" | "hidden";
  isComp946255Visible: "visible" | "hidden";
  isComp363431Visible: "visible" | "hidden";
  isComp529166Visible: "visible" | "hidden";
  isComp444186Visible: "visible" | "hidden";
  isComp480511Visible: "visible" | "hidden";
  isComp207616Visible: "visible" | "hidden";
  isComp787323Visible: "visible" | "hidden";
  isComp37156Visible: "visible" | "hidden";
  isComp475501Visible: "visible" | "hidden";
  isComp675920Visible: "visible" | "hidden";
  isComp196189Visible: "visible" | "hidden";
  isComp851329Visible: "visible" | "hidden";
  isComp774902Visible: "visible" | "hidden";
  isComp89417Visible: "visible" | "hidden";
  isComp633271Visible: "visible" | "hidden";
  isComp135860Visible: "visible" | "hidden";
  isComp821841Visible: "visible" | "hidden";
  isComp292389Visible: "visible" | "hidden";
  isComp132779Visible: "visible" | "hidden";
  isComp352438Visible: "visible" | "hidden";
  isComp346044Visible: "visible" | "hidden";
  isComp136214Visible: "visible" | "hidden";
  isComp600014Visible: "visible" | "hidden";
  isComp75709Visible: "visible" | "hidden";
  isComp997083Visible: "visible" | "hidden";
  isComp813054Visible: "visible" | "hidden";
  isComp528080Visible: "visible" | "hidden";
  isComp202938Visible: "visible" | "hidden";
  isComp765049Visible: "visible" | "hidden";
  isComp848222Visible: "visible" | "hidden";
  isComp144171Visible: "visible" | "hidden";
  isComp840013Visible: "visible" | "hidden";
  isComp874835Visible: "visible" | "hidden";
  isComp110140Visible: "visible" | "hidden";
  isComp65547Visible: "visible" | "hidden";
  isComp775779Visible: "visible" | "hidden";
  isComp463996Visible: "visible" | "hidden";
  isComp193012Visible: "visible" | "hidden";
  isComp995517Visible: "visible" | "hidden";
  isComp333477Visible: "visible" | "hidden";
  isComp285545Visible: "visible" | "hidden";
  isComp698959Visible: "visible" | "hidden";
  isComp138049Visible: "visible" | "hidden";
  isComp320401Visible: "visible" | "hidden";
  isComp175686Visible: "visible" | "hidden";
  isComp551885Visible: "visible" | "hidden";
  isComp662267Visible: "visible" | "hidden";
  isComp64505Visible: "visible" | "hidden";
  isComp287838Visible: "visible" | "hidden";
  isComp385058Visible: "visible" | "hidden";
  isComp895329Visible: "visible" | "hidden";
  isComp354071Visible: "visible" | "hidden";
  isComp210104Visible: "visible" | "hidden";
  isComp981878Visible: "visible" | "hidden";
  isComp587393Visible: "visible" | "hidden";
  isComp287399Visible: "visible" | "hidden";
  isComp486993Visible: "visible" | "hidden";
  isComp444352Visible: "visible" | "hidden";
  isComp549331Visible: "visible" | "hidden";
  isComp484116Visible: "visible" | "hidden";
  isComp389347Visible: "visible" | "hidden";
  isComp257094Visible: "visible" | "hidden";
  isComp264613Visible: "visible" | "hidden";
  isComp169200Visible: "visible" | "hidden";
  isComp983990Visible: "visible" | "hidden";
  isComp283148Visible: "visible" | "hidden";
  isComp624590Visible: "visible" | "hidden";
  isComp965140Visible: "visible" | "hidden";
  isComp126511Visible: "visible" | "hidden";
  isComp456155Visible: "visible" | "hidden";
  isComp319300Visible: "visible" | "hidden";
  isComp704910Visible: "visible" | "hidden";
  isComp455530Visible: "visible" | "hidden";
  isComp808223Visible: "visible" | "hidden";
  isComp83939Visible: "visible" | "hidden";
  isComp126174Visible: "visible" | "hidden";
  isComp802246Visible: "visible" | "hidden";
  isComp256022Visible: "visible" | "hidden";
  isComp443677Visible: "visible" | "hidden";
  isComp551536Visible: "visible" | "hidden";
  isComp891525Visible: "visible" | "hidden";
  isComp385921Visible: "visible" | "hidden";
  isComp581736Visible: "visible" | "hidden";
  isComp957472Visible: "visible" | "hidden";
  isComp134787Visible: "visible" | "hidden";
  isComp59681Visible: "visible" | "hidden";
  isComp34987Visible: "visible" | "hidden";
  isComp917474Visible: "visible" | "hidden";
  isComp10699Visible: "visible" | "hidden";
  isComp320106Visible: "visible" | "hidden";
  isComp820124Visible: "visible" | "hidden";
  isComp702841Visible: "visible" | "hidden";
  isComp634101Visible: "visible" | "hidden";
  isComp159973Visible: "visible" | "hidden";
  isComp652137Visible: "visible" | "hidden";
  isComp77197Visible: "visible" | "hidden";
  isComp820982Visible: "visible" | "hidden";
  isComp678030Visible: "visible" | "hidden";
  isComp536996Visible: "visible" | "hidden";
  isComp360719Visible: "visible" | "hidden";
  isComp790033Visible: "visible" | "hidden";
  isComp805006Visible: "visible" | "hidden";
  isComp327050Visible: "visible" | "hidden";
  isComp349099Visible: "visible" | "hidden";
  isComp453124Visible: "visible" | "hidden";
  isComp52399Visible: "visible" | "hidden";
  isComp113195Visible: "visible" | "hidden";
  isComp241697Visible: "visible" | "hidden";
  isComp412449Visible: "visible" | "hidden";
  isComp2509Enabled: "enabled" | "disabled";
  isComp944772Enabled: "enabled" | "disabled";
  isComp218416Enabled: "enabled" | "disabled";
  isComp834990Enabled: "enabled" | "disabled";
  isComp712501Enabled: "enabled" | "disabled";
  isComp600360Enabled: "enabled" | "disabled";
  isComp240986Enabled: "enabled" | "disabled";
  isComp7480Enabled: "enabled" | "disabled";
  isComp62063Enabled: "enabled" | "disabled";
  isComp165138Enabled: "enabled" | "disabled";
  isComp108466Enabled: "enabled" | "disabled";
  isComp204783Enabled: "enabled" | "disabled";
  isComp20874Enabled: "enabled" | "disabled";
  isComp615683Enabled: "enabled" | "disabled";
  isComp799182Enabled: "enabled" | "disabled";
  isComp749559Enabled: "enabled" | "disabled";
  isComp910913Enabled: "enabled" | "disabled";
  isComp446195Enabled: "enabled" | "disabled";
  isComp50512Enabled: "enabled" | "disabled";
  isComp477360Enabled: "enabled" | "disabled";
  isComp38410Enabled: "enabled" | "disabled";
  isComp736592Enabled: "enabled" | "disabled";
  isComp584267Enabled: "enabled" | "disabled";
  isComp183669Enabled: "enabled" | "disabled";
  isComp439367Enabled: "enabled" | "disabled";
  isComp66346Enabled: "enabled" | "disabled";
  isComp272601Enabled: "enabled" | "disabled";
  isComp604892Enabled: "enabled" | "disabled";
  isComp206874Enabled: "enabled" | "disabled";
  isComp294219Enabled: "enabled" | "disabled";
  isComp861350Enabled: "enabled" | "disabled";
  isComp403924Enabled: "enabled" | "disabled";
  isComp709588Enabled: "enabled" | "disabled";
  isComp760834Enabled: "enabled" | "disabled";
  isComp86834Enabled: "enabled" | "disabled";
  isComp113173Enabled: "enabled" | "disabled";
  isComp294909Enabled: "enabled" | "disabled";
  isComp722637Enabled: "enabled" | "disabled";
  isComp322797Enabled: "enabled" | "disabled";
  isComp372084Enabled: "enabled" | "disabled";
  isComp452596Enabled: "enabled" | "disabled";
  isComp608811Enabled: "enabled" | "disabled";
  isComp188042Enabled: "enabled" | "disabled";
  isComp546001Enabled: "enabled" | "disabled";
  isComp352264Enabled: "enabled" | "disabled";
  isComp431679Enabled: "enabled" | "disabled";
  isComp110193Enabled: "enabled" | "disabled";
  isComp183892Enabled: "enabled" | "disabled";
  isComp806951Enabled: "enabled" | "disabled";
  isComp963867Enabled: "enabled" | "disabled";
  isComp71137Enabled: "enabled" | "disabled";
  isComp93843Enabled: "enabled" | "disabled";
}

export class CheckupF_ScreenBase extends React.PureComponent<ICheckupF_ScreenProps, any> {
  checkupf_696401_value_kuikaDateRef: React.RefObject<any>;
  checkupf_486014_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_685456_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_295230_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_285289_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_818509_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_414768_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_398094_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_63093_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_625584_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_915251_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_250633_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_711326_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_543052_value_kuikaSelectBoxRef: React.RefObject<any>;
  checkupf_178016_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 101238, PropertyName: "value", Value: "Check-Up Date", TagName: "label2_value" },
        { Id: 696401, PropertyName: "placeholder", Value: "Choose...", TagName: "dtCheckup_placeholder" },
        { Id: 764402, PropertyName: "value", Value: "High", TagName: "label3_value" },
        { Id: 965654, PropertyName: "placeholder", Value: "cm", TagName: "numBoy_placeholder" },
        { Id: 74739, PropertyName: "value", Value: "Weight", TagName: "Label1_0_value" },
        { Id: 425186, PropertyName: "placeholder", Value: "kg", TagName: "numAgirlik_placeholder" },
        { Id: 910160, PropertyName: "value", Value: "-", TagName: "Label100_value" },
        { Id: 486014, PropertyName: "placeholder", Value: "Choose...", TagName: "selTest1_placeholder" },
        {
          Id: 2509,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest1Arasonuc1_placeholder"
        },
        {
          Id: 944772,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest1Arasonuc2_placeholder"
        },
        {
          Id: 218416,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest1Arasonuc3_placeholder"
        },
        { Id: 203443, PropertyName: "value", Value: "Score", TagName: "Label1_0_value" },
        {
          Id: 834990,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest1Arasonuc4_placeholder"
        },
        { Id: 913067, PropertyName: "value", Value: "-", TagName: "Label101_value" },
        { Id: 685456, PropertyName: "placeholder", Value: "Choose...", TagName: "selTest2_placeholder" },
        {
          Id: 712501,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest2Arasonuc1_placeholder"
        },
        {
          Id: 600360,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest2Arasonuc2_placeholder"
        },
        {
          Id: 240986,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest2Arasonuc3_placeholder"
        },
        { Id: 654846, PropertyName: "value", Value: "Score", TagName: "Label1_0_value" },
        {
          Id: 7480,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest2Arasonuc4_placeholder"
        },
        { Id: 779200, PropertyName: "value", Value: "-", TagName: "Label102_value" },
        { Id: 295230, PropertyName: "placeholder", Value: "Choose", TagName: "selTest3_placeholder" },
        {
          Id: 62063,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest3Arasonuc1_placeholder"
        },
        {
          Id: 165138,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest3Arasonuc2_placeholder"
        },
        {
          Id: 108466,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest3Arasonuc3_placeholder"
        },
        { Id: 723473, PropertyName: "value", Value: "Score", TagName: "Label1_0_value" },
        {
          Id: 204783,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest3Arasonuc4_placeholder"
        },
        { Id: 187173, PropertyName: "value", Value: "-", TagName: "Label103_value" },
        { Id: 285289, PropertyName: "placeholder", Value: "Choose", TagName: "selTest4_placeholder" },
        {
          Id: 20874,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest4Arasonuc1_placeholder"
        },
        {
          Id: 615683,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest4Arasonuc2_placeholder"
        },
        {
          Id: 799182,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest4Arasonuc3_placeholder"
        },
        { Id: 865235, PropertyName: "value", Value: "Score", TagName: "Label1_0_value" },
        {
          Id: 749559,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest4Arasonuc4_placeholder"
        },
        { Id: 200402, PropertyName: "value", Value: "-", TagName: "Label104_value" },
        { Id: 818509, PropertyName: "placeholder", Value: "Choose...", TagName: "selTest5_placeholder" },
        {
          Id: 910913,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest5Arasonuc1_placeholder"
        },
        {
          Id: 446195,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest5Arasonuc2_placeholder"
        },
        {
          Id: 50512,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest5Arasonuc3_placeholder"
        },
        { Id: 793931, PropertyName: "value", Value: "Score", TagName: "Label1_0_value" },
        {
          Id: 477360,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest5Arasonuc4_placeholder"
        },
        { Id: 555635, PropertyName: "value", Value: "-", TagName: "Label105_value" },
        { Id: 414768, PropertyName: "placeholder", Value: "Choose...", TagName: "selTest6_placeholder" },
        {
          Id: 38410,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest6Arasonuc1_placeholder"
        },
        {
          Id: 736592,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest6Arasonuc2_placeholder"
        },
        {
          Id: 584267,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest6Arasonuc3_placeholder"
        },
        { Id: 722406, PropertyName: "value", Value: "Score", TagName: "Label1_0_value" },
        {
          Id: 183669,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest6Arasonuc4_placeholder"
        },
        { Id: 191325, PropertyName: "value", Value: "-", TagName: "Label106_value" },
        { Id: 398094, PropertyName: "placeholder", Value: "Choose...", TagName: "selTest7_placeholder" },
        {
          Id: 439367,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest7Arasonuc1_placeholder"
        },
        {
          Id: 66346,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest7Arasonuc2_placeholder"
        },
        {
          Id: 272601,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest7Arasonuc3_placeholder"
        },
        { Id: 44862, PropertyName: "value", Value: "Score", TagName: "Label1_0_value" },
        {
          Id: 604892,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest7Arasonuc4_placeholder"
        },
        { Id: 318536, PropertyName: "value", Value: "-", TagName: "Label107_value" },
        { Id: 63093, PropertyName: "placeholder", Value: "Choose...", TagName: "selTest8_placeholder" },
        {
          Id: 206874,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest8Arasonuc1_placeholder"
        },
        {
          Id: 294219,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest8Arasonuc2_placeholder"
        },
        {
          Id: 861350,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest8Arasonuc3_placeholder"
        },
        { Id: 849092, PropertyName: "value", Value: "Score", TagName: "Label1_0_value" },
        {
          Id: 403924,
          PropertyName: "placeholder",
          Value: "Will be calculated",
          TagName: "numTest8Arasonuc4_placeholder"
        },
        { Id: 282374, PropertyName: "value", Value: "Instructor's note", TagName: "label1_0_value" },
        { Id: 9544, PropertyName: "label", Value: "SAVE", TagName: "btnSave_label" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "36514dd9-94b3-4be5-8b60-864efa4a623b",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 508287, PropertyName: "value", Value: "(" },
        { Id: 765981, PropertyName: "value", Value: ")" },
        { Id: 733224, PropertyName: "value", Value: "Danışan" },
        { Id: 780911, PropertyName: "value", Value: "Check-Up Tarihi" },
        { Id: 696401, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 764402, PropertyName: "value", Value: "Boy" },
        { Id: 965654, PropertyName: "placeholder", Value: "cm" },
        { Id: 74739, PropertyName: "value", Value: "Vücut Ağırlığı" },
        { Id: 425186, PropertyName: "placeholder", Value: "kg" },
        { Id: 910160, PropertyName: "value", Value: "-" },
        { Id: 486014, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 2509, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 944772, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 218416, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 203443, PropertyName: "value", Value: "Puan" },
        { Id: 834990, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 913067, PropertyName: "value", Value: "-" },
        { Id: 685456, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 712501, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 600360, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 240986, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 654846, PropertyName: "value", Value: "Puan" },
        { Id: 7480, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 779200, PropertyName: "value", Value: "-" },
        { Id: 295230, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 62063, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 165138, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 108466, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 723473, PropertyName: "value", Value: "Puan" },
        { Id: 204783, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 187173, PropertyName: "value", Value: "-" },
        { Id: 285289, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 20874, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 615683, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 799182, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 865235, PropertyName: "value", Value: "Puan" },
        { Id: 749559, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 200402, PropertyName: "value", Value: "-" },
        { Id: 818509, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 910913, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 446195, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 50512, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 793931, PropertyName: "value", Value: "Puan" },
        { Id: 477360, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 555635, PropertyName: "value", Value: "-" },
        { Id: 414768, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 38410, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 736592, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 584267, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 722406, PropertyName: "value", Value: "Puan" },
        { Id: 183669, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 191325, PropertyName: "value", Value: "-" },
        { Id: 398094, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 439367, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 66346, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 272601, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 44862, PropertyName: "value", Value: "Puan" },
        { Id: 604892, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 318536, PropertyName: "value", Value: "-" },
        { Id: 63093, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 206874, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 294219, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 861350, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 849092, PropertyName: "value", Value: "Puan" },
        { Id: 403924, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 372937, PropertyName: "value", Value: "-" },
        { Id: 625584, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 709588, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 760834, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 86834, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 666448, PropertyName: "value", Value: "Puan" },
        { Id: 113173, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 207710, PropertyName: "value", Value: "-" },
        { Id: 915251, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 294909, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 722637, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 322797, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 344194, PropertyName: "value", Value: "Puan" },
        { Id: 372084, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 12225, PropertyName: "value", Value: "-" },
        { Id: 250633, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 452596, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 608811, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 188042, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 404944, PropertyName: "value", Value: "Puan" },
        { Id: 546001, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 796513, PropertyName: "value", Value: "-" },
        { Id: 711326, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 352264, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 431679, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 110193, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 125024, PropertyName: "value", Value: "Puan" },
        { Id: 183892, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 25101, PropertyName: "value", Value: "-" },
        { Id: 543052, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 806951, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 963867, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 71137, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 893423, PropertyName: "value", Value: "Puan" },
        { Id: 93843, PropertyName: "placeholder", Value: "Hesaplanacak" },
        { Id: 282374, PropertyName: "value", Value: "Eğitmenin Notu" },
        { Id: 46860, PropertyName: "value", Value: "Hizmet" },
        { Id: 178016, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" },
        { Id: 241697, PropertyName: "label", Value: "trigger hidden" },
        { Id: 412449, PropertyName: "label", Value: "save record" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.checkupf_696401_value_kuikaDateRef = React.createRef();
    this.checkupf_486014_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_685456_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_295230_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_285289_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_818509_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_414768_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_398094_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_63093_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_625584_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_915251_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_250633_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_711326_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_543052_value_kuikaSelectBoxRef = React.createRef();
    this.checkupf_178016_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      CheckupData: [],
      CheckupMeta: [],
      KisiSelectById: [],
      AltBilesen1Testleri: [],
      AltBilesen2Testleri: [],
      AltBilesen3Testleri: [],
      AltBilesen4Testleri: [],
      AltBilesen5Testleri: [],
      AltBilesen6Testleri: [],
      AltBilesen7Testleri: [],
      AltBilesen8Testleri: [],
      AltBilesen9Testleri: [],
      AltBilesen10Testleri: [],
      AltBilesen11Testleri: [],
      AltBilesen12Testleri: [],
      AltBilesen13Testleri: [],
      ProtokolunHizmetleri: [],
      ChangeEnabledOf: "",
      DummySave: [],
      CheckUpFYasHesapla: [],
      BaseUrl: [],
      TriggerEvent: "",
      OpenInBrowser: "",
      TestExecute: [],
      SetValueOf: "",
      CheckupTekliSave: 0,
      CheckupBilesenleriKaydet: 0,
      CheckupData2: [],
      SiniflandirmaHesapla: [],
      SaveRecord: false,
      FitSkorHesapla: [],
      KisiCheckupAdedi: [],
      KullaniciMailLogoUrl: [],
      CheckupMail: [],
      IfThenElse: false,
      isComp543454Visible: "hidden",
      isComp699912Visible: "hidden",
      isComp319999Visible: "hidden",
      isComp944490Visible: "hidden",
      isComp7471646Visible: "hidden",
      isComp510803Visible: "hidden",
      isComp216233Visible: "hidden",
      isComp179819Visible: "hidden",
      isComp905629Visible: "hidden",
      isComp731109Visible: "hidden",
      isComp906929Visible: "hidden",
      isComp15252Visible: "hidden",
      isComp330353Visible: "hidden",
      isComp868884Visible: "hidden",
      isComp559118Visible: "hidden",
      isComp778240Visible: "hidden",
      isComp809924Visible: "hidden",
      isComp333825Visible: "hidden",
      isComp715446Visible: "hidden",
      isComp794446Visible: "hidden",
      isComp908895Visible: "hidden",
      isComp62484Visible: "hidden",
      isComp737319Visible: "hidden",
      isComp213419Visible: "hidden",
      isComp671173Visible: "hidden",
      isComp160509Visible: "hidden",
      isComp564442Visible: "hidden",
      isComp486342Visible: "hidden",
      isComp866359Visible: "hidden",
      isComp970568Visible: "hidden",
      isComp484054Visible: "hidden",
      isComp459319Visible: "hidden",
      isComp552646Visible: "hidden",
      isComp858132Visible: "hidden",
      isComp833754Visible: "hidden",
      isComp148686Visible: "hidden",
      isComp315640Visible: "hidden",
      isComp431362Visible: "hidden",
      isComp934273Visible: "hidden",
      isComp172628Visible: "hidden",
      isComp178101Visible: "hidden",
      isComp604698Visible: "hidden",
      isComp409984Visible: "hidden",
      isComp918055Visible: "hidden",
      isComp330672Visible: "hidden",
      isComp775669Visible: "hidden",
      isComp127185Visible: "hidden",
      isComp942457Visible: "hidden",
      isComp972311Visible: "hidden",
      isComp410574Visible: "hidden",
      isComp229454Visible: "hidden",
      isComp847379Visible: "hidden",
      isComp110347Visible: "hidden",
      isComp401993Visible: "hidden",
      isComp737330Visible: "hidden",
      isComp303017Visible: "hidden",
      isComp879819Visible: "hidden",
      isComp551320Visible: "hidden",
      isComp703893Visible: "hidden",
      isComp984127Visible: "hidden",
      isComp802104Visible: "hidden",
      isComp407695Visible: "hidden",
      isComp55955Visible: "hidden",
      isComp841154Visible: "hidden",
      isComp61460Visible: "hidden",
      isComp426204Visible: "hidden",
      isComp663238Visible: "hidden",
      isComp579165Visible: "hidden",
      isComp368313Visible: "hidden",
      isComp293494Visible: "hidden",
      isComp431891Visible: "hidden",
      isComp637907Visible: "hidden",
      isComp900697Visible: "hidden",
      isComp946255Visible: "hidden",
      isComp363431Visible: "hidden",
      isComp529166Visible: "hidden",
      isComp444186Visible: "hidden",
      isComp480511Visible: "hidden",
      isComp207616Visible: "hidden",
      isComp787323Visible: "hidden",
      isComp37156Visible: "hidden",
      isComp475501Visible: "hidden",
      isComp675920Visible: "hidden",
      isComp196189Visible: "hidden",
      isComp851329Visible: "hidden",
      isComp774902Visible: "hidden",
      isComp89417Visible: "hidden",
      isComp633271Visible: "hidden",
      isComp135860Visible: "hidden",
      isComp821841Visible: "hidden",
      isComp292389Visible: "hidden",
      isComp132779Visible: "hidden",
      isComp352438Visible: "hidden",
      isComp346044Visible: "hidden",
      isComp136214Visible: "hidden",
      isComp600014Visible: "hidden",
      isComp75709Visible: "hidden",
      isComp997083Visible: "hidden",
      isComp813054Visible: "hidden",
      isComp528080Visible: "hidden",
      isComp202938Visible: "hidden",
      isComp765049Visible: "hidden",
      isComp848222Visible: "hidden",
      isComp144171Visible: "hidden",
      isComp840013Visible: "hidden",
      isComp874835Visible: "hidden",
      isComp110140Visible: "hidden",
      isComp65547Visible: "hidden",
      isComp775779Visible: "hidden",
      isComp463996Visible: "hidden",
      isComp193012Visible: "hidden",
      isComp995517Visible: "hidden",
      isComp333477Visible: "hidden",
      isComp285545Visible: "hidden",
      isComp698959Visible: "hidden",
      isComp138049Visible: "hidden",
      isComp320401Visible: "hidden",
      isComp175686Visible: "hidden",
      isComp551885Visible: "hidden",
      isComp662267Visible: "hidden",
      isComp64505Visible: "hidden",
      isComp287838Visible: "hidden",
      isComp385058Visible: "hidden",
      isComp895329Visible: "hidden",
      isComp354071Visible: "hidden",
      isComp210104Visible: "hidden",
      isComp981878Visible: "hidden",
      isComp587393Visible: "hidden",
      isComp287399Visible: "hidden",
      isComp486993Visible: "hidden",
      isComp444352Visible: "hidden",
      isComp549331Visible: "hidden",
      isComp484116Visible: "hidden",
      isComp389347Visible: "hidden",
      isComp257094Visible: "hidden",
      isComp264613Visible: "hidden",
      isComp169200Visible: "hidden",
      isComp983990Visible: "hidden",
      isComp283148Visible: "hidden",
      isComp624590Visible: "hidden",
      isComp965140Visible: "hidden",
      isComp126511Visible: "hidden",
      isComp456155Visible: "hidden",
      isComp319300Visible: "hidden",
      isComp704910Visible: "hidden",
      isComp455530Visible: "hidden",
      isComp808223Visible: "hidden",
      isComp83939Visible: "hidden",
      isComp126174Visible: "hidden",
      isComp802246Visible: "hidden",
      isComp256022Visible: "hidden",
      isComp443677Visible: "hidden",
      isComp551536Visible: "hidden",
      isComp891525Visible: "hidden",
      isComp385921Visible: "hidden",
      isComp581736Visible: "hidden",
      isComp957472Visible: "hidden",
      isComp134787Visible: "hidden",
      isComp59681Visible: "hidden",
      isComp34987Visible: "hidden",
      isComp917474Visible: "hidden",
      isComp10699Visible: "hidden",
      isComp320106Visible: "hidden",
      isComp820124Visible: "hidden",
      isComp702841Visible: "hidden",
      isComp634101Visible: "hidden",
      isComp159973Visible: "hidden",
      isComp652137Visible: "hidden",
      isComp77197Visible: "hidden",
      isComp820982Visible: "hidden",
      isComp678030Visible: "hidden",
      isComp536996Visible: "hidden",
      isComp360719Visible: "hidden",
      isComp790033Visible: "hidden",
      isComp805006Visible: "hidden",
      isComp327050Visible: "hidden",
      isComp349099Visible: "hidden",
      isComp453124Visible: "hidden",
      isComp52399Visible: "hidden",
      isComp113195Visible: "hidden",
      isComp241697Visible: "hidden",
      isComp412449Visible: "hidden",
      isComp2509Enabled: "enabled",
      isComp944772Enabled: "enabled",
      isComp218416Enabled: "enabled",
      isComp834990Enabled: "enabled",
      isComp712501Enabled: "enabled",
      isComp600360Enabled: "enabled",
      isComp240986Enabled: "enabled",
      isComp7480Enabled: "enabled",
      isComp62063Enabled: "enabled",
      isComp165138Enabled: "enabled",
      isComp108466Enabled: "enabled",
      isComp204783Enabled: "enabled",
      isComp20874Enabled: "enabled",
      isComp615683Enabled: "enabled",
      isComp799182Enabled: "enabled",
      isComp749559Enabled: "enabled",
      isComp910913Enabled: "enabled",
      isComp446195Enabled: "enabled",
      isComp50512Enabled: "enabled",
      isComp477360Enabled: "enabled",
      isComp38410Enabled: "enabled",
      isComp736592Enabled: "enabled",
      isComp584267Enabled: "enabled",
      isComp183669Enabled: "enabled",
      isComp439367Enabled: "enabled",
      isComp66346Enabled: "enabled",
      isComp272601Enabled: "enabled",
      isComp604892Enabled: "enabled",
      isComp206874Enabled: "enabled",
      isComp294219Enabled: "enabled",
      isComp861350Enabled: "enabled",
      isComp403924Enabled: "enabled",
      isComp709588Enabled: "disabled",
      isComp760834Enabled: "disabled",
      isComp86834Enabled: "disabled",
      isComp113173Enabled: "disabled",
      isComp294909Enabled: "disabled",
      isComp722637Enabled: "disabled",
      isComp322797Enabled: "disabled",
      isComp372084Enabled: "disabled",
      isComp452596Enabled: "disabled",
      isComp608811Enabled: "disabled",
      isComp188042Enabled: "disabled",
      isComp546001Enabled: "disabled",
      isComp352264Enabled: "disabled",
      isComp431679Enabled: "disabled",
      isComp110193Enabled: "disabled",
      isComp183892Enabled: "disabled",
      isComp806951Enabled: "disabled",
      isComp963867Enabled: "disabled",
      isComp71137Enabled: "disabled",
      isComp93843Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("checkupf", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CheckupFPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("checkupf", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("checkupf", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CheckupFPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      checkupf_696401_value: this.state.CheckupData?.at?.(0)?.checkupTarihi ?? undefined,
      checkupf_965654_value: this.state.CheckupData?.at?.(0)?.boy ?? undefined,
      checkupf_425186_value: this.state.CheckupData?.at?.(0)?.agirlik ?? undefined,
      checkupf_486014_value: this.state.CheckupMeta?.at?.(0)?.test1Id ?? undefined,
      checkupf_713180_value: this.state.CheckupData?.at?.(0)?.test1Girdi1 ?? undefined,
      checkupf_965121_value: this.state.CheckupData?.at?.(0)?.test1Girdi2 ?? undefined,
      checkupf_321884_value: this.state.CheckupData?.at?.(0)?.test1Girdi3 ?? undefined,
      checkupf_209103_value: this.state.CheckupData?.at?.(0)?.test1Girdi4 ?? undefined,
      checkupf_2509_value: this.state.CheckupData?.at?.(0)?.test1AraSonuc1 ?? undefined,
      checkupf_944772_value: this.state.CheckupData?.at?.(0)?.test1AraSonuc2 ?? undefined,
      checkupf_218416_value: this.state.CheckupData?.at?.(0)?.test1AraSonuc3 ?? undefined,
      checkupf_834990_value: this.state.CheckupData?.at?.(0)?.test1Puan ?? undefined,
      checkupf_685456_value: this.state.CheckupMeta?.at?.(0)?.test2Id ?? undefined,
      checkupf_565949_value: this.state.CheckupData?.at?.(0)?.test2Girdi1 ?? undefined,
      checkupf_335804_value: this.state.CheckupData?.at?.(0)?.test2Girdi2 ?? undefined,
      checkupf_342230_value: this.state.CheckupData?.at?.(0)?.test2Girdi3 ?? undefined,
      checkupf_837990_value: this.state.CheckupData?.at?.(0)?.test2Girdi4 ?? undefined,
      checkupf_712501_value: this.state.CheckupData?.at?.(0)?.test2AraSonuc1 ?? undefined,
      checkupf_600360_value: this.state.CheckupData?.at?.(0)?.test2AraSonuc2 ?? undefined,
      checkupf_240986_value: this.state.CheckupData?.at?.(0)?.test2AraSonuc3 ?? undefined,
      checkupf_7480_value: this.state.CheckupData?.at?.(0)?.test2Puan ?? undefined,
      checkupf_295230_value: this.state.CheckupMeta?.at?.(0)?.test3Id ?? undefined,
      checkupf_142206_value: this.state.CheckupData?.at?.(0)?.test3Girdi1 ?? undefined,
      checkupf_445956_value: this.state.CheckupData?.at?.(0)?.test3Girdi2 ?? undefined,
      checkupf_392650_value: this.state.CheckupData?.at?.(0)?.test3Girdi3 ?? undefined,
      checkupf_412491_value: this.state.CheckupData?.at?.(0)?.test3Girdi4 ?? undefined,
      checkupf_62063_value: this.state.CheckupData?.at?.(0)?.test3AraSonuc1 ?? undefined,
      checkupf_165138_value: this.state.CheckupData?.at?.(0)?.test3AraSonuc2 ?? undefined,
      checkupf_108466_value: this.state.CheckupData?.at?.(0)?.test3AraSonuc3 ?? undefined,
      checkupf_204783_value: this.state.CheckupData?.at?.(0)?.test3Puan ?? undefined,
      checkupf_285289_value: this.state.CheckupMeta?.at?.(0)?.test4Id ?? undefined,
      checkupf_753080_value: this.state.CheckupData?.at?.(0)?.test4Girdi1 ?? undefined,
      checkupf_100508_value: this.state.CheckupData?.at?.(0)?.test4Girdi2 ?? undefined,
      checkupf_890285_value: this.state.CheckupData?.at?.(0)?.test4Girdi3 ?? undefined,
      checkupf_632517_value: this.state.CheckupData?.at?.(0)?.test4Girdi4 ?? undefined,
      checkupf_20874_value: this.state.CheckupData?.at?.(0)?.test4AraSonuc1 ?? undefined,
      checkupf_615683_value: this.state.CheckupData?.at?.(0)?.test4AraSonuc2 ?? undefined,
      checkupf_799182_value: this.state.CheckupData?.at?.(0)?.test4AraSonuc3 ?? undefined,
      checkupf_749559_value: this.state.CheckupData?.at?.(0)?.test4Puan ?? undefined,
      checkupf_818509_value: this.state.CheckupMeta?.at?.(0)?.test5Id ?? undefined,
      checkupf_91031_value: this.state.CheckupData?.at?.(0)?.test5Girdi1 ?? undefined,
      checkupf_286377_value: this.state.CheckupData?.at?.(0)?.test5Girdi2 ?? undefined,
      checkupf_840595_value: this.state.CheckupData?.at?.(0)?.test5Girdi3 ?? undefined,
      checkupf_785891_value: this.state.CheckupData?.at?.(0)?.test5Girdi4 ?? undefined,
      checkupf_910913_value: this.state.CheckupData?.at?.(0)?.test5AraSonuc1 ?? undefined,
      checkupf_446195_value: this.state.CheckupData?.at?.(0)?.test5AraSonuc2 ?? undefined,
      checkupf_50512_value: this.state.CheckupData?.at?.(0)?.test5AraSonuc3 ?? undefined,
      checkupf_477360_value: this.state.CheckupData?.at?.(0)?.test5Puan ?? undefined,
      checkupf_414768_value: this.state.CheckupMeta?.at?.(0)?.test6Id ?? undefined,
      checkupf_470497_value: this.state.CheckupData?.at?.(0)?.test6Girdi1 ?? undefined,
      checkupf_233533_value: this.state.CheckupData?.at?.(0)?.test6Girdi2 ?? undefined,
      checkupf_444205_value: this.state.CheckupData?.at?.(0)?.test6Girdi3 ?? undefined,
      checkupf_69705_value: this.state.CheckupData?.at?.(0)?.test6Girdi4 ?? undefined,
      checkupf_38410_value: this.state.CheckupData?.at?.(0)?.test6AraSonuc1 ?? undefined,
      checkupf_736592_value: this.state.CheckupData?.at?.(0)?.test6AraSonuc2 ?? undefined,
      checkupf_584267_value: this.state.CheckupData?.at?.(0)?.test6AraSonuc3 ?? undefined,
      checkupf_183669_value: this.state.CheckupData?.at?.(0)?.test6Puan ?? undefined,
      checkupf_398094_value: this.state.CheckupMeta?.at?.(0)?.test7Id ?? undefined,
      checkupf_158743_value: this.state.CheckupData?.at?.(0)?.test7Girdi1 ?? undefined,
      checkupf_128276_value: this.state.CheckupData?.at?.(0)?.test7Girdi2 ?? undefined,
      checkupf_391944_value: this.state.CheckupData?.at?.(0)?.test7Girdi3 ?? undefined,
      checkupf_347331_value: this.state.CheckupData?.at?.(0)?.test7Girdi4 ?? undefined,
      checkupf_439367_value: this.state.CheckupData?.at?.(0)?.test7AraSonuc1 ?? undefined,
      checkupf_66346_value: this.state.CheckupData?.at?.(0)?.test7AraSonuc2 ?? undefined,
      checkupf_272601_value: this.state.CheckupData?.at?.(0)?.test7AraSonuc3 ?? undefined,
      checkupf_604892_value: this.state.CheckupData?.at?.(0)?.test7Puan ?? undefined,
      checkupf_63093_value: this.state.CheckupMeta?.at?.(0)?.test8Id ?? undefined,
      checkupf_727646_value: this.state.CheckupData?.at?.(0)?.test8Girdi1 ?? undefined,
      checkupf_275943_value: this.state.CheckupData?.at?.(0)?.test8Girdi2 ?? undefined,
      checkupf_993404_value: this.state.CheckupData?.at?.(0)?.test8Girdi3 ?? undefined,
      checkupf_999696_value: this.state.CheckupData?.at?.(0)?.test8Girdi4 ?? undefined,
      checkupf_206874_value: this.state.CheckupData?.at?.(0)?.test8AraSonuc1 ?? undefined,
      checkupf_294219_value: this.state.CheckupData?.at?.(0)?.test8AraSonuc2 ?? undefined,
      checkupf_861350_value: this.state.CheckupData?.at?.(0)?.test8AraSonuc3 ?? undefined,
      checkupf_403924_value: this.state.CheckupData?.at?.(0)?.test8Puan ?? undefined,
      checkupf_625584_value: this.state.CheckupMeta?.at?.(0)?.test9Id ?? undefined,
      checkupf_60537_value: this.state.CheckupData?.at?.(0)?.test9Girdi1 ?? undefined,
      checkupf_629130_value: this.state.CheckupData?.at?.(0)?.test9Girdi2 ?? undefined,
      checkupf_667315_value: this.state.CheckupData?.at?.(0)?.test9Girdi3 ?? undefined,
      checkupf_766861_value: this.state.CheckupData?.at?.(0)?.test9Girdi4 ?? undefined,
      checkupf_709588_value: this.state.CheckupData?.at?.(0)?.test9AraSonuc1 ?? undefined,
      checkupf_760834_value: this.state.CheckupData?.at?.(0)?.test9AraSonuc2 ?? undefined,
      checkupf_86834_value: this.state.CheckupData?.at?.(0)?.test9AraSonuc3 ?? undefined,
      checkupf_113173_value: this.state.CheckupData?.at?.(-1)?.test9Puan ?? undefined,
      checkupf_915251_value: this.state.CheckupMeta?.at?.(0)?.test10Id ?? undefined,
      checkupf_943417_value: this.state.CheckupData?.at?.(0)?.test10Girdi1 ?? undefined,
      checkupf_36477_value: this.state.CheckupData?.at?.(0)?.test10Girdi2 ?? undefined,
      checkupf_520087_value: this.state.CheckupData?.at?.(0)?.test10Girdi3 ?? undefined,
      checkupf_309047_value: this.state.CheckupData?.at?.(0)?.test10Girdi4 ?? undefined,
      checkupf_294909_value: this.state.CheckupData?.at?.(0)?.test10AraSonuc1 ?? undefined,
      checkupf_722637_value: this.state.CheckupData?.at?.(0)?.test10AraSonuc2 ?? undefined,
      checkupf_322797_value: this.state.CheckupData?.at?.(0)?.test10AraSonuc3 ?? undefined,
      checkupf_372084_value: this.state.CheckupData?.at?.(0)?.test10Puan ?? undefined,
      checkupf_250633_value: this.state.CheckupMeta?.at?.(0)?.test11Id ?? undefined,
      checkupf_747579_value: this.state.CheckupData?.at?.(0)?.test11Girdi1 ?? undefined,
      checkupf_188524_value: this.state.CheckupData?.at?.(0)?.test11Girdi2 ?? undefined,
      checkupf_554524_value: this.state.CheckupData?.at?.(0)?.test11Girdi3 ?? undefined,
      checkupf_667827_value: this.state.CheckupData?.at?.(0)?.test11Girdi4 ?? undefined,
      checkupf_452596_value: this.state.CheckupData?.at?.(0)?.test11AraSonuc1 ?? undefined,
      checkupf_608811_value: this.state.CheckupData?.at?.(0)?.test11AraSonuc2 ?? undefined,
      checkupf_188042_value: this.state.CheckupData?.at?.(0)?.test11AraSonuc3 ?? undefined,
      checkupf_546001_value: this.state.CheckupData?.at?.(0)?.test11Puan ?? undefined,
      checkupf_711326_value: this.state.CheckupMeta?.at?.(0)?.test12Id ?? undefined,
      checkupf_601275_value: this.state.CheckupData?.at?.(0)?.test12Girdi1 ?? undefined,
      checkupf_315757_value: this.state.CheckupData?.at?.(0)?.test12Girdi2 ?? undefined,
      checkupf_560951_value: this.state.CheckupData?.at?.(0)?.test12Girdi3 ?? undefined,
      checkupf_117115_value: this.state.CheckupData?.at?.(0)?.test12Girdi4 ?? undefined,
      checkupf_352264_value: this.state.CheckupData?.at?.(0)?.test12AraSonuc1 ?? undefined,
      checkupf_431679_value: this.state.CheckupData?.at?.(0)?.test12AraSonuc2 ?? undefined,
      checkupf_110193_value: this.state.CheckupData?.at?.(0)?.test12AraSonuc3 ?? undefined,
      checkupf_183892_value: this.state.CheckupData?.at?.(0)?.test12Puan ?? undefined,
      checkupf_543052_value: this.state.CheckupMeta?.at?.(0)?.test13Id ?? undefined,
      checkupf_120090_value: this.state.CheckupData?.at?.(0)?.test13Girdi1 ?? undefined,
      checkupf_793476_value: this.state.CheckupData?.at?.(0)?.test13Girdi2 ?? undefined,
      checkupf_82332_value: this.state.CheckupData?.at?.(0)?.test13Girdi3 ?? undefined,
      checkupf_764695_value: this.state.CheckupData?.at?.(0)?.test13Girdi4 ?? undefined,
      checkupf_806951_value: this.state.CheckupData?.at?.(0)?.test13AraSonuc1 ?? undefined,
      checkupf_963867_value: this.state.CheckupData?.at?.(0)?.test13AraSonuc2 ?? undefined,
      checkupf_71137_value: this.state.CheckupData?.at?.(0)?.test13AraSonuc3 ?? undefined,
      checkupf_93843_value: this.state.CheckupData?.at?.(0)?.test13Puan ?? undefined,
      checkupf_702183_value: this.state.CheckupData?.at?.(0)?.egitmeninNotu ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  CheckupFPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      checkupId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      protokolId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      kisiId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_17: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiID_17: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.CheckupData = result?.data.checkupData;
    formVars.checkupf_696401_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].checkupTarihi
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].checkupTarihi
        : null
    );
    formVars.checkupf_965654_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].boy
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].boy
        : null
    );
    formVars.checkupf_425186_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].agirlik
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].agirlik
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_2509_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1AraSonuc1
        : null
    );
    formVars.checkupf_944772_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1AraSonuc2
        : null
    );
    formVars.checkupf_218416_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1AraSonuc3
        : null
    );
    formVars.checkupf_921858_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Puan
        : null
    );
    formVars.checkupf_834990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Puan
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_712501_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2AraSonuc1
        : null
    );
    formVars.checkupf_600360_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2AraSonuc2
        : null
    );
    formVars.checkupf_240986_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2AraSonuc3
        : null
    );
    formVars.checkupf_145787_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Puan
        : null
    );
    formVars.checkupf_7480_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Puan
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_62063_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3AraSonuc1
        : null
    );
    formVars.checkupf_165138_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3AraSonuc2
        : null
    );
    formVars.checkupf_108466_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3AraSonuc3
        : null
    );
    formVars.checkupf_87115_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Puan
        : null
    );
    formVars.checkupf_204783_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Puan
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_20874_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4AraSonuc1
        : null
    );
    formVars.checkupf_615683_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4AraSonuc2
        : null
    );
    formVars.checkupf_799182_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4AraSonuc3
        : null
    );
    formVars.checkupf_169067_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Puan
        : null
    );
    formVars.checkupf_749559_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Puan
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_910913_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5AraSonuc1
        : null
    );
    formVars.checkupf_446195_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5AraSonuc2
        : null
    );
    formVars.checkupf_50512_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5AraSonuc3
        : null
    );
    formVars.checkupf_684957_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Puan
        : null
    );
    formVars.checkupf_477360_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Puan
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_38410_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6AraSonuc1
        : null
    );
    formVars.checkupf_736592_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6AraSonuc2
        : null
    );
    formVars.checkupf_584267_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6AraSonuc3
        : null
    );
    formVars.checkupf_382833_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Puan
        : null
    );
    formVars.checkupf_183669_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Puan
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_439367_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7AraSonuc1
        : null
    );
    formVars.checkupf_66346_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7AraSonuc2
        : null
    );
    formVars.checkupf_272601_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7AraSonuc3
        : null
    );
    formVars.checkupf_876336_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Puan
        : null
    );
    formVars.checkupf_604892_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Puan
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_206874_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8AraSonuc1
        : null
    );
    formVars.checkupf_294219_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8AraSonuc2
        : null
    );
    formVars.checkupf_861350_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8AraSonuc3
        : null
    );
    formVars.checkupf_843594_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Puan
        : null
    );
    formVars.checkupf_403924_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Puan
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_709588_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9AraSonuc1
        : null
    );
    formVars.checkupf_760834_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9AraSonuc2
        : null
    );
    formVars.checkupf_86834_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9AraSonuc3
        : null
    );
    formVars.checkupf_520465_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Puan
        : null
    );
    formVars.checkupf_113173_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[stateVars.CheckupData.length - 1].test9Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[this.state.CheckupData.length - 1].test9Puan
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_294909_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10AraSonuc1
        : null
    );
    formVars.checkupf_722637_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10AraSonuc2
        : null
    );
    formVars.checkupf_322797_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10AraSonuc3
        : null
    );
    formVars.checkupf_627020_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Puan
        : null
    );
    formVars.checkupf_372084_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Puan
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_452596_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11AraSonuc1
        : null
    );
    formVars.checkupf_608811_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11AraSonuc2
        : null
    );
    formVars.checkupf_188042_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11AraSonuc3
        : null
    );
    formVars.checkupf_82596_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Puan
        : null
    );
    formVars.checkupf_546001_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Puan
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_352264_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12AraSonuc1
        : null
    );
    formVars.checkupf_431679_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12AraSonuc2
        : null
    );
    formVars.checkupf_110193_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12AraSonuc3
        : null
    );
    formVars.checkupf_821639_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Puan
        : null
    );
    formVars.checkupf_183892_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Puan
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_806951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13AraSonuc1
        : null
    );
    formVars.checkupf_963867_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13AraSonuc2
        : null
    );
    formVars.checkupf_71137_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13AraSonuc3
        : null
    );
    formVars.checkupf_769023_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Puan
        : null
    );
    formVars.checkupf_93843_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Puan
        : null
    );
    formVars.checkupf_702183_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].egitmeninNotu
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].egitmeninNotu
        : null
    );
    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_486014_options = stateVars.AltBilesen1Testleri;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_685456_options = stateVars.AltBilesen2Testleri;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_295230_options = stateVars.AltBilesen3Testleri;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_285289_options = stateVars.AltBilesen4Testleri;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_818509_options = stateVars.AltBilesen5Testleri;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_414768_options = stateVars.AltBilesen6Testleri;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_398094_options = stateVars.AltBilesen7Testleri;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_63093_options = stateVars.AltBilesen8Testleri;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_625584_options = stateVars.AltBilesen9Testleri;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_915251_options = stateVars.AltBilesen10Testleri;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_250633_options = stateVars.AltBilesen11Testleri;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_711326_options = stateVars.AltBilesen12Testleri;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_543052_options = stateVars.AltBilesen13Testleri;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KisiSelectById = result?.data.kisiSelectById;
    formVars.checkupf_346086_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );
    formVars.checkupf_187595_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );
    formVars.checkupf_815300_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].yas
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].yas
        : null
    );
    formVars.checkupf_101238_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );
    formVars.checkupf_166150_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );
    stateVars.isComp543454Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "E"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp699912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "K"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.AltBilesen1Testleri = result?.data.altBilesen1Testleri;

    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_486014_options = stateVars.AltBilesen1Testleri;
    stateVars.AltBilesen2Testleri = result?.data.altBilesen2Testleri;

    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_685456_options = stateVars.AltBilesen2Testleri;
    stateVars.AltBilesen3Testleri = result?.data.altBilesen3Testleri;

    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_295230_options = stateVars.AltBilesen3Testleri;
    stateVars.AltBilesen4Testleri = result?.data.altBilesen4Testleri;

    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_285289_options = stateVars.AltBilesen4Testleri;
    stateVars.AltBilesen5Testleri = result?.data.altBilesen5Testleri;

    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_818509_options = stateVars.AltBilesen5Testleri;
    stateVars.AltBilesen6Testleri = result?.data.altBilesen6Testleri;

    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_414768_options = stateVars.AltBilesen6Testleri;
    stateVars.AltBilesen7Testleri = result?.data.altBilesen7Testleri;

    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_398094_options = stateVars.AltBilesen7Testleri;
    stateVars.AltBilesen8Testleri = result?.data.altBilesen8Testleri;

    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_63093_options = stateVars.AltBilesen8Testleri;
    stateVars.AltBilesen9Testleri = result?.data.altBilesen9Testleri;

    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_625584_options = stateVars.AltBilesen9Testleri;
    stateVars.AltBilesen10Testleri = result?.data.altBilesen10Testleri;

    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_915251_options = stateVars.AltBilesen10Testleri;
    stateVars.AltBilesen11Testleri = result?.data.altBilesen11Testleri;

    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_250633_options = stateVars.AltBilesen11Testleri;
    stateVars.AltBilesen12Testleri = result?.data.altBilesen12Testleri;

    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_711326_options = stateVars.AltBilesen12Testleri;
    stateVars.AltBilesen13Testleri = result?.data.altBilesen13Testleri;

    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_543052_options = stateVars.AltBilesen13Testleri;
    stateVars.ProtokolunHizmetleri = result?.data.protokolunHizmetleri;

    formVars.checkupf_178016_options = stateVars.ProtokolunHizmetleri;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp2509Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp944772Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp218416Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp834990Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp712501Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp600360Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp240986Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp7480Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp62063Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp165138Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp108466Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp204783Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp20874Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp615683Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp799182Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp749559Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp910913Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp446195Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp50512Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp477360Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp38410Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp736592Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp584267Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp183669Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp439367Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp66346Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp272601Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp604892Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp206874Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp294219Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp861350Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp403924Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CheckupFPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isEarlierOrEqual(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        moment().format("YYYY-MM-DDTHH:mm:ss")
      ),
      message: "İleri bi tarih seçilemez!",
      formName: "checkupf_696401_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_696401_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        "string"
      ),
      prm2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "string"
      ),
      prm3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DummySave = result?.data.dummySave;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isEarlierOrEqual(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        moment().format("YYYY-MM-DDTHH:mm:ss")
      ),
      message: "İleri bi tarih seçilemez!",
      formName: "checkupf_696401_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_696401_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      dogumtarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].dogumTarihi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].dogumTarihi
            : null
        ),
        "Date"
      ),
      checkuptarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupData?.length > 0
            ? stateVars.CheckupData[0].checkupTarihi
            : this.state.CheckupData?.length > 0
            ? this.state.CheckupData[0].checkupTarihi
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFPageInit3_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CheckUpFYasHesapla = result?.data.checkUpFYasHesapla;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFPageInit4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFPageInit4_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BaseUrl = result?.data.baseUrl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFPageInit5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    stateVars.isComp543454Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "E"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp699912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "K"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_346086_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );

    formVars.checkupf_187595_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );

    formVars.checkupf_815300_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].yas
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].yas
        : null
    );

    formVars.checkupf_101238_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );

    formVars.checkupf_166150_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );

    formVars.checkupf_696401_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].checkupTarihi
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].checkupTarihi
        : null
    );

    formVars.checkupf_965654_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].boy
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].boy
        : null
    );

    formVars.checkupf_425186_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].agirlik
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].agirlik
        : null
    );

    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    stateVars.dataSource_486014 = this.state.AltBilesen1Testleri;
    stateVars.dataSource_486014 = this.state.AltBilesen1Testleri;
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_2509_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1AraSonuc1
        : null
    );

    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_944772_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1AraSonuc2
        : null
    );

    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_218416_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1AraSonuc3
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_921858_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Puan
        : null
    );

    formVars.checkupf_834990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Puan
        : null
    );

    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    stateVars.dataSource_685456 = this.state.AltBilesen2Testleri;
    stateVars.dataSource_685456 = this.state.AltBilesen2Testleri;
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_712501_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2AraSonuc1
        : null
    );

    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_600360_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2AraSonuc2
        : null
    );

    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_240986_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2AraSonuc3
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_145787_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Puan
        : null
    );

    formVars.checkupf_7480_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Puan
        : null
    );

    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    stateVars.dataSource_295230 = this.state.AltBilesen3Testleri;
    stateVars.dataSource_295230 = this.state.AltBilesen3Testleri;
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_62063_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3AraSonuc1
        : null
    );

    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_165138_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3AraSonuc2
        : null
    );

    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_108466_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3AraSonuc3
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_87115_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Puan
        : null
    );

    formVars.checkupf_204783_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Puan
        : null
    );

    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    stateVars.dataSource_285289 = this.state.AltBilesen4Testleri;
    stateVars.dataSource_285289 = this.state.AltBilesen4Testleri;
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_20874_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4AraSonuc1
        : null
    );

    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_615683_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4AraSonuc2
        : null
    );

    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_799182_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4AraSonuc3
        : null
    );

    formVars.checkupf_169067_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Puan
        : null
    );

    formVars.checkupf_749559_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Puan
        : null
    );

    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    stateVars.dataSource_818509 = this.state.AltBilesen5Testleri;
    stateVars.dataSource_818509 = this.state.AltBilesen5Testleri;
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_910913_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5AraSonuc1
        : null
    );

    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_446195_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5AraSonuc2
        : null
    );

    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_50512_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5AraSonuc3
        : null
    );

    formVars.checkupf_684957_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Puan
        : null
    );

    formVars.checkupf_477360_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Puan
        : null
    );

    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    stateVars.dataSource_414768 = this.state.AltBilesen6Testleri;
    stateVars.dataSource_414768 = this.state.AltBilesen6Testleri;
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_38410_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6AraSonuc1
        : null
    );

    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_736592_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6AraSonuc2
        : null
    );

    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_584267_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6AraSonuc3
        : null
    );

    formVars.checkupf_382833_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Puan
        : null
    );

    formVars.checkupf_183669_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Puan
        : null
    );

    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    stateVars.dataSource_398094 = this.state.AltBilesen7Testleri;
    stateVars.dataSource_398094 = this.state.AltBilesen7Testleri;
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_439367_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7AraSonuc1
        : null
    );

    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_66346_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7AraSonuc2
        : null
    );

    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_272601_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7AraSonuc3
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_876336_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Puan
        : null
    );

    formVars.checkupf_604892_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Puan
        : null
    );

    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    stateVars.dataSource_63093 = this.state.AltBilesen8Testleri;
    stateVars.dataSource_63093 = this.state.AltBilesen8Testleri;
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_206874_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8AraSonuc1
        : null
    );

    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_294219_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8AraSonuc2
        : null
    );

    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_861350_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8AraSonuc3
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_843594_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Puan
        : null
    );

    formVars.checkupf_403924_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Puan
        : null
    );

    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    stateVars.dataSource_625584 = this.state.AltBilesen9Testleri;
    stateVars.dataSource_625584 = this.state.AltBilesen9Testleri;
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_709588_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9AraSonuc1
        : null
    );

    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_760834_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9AraSonuc2
        : null
    );

    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_86834_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9AraSonuc3
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_520465_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Puan
        : null
    );

    formVars.checkupf_113173_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[stateVars.CheckupData.length - 1].test9Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[this.state.CheckupData.length - 1].test9Puan
        : null
    );

    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    stateVars.dataSource_915251 = this.state.AltBilesen10Testleri;
    stateVars.dataSource_915251 = this.state.AltBilesen10Testleri;
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_294909_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10AraSonuc1
        : null
    );

    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_722637_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10AraSonuc2
        : null
    );

    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_322797_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10AraSonuc3
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_627020_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Puan
        : null
    );

    formVars.checkupf_372084_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Puan
        : null
    );

    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    stateVars.dataSource_250633 = this.state.AltBilesen11Testleri;
    stateVars.dataSource_250633 = this.state.AltBilesen11Testleri;
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_452596_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11AraSonuc1
        : null
    );

    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_608811_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11AraSonuc2
        : null
    );

    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_188042_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11AraSonuc3
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_82596_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Puan
        : null
    );

    formVars.checkupf_546001_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Puan
        : null
    );

    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    stateVars.dataSource_711326 = this.state.AltBilesen12Testleri;
    stateVars.dataSource_711326 = this.state.AltBilesen12Testleri;
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_352264_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12AraSonuc1
        : null
    );

    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_431679_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12AraSonuc2
        : null
    );

    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_110193_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12AraSonuc3
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_821639_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Puan
        : null
    );

    formVars.checkupf_183892_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Puan
        : null
    );

    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    stateVars.dataSource_543052 = this.state.AltBilesen13Testleri;
    stateVars.dataSource_543052 = this.state.AltBilesen13Testleri;
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_806951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13AraSonuc1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13AraSonuc1
        : null
    );

    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_963867_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13AraSonuc2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13AraSonuc2
        : null
    );

    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_71137_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13AraSonuc3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13AraSonuc3
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupf_769023_percent = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Puan
        : null
    );

    formVars.checkupf_93843_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Puan
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Puan
        : null
    );

    formVars.checkupf_702183_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].egitmeninNotu
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].egitmeninNotu
        : null
    );

    stateVars.dataSource_178016 = this.state.ProtokolunHizmetleri;
    stateVars.dataSource_178016 = this.state.ProtokolunHizmetleri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CheckupFComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_696401_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isEarlierOrEqual(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        moment().format("YYYY-MM-DDTHH:mm:ss")
      ),
      message: "İleri bi tarih seçilemez!",
      formName: "checkupf_696401_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_696401_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        "string"
      ),
      prm2_0: ReactSystemFunctions.convertToTypeByName(" ", "string"),
      prm3_0: ReactSystemFunctions.convertToTypeByName(" ", "string"),
      dogumtarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].dogumTarihi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].dogumTarihi
            : null
        ),
        "Date"
      ),
      checkuptarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_696401_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DummySave = result?.data.dummySave;
    stateVars.CheckUpFYasHesapla = result?.data.checkUpFYasHesapla;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  CheckupFComponent_965654_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_241697_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_425186_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_241697_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_486014_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_486014_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_486014_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_486014_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_944490_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test1YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test1YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_713180_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_713180_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_713180_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest1Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest1Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965121_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965121_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest1Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest1Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_321884_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_321884_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest1Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest1Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_209103_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_209103_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest1Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest1Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen1Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen1Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_713180_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965121_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_321884_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_209103_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_713180_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_713180_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_713180_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_2509_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_944772_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_218416_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_834990_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_921858_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_965121_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_713180_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_321884_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_713180_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_209103_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_713180_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_685456_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_685456_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_685456_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_685456_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_333825_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test2YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test2YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_565949_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_565949_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_565949_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest2Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest2Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_335804_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_335804_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest2Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest2Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_342230_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_342230_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest2Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest2Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_837990_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_837990_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest2Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest2Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen2Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen2Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_565949_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_335804_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_342230_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_837990_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_565949_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_565949_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_565949_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_712501_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_600360_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_240986_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_7480_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_145787_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_335804_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_565949_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_342230_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_565949_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_837990_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_565949_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_295230_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_295230_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_295230_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_295230_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_459319_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test3YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test3YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_142206_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_142206_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_142206_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest3Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest3Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_445956_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_445956_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest3Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest3Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_392650_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_392650_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest3Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest3Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_412491_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_412491_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest3Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest3Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen3Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen3Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_142206_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_445956_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_392650_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_412491_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_142206_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_142206_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_142206_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_62063_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_165138_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_108466_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_204783_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_87115_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_445956_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_142206_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_392650_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_142206_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_412491_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_142206_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_285289_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_285289_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_285289_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_285289_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_775669_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test4YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test4YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_753080_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_753080_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_753080_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest4Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest4Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_100508_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_100508_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest4Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest4Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_890285_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_890285_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest4Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest4Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_632517_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_632517_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest4Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest4Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen4Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen4Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_753080_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_100508_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_890285_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_632517_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_753080_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_753080_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_753080_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_20874_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_615683_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_799182_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_749559_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_169067_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_100508_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_753080_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_890285_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_753080_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_632517_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_753080_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_818509_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_818509_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_818509_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_818509_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_551320_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test5YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test5YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_91031_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_91031_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "checkupf_91031_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest5Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest5Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_286377_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_286377_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest5Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest5Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_840595_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_840595_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest5Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest5Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_785891_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_785891_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest5Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest5Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen5Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen5Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_91031_value", "value", "", "", "")),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_286377_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_840595_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_785891_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_91031_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_91031_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_91031_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_910913_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_446195_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_50512_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_477360_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_684957_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_286377_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_91031_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_840595_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_91031_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_785891_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_91031_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_414768_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_414768_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_414768_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_414768_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_431891_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test6YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test6YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_470497_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_470497_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_470497_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest6Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest6Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_233533_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_233533_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest6Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest6Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_444205_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_444205_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest6Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest6Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_69705_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "checkupf_69705_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest6Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest6Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen6Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen6Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_470497_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_233533_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_444205_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_69705_value", "value", "", "", "")),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_470497_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_470497_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_470497_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_38410_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_736592_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_584267_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_183669_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_382833_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_233533_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_470497_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_444205_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_470497_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_69705_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_470497_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_398094_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_398094_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_398094_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_398094_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_196189_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test7YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test7YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_158743_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_158743_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_158743_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest7Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest7Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_128276_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_128276_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest7Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest7Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_391944_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_391944_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest7Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest7Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_347331_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_347331_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest7Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest7Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen7Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen7Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_158743_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_128276_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_391944_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_347331_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_158743_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_158743_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_158743_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_439367_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_66346_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_272601_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_604892_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_876336_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_128276_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_158743_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_391944_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_158743_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_347331_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_158743_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_63093_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_63093_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_63093_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_63093_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_997083_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test8YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test8YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_727646_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_727646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_727646_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest8Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest8Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_275943_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_275943_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest8Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest8Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_993404_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_993404_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest8Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest8Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_999696_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_999696_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest8Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest8Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen8Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen8Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_727646_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_275943_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_993404_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_999696_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_727646_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_727646_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_727646_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_206874_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_294219_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_861350_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_403924_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_843594_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_275943_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_727646_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_993404_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_727646_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_999696_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_727646_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_625584_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_625584_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_625584_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_625584_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_995517_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test9YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test9YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_60537_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_60537_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "checkupf_60537_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest9Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest9Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_629130_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_629130_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest9Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest9Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_667315_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_667315_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest9Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest9Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_766861_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_766861_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest9Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest9Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen9Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen9Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_60537_value", "value", "", "", "")),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_629130_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_667315_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_766861_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_60537_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_60537_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_60537_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_709588_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_760834_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_86834_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[stateVars.TestExecute.length - 1].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[this.state.TestExecute.length - 1].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_113173_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_520465_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_629130_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_60537_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_667315_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_60537_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_766861_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_60537_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_915251_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_915251_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_915251_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_915251_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_210104_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test10YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test10YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_943417_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_943417_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_943417_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest10Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest10Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_36477_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "checkupf_36477_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest10Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest10Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_520087_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_520087_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest10Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest10Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_309047_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_309047_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest10Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest10Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen10Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen10Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_943417_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_36477_value", "value", "", "", "")),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_520087_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_309047_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_943417_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_943417_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_943417_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_294909_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_722637_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_322797_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_372084_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_627020_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_36477_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_943417_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_520087_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_943417_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_309047_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_943417_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_250633_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_250633_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_250633_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_250633_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_624590_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test11YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test11YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_747579_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_747579_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_747579_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest11Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest11Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_188524_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_188524_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest11Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest11Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_554524_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_554524_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest11Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest11Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_999696_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_667827_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest11Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest11Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen11Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen11Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_747579_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_188524_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_554524_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_667827_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_747579_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_747579_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_747579_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_452596_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_608811_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_188042_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_546001_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_82596_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_188524_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_747579_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_554524_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_747579_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_667827_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_747579_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_711326_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_711326_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_711326_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_711326_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_891525_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test12YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test12YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_601275_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_601275_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_601275_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest12Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest12Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_315757_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_315757_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest12Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest12Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_560951_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_560951_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest12Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest12Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_117115_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_117115_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest12Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest12Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen12Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen12Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_601275_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_315757_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_560951_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_117115_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_601275_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_601275_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_601275_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_352264_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_431679_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_110193_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_183892_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_821639_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_315757_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_601275_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_560951_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_601275_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_117115_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_601275_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_543052_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      test1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_486014_value", "value", "AltBilesen1Testleri", "id", "id")
        ),
        "Guid"
      ),
      test2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_685456_value", "value", "AltBilesen2Testleri", "id", "id")
        ),
        "Guid"
      ),
      test3Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_295230_value", "value", "AltBilesen3Testleri", "id", "id")
        ),
        "Guid"
      ),
      test4Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_285289_value", "value", "AltBilesen4Testleri", "id", "id")
        ),
        "Guid"
      ),
      test5Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_818509_value", "value", "AltBilesen5Testleri", "id", "id")
        ),
        "Guid"
      ),
      test6Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_414768_value", "value", "AltBilesen6Testleri", "id", "id")
        ),
        "Guid"
      ),
      test7Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_398094_value", "value", "AltBilesen7Testleri", "id", "id")
        ),
        "Guid"
      ),
      test8Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_63093_value", "value", "AltBilesen8Testleri", "id", "id")
        ),
        "Guid"
      ),
      test9Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_625584_value", "value", "AltBilesen9Testleri", "id", "id")
        ),
        "Guid"
      ),
      test10Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_915251_value", "value", "AltBilesen10Testleri", "id", "id")
        ),
        "Guid"
      ),
      test11Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_250633_value", "value", "AltBilesen11Testleri", "id", "id")
        ),
        "Guid"
      ),
      test12Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_711326_value", "value", "AltBilesen12Testleri", "id", "id")
        ),
        "Guid"
      ),
      test13Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_543052_value", "value", "AltBilesen13Testleri", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_543052_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupMeta = result?.data.checkupMeta;
    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );
    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );
    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );
    formVars.checkupf_486014_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null;
    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );
    formVars.checkupf_713180_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi1
        : null
    );
    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );
    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );
    formVars.checkupf_965121_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi2
        : null
    );
    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );
    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );
    formVars.checkupf_321884_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi3
        : null
    );
    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );
    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_209103_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test1Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test1Girdi4
        : null
    );
    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );
    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );
    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );
    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );
    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );
    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );
    formVars.checkupf_685456_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null;
    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );
    formVars.checkupf_565949_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi1
        : null
    );
    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );
    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );
    formVars.checkupf_335804_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi2
        : null
    );
    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );
    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );
    formVars.checkupf_342230_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi3
        : null
    );
    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );
    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );
    formVars.checkupf_837990_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test2Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test2Girdi4
        : null
    );
    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );
    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );
    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );
    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );
    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );
    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );
    formVars.checkupf_295230_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null;
    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );
    formVars.checkupf_142206_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi1
        : null
    );
    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );
    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );
    formVars.checkupf_445956_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi2
        : null
    );
    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );
    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );
    formVars.checkupf_392650_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi3
        : null
    );
    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );
    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );
    formVars.checkupf_412491_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test3Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test3Girdi4
        : null
    );
    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );
    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );
    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );
    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );
    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );
    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );
    formVars.checkupf_285289_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null;
    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );
    formVars.checkupf_753080_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi1
        : null
    );
    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );
    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );
    formVars.checkupf_100508_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi2
        : null
    );
    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );
    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );
    formVars.checkupf_890285_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi3
        : null
    );
    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );
    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );
    formVars.checkupf_632517_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test4Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test4Girdi4
        : null
    );
    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );
    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );
    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );
    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );
    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );
    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );
    formVars.checkupf_818509_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null;
    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );
    formVars.checkupf_91031_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi1
        : null
    );
    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );
    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );
    formVars.checkupf_286377_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi2
        : null
    );
    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );
    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );
    formVars.checkupf_840595_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi3
        : null
    );
    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );
    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );
    formVars.checkupf_785891_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test5Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test5Girdi4
        : null
    );
    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );
    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );
    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );
    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );
    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );
    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );
    formVars.checkupf_414768_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null;
    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );
    formVars.checkupf_470497_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi1
        : null
    );
    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );
    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );
    formVars.checkupf_233533_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi2
        : null
    );
    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );
    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );
    formVars.checkupf_444205_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi3
        : null
    );
    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );
    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );
    formVars.checkupf_69705_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test6Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test6Girdi4
        : null
    );
    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );
    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );
    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );
    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );
    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );
    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );
    formVars.checkupf_398094_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null;
    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );
    formVars.checkupf_158743_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi1
        : null
    );
    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );
    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );
    formVars.checkupf_128276_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi2
        : null
    );
    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );
    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );
    formVars.checkupf_391944_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi3
        : null
    );
    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );
    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );
    formVars.checkupf_347331_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test7Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test7Girdi4
        : null
    );
    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );
    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );
    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );
    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );
    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );
    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );
    formVars.checkupf_63093_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null;
    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );
    formVars.checkupf_727646_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi1
        : null
    );
    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );
    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );
    formVars.checkupf_275943_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi2
        : null
    );
    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );
    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );
    formVars.checkupf_993404_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi3
        : null
    );
    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );
    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );
    formVars.checkupf_999696_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test8Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test8Girdi4
        : null
    );
    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );
    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );
    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );
    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );
    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );
    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );
    formVars.checkupf_625584_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null;
    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );
    formVars.checkupf_60537_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi1
        : null
    );
    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );
    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );
    formVars.checkupf_629130_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi2
        : null
    );
    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );
    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );
    formVars.checkupf_667315_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi3
        : null
    );
    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );
    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );
    formVars.checkupf_766861_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test9Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test9Girdi4
        : null
    );
    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );
    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );
    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );
    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );
    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );
    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );
    formVars.checkupf_915251_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null;
    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );
    formVars.checkupf_943417_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi1
        : null
    );
    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );
    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );
    formVars.checkupf_36477_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi2
        : null
    );
    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );
    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );
    formVars.checkupf_520087_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi3
        : null
    );
    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );
    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );
    formVars.checkupf_309047_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test10Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test10Girdi4
        : null
    );
    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );
    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );
    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );
    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );
    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );
    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );
    formVars.checkupf_250633_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null;
    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );
    formVars.checkupf_747579_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi1
        : null
    );
    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );
    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );
    formVars.checkupf_188524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi2
        : null
    );
    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );
    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );
    formVars.checkupf_554524_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi3
        : null
    );
    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );
    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );
    formVars.checkupf_667827_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test11Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test11Girdi4
        : null
    );
    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );
    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );
    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );
    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );
    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );
    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );
    formVars.checkupf_711326_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null;
    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );
    formVars.checkupf_601275_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi1
        : null
    );
    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );
    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );
    formVars.checkupf_315757_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi2
        : null
    );
    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );
    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );
    formVars.checkupf_560951_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi3
        : null
    );
    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );
    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );
    formVars.checkupf_117115_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test12Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test12Girdi4
        : null
    );
    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );
    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );
    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );
    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );
    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );
    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );
    formVars.checkupf_543052_value =
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null;
    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );
    formVars.checkupf_120090_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi1
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi1
        : null
    );
    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );
    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );
    formVars.checkupf_793476_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi2
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi2
        : null
    );
    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );
    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );
    formVars.checkupf_82332_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi3
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi3
        : null
    );
    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );
    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );
    formVars.checkupf_764695_value = ReactSystemFunctions.value(
      this,
      stateVars.CheckupData?.length > 0
        ? stateVars.CheckupData[0].test13Girdi4
        : this.state.CheckupData?.length > 0
        ? this.state.CheckupData[0].test13Girdi4
        : null
    );
    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );
    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );
    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );
    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );
    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );
    stateVars.isComp319999Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp944490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7471646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp510803Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp216233Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp179819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp905629Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp731109Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp906929Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15252Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330353Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp868884Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp559118Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778240Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp809924Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333825Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp715446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp794446Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp908895Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62484Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp213419Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp671173Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp160509Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564442Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486342Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp866359Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp970568Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp459319Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552646Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858132Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp833754Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp148686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp315640Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431362Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp934273Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172628Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp178101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604698Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp409984Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp918055Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp330672Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775669Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127185Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp942457Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp972311Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp410574Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp229454Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp847379Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp401993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp737330Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303017Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879819Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551320Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703893Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp984127Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp407695Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp55955Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp841154Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp61460Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp426204Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Girdi41Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp663238Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp579165Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368313Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp293494Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp431891Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637907Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp900697Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp946255Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363431Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp529166Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444186Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp207616Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787323Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37156Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475501Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp675920Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196189Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp851329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774902Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp89417Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp633271Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp135860Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp821841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292389Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp132779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp352438Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp346044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp136214Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp600014Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp75709Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997083Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp813054Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp528080Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp202938Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp765049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp848222Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp144171Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp840013Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp874835Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp65547Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775779Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp463996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193012Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995517Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp333477Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp285545Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp698959Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp138049Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320401Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175686Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551885Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp662267Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp64505Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287838Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385058Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp895329Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354071Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp210104Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp981878Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp587393Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp287399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp486993Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp444352Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549331Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp484116Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389347Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp257094Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp264613Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp169200Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp983990Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283148Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp624590Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp965140Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126511Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456155Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319300Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp704910Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp455530Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp808223Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp83939Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp126174Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802246Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp256022Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp443677Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp551536Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp385921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp581736Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp957472Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp134787Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp59681Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp34987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp917474Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp10699Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320106Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp702841Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp634101Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp159973Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp652137Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8YardimUrl
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8YardimUrl
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp77197Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820982Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678030Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp536996Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360719Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp790033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp805006Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre4Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327050Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349099Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp453124Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp52399Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AraSonuc3Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp113195Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_543052_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_543052_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.checkupf_163663_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].protokolAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].protokolAdi
        : null
    );

    formVars.checkupf_927294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1BilesenAdi
        : null
    );

    formVars.checkupf_883638_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AltBilesenAdi
        : null
    );

    formVars.checkupf_486014_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Id
        : null
    );

    formVars.checkupf_8163186_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre1Adi
        : null
    );

    formVars.checkupf_510803_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Aciklama
        : null
    );

    formVars.checkupf_713180_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi1Placeholder
        : null
    );

    formVars.checkupf_11697_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre2Adi
        : null
    );

    formVars.checkupf_179819_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Aciklama
        : null
    );

    formVars.checkupf_965121_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi2Placeholder
        : null
    );

    formVars.checkupf_364015_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre3Adi
        : null
    );

    formVars.checkupf_731109_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Aciklama
        : null
    );

    formVars.checkupf_321884_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi3Placeholder
        : null
    );

    formVars.checkupf_976632_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_15252_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_209103_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Placeholder
        : null
    );

    formVars.checkupf_995102_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc1Adi
        : null
    );

    formVars.checkupf_681375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc2Adi
        : null
    );

    formVars.checkupf_534470_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1AraSonuc3Adi
        : null
    );

    formVars.checkupf_496670_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_778240_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_823081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2BilesenAdi
        : null
    );

    formVars.checkupf_268287_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AltBilesenAdi
        : null
    );

    formVars.checkupf_685456_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Id
        : null
    );

    formVars.checkupf_165975_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre1Adi
        : null
    );

    formVars.checkupf_794446_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Aciklama
        : null
    );

    formVars.checkupf_565949_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi1Placeholder
        : null
    );

    formVars.checkupf_335450_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre2Adi
        : null
    );

    formVars.checkupf_62484_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Aciklama
        : null
    );

    formVars.checkupf_335804_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi2Placeholder
        : null
    );

    formVars.checkupf_433660_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre3Adi
        : null
    );

    formVars.checkupf_213419_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Aciklama
        : null
    );

    formVars.checkupf_342230_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi3Placeholder
        : null
    );

    formVars.checkupf_905530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Parametre4Adi
        : null
    );

    formVars.checkupf_160509_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Aciklama
        : null
    );

    formVars.checkupf_837990_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2Girdi4Placeholder
        : null
    );

    formVars.checkupf_730289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc1Adi
        : null
    );

    formVars.checkupf_617536_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc2Adi
        : null
    );

    formVars.checkupf_296818_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test2AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test2AraSonuc3Adi
        : null
    );

    formVars.checkupf_445709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_970568_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_966960_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3BilesenAdi
        : null
    );

    formVars.checkupf_905288_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AltBilesenAdi
        : null
    );

    formVars.checkupf_295230_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Id
        : null
    );

    formVars.checkupf_836886_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre1Adi
        : null
    );

    formVars.checkupf_858132_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Aciklama
        : null
    );

    formVars.checkupf_142206_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi1Placeholder
        : null
    );

    formVars.checkupf_311583_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre2Adi
        : null
    );

    formVars.checkupf_148686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Aciklama
        : null
    );

    formVars.checkupf_445956_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi2Placeholder
        : null
    );

    formVars.checkupf_813854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre3Adi
        : null
    );

    formVars.checkupf_431362_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Aciklama
        : null
    );

    formVars.checkupf_392650_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi3Placeholder
        : null
    );

    formVars.checkupf_198344_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Parametre4Adi
        : null
    );

    formVars.checkupf_172628_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Aciklama
        : null
    );

    formVars.checkupf_412491_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3Girdi4Placeholder
        : null
    );

    formVars.checkupf_582562_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc1Adi
        : null
    );

    formVars.checkupf_266929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc2Adi
        : null
    );

    formVars.checkupf_748650_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test3AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test3AraSonuc3Adi
        : null
    );

    formVars.checkupf_600425_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_918055_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_342238_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4BilesenAdi
        : null
    );

    formVars.checkupf_135767_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AltBilesenAdi
        : null
    );

    formVars.checkupf_285289_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Id
        : null
    );

    formVars.checkupf_929810_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre1Adi
        : null
    );

    formVars.checkupf_127185_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Aciklama
        : null
    );

    formVars.checkupf_753080_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi1Placeholder
        : null
    );

    formVars.checkupf_84921_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre2Adi
        : null
    );

    formVars.checkupf_972311_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Aciklama
        : null
    );

    formVars.checkupf_100508_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi2Placeholder
        : null
    );

    formVars.checkupf_879078_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre3Adi
        : null
    );

    formVars.checkupf_229454_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Aciklama
        : null
    );

    formVars.checkupf_890285_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi3Placeholder
        : null
    );

    formVars.checkupf_208090_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Parametre4Adi
        : null
    );

    formVars.checkupf_110347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Aciklama
        : null
    );

    formVars.checkupf_632517_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4Girdi4Placeholder
        : null
    );

    formVars.checkupf_227302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc1Adi
        : null
    );

    formVars.checkupf_340683_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc2Adi
        : null
    );

    formVars.checkupf_490075_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test4AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test4AraSonuc3Adi
        : null
    );

    formVars.checkupf_200267_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5BilesenAdi
        : null
    );

    formVars.checkupf_158828_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AltBilesenAdi
        : null
    );

    formVars.checkupf_818509_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Id
        : null
    );

    formVars.checkupf_51190_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre1Adi
        : null
    );

    formVars.checkupf_984127_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Aciklama
        : null
    );

    formVars.checkupf_91031_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi1Placeholder
        : null
    );

    formVars.checkupf_196753_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre2Adi
        : null
    );

    formVars.checkupf_407695_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Aciklama
        : null
    );

    formVars.checkupf_286377_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi2Placeholder
        : null
    );

    formVars.checkupf_97814_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre3Adi
        : null
    );

    formVars.checkupf_841154_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Aciklama
        : null
    );

    formVars.checkupf_840595_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi3Placeholder
        : null
    );

    formVars.checkupf_98165_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Parametre4Adi
        : null
    );

    formVars.checkupf_426204_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi41Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi41Aciklama
        : null
    );

    formVars.checkupf_785891_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5Girdi4Placeholder
        : null
    );

    formVars.checkupf_58613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc1Adi
        : null
    );

    formVars.checkupf_76982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc2Adi
        : null
    );

    formVars.checkupf_346622_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test5AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test5AraSonuc3Adi
        : null
    );

    formVars.checkupf_6302_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6BilesenAdi
        : null
    );

    formVars.checkupf_745220_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AltBilesenAdi
        : null
    );

    formVars.checkupf_414768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Id
        : null
    );

    formVars.checkupf_718709_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre1Adi
        : null
    );

    formVars.checkupf_900697_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Aciklama
        : null
    );

    formVars.checkupf_470497_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi1Placeholder
        : null
    );

    formVars.checkupf_49204_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre2Adi
        : null
    );

    formVars.checkupf_363431_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Aciklama
        : null
    );

    formVars.checkupf_233533_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi2Placeholder
        : null
    );

    formVars.checkupf_387768_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre3Adi
        : null
    );

    formVars.checkupf_444186_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Aciklama
        : null
    );

    formVars.checkupf_444205_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi3Placeholder
        : null
    );

    formVars.checkupf_557294_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Parametre4Adi
        : null
    );

    formVars.checkupf_207616_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Aciklama
        : null
    );

    formVars.checkupf_69705_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6Girdi4Placeholder
        : null
    );

    formVars.checkupf_119513_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc1Adi
        : null
    );

    formVars.checkupf_882892_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc2Adi
        : null
    );

    formVars.checkupf_85929_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test6AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test6AraSonuc3Adi
        : null
    );

    formVars.checkupf_136530_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7BilesenAdi
        : null
    );

    formVars.checkupf_263982_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AltBilesenAdi
        : null
    );

    formVars.checkupf_398094_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Id
        : null
    );

    formVars.checkupf_6538_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre1Adi
        : null
    );

    formVars.checkupf_774902_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Aciklama
        : null
    );

    formVars.checkupf_158743_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi1Placeholder
        : null
    );

    formVars.checkupf_261479_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre2Adi
        : null
    );

    formVars.checkupf_633271_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Aciklama
        : null
    );

    formVars.checkupf_128276_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi2Placeholder
        : null
    );

    formVars.checkupf_805475_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre3Adi
        : null
    );

    formVars.checkupf_821841_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Aciklama
        : null
    );

    formVars.checkupf_391944_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi3Placeholder
        : null
    );

    formVars.checkupf_31719_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Parametre4Adi
        : null
    );

    formVars.checkupf_692572_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Aciklama
        : null
    );

    formVars.checkupf_347331_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7Girdi4Placeholder
        : null
    );

    formVars.checkupf_837847_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc1Adi
        : null
    );

    formVars.checkupf_233613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc2Adi
        : null
    );

    formVars.checkupf_150758_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test7AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test7AraSonuc3Adi
        : null
    );

    formVars.checkupf_983073_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_600014_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_893576_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8BilesenAdi
        : null
    );

    formVars.checkupf_719590_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AltBilesenAdi
        : null
    );

    formVars.checkupf_63093_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Id
        : null
    );

    formVars.checkupf_73976_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre1Adi
        : null
    );

    formVars.checkupf_528080_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Aciklama
        : null
    );

    formVars.checkupf_727646_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi1Placeholder
        : null
    );

    formVars.checkupf_589081_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre2Adi
        : null
    );

    formVars.checkupf_765049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Aciklama
        : null
    );

    formVars.checkupf_275943_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi2Placeholder
        : null
    );

    formVars.checkupf_970822_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre3Adi
        : null
    );

    formVars.checkupf_144171_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Aciklama
        : null
    );

    formVars.checkupf_993404_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi3Placeholder
        : null
    );

    formVars.checkupf_926525_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Parametre4Adi
        : null
    );

    formVars.checkupf_874835_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Aciklama
        : null
    );

    formVars.checkupf_999696_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8Girdi4Placeholder
        : null
    );

    formVars.checkupf_68389_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc1Adi
        : null
    );

    formVars.checkupf_330128_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc2Adi
        : null
    );

    formVars.checkupf_936575_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test8AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test8AraSonuc3Adi
        : null
    );

    formVars.checkupf_853956_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_463996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_369618_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9BilesenAdi
        : null
    );

    formVars.checkupf_892648_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AltBilesenAdi
        : null
    );

    formVars.checkupf_625584_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Id
        : null
    );

    formVars.checkupf_469904_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre1Adi
        : null
    );

    formVars.checkupf_285545_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Aciklama
        : null
    );

    formVars.checkupf_60537_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi1Placeholder
        : null
    );

    formVars.checkupf_328726_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre2Adi
        : null
    );

    formVars.checkupf_138049_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Aciklama
        : null
    );

    formVars.checkupf_629130_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi2Placeholder
        : null
    );

    formVars.checkupf_324715_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre3Adi
        : null
    );

    formVars.checkupf_175686_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Aciklama
        : null
    );

    formVars.checkupf_667315_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi3Placeholder
        : null
    );

    formVars.checkupf_1491_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Parametre4Adi
        : null
    );

    formVars.checkupf_662267_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Aciklama
        : null
    );

    formVars.checkupf_766861_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9Girdi4Placeholder
        : null
    );

    formVars.checkupf_399988_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc1Adi
        : null
    );

    formVars.checkupf_674918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc2Adi
        : null
    );

    formVars.checkupf_381797_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test9AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test9AraSonuc3Adi
        : null
    );

    formVars.checkupf_932593_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_895329_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_412458_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10BilesenAdi
        : null
    );

    formVars.checkupf_151796_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AltBilesenAdi
        : null
    );

    formVars.checkupf_915251_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Id
        : null
    );

    formVars.checkupf_547605_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre1Adi
        : null
    );

    formVars.checkupf_587393_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Aciklama
        : null
    );

    formVars.checkupf_943417_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi1Placeholder
        : null
    );

    formVars.checkupf_426905_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre2Adi
        : null
    );

    formVars.checkupf_486993_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Aciklama
        : null
    );

    formVars.checkupf_36477_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi2Placeholder
        : null
    );

    formVars.checkupf_760152_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre3Adi
        : null
    );

    formVars.checkupf_549331_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Aciklama
        : null
    );

    formVars.checkupf_520087_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi3Placeholder
        : null
    );

    formVars.checkupf_142771_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Parametre4Adi
        : null
    );

    formVars.checkupf_389347_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Aciklama
        : null
    );

    formVars.checkupf_309047_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10Girdi4Placeholder
        : null
    );

    formVars.checkupf_339064_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc1Adi
        : null
    );

    formVars.checkupf_880106_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc2Adi
        : null
    );

    formVars.checkupf_408852_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test10AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test10AraSonuc3Adi
        : null
    );

    formVars.checkupf_417644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_983990_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_491651_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11BilesenAdi
        : null
    );

    formVars.checkupf_691088_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AltBilesenAdi
        : null
    );

    formVars.checkupf_250633_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Id
        : null
    );

    formVars.checkupf_592928_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre1Adi
        : null
    );

    formVars.checkupf_126511_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Aciklama
        : null
    );

    formVars.checkupf_747579_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi1Placeholder
        : null
    );

    formVars.checkupf_189150_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre2Adi
        : null
    );

    formVars.checkupf_319300_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Aciklama
        : null
    );

    formVars.checkupf_188524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi2Placeholder
        : null
    );

    formVars.checkupf_263459_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre3Adi
        : null
    );

    formVars.checkupf_455530_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Aciklama
        : null
    );

    formVars.checkupf_554524_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi3Placeholder
        : null
    );

    formVars.checkupf_407496_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Parametre4Adi
        : null
    );

    formVars.checkupf_83939_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Aciklama
        : null
    );

    formVars.checkupf_667827_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11Girdi4Placeholder
        : null
    );

    formVars.checkupf_777606_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc1Adi
        : null
    );

    formVars.checkupf_321924_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc2Adi
        : null
    );

    formVars.checkupf_329143_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test11AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test11AraSonuc3Adi
        : null
    );

    formVars.checkupf_838433_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_443677_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_950375_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12BilesenAdi
        : null
    );

    formVars.checkupf_599283_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AltBilesenAdi
        : null
    );

    formVars.checkupf_711326_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Id
        : null
    );

    formVars.checkupf_433098_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre1Adi
        : null
    );

    formVars.checkupf_581736_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Aciklama
        : null
    );

    formVars.checkupf_601275_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi1Placeholder
        : null
    );

    formVars.checkupf_649675_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre2Adi
        : null
    );

    formVars.checkupf_134787_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Aciklama
        : null
    );

    formVars.checkupf_315757_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi2Placeholder
        : null
    );

    formVars.checkupf_978170_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre3Adi
        : null
    );

    formVars.checkupf_34987_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Aciklama
        : null
    );

    formVars.checkupf_560951_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi3Placeholder
        : null
    );

    formVars.checkupf_184950_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Parametre4Adi
        : null
    );

    formVars.checkupf_10699_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Aciklama
        : null
    );

    formVars.checkupf_117115_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12Girdi4Placeholder
        : null
    );

    formVars.checkupf_6473_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc1Adi
        : null
    );

    formVars.checkupf_961918_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc2Adi
        : null
    );

    formVars.checkupf_350065_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test12AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test12AraSonuc3Adi
        : null
    );

    formVars.checkupf_366854_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_634101_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    formVars.checkupf_964330_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13BilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13BilesenAdi
        : null
    );

    formVars.checkupf_778723_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AltBilesenAdi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AltBilesenAdi
        : null
    );

    formVars.checkupf_543052_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Id
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Id
        : null
    );

    formVars.checkupf_64863_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre1Adi
        : null
    );

    formVars.checkupf_820982_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Aciklama
        : null
    );

    formVars.checkupf_120090_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi1Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi1Placeholder
        : null
    );

    formVars.checkupf_670991_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre2Adi
        : null
    );

    formVars.checkupf_536996_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Aciklama
        : null
    );

    formVars.checkupf_793476_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi2Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi2Placeholder
        : null
    );

    formVars.checkupf_916138_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre3Adi
        : null
    );

    formVars.checkupf_790033_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Aciklama
        : null
    );

    formVars.checkupf_82332_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi3Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi3Placeholder
        : null
    );

    formVars.checkupf_760644_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Parametre4Adi
        : null
    );

    formVars.checkupf_327050_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Aciklama
        : null
    );

    formVars.checkupf_764695_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13Girdi4Placeholder
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13Girdi4Placeholder
        : null
    );

    formVars.checkupf_959115_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc1Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc1Adi
        : null
    );

    formVars.checkupf_545424_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc2Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc2Adi
        : null
    );

    formVars.checkupf_555301_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test13AraSonuc3Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test13AraSonuc3Adi
        : null
    );

    formVars.checkupf_863613_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Parametre4Adi
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Parametre4Adi
        : null
    );

    formVars.checkupf_113195_title = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupMeta?.length > 0
        ? stateVars.CheckupMeta[0].test1Girdi4Aciklama
        : this.state.CheckupMeta?.length > 0
        ? this.state.CheckupMeta[0].test1Girdi4Aciklama
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_652137_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupMeta?.length > 0
          ? stateVars.CheckupMeta[0].test13YardimUrl
          : this.state.CheckupMeta?.length > 0
          ? this.state.CheckupMeta[0].test13YardimUrl
          : null
      ),
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_120090_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_120090_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_120090_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest13Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest13Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_793476_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_793476_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest13Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest13Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_82332_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "checkupf_82332_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest13Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest13Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_764695_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_764695_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest13Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest13Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Id
            : null
        ),
        "Guid"
      ),
      protokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen13Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen13Id
            : null
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_120090_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_793476_value", "value", "", "", "")
        ),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_82332_value", "value", "", "", "")),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_764695_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_120090_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecute = result?.data.testExecute;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_120090_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_120090_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_806951_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc1
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc1
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_963867_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc2
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc2
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_71137_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].araSonuc3
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].araSonuc3
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_93843_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "checkupf_769023_value",
      ReactSystemFunctions.value(
        this,
        stateVars.TestExecute?.length > 0
          ? stateVars.TestExecute[0].puan
          : this.state.TestExecute?.length > 0
          ? this.state.TestExecute[0].puan
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_793476_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_120090_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_82332_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_120090_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_764695_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.CheckupFComponent_120090_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_178016_value", "value", "ProtokolunHizmetleri", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_178016_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_834990_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_834990_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_7480_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "checkupf_7480_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_204783_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_204783_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_749559_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_749559_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_477360_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_477360_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_183669_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_183669_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_604892_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_604892_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_999696_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_999696_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest8Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest8Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_113173_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_113173_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_372084_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_372084_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_546001_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_546001_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_183892_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_183892_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_93843_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "checkupf_93843_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEarlierOrEqual(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        moment().format("YYYY-MM-DDTHH:mm:ss")
      ),
      message: "İleri bi tarih seçilemez!",
      formName: "checkupf_696401_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_696401_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_713180_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_713180_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest1Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest1Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965121_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965121_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest1Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest1Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_321884_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_321884_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest1Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest1Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_209103_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_209103_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest1Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest1Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_565949_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_565949_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest2Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest2Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_335804_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_335804_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest2Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest2Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_342230_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_342230_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest2Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest2Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_837990_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_837990_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest2Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest2Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_142206_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_142206_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest3Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest3Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_445956_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_445956_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest3Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest3Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_392650_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_392650_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest3Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest3Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_412491_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_412491_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest3Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest3Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_753080_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_753080_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest4Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest4Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_100508_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_100508_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest4Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest4Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_890285_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_890285_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest4Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest4Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_632517_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_632517_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest4Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest4Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_91031_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "checkupf_91031_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest5Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest5Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_286377_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_286377_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest5Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest5Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_840595_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_840595_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest5Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest5Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_785891_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_785891_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest5Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest5Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_470497_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_470497_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest6Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest6Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_233533_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_233533_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest6Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest6Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_444205_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_444205_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest6Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest6Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_69705_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "checkupf_69705_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest6Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest6Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_158743_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_158743_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest7Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest7Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_128276_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_128276_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest7Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest7Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_391944_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_391944_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest7Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest7Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_403924_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_403924_value",
      condition: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Adi
            : null
        ),
        null
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_727646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_727646_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest8Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest8Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_275943_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_275943_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest8Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest8Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_993404_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_993404_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest8Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest8Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_60537_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "checkupf_60537_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest9Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest9Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_629130_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_629130_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest9Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest9Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_667315_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_667315_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest9Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest9Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_766861_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_766861_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest9Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest9Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_943417_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_943417_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest10Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest10Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_36477_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "checkupf_36477_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest10Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest10Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_520087_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_520087_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest10Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest10Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_309047_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_309047_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest10Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest10Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_747579_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_747579_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest11Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest11Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_188524_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_188524_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest11Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest11Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_554524_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_554524_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest11Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest11Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_999696_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_667827_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest11Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest11Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_601275_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_601275_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest12Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest12Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_315757_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_315757_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest12Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest12Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_560951_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_560951_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest12Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest12Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_117115_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_117115_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest12Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest12Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_120090_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_120090_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest13Param1
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest13Param1
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_793476_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_793476_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest13Param2
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest13Param2
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_82332_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "checkupf_82332_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest13Param3
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest13Param3
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_764695_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_764695_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].isRequiredTest13Param4
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].isRequiredTest13Param4
            : null
        ),
        "1"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prm1_0: ReactSystemFunctions.convertToTypeByName("A", "string"),
      prm2_0: ReactSystemFunctions.convertToTypeByName("B", "string"),
      prm3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_178016_value", "value", "ProtokolunHizmetleri", "id", "")
        ),
        "string"
      ),
      prm1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_834990_value", "value", "", "", "")
        ),
        "string"
      ),
      prm2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_7480_value", "value", "", "", "")
        ),
        "string"
      ),
      prm3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_204783_value", "value", "", "", "")
        ),
        "string"
      ),
      prm1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_749559_value", "value", "", "", "")
        ),
        "string"
      ),
      prm2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_477360_value", "value", "", "", "")
        ),
        "string"
      ),
      prm3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_183669_value", "value", "", "", "")
        ),
        "string"
      ),
      prm1_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_604892_value", "value", "", "", "")
        ),
        "string"
      ),
      prm2_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_999696_value", "value", "", "", "")
        ),
        "string"
      ),
      prm3_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_178016_value", "value", "ProtokolunHizmetleri", "id", "")
        ),
        "string"
      ),
      prm1_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_113173_value", "value", "", "", "")
        ),
        "string"
      ),
      prm2_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_372084_value", "value", "", "", "")
        ),
        "string"
      ),
      prm3_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_546001_value", "value", "", "", "")
        ),
        "string"
      ),
      prm1_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_183892_value", "value", "", "", "")
        ),
        "string"
      ),
      prm2_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_93843_value", "value", "", "", "")
        ),
        "string"
      ),
      prm3_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_178016_value", "value", "ProtokolunHizmetleri", "id", "")
        ),
        "string"
      ),
      Agirlik_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      Boy_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      CheckupDonemiId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      CheckupTarihi_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        "Date"
      ),
      EgitmeninNotu_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "checkupf_702183_value", "value", "", "", "")
        ),
        "string"
      ),
      Kirilim1Id_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim1Id
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim1Id
            : null
        ),
        "Guid"
      ),
      Kirilim2Id_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].mevcutKirilim2Id
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].mevcutKirilim2Id
            : null
        ),
        "Guid"
      ),
      KisiId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      KurumId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      ProtokolId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      RandevuId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid,
        "Guid"
      ),
      TopluOlcumId_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupData?.length > 0
            ? stateVars.CheckupData[0].topluolcumId
            : this.state.CheckupData?.length > 0
            ? this.state.CheckupData[0].topluolcumId
            : null
        ),
        "Guid"
      ),
      Yas_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckUpFYasHesapla?.length > 0
            ? stateVars.CheckUpFYasHesapla[0].yas
            : this.state.CheckUpFYasHesapla?.length > 0
            ? this.state.CheckUpFYasHesapla[0].yas
            : null
        ),
        "number"
      ),
      Id_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      protokolBilesenId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen1Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen1Id
            : null
        ),
        "Guid"
      ),
      puan_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_834990_value", "value", "", "", "")
        ),
        "number"
      ),
      CheckupId_10: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      ProtokolBilesenId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen1Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen1Id
            : null
        ),
        "Guid"
      ),
      BilesenId_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_2509_value", "value", "", "", "")),
        "number"
      ),
      AraSonuc2_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_944772_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc3_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_218416_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi1_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_713180_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi2_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965121_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi3_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_321884_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi4_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_209103_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolAltBilesenId_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen1Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen1Id
            : null
        ),
        "Guid"
      ),
      Sira_11: ReactSystemFunctions.convertToTypeByName(1, "number"),
      TestId_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Id
            : null
        ),
        "Guid"
      ),
      Puan_11: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_834990_value", "value", "", "", "")
        ),
        "number"
      ),
      protokolBilesenId_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen2Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen2Id
            : null
        ),
        "Guid"
      ),
      puan_12: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_7480_value", "value", "", "", "")),
        "number"
      ),
      CheckupId_13: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      ProtokolBilesenId_13: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen2Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen2Id
            : null
        ),
        "Guid"
      ),
      BilesenId_13: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_712501_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc2_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_600360_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc3_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_240986_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi1_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_565949_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi2_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_335804_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi3_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_342230_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi4_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_837990_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolAltBilesenId_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen2Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen2Id
            : null
        ),
        "Guid"
      ),
      Puan_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_7480_value", "value", "", "", "")),
        "number"
      ),
      Sira_14: ReactSystemFunctions.convertToTypeByName(2, "number"),
      TestId_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Id
            : null
        ),
        "Guid"
      ),
      protokolBilesenId_15: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen3Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen3Id
            : null
        ),
        "Guid"
      ),
      puan_15: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_204783_value", "value", "", "", "")
        ),
        "number"
      ),
      CheckupId_16: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      Puan_16: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_204783_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolBilesenId_16: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen3Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen3Id
            : null
        ),
        "Guid"
      ),
      BilesenId_16: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_17: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_17: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_62063_value", "value", "", "", "")),
        "number"
      ),
      AraSonuc2_17: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_165138_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc3_17: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_108466_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi1_17: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_142206_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi2_17: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_445956_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi3_17: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_392650_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi4_17: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_412491_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolAltBilesenId_17: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen3Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen3Id
            : null
        ),
        "Guid"
      ),
      Puan_17: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_204783_value", "value", "", "", "")
        ),
        "number"
      ),
      Sira_17: ReactSystemFunctions.convertToTypeByName(3, "number"),
      TestId_17: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Id
            : null
        ),
        "Guid"
      ),
      protokolBilesenId_18: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen4Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen4Id
            : null
        ),
        "Guid"
      ),
      puan_18: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_749559_value", "value", "", "", "")
        ),
        "number"
      ),
      CheckupId_19: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      Puan_19: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_749559_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolBilesenId_19: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen4Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen4Id
            : null
        ),
        "Guid"
      ),
      BilesenId_19: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_20: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_20: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_20874_value", "value", "", "", "")),
        "number"
      ),
      AraSonuc2_20: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_615683_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc3_20: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_799182_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi1_20: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_753080_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi2_20: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_100508_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi3_20: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_890285_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi4_20: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_632517_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolAltBilesenId_20: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen4Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen4Id
            : null
        ),
        "Guid"
      ),
      Puan_20: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_749559_value", "value", "", "", "")
        ),
        "number"
      ),
      Sira_20: ReactSystemFunctions.convertToTypeByName(4, "number"),
      TestId_20: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Id
            : null
        ),
        "Guid"
      ),
      protokolBilesenId_21: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen5Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen5Id
            : null
        ),
        "Guid"
      ),
      puan_21: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_477360_value", "value", "", "", "")
        ),
        "number"
      ),
      CheckupId_22: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      Puan_22: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_477360_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolBilesenId_22: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen5Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen5Id
            : null
        ),
        "Guid"
      ),
      BilesenId_22: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_23: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_23: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_910913_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc2_23: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_446195_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc3_23: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_50512_value", "value", "", "", "")),
        "number"
      ),
      Girdi1_23: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_91031_value", "value", "", "", "")),
        "number"
      ),
      Girdi2_23: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_286377_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi3_23: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_840595_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi4_23: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_785891_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolAltBilesenId_23: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen4Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen4Id
            : null
        ),
        "Guid"
      ),
      Puan_23: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_477360_value", "value", "", "", "")
        ),
        "number"
      ),
      Sira_23: ReactSystemFunctions.convertToTypeByName(5, "number"),
      TestId_23: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Id
            : null
        ),
        "Guid"
      ),
      protokolBilesenId_24: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen6Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen6Id
            : null
        ),
        "Guid"
      ),
      puan_24: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_183669_value", "value", "", "", "")
        ),
        "number"
      ),
      CheckupId_25: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      Puan_25: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_183669_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolBilesenId_25: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen6Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen6Id
            : null
        ),
        "Guid"
      ),
      BilesenId_25: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_26: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_26: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_38410_value", "value", "", "", "")),
        "number"
      ),
      AraSonuc2_26: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_736592_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc3_26: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_584267_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi1_26: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_470497_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi2_26: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_233533_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi3_26: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_444205_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi4_26: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_69705_value", "value", "", "", "")),
        "number"
      ),
      ProtokolAltBilesenId_26: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen6Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen6Id
            : null
        ),
        "Guid"
      ),
      Puan_26: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_183669_value", "value", "", "", "")
        ),
        "number"
      ),
      Sira_26: ReactSystemFunctions.convertToTypeByName(6, "number"),
      TestId_26: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Id
            : null
        ),
        "Guid"
      ),
      protokolBilesenId_27: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen7Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen7Id
            : null
        ),
        "Guid"
      ),
      puan_27: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_604892_value", "value", "", "", "")
        ),
        "number"
      ),
      CheckupId_28: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      Puan_28: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_604892_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolBilesenId_28: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen7Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen7Id
            : null
        ),
        "Guid"
      ),
      BilesenId_28: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_29: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_29: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_439367_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc2_29: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_66346_value", "value", "", "", "")),
        "number"
      ),
      AraSonuc3_29: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_272601_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi1_29: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_158743_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi2_29: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_128276_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi3_29: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_391944_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi4_29: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_837990_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolAltBilesenId_29: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen7Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen7Id
            : null
        ),
        "Guid"
      ),
      Puan_29: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_604892_value", "value", "", "", "")
        ),
        "number"
      ),
      Sira_29: ReactSystemFunctions.convertToTypeByName(7, "number"),
      TestId_29: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Id
            : null
        ),
        "Guid"
      ),
      protokolBilesenId_30: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen8Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen8Id
            : null
        ),
        "Guid"
      ),
      puan_30: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_403924_value", "value", "", "", "")
        ),
        "number"
      ),
      CheckupId_31: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      Puan_31: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_403924_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolBilesenId_31: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen8Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen8Id
            : null
        ),
        "Guid"
      ),
      BilesenId_31: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_32: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_32: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_206874_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc2_32: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_294219_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc3_32: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_861350_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi1_32: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_727646_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi2_32: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_275943_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi3_32: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_993404_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi4_32: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_999696_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolAltBilesenId_32: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen8Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen8Id
            : null
        ),
        "Guid"
      ),
      Puan_32: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_403924_value", "value", "", "", "")
        ),
        "number"
      ),
      Sira_32: ReactSystemFunctions.convertToTypeByName(8, "number"),
      TestId_32: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Id
            : null
        ),
        "Guid"
      ),
      protokolBilesenId_33: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen9Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen9Id
            : null
        ),
        "Guid"
      ),
      puan_33: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_113173_value", "value", "", "", "")
        ),
        "number"
      ),
      CheckupId_34: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      Puan_34: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_113173_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolBilesenId_34: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen9Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen9Id
            : null
        ),
        "Guid"
      ),
      BilesenId_34: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_35: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_35: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_709588_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc2_35: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_760834_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc3_35: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_86834_value", "value", "", "", "")),
        "number"
      ),
      Girdi1_35: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_60537_value", "value", "", "", "")),
        "number"
      ),
      Girdi2_35: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_629130_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi3_35: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_667315_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi4_35: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_766861_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolAltBilesenId_35: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen9Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen9Id
            : null
        ),
        "Guid"
      ),
      Puan_35: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_113173_value", "value", "", "", "")
        ),
        "number"
      ),
      Sira_35: ReactSystemFunctions.convertToTypeByName(9, "number"),
      TestId_35: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Id
            : null
        ),
        "Guid"
      ),
      protokolBilesenId_36: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen10Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen10Id
            : null
        ),
        "Guid"
      ),
      puan_36: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_372084_value", "value", "", "", "")
        ),
        "number"
      ),
      CheckupId_37: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      Puan_37: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_372084_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolBilesenId_37: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen10Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen10Id
            : null
        ),
        "Guid"
      ),
      BilesenId_37: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_38: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_38: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_372084_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc2_38: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_722637_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc3_38: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_322797_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi1_38: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_943417_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi2_38: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_36477_value", "value", "", "", "")),
        "number"
      ),
      Girdi3_38: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_520087_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi4_38: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_309047_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolAltBilesenId_38: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen10Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen10Id
            : null
        ),
        "Guid"
      ),
      Puan_38: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_372084_value", "value", "", "", "")
        ),
        "number"
      ),
      Sira_38: ReactSystemFunctions.convertToTypeByName(10, "number"),
      TestId_38: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Id
            : null
        ),
        "Guid"
      ),
      protokolBilesenId_39: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen11Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen11Id
            : null
        ),
        "Guid"
      ),
      puan_39: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_546001_value", "value", "", "", "")
        ),
        "number"
      ),
      CheckupId_40: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      ProtokolBilesenId_40: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen11Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen11Id
            : null
        ),
        "Guid"
      ),
      BilesenId_40: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_41: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_41: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_452596_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc2_41: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_608811_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc3_41: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_188042_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi1_41: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_747579_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi2_41: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_188524_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi3_41: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_554524_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi4_41: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_667827_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolAltBilesenId_41: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen11Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen11Id
            : null
        ),
        "Guid"
      ),
      Sira_41: ReactSystemFunctions.convertToTypeByName(11, "number"),
      TestId_41: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Id
            : null
        ),
        "Guid"
      ),
      Puan_41: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_546001_value", "value", "", "", "")
        ),
        "number"
      ),
      protokolBilesenId_42: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen12Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen12Id
            : null
        ),
        "Guid"
      ),
      puan_42: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_183892_value", "value", "", "", "")
        ),
        "number"
      ),
      CheckupId_43: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      ProtokolBilesenId_43: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen12Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen12Id
            : null
        ),
        "Guid"
      ),
      BilesenId_43: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_44: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_44: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_352264_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc2_44: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_431679_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc3_44: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_110193_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi1_44: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_601275_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi2_44: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_315757_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi3_44: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_560951_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi4_44: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_117115_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolAltBilesenId_44: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen12Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen12Id
            : null
        ),
        "Guid"
      ),
      Sira_44: ReactSystemFunctions.convertToTypeByName(12, "number"),
      TestId_44: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Id
            : null
        ),
        "Guid"
      ),
      Puan_44: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_183892_value", "value", "", "", "")
        ),
        "number"
      ),
      protokolBilesenId_45: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen13Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen13Id
            : null
        ),
        "Guid"
      ),
      puan_45: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_93843_value", "value", "", "", "")),
        "number"
      ),
      CheckupId_46: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      ProtokolBilesenId_46: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolBilesen13Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolBilesen13Id
            : null
        ),
        "Guid"
      ),
      BilesenId_46: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13BilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13BilesenId
            : null
        ),
        "Guid"
      ),
      AltBilesenId_47: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13AltBilesenId
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13AltBilesenId
            : null
        ),
        "Guid"
      ),
      AraSonuc1_47: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_806951_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc2_47: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_963867_value", "value", "", "", "")
        ),
        "number"
      ),
      AraSonuc3_47: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_71137_value", "value", "", "", "")),
        "number"
      ),
      Girdi1_47: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_120090_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi2_47: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_793476_value", "value", "", "", "")
        ),
        "number"
      ),
      Girdi3_47: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_82332_value", "value", "", "", "")),
        "number"
      ),
      Girdi4_47: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_764695_value", "value", "", "", "")
        ),
        "number"
      ),
      ProtokolAltBilesenId_47: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].protokolAltBilesen13Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].protokolAltBilesen13Id
            : null
        ),
        "Guid"
      ),
      Sira_47: ReactSystemFunctions.convertToTypeByName(13, "number"),
      TestId_47: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Id
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Id
            : null
        ),
        "Guid"
      ),
      Puan_47: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupf_93843_value", "value", "", "", "")),
        "number"
      ),
      profileId_48: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      checkupId_48: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      hizmetId_48: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_178016_value", "value", "ProtokolunHizmetleri", "id", "id")
        ),
        "Guid"
      ),
      Id_49: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      kisiId_50: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      profilId_51: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      kisiid_51: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      ),
      currentrole_51: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kurumID_51: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      KisiId_52: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DummySave = result?.data.dummySave;
    stateVars.DummySave = result?.data.dummySave;
    stateVars.DummySave = result?.data.dummySave;
    stateVars.DummySave = result?.data.dummySave;
    stateVars.DummySave = result?.data.dummySave;
    stateVars.DummySave = result?.data.dummySave;
    stateVars.CheckupTekliSave = result?.data.checkupTekliSave;
    stateVars.CheckupBilesenleriKaydet = result?.data.checkupBilesenleriKaydet;
    stateVars.CheckupData2 = result?.data.checkupData2;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_3;
    stateVars.SaveRecord = result?.data.saveRecord_4;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_5;
    stateVars.SaveRecord = result?.data.saveRecord_6;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_7;
    stateVars.SaveRecord = result?.data.saveRecord_8;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_9;
    stateVars.SaveRecord = result?.data.saveRecord_10;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_11;
    stateVars.SaveRecord = result?.data.saveRecord_12;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_13;
    stateVars.SaveRecord = result?.data.saveRecord_14;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_15;
    stateVars.SaveRecord = result?.data.saveRecord_16;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_17;
    stateVars.SaveRecord = result?.data.saveRecord_18;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_19;
    stateVars.SaveRecord = result?.data.saveRecord_20;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_21;
    stateVars.SaveRecord = result?.data.saveRecord_22;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_23;
    stateVars.SaveRecord = result?.data.saveRecord_24;
    stateVars.SiniflandirmaHesapla = result?.data.siniflandirmaHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_25;
    stateVars.SaveRecord = result?.data.saveRecord_26;
    stateVars.FitSkorHesapla = result?.data.fitSkorHesapla;
    stateVars.SaveRecord = result?.data.saveRecord_27;
    stateVars.KisiCheckupAdedi = result?.data.kisiCheckupAdedi;
    stateVars.KullaniciMailLogoUrl = result?.data.kullaniciMailLogoUrl;
    stateVars.CheckupMail = result?.data.checkupMail;
    stateVars.BaseUrl = result?.data.baseUrl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_9544_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_9544_onClick_if11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CheckupFComponent_9544_onClick_if1f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_9544_onClick_if1f11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  CheckupFComponent_9544_onClick_if1f1f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupFComponent_9544_onClick_if1f1f11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_9544_onClick_if1f1f11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("d81b7fed-38a9-4e0c-94f0-d5c33d5fcea6", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMail?.length > 0
            ? stateVars.CheckupMail[0].receiver
            : this.state.CheckupMail?.length > 0
            ? this.state.CheckupMail[0].receiver
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("İlk Fitness Check-Up raporunuz hazır!", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BaseUrl?.length > 0
            ? stateVars.BaseUrl[0].baseUrl
            : this.state.BaseUrl?.length > 0
            ? this.state.BaseUrl[0].baseUrl
            : null
        ),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMail?.length > 0
            ? stateVars.CheckupMail[0].receiver
            : this.state.CheckupMail?.length > 0
            ? this.state.CheckupMail[0].receiver
            : null
        ),
        "string"
      ),
      kurumlogo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciMailLogoUrl?.length > 0
            ? stateVars.KullaniciMailLogoUrl[0].maillogourl
            : this.state.KullaniciMailLogoUrl?.length > 0
            ? this.state.KullaniciMailLogoUrl[0].maillogourl
            : null
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiCheckupAdedi?.length > 0
            ? stateVars.KisiCheckupAdedi[0].adet
            : this.state.KisiCheckupAdedi?.length > 0
            ? this.state.KisiCheckupAdedi[0].adet
            : null
        ),
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "CheckupF/CheckupFComponent_9544_onClick_if1f1f11_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_9544_onClick_if1f1f12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_9544_onClick_if1f1f12_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupFComponent_9544_onClick_if1f1f12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("cbc9ffbd-2c97-4be9-9e4a-0952aaba8c5a", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMail?.length > 0
            ? stateVars.CheckupMail[0].receiver
            : this.state.CheckupMail?.length > 0
            ? this.state.CheckupMail[0].receiver
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("FitSkor'unuz değişti", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BaseUrl?.length > 0
            ? stateVars.BaseUrl[0].baseUrl
            : this.state.BaseUrl?.length > 0
            ? this.state.BaseUrl[0].baseUrl
            : null
        ),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMail?.length > 0
            ? stateVars.CheckupMail[0].receiver
            : this.state.CheckupMail?.length > 0
            ? this.state.CheckupMail[0].receiver
            : null
        ),
        "string"
      ),
      kurumlogo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KullaniciMailLogoUrl?.length > 0
            ? stateVars.KullaniciMailLogoUrl[0].maillogourl
            : this.state.KullaniciMailLogoUrl?.length > 0
            ? this.state.KullaniciMailLogoUrl[0].maillogourl
            : null
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiCheckupAdedi?.length > 0
            ? stateVars.KisiCheckupAdedi[0].adet
            : this.state.KisiCheckupAdedi?.length > 0
            ? this.state.KisiCheckupAdedi[0].adet
            : null
        ),
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "CheckupF/CheckupFComponent_9544_onClick_if1f1f12_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  CheckupFComponent_9544_onClick_if1f1e1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "93145570_fb68_3818_24da_c0614e4df8c8_notify",
        this.defaultML,
        "Veri girdiğiniz kişi kullanıcı olmadığı için rapor maili gönderilmedi."
      ),
      "warning",
      "bottom-right",
      3,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  CheckupFComponent_9544_onClick_if1f11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMail?.length > 0
            ? stateVars.CheckupMail[0].receiver
            : this.state.CheckupMail?.length > 0
            ? this.state.CheckupMail[0].receiver
            : null
        ),
        "0"
      )
    ) {
      isErrorOccurred = await this.CheckupFComponent_9544_onClick_if1f1f1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.CheckupFComponent_9544_onClick_if1f1e1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  CheckupFComponent_9544_onClick_if1e1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "27e9ddc4_6c8b_e85a_782c_8ce4d3b26390_notify",
        this.defaultML,
        "Veri girdiğiniz kişi kullanıcı olmadığı için rapor maili gönderilmedi."
      ),
      "warning",
      "bottom-right",
      0,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  CheckupFComponent_9544_onClick_if11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].kUserId
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].kUserId
            : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.CheckupFComponent_9544_onClick_if1f1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.CheckupFComponent_9544_onClick_if1e1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  CheckupFComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumTipiKod
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumTipiKod
            : null
        ),
        "KT_School"
      ) &&
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumTipiKod
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumTipiKod
            : null
        ),
        "KT_Club"
      )
    ) {
      isErrorOccurred = await this.CheckupFComponent_9544_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupFComponent_241697_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test1Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test1Parametre1Adi
            : null
        ),
        null
      )
    ) {
      KuikaAppManager.showSpinner(this);

      if (await this.CheckupFComponent_713180_onChange()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupFComponent_241697_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test2Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test2Parametre1Adi
            : null
        ),
        null
      )
    ) {
      if (await this.CheckupFComponent_565949_onChange()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupFComponent_241697_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test3Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test3Parametre1Adi
            : null
        ),
        null
      )
    ) {
      if (await this.CheckupFComponent_142206_onChange()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupFComponent_241697_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test4Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test4Parametre1Adi
            : null
        ),
        null
      )
    ) {
      if (await this.CheckupFComponent_753080_onChange()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupFComponent_241697_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test5Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test5Parametre1Adi
            : null
        ),
        null
      )
    ) {
      if (await this.CheckupFComponent_91031_onChange()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupFComponent_241697_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test6Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test6Parametre1Adi
            : null
        ),
        null
      )
    ) {
      if (await this.CheckupFComponent_470497_onChange()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupFComponent_241697_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test7Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test7Parametre1Adi
            : null
        ),
        null
      )
    ) {
      if (await this.CheckupFComponent_158743_onChange()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupFComponent_241697_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test8Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test8Parametre1Adi
            : null
        ),
        null
      )
    ) {
      if (await this.CheckupFComponent_727646_onChange()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupFComponent_241697_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test9Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test9Parametre1Adi
            : null
        ),
        null
      )
    ) {
      if (await this.CheckupFComponent_60537_onChange()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupFComponent_241697_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test10Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test10Parametre1Adi
            : null
        ),
        null
      )
    ) {
      if (await this.CheckupFComponent_943417_onChange()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupFComponent_241697_onClick10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test11Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test11Parametre1Adi
            : null
        ),
        null
      )
    ) {
      if (await this.CheckupFComponent_747579_onChange()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupFComponent_241697_onClick11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test12Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test12Parametre1Adi
            : null
        ),
        null
      )
    ) {
      if (await this.CheckupFComponent_601275_onChange()) return true;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupFComponent_241697_onClick12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupFComponent_241697_onClick12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.CheckupMeta?.length > 0
            ? stateVars.CheckupMeta[0].test13Parametre1Adi
            : this.state.CheckupMeta?.length > 0
            ? this.state.CheckupMeta[0].test13Parametre1Adi
            : null
        ),
        null
      )
    ) {
      if (await this.CheckupFComponent_120090_onChange()) return true;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  CheckupFComponent_412449_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isEarlierOrEqual(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        moment().format("YYYY-MM-DDTHH:mm:ss")
      ),
      message: "İleri bi tarih seçilemez!",
      formName: "checkupf_696401_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_696401_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_965654_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "checkupf_425186_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      ProtokolId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      CheckupTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_696401_value", "value", "", "", "")
        ),
        "Date"
      ),
      Boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_965654_value", "value", "", "", "")
        ),
        "number"
      ),
      Agirlik_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_425186_value", "value", "", "", "")
        ),
        "number"
      ),
      Yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].yas
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].yas
            : null
        ),
        "number"
      ),
      KurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      EgitmeninNotu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "checkupf_702183_value", "value", "", "", "")
        ),
        "string"
      ),
      Kirilim1Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim1Id
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim1Id
            : null
        ),
        "Guid"
      ),
      CheckupDonemiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      Kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].mevcutKirilim2Id
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].mevcutKirilim2Id
            : null
        ),
        "Guid"
      ),
      RandevuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid,
        "Guid"
      ),
      TopluOlcumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupData?.length > 0
            ? stateVars.CheckupData[0].topluolcumId
            : this.state.CheckupData?.length > 0
            ? this.state.CheckupData[0].topluolcumId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupF/CheckupFComponent_412449_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_28;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
