import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  Footer,
  Header,
  HorizontalStack,
  Icon,
  KCol,
  KRow,
  Label,
  TextArea
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { ProtocolBilesenF2_ScreenBase } from "./protocolbilesenf2-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class ProtocolBilesenF2_Screen extends ProtocolBilesenF2_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              protocolbilesenf2_436800_value: this.state.ProtokolBilesenById?.at?.(0)?.siniflandirmaId ?? undefined,
              protocolbilesenf2_52244_value:
                this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye1Aciklama ?? undefined,
              protocolbilesenf2_626565_value:
                this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye2Aciklama ?? undefined,
              protocolbilesenf2_592190_value:
                this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye3Aciklama ?? undefined,
              protocolbilesenf2_308696_value:
                this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye4Aciklama ?? undefined,
              protocolbilesenf2_16146_value:
                this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye5Aciklama ?? undefined,
              protocolbilesenf2_317609_value:
                this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye6Aciklama ?? undefined,
              protocolbilesenf2_379240_value:
                this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye7Aciklama ?? undefined,
              protocolbilesenf2_531390_value:
                this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye8Aciklama ?? undefined
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="protocolbilesenf2_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="472882"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="622344"
                    xs={20}
                    sm={20}
                    md={20}
                    lg={20}
                    xl={20}
                    xxl={20}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="634568"
                      value={ReactSystemFunctions.translate(this.ml, 634568, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14pt",
                          color: "rgba(74, 74, 74, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="968985"
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    xxl={4}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Icon
                      id="968274"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.ProtocolBilesenF2Component_968274_onClick();
                      }}
                      showCursorPointer
                      iconName="close"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="protocolbilesenf2_body"
                style={
                  {
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="176430"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingRight: 16,
                      paddingBottom: 0,
                      paddingLeft: 16,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="302640"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <KRow
                      id="864921"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingRight: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="649867"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <Label
                          id="196500"
                          value={ReactSystemFunctions.translate(this.ml, 196500, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "12px",
                              color: "rgba(10, 33, 64, 1)",
                              lineHeight: "20px"
                            } as any
                          }
                        ></Label>

                        <Form.Item className="kFormItem" name="protocolbilesenf2_436800_value">
                          <KSelectBox
                            id="436800"
                            onChange={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.ProtocolBilesenF2Component_436800_onChange();
                            }}
                            kuikaRef={this.protocolbilesenf2_436800_value_kuikaSelectBoxRef}
                            options={this.state.SiniflandirmaAll}
                            placeholder={ReactSystemFunctions.translate(this.ml, 436800, "placeholder", this.defaultML)}
                            allowClear={false}
                            autoClearSearchValue={true}
                            showSearch={false}
                            onSearch={{}}
                            widthType="fill"
                            containsNullItem={false}
                            sortBy="none"
                            datavaluefield="id"
                            datatextfield="tanim"
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></KSelectBox>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="594938"
                      visibility={this.state.isComp594938Visible}
                      visibilityCondition={(rowData: object) =>
                        ReactSystemFunctions.isGreaterThan(
                          this.state.BilesenSiniflandirmaRaporAciklama[0]?.seviyeAdedi?.toString(),
                          "0"
                        )
                      }
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingRight: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="907048"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="511530"
                          direction="horizontal"
                          size={4}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="963811"
                            value={this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye1Adi ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="967318"
                            value={ReactSystemFunctions.translate(this.ml, 967318, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <Form.Item className="kFormItem" name="protocolbilesenf2_52244_value">
                          <TextArea
                            id="52244"
                            placeholder={ReactSystemFunctions.translate(this.ml, 52244, "placeholder", this.defaultML)}
                            allowClear={false}
                            autoSize={false}
                            showCount={false}
                            formatter="None"
                            readOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "160px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></TextArea>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="286959"
                      visibility={this.state.isComp286959Visible}
                      visibilityCondition={(rowData: object) =>
                        ReactSystemFunctions.isGreaterThan(
                          this.state.BilesenSiniflandirmaRaporAciklama[0]?.seviyeAdedi?.toString(),
                          "1"
                        )
                      }
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingRight: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="335778"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="411556"
                          direction="horizontal"
                          size={4}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="913903"
                            value={this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye2Adi ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="509578"
                            value={ReactSystemFunctions.translate(this.ml, 509578, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <Form.Item className="kFormItem" name="protocolbilesenf2_626565_value">
                          <TextArea
                            id="626565"
                            placeholder={ReactSystemFunctions.translate(this.ml, 626565, "placeholder", this.defaultML)}
                            allowClear={false}
                            autoSize={false}
                            showCount={false}
                            formatter="None"
                            readOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "160px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></TextArea>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="154471"
                      visibility={this.state.isComp154471Visible}
                      visibilityCondition={(rowData: object) =>
                        ReactSystemFunctions.isGreaterThan(
                          this.state.BilesenSiniflandirmaRaporAciklama[0]?.seviyeAdedi?.toString(),
                          "2"
                        )
                      }
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingRight: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="275434"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="598924"
                          direction="horizontal"
                          size={4}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="359711"
                            value={this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye3Adi ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="253361"
                            value={ReactSystemFunctions.translate(this.ml, 253361, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <Form.Item className="kFormItem" name="protocolbilesenf2_592190_value">
                          <TextArea
                            id="592190"
                            placeholder={ReactSystemFunctions.translate(this.ml, 592190, "placeholder", this.defaultML)}
                            allowClear={false}
                            autoSize={false}
                            showCount={false}
                            formatter="None"
                            readOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "160px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></TextArea>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="264710"
                      visibility={this.state.isComp264710Visible}
                      visibilityCondition={(rowData: object) =>
                        ReactSystemFunctions.isGreaterThan(
                          this.state.BilesenSiniflandirmaRaporAciklama[0]?.seviyeAdedi?.toString(),
                          "3"
                        )
                      }
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingRight: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="656449"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="19952"
                          direction="horizontal"
                          size={4}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="582368"
                            value={this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye4Adi ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="934805"
                            value={ReactSystemFunctions.translate(this.ml, 934805, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <Form.Item className="kFormItem" name="protocolbilesenf2_308696_value">
                          <TextArea
                            id="308696"
                            placeholder={ReactSystemFunctions.translate(this.ml, 308696, "placeholder", this.defaultML)}
                            allowClear={false}
                            autoSize={false}
                            showCount={false}
                            formatter="None"
                            readOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "160px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></TextArea>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="882780"
                      visibility={this.state.isComp882780Visible}
                      visibilityCondition={(rowData: object) =>
                        ReactSystemFunctions.isGreaterThan(
                          this.state.BilesenSiniflandirmaRaporAciklama[0]?.seviyeAdedi?.toString(),
                          "4"
                        )
                      }
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingRight: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="952192"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="822233"
                          direction="horizontal"
                          size={4}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="928483"
                            value={this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye5Adi ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="496019"
                            value={ReactSystemFunctions.translate(this.ml, 496019, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <Form.Item className="kFormItem" name="protocolbilesenf2_16146_value">
                          <TextArea
                            id="16146"
                            placeholder={ReactSystemFunctions.translate(this.ml, 16146, "placeholder", this.defaultML)}
                            allowClear={false}
                            autoSize={false}
                            showCount={false}
                            formatter="None"
                            readOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "160px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></TextArea>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="910124"
                      visibility={this.state.isComp910124Visible}
                      visibilityCondition={(rowData: object) =>
                        ReactSystemFunctions.isGreaterThan(
                          this.state.BilesenSiniflandirmaRaporAciklama[0]?.seviyeAdedi?.toString(),
                          "5"
                        )
                      }
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingRight: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="103251"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="573578"
                          direction="horizontal"
                          size={4}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="191609"
                            value={this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye6Adi ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="114698"
                            value={ReactSystemFunctions.translate(this.ml, 114698, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <Form.Item className="kFormItem" name="protocolbilesenf2_317609_value">
                          <TextArea
                            id="317609"
                            placeholder={ReactSystemFunctions.translate(this.ml, 317609, "placeholder", this.defaultML)}
                            allowClear={false}
                            autoSize={false}
                            showCount={false}
                            formatter="None"
                            readOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "160px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></TextArea>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="514465"
                      visibility={this.state.isComp514465Visible}
                      visibilityCondition={(rowData: object) =>
                        ReactSystemFunctions.isGreaterThan(
                          this.state.BilesenSiniflandirmaRaporAciklama[0]?.seviyeAdedi?.toString(),
                          "6"
                        )
                      }
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingRight: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="272194"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="420077"
                          direction="horizontal"
                          size={4}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="525404"
                            value={this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye7Adi ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="336078"
                            value={ReactSystemFunctions.translate(this.ml, 336078, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <Form.Item className="kFormItem" name="protocolbilesenf2_379240_value">
                          <TextArea
                            id="379240"
                            placeholder={ReactSystemFunctions.translate(this.ml, 379240, "placeholder", this.defaultML)}
                            allowClear={false}
                            autoSize={false}
                            showCount={false}
                            formatter="None"
                            readOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "160px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></TextArea>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="648318"
                      visibility={this.state.isComp648318Visible}
                      visibilityCondition={(rowData: object) =>
                        ReactSystemFunctions.isGreaterThan(
                          this.state.BilesenSiniflandirmaRaporAciklama[0]?.seviyeAdedi?.toString(),
                          "7"
                        )
                      }
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingRight: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="173041"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="828445"
                          direction="horizontal"
                          size={4}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="126655"
                            value={this.state.BilesenSiniflandirmaRaporAciklama?.at?.(0)?.seviye8Adi ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="268967"
                            value={ReactSystemFunctions.translate(this.ml, 268967, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <Form.Item className="kFormItem" name="protocolbilesenf2_531390_value">
                          <TextArea
                            id="531390"
                            placeholder={ReactSystemFunctions.translate(this.ml, 531390, "placeholder", this.defaultML)}
                            allowClear={false}
                            autoSize={false}
                            showCount={false}
                            formatter="None"
                            readOnly={false}
                            style={
                              {
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                borderBottomRightRadius: 8,
                                borderBottomLeftRadius: 8,
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                height: "160px",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></TextArea>
                        </Form.Item>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>
              </div>

              <Footer
                id="protocolbilesenf2_footer"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="841691"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="600721"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Button
                      id="9544"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.ProtocolBilesenF2Component_9544_onClick();
                      }}
                      showCursorPointer
                      label={ReactSystemFunctions.translate(this.ml, 9544, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      icon="keyboard_return"
                      iconPosition="right"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          backgroundColor: "rgba(0, 190, 214, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          alignItems: "center",
                          textAlign: "-webkit-center",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "rgba(255, 255, 255, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    ></Button>
                  </KCol>
                </KRow>
              </Footer>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(ProtocolBilesenF2_Screen))))
  )
);
export { tmp as ProtocolBilesenF2_Screen };
//export default tmp;
//export { tmp as ProtocolBilesenF2_Screen };
