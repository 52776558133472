import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKurumBasvuruForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKurumBasvuruForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MerkezAll: any[];
  MerkezAll_dummy: any[];
  ServiceAll: any[];
  ServiceAll_dummy: any[];
  LocationGetCurrentCoordinates: any;
  LocationGetCurrentCoordinates_dummy: any;
  LocationAdressOfCoordinates: any;
  LocationAdressOfCoordinates_dummy: any;
  SetValueOf: any;
  SetValueOf_dummy: any;
  SelectOkulBransAll: any[];
  SelectOkulBransAll_dummy: any[];
  PhotoFromGallery: string;
  PhotoFromGallery_dummy: string;
  OpenInBrowser: any;
  OpenInBrowser_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  BasvuruKurumSave: number;
  BasvuruKurumSave_dummy: number;
  SendMail: boolean;
  SendMail_dummy: boolean;
  Notify: boolean;
  Notify_dummy: boolean;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
}

export class KurumBasvuruForm_ScreenBase extends React.PureComponent<IKurumBasvuruForm_ScreenProps, any> {
  kurumbasvuruform_158152_value_kuikaDateRef: React.RefObject<any>;
  kurumbasvuruform_708851_value_kuikaSelectBoxRef: React.RefObject<any>;
  kurumbasvuruform_205425_value_kuikaPlacesAutocompleteInputRef: React.RefObject<any>;
  kurumbasvuruform_484841_value_kuikaLocationPickerComponentRef: React.RefObject<any>;
  kurumbasvuruform_644805_value_kuikaMultiSelectRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        {
          Id: 777862,
          PropertyName: "value",
          Value: "Your application will be evaluated by MeMap, and once approved, you will be informed by e-mail.",
          TagName: "Label5_value"
        },
        { Id: 194783, PropertyName: "value", Value: "Account Manager Information", TagName: "Label2_value" },
        { Id: 482943, PropertyName: "value", Value: "Name", TagName: "Label1_0_value" },
        { Id: 378355, PropertyName: "placeholder", Value: "Name", TagName: "txtAd_placeholder" },
        { Id: 50174, PropertyName: "value", Value: "Surname", TagName: "Label1_0_value" },
        { Id: 503657, PropertyName: "placeholder", Value: "Surname", TagName: "txtSoyad_placeholder" },
        { Id: 276803, PropertyName: "value", Value: "Birth Date", TagName: "Label1_0_value" },
        { Id: 158152, PropertyName: "placeholder", Value: "01/01/1978", TagName: "dtDogumTarihi_placeholder" },
        { Id: 995851, PropertyName: "value", Value: "Biologic Gender", TagName: "Label1_0_value" },
        { Id: 708851, PropertyName: "placeholder", Value: "♀ / ♂", TagName: "sbCinsiyet_placeholder" },
        { Id: 216394, PropertyName: "value", Value: "E-mail", TagName: "Label2_value" },
        { Id: 615228, PropertyName: "placeholder", Value: "name@domainname.com", TagName: "txtEmail_placeholder" },
        { Id: 560047, PropertyName: "value", Value: "Cell number", TagName: "Label6_value" },
        { Id: 449007, PropertyName: "placeholder", Value: "0(5__) ___ __ __", TagName: "txtCebTelefonu_placeholder" },
        { Id: 94596, PropertyName: "value", Value: "Corporate Information", TagName: "Label1_0_value" },
        { Id: 478913, PropertyName: "value", Value: "Logo", TagName: "Label8_value" },
        { Id: 696473, PropertyName: "value", Value: "Download", TagName: "Label9_value" },
        { Id: 481919, PropertyName: "value", Value: "Name", TagName: "Label4_value" },
        { Id: 472037, PropertyName: "placeholder", Value: "Corporate Name", TagName: "txtMerkezAd_placeholder" },
        { Id: 121663, PropertyName: "value", Value: "Phone number", TagName: "txtTelefon_value" },
        { Id: 766814, PropertyName: "placeholder", Value: "0(5__) ___ __ __", TagName: "txtMerkezTelefon_placeholder" },
        { Id: 725813, PropertyName: "value", Value: "Web adress", TagName: "Label3_value" },
        { Id: 814517, PropertyName: "placeholder", Value: "https://www.", TagName: "txtWebAdresi_placeholder" },
        { Id: 269665, PropertyName: "value", Value: "Adress", TagName: "Label2_value" },
        {
          Id: 205425,
          PropertyName: "placeholder",
          Value: "Location search from your address...",
          TagName: "LocationSearch1_placeholder"
        },
        {
          Id: 940974,
          PropertyName: "value",
          Value: "Please select MeMap services for your organization's members.",
          TagName: "Label1_0_value"
        },
        {
          Id: 886417,
          PropertyName: "value",
          Value: "Please select the locations where your organization will provide services.",
          TagName: "Label7_value"
        },
        {
          Id: 287137,
          PropertyName: "value",
          Value: "Get information about the benefits of the MeMap platform.",
          TagName: "Label1_0_value"
        },
        {
          Id: 572567,
          PropertyName: "value",
          Value: "Would you like to appear a place in MeMap Platform?",
          TagName: "Label73_value"
        },
        {
          Id: 34124,
          PropertyName: "value",
          Value: "Would you like to be a part of the MeMap platform?",
          TagName: "Label1_1_value"
        },
        {
          Id: 479811,
          PropertyName: "value",
          Value: "I have read the Disclosure Text, I Understand.",
          TagName: "Label1_1_value"
        },
        { Id: 395898, PropertyName: "label", Value: "Send", TagName: "Button0_0_label" },
        { Id: 462354, PropertyName: "value", Value: "Explore", TagName: "Label1_0_value" },
        { Id: 970, PropertyName: "value", Value: "Fitness Check-Up", TagName: "Label1_1_value" },
        { Id: 203044, PropertyName: "value", Value: "FitSkor®", TagName: "Label1_2_value" },
        { Id: 289691, PropertyName: "value", Value: "Pricing", TagName: "Label1_3_value" },
        { Id: 856928, PropertyName: "value", Value: "Where to find", TagName: "Label1_4_value" },
        { Id: 469986, PropertyName: "value", Value: "Thought Leadership", TagName: "Label1_5_value" },
        { Id: 836818, PropertyName: "value", Value: "Terms of Use", TagName: "Label1_6_value" },
        { Id: 120010, PropertyName: "value", Value: "Privacy Policy", TagName: "Label1_7_value" },
        { Id: 473528, PropertyName: "value", Value: "Platform Guide", TagName: "Label1_8_value" },
        { Id: 104774, PropertyName: "value", Value: "What is MeMap", TagName: "Label1_0_value" },
        { Id: 654658, PropertyName: "value", Value: "For You", TagName: "Label1_1_value" },
        { Id: 11254, PropertyName: "value", Value: "For Trainers", TagName: "Label1_2_value" },
        { Id: 833644, PropertyName: "value", Value: "For Gyms", TagName: "Label1_3_value" },
        { Id: 370672, PropertyName: "value", Value: "For Companies", TagName: "Label1_4_value" },
        { Id: 179234, PropertyName: "value", Value: "For Academics", TagName: "Label1_5_value" },
        { Id: 186230, PropertyName: "value", Value: "FAQ", TagName: "Label1_6_value" },
        { Id: 398767, PropertyName: "value", Value: "Distance Sale Agreement", TagName: "Label1_7_value" },
        { Id: 601824, PropertyName: "value", Value: "Contact", TagName: "Label1_8_value" },
        {
          Id: 374427,
          PropertyName: "value",
          Value:
            "© 2023 MeMap all rights reserved. MeMap is a method for preparing customized exercise strategy and prescription. Technology protected by U.S. Patent and EPO patent and other patent pending applications.",
          TagName: "Label1_0_value"
        },
        {
          Id: 10545647,
          PropertyName: "431cac2d-6c60-885f-0598-73d799b88de1_notify",
          Value: "You must approve the KVKK text.",
          TagName: "Notify_notify",
          IsNotify: true
        },
        {
          Id: 10580746,
          PropertyName: "cdfc63f1-0753-723a-9b25-dadf3aeb1227_notify",
          Value: "You need to approve the explicit consent text.",
          TagName: "Notify_notify",
          IsNotify: true
        },
        { Id: 702105, PropertyName: "value", Value: "Company Application Form", TagName: "Label1_value" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "0b086130-1b19-41ba-bb61-bcc0b171788e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 702105, PropertyName: "value", Value: "Kurum Başvuru Formu" },
        {
          Id: 777862,
          PropertyName: "value",
          Value:
            "Başvurunuz MeMap tarafından değerlendirilecek ve onaylandığında telefonla bilgi verilecektir. Bu formdaki bilgiler onay alan eğitmenlerin Yeni Nesil Fitness Eğitimine kaydını yapmak ve 1 haftalık ScoreSoft® deneme lisansınızı tanımlamak için kullanılacaktır.\n"
        },
        { Id: 194783, PropertyName: "value", Value: "Hesap Yöneticisi Bilgileri" },
        { Id: 482943, PropertyName: "value", Value: "Adınız" },
        { Id: 378355, PropertyName: "placeholder", Value: "Adınız" },
        { Id: 50174, PropertyName: "value", Value: "Soyadınız" },
        { Id: 503657, PropertyName: "placeholder", Value: "Soyadınız" },
        { Id: 276803, PropertyName: "value", Value: "Doğum Tarihiniz" },
        { Id: 158152, PropertyName: "placeholder", Value: "01/01/203" },
        { Id: 995851, PropertyName: "value", Value: "Cinsiyetiniz" },
        { Id: 708851, PropertyName: "placeholder", Value: "♀ / ♂" },
        { Id: 216394, PropertyName: "value", Value: "Email" },
        { Id: 615228, PropertyName: "placeholder", Value: "name@domainname.com" },
        { Id: 560047, PropertyName: "value", Value: "Cep Telefonu" },
        { Id: 449007, PropertyName: "placeholder", Value: "0(5__) ___ __ __" },
        { Id: 94596, PropertyName: "value", Value: "Kurum Bilgileri" },
        { Id: 478913, PropertyName: "value", Value: "Logo" },
        { Id: 696473, PropertyName: "value", Value: "Yükle" },
        { Id: 481919, PropertyName: "value", Value: "İsim" },
        { Id: 472037, PropertyName: "placeholder", Value: "Kurum ünvanı" },
        { Id: 121663, PropertyName: "value", Value: "Telefon" },
        { Id: 766814, PropertyName: "placeholder", Value: "0(5__) ___ __ __" },
        { Id: 725813, PropertyName: "value", Value: "Web Adresi" },
        { Id: 814517, PropertyName: "placeholder", Value: "https://www." },
        { Id: 269665, PropertyName: "value", Value: "Adres" },
        { Id: 205425, PropertyName: "placeholder", Value: "Açık adresten lokasyon arama..." },
        { Id: 940974, PropertyName: "value", Value: "Kurumunuz hangi merkezlerde hizmet sunacak?" },
        { Id: 644805, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 34124, PropertyName: "value", Value: "Açık Rıza Metni" },
        { Id: 294048, PropertyName: "value", Value: "'ni Okudum, Anladım, Kabul ediyorum." },
        { Id: 479811, PropertyName: "value", Value: "KVKK Aydınlatma Metni" },
        { Id: 675494, PropertyName: "value", Value: "'ni Okudum, Anladım." },
        { Id: 395898, PropertyName: "label", Value: "Gönder" },
        { Id: 462354, PropertyName: "value", Value: "Keşfet" },
        { Id: 970, PropertyName: "value", Value: "Fitness Check-Up" },
        { Id: 203044, PropertyName: "value", Value: "FitSkor®" },
        { Id: 289691, PropertyName: "value", Value: "Fiyatlandırma" },
        { Id: 856928, PropertyName: "value", Value: "Nerede Bulurum" },
        { Id: 469986, PropertyName: "value", Value: "Düşünce Liderliği" },
        { Id: 836818, PropertyName: "value", Value: "Üyelik Sözleşmesi" },
        { Id: 120010, PropertyName: "value", Value: "Aydınlatma Metni" },
        { Id: 473528, PropertyName: "value", Value: "İşlem Rehberi" },
        { Id: 104774, PropertyName: "value", Value: "MeMap Nedir?" },
        { Id: 654658, PropertyName: "value", Value: "Senin için" },
        { Id: 11254, PropertyName: "value", Value: "Eğitmenler için" },
        { Id: 833644, PropertyName: "value", Value: "Merkezler için" },
        { Id: 370672, PropertyName: "value", Value: "Kurumlar için" },
        { Id: 179234, PropertyName: "value", Value: "Akademik Kullanım" },
        { Id: 186230, PropertyName: "value", Value: "Sıkça Sorulan Sorular" },
        { Id: 398767, PropertyName: "value", Value: "Mesafeli Satış Sözleşmesi" },
        { Id: 601824, PropertyName: "value", Value: "İletişim" },
        {
          Id: 374427,
          PropertyName: "value",
          Value:
            "© 2017 MeMap tüm hakları saklıdır. MeMap, özelleştirilmiş egzersiz stratejisi ve reçetesi hazırlamak için bir yöntemdir. ABD Patent ofisi ve EPO patent ofisi ve diğer patent bekleyen başvurularla korunan bir teknolojidir."
        }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.kurumbasvuruform_158152_value_kuikaDateRef = React.createRef();
    this.kurumbasvuruform_708851_value_kuikaSelectBoxRef = React.createRef();
    this.kurumbasvuruform_205425_value_kuikaPlacesAutocompleteInputRef = React.createRef();
    this.kurumbasvuruform_484841_value_kuikaLocationPickerComponentRef = React.createRef();
    this.kurumbasvuruform_644805_value_kuikaMultiSelectRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MerkezAll: [],
      ServiceAll: [],
      LocationGetCurrentCoordinates: "",
      LocationAdressOfCoordinates: "",
      SetValueOf: "",
      SelectOkulBransAll: [],
      PhotoFromGallery: "",
      OpenInBrowser: "",
      MyContext: [],
      IfThenElse: false,
      NAVIGATE: "",
      dataSource_708851: [
        { key: "K", text: "Kadın" },
        { key: "E", text: "Erkek" }
      ],
      dataSource_644805: [
        { text: "Kendi merkezimizde.", key: "Kendi merkezimizde." },
        {
          key: "MeMap platformunda bulunan merkezlerle anlaşmak istiyoruz.",
          text: "MeMap platformunda bulunan merkezlerle anlaşmak istiyoruz."
        },
        {
          key: "Bize yakın yeni MeMap merkezleri kurulmasını istiyoruz.",
          text: "Bize yakın yeni MeMap merkezleri kurulmasını istiyoruz."
        }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("kurumbasvuruform", "");
      return;
    }

    if (true) {
      await this.KurumBasvuruFormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("kurumbasvuruform", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("kurumbasvuruform", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.KurumBasvuruFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      kurumbasvuruform_882933_value: false,
      kurumbasvuruform_840312_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  KurumBasvuruFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "KurumBasvuruForm/KurumBasvuruFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MerkezAll = result?.data.merkezAll;
    stateVars.ServiceAll = result?.data.serviceAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KurumBasvuruFormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KurumBasvuruFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.LocationGetCurrentCoordinates = (
      (await ReactSystemFunctions.locationGetCurrentCoordinates()) as any
    ).data;
    formVars.kurumbasvuruform_484841_latitude = ReactSystemFunctions.toDecimal(
      this,
      stateVars.LocationGetCurrentCoordinates?.latitude
    );
    formVars.kurumbasvuruform_484841_longitude = ReactSystemFunctions.toDecimal(
      this,
      stateVars.LocationGetCurrentCoordinates?.longitude
    );
    stateVars.LocationAdressOfCoordinates = (
      (await ReactSystemFunctions.locationAdressOfCoordinates(
        ReactSystemFunctions.toDecimal(this, stateVars.LocationGetCurrentCoordinates?.latitude),
        ReactSystemFunctions.toDecimal(this, stateVars.LocationGetCurrentCoordinates?.longitude)
      )) as any
    ).data;
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "kurumbasvuruform_205425_value",
      ReactSystemFunctions.toString(this, stateVars.LocationAdressOfCoordinates?.address),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KurumBasvuruFormPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  KurumBasvuruFormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "KurumBasvuruForm/KurumBasvuruFormPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectOkulBransAll = result?.data.selectOkulBransAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KurumBasvuruFormPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KurumBasvuruFormPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    var value_484841 = {} as any;
    value_484841.latitude = ReactSystemFunctions.toDecimal(this, this.state.LocationGetCurrentCoordinates?.latitude);
    value_484841.longitude = ReactSystemFunctions.toDecimal(this, this.state.LocationGetCurrentCoordinates?.longitude);

    formVars.kurumbasvuruform_484841_value = value_484841;
    var value_484841 = {} as any;
    value_484841.latitude = ReactSystemFunctions.toDecimal(this, this.state.LocationGetCurrentCoordinates?.latitude);
    value_484841.longitude = ReactSystemFunctions.toDecimal(this, this.state.LocationGetCurrentCoordinates?.longitude);

    formVars.kurumbasvuruform_484841_value = value_484841;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_513932_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_696473_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KurumBasvuruFormComponent_696473_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  KurumBasvuruFormComponent_696473_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "kurumbasvuruform_41134_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_205425_onSelectPlace = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "kurumbasvuruform_484841_value",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "kurumbasvuruform_205425_value", "Latitude", "", "", "")
      ),
      "latitude"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "kurumbasvuruform_484841_value",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "kurumbasvuruform_484841_value", "Longitude", "", "", "")
      ),
      "longitude"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_484841_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.LocationAdressOfCoordinates = (
      (await ReactSystemFunctions.locationAdressOfCoordinates(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_484841_value", "Latitude", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_484841_value", "Longitude", "", "", "")
        )
      )) as any
    ).data;
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "kurumbasvuruform_205425_value",
      ReactSystemFunctions.toString(this, stateVars.LocationAdressOfCoordinates?.address),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_34124_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://www.memap.com.tr/tr/pages/permitionfortransferdataabroad/",
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_479811_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/privacy_policy_tr/",
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_395898_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KurumBasvuruForm/KurumBasvuruFormComponent_395898_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KurumBasvuruFormComponent_395898_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KurumBasvuruFormComponent_395898_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KurumBasvuruFormComponent_395898_onClick_if11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KurumBasvuruFormComponent_395898_onClick_if11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_378355_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kurumbasvuruform_378355_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_449007_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kurumbasvuruform_449007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_708851_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "kurumbasvuruform_708851_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_615228_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kurumbasvuruform_615228_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_615228_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "kurumbasvuruform_615228_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_472037_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kurumbasvuruform_472037_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_766814_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "kurumbasvuruform_766814_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_41134_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "kurumbasvuruform_41134_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_503657_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kurumbasvuruform_503657_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Ad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_378355_value", "value", "", "", "")
        ),
        "string"
      ),
      CepTelefonu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_449007_value", "value", "", "", "")
        ),
        "string"
      ),
      Cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_708851_value", "value", "", "key", "")
        ),
        "string"
      ),
      DogumTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_158152_value", "value", "", "", "")
        ),
        "Date"
      ),
      Email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_615228_value", "value", "", "", "")
        ),
        "string"
      ),
      KurumAdres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.LocationAdressOfCoordinates?.address),
        "string"
      ),
      KurumIsim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_472037_value", "value", "", "", "")
        ),
        "string"
      ),
      KurumLat_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_484841_value", "Latitude", "", "", "")
        ),
        "number"
      ),
      KurumLongs_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_484841_value", "Longitude", "", "", "")
        ),
        "number"
      ),
      KurumTelefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_766814_value", "value", "", "", "")
        ),
        "string"
      ),
      KurumWebAdresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_814517_value", "value", "", "", "")
        ),
        "string"
      ),
      Logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_41134_value", "value", "", "", "")
        ),
        "string"
      ),
      MemapHizmetleri_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_644805_value", "value", "", "key", "")
        ),
        "string"
      ),
      Soyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_503657_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_840312_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "KurumBasvuruForm/KurumBasvuruFormComponent_395898_onClick_if11_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.BasvuruKurumSave = result?.data.basvuruKurumSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KurumBasvuruFormComponent_395898_onClick_if12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KurumBasvuruFormComponent_395898_onClick_if12_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KurumBasvuruFormComponent_395898_onClick_if12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_472037_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "kurumbasvuruform_472037_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("0d0e1234-fabf-43d2-a60f-79a9933c8fb7", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName("info@memap.com.tr", "string"),
      subject_0: ReactSystemFunctions.convertToTypeByName("Yeni Kurum Başvurun var", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      basvurutipi_0: ReactSystemFunctions.convertToTypeByName("Kurum", "string"),
      ad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_472037_value", "value", "", "", "")
        ),
        "string"
      ),
      soyad_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_840312_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "KurumBasvuruForm/KurumBasvuruFormComponent_395898_onClick_if12_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KurumBasvuruFormComponent_395898_onClick_if13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KurumBasvuruFormComponent_395898_onClick_if13_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KurumBasvuruFormComponent_395898_onClick_if13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_840312_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "58015d58_aaa8_e86b_74d2_955d4dd236aa_notify",
          this.defaultML,
          "Başvurunuz başarı ile tamamlandı"
        ),
        "success",
        "bottom-right",
        4,
        "",
        "",
        0
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KurumBasvuruFormComponent_395898_onClick_if14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KurumBasvuruFormComponent_395898_onClick_if14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  KurumBasvuruFormComponent_395898_onClick_if14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_840312_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KurumBasvuruFormComponent_395898_onClick_if15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KurumBasvuruFormComponent_395898_onClick_if15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  KurumBasvuruFormComponent_395898_onClick_if15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_840312_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "431cac2d_6c60_885f_0598_73d799b88de1_notify",
          this.defaultML,
          "KVKK metnini onaylamanız gereklidir."
        ),
        "default",
        "bottom-right",
        0,
        "",
        "",
        0
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_395898_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "cdfc63f1_0753_723a_9b25_dadf3aeb1227_notify",
        this.defaultML,
        "Açık rıza metnini onaylamanız gereklidir."
      ),
      "default",
      "bottom-right",
      0,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_395898_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumbasvuruform_882933_value", "value", "", "", "")
        ),
        true
      )
    ) {
      isErrorOccurred = await this.KurumBasvuruFormComponent_395898_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.KurumBasvuruFormComponent_395898_onClick_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_970_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/page/fitness_checkup/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_203044_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/page/fitskor/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_289691_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/page/pricing/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_856928_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/gyms/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_469986_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/page/thought-leadership/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_836818_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/terms_of_use_tr/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_120010_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/privacy_policy_tr/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_473528_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/platform_guide/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_654658_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/page/for-you/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_11254_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KurumBasvuruForm",
      "EgitmenBasvuruFormu",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_833644_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KurumBasvuruForm",
      "MerkezBasvuruForm",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_370672_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "KurumBasvuruForm",
      "KurumBasvuruForm",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_179234_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/page/for-schools/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_186230_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/faq/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_398767_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/sale_agreement/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumBasvuruFormComponent_601824_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/memap_contact/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.KurumBasvuruFormPageInit();
    }
  }
}
