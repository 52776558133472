import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISinifinOgrencileri_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISinifinOgrencileri_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  SinifinOgrencileri: any[];
  SinifinOgrencileri_dummy: any[];
  KurumTipiSelectById: any[];
  KurumTipiSelectById_dummy: any[];
  EmptySheet1: number;
  EmptySheet1_dummy: number;
  UploadFile: any;
  UploadFile_dummy: any;
  ImportExcelBase64: string;
  ImportExcelBase64_dummy: string;
  ImportKisiFromSheet1: number;
  ImportKisiFromSheet1_dummy: number;
  SampleData: any[];
  SampleData_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
  isComp596062Visible: "visible" | "hidden";
}

export class SinifinOgrencileri_ScreenBase extends React.PureComponent<ISinifinOgrencileri_ScreenProps, any> {
  sinifinogrencileri_399769_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "94d4ee38-78f8-437a-b71d-4ee548f84791",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 281715, PropertyName: "label", Value: "Toplu Yükleme" },
        { Id: 328508, PropertyName: "label", Value: "Örnek" },
        { Id: 134316, PropertyName: "label", Value: "Listeden Ekle" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 596062, PropertyName: "title", Value: "Okul Numarası" },
        { Id: 93027, PropertyName: "value", Value: "[datafield:okulnumarasi]" },
        { Id: 7829763, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 576548, PropertyName: "value", Value: "[datafield:soyadi]" },
        { Id: 653088, PropertyName: "title", Value: "Yaş" },
        { Id: 854266, PropertyName: "value", Value: "[datafield:kisiyas]" },
        { Id: 3249157, PropertyName: "title", Value: "Cinsiyet" },
        { Id: 1235982, PropertyName: "value", Value: "[datafield:cinsiyet]" },
        { Id: 540758, PropertyName: "title", Value: "Doğum Tarihi" },
        { Id: 789071, PropertyName: "value", Value: "[datafield:dogumtarihi]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.sinifinogrencileri_399769_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      SinifinOgrencileri: [],
      KurumTipiSelectById: [],
      EmptySheet1: 0,
      UploadFile: "",
      ImportExcelBase64: "",
      ImportKisiFromSheet1: 0,
      SampleData: [],
      ExportExcel: "",
      NAVIGATE: "",
      SaveRecord: false,
      isComp596062Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("sinifinogrencileri", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.SinifinOgrencileriPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("sinifinogrencileri", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("sinifinogrencileri", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SinifinOgrencileriPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  SinifinOgrencileriPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      sinifId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SinifinOgrencileri/SinifinOgrencileriPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.isComp596062Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumTipiKod
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumTipiKod
            : null
        ),
        "KT_School"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SinifinOgrencileri = result?.data.sinifinOgrencileri;

    stateVars.KurumTipiSelectById = result?.data.kurumTipiSelectById;
    formVars.sinifinogrencileri_923160_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].kirilim2ninozneleri
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].kirilim2ninozneleri
        : null
    );
    formVars.sinifinogrencileri_117613_label = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].yeni
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].yeni
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SinifinOgrencileriPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifinOgrencileriPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.sinifinogrencileri_923160_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].kirilim2ninozneleri
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].kirilim2ninozneleri
        : null
    );

    formVars.sinifinogrencileri_117613_label = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].yeni
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].yeni
        : null
    );

    stateVars.dataSource_399769 = this.state.SinifinOgrencileri;
    stateVars.isComp596062Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumTipiKod
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumTipiKod
            : null
        ),
        "KT_School"
      ) === true
        ? "visible"
        : "hidden";
    formVars.sinifinogrencileri_2977226_title = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].adi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].adi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SinifinOgrencileriComponent_321766_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifinOgrencileriComponent_281715_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifinOgrencileri/SinifinOgrencileriComponent_281715_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EmptySheet1 = result?.data.emptySheet1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SinifinOgrencileriComponent_281715_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifinOgrencileriComponent_281715_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SinifinOgrencileriComponent_281715_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SinifinOgrencileriComponent_281715_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      excelBase64_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      tableName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      primaryKeyName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      sinifId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      kurumId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName("Rol_Client", "string"),
      sinifId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifinOgrencileri/SinifinOgrencileriComponent_281715_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ImportExcelBase64 = result?.data.importExcelBase64;
    stateVars.ImportKisiFromSheet1 = result?.data.importKisiFromSheet1;
    stateVars.SinifinOgrencileri = result?.data.sinifinOgrencileri;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SinifinOgrencileriComponent_281715_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifinOgrencileriComponent_281715_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.SinifinOgrencileri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifinOgrencileriComponent_328508_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      header_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName(null, "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifinOgrencileri/SinifinOgrencileriComponent_328508_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SampleData = result?.data.sampleData;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  SinifinOgrencileriComponent_117613_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Person", "kisiId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Person", "role", "Rol_Client");
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "Kirilim2Id",
      this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id
    );
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "kurumID",
      ReactSystemFunctions.value(
        this,
        stateVars.MyContext?.length > 0
          ? stateVars.MyContext[0].kurumId
          : this.state.MyContext?.length > 0
          ? this.state.MyContext[0].kurumId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables("Person", "profilID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Person", "Ne", "N");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifinOgrencileri",
      "Person",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "930166",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifinOgrencileriComponent_134316_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("SinifYeniOgrenci", "role", "Rol_Client");
    KuikaAppManager.addToPageInputVariables(
      "SinifYeniOgrenci",
      "KisiId",
      ReactSystemFunctions.value(
        this,
        stateVars.MyContext?.length > 0
          ? stateVars.MyContext[0].kisiId
          : this.state.MyContext?.length > 0
          ? this.state.MyContext[0].kisiId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "SinifYeniOgrenci",
      "kurumID",
      ReactSystemFunctions.value(
        this,
        stateVars.MyContext?.length > 0
          ? stateVars.MyContext[0].kurumId
          : this.state.MyContext?.length > 0
          ? this.state.MyContext[0].kurumId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "SinifYeniOgrenci",
      "Kirilim2ID",
      this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifinOgrencileri",
      "SinifYeniOgrenci",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "76933",
      null,
      "right",
      null,
      "800px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifinOgrencileriComponent_518913_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PersonCard",
      "kisiId",
      ReactSystemFunctions.value(this, "sinifinogrencileri_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifinOgrencileri",
      "PersonCard",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifinOgrencileriComponent_292996_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "kisiId",
      ReactSystemFunctions.value(this, "sinifinogrencileri_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("Person", "role", "Rol_Client");
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "Kirilim2Id",
      this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id
    );
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "kurumID",
      ReactSystemFunctions.value(
        this,
        stateVars.MyContext?.length > 0
          ? stateVars.MyContext[0].kurumId
          : this.state.MyContext?.length > 0
          ? this.state.MyContext[0].kurumId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables("Person", "profilID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Person", "Ne", "E");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SinifinOgrencileri",
      "Person",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "295537",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SinifinOgrencileriComponent_441108_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "sinifinogrencileri_399769_value", "profilID"),
        "Guid"
      ),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "sinifinogrencileri_399769_value", "id"),
        "Guid"
      ),
      Rolename_0: ReactSystemFunctions.convertToTypeByName("Rol_Client", "string"),
      Kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "sinifinogrencileri_399769_value", "id"),
        "Guid"
      ),
      MevcutKirilim2Id_1: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      sinifId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SinifinOgrencileri/SinifinOgrencileriComponent_441108_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.SinifinOgrencileri = result?.data.sinifinOgrencileri;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SinifinOgrencileriComponent_441108_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SinifinOgrencileriComponent_441108_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.SinifinOgrencileri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [930166, 76933, 295537] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SinifinOgrencileriPageInit();
    }
  }
}
