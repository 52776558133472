import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Icon,
  KCol,
  KDrawer,
  KRow,
  KTab,
  KTabItem,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label
} from "../../components/web-components";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { Master_Screen } from "../master/master";
import { Basvurular_ScreenBase } from "./basvurular-base";

import { BasvuruEgitmenDetay_Screen } from "../basvuruegitmendetay/basvuruegitmendetay";
import { BasvuruKurumDetay_Screen } from "../basvurukurumdetay/basvurukurumdetay";
import { BasvuruMerkezDetay_Screen } from "../basvurumerkezdetay/basvurumerkezdetay";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class Basvurular_Screen extends Basvurular_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Master_Screen
          form={this.props.form}
          initialValues={{ basvurular_324400_value: undefined }}
          screenInputs={this.props.screenInputs}
          setMasterPageInitLoaded={this.setMasterPageInitLoaded}
          setMasterPageRecurringLoaded={this.setMasterPageRecurringLoaded}
          isMasterPageInitLoaded={this.state.isMasterPageInitLoaded}
          isMasterPageRecurringLoaded={this.state.isMasterPageRecurringLoaded}
          shouldTriggerPageInit={this.state.shouldTriggerPageInit}
          setShouldTriggerPageInit={this.setShouldTriggerPageInit}
        >
          <Spin spinning={this.state.spinnerCount > 0}>
            <div
              id="basvurular_body"
              style={
                {
                  backgroundColor: "rgba(244, 244, 244, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block",
                  overflow: "auto",
                  minHeight: "calc(100vh - 60px - 60px - 0px - 0px)",
                  maxHeight: "calc(100vh - 60px - 60px - 0px - 0px)",
                  height: "calc(100vh - 60px - 60px - 0px - 0px)"
                } as any
              }
            >
              <KRow
                id="851353"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="452869"
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <Label
                    id="669468"
                    value={ReactSystemFunctions.translate(this.ml, 669468, "value", this.defaultML)}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 5,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "18pt",
                        color: "rgba(10, 33, 64, 1)",
                        textTransform: "uppercase"
                      } as any
                    }
                  ></Label>
                </KCol>

                <KCol
                  id="506271"
                  xs={16}
                  sm={16}
                  md={16}
                  lg={16}
                  xl={16}
                  xxl={16}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "center",
                      textAlign: "-webkit-right",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KCol>
              </KRow>

              <KRow
                id="78433"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="155994"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <Form.Item className="kFormItem" name="basvurular_324400_value" noStyle>
                    <KTab
                      id="324400"
                      type="line"
                      size="default"
                      tabPosition="top"
                      selectedTabColor="#0078FF"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KTabItem
                        id="827906"
                        tab={this.state.BasvuruTabTitle?.at?.(0)?.egitmenTab ?? undefined}
                        forceRender={true}
                        key={827906}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KTable
                          id="179648"
                          kuikaRef={this.basvurular_179648_value_kuikaTableRef}
                          form={this.props.form}
                          dataSource={this.state.BasvuruEgitmenAll}
                          size="middle"
                          bordered={true}
                          showHeader={true}
                          loading={false}
                          nodatafoundmessage={ReactSystemFunctions.translate(
                            this.ml,
                            179648,
                            "nodatafoundmessage",
                            this.defaultML
                          )}
                          sorter={false}
                          pagination={true}
                          striped={false}
                          stripedColor="#F5F7FA"
                          insertRowActive={false}
                          searchable={false}
                          filtering={false}
                          transformedOnMobileResolution={false}
                          tableWidthMode="fit"
                          columnChooser={false}
                          fixedHeader={false}
                          pageSize={10}
                          showSizeChanger={false}
                          insertRowPosition="top"
                          multiSelect={false}
                          editable={false}
                          globalSearch={false}
                          resizableColumns={false}
                          showNoDataFound={true}
                          export={false}
                          multiSelectScope="page"
                          editableAlwaysActive={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KTableHeader
                            id="286926"
                            hideOnMobileResolution={false}
                            textDirection="Default"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></KTableHeader>

                          <KTableRow
                            id="88498"
                            hoverFontColor="red"
                            hoverBgColor="#F5F5F5"
                            style={
                              {
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KTableColumn
                              id="703389"
                              title={ReactSystemFunctions.translate(this.ml, 703389, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="735192"
                                value="[datafield:tamAd]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="231125"
                              title={ReactSystemFunctions.translate(this.ml, 231125, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="883562"
                                value="[datafield:meslek]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="790083"
                              title={ReactSystemFunctions.translate(this.ml, 790083, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="14917"
                                value="[datafield:email]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="739254"
                              title={ReactSystemFunctions.translate(this.ml, 739254, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="134188"
                                value="[datafield:egitimDuzey]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="169537"
                              title={ReactSystemFunctions.translate(this.ml, 169537, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="994758"
                                value="[datafield:tecrubeSure]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="278706"
                              title={ReactSystemFunctions.translate(this.ml, 278706, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  width: "40px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="348872"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.BasvurularComponent_348872_onClick();
                                }}
                                showCursorPointer
                                iconName="remove_red_eye"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(155, 155, 155, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>
                            </KTableColumn>
                          </KTableRow>
                        </KTable>
                      </KTabItem>

                      <KTabItem
                        id="559676"
                        tab={this.state.BasvuruTabTitle?.at?.(0)?.merkezTab ?? undefined}
                        forceRender={true}
                        key={559676}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KTable
                          id="36660"
                          kuikaRef={this.basvurular_36660_value_kuikaTableRef}
                          form={this.props.form}
                          dataSource={this.state.BasvuruMerkezAll}
                          size="middle"
                          bordered={true}
                          showHeader={true}
                          loading={false}
                          nodatafoundmessage={ReactSystemFunctions.translate(
                            this.ml,
                            36660,
                            "nodatafoundmessage",
                            this.defaultML
                          )}
                          searchable={false}
                          sorter={false}
                          filtering={false}
                          pagination={true}
                          striped={false}
                          stripedColor="#F5F7FA"
                          insertRowActive={false}
                          transformedOnMobileResolution={false}
                          tableWidthMode="fit"
                          columnChooser={false}
                          fixedHeader={false}
                          pageSize={10}
                          showSizeChanger={false}
                          insertRowPosition="top"
                          multiSelect={false}
                          editable={false}
                          globalSearch={false}
                          resizableColumns={false}
                          showNoDataFound={true}
                          export={false}
                          multiSelectScope="page"
                          editableAlwaysActive={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KTableHeader
                            id="88682"
                            hideOnMobileResolution={false}
                            textDirection="Default"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></KTableHeader>

                          <KTableRow
                            id="945928"
                            onClick={(e?: any) => {
                              if (!e?.currentTarget?.contains(e?.target)) return;
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.BasvurularComponent_945928_onClick();
                            }}
                            showCursorPointer
                            hoverFontColor="red"
                            hoverBgColor="#F5F5F5"
                            style={
                              {
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KTableColumn
                              id="522362"
                              title={ReactSystemFunctions.translate(this.ml, 522362, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="421842"
                                value="[datafield:merkezAdi]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="764173"
                              title={ReactSystemFunctions.translate(this.ml, 764173, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="812212"
                                value="[datafield:webAdres]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="617854"
                              title={ReactSystemFunctions.translate(this.ml, 617854, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="62191"
                                value="[datafield:adres]"
                                maxLine={1}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="885623"
                              title={ReactSystemFunctions.translate(this.ml, 885623, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="572568"
                                value="[datafield:tamAd]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="94843"
                              title={ReactSystemFunctions.translate(this.ml, 94843, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="539979"
                                value="[datafield:telefon]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="950837"
                              title={ReactSystemFunctions.translate(this.ml, 950837, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  width: "40px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="318519"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.BasvurularComponent_318519_onClick();
                                }}
                                showCursorPointer
                                iconName="remove_red_eye"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(155, 155, 155, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>
                            </KTableColumn>
                          </KTableRow>
                        </KTable>
                      </KTabItem>

                      <KTabItem
                        id="101024"
                        tab={this.state.BasvuruTabTitle?.at?.(0)?.kurumTab ?? undefined}
                        forceRender={true}
                        key={101024}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KTable
                          id="579324"
                          kuikaRef={this.basvurular_579324_value_kuikaTableRef}
                          form={this.props.form}
                          dataSource={this.state.BasvuruKurumAll}
                          size="middle"
                          bordered={true}
                          showHeader={true}
                          loading={false}
                          nodatafoundmessage={ReactSystemFunctions.translate(
                            this.ml,
                            579324,
                            "nodatafoundmessage",
                            this.defaultML
                          )}
                          sorter={false}
                          pagination={true}
                          striped={false}
                          stripedColor="#F5F7FA"
                          insertRowActive={false}
                          searchable={false}
                          filtering={false}
                          transformedOnMobileResolution={false}
                          tableWidthMode="fit"
                          columnChooser={false}
                          fixedHeader={false}
                          pageSize={10}
                          showSizeChanger={false}
                          insertRowPosition="top"
                          multiSelect={false}
                          editable={false}
                          globalSearch={false}
                          resizableColumns={false}
                          showNoDataFound={true}
                          export={false}
                          multiSelectScope="page"
                          editableAlwaysActive={false}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <KTableHeader
                            id="697010"
                            hideOnMobileResolution={false}
                            textDirection="Default"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "rgba(10, 33, 64, 1)",
                                lineHeight: "20px"
                              } as any
                            }
                          ></KTableHeader>

                          <KTableRow
                            id="679633"
                            onClick={(e?: any) => {
                              if (!e?.currentTarget?.contains(e?.target)) return;
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.BasvurularComponent_679633_onClick();
                            }}
                            showCursorPointer
                            hoverFontColor="red"
                            hoverBgColor="#F5F5F5"
                            style={
                              {
                                borderTopWidth: 1,
                                borderRightWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderColor: "rgba(218, 218, 218, 1)",
                                borderStyle: "solid",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <KTableColumn
                              id="477204"
                              title={ReactSystemFunctions.translate(this.ml, 477204, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="464774"
                                value="[datafield:kurumIsim]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="16754"
                              title={ReactSystemFunctions.translate(this.ml, 16754, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="125480"
                                value="[datafield:kurumWebAdresi]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="879424"
                              title={ReactSystemFunctions.translate(this.ml, 879424, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="84754"
                                value="[datafield:kurumAdres]"
                                maxLine={1}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="283040"
                              title={ReactSystemFunctions.translate(this.ml, 283040, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="919036"
                                value="[datafield:tamAd]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="937031"
                              title={ReactSystemFunctions.translate(this.ml, 937031, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Label
                                id="829987"
                                value="[datafield:cepTelefonu]"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "15px",
                                    color: "rgba(10, 33, 64, 1)"
                                  } as any
                                }
                              ></Label>
                            </KTableColumn>

                            <KTableColumn
                              id="721294"
                              title={ReactSystemFunctions.translate(this.ml, 721294, "title", this.defaultML)}
                              alwaysVisibleOnMobileResolution={false}
                              hideFiltering={false}
                              hideSorting={false}
                              hideSearch={false}
                              footerOptions="none"
                              footerFontSize={14}
                              footerFontColor="rgb(68, 68, 68)"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  width: "40px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            >
                              <Icon
                                id="89396"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.BasvurularComponent_89396_onClick();
                                }}
                                showCursorPointer
                                iconName="remove_red_eye"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "rgba(155, 155, 155, 1)",
                                    letterSpacing: "1px"
                                  } as any
                                }
                              ></Icon>
                            </KTableColumn>
                          </KTableRow>
                        </KTable>
                      </KTabItem>
                    </KTab>
                  </Form.Item>
                </KCol>
              </KRow>

              <KDrawer
                id="289749"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible289749: false }, () => {
                    this.callCallbackFunction(289749);
                  });
                }}
                visible={this.state.NavVisible289749}
                width="80vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible289749 && (
                    <BasvuruEgitmenDetay_Screen
                      onClose={() => {
                        this.setState({ NavVisible289749: false }, () => {
                          this.callCallbackFunction(289749);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 5,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KDrawer
                id="1365"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible1365: false }, () => {
                    this.callCallbackFunction(1365);
                  });
                }}
                visible={this.state.NavVisible1365}
                width="80vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible1365 && (
                    <BasvuruMerkezDetay_Screen
                      onClose={() => {
                        this.setState({ NavVisible1365: false }, () => {
                          this.callCallbackFunction(1365);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 5,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              ></KDrawer>

              <KDrawer
                id="862839"
                placement="right"
                onClose={() => {
                  this.setState({ NavVisible862839: false }, () => {
                    this.callCallbackFunction(862839);
                  });
                }}
                visible={this.state.NavVisible862839}
                width="80vw"
                height="100vh"
                push={true}
                children={
                  this.state.NavVisible862839 && (
                    <BasvuruKurumDetay_Screen
                      onClose={() => {
                        this.setState({ NavVisible862839: false }, () => {
                          this.callCallbackFunction(862839);
                        });
                      }}
                      screenInputs={window.kuika.navigationScreenInputs}
                    />
                  )
                }
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 5,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              ></KDrawer>
            </div>
          </Spin>
        </Master_Screen>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Basvurular_Screen)))))
);
export { tmp as Basvurular_Screen };
//export default tmp;
//export { tmp as Basvurular_Screen };
