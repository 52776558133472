import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITests_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITests_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  AltBilesenTestCount: any[];
  AltBilesenTestCount_dummy: any[];
  AltBilesenTestSearch: any[];
  AltBilesenTestSearch_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  DeleteRecord: number;
  DeleteRecord_dummy: number;

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class Tests_ScreenBase extends React.PureComponent<ITests_ScreenProps, any> {
  tests_399769_value_kuikaTableRef: React.RefObject<any>;
  tests_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "fa08c0fd-e9f9-43ab-a404-20a2dc6c5e68",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 810597, PropertyName: "value", Value: "TEST LİSTESİ" },
        { Id: 416063, PropertyName: "placeholder", Value: "Search..." },
        { Id: 140565, PropertyName: "label", Value: "YENİ" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 8203661, PropertyName: "title", Value: "Test Adı" },
        { Id: 9828083, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 489854, PropertyName: "title", Value: "Test Adı (Internal)" },
        { Id: 395571, PropertyName: "value", Value: "[datafield:taniminternal]" },
        { Id: 8783934, PropertyName: "title", Value: "Bileşen" },
        { Id: 3971944, PropertyName: "value", Value: "[datafield:bilesen]" },
        { Id: 301177, PropertyName: "title", Value: "Alt Bileşen" },
        { Id: 859476, PropertyName: "value", Value: "[datafield:altbilesen]" },
        { Id: 5352191, PropertyName: "title", Value: "Girdi Adedi" },
        { Id: 4086657, PropertyName: "value", Value: "[datafield:girdiadedi]" },
        { Id: 924270, PropertyName: "title", Value: "Ara Sonuç Adedi" },
        { Id: 57641, PropertyName: "value", Value: "[datafield:arasonucadedi]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.tests_399769_value_kuikaTableRef = React.createRef();
    this.tests_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      AltBilesenTestCount: [],
      AltBilesenTestSearch: [],
      SetValueOf: "",
      NAVIGATE: "",
      DeleteRecord: false,

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tests", "master_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.TestsPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tests", "master_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tests", "master_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TestsPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.TestsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  TestsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_416063_value", "value", "", "", "")),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_172599_value", "current", "", "", "")),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tests_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Tests/TestsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AltBilesenTestCount = result?.data.altBilesenTestCount;
    stateVars.AltBilesenTestSearch = result?.data.altBilesenTestSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestsPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.AltBilesenTestSearch;
    formVars.tests_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.AltBilesenTestCount?.length > 0
        ? stateVars.AltBilesenTestCount[0].count
        : this.state.AltBilesenTestCount?.length > 0
        ? this.state.AltBilesenTestCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TestsComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "tests_172599_value", 1, null);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestsComponent_416063_onPressEnter1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TestsComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_416063_value", "value", "", "", "")),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_172599_value", "current", "", "", "")),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tests_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Tests/TestsComponent_416063_onPressEnter1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AltBilesenTestCount = result?.data.altBilesenTestCount;
    stateVars.AltBilesenTestSearch = result?.data.altBilesenTestSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestsComponent_416063_onPressEnter2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestsComponent_416063_onPressEnter2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.AltBilesenTestSearch;
    formVars.tests_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.AltBilesenTestCount?.length > 0
        ? stateVars.AltBilesenTestCount[0].count
        : this.state.AltBilesenTestCount?.length > 0
        ? this.state.AltBilesenTestCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestsComponent_416063_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "tests_172599_value", 1, null);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestsComponent_416063_onBlur1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TestsComponent_416063_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_416063_value", "value", "", "", "")),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_172599_value", "current", "", "", "")),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tests_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Tests/TestsComponent_416063_onBlur1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AltBilesenTestCount = result?.data.altBilesenTestCount;
    stateVars.AltBilesenTestSearch = result?.data.altBilesenTestSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestsComponent_416063_onBlur2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestsComponent_416063_onBlur2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.AltBilesenTestSearch;
    formVars.tests_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.AltBilesenTestCount?.length > 0
        ? stateVars.AltBilesenTestCount[0].count
        : this.state.AltBilesenTestCount?.length > 0
        ? this.state.AltBilesenTestCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestsComponent_140565_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("TestF", "TestId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Tests",
      "TestF",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "443044",
      null,
      "right",
      null,
      "90vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestsComponent_935881_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TestF",
      "TestId",
      ReactSystemFunctions.value(this, "tests_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Tests",
      "TestF",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "481699",
      null,
      "right",
      null,
      "90vw",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestsComponent_314979_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "tests_399769_value", "id"),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_172599_value", "current", "", "", "")),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tests_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_416063_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "b8448927_0218_7935_0f34_497fb5e55406_confirmation",
        this.defaultML,
        "Kaydı silmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Tests/TestsComponent_314979_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeleteRecord = result?.data.deleteRecord_1;
      stateVars.AltBilesenTestSearch = result?.data.altBilesenTestSearch;

      stateVars.AltBilesenTestCount = result?.data.altBilesenTestCount;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TestsComponent_314979_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestsComponent_314979_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.AltBilesenTestSearch;
    formVars.tests_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.AltBilesenTestCount?.length > 0
        ? stateVars.AltBilesenTestCount[0].count
        : this.state.AltBilesenTestCount?.length > 0
        ? this.state.AltBilesenTestCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestsComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_416063_value", "value", "", "", "")),
        "string"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "tests_172599_value", "current", "", "", "")),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tests_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Tests/TestsComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AltBilesenTestCount = result?.data.altBilesenTestCount;
    stateVars.AltBilesenTestSearch = result?.data.altBilesenTestSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestsComponent_172599_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestsComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.AltBilesenTestSearch;
    formVars.tests_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.AltBilesenTestCount?.length > 0
        ? stateVars.AltBilesenTestCount[0].count
        : this.state.AltBilesenTestCount?.length > 0
        ? this.state.AltBilesenTestCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [443044, 481699] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TestsPageInit();
    }
  }
}
