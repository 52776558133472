import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IInvitations_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IInvitations_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  DavetiyeCount: any[];
  DavetiyeCount_dummy: any[];
  DavetiyeSearch: any[];
  DavetiyeSearch_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  DavetiyeDeleteById: number;
  DavetiyeDeleteById_dummy: number;
  isComp814413Visible: "visible" | "hidden";
  isComp103634Visible: "visible" | "hidden";
  isComp792509Visible: "visible" | "hidden";
  isComp860665Visible: "visible" | "hidden";
  isComp505853Visible: "visible" | "hidden";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class Invitations_ScreenBase extends React.PureComponent<IInvitations_ScreenProps, any> {
  invitations_399769_value_kuikaTableRef: React.RefObject<any>;
  invitations_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "INVITATIONS LISTS", TagName: "lblHead_value" },
        { Id: 416063, PropertyName: "placeholder", Value: "Search...", TagName: "txtSearch_placeholder" },
        { Id: 814413, PropertyName: "label", Value: "NEW", TagName: "btnNew_label" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "No data found.",
          TagName: "tblDavetiye_nodatafoundmessage"
        },
        { Id: 103634, PropertyName: "label", Value: "Appointment", TagName: "Button2_label" },
        { Id: 283425, PropertyName: "title", Value: "Invited", TagName: "TableColumn6_title" },
        { Id: 185521, PropertyName: "value", Value: "[datafield:davetli]", TagName: "Label5_0_value" },
        { Id: 250799, PropertyName: "title", Value: "Instructors included", TagName: "TableColumn11_title" },
        { Id: 780320, PropertyName: "value", Value: "[datafield:antrenorler]", TagName: "Label8_0_value" },
        { Id: 6110781, PropertyName: "title", Value: "Locations included", TagName: "clmKisitGym_title" },
        { Id: 6614873, PropertyName: "value", Value: "[datafield:merkezler]", TagName: "lblKisitGym_value" },
        { Id: 5979561, PropertyName: "title", Value: "Services included", TagName: "clmKisitServis_title" },
        { Id: 4384118, PropertyName: "value", Value: "[datafield:hizmetler]", TagName: "lblKisitServis_value" },
        { Id: 852669, PropertyName: "title", Value: "Credit", TagName: "TableColumn9_title" },
        { Id: 31622, PropertyName: "value", Value: "[datafield:kredi]", TagName: "Label7_0_value" },
        { Id: 4960833, PropertyName: "title", Value: "Will be payed by", TagName: "clmOdeyecekHesapId_title" },
        { Id: 8972996, PropertyName: "value", Value: "[datafield:hesap]", TagName: "lblOdeyecekHesapId_value" },
        { Id: 337504, PropertyName: "title", Value: "Appointment Date", TagName: "TableColumn8_title" },
        { Id: 152847, PropertyName: "value", Value: "[datafield:randevutarihi]", TagName: "Label6_0_value" },
        { Id: 923215, PropertyName: "title", Value: "Appointment Time", TagName: "TableColumn10_title" },
        { Id: 942908, PropertyName: "value", Value: "[datafield:randevusaati]", TagName: "Label7_0_value" },
        {
          Id: 27754560,
          PropertyName: "fe852656-41cb-0d69-f310-feba936ad060_confirmation",
          Value: "Are you sure you want to delete this record?",
          TagName: "DavetiyeDeleteById_confirmation",
          IsConfirmation: true
        }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "c75bad71-4413-44f5-925a-dc29e59fbe11",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "DAVETİYE LİSTESİ" },
        { Id: 416063, PropertyName: "placeholder", Value: "Arama..." },
        { Id: 814413, PropertyName: "label", Value: "YENİ" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 103634, PropertyName: "label", Value: "Randevu" },
        { Id: 283425, PropertyName: "title", Value: "Davetli" },
        { Id: 185521, PropertyName: "value", Value: "[datafield:davetli]" },
        { Id: 250799, PropertyName: "title", Value: "Geçerli Antrenörler" },
        { Id: 780320, PropertyName: "value", Value: "[datafield:antrenorler]" },
        { Id: 6110781, PropertyName: "title", Value: "Geçerli Merkezler" },
        { Id: 6614873, PropertyName: "value", Value: "[datafield:merkezler]" },
        { Id: 5979561, PropertyName: "title", Value: "Geçerli Hizmetler" },
        { Id: 4384118, PropertyName: "value", Value: "[datafield:hizmetler]" },
        { Id: 4960833, PropertyName: "title", Value: "Ödeyecek Hesap" },
        { Id: 8972996, PropertyName: "value", Value: "[datafield:hesap]" },
        { Id: 337504, PropertyName: "title", Value: "Randevu Tarihi" },
        { Id: 152847, PropertyName: "value", Value: "[datafield:randevutarihi]" },
        { Id: 923215, PropertyName: "title", Value: "Randevu Saati" },
        { Id: 942908, PropertyName: "value", Value: "[datafield:randevusaati]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.invitations_399769_value_kuikaTableRef = React.createRef();
    this.invitations_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      DavetiyeCount: [],
      DavetiyeSearch: [],
      SetValueOf: "",
      NAVIGATE: "",
      DavetiyeDeleteById: 0,
      isComp814413Visible: "hidden",
      isComp103634Visible: "hidden",
      isComp792509Visible: "hidden",
      isComp860665Visible: "hidden",
      isComp505853Visible: "hidden",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("invitations", "master_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.InvitationsPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("invitations", "master_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("invitations", "master_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.InvitationsPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.InvitationsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  InvitationsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentPage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pageSize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Invitations/InvitationsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.isComp814413Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].davetOlusturmaHakkiVarMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].davetOlusturmaHakkiVarMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DavetiyeCount = result?.data.davetiyeCount;
    stateVars.DavetiyeSearch = result?.data.davetiyeSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationsPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp814413Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].davetOlusturmaHakkiVarMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].davetOlusturmaHakkiVarMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_399769 = this.state.DavetiyeSearch;
    stateVars.isComp103634Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "invitations_399769_value", "randevuAlinabilir"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp792509Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "invitations_399769_value", "randevuTarihi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp860665Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "invitations_399769_value", "randevuAlinabilir"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505853Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "invitations_399769_value", "randevuAlinabilir"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.invitations_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.DavetiyeCount?.length > 0
        ? stateVars.DavetiyeCount[0].count
        : this.state.DavetiyeCount?.length > 0
        ? this.state.DavetiyeCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  InvitationsComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "invitations_172599_value", 1, null);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationsComponent_416063_onPressEnter1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  InvitationsComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Invitations/InvitationsComponent_416063_onPressEnter1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DavetiyeCount = result?.data.davetiyeCount;
    stateVars.DavetiyeSearch = result?.data.davetiyeSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationsComponent_416063_onPressEnter2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationsComponent_416063_onPressEnter2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.DavetiyeSearch;
    formVars.invitations_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.DavetiyeCount?.length > 0
        ? stateVars.DavetiyeCount[0].count
        : this.state.DavetiyeCount?.length > 0
        ? this.state.DavetiyeCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  InvitationsComponent_416063_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "invitations_172599_value", 1, null);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationsComponent_416063_onBlur1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  InvitationsComponent_416063_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Invitations/InvitationsComponent_416063_onBlur1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DavetiyeCount = result?.data.davetiyeCount;
    stateVars.DavetiyeSearch = result?.data.davetiyeSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationsComponent_416063_onBlur2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationsComponent_416063_onBlur2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.DavetiyeSearch;
    formVars.invitations_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.DavetiyeCount?.length > 0
        ? stateVars.DavetiyeCount[0].count
        : this.state.DavetiyeCount?.length > 0
        ? this.state.DavetiyeCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  InvitationsComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Invitation", "DavetiyeId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Invitation", "ShowDownload", false);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Invitations",
      "Invitation",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486227",
      null,
      "right",
      null,
      "450px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  InvitationsComponent_103634_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "invitations_399769_value", "davetEdenRol"),
        "Rol_Instructor"
      )
    ) {
      KuikaAppManager.showSpinner(this);

      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "RandevuFromEgitmen",
        "DavetiyeId",
        ReactSystemFunctions.value(this, "invitations_399769_value", "id")
      );
      KuikaAppManager.addToPageInputVariables(
        "RandevuFromEgitmen",
        "EgitmenId",
        ReactSystemFunctions.value(this, "invitations_399769_value", "davetEdenKisiId")
      );
      KuikaAppManager.addToPageInputVariables("RandevuFromEgitmen", "RandevuId", Guid.create().toString());
      KuikaAppManager.addToPageInputVariables(
        "RandevuFromEgitmen",
        "DanisanKisiId",
        ReactSystemFunctions.value(this, "invitations_399769_value", "davetliId")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.InvitationsComponent_103634_onClick1_,
        "Invitations",
        "RandevuFromEgitmen",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "45539",
        null,
        "right",
        null,
        "1200px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.InvitationsComponent_103634_onClick1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  InvitationsComponent_103634_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "invitations_399769_value", "davetEdenRol"),
        "Rol_Manager"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables(
        "RandevuFromMerkez",
        "DavetiyeId",
        ReactSystemFunctions.value(this, "invitations_399769_value", "id")
      );
      KuikaAppManager.addToPageInputVariables(
        "RandevuFromMerkez",
        "MerkezId",
        ReactSystemFunctions.value(this, "invitations_399769_value", "davetEdenKurumId")
      );
      KuikaAppManager.addToPageInputVariables("RandevuFromMerkez", "RandevuId", Guid.create().toString());
      KuikaAppManager.addToPageInputVariables(
        "RandevuFromMerkez",
        "DanisanKisiId",
        ReactSystemFunctions.value(this, "invitations_399769_value", "davetliId")
      );

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        undefined,
        "Invitations",
        "RandevuFromMerkez",
        "",
        "7d969ddf-5801-4eb6-b92b-12be35bdd565",
        "469965",
        null,
        "right",
        null,
        "1200px",
        "100vh",
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  InvitationsComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Invitation",
      "DavetiyeId",
      ReactSystemFunctions.value(this, "invitations_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("Invitation", "ShowDownload", false);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Invitations",
      "Invitation",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "450px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  InvitationsComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "invitations_399769_value", "id"),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentPage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pageSize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
        this.defaultML,
        "Are you sure you want to delete this record?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Invitations/InvitationsComponent_505853_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DavetiyeDeleteById = result?.data.davetiyeDeleteById;
      stateVars.DavetiyeCount = result?.data.davetiyeCount;
      stateVars.DavetiyeSearch = result?.data.davetiyeSearch;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.InvitationsComponent_505853_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationsComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.DavetiyeSearch;
    formVars.invitations_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.DavetiyeCount?.length > 0
        ? stateVars.DavetiyeCount[0].count
        : this.state.DavetiyeCount?.length > 0
        ? this.state.DavetiyeCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  InvitationsComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_416063_value", "value", "", "", "")
        ),
        "string"
      ),
      currentPage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "invitations_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Invitations/InvitationsComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DavetiyeCount = result?.data.davetiyeCount;
    stateVars.DavetiyeSearch = result?.data.davetiyeSearch;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.InvitationsComponent_172599_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  InvitationsComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.DavetiyeSearch;
    formVars.invitations_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.DavetiyeCount?.length > 0
        ? stateVars.DavetiyeCount[0].count
        : this.state.DavetiyeCount?.length > 0
        ? this.state.DavetiyeCount[0].count
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [486227, 45539, 469965, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.InvitationsPageInit();
    }
    if (diId == 45539) {
      isErrorOccurred = await this.InvitationsComponent_103634_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
