import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import { Button, Header, Icon, KCol, KRow, Label, TextArea, TextInput } from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { TestDegisken_ScreenBase } from "./testdegisken-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class TestDegisken_Screen extends TestDegisken_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              testdegisken_195614_value: this.state.TestDegiskenById?.at?.(0)?.degiskenTipi ?? undefined,
              testdegisken_361485_value: this.state.TestDegiskenById?.at?.(0)?.degiskenAdi ?? undefined,
              testdegisken_896060_value: this.state.TestDegiskenById?.at?.(0)?.formul ?? undefined
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="testdegisken_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 5,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              >
                <KRow
                  id="42706"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="677060"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="796540"
                      value={ReactSystemFunctions.translate(this.ml, 796540, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "18pt",
                          color: "rgba(10, 33, 64, 1)",
                          textTransform: "uppercase"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="434927"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Icon
                      id="107559"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.TestDegiskenComponent_107559_onClick();
                      }}
                      showCursorPointer
                      iconName="close"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="testdegisken_body"
                style={
                  {
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="444167"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="412875"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="547635"
                      value={ReactSystemFunctions.translate(this.ml, 547635, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="testdegisken_195614_value">
                      <KSelectBox
                        id="195614"
                        onChange={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.TestDegiskenComponent_195614_onChange();
                        }}
                        kuikaRef={this.testdegisken_195614_value_kuikaSelectBoxRef}
                        options={this.state.dataSource_195614}
                        placeholder={ReactSystemFunctions.translate(this.ml, 195614, "placeholder", this.defaultML)}
                        allowClear={false}
                        autoClearSearchValue={true}
                        showSearch={false}
                        onSearch={{}}
                        widthType="fill"
                        containsNullItem={false}
                        sortBy="none"
                        datavaluefield="key"
                        datatextfield="text"
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></KSelectBox>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KRow
                  id="311745"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="884088"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="732735"
                      value={ReactSystemFunctions.translate(this.ml, 732735, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="testdegisken_361485_value">
                      <TextInput
                        id="361485"
                        placeholder={ReactSystemFunctions.translate(this.ml, 361485, "placeholder", this.defaultML)}
                        allowClear={false}
                        bordered={true}
                        disabled={false}
                        type="text"
                        iconColor="rgba(0, 0, 0, 1)"
                        formatter="None"
                        isCharOnly={false}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></TextInput>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KRow
                  id="889801"
                  visibility={this.state.isComp889801Visible}
                  visibilityCondition={(rowData: object) =>
                    ReactSystemFunctions.isNotEqualTo(this.state.TestDegiskenById[0]?.degiskenTipi?.toString(), "Girdi")
                  }
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="606057"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="603989"
                      value={ReactSystemFunctions.translate(this.ml, 603989, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "block",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    ></Label>

                    <Form.Item className="kFormItem" name="testdegisken_896060_value">
                      <TextArea
                        id="896060"
                        placeholder={ReactSystemFunctions.translate(this.ml, 896060, "placeholder", this.defaultML)}
                        allowClear={false}
                        autoSize={false}
                        showCount={false}
                        formatter="None"
                        readOnly={false}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            width: "610px",
                            height: "200px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></TextArea>
                    </Form.Item>
                  </KCol>
                </KRow>

                <KRow
                  id="417723"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="34892"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>

                  <KCol
                    id="780063"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  ></KCol>

                  <KCol
                    id="486611"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Button
                      id="529918"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.TestDegiskenComponent_529918_onClick();
                      }}
                      showCursorPointer
                      label={ReactSystemFunctions.translate(this.ml, 529918, "label", this.defaultML)}
                      size="middle"
                      loading={false}
                      ghost={false}
                      block={false}
                      htmlType="button"
                      iconPosition="left"
                      danger={false}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(0, 190, 214, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 8,
                          paddingRight: 16,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          alignItems: "center",
                          textAlign: "-webkit-center",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "rgba(255, 255, 255, 1)",
                          lineHeight: "22px"
                        } as any
                      }
                    ></Button>
                  </KCol>
                </KRow>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(TestDegisken_Screen)))))
);
export { tmp as TestDegisken_Screen };
//export default tmp;
//export { tmp as TestDegisken_Screen };
