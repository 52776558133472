import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICheckupReport3_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICheckupReport3_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ComponentToPdf: string;
  ComponentToPdf_dummy: string;
  OpenPdf: any;
  OpenPdf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  CheckupById: any[];
  CheckupById_dummy: any[];
  KisiSelectById: any[];
  KisiSelectById_dummy: any[];
  Kirilim2SelectById: any[];
  Kirilim2SelectById_dummy: any[];
  ProtokolSelectById: any[];
  ProtokolSelectById_dummy: any[];
  ProtokolReportContentById: any[];
  ProtokolReportContentById_dummy: any[];
  ProtokolSinifSeviyeAciklamaBul: any[];
  ProtokolSinifSeviyeAciklamaBul_dummy: any[];
  KurumSelectById: any[];
  KurumSelectById_dummy: any[];
  CheckupDetaylari2: any[];
  CheckupDetaylari2_dummy: any[];
  CheckupDetaylari3: any[];
  CheckupDetaylari3_dummy: any[];
  CheckupDetaylari4: any[];
  CheckupDetaylari4_dummy: any[];
  CheckupDetaylariGraph: any[];
  CheckupDetaylariGraph_dummy: any[];
  ChartLevels: any[];
  ChartLevels_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  CheckupDetaylari31: any[];
  CheckupDetaylari31_dummy: any[];
  CheckupDetaylari32: any[];
  CheckupDetaylari32_dummy: any[];
  CheckupDetaylari33: any[];
  CheckupDetaylari33_dummy: any[];
  CheckupDetaylari34: any[];
  CheckupDetaylari34_dummy: any[];
  CheckupDetaylari35: any[];
  CheckupDetaylari35_dummy: any[];
  isComp389070Visible: "visible" | "hidden";
  isComp643143Visible: "visible" | "hidden";
  isComp164304Visible: "visible" | "hidden";
  isComp694894Visible: "visible" | "hidden";
  isComp442339Visible: "visible" | "hidden";
  isComp954556Visible: "visible" | "hidden";
  isComp933266Visible: "visible" | "hidden";
  isComp968453Visible: "visible" | "hidden";
  isComp137834Visible: "visible" | "hidden";
  isComp998536Visible: "visible" | "hidden";
  isComp518249Visible: "visible" | "hidden";
  isComp680611Visible: "visible" | "hidden";
  isComp785960Visible: "visible" | "hidden";
  isComp999688Visible: "visible" | "hidden";
  isComp260596Visible: "visible" | "hidden";
  isComp835917Visible: "visible" | "hidden";
  isComp336879Visible: "visible" | "hidden";
  isComp205412Visible: "visible" | "hidden";
}

export class CheckupReport3_ScreenBase extends React.PureComponent<ICheckupReport3_ScreenProps, any> {
  checkupreport3_442621_value_kuikaTableRef: React.RefObject<any>;
  checkupreport3_65668_value_kuikaTableRef: React.RefObject<any>;
  checkupreport3_916171_value_kuikaTableRef: React.RefObject<any>;
  checkupreport3_573761_value_kuikaTableRef: React.RefObject<any>;
  checkupreport3_79472_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 106566, PropertyName: "value", Value: "Name Surname", TagName: "Label0_0_value" },
        { Id: 933266, PropertyName: "value", Value: "Age:", TagName: "Label0_0_value" },
        { Id: 161339, PropertyName: "value", Value: "4/B", TagName: "Label0_1_value" },
        { Id: 425168, PropertyName: "value", Value: "Bilim Yasam Schools", TagName: "Label0_1_value" },
        { Id: 775226, PropertyName: "value", Value: "Report Date:", TagName: "Label0_0_value" },
        { Id: 726038, PropertyName: "value", Value: "FITNESS COMPONENT DETAILS", TagName: "Label0_0_value" },
        {
          Id: 442621,
          PropertyName: "nodatafoundmessage",
          Value: "No data found.",
          TagName: "Table1_nodatafoundmessage"
        },
        { Id: 967050, PropertyName: "title", Value: "Title", TagName: "TableTemplateColumn1_0_title" },
        { Id: 41680, PropertyName: "value", Value: "[datafield:component]", TagName: "Label0_0_value" },
        { Id: 794999, PropertyName: "value", Value: ":", TagName: "Label2_value" },
        { Id: 520599, PropertyName: "value", Value: "[datafield:puan]", TagName: "Label0_1_value" },
        { Id: 736702, PropertyName: "value", Value: "[datafield:sinifadi]", TagName: "Label0_0_value" },
        { Id: 733330, PropertyName: "value", Value: "[datafield:raporaciklama]", TagName: "Label0_5_value" },
        { Id: 35831, PropertyName: "value", Value: "[datafield:testraporsonuccumlesi]", TagName: "Label0_2_value" },
        {
          Id: 882954,
          PropertyName: "value",
          Value: "[datafield:bilesenpuansiniflandirmaaciklama]",
          TagName: "Label0_8_value"
        },
        { Id: 401691, PropertyName: "value", Value: "Instructor's Mark:", TagName: "Label0_0_value" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "02c7dc49-bb7e-4464-9214-9cb9788aa0de",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 106566, PropertyName: "value", Value: "Adı Soyadı :" },
        { Id: 213939, PropertyName: "value", Value: ":" },
        { Id: 933266, PropertyName: "value", Value: "Yaş:" },
        { Id: 396751, PropertyName: "value", Value: "9" },
        { Id: 531097, PropertyName: "value", Value: ":" },
        { Id: 775226, PropertyName: "value", Value: "Rapor Tarihi :" },
        { Id: 726038, PropertyName: "value", Value: "FitSkor® Bileşen Detayları" },
        { Id: 500197, PropertyName: "value", Value: "FitSkor® Bileşen Detayları" },
        { Id: 419295, PropertyName: "value", Value: "2. Sayfa" },
        { Id: 442621, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 967050, PropertyName: "title", Value: "Title" },
        { Id: 41680, PropertyName: "value", Value: "[datafield:bilesen]" },
        { Id: 736702, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 733330, PropertyName: "value", Value: "[datafield:raporaciklama]" },
        { Id: 35831, PropertyName: "value", Value: "[datafield:testraporsonuccumlesi]" },
        { Id: 882954, PropertyName: "value", Value: "[datafield:bilesenpuansiniflandirmaaciklama]" },
        { Id: 865905, PropertyName: "value", Value: "Eğitmen Notu:" },
        { Id: 160327, PropertyName: "value", Value: "FitSkor® Bileşen Detayları" },
        { Id: 828879, PropertyName: "value", Value: "FitSkor® Bileşen Detayları" },
        { Id: 39172, PropertyName: "value", Value: "3. Sayfa" },
        { Id: 65668, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 217065, PropertyName: "title", Value: "Title" },
        { Id: 36792, PropertyName: "value", Value: "[datafield:bilesen]" },
        { Id: 75974, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 787978, PropertyName: "value", Value: "[datafield:raporaciklama]" },
        { Id: 272749, PropertyName: "value", Value: "[datafield:testraporsonuccumlesi]" },
        { Id: 712587, PropertyName: "value", Value: "[datafield:bilesenpuansiniflandirmaaciklama]" },
        { Id: 850501, PropertyName: "value", Value: "Eğitmen Notu:" },
        { Id: 887911, PropertyName: "value", Value: "FitSkor® Bileşen Detayları" },
        { Id: 756566, PropertyName: "value", Value: "FitSkor® Bileşen Detayları" },
        { Id: 259172, PropertyName: "value", Value: "4. Sayfa" },
        { Id: 916171, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 378715, PropertyName: "title", Value: "Title" },
        { Id: 77559, PropertyName: "value", Value: "[datafield:bilesen]" },
        { Id: 145403, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 949077, PropertyName: "value", Value: "[datafield:raporaciklama]" },
        { Id: 896932, PropertyName: "value", Value: "[datafield:testraporsonuccumlesi]" },
        { Id: 714590, PropertyName: "value", Value: "[datafield:bilesenpuansiniflandirmaaciklama]" },
        { Id: 401691, PropertyName: "value", Value: "Eğitmen Notu:" },
        { Id: 364747, PropertyName: "value", Value: "FitSkor® Bileşen Detayları" },
        { Id: 487729, PropertyName: "value", Value: "FitSkor® Bileşen Detayları" },
        { Id: 472338, PropertyName: "value", Value: "5. Sayfa" },
        { Id: 573761, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 957534, PropertyName: "title", Value: "Title" },
        { Id: 800076, PropertyName: "value", Value: "[datafield:bilesen]" },
        { Id: 106763, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 71440, PropertyName: "value", Value: "[datafield:raporaciklama]" },
        { Id: 482289, PropertyName: "value", Value: "[datafield:testraporsonuccumlesi]" },
        { Id: 427463, PropertyName: "value", Value: "[datafield:bilesenpuansiniflandirmaaciklama]" },
        { Id: 939147, PropertyName: "value", Value: "Eğitmen Notu:" },
        { Id: 530567, PropertyName: "value", Value: "FitSkor® Bileşen Detayları" },
        { Id: 674666, PropertyName: "value", Value: "FitSkor® Bileşen Detayları" },
        { Id: 477866, PropertyName: "value", Value: "6. Sayfa" },
        { Id: 79472, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 249422, PropertyName: "title", Value: "Title" },
        { Id: 874075, PropertyName: "value", Value: "[datafield:bilesen]" },
        { Id: 951054, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 567546, PropertyName: "value", Value: "[datafield:raporaciklama]" },
        { Id: 141298, PropertyName: "value", Value: "[datafield:testraporsonuccumlesi]" },
        { Id: 907619, PropertyName: "value", Value: "[datafield:bilesenpuansiniflandirmaaciklama]" },
        { Id: 424259, PropertyName: "value", Value: "Eğitmen Notu:" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.checkupreport3_442621_value_kuikaTableRef = React.createRef();
    this.checkupreport3_65668_value_kuikaTableRef = React.createRef();
    this.checkupreport3_916171_value_kuikaTableRef = React.createRef();
    this.checkupreport3_573761_value_kuikaTableRef = React.createRef();
    this.checkupreport3_79472_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ComponentToPdf: "",
      OpenPdf: "",
      GoBack: "",
      MyContext: [],
      CheckupById: [],
      KisiSelectById: [],
      Kirilim2SelectById: [],
      ProtokolSelectById: [],
      ProtokolReportContentById: [],
      ProtokolSinifSeviyeAciklamaBul: [],
      KurumSelectById: [],
      CheckupDetaylari2: [],
      CheckupDetaylari3: [],
      CheckupDetaylari4: [],
      CheckupDetaylariGraph: [],
      ChartLevels: [],
      ChangeVisibilityOf: "",
      CheckupDetaylari31: [],
      CheckupDetaylari32: [],
      CheckupDetaylari33: [],
      CheckupDetaylari34: [],
      CheckupDetaylari35: [],
      isComp389070Visible: "hidden",
      isComp643143Visible: "hidden",
      isComp164304Visible: "hidden",
      isComp694894Visible: "hidden",
      isComp442339Visible: "hidden",
      isComp954556Visible: "hidden",
      isComp933266Visible: "hidden",
      isComp968453Visible: "hidden",
      isComp137834Visible: "hidden",
      isComp998536Visible: "hidden",
      isComp518249Visible: "hidden",
      isComp680611Visible: "hidden",
      isComp785960Visible: "hidden",
      isComp999688Visible: "hidden",
      isComp260596Visible: "hidden",
      isComp835917Visible: "hidden",
      isComp336879Visible: "hidden",
      isComp205412Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("checkupreport3", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.CheckupReport3PageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("checkupreport3", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("checkupreport3", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.CheckupReport3PageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      checkupreport3_954556_value: this.state.KurumSelectById?.at?.(0)?.logo ?? undefined,
      checkupreport3_626759_value: this.state.ProtokolReportContentById?.at?.(0)?.moreInfoQR ?? undefined,
      checkupreport3_577506_value: this.state.ProtokolReportContentById?.at?.(0)?.moreInfoQR ?? undefined,
      checkupreport3_13060_value: this.state.ProtokolReportContentById?.at?.(0)?.moreInfoQR ?? undefined,
      checkupreport3_959656_value: this.state.ProtokolReportContentById?.at?.(0)?.moreInfoQR ?? undefined,
      checkupreport3_652400_value: this.state.ProtokolReportContentById?.at?.(0)?.moreInfoQR ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  CheckupReport3PageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_9: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_10: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_11: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      olcumId_12: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupReport3/CheckupReport3PageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.checkupreport3_920538_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );
    stateVars.CheckupById = result?.data.checkupById;
    formVars.checkupreport3_968453_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].yas
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].yas
        : null
    );
    formVars.checkupreport3_133156_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].checkupTarihi
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].checkupTarihi
        : null
    );
    formVars.checkupreport3_628396_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].egitmeninNotu
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].egitmeninNotu
        : null
    );
    formVars.checkupreport3_270627_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].egitmeninNotu
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].egitmeninNotu
        : null
    );
    formVars.checkupreport3_569367_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].egitmeninNotu
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].egitmeninNotu
        : null
    );
    formVars.checkupreport3_952785_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].egitmeninNotu
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].egitmeninNotu
        : null
    );
    formVars.checkupreport3_636881_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].egitmeninNotu
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].egitmeninNotu
        : null
    );
    stateVars.KisiSelectById = result?.data.kisiSelectById;
    formVars.checkupreport3_144112_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );
    formVars.checkupreport3_211390_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );
    stateVars.Kirilim2SelectById = result?.data.kirilim2SelectById;
    formVars.checkupreport3_161339_value = ReactSystemFunctions.toString(
      this,
      stateVars.Kirilim2SelectById?.length > 0
        ? stateVars.Kirilim2SelectById[0].tanim
        : this.state.Kirilim2SelectById?.length > 0
        ? this.state.Kirilim2SelectById[0].tanim
        : null
    );
    stateVars.ProtokolSelectById = result?.data.protokolSelectById;
    stateVars.ProtokolReportContentById = result?.data.protokolReportContentById;
    formVars.checkupreport3_753275_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].reportH1
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].reportH1
        : null
    );
    formVars.checkupreport3_838293_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].reportH2
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].reportH2
        : null
    );
    formVars.checkupreport3_645805_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].introductionHeader
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].introductionHeader
        : null
    );
    formVars.checkupreport3_38974_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].introductionContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].introductionContent
        : null
    );
    formVars.checkupreport3_91382_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].graphHeader
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].graphHeader
        : null
    );
    formVars.checkupreport3_555177_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].resultSummaryHeader
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].resultSummaryHeader
        : null
    );
    formVars.checkupreport3_155775_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoContent
        : null
    );
    formVars.checkupreport3_709731_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoLink
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoLink
        : null
    );
    formVars.checkupreport3_626759_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoQR
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoQR
        : null
    );
    formVars.checkupreport3_626759_placeholderimage = "";
    formVars.checkupreport3_70605_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoContent
        : null
    );
    formVars.checkupreport3_800227_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoLink
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoLink
        : null
    );
    formVars.checkupreport3_577506_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoQR
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoQR
        : null
    );
    formVars.checkupreport3_577506_placeholderimage = "";
    formVars.checkupreport3_77306_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoContent
        : null
    );
    formVars.checkupreport3_396130_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoLink
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoLink
        : null
    );
    formVars.checkupreport3_13060_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoQR
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoQR
        : null
    );
    formVars.checkupreport3_13060_placeholderimage = "";
    formVars.checkupreport3_730592_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoContent
        : null
    );
    formVars.checkupreport3_628481_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoLink
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoLink
        : null
    );
    formVars.checkupreport3_959656_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoQR
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoQR
        : null
    );
    formVars.checkupreport3_959656_placeholderimage = "";
    formVars.checkupreport3_789984_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoContent
        : null
    );
    formVars.checkupreport3_252835_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoLink
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoLink
        : null
    );
    formVars.checkupreport3_652400_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoQR
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoQR
        : null
    );
    formVars.checkupreport3_652400_placeholderimage = "";
    stateVars.ProtokolSinifSeviyeAciklamaBul = result?.data.protokolSinifSeviyeAciklamaBul;
    formVars.checkupreport3_462819_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolSinifSeviyeAciklamaBul?.length > 0
        ? stateVars.ProtokolSinifSeviyeAciklamaBul[0].raporAciklama
        : this.state.ProtokolSinifSeviyeAciklamaBul?.length > 0
        ? this.state.ProtokolSinifSeviyeAciklamaBul[0].raporAciklama
        : null
    );
    stateVars.KurumSelectById = result?.data.kurumSelectById;
    formVars.checkupreport3_954556_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].logo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].logo
        : null
    );
    formVars.checkupreport3_710186_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].tanim
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].tanim
        : null
    );
    formVars.checkupreport3_511634_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].unvani
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].unvani
        : null
    );
    stateVars.isComp954556Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].logo
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].logo
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp933266Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].kurumTipi
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].kurumTipi
            : null
        ),
        "KT_Gymn"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp968453Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].kurumTipi
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].kurumTipi
            : null
        ),
        "KT_Gymn"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp137834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].organizasyonelKirilimVarMi
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].organizasyonelKirilimVarMi
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.CheckupDetaylari2 = result?.data.checkupDetaylari2;
    stateVars.CheckupDetaylari3 = result?.data.checkupDetaylari3;
    stateVars.isComp205412Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari3?.length > 0
            ? stateVars.CheckupDetaylari3[0].kayitSayisi
            : this.state.CheckupDetaylari3?.length > 0
            ? this.state.CheckupDetaylari3[0].kayitSayisi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.CheckupDetaylari4 = result?.data.checkupDetaylari4;
    stateVars.CheckupDetaylariGraph = result?.data.checkupDetaylariGraph;
    stateVars.ChartLevels = result?.data.chartLevels;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupReport3PageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupReport3PageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari?.length > 0
            ? stateVars.CheckupDetaylari[0].kayitSayisi
            : this.state.CheckupDetaylari?.length > 0
            ? this.state.CheckupDetaylari[0].kayitSayisi
            : null
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp389070Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupReport3PageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari2?.length > 0
            ? stateVars.CheckupDetaylari2[0].kayitSayisi
            : this.state.CheckupDetaylari2?.length > 0
            ? this.state.CheckupDetaylari2[0].kayitSayisi
            : null
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp389070Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupReport3PageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari?.length > 0
            ? stateVars.CheckupDetaylari[0].kayitSayisi
            : this.state.CheckupDetaylari?.length > 0
            ? this.state.CheckupDetaylari[0].kayitSayisi
            : null
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp643143Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupReport3PageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari2?.length > 0
            ? stateVars.CheckupDetaylari2[0].kayitSayisi
            : this.state.CheckupDetaylari2?.length > 0
            ? this.state.CheckupDetaylari2[0].kayitSayisi
            : null
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp643143Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupReport3PageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari2?.length > 0
            ? stateVars.CheckupDetaylari2[0].kayitSayisi
            : this.state.CheckupDetaylari2?.length > 0
            ? this.state.CheckupDetaylari2[0].kayitSayisi
            : null
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp164304Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupReport3PageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari3?.length > 0
            ? stateVars.CheckupDetaylari3[0].kayitSayisi
            : this.state.CheckupDetaylari3?.length > 0
            ? this.state.CheckupDetaylari3[0].kayitSayisi
            : null
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp164304Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupReport3PageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari2?.length > 0
            ? stateVars.CheckupDetaylari2[0].kayitSayisi
            : this.state.CheckupDetaylari2?.length > 0
            ? this.state.CheckupDetaylari2[0].kayitSayisi
            : null
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp694894Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupReport3PageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari3?.length > 0
            ? stateVars.CheckupDetaylari3[0].kayitSayisi
            : this.state.CheckupDetaylari3?.length > 0
            ? this.state.CheckupDetaylari3[0].kayitSayisi
            : null
        ),
        "3"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp694894Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.CheckupReport3PageInit9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  CheckupReport3PageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      checkupId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      ),
      checkupId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CheckupId ?? this.props.screenInputs.checkupid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "CheckupReport3/CheckupReport3PageInit9_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupDetaylari31 = result?.data.checkupDetaylari31;

    stateVars.isComp998536Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari31?.length > 0
            ? stateVars.CheckupDetaylari31[0].kayitSayisi
            : this.state.CheckupDetaylari31?.length > 0
            ? this.state.CheckupDetaylari31[0].kayitSayisi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp518249Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari31?.length > 0
            ? stateVars.CheckupDetaylari31[0].kayitSayisi
            : this.state.CheckupDetaylari31?.length > 0
            ? this.state.CheckupDetaylari31[0].kayitSayisi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp680611Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari31?.length > 0
            ? stateVars.CheckupDetaylari31[0].kayitSayisi
            : this.state.CheckupDetaylari31?.length > 0
            ? this.state.CheckupDetaylari31[0].kayitSayisi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.CheckupDetaylari32 = result?.data.checkupDetaylari32;

    stateVars.isComp389070Visible =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari32?.length > 0
            ? stateVars.CheckupDetaylari32[0].kayitSayisi
            : this.state.CheckupDetaylari32?.length > 0
            ? this.state.CheckupDetaylari32[0].kayitSayisi
            : null
        ),
        "2"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.CheckupDetaylari32?.length > 0
              ? stateVars.CheckupDetaylari32[0].kayitSayisi
              : this.state.CheckupDetaylari32?.length > 0
              ? this.state.CheckupDetaylari32[0].kayitSayisi
              : null
          ),
          "3"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp643143Visible =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari32?.length > 0
            ? stateVars.CheckupDetaylari32[0].kayitSayisi
            : this.state.CheckupDetaylari32?.length > 0
            ? this.state.CheckupDetaylari32[0].kayitSayisi
            : null
        ),
        "2"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.CheckupDetaylari32?.length > 0
              ? stateVars.CheckupDetaylari32[0].kayitSayisi
              : this.state.CheckupDetaylari32?.length > 0
              ? this.state.CheckupDetaylari32[0].kayitSayisi
              : null
          ),
          "3"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp785960Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari32?.length > 0
            ? stateVars.CheckupDetaylari32[0].kayitSayisi
            : this.state.CheckupDetaylari32?.length > 0
            ? this.state.CheckupDetaylari32[0].kayitSayisi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.CheckupDetaylari33 = result?.data.checkupDetaylari33;

    stateVars.isComp164304Visible =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari33?.length > 0
            ? stateVars.CheckupDetaylari33[0].kayitSayisi
            : this.state.CheckupDetaylari33?.length > 0
            ? this.state.CheckupDetaylari33[0].kayitSayisi
            : null
        ),
        "2"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.CheckupDetaylari33?.length > 0
              ? stateVars.CheckupDetaylari33[0].kayitSayisi
              : this.state.CheckupDetaylari33?.length > 0
              ? this.state.CheckupDetaylari33[0].kayitSayisi
              : null
          ),
          "3"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp694894Visible =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari33?.length > 0
            ? stateVars.CheckupDetaylari33[0].kayitSayisi
            : this.state.CheckupDetaylari33?.length > 0
            ? this.state.CheckupDetaylari33[0].kayitSayisi
            : null
        ),
        "2"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.CheckupDetaylari33?.length > 0
              ? stateVars.CheckupDetaylari33[0].kayitSayisi
              : this.state.CheckupDetaylari33?.length > 0
              ? this.state.CheckupDetaylari33[0].kayitSayisi
              : null
          ),
          "3"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp999688Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari33?.length > 0
            ? stateVars.CheckupDetaylari33[0].kayitSayisi
            : this.state.CheckupDetaylari33?.length > 0
            ? this.state.CheckupDetaylari33[0].kayitSayisi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.CheckupDetaylari34 = result?.data.checkupDetaylari34;

    stateVars.isComp260596Visible =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari34?.length > 0
            ? stateVars.CheckupDetaylari34[0].kayitSayisi
            : this.state.CheckupDetaylari34?.length > 0
            ? this.state.CheckupDetaylari34[0].kayitSayisi
            : null
        ),
        "2"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.CheckupDetaylari34?.length > 0
              ? stateVars.CheckupDetaylari34[0].kayitSayisi
              : this.state.CheckupDetaylari34?.length > 0
              ? this.state.CheckupDetaylari34[0].kayitSayisi
              : null
          ),
          "3"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp835917Visible =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari34?.length > 0
            ? stateVars.CheckupDetaylari34[0].kayitSayisi
            : this.state.CheckupDetaylari34?.length > 0
            ? this.state.CheckupDetaylari34[0].kayitSayisi
            : null
        ),
        "2"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.CheckupDetaylari34?.length > 0
              ? stateVars.CheckupDetaylari34[0].kayitSayisi
              : this.state.CheckupDetaylari34?.length > 0
              ? this.state.CheckupDetaylari34[0].kayitSayisi
              : null
          ),
          "3"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp336879Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari34?.length > 0
            ? stateVars.CheckupDetaylari34[0].kayitSayisi
            : this.state.CheckupDetaylari34?.length > 0
            ? this.state.CheckupDetaylari34[0].kayitSayisi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.CheckupDetaylari35 = result?.data.checkupDetaylari35;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.CheckupReport3PageInit10_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  CheckupReport3PageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp442339Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.ShowDownload ?? this.props.screenInputs.showdownload,
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_753275_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].reportH1
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].reportH1
        : null
    );

    formVars.checkupreport3_838293_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].reportH2
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].reportH2
        : null
    );

    formVars.checkupreport3_954556_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].logo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].logo
        : null
    );

    stateVars.isComp954556Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].logo
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].logo
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_710186_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].tanim
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].tanim
        : null
    );

    formVars.checkupreport3_144112_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );

    formVars.checkupreport3_211390_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );

    formVars.checkupreport3_511634_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].unvani
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].unvani
        : null
    );

    stateVars.isComp933266Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].kurumTipi
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].kurumTipi
            : null
        ),
        "KT_Gymn"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_968453_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].yas
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].yas
        : null
    );

    stateVars.isComp968453Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].kurumTipi
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].kurumTipi
            : null
        ),
        "KT_Gymn"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp137834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].organizasyonelKirilimVarMi
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].organizasyonelKirilimVarMi
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_920538_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );

    formVars.checkupreport3_161339_value = ReactSystemFunctions.toString(
      this,
      stateVars.Kirilim2SelectById?.length > 0
        ? stateVars.Kirilim2SelectById[0].tanim
        : this.state.Kirilim2SelectById?.length > 0
        ? this.state.Kirilim2SelectById[0].tanim
        : null
    );

    formVars.checkupreport3_133156_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].checkupTarihi
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].checkupTarihi
        : null
    );

    formVars.checkupreport3_645805_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].introductionHeader
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].introductionHeader
        : null
    );

    formVars.checkupreport3_38974_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].introductionContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].introductionContent
        : null
    );

    formVars.checkupreport3_91382_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].graphHeader
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].graphHeader
        : null
    );

    stateVars.dataSource_761662 = this.state.CheckupDetaylariGraph;
    stateVars.dataSource_761662 = this.state.CheckupDetaylariGraph;
    formVars.checkupreport3_155443_text = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].fitSkor
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].fitSkor
        : null
    );

    formVars.checkupreport3_555177_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].resultSummaryHeader
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].resultSummaryHeader
        : null
    );

    formVars.checkupreport3_462819_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolSinifSeviyeAciklamaBul?.length > 0
        ? stateVars.ProtokolSinifSeviyeAciklamaBul[0].raporAciklama
        : this.state.ProtokolSinifSeviyeAciklamaBul?.length > 0
        ? this.state.ProtokolSinifSeviyeAciklamaBul[0].raporAciklama
        : null
    );

    stateVars.dataSource_442621 = this.state.CheckupDetaylari31;

    stateVars.isComp998536Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari31?.length > 0
            ? stateVars.CheckupDetaylari31[0].kayitSayisi
            : this.state.CheckupDetaylari31?.length > 0
            ? this.state.CheckupDetaylari31[0].kayitSayisi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_628396_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].egitmeninNotu
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].egitmeninNotu
        : null
    );

    stateVars.isComp518249Visible =
      ReactSystemFunctions.isLessThan(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari31?.length > 0
            ? stateVars.CheckupDetaylari31[0].kayitSayisi
            : this.state.CheckupDetaylari31?.length > 0
            ? this.state.CheckupDetaylari31[0].kayitSayisi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_155775_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoContent
        : null
    );

    formVars.checkupreport3_709731_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoLink
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoLink
        : null
    );

    formVars.checkupreport3_626759_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoQR
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoQR
        : null
    );

    stateVars.isComp680611Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari31?.length > 0
            ? stateVars.CheckupDetaylari31[0].kayitSayisi
            : this.state.CheckupDetaylari31?.length > 0
            ? this.state.CheckupDetaylari31[0].kayitSayisi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_65668 = this.state.CheckupDetaylari32;

    stateVars.isComp389070Visible =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari32?.length > 0
            ? stateVars.CheckupDetaylari32[0].kayitSayisi
            : this.state.CheckupDetaylari32?.length > 0
            ? this.state.CheckupDetaylari32[0].kayitSayisi
            : null
        ),
        "2"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.CheckupDetaylari32?.length > 0
              ? stateVars.CheckupDetaylari32[0].kayitSayisi
              : this.state.CheckupDetaylari32?.length > 0
              ? this.state.CheckupDetaylari32[0].kayitSayisi
              : null
          ),
          "3"
        )) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_270627_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].egitmeninNotu
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].egitmeninNotu
        : null
    );

    stateVars.isComp643143Visible =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari32?.length > 0
            ? stateVars.CheckupDetaylari32[0].kayitSayisi
            : this.state.CheckupDetaylari32?.length > 0
            ? this.state.CheckupDetaylari32[0].kayitSayisi
            : null
        ),
        "2"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.CheckupDetaylari32?.length > 0
              ? stateVars.CheckupDetaylari32[0].kayitSayisi
              : this.state.CheckupDetaylari32?.length > 0
              ? this.state.CheckupDetaylari32[0].kayitSayisi
              : null
          ),
          "3"
        )) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_70605_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoContent
        : null
    );

    formVars.checkupreport3_800227_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoLink
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoLink
        : null
    );

    formVars.checkupreport3_577506_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoQR
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoQR
        : null
    );

    stateVars.isComp785960Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari32?.length > 0
            ? stateVars.CheckupDetaylari32[0].kayitSayisi
            : this.state.CheckupDetaylari32?.length > 0
            ? this.state.CheckupDetaylari32[0].kayitSayisi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_916171 = this.state.CheckupDetaylari33;

    stateVars.isComp164304Visible =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari33?.length > 0
            ? stateVars.CheckupDetaylari33[0].kayitSayisi
            : this.state.CheckupDetaylari33?.length > 0
            ? this.state.CheckupDetaylari33[0].kayitSayisi
            : null
        ),
        "2"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.CheckupDetaylari33?.length > 0
              ? stateVars.CheckupDetaylari33[0].kayitSayisi
              : this.state.CheckupDetaylari33?.length > 0
              ? this.state.CheckupDetaylari33[0].kayitSayisi
              : null
          ),
          "3"
        )) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_569367_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].egitmeninNotu
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].egitmeninNotu
        : null
    );

    stateVars.isComp694894Visible =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari33?.length > 0
            ? stateVars.CheckupDetaylari33[0].kayitSayisi
            : this.state.CheckupDetaylari33?.length > 0
            ? this.state.CheckupDetaylari33[0].kayitSayisi
            : null
        ),
        "2"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.CheckupDetaylari33?.length > 0
              ? stateVars.CheckupDetaylari33[0].kayitSayisi
              : this.state.CheckupDetaylari33?.length > 0
              ? this.state.CheckupDetaylari33[0].kayitSayisi
              : null
          ),
          "3"
        )) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_77306_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoContent
        : null
    );

    formVars.checkupreport3_396130_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoLink
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoLink
        : null
    );

    formVars.checkupreport3_13060_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoQR
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoQR
        : null
    );

    stateVars.isComp999688Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari33?.length > 0
            ? stateVars.CheckupDetaylari33[0].kayitSayisi
            : this.state.CheckupDetaylari33?.length > 0
            ? this.state.CheckupDetaylari33[0].kayitSayisi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_573761 = this.state.CheckupDetaylari34;

    stateVars.isComp260596Visible =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari34?.length > 0
            ? stateVars.CheckupDetaylari34[0].kayitSayisi
            : this.state.CheckupDetaylari34?.length > 0
            ? this.state.CheckupDetaylari34[0].kayitSayisi
            : null
        ),
        "2"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.CheckupDetaylari34?.length > 0
              ? stateVars.CheckupDetaylari34[0].kayitSayisi
              : this.state.CheckupDetaylari34?.length > 0
              ? this.state.CheckupDetaylari34[0].kayitSayisi
              : null
          ),
          "3"
        )) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_952785_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].egitmeninNotu
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].egitmeninNotu
        : null
    );

    stateVars.isComp835917Visible =
      (ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari34?.length > 0
            ? stateVars.CheckupDetaylari34[0].kayitSayisi
            : this.state.CheckupDetaylari34?.length > 0
            ? this.state.CheckupDetaylari34[0].kayitSayisi
            : null
        ),
        "2"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.CheckupDetaylari34?.length > 0
              ? stateVars.CheckupDetaylari34[0].kayitSayisi
              : this.state.CheckupDetaylari34?.length > 0
              ? this.state.CheckupDetaylari34[0].kayitSayisi
              : null
          ),
          "3"
        )) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_730592_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoContent
        : null
    );

    formVars.checkupreport3_628481_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoLink
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoLink
        : null
    );

    formVars.checkupreport3_959656_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoQR
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoQR
        : null
    );

    stateVars.isComp336879Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari34?.length > 0
            ? stateVars.CheckupDetaylari34[0].kayitSayisi
            : this.state.CheckupDetaylari34?.length > 0
            ? this.state.CheckupDetaylari34[0].kayitSayisi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_79472 = this.state.CheckupDetaylari35;

    formVars.checkupreport3_636881_value = ReactSystemFunctions.toString(
      this,
      stateVars.CheckupById?.length > 0
        ? stateVars.CheckupById[0].egitmeninNotu
        : this.state.CheckupById?.length > 0
        ? this.state.CheckupById[0].egitmeninNotu
        : null
    );

    stateVars.isComp205412Visible =
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDetaylari3?.length > 0
            ? stateVars.CheckupDetaylari3[0].kayitSayisi
            : this.state.CheckupDetaylari3?.length > 0
            ? this.state.CheckupDetaylari3[0].kayitSayisi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    formVars.checkupreport3_789984_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoContent
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoContent
        : null
    );

    formVars.checkupreport3_252835_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoLink
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoLink
        : null
    );

    formVars.checkupreport3_652400_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolReportContentById?.length > 0
        ? stateVars.ProtokolReportContentById[0].moreInfoQR
        : this.state.ProtokolReportContentById?.length > 0
        ? this.state.ProtokolReportContentById[0].moreInfoQR
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  CheckupReport3Component_442339_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ComponentToPdf = await ReactSystemFunctions.componentToPDF("82971", "MEDIUM", "");
    stateVars.OpenPdf = await ReactSystemFunctions.openPdf(
      ReactSystemFunctions.toString(this, stateVars.ComponentToPdf),
      false,
      "newTab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupReport3Component_436910_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  CheckupReport3Component_927174_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
