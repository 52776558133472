import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashboardKatilimDetay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashboardKatilimDetay_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  Kirilim2SelectById: any[];
  Kirilim2SelectById_dummy: any[];
  DashboardKatilimDetay: any[];
  DashboardKatilimDetay_dummy: any[];
  isComp123001Visible: "visible" | "hidden";
  isComp744775Visible: "visible" | "hidden";
  isComp531473Visible: "visible" | "hidden";
  isComp599044Visible: "visible" | "hidden";
  isComp378441Visible: "visible" | "hidden";
  isComp975624Visible: "visible" | "hidden";
  isComp637089Visible: "visible" | "hidden";
  isComp23260Visible: "visible" | "hidden";
  isComp337363Visible: "visible" | "hidden";
  isComp481066Visible: "visible" | "hidden";
  isComp470102Visible: "visible" | "hidden";
  isComp110043Visible: "visible" | "hidden";
  isComp260258Visible: "visible" | "hidden";
}

export class DashboardKatilimDetay_ScreenBase extends React.PureComponent<IDashboardKatilimDetay_ScreenProps, any> {
  dashboardkatilimdetay_794147_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 895607, PropertyName: "value", Value: "Participation Report", TagName: "Label1_value" },
        { Id: 961368, PropertyName: "value", Value: ":", TagName: "Label15_value" },
        { Id: 349671, PropertyName: "value", Value: "Report", TagName: "Label4_value" },
        { Id: 513919, PropertyName: "value", Value: ":", TagName: "Label16_value" },
        { Id: 942958, PropertyName: "value", Value: "Participation", TagName: "Label5_value" },
        { Id: 960691, PropertyName: "title", Value: "Class", TagName: "TableTemplateColumn1_0_title" },
        { Id: 123001, PropertyName: "title", Value: "School Number", TagName: "TableTemplateColumn9_title" },
        { Id: 11304, PropertyName: "title", Value: "Student Name", TagName: "TableTemplateColumn8_title" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "e2e7dbf4-8db2-47cf-94ac-ab2ccb46942b",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 895607, PropertyName: "value", Value: "Katılım Raporu" },
        { Id: 961368, PropertyName: "value", Value: ":" },
        { Id: 349671, PropertyName: "value", Value: "Rapor" },
        { Id: 513919, PropertyName: "value", Value: ":" },
        { Id: 942958, PropertyName: "value", Value: "Katılım" },
        { Id: 960691, PropertyName: "title", Value: "Sınıf" },
        { Id: 174237, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 123001, PropertyName: "title", Value: "Okul no" },
        { Id: 826024, PropertyName: "value", Value: "[datafield:okulnumarasi]" },
        { Id: 11304, PropertyName: "title", Value: "Öğrenci Adı" },
        { Id: 580650, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 984362, PropertyName: "value", Value: "[datafield:avg1]" },
        { Id: 923242, PropertyName: "value", Value: "[datafield:avg2]" },
        { Id: 493868, PropertyName: "value", Value: "[datafield:avg3]" },
        { Id: 979612, PropertyName: "value", Value: "[datafield:avg4]" },
        { Id: 524256, PropertyName: "value", Value: "[datafield:avg5]" },
        { Id: 425065, PropertyName: "value", Value: "[datafield:avg6]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardkatilimdetay_794147_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      Kirilim2SelectById: [],
      DashboardKatilimDetay: [],
      isComp123001Visible: "hidden",
      isComp744775Visible: "hidden",
      isComp531473Visible: "hidden",
      isComp599044Visible: "hidden",
      isComp378441Visible: "hidden",
      isComp975624Visible: "hidden",
      isComp637089Visible: "hidden",
      isComp23260Visible: "hidden",
      isComp337363Visible: "hidden",
      isComp481066Visible: "hidden",
      isComp470102Visible: "hidden",
      isComp110043Visible: "hidden",
      isComp260258Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardkatilimdetay", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashboardKatilimDetayPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardkatilimdetay", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardkatilimdetay", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashboardKatilimDetayPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  DashboardKatilimDetayPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      Kirilim2Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      kirilim1Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id,
        "Guid"
      ),
      protokolID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashboardKatilimDetay/DashboardKatilimDetayPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.dashboardkatilimdetay_721860_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );
    stateVars.isComp123001Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumTipiKod
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumTipiKod
            : null
        ),
        "KT_School"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.Kirilim2SelectById = result?.data.kirilim2SelectById;
    formVars.dashboardkatilimdetay_794864_value = ReactSystemFunctions.toString(
      this,
      stateVars.Kirilim2SelectById?.length > 0
        ? stateVars.Kirilim2SelectById[0].tanim
        : this.state.Kirilim2SelectById?.length > 0
        ? this.state.Kirilim2SelectById[0].tanim
        : null
    );
    stateVars.DashboardKatilimDetay = result?.data.dashboardKatilimDetay;

    stateVars.isComp744775Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.DashboardKatilimDetay?.length > 0
            ? stateVars.DashboardKatilimDetay[0].tanim1
            : this.state.DashboardKatilimDetay?.length > 0
            ? this.state.DashboardKatilimDetay[0].tanim1
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp599044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.DashboardKatilimDetay?.length > 0
            ? stateVars.DashboardKatilimDetay[0].tanim2
            : this.state.DashboardKatilimDetay?.length > 0
            ? this.state.DashboardKatilimDetay[0].tanim2
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp975624Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.DashboardKatilimDetay?.length > 0
            ? stateVars.DashboardKatilimDetay[0].tanim3
            : this.state.DashboardKatilimDetay?.length > 0
            ? this.state.DashboardKatilimDetay[0].tanim3
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp23260Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.DashboardKatilimDetay?.length > 0
            ? stateVars.DashboardKatilimDetay[0].tanim4
            : this.state.DashboardKatilimDetay?.length > 0
            ? this.state.DashboardKatilimDetay[0].tanim4
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp481066Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.DashboardKatilimDetay?.length > 0
            ? stateVars.DashboardKatilimDetay[0].tanim5
            : this.state.DashboardKatilimDetay?.length > 0
            ? this.state.DashboardKatilimDetay[0].tanim5
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp110043Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.DashboardKatilimDetay?.length > 0
            ? stateVars.DashboardKatilimDetay[0].tanim6
            : this.state.DashboardKatilimDetay?.length > 0
            ? this.state.DashboardKatilimDetay[0].tanim6
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashboardKatilimDetayPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardKatilimDetayPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.dashboardkatilimdetay_721860_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );

    formVars.dashboardkatilimdetay_794864_value = ReactSystemFunctions.toString(
      this,
      stateVars.Kirilim2SelectById?.length > 0
        ? stateVars.Kirilim2SelectById[0].tanim
        : this.state.Kirilim2SelectById?.length > 0
        ? this.state.Kirilim2SelectById[0].tanim
        : null
    );

    stateVars.dataSource_794147 = this.state.DashboardKatilimDetay;
    stateVars.isComp123001Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumTipiKod
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumTipiKod
            : null
        ),
        "KT_School"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardkatilimdetay_744775_title = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardKatilimDetay?.length > 0
        ? stateVars.DashboardKatilimDetay[0].tanim1
        : this.state.DashboardKatilimDetay?.length > 0
        ? this.state.DashboardKatilimDetay[0].tanim1
        : null
    );

    stateVars.isComp744775Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.DashboardKatilimDetay?.length > 0
            ? stateVars.DashboardKatilimDetay[0].tanim1
            : this.state.DashboardKatilimDetay?.length > 0
            ? this.state.DashboardKatilimDetay[0].tanim1
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp531473Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "dashboardkatilimdetay_794147_value", "avg1"),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardkatilimdetay_599044_title = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardKatilimDetay?.length > 0
        ? stateVars.DashboardKatilimDetay[0].tanim2
        : this.state.DashboardKatilimDetay?.length > 0
        ? this.state.DashboardKatilimDetay[0].tanim2
        : null
    );

    stateVars.isComp599044Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.DashboardKatilimDetay?.length > 0
            ? stateVars.DashboardKatilimDetay[0].tanim2
            : this.state.DashboardKatilimDetay?.length > 0
            ? this.state.DashboardKatilimDetay[0].tanim2
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp378441Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "dashboardkatilimdetay_794147_value", "avg2"),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardkatilimdetay_975624_title = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardKatilimDetay?.length > 0
        ? stateVars.DashboardKatilimDetay[0].tanim3
        : this.state.DashboardKatilimDetay?.length > 0
        ? this.state.DashboardKatilimDetay[0].tanim3
        : null
    );

    stateVars.isComp975624Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.DashboardKatilimDetay?.length > 0
            ? stateVars.DashboardKatilimDetay[0].tanim3
            : this.state.DashboardKatilimDetay?.length > 0
            ? this.state.DashboardKatilimDetay[0].tanim3
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637089Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "dashboardkatilimdetay_794147_value", "avg3"),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardkatilimdetay_23260_title = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardKatilimDetay?.length > 0
        ? stateVars.DashboardKatilimDetay[0].tanim4
        : this.state.DashboardKatilimDetay?.length > 0
        ? this.state.DashboardKatilimDetay[0].tanim4
        : null
    );

    stateVars.isComp23260Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.DashboardKatilimDetay?.length > 0
            ? stateVars.DashboardKatilimDetay[0].tanim4
            : this.state.DashboardKatilimDetay?.length > 0
            ? this.state.DashboardKatilimDetay[0].tanim4
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp337363Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "dashboardkatilimdetay_794147_value", "avg4"),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardkatilimdetay_481066_title = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardKatilimDetay?.length > 0
        ? stateVars.DashboardKatilimDetay[0].tanim5
        : this.state.DashboardKatilimDetay?.length > 0
        ? this.state.DashboardKatilimDetay[0].tanim5
        : null
    );

    stateVars.isComp481066Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.DashboardKatilimDetay?.length > 0
            ? stateVars.DashboardKatilimDetay[0].tanim5
            : this.state.DashboardKatilimDetay?.length > 0
            ? this.state.DashboardKatilimDetay[0].tanim5
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp470102Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "dashboardkatilimdetay_794147_value", "avg5"),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboardkatilimdetay_110043_title = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardKatilimDetay?.length > 0
        ? stateVars.DashboardKatilimDetay[0].tanim6
        : this.state.DashboardKatilimDetay?.length > 0
        ? this.state.DashboardKatilimDetay[0].tanim6
        : null
    );

    stateVars.isComp110043Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.DashboardKatilimDetay?.length > 0
            ? stateVars.DashboardKatilimDetay[0].tanim6
            : this.state.DashboardKatilimDetay?.length > 0
            ? this.state.DashboardKatilimDetay[0].tanim6
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp260258Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, "dashboardkatilimdetay_794147_value", "avg6"),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashboardKatilimDetayComponent_885957_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
