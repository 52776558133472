import { Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import { KCol, KRow, Label } from "../../components/web-components";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { Master_Screen } from "../master/master";
import { Resources_ScreenBase } from "./resources-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class Resources_Screen extends Resources_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Master_Screen
          form={this.props.form}
          initialValues={{}}
          screenInputs={this.props.screenInputs}
          setMasterPageInitLoaded={this.setMasterPageInitLoaded}
          setMasterPageRecurringLoaded={this.setMasterPageRecurringLoaded}
          isMasterPageInitLoaded={this.state.isMasterPageInitLoaded}
          isMasterPageRecurringLoaded={this.state.isMasterPageRecurringLoaded}
          shouldTriggerPageInit={this.state.shouldTriggerPageInit}
          setShouldTriggerPageInit={this.setShouldTriggerPageInit}
        >
          <Spin spinning={this.state.spinnerCount > 0}>
            <div
              id="resources_body"
              style={
                {
                  backgroundColor: "rgba(244, 244, 244, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block",
                  overflow: "auto",
                  minHeight: "calc(100vh - 60px - 60px - 0px - 0px)",
                  maxHeight: "calc(100vh - 60px - 60px - 0px - 0px)",
                  height: "calc(100vh - 60px - 60px - 0px - 0px)"
                } as any
              }
            >
              <KRow
                id="331958"
                align="top"
                justify="start"
                horizontalGutter={0}
                verticalGutter={0}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    alignItems: "flex-start",
                    textAlign: "-webkit-left",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "rgba(10, 33, 64, 1)"
                  } as any
                }
              >
                <KCol
                  id="44993"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  order={0}
                  pull={0}
                  push={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "center",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <Label
                    id="54307"
                    value={ReactSystemFunctions.translate(this.ml, 54307, "value", this.defaultML)}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 5,
                        paddingLeft: 0,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        display: "inline",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        fontSize: "18pt",
                        color: "rgba(10, 33, 64, 1)",
                        textTransform: "uppercase"
                      } as any
                    }
                  ></Label>
                </KCol>
              </KRow>
            </div>
          </Spin>
        </Master_Screen>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Resources_Screen)))))
);
export { tmp as Resources_Screen };
//export default tmp;
//export { tmp as Resources_Screen };
