import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITestF2_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITestF2_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  SiniflandirmaSelectAll: any[];
  SiniflandirmaSelectAll_dummy: any[];
  NormmAll: any[];
  NormmAll_dummy: any[];
  PopulasyonAll: any[];
  PopulasyonAll_dummy: any[];
  TestById: any[];
  TestById_dummy: any[];
  BilesenAll: any[];
  BilesenAll_dummy: any[];
  AltBilesenByBilesenId: any[];
  AltBilesenByBilesenId_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
}

export class TestF2_ScreenBase extends React.PureComponent<ITestF2_ScreenProps, any> {
  testf2_331863_value_kuikaSelectBoxRef: React.RefObject<any>;
  testf2_1763_value_kuikaSelectBoxRef: React.RefObject<any>;
  testf2_813822_value_kuikaSelectBoxRef: React.RefObject<any>;
  testf2_845288_value_kuikaSelectBoxRef: React.RefObject<any>;
  testf2_596953_value_kuikaSelectBoxRef: React.RefObject<any>;
  testf2_992666_value_kuikaSelectBoxRef: React.RefObject<any>;
  testf2_164448_value_kuikaSelectBoxRef: React.RefObject<any>;
  testf2_537552_value_kuikaMultiSelectRef: React.RefObject<any>;
  testf2_105448_value_kuikaMultiSelectRef: React.RefObject<any>;
  testf2_65627_value_kuikaSelectBoxRef: React.RefObject<any>;
  testf2_108534_value_kuikaSelectBoxRef: React.RefObject<any>;
  testf2_46999_value_kuikaSelectBoxRef: React.RefObject<any>;
  testf2_405121_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "0b6ff76d-add6-4bd9-9d7f-c92d9cbf52f1",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "TEST TANIMI" },
        { Id: 438131, PropertyName: "value", Value: "Test Adı (public)" },
        { Id: 35332, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 524663, PropertyName: "value", Value: "Test Adı (İç kullanım)" },
        { Id: 102153, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 523370, PropertyName: "value", Value: "Bileşen" },
        { Id: 331863, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 361153, PropertyName: "value", Value: "Alt Bileşen" },
        { Id: 1763, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 650869, PropertyName: "value", Value: "Yaş Aralığı" },
        { Id: 997114, PropertyName: "placeholder", Value: "En Küçük" },
        { Id: 605435, PropertyName: "placeholder", Value: "En Büyük" },
        { Id: 81941, PropertyName: "value", Value: "Yardım URL" },
        { Id: 287064, PropertyName: "placeholder", Value: "https://www.kuika.com" },
        { Id: 702813, PropertyName: "value", Value: "Test Sonuç Cümlesi" },
        { Id: 555983, PropertyName: "title", Value: "@boy, @vucutAgirligi, @yas, @puan" },
        {
          Id: 183326,
          PropertyName: "placeholder",
          Value:
            "@boy cm boy uzunluğu ve @vucutAgirligi kg vücut ağırlığı ile Beden Kitle İndeksi (BMI) @puan olarak hesaplanmıştır."
        },
        { Id: 428176, PropertyName: "value", Value: "Genel Görünüm" },
        { Id: 813822, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 571306, PropertyName: "value", Value: "Yüklenme" },
        { Id: 845288, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 711956, PropertyName: "value", Value: "Norm" },
        { Id: 596953, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 455750, PropertyName: "value", Value: "Yıldız Numarası" },
        { Id: 129944, PropertyName: "value", Value: "Popülasyon" },
        { Id: 992666, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 188541, PropertyName: "value", Value: "Cinsiyet" },
        { Id: 164448, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 220536, PropertyName: "value", Value: "Alan" },
        { Id: 537552, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 57033, PropertyName: "value", Value: "Amaç" },
        { Id: 105448, PropertyName: "placeholder", Value: "Seçiniz.." },
        { Id: 674175, PropertyName: "value", Value: "Yöntem" },
        { Id: 65627, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 918177, PropertyName: "value", Value: "Risk Sınıfı" },
        { Id: 108534, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 529347, PropertyName: "value", Value: "Hedef Bölge" },
        { Id: 46999, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 891032, PropertyName: "value", Value: "Taraf" },
        { Id: 405121, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 421612, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.testf2_331863_value_kuikaSelectBoxRef = React.createRef();
    this.testf2_1763_value_kuikaSelectBoxRef = React.createRef();
    this.testf2_813822_value_kuikaSelectBoxRef = React.createRef();
    this.testf2_845288_value_kuikaSelectBoxRef = React.createRef();
    this.testf2_596953_value_kuikaSelectBoxRef = React.createRef();
    this.testf2_992666_value_kuikaSelectBoxRef = React.createRef();
    this.testf2_164448_value_kuikaSelectBoxRef = React.createRef();
    this.testf2_537552_value_kuikaMultiSelectRef = React.createRef();
    this.testf2_105448_value_kuikaMultiSelectRef = React.createRef();
    this.testf2_65627_value_kuikaSelectBoxRef = React.createRef();
    this.testf2_108534_value_kuikaSelectBoxRef = React.createRef();
    this.testf2_46999_value_kuikaSelectBoxRef = React.createRef();
    this.testf2_405121_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      SiniflandirmaSelectAll: [],
      NormmAll: [],
      PopulasyonAll: [],
      TestById: [],
      BilesenAll: [],
      AltBilesenByBilesenId: [],
      GoBack: "",
      SaveRecord: false,
      dataSource_813822: [
        { key: "Acik", text: "Açık" },
        { key: "Kapali", text: "Kapalı" }
      ],
      dataSource_845288: [
        { key: "Maksimal", text: "Maksimal" },
        { key: "Submaksimal", text: "Submaksimal" }
      ],
      dataSource_596953: [
        { key: "1", text: "Var" },
        { key: "0", text: "Yok" }
      ],
      dataSource_164448: [
        { key: "Tumu", text: "Tümü" },
        { key: "Erkek", text: "Erkek" },
        { key: "Kadin", text: "Kadın" }
      ],
      dataSource_537552: [
        { key: "Fitness", text: "Fitness" },
        { key: "Saha", text: "Saha" },
        { key: "Laboratuvar", text: "Laboratuvar" }
      ],
      dataSource_105448: [
        { key: "Saglik", text: "Sağlık" },
        { key: "Beceri", text: "Beceri" },
        { key: "Performans", text: "Performans" }
      ],
      dataSource_65627: [
        { text: "Dinamik", key: "Dinamik" },
        { text: "İzometrik", key: "Izometrik" },
        { text: "İzokinetik", key: "Izokinetik" },
        { text: "Çevre", key: "Cevre" },
        { text: "Koşu", key: "Kosu" },
        { text: "Yürüyüş", key: "Yuruyus" },
        { text: "Basamak", key: "Basamak" },
        { text: "Ergometre", key: "Ergometre" },
        { text: "Yüzme", key: "Yuzme" }
      ],
      dataSource_108534: [
        { key: "Dusuk", text: "Düşük" },
        { key: "Orta", text: "Orta" },
        { key: "Yuksek", text: "Yüksek" }
      ],
      dataSource_46999: [
        { text: "Alt Ekstremite", key: "AltEkstremite" },
        { text: "Üst Ekstremite", key: "UstEkstremite" },
        { text: "Gövde", key: "Govde" },
        { text: "Diz", key: "Diz" },
        { text: "Kalça", key: "Kalca" },
        { text: "Omuz", key: "Omuz" },
        { text: "Ayak Bileği", key: "AyakBilegi" },
        { text: "El Bileği", key: "ElBilegi" },
        { text: "Boyun", key: "Boyun" }
      ],
      dataSource_405121: [
        { key: "Bilateral", text: "Bilateral" },
        { key: "Unilateral", text: "Unilateral" }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("testf2", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TestF2PageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("testf2", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("testf2", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TestF2PageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      testf2_35332_value: this.state.TestById?.at?.(0)?.tanim ?? undefined,
      testf2_102153_value: this.state.TestById?.at?.(0)?.tanimInternal ?? undefined,
      testf2_331863_value: this.state.TestById?.at?.(0)?.bilesenId ?? undefined,
      testf2_1763_value: this.state.TestById?.at?.(0)?.altBilesenId ?? undefined,
      testf2_997114_value: this.state.TestById?.at?.(0)?.yasAraligiAlt ?? undefined,
      testf2_605435_value: this.state.TestById?.at?.(0)?.yasAraligiUst ?? undefined,
      testf2_287064_value: this.state.TestById?.at?.(0)?.yardimURL ?? undefined,
      testf2_183326_value: this.state.TestById?.at?.(0)?.raporSonucCumlesi ?? undefined,
      testf2_813822_value: this.state.TestById?.at?.(0)?.genelGorunum ?? undefined,
      testf2_845288_value: this.state.TestById?.at?.(0)?.yuklenme ?? undefined,
      testf2_596953_value: this.state.TestById?.at?.(0)?.normVarmi ?? undefined,
      testf2_238699_value: this.state.TestById?.at?.(0)?.yildizNumarasi ?? undefined,
      testf2_992666_value: this.state.TestById?.at?.(0)?.populasyonId ?? undefined,
      testf2_164448_value: this.state.TestById?.at?.(0)?.cinsiyet ?? undefined,
      testf2_537552_value: this.state.TestById?.at?.(0)?.alan ?? undefined,
      testf2_105448_value: this.state.TestById?.at?.(0)?.amac ?? undefined,
      testf2_65627_value: this.state.TestById?.at?.(0)?.yontem ?? undefined,
      testf2_108534_value: this.state.TestById?.at?.(0)?.riskSinifi ?? undefined,
      testf2_46999_value: this.state.TestById?.at?.(0)?.hedefBolge ?? undefined,
      testf2_405121_value: this.state.TestById?.at?.(0)?.taraf ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TestF2PageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF2/TestF2PageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiniflandirmaSelectAll = result?.data.siniflandirmaSelectAll;
    stateVars.NormmAll = result?.data.normmAll;
    stateVars.PopulasyonAll = result?.data.populasyonAll;

    formVars.testf2_992666_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].populasyonId
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].populasyonId
        : null;
    formVars.testf2_992666_options = stateVars.PopulasyonAll;
    stateVars.TestById = result?.data.testById;
    formVars.testf2_35332_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].tanim
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].tanim
        : null
    );
    formVars.testf2_102153_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].tanimInternal
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].tanimInternal
        : null
    );
    formVars.testf2_331863_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].bilesenId
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].bilesenId
        : null;
    formVars.testf2_331863_options = stateVars.BilesenAll;
    formVars.testf2_1763_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].altBilesenId
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].altBilesenId
        : null;
    formVars.testf2_1763_options = stateVars.AltBilesenByBilesenId;
    formVars.testf2_997114_value = ReactSystemFunctions.value(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].yasAraligiAlt
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].yasAraligiAlt
        : null
    );
    formVars.testf2_605435_value = ReactSystemFunctions.value(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].yasAraligiUst
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].yasAraligiUst
        : null
    );
    formVars.testf2_287064_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].yardimURL
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].yardimURL
        : null
    );
    formVars.testf2_183326_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].raporSonucCumlesi
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].raporSonucCumlesi
        : null
    );
    formVars.testf2_813822_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].genelGorunum
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].genelGorunum
        : null;
    formVars.testf2_845288_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].yuklenme
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].yuklenme
        : null;
    formVars.testf2_596953_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].normVarmi
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].normVarmi
        : null;
    formVars.testf2_992666_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].populasyonId
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].populasyonId
        : null;
    formVars.testf2_992666_options = stateVars.PopulasyonAll;
    formVars.testf2_164448_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].cinsiyet
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].cinsiyet
        : null;
    formVars.testf2_537552_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].alan
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].alan
        : null;
    formVars.testf2_105448_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].amac
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].amac
        : null;
    formVars.testf2_65627_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].yontem
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].yontem
        : null;
    formVars.testf2_108534_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].riskSinifi
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].riskSinifi
        : null;
    formVars.testf2_46999_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].hedefBolge
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].hedefBolge
        : null;
    formVars.testf2_405121_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].taraf
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].taraf
        : null;
    stateVars.BilesenAll = result?.data.bilesenAll;

    formVars.testf2_331863_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].bilesenId
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].bilesenId
        : null;
    formVars.testf2_331863_options = stateVars.BilesenAll;
    stateVars.AltBilesenByBilesenId = result?.data.altBilesenByBilesenId;

    formVars.testf2_1763_value =
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].altBilesenId
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].altBilesenId
        : null;
    formVars.testf2_1763_options = stateVars.AltBilesenByBilesenId;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestF2PageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestF2PageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf2_35332_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].tanim
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].tanim
        : null
    );

    formVars.testf2_102153_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].tanimInternal
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].tanimInternal
        : null
    );

    formVars.testf2_331863_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].bilesenId
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].bilesenId
        : null
    );

    stateVars.dataSource_331863 = this.state.BilesenAll;
    stateVars.dataSource_331863 = this.state.BilesenAll;
    formVars.testf2_1763_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].altBilesenId
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].altBilesenId
        : null
    );

    stateVars.dataSource_1763 = this.state.AltBilesenByBilesenId;
    stateVars.dataSource_1763 = this.state.AltBilesenByBilesenId;
    formVars.testf2_997114_value = ReactSystemFunctions.value(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].yasAraligiAlt
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].yasAraligiAlt
        : null
    );

    formVars.testf2_605435_value = ReactSystemFunctions.value(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].yasAraligiUst
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].yasAraligiUst
        : null
    );

    formVars.testf2_287064_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].yardimURL
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].yardimURL
        : null
    );

    formVars.testf2_183326_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].raporSonucCumlesi
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].raporSonucCumlesi
        : null
    );

    formVars.testf2_813822_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].genelGorunum
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].genelGorunum
        : null
    );

    formVars.testf2_845288_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].yuklenme
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].yuklenme
        : null
    );

    formVars.testf2_596953_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].normVarmi
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].normVarmi
        : null
    );

    formVars.testf2_238699_value = ReactSystemFunctions.value(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].yildizNumarasi
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].yildizNumarasi
        : null
    );

    formVars.testf2_992666_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].populasyonId
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].populasyonId
        : null
    );

    stateVars.dataSource_992666 = this.state.PopulasyonAll;
    stateVars.dataSource_992666 = this.state.PopulasyonAll;
    formVars.testf2_164448_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].cinsiyet
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].cinsiyet
        : null
    );

    formVars.testf2_537552_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].alan
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].alan
        : null
    );

    formVars.testf2_105448_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].amac
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].amac
        : null
    );

    formVars.testf2_65627_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].yontem
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].yontem
        : null
    );

    formVars.testf2_108534_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].riskSinifi
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].riskSinifi
        : null
    );

    formVars.testf2_46999_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].hedefBolge
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].hedefBolge
        : null
    );

    formVars.testf2_405121_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].taraf
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].taraf
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TestF2Component_653854_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestF2Component_331863_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_331863_value", "value", "BilesenAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "testf2_331863_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      bilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf2_331863_value", "value", "BilesenAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF2/TestF2Component_331863_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AltBilesenByBilesenId = result?.data.altBilesenByBilesenId;

    formVars.testf2_1763_options = stateVars.AltBilesenByBilesenId;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestF2Component_331863_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestF2Component_331863_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_1763 = this.state.AltBilesenByBilesenId;
    stateVars.dataSource_1763 = this.state.AltBilesenByBilesenId;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestF2Component_421612_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_1763_value", "value", "AltBilesenByBilesenId", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "testf2_1763_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_35332_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "testf2_35332_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf2_997114_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "testf2_997114_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf2_605435_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "testf2_605435_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_183326_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "testf2_183326_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_35332_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "testf2_102153_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      AltBilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf2_1763_value", "value", "AltBilesenByBilesenId", "id", "id")
        ),
        "Guid"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf2_35332_value", "value", "", "", "")),
        "string"
      ),
      YasAraligiAlt_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf2_997114_value", "value", "", "", "")),
        "number"
      ),
      YasAraligiUst_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf2_605435_value", "value", "", "", "")),
        "number"
      ),
      RaporSonucCumlesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_183326_value", "value", "", "", "")
        ),
        "string"
      ),
      TanimInternal_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_102153_value", "value", "", "", "")
        ),
        "string"
      ),
      YardimURL_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_287064_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Alan_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_537552_value", "value", "", "key", "")
        ),
        "string"
      ),
      Amac_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_105448_value", "value", "", "key", "")
        ),
        "string"
      ),
      GenelGorunum_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_813822_value", "value", "", "key", "")
        ),
        "string"
      ),
      NormVarmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "testf2_596953_value", "value", "", "key", "")
        ),
        "number"
      ),
      PopulasyonId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf2_992666_value", "value", "PopulasyonAll", "id", "id")
        ),
        "Guid"
      ),
      RiskSinifi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_108534_value", "value", "", "key", "")
        ),
        "string"
      ),
      YildizNumarasi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf2_238699_value", "value", "", "", "")),
        "number"
      ),
      Yuklenme_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_845288_value", "value", "", "key", "")
        ),
        "string"
      ),
      HedefBolge_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_46999_value", "value", "", "key", "")
        ),
        "string"
      ),
      Yontem_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_65627_value", "value", "", "key", "")
        ),
        "string"
      ),
      Taraf_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_405121_value", "value", "", "key", "")
        ),
        "string"
      ),
      Cinsiyet_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf2_164448_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF2/TestF2Component_421612_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.SaveRecord = result?.data.saveRecord_2;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestF2Component_421612_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestF2Component_421612_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
