import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPersonConnectedList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPersonConnectedList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  ProfilByKisiIdAndRolename: any[];
  ProfilByKisiIdAndRolename_dummy: any[];
  KisiSearchObserverClient: any[];
  KisiSearchObserverClient_dummy: any[];
  AktifKirilim1AltKirilim2leri: any[];
  AktifKirilim1AltKirilim2leri_dummy: any[];
  RolePerKurumTipi: any[];
  RolePerKurumTipi_dummy: any[];
  Rol: any[];
  Rol_dummy: any[];
  IsSinifExisting: any[];
  IsSinifExisting_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
  KisiSearch: any[];
  KisiSearch_dummy: any[];
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  isComp254348Visible: "visible" | "hidden";
  isComp25249Visible: "visible" | "hidden";
  isComp259491Visible: "visible" | "hidden";
  isComp331748Visible: "visible" | "hidden";
  isComp832432Visible: "visible" | "hidden";
}

export class PersonConnectedList_ScreenBase extends React.PureComponent<IPersonConnectedList_ScreenProps, any> {
  personconnectedlist_254348_value_kuikaSelectBoxRef: React.RefObject<any>;
  personconnectedlist_350368_value_kuikaTableRef: React.RefObject<any>;
  personconnectedlist_753001_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "362cd95d-701f-41cc-bcae-9adb929972ae",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 254348, PropertyName: "placeholder", Value: "Sınıf Filtresi" },
        { Id: 863981, PropertyName: "placeholder", Value: "Arama..." },
        { Id: 25249, PropertyName: "label", Value: "Yeni Öğrenci" },
        { Id: 259491, PropertyName: "label", Value: "Yeni Veli" },
        { Id: 350368, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 271780, PropertyName: "value", Value: "[datafield:kirilim2]" },
        { Id: 832432, PropertyName: "title", Value: "Okul Numarası" },
        { Id: 968391, PropertyName: "value", Value: "[datafield:okulnumarasi]" },
        { Id: 999517, PropertyName: "title", Value: "Adı" },
        { Id: 11801, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 533601, PropertyName: "title", Value: "Soyadı" },
        { Id: 568408, PropertyName: "value", Value: "[datafield:soyadi]" },
        { Id: 306783, PropertyName: "title", Value: "Yaş" },
        { Id: 853913, PropertyName: "value", Value: "[datafield:yas]" },
        { Id: 325548, PropertyName: "title", Value: "Cinsiyet" },
        { Id: 220207, PropertyName: "value", Value: "[datafield:cinsiyet]" },
        { Id: 364708, PropertyName: "title", Value: "Telefon" },
        { Id: 228405, PropertyName: "value", Value: "[datafield:telefon]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.personconnectedlist_254348_value_kuikaSelectBoxRef = React.createRef();
    this.personconnectedlist_350368_value_kuikaTableRef = React.createRef();
    this.personconnectedlist_753001_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      ProfilByKisiIdAndRolename: [],
      KisiSearchObserverClient: [],
      AktifKirilim1AltKirilim2leri: [],
      RolePerKurumTipi: [],
      Rol: [],
      IsSinifExisting: [],
      GoBack: "",
      NAVIGATE: "",
      IfThenElse: false,
      isComp254348Visible: "hidden",
      isComp25249Visible: "hidden",
      isComp259491Visible: "hidden",
      isComp331748Visible: "hidden",
      isComp832432Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("personconnectedlist", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.PersonConnectedListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("personconnectedlist", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("personconnectedlist", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.PersonConnectedListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      personconnectedlist_254348_value: this.props.screenInputs.role ?? this.props.screenInputs.role
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  PersonConnectedListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      KurumId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid,
        "Guid"
      ),
      roleFilter_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kirilim2Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personconnectedlist_254348_value",
            "value",
            "AktifKirilim1AltKirilim2leri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KisiId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      role_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      rolename_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonConnectedList/PersonConnectedListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.ProfilByKisiIdAndRolename = result?.data.profilByKisiIdAndRolename;
    stateVars.KisiSearchObserverClient = result?.data.kisiSearchObserverClient;

    stateVars.AktifKirilim1AltKirilim2leri = result?.data.aktifKirilim1AltKirilim2leri;

    formVars.personconnectedlist_254348_options = stateVars.AktifKirilim1AltKirilim2leri;
    stateVars.RolePerKurumTipi = result?.data.rolePerKurumTipi;
    formVars.personconnectedlist_834413_value = ReactSystemFunctions.toString(
      this,
      stateVars.RolePerKurumTipi?.length > 0
        ? stateVars.RolePerKurumTipi[0].label
        : this.state.RolePerKurumTipi?.length > 0
        ? this.state.RolePerKurumTipi[0].label
        : null
    );
    stateVars.Rol = result?.data.rol;
    stateVars.IsSinifExisting = result?.data.isSinifExisting;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonConnectedListPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonConnectedListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.personconnectedlist_834413_value = ReactSystemFunctions.toString(
      this,
      stateVars.RolePerKurumTipi?.length > 0
        ? stateVars.RolePerKurumTipi[0].label
        : this.state.RolePerKurumTipi?.length > 0
        ? this.state.RolePerKurumTipi[0].label
        : null
    );

    stateVars.dataSource_254348 = this.state.AktifKirilim1AltKirilim2leri;
    stateVars.dataSource_254348 = this.state.AktifKirilim1AltKirilim2leri;
    stateVars.isComp254348Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp25249Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp259491Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Observer") ===
      true
        ? "visible"
        : "hidden";

    stateVars.dataSource_350368 = this.state.KisiSearchObserverClient;
    formVars.personconnectedlist_331748_title = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );

    stateVars.isComp331748Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp832432Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client") ===
      true
        ? "visible"
        : "hidden";
    formVars.personconnectedlist_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearchObserverClient?.length > 0
        ? stateVars.KisiSearchObserverClient[0].personCount
        : this.state.KisiSearchObserverClient?.length > 0
        ? this.state.KisiSearchObserverClient[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  PersonConnectedListComponent_372921_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonConnectedListComponent_254348_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personconnectedlist_254348_value",
            "value",
            "AktifKirilim1AltKirilim2leri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PersonConnectedList/PersonConnectedListComponent_254348_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiSearchObserverClient = result?.data.kisiSearchObserverClient;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonConnectedListComponent_254348_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonConnectedListComponent_254348_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_350368 = this.state.KisiSearchObserverClient;
    formVars.personconnectedlist_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearchObserverClient?.length > 0
        ? stateVars.KisiSearchObserverClient[0].personCount
        : this.state.KisiSearchObserverClient?.length > 0
        ? this.state.KisiSearchObserverClient[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonConnectedListComponent_863981_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personconnectedlist_254348_value",
            "value",
            "AktifKirilim1AltKirilim2leri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PersonConnectedList/PersonConnectedListComponent_863981_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiSearchObserverClient = result?.data.kisiSearchObserverClient;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonConnectedListComponent_863981_onPressEnter1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonConnectedListComponent_863981_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_350368 = this.state.KisiSearchObserverClient;
    formVars.personconnectedlist_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearchObserverClient?.length > 0
        ? stateVars.KisiSearchObserverClient[0].personCount
        : this.state.KisiSearchObserverClient?.length > 0
        ? this.state.KisiSearchObserverClient[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonConnectedListComponent_25249_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Person", "kisiId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Person", "role", "Rol_Client");
    KuikaAppManager.addToPageInputVariables("Person", "Kirilim2Id", null);
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "kurumID",
      ReactSystemFunctions.value(
        this,
        stateVars.MyContext?.length > 0
          ? stateVars.MyContext[0].kurumId
          : this.state.MyContext?.length > 0
          ? this.state.MyContext[0].kurumId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables("Person", "profilID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Person", "Ne", "N");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.PersonConnectedListComponent_25249_onClick1_,
      "PersonConnectedList",
      "Person",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "259838",
      null,
      "right",
      null,
      "500px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  PersonConnectedListComponent_25249_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personconnectedlist_254348_value",
            "value",
            "AktifKirilim1AltKirilim2leri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PersonConnectedList/PersonConnectedListComponent_25249_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiSearchObserverClient = result?.data.kisiSearchObserverClient;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonConnectedListComponent_25249_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonConnectedListComponent_25249_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_350368 = this.state.KisiSearchObserverClient;
    formVars.personconnectedlist_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearchObserverClient?.length > 0
        ? stateVars.KisiSearchObserverClient[0].personCount
        : this.state.KisiSearchObserverClient?.length > 0
        ? this.state.KisiSearchObserverClient[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonConnectedListComponent_259491_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Person", "kisiId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Person", "role", "Rol_Observer");
    KuikaAppManager.addToPageInputVariables("Person", "Kirilim2Id", null);
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "kurumID",
      ReactSystemFunctions.value(
        this,
        stateVars.MyContext?.length > 0
          ? stateVars.MyContext[0].kurumId
          : this.state.MyContext?.length > 0
          ? this.state.MyContext[0].kurumId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables("Person", "profilID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Person", "Ne", "N");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.PersonConnectedListComponent_259491_onClick1_,
      "PersonConnectedList",
      "Person",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "295674",
      null,
      "right",
      null,
      "500px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  PersonConnectedListComponent_259491_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personconnectedlist_254348_value",
            "value",
            "AktifKirilim1AltKirilim2leri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PersonConnectedList/PersonConnectedListComponent_259491_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiSearchObserverClient = result?.data.kisiSearchObserverClient;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonConnectedListComponent_259491_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonConnectedListComponent_259491_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_350368 = this.state.KisiSearchObserverClient;
    formVars.personconnectedlist_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearchObserverClient?.length > 0
        ? stateVars.KisiSearchObserverClient[0].personCount
        : this.state.KisiSearchObserverClient?.length > 0
        ? this.state.KisiSearchObserverClient[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonConnectedListComponent_511637_onClick_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      Rolename_0: ReactSystemFunctions.convertToTypeByName("Rol_Observer", "string"),
      IzledigiKisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "personconnectedlist_350368_value", "id"),
        "Guid"
      ),
      KurumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid,
        "Guid"
      ),
      currentRole_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personconnectedlist_254348_value",
            "value",
            "AktifKirilim1AltKirilim2leri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PersonConnectedList/PersonConnectedListComponent_511637_onClick_if0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.KisiSearch = result?.data.kisiSearch;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  PersonConnectedListComponent_511637_onClick_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "personconnectedlist_350368_value", "id"),
        "Guid"
      ),
      Rolename_0: ReactSystemFunctions.convertToTypeByName("Rol_Observer", "string"),
      IzledigiKisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      KurumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid,
        "Guid"
      ),
      currentRole_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personconnectedlist_254348_value",
            "value",
            "AktifKirilim1AltKirilim2leri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PersonConnectedList/PersonConnectedListComponent_511637_onClick_else0",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.KisiSearch = result?.data.kisiSearch;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  PersonConnectedListComponent_511637_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client")) {
      isErrorOccurred = await this.PersonConnectedListComponent_511637_onClick_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.PersonConnectedListComponent_511637_onClick_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonConnectedListComponent_511637_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersonConnectedListComponent_511637_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personconnectedlist_254348_value",
            "value",
            "AktifKirilim1AltKirilim2leri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PersonConnectedList/PersonConnectedListComponent_511637_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiSearchObserverClient = result?.data.kisiSearchObserverClient;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonConnectedListComponent_511637_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonConnectedListComponent_511637_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_350368 = this.state.KisiSearchObserverClient;
    formVars.personconnectedlist_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearchObserverClient?.length > 0
        ? stateVars.KisiSearchObserverClient[0].personCount
        : this.state.KisiSearchObserverClient?.length > 0
        ? this.state.KisiSearchObserverClient[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonConnectedListComponent_753001_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personconnectedlist_254348_value",
            "value",
            "AktifKirilim1AltKirilim2leri",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_863981_value", "value", "", "", "")
        ),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "personconnectedlist_753001_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PersonConnectedList/PersonConnectedListComponent_753001_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiSearchObserverClient = result?.data.kisiSearchObserverClient;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonConnectedListComponent_753001_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonConnectedListComponent_753001_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_350368 = this.state.KisiSearchObserverClient;
    formVars.personconnectedlist_753001_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearchObserverClient?.length > 0
        ? stateVars.KisiSearchObserverClient[0].personCount
        : this.state.KisiSearchObserverClient?.length > 0
        ? this.state.KisiSearchObserverClient[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [259838, 295674] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.PersonConnectedListPageInit();
    }
    if (diId == 259838) {
      isErrorOccurred = await this.PersonConnectedListComponent_25249_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 295674) {
      isErrorOccurred = await this.PersonConnectedListComponent_259491_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
