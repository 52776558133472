import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  Header,
  HorizontalStack,
  Icon,
  KCol,
  KDrawer,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label
} from "../../components/web-components";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { SinifinOgrencileri_ScreenBase } from "./sinifinogrencileri-base";

import { Person_Screen } from "../person/person";
import { SinifYeniOgrenci_Screen } from "../sinifyeniogrenci/sinifyeniogrenci";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class SinifinOgrencileri_Screen extends SinifinOgrencileri_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{}}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="sinifinogrencileri_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="84486"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="996029"
                    xs={20}
                    sm={20}
                    md={20}
                    lg={20}
                    xl={20}
                    xxl={20}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="334340"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <Label
                        id="923160"
                        value={this.state.KurumTipiSelectById?.at?.(0)?.kirilim2ninozneleri ?? undefined}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "18pt",
                            color: "rgba(10, 33, 64, 1)",
                            textTransform: "capitalize"
                          } as any
                        }
                      ></Label>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="800621"
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    xxl={4}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Icon
                      id="321766"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.SinifinOgrencileriComponent_321766_onClick();
                      }}
                      showCursorPointer
                      iconName="close"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="sinifinogrencileri_body"
                style={
                  {
                    backgroundColor: "rgba(244, 244, 244, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="253218"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="485160"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="145438"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <Button
                        id="281715"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.SinifinOgrencileriComponent_281715_onClick();
                        }}
                        showCursorPointer
                        label={ReactSystemFunctions.translate(this.ml, 281715, "label", this.defaultML)}
                        size="middle"
                        loading={false}
                        ghost={false}
                        block={false}
                        htmlType="button"
                        iconPosition="left"
                        danger={false}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            textAlign: "-webkit-center",
                            color: "rgba(24, 144, 255, 1)",
                            textDecoration: "underline"
                          } as any
                        }
                      ></Button>

                      <Button
                        id="328508"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.SinifinOgrencileriComponent_328508_onClick();
                        }}
                        showCursorPointer
                        label={ReactSystemFunctions.translate(this.ml, 328508, "label", this.defaultML)}
                        size="middle"
                        loading={false}
                        ghost={false}
                        block={false}
                        htmlType="button"
                        iconPosition="left"
                        danger={false}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            textAlign: "-webkit-center",
                            color: "rgba(24, 144, 255, 1)",
                            textDecoration: "underline"
                          } as any
                        }
                      ></Button>

                      <Button
                        id="117613"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.SinifinOgrencileriComponent_117613_onClick();
                        }}
                        showCursorPointer
                        label={this.state.KurumTipiSelectById?.at?.(0)?.yeni ?? undefined}
                        size="middle"
                        loading={false}
                        ghost={false}
                        block={false}
                        htmlType="button"
                        iconPosition="left"
                        danger={false}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(0, 190, 214, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 16,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "16px",
                            color: "rgba(255, 255, 255, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Button>

                      <Button
                        id="134316"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.SinifinOgrencileriComponent_134316_onClick();
                        }}
                        showCursorPointer
                        label={ReactSystemFunctions.translate(this.ml, 134316, "label", this.defaultML)}
                        size="middle"
                        loading={false}
                        ghost={false}
                        block={false}
                        htmlType="button"
                        iconPosition="left"
                        danger={false}
                        style={
                          {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundColor: "rgba(0, 190, 214, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 16,
                            paddingBottom: 8,
                            paddingLeft: 16,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "16px",
                            color: "rgba(255, 255, 255, 1)",
                            lineHeight: "22px"
                          } as any
                        }
                      ></Button>
                    </HorizontalStack>
                  </KCol>
                </KRow>

                <KRow
                  id="720564"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="769800"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <KTable
                      id="399769"
                      kuikaRef={this.sinifinogrencileri_399769_value_kuikaTableRef}
                      form={this.props.form}
                      dataSource={this.state.SinifinOgrencileri}
                      size="middle"
                      bordered={true}
                      loading={false}
                      transformedOnMobileResolution={false}
                      showHeader={true}
                      fixedHeader={false}
                      editable={false}
                      tableWidthMode="fit"
                      globalSearch={false}
                      searchable={false}
                      sorter={true}
                      filtering={false}
                      pagination={false}
                      pageSize={10}
                      showSizeChanger={false}
                      columnChooser={false}
                      resizableColumns={false}
                      striped={false}
                      stripedColor="#F5F7FA"
                      insertRowActive={false}
                      insertRowPosition="top"
                      showNoDataFound={true}
                      nodatafoundmessage={ReactSystemFunctions.translate(
                        this.ml,
                        399769,
                        "nodatafoundmessage",
                        this.defaultML
                      )}
                      multiSelect={false}
                      export={false}
                      editableAlwaysActive={false}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KTableHeader
                        id="500856"
                        hideOnMobileResolution={false}
                        textDirection="Default"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "12px",
                            color: "rgba(10, 33, 64, 1)",
                            lineHeight: "20px"
                          } as any
                        }
                      ></KTableHeader>

                      <KTableRow
                        id="489075"
                        hoverFontColor="red"
                        hoverBgColor="#F5F5F5"
                        style={
                          {
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            height: "30px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KTableColumn
                          id="596062"
                          visibility={this.state.isComp596062Visible}
                          visibilityCondition={(rowData: object) =>
                            ReactSystemFunctions.isNotEqualTo(
                              this.state.MyContext[0]?.kurumTipiKod?.toString(),
                              "KT_School"
                            )
                          }
                          title={ReactSystemFunctions.translate(this.ml, 596062, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              width: "100px",
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="534977"
                            direction="horizontal"
                            size={4}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="93027"
                              value="[datafield:okulNumarasi]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </KTableColumn>

                        <KTableColumn
                          id="2977226"
                          title={this.state.KurumTipiSelectById?.at?.(0)?.adi ?? undefined}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="235501"
                            direction="horizontal"
                            size={4}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="7829763"
                              value="[datafield:adi]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="576548"
                              value="[datafield:soyadi]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </KTableColumn>

                        <KTableColumn
                          id="653088"
                          title={ReactSystemFunctions.translate(this.ml, 653088, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              width: "100px",
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="167095"
                            direction="horizontal"
                            size={4}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="854266"
                              value="[datafield:kisiyas]"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  color: "rgba(10, 33, 64, 1)"
                                } as any
                              }
                            ></Label>
                          </HorizontalStack>
                        </KTableColumn>

                        <KTableColumn
                          id="3249157"
                          title={ReactSystemFunctions.translate(this.ml, 3249157, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              width: "120px",
                              alignItems: "center",
                              textAlign: "-webkit-center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="1235982"
                            value="[datafield:cinsiyet]"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="540758"
                          title={ReactSystemFunctions.translate(this.ml, 540758, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              width: "120px",
                              alignItems: "center",
                              textAlign: "-webkit-center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="789071"
                            value="[datafield:dogumTarihi]"
                            formatter="date"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>
                        </KTableColumn>

                        <KTableColumn
                          id="792509"
                          title={ReactSystemFunctions.translate(this.ml, 792509, "title", this.defaultML)}
                          alwaysVisibleOnMobileResolution={false}
                          hideFiltering={false}
                          hideSorting={false}
                          hideSearch={false}
                          footerOptions="none"
                          style={
                            {
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(218, 218, 218, 1)",
                              borderStyle: "solid",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              width: "40px",
                              alignItems: "center",
                              textAlign: "-webkit-center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <HorizontalStack
                            id="893506"
                            direction="horizontal"
                            size={12}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          >
                            <Icon
                              id="518913"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SinifinOgrencileriComponent_518913_onClick();
                              }}
                              showCursorPointer
                              iconName="remove_red_eye"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "20px",
                                  color: "rgba(155, 155, 155, 1)",
                                  letterSpacing: "1px"
                                } as any
                              }
                            ></Icon>

                            <Icon
                              id="292996"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SinifinOgrencileriComponent_292996_onClick();
                              }}
                              showCursorPointer
                              iconName="create"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "20px",
                                  color: "rgba(155, 155, 155, 1)",
                                  letterSpacing: "1px"
                                } as any
                              }
                            ></Icon>

                            <Icon
                              id="441108"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.SinifinOgrencileriComponent_441108_onClick();
                              }}
                              showCursorPointer
                              iconName="person_remove"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 5,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "20px",
                                  color: "rgba(155, 155, 155, 1)",
                                  letterSpacing: "1px"
                                } as any
                              }
                            ></Icon>
                          </HorizontalStack>
                        </KTableColumn>
                      </KTableRow>
                    </KTable>
                  </KCol>
                </KRow>

                <KDrawer
                  id="295537"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible295537: false }, () => {
                      this.callCallbackFunction(295537);
                    });
                  }}
                  visible={this.state.NavVisible295537}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible295537 && (
                      <Person_Screen
                        onClose={() => {
                          this.setState({ NavVisible295537: false }, () => {
                            this.callCallbackFunction(295537);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="930166"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible930166: false }, () => {
                      this.callCallbackFunction(930166);
                    });
                  }}
                  visible={this.state.NavVisible930166}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible930166 && (
                      <Person_Screen
                        onClose={() => {
                          this.setState({ NavVisible930166: false }, () => {
                            this.callCallbackFunction(930166);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="76933"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible76933: false }, () => {
                      this.callCallbackFunction(76933);
                    });
                  }}
                  visible={this.state.NavVisible76933}
                  width="800px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible76933 && (
                      <SinifYeniOgrenci_Screen
                        onClose={() => {
                          this.setState({ NavVisible76933: false }, () => {
                            this.callCallbackFunction(76933);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(
    withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(SinifinOgrencileri_Screen))))
  )
);
export { tmp as SinifinOgrencileri_Screen };
//export default tmp;
//export { tmp as SinifinOgrencileri_Screen };
