import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IMerkezBasvuruForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IMerkezBasvuruForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  GoBack: any;
  GoBack_dummy: any;
  LocationGetCurrentCoordinates: any;
  LocationGetCurrentCoordinates_dummy: any;
  LocationAdressOfCoordinates: any;
  LocationAdressOfCoordinates_dummy: any;
  SetValueOf: any;
  SetValueOf_dummy: any;
  SelectOkulBransAll: any[];
  SelectOkulBransAll_dummy: any[];
  PhotoFromGallery: string;
  PhotoFromGallery_dummy: string;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  OpenInBrowser: any;
  OpenInBrowser_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  MailSubject: any[];
  MailSubject_dummy: any[];
  SehirByAd: any[];
  SehirByAd_dummy: any[];
  BasvuruMerkezSave: number;
  BasvuruMerkezSave_dummy: number;
  Notify: boolean;
  Notify_dummy: boolean;
  SendMail: boolean;
  SendMail_dummy: boolean;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp648497Visible: "visible" | "hidden";
  isComp253346Visible: "visible" | "hidden";
  isComp411140Visible: "visible" | "hidden";
  isComp344086Visible: "visible" | "hidden";
  isComp182940Visible: "visible" | "hidden";
  isComp808241Visible: "visible" | "hidden";
  isComp253619Visible: "visible" | "hidden";
  isComp484388Visible: "visible" | "hidden";
  isComp201562Visible: "visible" | "hidden";
  isComp893615Visible: "visible" | "hidden";
  isComp698930Visible: "visible" | "hidden";
  isComp436269Visible: "visible" | "hidden";
  isComp55920Visible: "visible" | "hidden";
  isComp133751Visible: "visible" | "hidden";
  isComp153705Visible: "visible" | "hidden";
}

export class MerkezBasvuruForm_ScreenBase extends React.PureComponent<IMerkezBasvuruForm_ScreenProps, any> {
  merkezbasvuruform_158152_value_kuikaDateRef: React.RefObject<any>;
  merkezbasvuruform_708851_value_kuikaSelectBoxRef: React.RefObject<any>;
  merkezbasvuruform_205425_value_kuikaPlacesAutocompleteInputRef: React.RefObject<any>;
  merkezbasvuruform_484841_value_kuikaLocationPickerComponentRef: React.RefObject<any>;
  merkezbasvuruform_480644_value_kuikaSelectBoxRef: React.RefObject<any>;
  merkezbasvuruform_907373_value_kuikaMultiSelectRef: React.RefObject<any>;
  merkezbasvuruform_622948_value_kuikaMultiSelectRef: React.RefObject<any>;
  merkezbasvuruform_395692_value_kuikaMultiSelectRef: React.RefObject<any>;
  merkezbasvuruform_635160_value_kuikaMultiSelectRef: React.RefObject<any>;
  merkezbasvuruform_911052_value_kuikaMultiSelectRef: React.RefObject<any>;
  merkezbasvuruform_642003_value_kuikaMultiSelectRef: React.RefObject<any>;
  merkezbasvuruform_345088_value_kuikaMultiSelectRef: React.RefObject<any>;
  merkezbasvuruform_55793_value_kuikaSelectBoxRef: React.RefObject<any>;
  merkezbasvuruform_672067_value_kuikaMultiSelectRef: React.RefObject<any>;
  merkezbasvuruform_122689_value_kuikaMultiSelectRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 153705, PropertyName: "label", Value: "Triger", TagName: "btnTrigger_label" },
        { Id: 702105, PropertyName: "value", Value: "Gym/School/Sport Club Application Form", TagName: "Label1_value" },
        {
          Id: 777862,
          PropertyName: "value",
          Value: "Your application will be evaluated by MeMap, and you will be notified via email upon approval.",
          TagName: "Label5_value"
        },
        { Id: 194783, PropertyName: "value", Value: "Personal Information", TagName: "Label2_value" },
        { Id: 482943, PropertyName: "value", Value: "Name", TagName: "Label1_0_value" },
        { Id: 378355, PropertyName: "placeholder", Value: "John", TagName: "txtAd_placeholder" },
        { Id: 50174, PropertyName: "value", Value: "Surname", TagName: "Label1_0_value" },
        { Id: 503657, PropertyName: "placeholder", Value: "Smith", TagName: "txtSoyad_placeholder" },
        { Id: 276803, PropertyName: "value", Value: "Birth date", TagName: "Label1_0_value" },
        { Id: 158152, PropertyName: "placeholder", Value: "01/01/2010", TagName: "dtDogumTarihi_placeholder" },
        { Id: 995851, PropertyName: "value", Value: "Biological gender", TagName: "Label1_0_value" },
        { Id: 708851, PropertyName: "placeholder", Value: "♀ / ♂", TagName: "sbCinsiyet_placeholder" },
        { Id: 216394, PropertyName: "value", Value: "E-mail", TagName: "Label2_value" },
        { Id: 615228, PropertyName: "placeholder", Value: "name@domainname.com", TagName: "txtEmail_placeholder" },
        { Id: 560047, PropertyName: "value", Value: "Mobile phone", TagName: "Label6_value" },
        { Id: 449007, PropertyName: "placeholder", Value: "0(5__) ___ __ __", TagName: "txtCebTelefonu_placeholder" },
        { Id: 94596, PropertyName: "value", Value: "Institution Information", TagName: "Label1_0_value" },
        { Id: 551137, PropertyName: "value", Value: "Name", TagName: "Label1_0_value" },
        { Id: 472037, PropertyName: "placeholder", Value: "Intitution title", TagName: "txtMerkezAd_placeholder" },
        { Id: 725813, PropertyName: "value", Value: "Web adress", TagName: "Label3_value" },
        { Id: 814517, PropertyName: "placeholder", Value: "http://www.", TagName: "txtWebAdresi_placeholder" },
        {
          Id: 940974,
          PropertyName: "value",
          Value: "For how many years has it been in operation?",
          TagName: "Label1_0_value"
        },
        { Id: 126412, PropertyName: "placeholder", Value: "3", TagName: "txtMerkezFaliyetSuresi_placeholder" },
        { Id: 121663, PropertyName: "value", Value: "Phone number", TagName: "txtTelefon_value" },
        { Id: 766814, PropertyName: "placeholder", Value: "0(5__) ___ __ __", TagName: "txtMerkezTelefon_placeholder" },
        { Id: 269665, PropertyName: "value", Value: "Adress:", TagName: "Label2_value" },
        {
          Id: 205425,
          PropertyName: "placeholder",
          Value: "Location search...",
          TagName: "LocationSearch1_placeholder"
        },
        { Id: 410740, PropertyName: "value", Value: "Logo", TagName: "Label1_0_value" },
        { Id: 901652, PropertyName: "value", Value: "Download", TagName: "Label1_1_value" },
        { Id: 354263, PropertyName: "value", Value: "Outdoor photo", TagName: "Label1_0_value" },
        { Id: 530494, PropertyName: "value", Value: "Download", TagName: "Label1_1_value" },
        { Id: 333084, PropertyName: "value", Value: "Download", TagName: "Label1_1_value" },
        { Id: 610824, PropertyName: "value", Value: "Download", TagName: "Label1_1_value" },
        { Id: 781119, PropertyName: "value", Value: "Indoor", TagName: "Label1_0_value" },
        { Id: 456376, PropertyName: "value", Value: "Download", TagName: "Label1_1_value" },
        { Id: 669073, PropertyName: "value", Value: "Download", TagName: "Label1_1_value" },
        { Id: 112006, PropertyName: "value", Value: "Download", TagName: "Label1_1_value" },
        { Id: 684734, PropertyName: "value", Value: "Physical facilities", TagName: "Label1_0_value" },
        { Id: 987392, PropertyName: "value", Value: "Choose the type of institution", TagName: "Label1_0_value" },
        { Id: 480644, PropertyName: "placeholder", Value: "Choose...", TagName: "sbMerkezTipi_placeholder" },
        { Id: 978389, PropertyName: "value", Value: "Consultation area with the client", TagName: "Label1_0_value" },
        { Id: 829602, PropertyName: "value", Value: "Computer", TagName: "Label1_0_value" },
        { Id: 357571, PropertyName: "value", Value: "Blood pressure measurement device", TagName: "Label1_0_value" },
        { Id: 37117, PropertyName: "value", Value: "Heart rate monitor", TagName: "Label1_0_value" },
        {
          Id: 513259,
          PropertyName: "value",
          Value: "Please mark your body composition evaluation facilities.",
          TagName: "Label1_0_value"
        },
        {
          Id: 606673,
          PropertyName: "value",
          Value: "Please mark your cardiorespiratory equipment facilities.",
          TagName: "Label1_0_value"
        },
        { Id: 536366, PropertyName: "value", Value: "Other activity areas", TagName: "Label1_0_value" },
        {
          Id: 706421,
          PropertyName: "value",
          Value: "If you provide group classes, choose...",
          TagName: "Label1_0_value"
        },
        {
          Id: 723655,
          PropertyName: "value",
          Value: "Do you have programs related to special populations?",
          TagName: "Label1_0_value"
        },
        {
          Id: 677991,
          PropertyName: "value",
          Value: "Please mark your muscle strength and flexibility equipment facilities.",
          TagName: "Label1_0_value"
        },
        {
          Id: 33942,
          PropertyName: "value",
          Value: "Please mark the services you want to provide.",
          TagName: "Label1_0_value"
        },
        {
          Id: 769458,
          PropertyName: "value",
          Value: "Please choose the sports branches of your sport club.",
          TagName: "Label1_0_value"
        },
        {
          Id: 769119,
          PropertyName: "value",
          Value: "Please choose which students at your school will receive Fitness Check-Up.",
          TagName: "Label1_0_value"
        },
        {
          Id: 278510,
          PropertyName: "value",
          Value: "Please choose the physical facilities at your school.",
          TagName: "Label1_0_value"
        },
        {
          Id: 133574,
          PropertyName: "value",
          Value: "Would you like to offer Fitness Check-Up service to students outside of your school?",
          TagName: "Label1_0_value"
        },
        {
          Id: 298811,
          PropertyName: "value",
          Value: "Would you like to offer Adult Fitness Check-Up service for parents?",
          TagName: "Label1_1_value"
        },
        {
          Id: 572567,
          PropertyName: "value",
          Value: "Would you like to be part of MeMap platform?",
          TagName: "Label73_value"
        },
        {
          Id: 306763,
          PropertyName: "value",
          Value: "I want to get information about the benefits of the MeMap platform.",
          TagName: "Label2_value"
        },
        {
          Id: 34124,
          PropertyName: "value",
          Value: "I have read, understood, and accepted the Consent Text.",
          TagName: "Label1_1_value"
        },
        {
          Id: 650532,
          PropertyName: "value",
          Value: "KVKK Aydınlatma Metni'ni Okudum, Anladım.",
          TagName: "Label1_1_value"
        },
        {
          Id: 438696,
          PropertyName: "label",
          Value: "I have read and understood the Personal Data Protection Law Information Text.",
          TagName: "Button1_label"
        },
        { Id: 203679, PropertyName: "value", Value: "Explore", TagName: "Label1_0_value" },
        { Id: 766036, PropertyName: "value", Value: "Fitness Check-Up", TagName: "Label1_1_value" },
        { Id: 356086, PropertyName: "value", Value: "FitSkor®", TagName: "Label1_2_value" },
        { Id: 186162, PropertyName: "value", Value: "Pricing", TagName: "Label1_3_value" },
        { Id: 952572, PropertyName: "value", Value: "Where to find", TagName: "Label1_4_value" },
        { Id: 712946, PropertyName: "value", Value: "Thought Leadership", TagName: "Label1_5_value" },
        { Id: 366524, PropertyName: "value", Value: "Terms of Use", TagName: "Label1_6_value" },
        { Id: 538837, PropertyName: "value", Value: "Privacy Policy", TagName: "Label1_7_value" },
        { Id: 969930, PropertyName: "value", Value: "Platform Guide", TagName: "Label1_8_value" },
        { Id: 875241, PropertyName: "value", Value: "What is MeMap?", TagName: "Label1_0_value" },
        { Id: 696653, PropertyName: "value", Value: "For You", TagName: "Label1_1_value" },
        { Id: 232374, PropertyName: "value", Value: "For Trainers", TagName: "Label1_2_value" },
        { Id: 199576, PropertyName: "value", Value: "For Gyms", TagName: "Label1_3_value" },
        { Id: 456180, PropertyName: "value", Value: "For Companies", TagName: "Label1_4_value" },
        { Id: 215203, PropertyName: "value", Value: "For Academics", TagName: "Label1_5_value" },
        { Id: 716117, PropertyName: "value", Value: "FAQ", TagName: "Label1_6_value" },
        { Id: 477712, PropertyName: "value", Value: "Distance Sale Agreement", TagName: "Label1_7_value" },
        { Id: 452093, PropertyName: "value", Value: "Contact", TagName: "Label1_8_value" },
        {
          Id: 358664,
          PropertyName: "value",
          Value:
            "© 2023 MeMap all rights reserved. MeMap is a method for preparing customized exercise strategy and prescription. Technology protected by U.S. Patent and EPO patent and other patent pending applications.",
          TagName: "Label1_0_value"
        },
        {
          Id: 82109133,
          PropertyName: "431cac2d-6c60-885f-0598-73d799b88de1_notify",
          Value: "You need to approve the PDPL text.",
          TagName: "Notify_notify",
          IsNotify: true
        },
        {
          Id: 71143315,
          PropertyName: "cdfc63f1-0753-723a-9b25-dadf3aeb1227_notify",
          Value: "You need to approve the explicit consent text.",
          TagName: "Notify_notify",
          IsNotify: true
        }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "d7fdd58a-89be-4c38-9e6e-eae6a3eafbb3",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 153705, PropertyName: "label", Value: "Trigger" },
        { Id: 702105, PropertyName: "value", Value: "Merkez/Okul/Kulüp Başvuru Formu" },
        {
          Id: 777862,
          PropertyName: "value",
          Value: "Kurum başvurunuz MeMap tarafından değerlendirilecek ve onaylandığında e-posta ile bilgi verilecektir."
        },
        { Id: 194783, PropertyName: "value", Value: "Kişisel Bilgiler" },
        { Id: 482943, PropertyName: "value", Value: "Adınız" },
        { Id: 378355, PropertyName: "placeholder", Value: "Doruk" },
        { Id: 50174, PropertyName: "value", Value: "Soyadınız" },
        { Id: 503657, PropertyName: "placeholder", Value: "Bartu" },
        { Id: 276803, PropertyName: "value", Value: "Doğum Tarihiniz" },
        { Id: 158152, PropertyName: "placeholder", Value: "01/01/2030" },
        { Id: 995851, PropertyName: "value", Value: "Biyolojik cinsiyetiniz" },
        { Id: 708851, PropertyName: "placeholder", Value: "♀ / ♂" },
        { Id: 216394, PropertyName: "value", Value: "Email" },
        { Id: 615228, PropertyName: "placeholder", Value: "name@domainname.com" },
        { Id: 560047, PropertyName: "value", Value: "Cep Telefonu" },
        { Id: 449007, PropertyName: "placeholder", Value: "0(5__) ___ __ __" },
        { Id: 94596, PropertyName: "value", Value: "Kurum Bilgileri" },
        { Id: 551137, PropertyName: "value", Value: "İsim" },
        { Id: 472037, PropertyName: "placeholder", Value: "Ünvan" },
        { Id: 725813, PropertyName: "value", Value: "Web Adresi" },
        { Id: 814517, PropertyName: "placeholder", Value: "http://www." },
        { Id: 940974, PropertyName: "value", Value: "Kaç Yıldır Faliyet Göstermektedir?" },
        { Id: 126412, PropertyName: "placeholder", Value: "3" },
        { Id: 121663, PropertyName: "value", Value: "Telefon" },
        { Id: 766814, PropertyName: "placeholder", Value: "0(5__) ___ __ __" },
        { Id: 204421, PropertyName: "value", Value: "Kurum e-posta" },
        { Id: 33863, PropertyName: "placeholder", Value: "name@domainname.com" },
        { Id: 269665, PropertyName: "value", Value: "Adres" },
        { Id: 205425, PropertyName: "placeholder", Value: "Adres yazarak lokasyon arama..." },
        { Id: 410740, PropertyName: "value", Value: "Logo" },
        { Id: 901652, PropertyName: "value", Value: "Yükle" },
        { Id: 354263, PropertyName: "value", Value: "Dış Mekan" },
        { Id: 530494, PropertyName: "value", Value: "Yükle" },
        { Id: 333084, PropertyName: "value", Value: "Yükle" },
        { Id: 610824, PropertyName: "value", Value: "Yükle" },
        { Id: 781119, PropertyName: "value", Value: "İç Mekan" },
        { Id: 456376, PropertyName: "value", Value: "Yükle" },
        { Id: 669073, PropertyName: "value", Value: "Yükle" },
        { Id: 112006, PropertyName: "value", Value: "Yükle" },
        { Id: 684734, PropertyName: "value", Value: "Fiziksel Olanaklar" },
        { Id: 987392, PropertyName: "value", Value: "Merkez türünü seçin" },
        { Id: 480644, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 978389, PropertyName: "value", Value: "Danışan ile görüşme alanı" },
        { Id: 829602, PropertyName: "value", Value: "Bilgisayar" },
        { Id: 357571, PropertyName: "value", Value: "Kan basıncı ölçüm cihazı" },
        { Id: 37117, PropertyName: "value", Value: "Nabız ölçer" },
        { Id: 513259, PropertyName: "value", Value: "Vücut kompozisyonu ölçüm olanaklarınızı işaretleyiniz" },
        { Id: 907373, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 606673, PropertyName: "value", Value: "Kardiyorespiratuar ekipman olanaklarınızı işaretleyiniz" },
        { Id: 622948, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 536366, PropertyName: "value", Value: "Diğer aktivite alanları" },
        { Id: 395692, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 706421, PropertyName: "value", Value: "Grup dersleriniz varsa işaretleyiniz" },
        { Id: 635160, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 723655, PropertyName: "value", Value: "Özel popülasyonlarla ilgili programlarınız var mı?" },
        { Id: 911052, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 677991, PropertyName: "value", Value: "Kas kuvveti ve esneklik ekipman olanaklarınızı işaretleyiniz." },
        { Id: 642003, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 33942, PropertyName: "value", Value: "Vermek istediğiniz hizmetleri işaretleyiniz." },
        { Id: 345088, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 769458, PropertyName: "value", Value: "Kulübünüzün spor branşını seçiniz." },
        { Id: 55793, PropertyName: "placeholder", Value: "Seçiniz..." },
        {
          Id: 769119,
          PropertyName: "value",
          Value: "Okulunuzda hangi öğrencilere Fitness Check-Up yapılacağını seçiniz."
        },
        { Id: 672067, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 278510, PropertyName: "value", Value: "Okulunuzdaki fiziksel olanakları seçiniz." },
        { Id: 122689, PropertyName: "placeholder", Value: "Seçiniz..." },
        {
          Id: 133574,
          PropertyName: "value",
          Value: "Okulunuz dışındaki öğrencilere Fitness Check-Up hizmeti vermek istermisiniz?"
        },
        {
          Id: 298811,
          PropertyName: "value",
          Value: "Velilere yönelik Yetişkin Fitness Check-Up hizmeti vermek ister misiniz?"
        },
        { Id: 572567, PropertyName: "value", Value: "MeMap platformunda yer almak ister misiniz?" },
        { Id: 306763, PropertyName: "value", Value: "MeMap platformunun faydaları hakkında bilgi almak istiyorum." },
        { Id: 190858, PropertyName: "value", Value: "Açık Rıza Metni" },
        { Id: 401534, PropertyName: "value", Value: "ni okudum, anladım, kabul ediyorum." },
        { Id: 901848, PropertyName: "value", Value: "KVKK Aydınlatma Metni" },
        { Id: 48507, PropertyName: "value", Value: "'ni okudum, anladım." },
        { Id: 438696, PropertyName: "label", Value: "Gönder" },
        { Id: 203679, PropertyName: "value", Value: "Keşfet" },
        { Id: 766036, PropertyName: "value", Value: "Fitness Check-Up" },
        { Id: 356086, PropertyName: "value", Value: "FitSkor®" },
        { Id: 186162, PropertyName: "value", Value: "Fiyatlandırma" },
        { Id: 952572, PropertyName: "value", Value: "Nerede Bulurum" },
        { Id: 712946, PropertyName: "value", Value: "Düşünce Liderliği" },
        { Id: 366524, PropertyName: "value", Value: "Üyelik Sözleşmesi" },
        { Id: 538837, PropertyName: "value", Value: "Aydınlatma Metni" },
        { Id: 969930, PropertyName: "value", Value: "İşlem Rehberi" },
        { Id: 875241, PropertyName: "value", Value: "MeMap Nedir?" },
        { Id: 696653, PropertyName: "value", Value: "Senin için" },
        { Id: 232374, PropertyName: "value", Value: "Eğitmenler için" },
        { Id: 199576, PropertyName: "value", Value: "Merkezler için" },
        { Id: 456180, PropertyName: "value", Value: "Kurumlar için" },
        { Id: 215203, PropertyName: "value", Value: "Akademik Kullanım" },
        { Id: 716117, PropertyName: "value", Value: "Sıkça Sorulan Sorular" },
        { Id: 477712, PropertyName: "value", Value: "Mesafeli Satış Sözleşmesi" },
        { Id: 452093, PropertyName: "value", Value: "İletişim" },
        {
          Id: 358664,
          PropertyName: "value",
          Value:
            "© 2017 MeMap tüm hakları saklıdır. MeMap, özelleştirilmiş egzersiz stratejisi ve reçetesi hazırlamak için bir yöntemdir. ABD Patent ofisi ve EPO patent ofisi ve diğer patent bekleyen başvurularla korunan bir teknolojidir."
        }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.merkezbasvuruform_158152_value_kuikaDateRef = React.createRef();
    this.merkezbasvuruform_708851_value_kuikaSelectBoxRef = React.createRef();
    this.merkezbasvuruform_205425_value_kuikaPlacesAutocompleteInputRef = React.createRef();
    this.merkezbasvuruform_484841_value_kuikaLocationPickerComponentRef = React.createRef();
    this.merkezbasvuruform_480644_value_kuikaSelectBoxRef = React.createRef();
    this.merkezbasvuruform_907373_value_kuikaMultiSelectRef = React.createRef();
    this.merkezbasvuruform_622948_value_kuikaMultiSelectRef = React.createRef();
    this.merkezbasvuruform_395692_value_kuikaMultiSelectRef = React.createRef();
    this.merkezbasvuruform_635160_value_kuikaMultiSelectRef = React.createRef();
    this.merkezbasvuruform_911052_value_kuikaMultiSelectRef = React.createRef();
    this.merkezbasvuruform_642003_value_kuikaMultiSelectRef = React.createRef();
    this.merkezbasvuruform_345088_value_kuikaMultiSelectRef = React.createRef();
    this.merkezbasvuruform_55793_value_kuikaSelectBoxRef = React.createRef();
    this.merkezbasvuruform_672067_value_kuikaMultiSelectRef = React.createRef();
    this.merkezbasvuruform_122689_value_kuikaMultiSelectRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      ChangeVisibilityOf: "",
      GoBack: "",
      LocationGetCurrentCoordinates: "",
      LocationAdressOfCoordinates: "",
      SetValueOf: "",
      SelectOkulBransAll: [],
      PhotoFromGallery: "",
      TriggerEvent: "",
      OpenInBrowser: "",
      MyContext: [],
      MailSubject: [],
      IfThenElse: false,
      NAVIGATE: "",
      dataSource_708851: [
        { key: "K", text: "Kadın" },
        { key: "E", text: "Erkek" }
      ],
      dataSource_480644: [
        { key: "Fitness Merkezi", text: "Fitness Merkezi" },
        { key: "Okul", text: "Okul" },
        { key: "Spor Kulübü", text: "Spor Kulübü" }
      ],
      dataSource_907373: [
        { key: "Beden kitle indeksi", text: "Beden kitle indeksi" },
        { key: "Biyoelektrik impedans analizi", text: "Biyoelektrik impedans analizi" },
        { key: "Skinfold kaliper", text: "Skinfold kaliper" }
      ],
      dataSource_622948: [
        { key: "Koşu bandı", text: "Koşu bandı" },
        { key: "Bacak ergometre", text: "Bacak ergometre" },
        { key: "Kol ergometre", text: "Kol ergometre" },
        { key: "Kürek ergometre", text: "Kürek ergometre" },
        { key: "Step tahtası", text: "Step tahtası" },
        { key: "Açık ya da kapalı saha (eğimsiz zemin)", text: "Açık ya da kapalı saha (eğimsiz zemin)" }
      ],
      dataSource_395692: [
        { key: "Açık yüzme havuzu", text: "Açık yüzme havuzu" },
        { key: "Kapalı yüzme havuzu", text: "Kapalı yüzme havuzu" },
        { key: "Tenis kortu", text: "Tenis kortu" },
        { key: "Squash kortu", text: "Squash kortu" },
        { key: "Masa tenisi", text: "Masa tenisi" }
      ],
      dataSource_635160: [
        { key: "Spinning", text: "Spinning" },
        { key: "Step", text: "Step" },
        { key: "Zumba", text: "Zumba" },
        { key: "Dans temelli aerobik", text: "Dans temelli aerobik" },
        { key: "Dövüş temelli aerobik", text: "Dövüş temelli aerobik" },
        { key: "TRX", text: "TRX" },
        { key: "Yoga", text: "Yoga" },
        { key: "Mat pilates", text: "Mat pilates" },
        { key: "Reformer pilates", text: "Reformer pilates" },
        { key: "Bosu", text: "Bosu" },
        { key: "Fonksiyonel", text: "Fonksiyonel" },
        { key: "Bootcamp", text: "Bootcamp" }
      ],
      dataSource_911052: [
        { key: "Hamilelik", text: "Hamilelik" },
        { key: "Yaşlılık", text: "Yaşlılık" },
        { key: "Çocuk", text: "Çocuk" },
        { key: "Obezite", text: "Obezite" },
        { key: "Koroner kalp hastalıkları", text: "Koroner kalp hastalıkları" },
        { key: "Diyabet", text: "Diyabet" }
      ],
      dataSource_642003: [
        { key: "Leg press", text: "Leg press" },
        { key: "Bench press", text: "Bench press" },
        { key: "Smith press", text: "Smith press" },
        { key: "Lat pull down", text: "Lat pull down" },
        { key: "Serbest ağırlıklar", text: "Serbest ağırlıklar" },
        { key: "Egzersiz minderi", text: "Egzersiz minderi" },
        { key: "Goniometre", text: "Goniometre" },
        { key: "Otur-Uzan kutusu", text: "Otur-Uzan kutusu" }
      ],
      dataSource_345088: [
        { key: "Yetişkin Fitness Check-Up", text: "Yetişkin Fitness Check-Up" },
        { key: "Junior Fitness Check-Up", text: "Junior Fitness Check-Up" },
        { key: "Senior Fitness Check-Up", text: "Senior Fitness Check-Up" },
        { key: "Kendi Check-Up protokolümüz var", text: "Kendi Check-Up protokolümüz var" }
      ],
      dataSource_672067: [
        { key: "Anaokulu", text: "Anaokulu" },
        { key: "İlk Okul", text: "İlk Okul" },
        { key: "Orta Okul", text: "Orta Okul" },
        { key: "Lise", text: "Lise" }
      ],
      dataSource_122689: [
        { key: "Bahçe", text: "Bahçe" },
        { key: "Kapalı spor salonu", text: "Kapalı spor salonu" },
        { key: "Koşu Pisti", text: "Koşu Pisti" }
      ],
      isComp648497Visible: "hidden",
      isComp253346Visible: "hidden",
      isComp411140Visible: "hidden",
      isComp344086Visible: "hidden",
      isComp182940Visible: "hidden",
      isComp808241Visible: "hidden",
      isComp253619Visible: "hidden",
      isComp484388Visible: "hidden",
      isComp201562Visible: "hidden",
      isComp893615Visible: "hidden",
      isComp698930Visible: "hidden",
      isComp436269Visible: "hidden",
      isComp55920Visible: "hidden",
      isComp133751Visible: "hidden",
      isComp153705Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("merkezbasvuruform", "");
      return;
    }

    if (true) {
      await this.MerkezBasvuruFormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("merkezbasvuruform", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("merkezbasvuruform", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.MerkezBasvuruFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      merkezbasvuruform_311048_value: false,
      merkezbasvuruform_229717_value: false,
      merkezbasvuruform_322859_value: false,
      merkezbasvuruform_440716_value: false,
      merkezbasvuruform_803102_value: false,
      merkezbasvuruform_846816_value: false,
      merkezbasvuruform_262958_value: false,
      merkezbasvuruform_29927_value: false,
      merkezbasvuruform_882933_value: false,
      merkezbasvuruform_846209_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  MerkezBasvuruFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.LocationGetCurrentCoordinates = (
      (await ReactSystemFunctions.locationGetCurrentCoordinates()) as any
    ).data;
    formVars.merkezbasvuruform_484841_latitude = ReactSystemFunctions.toDecimal(
      this,
      stateVars.LocationGetCurrentCoordinates?.latitude
    );
    formVars.merkezbasvuruform_484841_longitude = ReactSystemFunctions.toDecimal(
      this,
      stateVars.LocationGetCurrentCoordinates?.longitude
    );
    stateVars.LocationAdressOfCoordinates = (
      (await ReactSystemFunctions.locationAdressOfCoordinates(
        ReactSystemFunctions.toDecimal(this, stateVars.LocationGetCurrentCoordinates?.latitude),
        ReactSystemFunctions.toDecimal(this, stateVars.LocationGetCurrentCoordinates?.longitude)
      )) as any
    ).data;
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "merkezbasvuruform_205425_value",
      ReactSystemFunctions.toString(this, stateVars.LocationAdressOfCoordinates?.address),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MerkezBasvuruFormPageInit1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MerkezBasvuruFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MerkezBasvuruForm/MerkezBasvuruFormPageInit1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectOkulBransAll = result?.data.selectOkulBransAll;

    formVars.merkezbasvuruform_55793_options = stateVars.SelectOkulBransAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MerkezBasvuruFormPageInit2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MerkezBasvuruFormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    var value_484841 = {} as any;
    value_484841.latitude = ReactSystemFunctions.toDecimal(this, this.state.LocationGetCurrentCoordinates?.latitude);
    value_484841.longitude = ReactSystemFunctions.toDecimal(this, this.state.LocationGetCurrentCoordinates?.longitude);

    formVars.merkezbasvuruform_484841_value = value_484841;
    var value_484841 = {} as any;
    value_484841.latitude = ReactSystemFunctions.toDecimal(this, this.state.LocationGetCurrentCoordinates?.latitude);
    value_484841.longitude = ReactSystemFunctions.toDecimal(this, this.state.LocationGetCurrentCoordinates?.longitude);

    formVars.merkezbasvuruform_484841_value = value_484841;

    stateVars.dataSource_55793 = this.state.SelectOkulBransAll;
    stateVars.dataSource_55793 = this.state.SelectOkulBransAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_153705_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp648497Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp253346Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp411140Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp344086Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp182940Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp808241Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp253619Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp484388Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp201562Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_513932_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_205425_onSelectPlace = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "merkezbasvuruform_484841_value",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "merkezbasvuruform_205425_value", "Latitude", "", "", "")
      ),
      "latitude"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "merkezbasvuruform_484841_value",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "merkezbasvuruform_205425_value", "Longitude", "", "", "")
      ),
      "longitude"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_484841_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.LocationAdressOfCoordinates = (
      (await ReactSystemFunctions.locationAdressOfCoordinates(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_484841_value", "Latitude", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_484841_value", "Longitude", "", "", "")
        )
      )) as any
    ).data;
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "merkezbasvuruform_205425_value",
      ReactSystemFunctions.toString(this, stateVars.LocationAdressOfCoordinates?.address),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_901652_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MerkezBasvuruFormComponent_901652_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_901652_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "merkezbasvuruform_41134_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_530494_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MerkezBasvuruFormComponent_530494_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_530494_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "merkezbasvuruform_912502_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_333084_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MerkezBasvuruFormComponent_333084_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_333084_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "merkezbasvuruform_605384_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_610824_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MerkezBasvuruFormComponent_610824_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_610824_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "merkezbasvuruform_487484_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_456376_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MerkezBasvuruFormComponent_456376_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_456376_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "merkezbasvuruform_866980_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_669073_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MerkezBasvuruFormComponent_669073_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_669073_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "merkezbasvuruform_127011_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_112006_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MerkezBasvuruFormComponent_112006_onClick1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_112006_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.PhotoFromGallery), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "merkezbasvuruform_720139_value",
        ReactSystemFunctions.value(this, this.state.PhotoFromGallery),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_480644_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.MerkezBasvuruFormComponent_153705_onClick()) return true;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Okul"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp484388Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Okul"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp201562Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Okul"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp808241Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Okul"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp344086Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Okul"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp182940Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Okul"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp411140Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Fitness Merkezi"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp201562Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Fitness Merkezi"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp648497Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Fitness Merkezi"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp253346Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Fitness Merkezi"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp253619Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Spor Kulübü"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp893615Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Okul"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp698930Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Okul"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp436269Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Okul"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp55920Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_480644_onChange15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_480644_onChange15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "Okul"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp133751Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_190858_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://www.memap.com.tr/tr/pages/permitionfortransferdataabroad/",
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_901848_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/privacy_policy_tr/",
      "newtab"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_438696_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_480644_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      lang_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      basvurutipi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MerkezBasvuruForm/MerkezBasvuruFormComponent_438696_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.MailSubject = result?.data.mailSubject;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MerkezBasvuruFormComponent_438696_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_438696_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      sehirAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.LocationAdressOfCoordinates?.city),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MerkezBasvuruForm/MerkezBasvuruFormComponent_438696_onClick_if1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SehirByAd = result?.data.sehirByAd;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MerkezBasvuruFormComponent_438696_onClick_if11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_438696_onClick_if11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_378355_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_378355_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_708851_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_708851_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_615228_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_615228_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_615228_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "merkezbasvuruform_615228_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_126412_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_126412_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_41134_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_41134_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_472037_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_472037_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_766814_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "merkezbasvuruform_766814_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_503657_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_503657_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_449007_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_449007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_480644_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_158152_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_158152_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_33863_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_33863_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_33863_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "merkezbasvuruform_33863_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Ad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_378355_value", "value", "", "", "")
        ),
        "string"
      ),
      BransId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_55793_value", "value", "SelectOkulBransAll", "id", "id")
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_205425_value", "Address", "", "", "")
        ),
        "string"
      ),
      AktiviteAlanlari_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_395692_value", "value", "", "key", "")
        ),
        "string"
      ),
      Bilgisayar_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_229717_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_708851_value", "value", "", "key", "")
        ),
        "string"
      ),
      DanisanGorus_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_311048_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Eposta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_615228_value", "value", "", "", "")
        ),
        "string"
      ),
      FaaliyetSure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_126412_value", "value", "", "", "")
        ),
        "string"
      ),
      GrupDers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_635160_value", "value", "", "key", "")
        ),
        "string"
      ),
      Hizmet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_345088_value", "value", "", "key", "")
        ),
        "string"
      ),
      KanBasinc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_322859_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Kardio_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_622948_value", "value", "", "key", "")
        ),
        "string"
      ),
      KasKuvvet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_642003_value", "value", "", "key", "")
        ),
        "string"
      ),
      KlubSporBrans_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      latitude_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_484841_value", "Latitude", "", "", "")
        ),
        "string"
      ),
      Logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_41134_value", "value", "", "", "")
        ),
        "string"
      ),
      longitude_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_484841_value", "Longitude", "", "", "")
        ),
        "string"
      ),
      MekanFotoAlti_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_487484_value", "value", "", "", "")
        ),
        "string"
      ),
      MekanFotoBes_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_605384_value", "value", "", "", "")
        ),
        "string"
      ),
      MekanFotoBir_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_866980_value", "value", "", "", "")
        ),
        "string"
      ),
      MekanFotoDort_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_912502_value", "value", "", "", "")
        ),
        "string"
      ),
      MekanFotoiki_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_127011_value", "value", "", "", "")
        ),
        "string"
      ),
      MekanFotoUc_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_720139_value", "value", "", "", "")
        ),
        "string"
      ),
      MemapFayda_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_29927_value", "value", "", "", "")
        ),
        "boolean"
      ),
      MemapYer_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_262958_value", "value", "", "", "")
        ),
        "boolean"
      ),
      MerkezAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_472037_value", "value", "", "", "")
        ),
        "string"
      ),
      MerkezTelefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_766814_value", "value", "", "", "")
        ),
        "string"
      ),
      NabizOlcer_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_440716_value", "value", "", "", "")
        ),
        "boolean"
      ),
      OkulFitness_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_803102_value", "value", "", "", "")
        ),
        "boolean"
      ),
      OkulFizikselOlanak_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_122689_value", "value", "", "key", "")
        ),
        "string"
      ),
      OkulOgrenci_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_672067_value", "value", "", "key", "")
        ),
        "string"
      ),
      OkulVei_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_846816_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Popi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_911052_value", "value", "", "key", "")
        ),
        "string"
      ),
      Soyad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_503657_value", "value", "", "", "")
        ),
        "string"
      ),
      Statu_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      Telefon_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_449007_value", "value", "", "", "")
        ),
        "string"
      ),
      Tip_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "string"
      ),
      VKO_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_907373_value", "value", "", "key", "")
        ),
        "string"
      ),
      WebAdres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_814517_value", "value", "", "", "")
        ),
        "string"
      ),
      sehirId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.SehirByAd?.length > 0
            ? stateVars.SehirByAd[0].id
            : this.state.SehirByAd?.length > 0
            ? this.state.SehirByAd[0].id
            : null
        ),
        "Guid"
      ),
      dogumTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_158152_value", "value", "", "", "")
        ),
        "Date"
      ),
      kurumEposta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_33863_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_846209_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "MerkezBasvuruForm/MerkezBasvuruFormComponent_438696_onClick_if11_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.BasvuruMerkezSave = result?.data.basvuruMerkezSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_438696_onClick_if12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_438696_onClick_if12_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_438696_onClick_if12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_846209_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "67c864a8_9f63_d326_3290_66ca61cffa80_notify",
          this.defaultML,
          "Başvurunuz başarı ile tamamlandı"
        ),
        "success",
        "bottom-right",
        10,
        "",
        "",
        0
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_438696_onClick_if13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_438696_onClick_if13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_438696_onClick_if13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_480644_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_472037_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "merkezbasvuruform_472037_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("0d0e1234-fabf-43d2-a60f-79a9933c8fb7", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName("info@memap.com.tr", "string"),
      subject_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MailSubject?.length > 0
            ? stateVars.MailSubject[0].mail5Subject
            : this.state.MailSubject?.length > 0
            ? this.state.MailSubject[0].mail5Subject
            : null
        ),
        "string"
      ),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_0: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      basvurutipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_480644_value", "value", "", "key", "")
        ),
        "string"
      ),
      ad_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_472037_value", "value", "", "", "")
        ),
        "string"
      ),
      soyad_0: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_846209_value", "value", "", "", "")
        ),
        true
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "MerkezBasvuruForm/MerkezBasvuruFormComponent_438696_onClick_if13_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_438696_onClick_if14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_438696_onClick_if14_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_438696_onClick_if14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_846209_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.GoBack = await ReactSystemFunctions.goBack(this);

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_438696_onClick_if15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MerkezBasvuruFormComponent_438696_onClick_if15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  MerkezBasvuruFormComponent_438696_onClick_if15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_846209_value", "value", "", "", "")
        ),
        false
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "431cac2d_6c60_885f_0598_73d799b88de1_notify",
          this.defaultML,
          "KVKK metnini onaylamanız gereklidir."
        ),
        "default",
        "bottom-right",
        0,
        "",
        "",
        0
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_438696_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "cdfc63f1_0753_723a_9b25_dadf3aeb1227_notify",
        this.defaultML,
        "Açık rıza metnini onaylamanız gereklidir."
      ),
      "default",
      "bottom-right",
      0,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_438696_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "merkezbasvuruform_882933_value", "value", "", "", "")
        ),
        true
      )
    ) {
      isErrorOccurred = await this.MerkezBasvuruFormComponent_438696_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.MerkezBasvuruFormComponent_438696_onClick_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_766036_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/page/fitness_checkup/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_356086_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/page/fitskor/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_186162_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/page/pricing/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_952572_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/gyms/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_712946_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/page/thought-leadership/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_366524_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/terms_of_use_tr/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_538837_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/privacy_policy_tr/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_969930_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/platform_guide/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_696653_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/page/for-you/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_232374_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MerkezBasvuruForm",
      "EgitmenBasvuruFormu",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_199576_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MerkezBasvuruForm",
      "MerkezBasvuruForm",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_456180_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MerkezBasvuruForm",
      "KurumBasvuruForm",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_215203_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/page/for-schools/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_716117_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser("https://memap.com.tr/tr/faq/", "");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_477712_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/sale_agreement/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MerkezBasvuruFormComponent_452093_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      "https://memap.com.tr/tr/pages/memap_contact/",
      ""
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, ,] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.MerkezBasvuruFormPageInit();
    }
  }
}
