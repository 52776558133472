import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDrawer,
  KModal,
  KPanel,
  KRow,
  Label,
  LayoutLeft,
  Menu,
  MenuItem,
  VerticalStack
} from "../../components/web-components";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { Master_ScreenBase } from "./master-base";

import { EgitmenSozlesmesiYeni_Screen } from "../egitmensozlesmesiyeni/egitmensozlesmesiyeni";
import { KurumSozlesmesi_Screen } from "../kurumsozlesmesi/kurumsozlesmesi";
import { KVKKAydinlatmaSozlesmesi_Screen } from "../kvkkaydinlatmasozlesmesi/kvkkaydinlatmasozlesmesi";
import { Settings_Screen } from "../settings/settings";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class Master_Screen extends Master_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              ...this.props.initialValues,
              master_189293_value: this.state.KurumSelectById?.at?.(0)?.logo ?? undefined,
              master_920284_value: { collapsed: false, selectedKey: "" }
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div>
              <div style={{ display: "flex" }}>
                <LayoutLeft
                  id="Minus12"
                  id="master_layout_left"
                  layoutPosition={1}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      width: "100px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "rgba(10, 33, 64, 1)"
                    } as any
                  }
                >
                  <VerticalStack
                    id="340836"
                    direction="vertical"
                    size={12}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 16,
                        paddingRight: 0,
                        paddingBottom: 5,
                        paddingLeft: 0,
                        textAlign: "-webkit-center",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="master_189293_value">
                      <Image
                        id="189293"
                        zoomOnClick={false}
                        imageFit="fit"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            maxHeight: "50px",
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></Image>
                    </Form.Item>

                    <KPanel
                      id="216210"
                      scrolling="auto"
                      scrollRadius={false}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          height: "64vh",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <Form.Item className="kFormItem" name="master_920284_value" noStyle>
                        <Menu
                          id="920284"
                          menuMap={menuMap}
                          onChange={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.MasterComponent_920284_onChange();
                          }}
                          options={this.state.LeftMenu}
                          mode="vertical"
                          theme="light"
                          primaryColor="rgba(0, 190, 214, 1)"
                          collapsible={false}
                          collapsedButtonColor="rgba(10, 33, 64, 1)"
                          dataidfield="KeyField"
                          datatitlefield="TitleField"
                          dataparentidfield="ParentField"
                          datalinkfield="LinkField"
                          dataiconfield="IconField"
                          dataorderfield="OrderField"
                          style={{} as any}
                        >
                          <MenuItem
                            id="985455"
                            key={985455}
                            label={ReactSystemFunctions.translate(this.ml, 985455, "label", this.defaultML)}
                            key={985455}
                            style={{ fontWeight: 600, color: "rgba(10, 34, 64, 1)" } as any}
                          ></MenuItem>
                        </Menu>
                      </Form.Item>

                      <HorizontalStack
                        id="99793"
                        visibility={this.state.isComp99793Visible}
                        visibilityCondition={(rowData: object) =>
                          ReactSystemFunctions.isEqualTo(this.state.MasterScrYetki[0]?.kaynaklarYetki?.toString(), "1")
                        }
                        direction="horizontal"
                        size={6}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 0,
                            paddingBottom: 8,
                            paddingLeft: 29,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <Icon
                          id="19241"
                          iconName="splitscreen"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 5,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 33, 64, 1)",
                              letterSpacing: "1px"
                            } as any
                          }
                        ></Icon>

                        <Label
                          id="383613"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.MasterComponent_383613_onClick();
                          }}
                          showCursorPointer
                          value={ReactSystemFunctions.translate(this.ml, 383613, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        ></Label>
                      </HorizontalStack>
                    </KPanel>
                  </VerticalStack>

                  <KRow
                    id="198612"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="676907"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 0,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <Label
                        id="612605"
                        value={ReactSystemFunctions.translate(this.ml, 612605, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 5,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: "17px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      ></Label>
                    </KCol>
                  </KRow>

                  <KRow
                    id="249026"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="816078"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          backgroundColor: "rgba(0, 190, 214, 0.2)",
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    >
                      <KRow
                        id="29665"
                        align="top"
                        justify="start"
                        horizontalGutter={0}
                        verticalGutter={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "15px",
                            color: "rgba(10, 33, 64, 1)"
                          } as any
                        }
                      >
                        <KCol
                          id="495133"
                          xs={20}
                          sm={20}
                          md={20}
                          lg={20}
                          xl={20}
                          xxl={20}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Label
                            id="719694"
                            value={this.state.MyContext?.at?.(0)?.kurumAdi ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="16000"
                            value={this.state.MyContext?.at?.(0)?.rolenameDesc ?? undefined}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "15px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>

                          <Label
                            id="990465"
                            value={ReactSystemFunctions.getMyUsername()}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "block",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "11px",
                                color: "rgba(10, 33, 64, 1)"
                              } as any
                            }
                          ></Label>
                        </KCol>

                        <KCol
                          id="533919"
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          xxl={4}
                          order={0}
                          pull={0}
                          push={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              alignItems: "center",
                              textAlign: "-webkit-right",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "15px",
                              color: "rgba(10, 33, 64, 1)"
                            } as any
                          }
                        >
                          <Icon
                            id="862119"
                            onClick={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.MasterComponent_862119_onClick();
                            }}
                            showCursorPointer
                            iconName="settings_suggest"
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 5,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "rgba(155, 155, 155, 1)",
                                letterSpacing: "1px"
                              } as any
                            }
                          ></Icon>
                        </KCol>
                      </KRow>
                    </KCol>
                  </KRow>

                  <KRow
                    id="206089"
                    align="top"
                    justify="start"
                    horizontalGutter={0}
                    verticalGutter={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <KCol
                      id="482923"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      order={0}
                      pull={0}
                      push={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    ></KCol>
                  </KRow>

                  <HorizontalStack
                    id="826769"
                    direction="horizontal"
                    size={12}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 16,
                        paddingRight: 16,
                        paddingBottom: 16,
                        paddingLeft: 16,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "15px",
                        color: "rgba(10, 33, 64, 1)"
                      } as any
                    }
                  >
                    <Icon
                      id="77603"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.MasterComponent_77603_onClick();
                      }}
                      showCursorPointer
                      iconName="logout"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          color: "rgba(155, 155, 155, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>

                    <Label
                      id="179698"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.MasterComponent_179698_onClick();
                      }}
                      showCursorPointer
                      value={ReactSystemFunctions.translate(this.ml, 179698, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 5,
                          paddingLeft: 0,
                          width: "100px",
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "15px",
                          color: "rgba(10, 33, 64, 1)"
                        } as any
                      }
                    ></Label>
                  </HorizontalStack>
                </LayoutLeft>
                <div style={{ flex: "auto" }}>
                  <div style={{ display: "flex" }}>
                    <div
                      id="masterRootDI"
                      style={{
                        width: "0px",
                        height: "calc(100vh - 0px)",
                        flex: "auto",
                        overflow: "hidden",
                        maxWidth: "100vw",
                        position: "relative"
                      }}
                    >
                      <>{this.props.children}</>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <KDrawer
              id="762089"
              placement="left"
              onClose={() => {
                this.setState({ NavVisible762089: false }, () => {
                  this.callCallbackFunction(762089);
                });
              }}
              visible={this.state.NavVisible762089}
              width="380px"
              height="100vh"
              push={true}
              children={
                this.state.NavVisible762089 && (
                  <Settings_Screen
                    onClose={() => {
                      this.setState({ NavVisible762089: false }, () => {
                        this.callCallbackFunction(762089);
                      });
                    }}
                    screenInputs={window.kuika.navigationScreenInputs}
                  />
                )
              }
              style={
                {
                  borderStyle: "solid",
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  paddingTop: 0,
                  paddingRight: 0,
                  paddingBottom: 5,
                  paddingLeft: 0,
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "15px",
                  color: "rgba(10, 33, 64, 1)"
                } as any
              }
            ></KDrawer>

            <KModal
              id="516424"
              visible={this.state.NavVisible516424}
              onOk={() => {
                this.setState({ NavVisible516424: false }, () => {
                  this.callCallbackFunction(516424);
                });
              }}
              onCancel={() => {
                this.setState({ NavVisible516424: false }, () => {
                  this.callCallbackFunction(516424);
                });
              }}
              closable={true}
              width="800px"
              footer={null}
              height="70vh"
              children={
                this.state.NavVisible516424 && (
                  <KVKKAydinlatmaSozlesmesi_Screen
                    onClose={() => {
                      this.setState({ NavVisible516424: false }, () => {
                        this.callCallbackFunction(516424);
                      });
                    }}
                    screenInputs={window.kuika.navigationScreenInputs}
                  />
                )
              }
            ></KModal>

            <KModal
              id="741722"
              visible={this.state.NavVisible741722}
              onOk={() => {
                this.setState({ NavVisible741722: false }, () => {
                  this.callCallbackFunction(741722);
                });
              }}
              onCancel={() => {
                this.setState({ NavVisible741722: false }, () => {
                  this.callCallbackFunction(741722);
                });
              }}
              closable={true}
              width="800px"
              footer={null}
              height="70vh"
              children={
                this.state.NavVisible741722 && (
                  <KurumSozlesmesi_Screen
                    onClose={() => {
                      this.setState({ NavVisible741722: false }, () => {
                        this.callCallbackFunction(741722);
                      });
                    }}
                    screenInputs={window.kuika.navigationScreenInputs}
                  />
                )
              }
            ></KModal>

            <KModal
              id="478348"
              visible={this.state.NavVisible478348}
              onOk={() => {
                this.setState({ NavVisible478348: false }, () => {
                  this.callCallbackFunction(478348);
                });
              }}
              onCancel={() => {
                this.setState({ NavVisible478348: false }, () => {
                  this.callCallbackFunction(478348);
                });
              }}
              closable={true}
              width="800px"
              footer={null}
              height="70vh"
              children={
                this.state.NavVisible478348 && (
                  <EgitmenSozlesmesiYeni_Screen
                    onClose={() => {
                      this.setState({ NavVisible478348: false }, () => {
                        this.callCallbackFunction(478348);
                      });
                    }}
                    screenInputs={window.kuika.navigationScreenInputs}
                  />
                )
              }
            ></KModal>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withHistory(connect(mapStateToProps, mapDispatchToProps)(Master_Screen))))
);
export { tmp as Master_Screen };
//export default tmp;
//export { tmp as Master_Screen };
