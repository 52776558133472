import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProtokolAltBilesenler_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProtokolAltBilesenler_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  ProtokolSelectById: any[];
  ProtokolSelectById_dummy: any[];
  ProtokolBilesenById: any[];
  ProtokolBilesenById_dummy: any[];
  BilesenSelectById: any[];
  BilesenSelectById_dummy: any[];
  ProtokoAltBilesenleri: any[];
  ProtokoAltBilesenleri_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  DeleteAltBilesen: any[];
  DeleteAltBilesen_dummy: any[];
}

export class ProtokolAltBilesenler_ScreenBase extends React.PureComponent<IProtokolAltBilesenler_ScreenProps, any> {
  protokolaltbilesenler_399769_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "7c89ae0d-0814-4bdf-8cc0-95d03157ec8e",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "PROTOKOL BİLEŞENLERİ" },
        { Id: 814413, PropertyName: "label", Value: "YENİ" },
        { Id: 536516, PropertyName: "value", Value: "Protokol" },
        { Id: 983442, PropertyName: "value", Value: "Bileşen" },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 91769, PropertyName: "title", Value: "Sıra" },
        { Id: 629897, PropertyName: "value", Value: "[datafield:sira]" },
        { Id: 8107049, PropertyName: "title", Value: "Alt Bileşen" },
        { Id: 8143247, PropertyName: "value", Value: "[datafield:altbilesen]" },
        { Id: 628421, PropertyName: "title", Value: "Varsayılan Test" },
        { Id: 457911, PropertyName: "value", Value: "[datafield:test]" },
        { Id: 130986, PropertyName: "title", Value: "Norm" },
        { Id: 467673, PropertyName: "value", Value: "[datafield:norm]" },
        { Id: 78918, PropertyName: "title", Value: "Pay" },
        { Id: 660133, PropertyName: "value", Value: "[datafield:pay]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.protokolaltbilesenler_399769_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      GoBack: "",
      ProtokolSelectById: [],
      ProtokolBilesenById: [],
      BilesenSelectById: [],
      ProtokoAltBilesenleri: [],
      SaveRecord: false,
      DeleteAltBilesen: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("protokolaltbilesenler", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ProtokolAltBilesenlerPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("protokolaltbilesenler", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("protokolaltbilesenler", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ProtokolAltBilesenlerPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ProtokolAltBilesenlerPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid,
        "Guid"
      ),
      protokolBilesenId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "ProtokolAltBilesenler/ProtokolAltBilesenlerPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProtokolSelectById = result?.data.protokolSelectById;
    formVars.protokolaltbilesenler_662169_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolSelectById?.length > 0
        ? stateVars.ProtokolSelectById[0].tanim
        : this.state.ProtokolSelectById?.length > 0
        ? this.state.ProtokolSelectById[0].tanim
        : null
    );
    stateVars.ProtokolBilesenById = result?.data.protokolBilesenById;
    stateVars.BilesenSelectById = result?.data.bilesenSelectById;
    formVars.protokolaltbilesenler_435552_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSelectById?.length > 0
        ? stateVars.BilesenSelectById[0].tanim
        : this.state.BilesenSelectById?.length > 0
        ? this.state.BilesenSelectById[0].tanim
        : null
    );
    stateVars.ProtokoAltBilesenleri = result?.data.protokoAltBilesenleri;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ProtokolAltBilesenlerPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProtokolAltBilesenlerPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.protokolaltbilesenler_662169_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolSelectById?.length > 0
        ? stateVars.ProtokolSelectById[0].tanim
        : this.state.ProtokolSelectById?.length > 0
        ? this.state.ProtokolSelectById[0].tanim
        : null
    );

    formVars.protokolaltbilesenler_435552_value = ReactSystemFunctions.toString(
      this,
      stateVars.BilesenSelectById?.length > 0
        ? stateVars.BilesenSelectById[0].tanim
        : this.state.BilesenSelectById?.length > 0
        ? this.state.BilesenSelectById[0].tanim
        : null
    );

    stateVars.dataSource_399769 = this.state.ProtokoAltBilesenleri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ProtokolAltBilesenlerComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ProtokolAltBilesenF",
      "ProtokolBilesenId",
      this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid
    );
    KuikaAppManager.addToPageInputVariables(
      "ProtokolAltBilesenF",
      "ProtokolId",
      this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid
    );
    KuikaAppManager.addToPageInputVariables("ProtokolAltBilesenF", "ProtokolAltBilesenId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.ProtokolAltBilesenlerComponent_814413_onClick1_,
      "ProtokolAltBilesenler",
      "ProtokolAltBilesenF",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "486227",
      null,
      "right",
      null,
      "450px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  ProtokolAltBilesenlerComponent_814413_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProtokolAltBilesenlerComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ProtokolAltBilesenF",
      "ProtokolBilesenId",
      this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid
    );
    KuikaAppManager.addToPageInputVariables(
      "ProtokolAltBilesenF",
      "ProtokolId",
      this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid
    );
    KuikaAppManager.addToPageInputVariables(
      "ProtokolAltBilesenF",
      "ProtokolAltBilesenId",
      ReactSystemFunctions.value(this, "protokolaltbilesenler_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "ProtokolAltBilesenler",
      "ProtokolAltBilesenF",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "534407",
      null,
      "right",
      null,
      "450px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ProtokolAltBilesenlerComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProtokolAltBilesenId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      ProtokolAltBilesenId_0_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "protokolaltbilesenler_399769_value", "id"),
        "Guid"
      ),
      pabID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "protokolaltbilesenler_399769_value", "id"),
        "Guid"
      ),
      protokolBilesenId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolBilesenId ?? this.props.screenInputs.protokolbilesenid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f1b34c90_200f_51e7_1ae4_d7282d26938e_confirmation",
        this.defaultML,
        "Alt bileşen silinecektir onaylıyor musunuz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "ProtokolAltBilesenler/ProtokolAltBilesenlerComponent_505853_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_1;
      stateVars.DeleteAltBilesen = result?.data.deleteAltBilesen;
      stateVars.ProtokoAltBilesenleri = result?.data.protokoAltBilesenleri;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ProtokolAltBilesenlerComponent_505853_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ProtokolAltBilesenlerComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_399769 = this.state.ProtokoAltBilesenleri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [486227, 534407] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.ProtokolAltBilesenlerPageInit();
    }
    if (diId == 486227) {
      isErrorOccurred = await this.ProtokolAltBilesenlerComponent_814413_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
